/**
 * Created by Daniel on 12/13/2020.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum EntityActionTypes {
    INITIALIZE = '[ENTITY] INITIALIZE',
    INITIALIZE_SUCCESS = '[ENTITY] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[ENTITY] INITIALIZE_FAILED'
}

export class Initialize implements Action {
    readonly type = EntityActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = EntityActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = EntityActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export type EntityActions = Initialize | InitializeSuccess | InitializeFailed;
