/**
 * Created by Yu/Alan/Jiu.
 *
 * ------ maintenance history ------
 * updated by Alan && Ella on 2019
 */
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { AdvFilterHelperService } from './adv-filter-helper.service';
import { FiltersConfig, FilterMetadataType } from '../components/filter-config';
import { TSGuid } from '../models/ts-guid.model';
import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { DateHelperService } from './date-helper.service';
import { EntityBrief } from '../models/entity-brief.model';
import { ALL_VALUE, businessConstants, CURRENT_USER } from '../constants/business.constants';
import { ContactService } from './contact.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../redux';
import { filter, take } from 'rxjs/operators';
import { EntityType } from '../models/entity-type.model';
import { DefaultWordCites } from '../constants/DefaultWorldCities.constants';
import { validRelationshipTypeSelector } from '../../redux/reducers/valid-relationship-type.reducer';
import { availableEntryTypeSelector } from '../../redux/reducers/entry-type.reducer';
import { fullUsersSelector } from '../../redux/reducers/users.reducer';
import { allTeamSelector } from '../../redux/reducers/teams.reducer';
import { AdhocService } from './adhoc.service';
import { ArrayHelperService } from './array-helper.service';

@Injectable()
export class FilterService {

    standardMetadata = [
        {
            fields: ['title'],
            metadataType: 'FileName',
            title: 'File Name',
            value: ''
        },
        {
            fields: ['file ext'],
            metadataType: 'FileType',
            title: 'File Type',
            value: ''
        },
        {
            fields: ['title'],
            metadataType: 'Subject',
            title: 'Subject',
            value: ''
        },
        {
            fields: ['location'],
            metadataType: 'Location',
            title: 'Location',
            value: ''
        },
        {
            fields: ['title'],
            metadataType: 'TextBox',
            title: 'Subject',
            value: ''
        },
        {
            fields: ['entry-type id'],
            metadataType: 'NoteType',
            source: [],
            title: 'Note Type',
            value: [],
        },
        {
            fields: ['entry-entity-type'],
            metadataType: 'EntityType',
            source: [],
            title: 'Entity Type',
            value: [],
        },
        {
            fields: ['entry-entity'],
            metadataType: 'EntryEntity',
            title: 'Entities',
            value: '' // depending on the server data requirement, the value format is different from the other entity related fields
        },
        {
            fields: ['entities'],
            metadataType: 'Entities',
            title: 'Entities',
            value: []
        },
        {
            fields: ['display-date'],
            metadataType: 'DisplayDate',
            title: 'Display Date',
            value: '1 day'
        },
        {
            fields: ['submitted-date'],
            metadataType: 'SubmittedDate',
            title: 'Submitted Date',
            value: '1 day'
        },
        {
            fields: ['last-edited-date'],
            metadataType: 'LastModifiedDate',
            title: 'Last Modified Date',
            value: '1 day'
        },
        {
            fields: ['source id'],
            metadataType: 'Source',
            title: 'Source',
            value: []
        },
        {
            fields: ['participants id'],
            metadataType: 'Participants',
            title: 'Participants',
            value: []
        },
        {
            fields: ['submitter id'],
            metadataType: 'Submitter',
            title: 'Submitter',
            value: []
        },
        {
            fields: ['priority'],
            metadataType: 'Priority',
            title: 'Priority',
            value: 0
        },
        {
            fields: ['sentiment'],
            metadataType: 'Sentiment',
            title: 'Sentiment',
            value: []
        },
        {
            fields: ['team'],
            metadataType: 'Team',
            title: 'Team',
            value: []
        },
        {
            fields: ['user'],
            metadataType: 'User',
            title: 'User',
            value: []
        }, {
            fields: ['where'],
            metadataType: 'Where',
            title: 'Where',
            value: []
        },
        {
            fields: ['time'],
            metadataType: 'DateAndTime',
            title: 'Date & Time',
            value: '1 day'
        },
        {
            fields: ['action'],
            metadataType: 'Action',
            title: 'Action',
            value: []
        },
        {
            fields: [],
            metadataType: 'Date',
            title: '',
            value: '1 day'
        },
        {
            fields: ['entity id'],
            metadataType: 'Entity',
            title: 'Entity',
            value: []
        },
        {
            fields: ['entity id'],
            metadataType: 'ED',
            title: 'ED',
            value: []
        },
        {
            fields: ['entity id'],
            metadataType: 'MED',
            title: 'MED',
            value: []
        },
        {
            fields: ['entity id'],
            metadataType: 'MET',
            title: 'MET',
            value: []
        },
        {
            fields: ['dropdown'],
            metadataType: 'Dropdown',
            title: 'Dropdown',
            value: []
        },
        {
            fields: ['entity id'],
            metadataType: 'MSD',
            title: 'MSD',
            value: []
        },
        {
            fields: ['entity id'],
            metadataType: 'CheckBox',
            title: 'CheckBox',
            value: []
        },

        {
            fields: ['Company'],
            metadataType: 'Company',
            title: 'Company',
            value: []
        },
        {
            fields: ['JobTitle'],
            metadataType: 'JobTitle',
            title: 'Job Title',
            value: []
        },
        {
            fields: ['Job Function'],
            metadataType: 'JobFunction',
            title: 'Job Function',
            value: []
        },
        {
            fields: ['Alias'],
            metadataType: 'Alias',
            title: 'Alias',
            value: []
        },
        {
            fields: ['City'],
            metadataType: 'City',
            title: 'City',
            value: []
        },
        {
            fields: ['Country & State'],
            metadataType: 'CountryState',
            title: 'Country & State',
            value: []
        },
        {
            fields: ['Former Company'],
            metadataType: 'FormerCompany',
            title: 'Company',
            value: []
        },
        {
            fields: ['start-time'],
            metadataType: 'StartTime',
            title: 'Start Time',
            value: new Date().toLocaleDateString()
        },
        {
            fields: ['end-time'],
            metadataType: 'EndTime',
            title: 'End Time',
            value: new Date().toLocaleDateString()
        },

    ];

    constructor(
        private _adhocService: AdhocService,
        private _advFilterHelperService: AdvFilterHelperService,
        private _dateHelper: DateHelperService,
        private _transportService: TransportService,
        private _contactService: ContactService,
        private _store: Store<AppState>
    ) { }

    convertFilterV2toV3(filterConfigV2): Array<FiltersConfig> {
        const result: Array<FiltersConfig> = [];

        filterConfigV2.filterComponents.forEach(element => {
            const filterConfig: any = {
                title: element.config.labelName,
                id: element.config.filterWidgetId,
            };
            if (element.config.filterType === 'note-type-filter') {
                filterConfig.fields = ['entry-type id'];
                filterConfig.metadataType = FilterMetadataType.NoteType;
                if (element.config.ctrlValue.filterOptionValue === 'none of') {
                    filterConfig.selectedOperator = 'excludes';
                } else {
                    filterConfig.selectedOperator = 'includes';
                }
                filterConfig.value = element.config.ctrlValue.filterContentValue.map(value => value.id);
            } else if (element.config.filterType === 'entity-type-filter') {
                filterConfig.fields = ['entry-entity-type'];
                filterConfig.metadataType = FilterMetadataType.EntityType;
                if (element.config.ctrlValue.filterOptionValue === 'none of') {
                    filterConfig.selectedOperator = 'excludes';
                } else {
                    filterConfig.selectedOperator = 'includes';
                }
                filterConfig.value = element.config.ctrlValue.filterContentValue.map(value => value.id);
            } else if (element.config.filterType === 'entity-filter') {
                filterConfig.fields = ['entities'];
                filterConfig.metadataType = FilterMetadataType.Entities;
                filterConfig.title = this.getTitle(filterConfig.metadataType);
                if (element.config.ctrlValue.filterOptionValue === 'none of') {
                    filterConfig.selectedOperator = 'excludes';
                } else if (element.config.ctrlValue.filterOptionValue === 'all of') {
                    filterConfig.selectedOperator = 'equals';
                } else {
                    filterConfig.selectedOperator = 'includes';
                }
                filterConfig.value = element.config.ctrlValue.filterContentValue.map(value => {
                    return {
                        id: value.id,
                        name: value.longName,
                        shortName: value.shortName,
                        isPublic: value.isPublic,
                        company: value.company
                    };
                });
                filterConfig.relationshipType = element.config.ctrlValue.relationshipTypeIds;
            } else if (element.config.filterType === 'source-filter') {
                filterConfig.fields = ['source id'];
                filterConfig.metadataType = FilterMetadataType.Source;
                if (element.config.ctrlValue.filterOptionValue === 'none of') {
                    filterConfig.selectedOperator = 'excludes';
                } else {
                    filterConfig.selectedOperator = 'includes';
                }
                filterConfig.value = element.config.ctrlValue.filterContentValue.map(value => {
                    return {
                        id: value.id,
                        name: value.longName,
                        shortName: value.longName,
                        company: value.company
                    };
                });
            } else if (element.config.filterType === 'submitter-filter') {
                filterConfig.fields = ['submitter id'];
                filterConfig.metadataType = FilterMetadataType.Submitter;
                if (element.config.ctrlValue.filterOptionValue === 'none of') {
                    filterConfig.selectedOperator = 'excludes';
                } else {
                    filterConfig.selectedOperator = 'includes';
                }
                filterConfig.value = element.config.ctrlValue.filterContentValue.map(value => {
                    return {
                        id: value.id,
                        name: value.longName,
                        shortName: value.longName,
                        company: value.company
                    };
                });
            } else if (element.config.filterType === 'sentiment-filter') {
                filterConfig.fields = ['sentiment'];
                filterConfig.metadataType = FilterMetadataType.Sentiment;
                if (element.config.ctrlValue.filterOptionValue === 'Hide') {
                    filterConfig.selectedOperator = 'excludes';
                } else {
                    filterConfig.selectedOperator = 'includes';
                }
                // Web 1.0 research view sentiment filter save value as number, need convert to string
                filterConfig.value = [element.config.ctrlValue.filterContentValue + ''];
            } else if (element.config.filterType === 'subject-filter') {
                filterConfig.fields = ['title'];
                filterConfig.metadataType = FilterMetadataType.Subject;
                if (element.config.ctrlValue.filterOptionValue === 'Hide') {
                    filterConfig.selectedOperator = 'notcontains';
                } else {
                    filterConfig.selectedOperator = 'contains';
                }
                filterConfig.value = element.config.ctrlValue.filterContentValue;
            } else if (element.config.filterType === 'priority-filter') {
                filterConfig.fields = ['priority'];
                filterConfig.metadataType = FilterMetadataType.Priority;
                if (element.config.ctrlValue.filterOptionValue === 'Greater than') {
                    filterConfig.selectedOperator = 'greater';
                } else if (element.config.ctrlValue.filterOptionValue === 'Greater than or equal to') {
                    filterConfig.selectedOperator = 'greater_or_equal';
                } else if (element.config.ctrlValue.filterOptionValue === 'Equals') {
                    filterConfig.selectedOperator = 'equals';
                } else if (element.config.ctrlValue.filterOptionValue === 'Not equal to') {
                    filterConfig.selectedOperator = 'not_equal';
                } else if (element.config.ctrlValue.filterOptionValue === 'Less than') {
                    filterConfig.selectedOperator = 'less';
                } else if (element.config.ctrlValue.filterOptionValue === 'Less than or equal to') {
                    filterConfig.selectedOperator = 'less_or_equal';
                }
                filterConfig.value = element.config.ctrlValue.priorityValue;
            } else if (element.config.filterType === 'date-filter') {
                filterConfig.fields = [element.config.fieldName];
                if (element.config.fieldName === 'display-date') {
                    filterConfig.metadataType = FilterMetadataType.DisplayDate;
                } else if (element.config.fieldName === 'submitted-date') {
                    filterConfig.metadataType = FilterMetadataType.SubmittedDate;
                } else {
                    filterConfig.metadataType = FilterMetadataType.Date;
                }
                if (element.config.radioButton === 'standard') {
                    filterConfig.selectedOperator = 'last';
                    filterConfig.value = element.config.ctrlValue.standard.rangeValue
                        + ' ' + element.config.ctrlValue.standard.filterUnitValue.slice(0, -1);
                } else if (element.config.radioButton === 'custom') {
                    filterConfig.selectedOperator = 'range';
                    const startDate = new Date(element.config.ctrlValue.custom.startDate);
                    const endDate = new Date(element.config.ctrlValue.custom.endDate);
                    filterConfig.value = this._dateHelper.getDateString(startDate, 'MM/dd/yyyy')
                        + ' ' + this._dateHelper.getDateString(endDate, 'MM/dd/yyyy');
                }
            }
            result.push(filterConfig);
        });
        return result;
    }

    generateFilterSource(metadataType: string): Array<{ name: string, value: string }> {
        let source = [];
        switch (metadataType?.toLowerCase()) {
            case 'FileType'.toLowerCase():
                source = [
                    { id: 'mp3,wav', name: 'Audio (.mp3, .wav)', shortName: 'Audio' },
                    { id: 'txt,htm,html,xml', name: 'Clip (.txt, .htm, .html, .xml)', shortName: 'Clip' },
                    { id: 'doc,docx,rtf,dot', name: 'Document (.doc, .docx, .rtf, .dot)', shortName: 'Document' },
                    { id: 'jpg,jpeg,gif,png,bmp', name: 'Image (.jpg, .jpeg, .gif, .png, .bmp)', shortName: 'Image' },
                    { id: 'pdf', name: 'PDF files (.pdf)', shortName: 'PDF files' },
                    { id: 'ppt,pptx', name: 'Presentation (.ppt, .pptx)', shortName: 'Presentation' },
                    { id: 'xls,xlsx', name: 'Spreadsheet (.xls, .xlsx)', shortName: 'Spreadsheet' },
                    { id: '.eml,.msg,.ndl,.mime,.pst', name: 'Email (.eml, .msg, .ndl, .mime, .pst)', shortName: 'Email' }
                ];
                break;
            case 'Sentiment'.toLowerCase():
                source = [
                    { value: '1', name: 'Positive' },
                    { value: '0', name: 'Neutral' },
                    { value: '-1', name: 'Negative' }
                ];
                break;
            case 'Where'.toLowerCase():
                source = [
                    { value: 'w', name: 'Web' },
                    { value: 'd', name: 'Desktop' },
                    { value: 'i', name: 'iOS' },
                    { value: 'r', name: 'REST API' },
                    { value: 'e', name: 'Email Deposit' },
                    { value: 'Addin', name: 'Addin' },
                    { value: 'Workflow', name: 'Workflow' }
                ];
                break;
            case 'Action'.toLowerCase():
                source = [
                    { value: 'c', name: 'Create' },
                    { value: 'r', name: 'Read' },
                    { value: 'u', name: 'Update' },
                    { value: 'd', name: 'Delete' }
                ];
                break;
            case 'Alias'.toLowerCase():
                this._contactService.getContactAlias().subscribe(res => {
                    res.alias.forEach(item => {
                        source.push({
                            'value': item,
                            'name': item
                        });
                    });
                });
                break;
            case 'JobFunction'.toLowerCase():
                this._contactService.getJobFunction().subscribe(res => {
                    res.forEach(item => {
                        source.push({
                            'value': item.id,
                            'name': item.name
                        });
                    });
                });
                break;
            case 'JobTitle'.toLowerCase():
                this._store.pipe(
                    select(validRelationshipTypeSelector),
                    filter(res => res && res.length > 0),
                ).subscribe(res => {
                    res.forEach(element => {
                        if (element.childEntityType.id === EntityType.CONTACT.id &&
                            element.parentEntityType.id === EntityType.CONTACT.id) {
                            source.push({
                                'value': element.relationshipType.id,
                                'name': element.relationshipType.name
                            });
                        }
                    });
                });
                break;
            case 'Country&State'.toLowerCase():
                source = Array.from(new Set(DefaultWordCites.map(data => data.country).sort()));
                break;
            case 'Relationship'.toLowerCase():
                break;
            case 'EventType'.toLowerCase():
                this._store.pipe(
                    select(availableEntryTypeSelector),
                    filter(res => res && res.length > 0),
                ).subscribe(res => {
                    res.forEach(item => {
                        if (item.usage.includes(businessConstants.EntryTypes.event)) {
                            source.push({
                                'id': item.id,
                                'name': item.name
                            });
                        }
                    });
                });
                break;
            case 'Specific users'.toLowerCase():
                this._store.pipe(
                    select(fullUsersSelector),
                ).subscribe(res => {
                    res.forEach(item => {
                        source.push({
                            'id': item.id,
                            'name': item.name
                        });
                    });
                    ArrayHelperService.sort(source, 'name');
                });
                break;
            case 'Specific teams'.toLowerCase():
                this._store.pipe(
                    select(allTeamSelector),
                    filter((list: any) => list.length > 0),
                    take(1),
                ).subscribe(res => {
                    res.forEach(item => {
                        source.push({
                            'id': item.id,
                            'name': item.name
                        });
                    });
                    ArrayHelperService.sort(source, 'name');
                });
                break;
            default:
                break;
        }

        return source;
    }

    getDefaultOperator(metadataType: string): { name: string, value: string } {
        let operator;
        switch (metadataType?.toLowerCase()) {
            case 'Subject'.toLowerCase():
            case 'TextBox'.toLowerCase():
            case 'FileName'.toLowerCase():
            case 'Location'.toLowerCase():
                operator = { name: 'Contains', value: 'contains' };
                break;
            case 'DisplayDate'.toLowerCase():
            case 'SubmittedDate'.toLowerCase():
            case 'LastModifiedDate'.toLowerCase():
            case 'Date'.toLowerCase():
            case 'DateAndTime'.toLowerCase():
                operator = { name: 'Last', value: 'last' };
                break;
            case 'StartTime'.toLowerCase():
            case 'EndTime'.toLowerCase():
                operator = { name: 'After', value: 'after' };
                break;
            case 'Priority'.toLowerCase():
                operator = { name: 'Greater than', value: 'greater' };
                break;
            case 'Team'.toLowerCase():
                operator = { name: 'Submitted by', value: 'submittedby' };
                break;
            default:
                operator = { name: 'Includes', value: 'includes' };
                break;
        }
        return operator;
    }

    /**
     * Gets the operator option items according to metadataType.
     *
     * param {string} metadataType
     * returns {Array<{ name: string, value: string }>}
     * memberof MetadataService
     */
    getOperatorOptions(metadataType: string): Array<{ name: string, value: string }> {
        let operators = [];
        switch (metadataType?.toLowerCase()) {
            case 'EntityType'.toLowerCase():
            case 'FileType'.toLowerCase():
            case 'NoteType'.toLowerCase():
            case 'Sentiment'.toLowerCase():
                operators = [
                    { name: 'Includes', value: 'includes' },
                    { name: 'Excludes', value: 'excludes' },
                ];
                break;
            case 'Entities'.toLowerCase():
                operators = [
                    { name: 'Includes', value: 'includes' },
                    { name: 'Equals', value: 'equals' },
                    { name: 'Excludes', value: 'excludes' },
                ];
                break;
            case 'Team'.toLowerCase():
                operators = [
                    { name: 'Submitted by', value: 'submittedby' },
                    { name: 'Not Submitted by', value: 'notsubmittedby' },
                    { name: 'Sourced to', value: 'sourcedto' },
                    { name: 'Not Sourced to', value: 'notsourcedto' },
                ];
                break;
            case 'MED'.toLowerCase():
            case 'MET'.toLowerCase():
            case 'MSD'.toLowerCase():
                operators = [
                    { name: 'Includes', value: 'includes' },
                    { name: 'Equals', value: 'equals' },
                    { name: 'Excludes', value: 'excludes' },
                    { name: 'is Blank', value: 'blank' },
                    { name: 'Not Blank', value: 'notblank' },
                ];
                break;
            case 'ED'.toLowerCase():
            case 'Dropdown'.toLowerCase():
            case 'Entity'.toLowerCase():
                operators = [
                    { name: 'Includes', value: 'includes' },
                    { name: 'Excludes', value: 'excludes' },
                    { name: 'is Blank', value: 'blank' },
                    { name: 'Not Blank', value: 'notblank' },
                ];
                break;
            case 'Source'.toLowerCase():
            case 'Submitter'.toLowerCase():
            case 'User'.toLowerCase():
            case 'Where'.toLowerCase():
            case 'Action'.toLowerCase():
            case 'Participants'.toLowerCase():
                operators = [
                    { name: 'Includes', value: 'includes' },
                    { name: 'Excludes', value: 'excludes' },
                ];
                break;
            case 'Location'.toLowerCase():
            case 'Subject'.toLowerCase():
            case 'TextBox'.toLowerCase():
                operators = [
                    { name: 'Contains', value: 'contains' },
                    { name: 'Not Contains', value: 'notcontains' },
                    { name: 'Starts with', value: 'startswith' },
                    { name: 'Ends with', value: 'endswith' },
                    { name: 'is Blank', value: 'blank' },
                    { name: 'Not Blank', value: 'notblank' },
                ];
                break;
            case 'FileName'.toLowerCase():
                operators = [
                    { name: 'Contains', value: 'contains' },
                    { name: 'Starts with', value: 'startswith' },
                    { name: 'Ends with', value: 'endswith' },
                ];
                break;
            case 'CheckBox'.toLowerCase():
                operators = [
                    { name: 'Includes', value: 'includes' },
                    { name: 'Equals', value: 'equals' },
                ];
                break;
            case 'Date'.toLowerCase():
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Next', value: 'next' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'is Blank', value: 'blank' },
                    { name: 'Not Blank', value: 'notblank' },
                    { name: 'Dynamic Date', value: 'dynamicDate' },
                ];
                break;
            case 'SubmittedDate'.toLowerCase():
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'Today', value: 'today' },
                    { name: 'Dynamic Date', value: 'dynamicDate' },
                ];
                break;
            case 'DisplayDate'.toLowerCase():
            case 'LastModifiedDate'.toLowerCase():
            case 'DateAndTime'.toLowerCase():
            case 'StartTime'.toLowerCase():
            case 'EndTime'.toLowerCase():
                operators = [
                    { name: 'Last', value: 'last' },
                    { name: 'Next', value: 'next' },
                    { name: 'Date Range', value: 'range' },
                    { name: 'Before', value: 'before' },
                    { name: 'After', value: 'after' },
                    { name: 'Today', value: 'today' },
                    { name: 'Dynamic Date', value: 'dynamicDate' },
                ];
                break;
            case 'Priority'.toLowerCase():
                operators = [
                    { name: 'Greater than', value: 'greater' },
                    { name: 'Greater than or equal to', value: 'greater_or_equal' },
                    { name: 'Equals', value: 'equals' },
                    { name: 'Not equal to', value: 'not_equal' },
                    { name: 'Less than', value: 'less' },
                    { name: 'Less than or equal to', value: 'less_or_equal' },
                ];
                break;
            default:
                break;
        }
        return operators;
    }

    /**
    * Creates filterConfig instance when user adding a new filter.
    *
    * param {string} title the filter textField for displaying.
    * param {string} metadataType the metadataType for checking filter widget type.
    * returns {FiltersConfig}
    * memberof MetadataService
    */
    getNewFilterDefaultInstance(title: string, metadataType: string, hideOperators?: any,
        disabledDel?: any, showCurrenUser?: boolean): FiltersConfig {
        const filtersConfig = new FiltersConfig();

        filtersConfig.id = new TSGuid().toString();
        filtersConfig.fields = this.getFields(metadataType);
        filtersConfig.title = this.getTitle(metadataType);

        filtersConfig.metadataType = metadataType;

        filtersConfig.value = this.getDefaultValue(metadataType);
        filtersConfig.source = this.generateFilterSource(metadataType);

        filtersConfig.selectedOperator = this.getDefaultOperator(metadataType).value;
        filtersConfig.operators = this.getOperatorOptions(metadataType);

        filtersConfig.disable = false; // by default
        filtersConfig.deletable = !!disabledDel;
        filtersConfig.hideOperators = !!hideOperators;
        if (showCurrenUser && (metadataType === FilterMetadataType.Source || metadataType === FilterMetadataType.Submitter)) {
            filtersConfig.currentUser = CURRENT_USER;
        }
        return filtersConfig;
    }

    getFields(metadataType: string): string[] {
        if (!metadataType) { return []; }
        return this.standardMetadata.filter(value => value.metadataType === metadataType)[0].fields;
    }

    getDefaultValue(metadataType: string): any {
        if (!metadataType) { return null; }
        return this.standardMetadata.filter(value => value.metadataType.toLowerCase() === metadataType.toLowerCase())[0]?.value;
    }

    getTitle(metadataType: string): string {
        if (!metadataType) { }
        return this.standardMetadata.filter(value => value.metadataType === metadataType)[0].title;
    }

    generateAdvFilterForDate(setting: FiltersConfig, timeZone: number) {
        if (!setting.value) {
            return null;
        }
        timeZone = !timeZone ? 0 : timeZone;
        const operator = setting.selectedOperator;
        let dateInNumber, startDateInNumber, endDateInNumber;
        // Need to minus getTimezoneOffset, then minus timeZone
        const localTimzeoneOffset = new Date().getTimezoneOffset();
        const timezoneOffset = (localTimzeoneOffset + timeZone) * 60 * 1000;

        if (operator === 'before') {
            dateInNumber = new Date(setting.value).getTime() - timezoneOffset;
        } else if (operator === 'after') {
            const tmpDate = new Date(setting.value);
            tmpDate.setDate(tmpDate.getDate() + 1);
            dateInNumber = tmpDate.getTime() - timezoneOffset - 1; // Need to minus 1 millisecond in order to include 00:00:00:000
        } else if (operator === 'range') {
            const dates = setting.value.split(' ');
            if (dates[0]) {
                const startDate = new Date(dates[0]);
                // Need to minus 1 millisecond in order to include 00:00:00:000
                startDateInNumber = startDate.getTime() - timezoneOffset - 1;
            }
            if (dates[1]) {
                const endDate = new Date(dates[1]);
                endDate.setDate(endDate.getDate() + 1);
                endDateInNumber = endDate.getTime() - timezoneOffset;
            }
        }

        const advfilterArr = [];
        setting.fields.forEach(field => {
            if (operator.indexOf('blank') > -1 || operator.indexOf('null') > -1) {
                if (field === 'display-date' || field === 'submitted-date' || field === 'last-edited-date' || field === 'start-time' || field === 'end-time') {
                    advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, setting.value, 'standard'));
                } else {
                    advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, setting.value, 'adhoc'));
                }
            } else if (operator === 'range') {
                const startEndDateFilter = [];
                if (field === 'display-date' || field === 'submitted-date' || field === 'last-edited-date' || field === 'start-time' || field === 'end-time') {
                    if (startDateInNumber) {
                        startEndDateFilter.push(this._advFilterHelperService.generateFilterStr(field,
                            'after', startDateInNumber, 'standard'));
                    }
                    if (endDateInNumber) {
                        startEndDateFilter.push(this._advFilterHelperService.generateFilterStr(field,
                            'before', endDateInNumber, 'standard'));
                    }
                } else {
                    if (startDateInNumber) {
                        startEndDateFilter.push(this._advFilterHelperService.generateFilterStr(field, 'after', startDateInNumber, 'adhoc'));
                    }
                    if (endDateInNumber) {
                        startEndDateFilter.push(this._advFilterHelperService.generateFilterStr(field, 'before', endDateInNumber, 'adhoc'));
                    }
                }
                advfilterArr.push(this._advFilterHelperService.joinFilter(startEndDateFilter, 'and', false));
            } else if (operator === 'before' || operator === 'after') {
                if (field === 'display-date' || field === 'submitted-date' || field === 'last-edited-date' || field === 'start-time' || field === 'end-time') {
                    advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, dateInNumber, 'standard'));
                } else {
                    advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, dateInNumber, 'adhoc'));
                }
            } else {
                if (field === 'display-date' || field === 'submitted-date' || field === 'last-edited-date' || field === 'start-time' || field === 'end-time') {
                    advfilterArr.push('(' + field + ' ' + operator + ' "' + setting.value + '" "' + timeZone + '")');
                } else {
                    advfilterArr.push('(property "' + field + '" ' + operator + ' "' + setting.value + '" "' + timeZone + '")');
                }
            }
        });
        return this._advFilterHelperService.joinFilter(advfilterArr, 'or', false);
    }

    /**
     * The logic is the same with the Entities fitler in Email Digest.
     *
     * param {FiltersConfig} setting
     * returns {string}
     * memberof FilterService
     */
    generateAdvFilterForEntities(setting: FiltersConfig): string {
        let operator;
        let advfilterStr = '';
        const ids = [];
        if (setting.value && setting.value.length > 0) {
            setting.value.forEach(element => {
                ids.push(element.id);
            });
        }
        if (setting.relationshipType && setting.relationshipType.length > 0) {
            if (setting.selectedOperator === 'includes') {
                operator = 'anyof';
            } else if (setting.selectedOperator === 'excludes') {
                operator = 'noneof';
            } else if (setting.selectedOperator === 'equals') {
                operator = 'array-equals';
            } else {
                operator = setting.selectedOperator;
            }
            // tslint:disable-next-line: max-line-length
            // advfilter: ((entry-entity-relationship anyof entities "23ff07490a16aed09f4a59ca97ea528c,23f2b2bb0a16aed04bf5c51c18f6a282" relationships "6d81ab7fb5124aefa1a0d6843c037794,3fe2380a0a16aed02c314dbaabf33d00")
            advfilterStr = `(entry-entity-relationship ${operator} entities "${ids}" relationships "${setting.relationshipType}")`;
        } else {
            if (setting.selectedOperator === 'includes') {
                operator = 'anyof id';
            } else if (setting.selectedOperator === 'excludes') {
                operator = 'noneof id';
            } else if (setting.selectedOperator === 'equals') {
                operator = 'array-equals id';
            } else {
                operator = setting.selectedOperator;
            }
            advfilterStr = this._advFilterHelperService.generateFilterStr('entities', operator, ids, 'standard');
        }
        return advfilterStr;
    }

    generateAdvFilterForEntityType(setting: FiltersConfig) {
        if (setting.value === ALL_VALUE) {
            return null;
        }
        let operator = '';
        if (setting.selectedOperator === 'includes') {
            operator = 'anyof';
        } else if (setting.selectedOperator === 'excludes') {
            operator = 'noneof';
        }

        return this._advFilterHelperService.generateFilterStr(setting.fields[0], operator, setting.value, 'standard');
    }

    generateAdvFilterForDropdown(setting: FiltersConfig) {
        let operator;
        if (setting.selectedOperator === 'includes') {
            operator = 'anyof';
        } else if (setting.selectedOperator === 'excludes') {
            operator = 'noneof';
        } else if (setting.selectedOperator === 'equals' && setting.metadataType === 'MSD') {
            operator = 'array-equals';
        } else {
            operator = setting.selectedOperator;
        }

        // replace double quote character (") in value to Unicode to prevent advfilter error in server
        let value = '';
        if (setting.value) {
            value = setting.value.map(function (val) {
                return val.replace('"', '&#34;').replace(',', '&#44;');
            });
        }

        const advfilterArr = [];
        setting.fields.forEach(field => {
            advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, value, 'adhoc'));
        });
        return this._advFilterHelperService.joinFilter(advfilterArr, 'or', false);
    }

    generateAdvFilterForNoteType(setting: FiltersConfig) {
        if (setting.value === ALL_VALUE) {
            return null;
        }
        let operator = '';
        if (setting.selectedOperator === 'includes') {
            operator = 'belongsto';
        } else if (setting.selectedOperator === 'excludes') {
            operator = 'noneof';
        }
        return this._advFilterHelperService.generateFilterStr(setting.fields[0], operator, setting.value, 'standard');
    }

    generateAdvFilterForPriority(setting: FiltersConfig): string {
        let operator = '';
        let value = setting.value;
        let advfilterStr = '';
        if (setting.selectedOperator === 'greater') {
            operator = 'greater-than';
        } else if (setting.selectedOperator === 'less') {
            operator = 'less-than';
        } else if (setting.selectedOperator === 'greater_or_equal') {
            operator = 'greater-than';
            value--;
        } else if (setting.selectedOperator === 'less_or_equal') {
            operator = 'less-than';
            value++;
        } else if (setting.selectedOperator === 'not_equal') {
            operator = 'equals';
        } else {
            operator = setting.selectedOperator;
        }

        advfilterStr = setting.fields[0] + ' ' + operator + ' "' + value + '"';
        if (setting.selectedOperator === 'not_equal') {
            advfilterStr = '! ' + advfilterStr;
        }
        return '(' + advfilterStr + ')';
    }

    generateAdvFilterForSentiment(setting: FiltersConfig): string {
        let advfilterStr = '';
        const advfilterArr = [];
        if (setting.selectedOperator === 'includes') {
            setting.value.forEach(val => {
                advfilterArr.push(this._advFilterHelperService.generateFilterStr(setting.fields[0], 'equals', val, 'standard'));
            });
            advfilterStr = this._advFilterHelperService.joinFilter(advfilterArr, 'or', false);
        } else {
            setting.value.forEach(val => {
                advfilterArr.push('(! ' + setting.fields[0] + ' equals "' + val + '")');
            });
            advfilterStr = this._advFilterHelperService.joinFilter(advfilterArr, 'and', false);
        }
        return advfilterStr;
    }

    generateAdvFilterForSourceSubmitter(setting: FiltersConfig) {
        let operator = '';
        if (setting.selectedOperator === 'includes') {
            operator = 'belongsto';
        } else if (setting.selectedOperator === 'excludes') {
            operator = 'noneof';
        }
        const value: Array<string> = [];
        if (setting.value) {
            setting.value.forEach(entityBrief => {
                value.push(entityBrief.id);
            });
        }

        return this._advFilterHelperService.generateFilterStr(setting.fields[0], operator, value, 'standard');
    }

    generateAdvFilterForTextBox(setting: FiltersConfig): string {
        let operator;
        if (setting.selectedOperator === 'contains') {
            operator = 'contains';
        } else if (setting.selectedOperator === 'startswith') {
            operator = 'starts-with';
        } else if (setting.selectedOperator === 'endswith') {
            operator = 'ends-with';
        } else {
            operator = setting.selectedOperator;
        }

        // replace double quote character (') in value to Unicode to prevent advfilter error in server
        const value = setting.value.replace('"', '&#34;').replace(',', '&#44;');

        const advfilterArr = [];
        setting.fields.forEach(field => {
            if (field === 'title' || field === 'file name' || field === 'file subject' || field === 'location') {
                advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, value, 'standard'));
            } else {
                advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, value, 'adhoc'));
            }
        });
        return this._advFilterHelperService.joinFilter(advfilterArr, 'or', false);
    }

    generateAdvFilterForFileType(setting: FiltersConfig): string {
        if (setting.value === 'all') {
            return null;
        }
        let operator = '';
        if (setting.selectedOperator === 'includes') {
            operator = 'belongsto';
        } else if (setting.selectedOperator === 'excludes') {
            operator = 'noneof';
        }
        return this._advFilterHelperService.generateFilterStr(setting.fields[0], operator, setting.value, 'standard');

    }
    generateAdvFilterForEntityAdhoc(setting: FiltersConfig): string {
        let operator;
        if (setting.selectedOperator === 'includes') {
            operator = 'anyof';
        } else if (setting.selectedOperator === 'excludes') {
            operator = 'noneof';
        } else if (setting.selectedOperator === 'equals') {
            operator = 'array-equals';
        } else {
            operator = setting.selectedOperator;
        }

        const advfilterArr = [];
        setting.fields.forEach((field) => {
            advfilterArr.push(this._advFilterHelperService.generateFilterStr(field, operator, setting.value.map(item => item.id), 'adhoc'));
        });
        return this._advFilterHelperService.joinFilter(advfilterArr, 'or', false);

    }
    generateAdvFilterForTeam(setting: FiltersConfig): string {
        const value = setting.value.map(item => item.id);
        switch (setting.selectedOperator) {
            case 'submittedby':
                return this._advFilterHelperService.generateFilterStr('submitter id', 'in-team', value, 'standard');
            case 'notsubmittedby':
                return this._advFilterHelperService.generateFilterStr('submitter id', 'not-in-team', value, 'standard');
            case 'sourcedto':
                return this._advFilterHelperService.generateFilterStr('source id', 'in-team', value, 'standard');
            case 'notsourcedto':
                return this._advFilterHelperService.generateFilterStr('source id', 'not-in-team', value, 'standard');
            default:
                break;
        }
    }
    getById(id) {
        const url = AppConfig.filterconfigEndpoint + id;
        return this._transportService.get(url);
    }
    // research screen filter do not need type, it has own advfilterArr in function reloadThreadListEffect.
    parseFilterSettingToAdvFilter(settings: Array<FiltersConfig>, filterLogic: string,
        timeZone: number, type?: 'FILES' | 'NOTES', repalceCurrentUserId?) {
        let advfilterArr = [];
        if (!timeZone) {
            timeZone = -new Date().getTimezoneOffset();
        }
        if (type === 'FILES') {
            advfilterArr = [AppConfig.defaultAdvFileFilter];
        } else if (type === 'NOTES') {
            advfilterArr = [AppConfig.defaultAdvFilter];
        }

        settings.forEach(setting => {
            let advfilterStr = '';
            switch (setting.metadataType) {
                case FilterMetadataType.FileName:
                case FilterMetadataType.TextBox:
                case FilterMetadataType.Subject:
                case FilterMetadataType.Location:
                    advfilterStr = this.generateAdvFilterForTextBox(setting);
                    break;
                case FilterMetadataType.NoteType:
                    advfilterStr = this.generateAdvFilterForNoteType(setting);
                    break;
                case FilterMetadataType.EntityType:
                    advfilterStr = this.generateAdvFilterForEntityType(setting);
                    break;
                case FilterMetadataType.FileType:
                    advfilterStr = this.generateAdvFilterForFileType(setting);
                    break;
                case FilterMetadataType.Source:
                case FilterMetadataType.Submitter:
                case FilterMetadataType.Participants:
                    if (repalceCurrentUserId) {
                        // must use cloneDeep, not change settings
                        const tempSetting = _.cloneDeep(setting);
                        tempSetting.value.forEach((item) => {
                            if (item.id === CURRENT_USER.id) {
                                item.id = repalceCurrentUserId;
                            }
                        });
                        advfilterStr = this.generateAdvFilterForSourceSubmitter(tempSetting);
                    } else {
                        advfilterStr = this.generateAdvFilterForSourceSubmitter(setting);
                    }
                    break;
                case FilterMetadataType.ED:
                case FilterMetadataType.MED:
                case FilterMetadataType.MET:
                    advfilterStr = this.generateAdvFilterForEntityAdhoc(setting);
                    break;
                case FilterMetadataType.Dropdown:
                case FilterMetadataType.CheckBox:
                case FilterMetadataType.MSD:
                    advfilterStr = this.generateAdvFilterForDropdown(setting);
                    break;
                case FilterMetadataType.DisplayDate:
                case FilterMetadataType.SubmittedDate:
                case FilterMetadataType.LastModifiedDate:
                case FilterMetadataType.Date:
                case FilterMetadataType.StartTime:
                case FilterMetadataType.EndTime:
                    advfilterStr = this.generateAdvFilterForDate(setting, timeZone);
                    break;
                case FilterMetadataType.Priority:
                    advfilterStr = this.generateAdvFilterForPriority(setting);
                    break;
                case FilterMetadataType.Entities:
                    advfilterStr = this.generateAdvFilterForEntities(setting);
                    break;
                case FilterMetadataType.Sentiment:
                    advfilterStr = this.generateAdvFilterForSentiment(setting);
                    break;
                case FilterMetadataType.Team:
                    advfilterStr = this.generateAdvFilterForTeam(setting);
                    break;
                default:
                    break;
            }
            if (advfilterStr) {
                advfilterArr.push(advfilterStr);
            }
        });
        // the default value of “Show if All of the following filters match" is "All" and or
        const filterLogicStr = filterLogic === ALL_VALUE ? 'and' : 'or';
        return this._advFilterHelperService.joinFilter(advfilterArr, filterLogicStr, false);
    }

    /**
     * parses the FiltersConfig.value from [id1,id2] to [id:'xx', shortName:'xx'] for store usage
     * this is for Entities only.
     * param {Array<FiltersConfig>} filterList
     * returns {Array<FiltersConfig>}
     * memberof FilterService
     */
    mapFilterListValueFromIdsToIdNamePair(filterList: Array<FiltersConfig>): Array<FiltersConfig> {
        filterList.forEach(filterItem => {
            if (filterItem.metadataType === FilterMetadataType.Entities && filterItem.value && filterItem.value.length > 0) {
                const idNameList = new Array<EntityBrief>();
                for (const key in filterItem.value) {
                    if (filterItem.value.hasOwnProperty(key)) {
                        const eb = new EntityBrief(key, '');
                        eb.shortName = '';
                        idNameList.push(eb);
                    }
                }
                filterItem.value = idNameList;
            }
        });
        return filterList;
    }
}

