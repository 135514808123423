import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { LicenseManager } from 'ag-grid-enterprise';
import { AddInUtilities } from './app/services/utilities/addin-utilities';

LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-057426}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Advent_Software,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Data_Services}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Data_Services}_need_to_be_licensed___{Data_Services}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{27_April_2025}____[v3]_[01]_MTc0NTcwODQwMDAwMA==bb538f6b01c90e48aafabe97f0a2e252');

if (environment.production) {
    enableProdMode();
    if (!environment.isOfficeAddin) {
        // keep console logs from surfacing on Tamale Web but enabling it for Add-in for debug purpose.
        window.console.log = () => { };
    }
}

if (environment.isOfficeAddin && Office) {
    Office.initialize = reason => {
        Office.onReady()
            .then((info: { host: Office.HostType; platform: Office.PlatformType }) => {
                platformBrowserDynamic().bootstrapModule(AppModule)
                    .catch(err => console.error(err));
            })
            .catch(res => {
                console.log(res);
            });
    };
} else {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
}
