/**
 * Created by Marcus Zhao on 01/23/2019.
 *  Description:NoteDialogOpenOptions
 *
 * ------ maintenance history ------
 */

import { BlastEmail } from '../../tamalelibs/models/blastemail.model';
import { IntralinksFile } from '../../tamalelibs/models/intralinks.model';
import { OfficeAddIn } from '../../tamalelibs/models/office-addin.model';

export class NoteDialogOpenOptions {
    openType: NoteDialogType;
    minimizable: boolean;
    entryId: string;
    entityFieldEditable: boolean;
    entity: any;
    blastEmail?: BlastEmail;
    eventKey?: string;
    eventValue?: string;
    intralinks?: IntralinksFile[];
    officeAddIn?: OfficeAddIn;

    // For EntryForm, The plugin Must pass it as True for office plugin, adobe,IE ect.
    isPlugInEditNoteMode?: boolean;
    isPopupEntryMode?: boolean;
    isOfficeAddinMode?: boolean;
    attachments?: any[];
    entryTypeId?: string;
    isTransformByCalendar?: boolean;
    isSwitchFromCalendar?: boolean;
}

export enum NoteDialogType {
    CreateNote = 'New Note',
    EditNote = 'Edit Note',
    AddSideNote = 'Add Sidenote',
    EditSideNote = 'Edit Sidenote',
    EditDraft = 'Edit Draft',
    CreateOrEditNote = 'Create Or Edit Note',
    CreateAddinNote = 'Create Add-in Note',
}

export enum SidenoteTypeFollowRule {
    FirstNote = 'First note',
    LatestNote = 'Latest note/sidenote'
}

export enum MailBoxItemType {
    /**
     * An email, meeting request, meeting response, or meeting cancellation.
     */
    Message = 'message',
    /**
     * An appointment item.
     */
    Appointment = 'appointment',
    /**
     * An event item.
     */
    Event = 'event'
}

export enum MailBoxItemSource {
    Addin = 'addin'
}

/**
 * requirement set for office add-in
 * https://learn.microsoft.com/en-us/javascript/api/requirement-sets/outlook/requirement-set-1.8/outlook-requirement-set-1.8?view=excel-js-preview
 */
export enum requirementSetVersion {
    version1_8 = '1.8',
}
