/**
 * Created by Alex Xia on 08/1/2023.
 * Description:
 * Add/Edit/Copy/Delete functions for Notification
 * ------ maintenance history ------
 * update by Alex 9/13/2023 remove source data returned to improve performance.
 * Updated by Daniel 12/10/2023 Added entry action(Create/Edit/Delete) option.
 * Updated by Daniel Wang 1/2/2024 Migrate existing desktop notification settings To Reminder.
 * Updated by Daniel Wang 5/6/2024 Refactor the code to improve performance.
 */

import { AppState } from '../../../../../redux';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _lodash from 'lodash';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';

import { AdhocService } from '../../../../../tamalelibs/services/adhoc.service';
import { businessConstants as webBusinessConstants } from '../../../../../../app/constants/business.constants';
import { CreateNotifications, NotificationActionTypes, EditNotifications } from '../../../../../redux/actions/notification.actions';
import { EmailDigestService } from '../../../../../services/email-digest.service';
import { FilterPanelActions, FilterPanelConfig, FilterPanelEvents } from '../../../../../widgets/filter-item/filter-panel/filter-panel.view-model';
import { filter, take } from 'rxjs/operators';
import { FilterMetadataType, FiltersConfig } from '../../../../../tamalelibs/components/filter-config';
import { FieldConfig, FieldConfigData, FieldEventType, RecipientsFilterItemsEvent, FieldActionType, StandardFilterItemsNotificationRule } from '../../../../../widgets/field-selector/field-selector.model';
import { lastCustomActionSelector } from '../../../../../redux/reducers/action-history.reducer';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from '../../../../../widgets/multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { MetadataType, NotificationFilterContent, NotificationModel, ReminderType, NotificationFilterModel, NotificationConfigModel, SpecificActionType, TriggerModel, StopCriteriaModel, MessageContextType } from '../../../../../tamalelibs/models/notification.model';
import { MapFilterService } from '../../../../../tamalelibs/services/map-filter.service';
import { STANDARD } from '../../../../tam-dashboard/tam-dashboard-map-tile/tam-dashboard-map-nearby/tam-dashboard-map-nearby.component';
import { SingleSelectDropdownFilterConfig } from '../../../../../widgets/single-select-dropdown-filter/single-select-dropdown-filter.model';
import { StoreQuerierService } from '../../../../../services/store-querier.service';
import { StringLiteralsPipe } from '../../../../../pipes/translate.pipe';
import { SlideSheetService } from '../../../../../services/slide-sheet.service';
import { SlideSheetActionTypes } from '../../../../slide-sheet/slide-sheet.model';
import { TimeZone } from '../../../../../tamalelibs/models/emaildigest.model';
import { ArrayHelperService } from '../../../../../tamalelibs/services/array-helper.service';
import { DateHelperService } from '../../../../../tamalelibs/services/date-helper.service';
import { ContactList } from '../../../../../tamalelibs/models/entity-list.model';
import { FilterService } from '../../../../../tamalelibs/services/filter.service';
import { contactsSelector } from '../../../../../redux/reducers/system.reducer';
import { AlertWindowService } from '../../../../../widgets/alert-window/alert-window.service';

import { ALL_NAME, API_SUBSCRIPTION_ID, CriteriaData, DayTypeData, EMAIL_ID, EMAIL_NAME, EntryActionData, EntryDetailAttachmentsData, FrequencyData, GENERATESIDENOTE_ID, GENERATESIDENOTE_NAME, IncludeAttachments, IncludeEntityListInSubjectDropdownConfigData, IncludeEntryDetails, IncludeLinkToAttachments, MessageContentData, SelectedCriteria, SelectedDateType, SelectedFrequency, SendTypeData, TypeDropdownData } from './notification-editor.model';
import { NOTIFICATION_NAME, PrivacyData, SELECTDATACONTROL, SELECTENTRYACTIONCONTROL, SENDASGROUPEMAIL, SelectedEntityListInSubject, SelectedMessageContent, SelectedPrivacy, VIEWTHREAD_ID, VIEWTHREAD_NAME, DateTypeAdhocBasicData, DefaultTimeToSendNotification } from './notification-editor.model';
import { adhocTitleSelector } from '../../../../../redux/reducers/adhoc.reducer';
import { NotificationEditorService } from './notification-editor.service';
import { NotificationService } from '../../../../../tamalelibs/services/notification.service';

@Component({
    selector: 'notification-editor',
    templateUrl: './notification-editor.component.html',
    styleUrls: ['./notification-editor.component.scss']
})
export class NotificationEditorComponent implements OnInit, OnDestroy {
    @Input() notificationData?: any;

    // #region public parameters
    // card action dropdown
    actionsDropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    actionsDropdownConfigData: Array<{ id: string, name: string }> = [];
    actionTypeName: string = GENERATESIDENOTE_NAME;
    actionTypeIds = GENERATESIDENOTE_ID;

    // content to be included dropdown
    contentData: Array<{ value: MessageContextType, text: string }> = MessageContentData;
    selectedContent = SelectedMessageContent;
    // for custom field of reciepients
    contactList: ContactList;
    criteriaList: Array<{ text: string, value: number }> = [];
    currNotification: NotificationModel;
    // for select date control
    dateType = SELECTDATACONTROL;
    dateTypeId = '';
    dateTypeAdHocConfig: SingleSelectDropdownFilterConfig = new SingleSelectDropdownFilterConfig(true);
    dayTypeList: Array<{ text: string, value: string }> = [];

    entryAction = SELECTENTRYACTIONCONTROL;
    entryActionId: Array<string> = [];
    entryActionConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    entryActionData: Array<MultiSelDropdownConfig> = EntryActionData;
    // entry detail and attachments dropdown
    entryDetailAttachmentsConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    entryDetailAttachmentsData: Array<{ id: string, name: string }> = EntryDetailAttachmentsData;
    entryDetailAttachmentsNames: string;
    entryDetailAttachmentsIds: string;

    filterRulesConfig: FilterPanelConfig;
    filterRecipientsConfig: FilterPanelConfig;
    fieldRulesConfig: FieldConfig;
    fieldRecipientsConfig: FieldConfig;
    frequencyList: Array<{ text: string, value: string }> = [];
    formErrorMsg = {
        descriptionExceedMaxLength: false,
        nameDuplicated: false,
        isNameRequired: false,
        nameExceedMaxLength: false
    };
    // include entity list in subject dropdown
    includeEntityListInSubjectDropdownConfigData: Array<{ value: string, text: string }> = IncludeEntityListInSubjectDropdownConfigData;
    selectedEntityListInSubject = SelectedEntityListInSubject;

    isActionTypeChecked = true;
    isDateControlSelected = true;
    isEntryActionControlSelected = true;
    isEventTypeUnChecked = false;
    isIncludeSubmitterInBCC = false;
    isProgressing = false;
    isRecipientsExist = true;
    isTypeChecked = true;
    isSpecificEntryActionTriggered = false;
    isAPISubscriptionSelected = false;
    isUseSubmitterSecureEmailAddress = false;
    // for spinner
    loading = true;
    name = new FormControl('');
    privacyData: Array<{ value: string, text: string }> = PrivacyData;
    selectedPrivacy = SelectedPrivacy;
    recipientListData: Array<{ id: string, name: string }> = [];
    selectTime = DefaultTimeToSendNotification; // default time to send notification
    selectedTimezone: TimeZone;
    selectedCriteria = SelectedCriteria;
    selectedFrequency = SelectedFrequency;
    selectedDateType = SelectedDateType;
    sendTypeList: Array<{ text: string, value: boolean }> = [];
    selectedSendType = {
        text: SENDASGROUPEMAIL,
        value: true
    };
    specificActionType = SpecificActionType.ENTRY;
    title = StringLiteralsPipe.translate('notification.new_notification_rule');
    typeDropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    typeDropdownConfigData: Array<{ id: string, name: string }> = [];
    typeName: string = EMAIL_NAME;
    typeIds = EMAIL_ID;
    timezoneList: Array<TimeZone>;
    isChanged = false;
    // #endregion

    // #region private properties
    private _destroySubscriptions: Array<Subscription> = [];
    private _dropdownSource: Array<any> = [];
    // used for check if duplicated name
    private _isLoading = false;
    private _metadataFilterModelForEvent = new NotificationFilterModel();
    // #endregion

    // #region constructor
    constructor(
        private _alertWindow: AlertWindowService,
        private _adhocService: AdhocService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _dateHelperService: DateHelperService,
        private _emailDigestService: EmailDigestService,
        private _filterService: FilterService,
        private _mapFilterService: MapFilterService,
        private _notificationEditorService: NotificationEditorService,
        private _notificationService: NotificationService,
        private _slideSheetService: SlideSheetService,
        private _store: Store<AppState>,
        private _storeQuerier: StoreQuerierService,
    ) { }
    // #endregion

    //#region angular functions
    //#region ngOnInit() method to handle any additional initialization tasks.
    ngOnInit() {
        // to load adhoc info from cache
        this._store.pipe(
            select(adhocTitleSelector),
            take(1),
        ).subscribe(data => {
            if (data && data.size > 0) {
                data.forEach((item) => {
                    this._dropdownSource.push(item);
                });
            }
        });
        // subscribe the common actions
        this._destroySubscriptions.push(
            this._store.pipe(
                select(contactsSelector),
                filter(data => data),
            ).subscribe(result => {
                this.contactList = result;
            }),
            this._store.pipe(
                select(lastCustomActionSelector)
            ).subscribe(action => {
                this._onSubscribeActionMessage(action);
            }),
        );
        // Initialize controls in order, then assign the value for the controls in edit mode
        this._initContorls();
    }
    //#endregion

    //#region ngOnDestroy used to unsubscribe the subscribes
    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription?.unsubscribe());
        this._destroySubscriptions = [];
    }
    //#endregion
    //#endregion

    //#region public functions
    actionValueChangeSource(data): void {
        if (data.length === 0) {
            this.actionTypeIds = this.actionTypeName = null;
        } else if (data === ALL_NAME.toLowerCase()) {
            this.actionTypeIds = this.actionTypeName = '';

            this.actionsDropdownConfigData.forEach(item => {
                this.actionTypeName += item.name + ',';
                this.actionTypeIds += item.id + ',';
            });
            this.actionTypeName = this.actionTypeName.substring(0, this.actionTypeName.length - 1);
            this.actionTypeIds = this.actionTypeIds.substring(0, this.actionTypeIds.length - 1);
        } else {
            this.actionTypeName = data.map(item => item.name).join(',');
            this.actionTypeIds = data.map(item => item.id).join(',');
        }
    }

    // the control is changed if it's triggered
    configChanged() {
        this.isChanged = true;
    }

    /**
    * input  endTime  for trigger timeEndClick
    * @param value
    */
    endTimeBlur(value) {
        if (this._dateHelperService.judgeTimeFormat(value)) {
            this.timeEndClick(value);
        }
    }

    /**
     * the value change of 'Entry detail and attachments'
     * @param data
     */
    entryDetailAttachmentsValueChange(data): void {
        if (data.length === 0) {
            this.entryDetailAttachmentsNames = this.entryDetailAttachmentsIds = '';
        } else if (data === ALL_NAME.toLowerCase()) {
            this.entryDetailAttachmentsNames = this.entryDetailAttachmentsIds = '';

            this.entryDetailAttachmentsData.forEach(item => {
                this.entryDetailAttachmentsIds += item.id + ',';
                this.entryDetailAttachmentsNames += item.name + ',';
            });
            this.entryDetailAttachmentsNames = this.entryDetailAttachmentsNames.substring(0, this.entryDetailAttachmentsNames.length - 1);
            this.entryDetailAttachmentsIds = this.entryDetailAttachmentsIds.substring(0, this.entryDetailAttachmentsIds.length - 1);
        } else {
            this.entryDetailAttachmentsNames = data.map(item => item.name).join(',');
            this.entryDetailAttachmentsIds = data.map(item => item.id).join(',');
        }
    }

    /**
     * The empty check error should be cleard when inputting some charactors
     */
    inputNameValueChange() {
        this.formErrorMsg.nameDuplicated = false;
        if (this.currNotification[NOTIFICATION_NAME] && this.currNotification[NOTIFICATION_NAME].length > 0) {
            this.formErrorMsg.isNameRequired = false;

            if (this.currNotification[NOTIFICATION_NAME].length > 128) {
                this.formErrorMsg.nameExceedMaxLength = true;
            } else {
                this.formErrorMsg.nameExceedMaxLength = false;
            }
        } else {
            this.formErrorMsg.isNameRequired = true;
        }
    }

    onSlideSheetClose() {
        if (this.isChanged) {
            const message = `Are you sure you want to discard all your changes?`;
            const subscription = this._alertWindow.warn('You have unsaved changes',
                [message], StringLiteralsPipe.translate('general.discard'), StringLiteralsPipe.translate('general.go_back'))
                .subscribe((result: boolean) => {
                    if (result) {
                        this._slideSheetService.slideSheetActionSubject$.next({
                            type: SlideSheetActionTypes.CLOSE
                        });
                    }
                    subscription.unsubscribe();
                });
        } else {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE
            });
        }
    }

    onSpecificTimeChange(value: any) {
        this.selectTime = value;
    }

    onSubmitClick() {
        this._isLoading = false;
        let isValidInputs = true;
        // check type of notification
        this._checkTypeOfNotification();
        // check recipients
        this._handleRecipientsErrorMessage();
        // check event filter
        if (this.filterRulesConfig.filtersConfigList
            && this.filterRulesConfig.filtersConfigList.length > 0
            && this.filterRulesConfig.filtersConfigList[0].value
            && this.filterRulesConfig.filtersConfigList[0].value.length > 0) {
            this.isEventTypeUnChecked = false;
        } else {
            this.isEventTypeUnChecked = true;
        }
        if (this.specificActionType === SpecificActionType.TIME) {
            // check date type
            this.isDateControlSelected = !this.dateType || !(this.dateType === SELECTDATACONTROL);
        } else {
            this.isEntryActionControlSelected = !(this.entryAction === SELECTENTRYACTIONCONTROL);
        }
        // check name
        if (!this.name.value) {
            this.formErrorMsg.isNameRequired = true;
        } else if (this.name.value.length > 128) {
            this.formErrorMsg.nameExceedMaxLength = true;
        }
        isValidInputs = this._checkTypeOfNotification() && this._handleRecipientsErrorMessage() && !this.isEventTypeUnChecked && this.isDateControlSelected && this.isEntryActionControlSelected && !this.formErrorMsg.isNameRequired && !this.formErrorMsg.nameExceedMaxLength;

        if (isValidInputs) {
            // check whether notification name is duplicated or not
            this._notificationService.checkNotificationName(this.currNotification.id, this.currNotification.name).pipe(
                take(1)
            ).subscribe(res => {
                if (res) {
                    this.isProgressing = true;
                    this.currNotification.active = true;
                    this.currNotification.description = this.currNotification.description || '';

                    this._setNoChangeFilterData();
                    this.currNotification.configuration = new NotificationConfigModel();

                    if (this.typeIds) {
                        this.currNotification.configuration.actionType = this.typeIds.split(',');
                    } else {
                        this.currNotification.configuration.actionType = [];
                    }

                    if (this.actionTypeIds) {
                        this.currNotification.configuration.cardOption.action = this.actionTypeIds.split(',');
                    } else {
                        this.currNotification.configuration.cardOption.action = [];
                    }
                    this.currNotification.configuration.cardOption.messageContextType = this.selectedContent.value;
                    this.currNotification.configuration.cardOption.entityLocationInSubject = this.selectedEntityListInSubject.value;
                    if (this.entryDetailAttachmentsIds) {
                        if (this.entryDetailAttachmentsIds.indexOf(IncludeEntryDetails) > -1) {
                            this.currNotification.configuration.cardOption.includeEntryDetailTable = true;
                        }
                        if (this.entryDetailAttachmentsIds.indexOf(IncludeAttachments) > -1) {
                            this.currNotification.configuration.cardOption.includeWebAttachment = true;
                        }
                        if (this.entryDetailAttachmentsIds.indexOf(IncludeLinkToAttachments) > -1) {
                            this.currNotification.configuration.cardOption.includeWebAttachementLink = true;
                        }
                    }

                    this.currNotification.configuration.metadataFilter = this._metadataFilterModelForEvent;

                    if (this.specificActionType === SpecificActionType.TIME) {
                        this.currNotification.reminderType = ReminderType.SCHEDULED;
                        this.currNotification.metadataType = MetadataType.EVENT;

                        this.currNotification.configuration.trigger = new TriggerModel();
                        this.currNotification.configuration.trigger.scheduledStart.number = 1;
                        this.currNotification.configuration.trigger.scheduledStart.unit = this.selectedDateType.value;
                        this.currNotification.configuration.trigger.scheduledStart.operator = 'after';
                        this.currNotification.configuration.trigger.scheduledStart.attDefId = this.dateTypeId;
                        this.currNotification.configuration.trigger.scheduledStart.time = this.selectTime;

                        this.currNotification.configuration.trigger.triggerPeriod.every = 1;
                        this.currNotification.configuration.trigger.triggerPeriod.frequency = this.selectedFrequency.value;
                        this.currNotification.configuration.trigger.timezone = this.selectedTimezone.zonename;

                        this.currNotification.configuration.stopCriteria = new StopCriteriaModel();
                        this.currNotification.configuration.numberLimit = this.selectedCriteria?.value;
                    } else {
                        this.currNotification.reminderType = ReminderType.REALTIME;
                        const restrictList = this.filterRulesConfig.filtersConfigList[0]?.typeValue;
                        if (restrictList === 'eventTypes') {
                            this.currNotification.metadataType = MetadataType.EVENT;
                        } else if (restrictList === 'entryTypes') {
                            this.currNotification.metadataType = MetadataType.ENTRY;
                        } else {
                            this.currNotification.metadataType = MetadataType.NOTE;
                        }

                        this.currNotification.configuration.changeType = this.entryActionId;
                    }
                    this.currNotification.configuration.emailActionDetail.groupMail = this.selectedSendType.value;
                    this.currNotification.configuration.emailActionDetail.submitterBCC = this.isIncludeSubmitterInBCC;
                    this.currNotification.configuration.emailActionDetail.secureEmail = this.isUseSubmitterSecureEmailAddress;
                    this.currNotification.configuration.overridePrivacy = this.selectedPrivacy.value === 'override';

                    // set value for recipients
                    this._notificationEditorService.setValueForRecipients(this.filterRecipientsConfig, this.currNotification);
                    this.notificationData = this.currNotification;
                    if (this.title === StringLiteralsPipe.translate('notification.new_notification_rule')) {
                        this._store.dispatch(new CreateNotifications({
                            name: this.currNotification[NOTIFICATION_NAME],
                            data: this.currNotification,
                            successMessage: `${this.currNotification[NOTIFICATION_NAME]} is created successfully.`
                        }));
                    } else if (this.currNotification.isCopy) {
                        this._store.dispatch(new CreateNotifications({
                            name: this.currNotification[NOTIFICATION_NAME],
                            data: this.currNotification,
                            successMessage: `${this.currNotification[NOTIFICATION_NAME]} is created successfully.`
                        }));
                    } else {
                        this._store.dispatch(new EditNotifications({
                            name: this.currNotification[NOTIFICATION_NAME],
                            data: this.currNotification,
                            successMessage: `${this.currNotification[NOTIFICATION_NAME]} is updated successfully.`
                        }));
                    }
                    this.isChanged = false;
                } else {
                    this.formErrorMsg.nameDuplicated = true;
                    this.isProgressing = false;
                }
            });
        }
    }

    onActionClickType(event) {
        this.actionsDropdownConfig.open$.next(event.target);
    }

    /**
     * Event to trigger include submitter in bbc
     * @param event
     */
    onChangeIncludeSubmitterInBCC(event) {
        this.isIncludeSubmitterInBCC = event;
    }

    onChangeTimezone(value: TimeZone) {
        this.selectedTimezone = value;
    }

    onClickType(event) {
        this.typeDropdownConfig.open$.next(event.target);
    }

    onEntryDetailAttachmentsClick(event) {
        this.entryDetailAttachmentsConfig.open$.next(event.target);
    }

    onSelectAdHocDateTypeClick(event) {
        event.target.type = 'refresh';
        this.dateTypeAdHocConfig.open$.next(event.target);
    }

    onSelectEntryActionClick(event) {
        this.entryActionConfig.open$.next(event.target);
    }

    onSpecificActionTypeChange() {
        if (this.filterRulesConfig &&
            this.filterRulesConfig.filtersConfigList &&
            this.filterRulesConfig.filtersConfigList.length > 0) {
            let entryTypeFilterItem = new FiltersConfig();

            this.filterRulesConfig.filtersConfigList.forEach(filterItem => {
                if (filterItem.metadataType.toLowerCase() === FilterMetadataType.NoteType.toLowerCase() ||
                    filterItem.metadataType.toLowerCase() === FilterMetadataType.EntryType.toLowerCase() ||
                    filterItem.metadataType.toLowerCase() === FilterMetadataType.EventType.toLowerCase()) {
                    filterItem.value = [];
                    entryTypeFilterItem = filterItem;
                }
            });
            this.filterRulesConfig.filtersConfigList = [].concat(entryTypeFilterItem);
            this.filterRulesConfig.action$.next({ type: FilterPanelActions.reloadFilter, payload: this.filterRulesConfig.filtersConfigList });
        }

        if (this.specificActionType === SpecificActionType.TIME) {
            this.isEntryActionControlSelected = true;
            this.isSpecificEntryActionTriggered = false;
            this._refreshEventAndCustomFields('eventTypes', '');
        } else {
            this.isDateControlSelected = true;
            this.isSpecificEntryActionTriggered = true;
            this._refreshEventAndCustomFields('entryTypes', '');
        }

        this.filterRulesConfig?.action$.next({ type: FilterPanelActions.restrictFilter, payload: this.specificActionType });
    }

    /**
     * Event to trigger user submitter secure email address
     * @param event
     */
    onChangeUseSubmitterSecureEmailAddress(event) {
        this.isUseSubmitterSecureEmailAddress = event;
    }

    timeEndClick(value) {
        this.selectTime = value;
    }

    validateDescription(value: string) {
        this.formErrorMsg.descriptionExceedMaxLength = value && value.length > 1024;
    }

    valueChangeSource(data): void {
        if (data.length === 0) {
            this.typeIds = this.typeName = null;
        } else if (data === ALL_NAME.toLowerCase()) {
            this.typeIds = this.typeName = '';

            this.typeDropdownConfigData.forEach(item => {
                this.typeName += item.name + ', ';
                this.typeIds += item.id + ',';
            });
            this.typeName = this.typeName.substring(0, this.typeName.length - 2);
            this.typeIds = this.typeIds.substring(0, this.typeIds.length - 1);
        } else {
            this.typeName = data.map(item => item.name).join(', ');
            this.typeIds = data.map(item => item.id).join(',');
        }
        if (this.typeIds?.includes(API_SUBSCRIPTION_ID)) {
            this.isAPISubscriptionSelected = true;
        } else {
            this.isAPISubscriptionSelected = false;
        }
        this._checkTypeOfNotification();
    }
    //#endregion

    //#region private functions
    private _checkTypeOfNotification(): Boolean {
        if (!this.typeName) {
            this.isTypeChecked = false;
            return false;
        } else {
            this.isTypeChecked = true;
            return true;
        }
    }

    /**
     * set title and notification type
     */
    private _editorInit() {
        this._isLoading = true;
        this.formErrorMsg.nameDuplicated = false;
        if (!this.notificationData) {
            this.title = StringLiteralsPipe.translate('notification.new_notification_rule');
            this.currNotification = new NotificationModel();
        } else if (this.notificationData.isCopy) {
            this.title = StringLiteralsPipe.translate('notification.new_notification_rule');
            this.currNotification = _lodash.cloneDeep(this.notificationData);
            this.currNotification.name = 'Copy of ' + this.currNotification.name;
        } else {
            this.title = StringLiteralsPipe.translate('notification.edit_notification_rule');
            this.currNotification = _lodash.cloneDeep(this.notificationData);
        }
        this._setNotificationType();
        this._setNotificationActionType();
        this._setNotificationEntryDetailAttachments(this.currNotification);
        this.selectedContent.value = this.currNotification?.configuration?.cardOption?.messageContextType;
        this.selectedEntityListInSubject.value = this.currNotification?.configuration?.cardOption?.entityLocationInSubject;
        this.selectedPrivacy.value = this.currNotification.configuration?.overridePrivacy ? 'override' : 'enforce';

        if (this.notificationData) {
            if (this.currNotification.reminderType === ReminderType.REALTIME) {
                this.specificActionType = SpecificActionType.ENTRY;
                if (this.currNotification.configuration.changeType) {
                    this.entryAction = '';
                    this.entryActionConfig.data.forEach(item => {
                        if (this.currNotification.configuration.changeType.includes(item.id)) {
                            this.entryAction = this.entryAction + item.name + ',';
                            this.entryActionId.push(item.id);
                            item.checked = true;
                        }
                    });
                    this.entryAction = this.entryAction.substring(0, this.entryAction.length - 1);
                }
            } else {
                this.specificActionType = SpecificActionType.TIME;
            }
            this.isSpecificEntryActionTriggered = this.specificActionType === SpecificActionType.ENTRY;
        }
    }

    /**
     * set new values for field events, date control and recipicents after change values in event list
     */
    private _filterConfigSubjectForEvent(): void {
        this._destroySubscriptions.push(
            this.filterRulesConfig.events$.pipe(
                filter((data) => data.type === FilterPanelEvents.change || data.type === FilterPanelEvents.delete)
            ).subscribe((data) => {
                this.filterRulesConfig.filtersConfigList = [].concat(data.payload);
                this._formatFilterEventAdhocData();
                if (data?.changedItem?.metadataType === FilterMetadataType.NoteType ||
                    data?.changedItem?.metadataType === FilterMetadataType.EventType ||
                    data?.changedItem?.metadataType === FilterMetadataType.EntryType) {
                    this._refreshCustomFieldsByEntryTypes(data);
                }
            }),
        );
    }

    /**
     * need to add the option back to fild recipients after delete a control
     */
    private _filterRecipientConfigSubjectForEvent(): void {
        const self = this;
        this._destroySubscriptions.push(
            this.filterRecipientsConfig.events$.pipe(
                filter((data) => data.type === FilterPanelEvents.delete || data.type === FilterPanelEvents.change)
            ).subscribe((data) => {
                this.filterRecipientsConfig.filtersConfigList = data.payload;
                if (data.type === FilterPanelEvents.delete) {
                    const configListAfterDelete = [].concat(data.payload);
                    // show the left items that was not deleted in field selector
                    this.fieldRecipientsConfig.config.standardItem = RecipientsFilterItemsEvent.filter(item => {
                        if (configListAfterDelete.find(ca => ca.metadataType === item.value)) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                    self.fieldRecipientsConfig.actions$.next({ type: FieldActionType.changeRecipientList, payload: this.filterRecipientsConfig.filtersConfigList });
                    this._handleRecipientsErrorMessage();
                } else {
                    this._handleRecipientsErrorMessage();
                }
            }),
        );
    }

    /**
     * load controls when selecting a field
     */
    private _fieldConfigSubjectForEvent(): void {
        this._destroySubscriptions.push(
            // Event
            this.fieldRulesConfig.events$.pipe(
                filter(data => data.type === FieldEventType.select)
            ).subscribe((data) => {
                let filtersConfig;
                if (data.payload.type === STANDARD) {
                    filtersConfig = this._mapFilterService.getNewFilterDefaultInstance(data.payload.text, data.payload.value);
                    if (filtersConfig.metadataType === FilterMetadataType.Entities) {
                        filtersConfig.isHiddenRelationship = true;
                    }
                    if (filtersConfig.metadataType === FilterMetadataType.Participants || filtersConfig.metadataType === FilterMetadataType.Source) {
                        // Only admin can create notification settings, if select "current user" for participant or source to filter events, it doesn't make sense
                        filtersConfig.currentUser = undefined;
                    }
                } else {
                    filtersConfig = this._mapFilterService.getAdhocFilterDefaultInstance(data.payload);
                }
                this.filterRulesConfig.filtersConfigList.push(filtersConfig);
                // to add the select control to _metadataFilterModelForEvent for saving. it will be updated after the value of select control is changed.
                this._formatFilterEventAdhocData();
                this.filterRulesConfig.action$.next({ type: FilterPanelActions.addFilter, payload: [filtersConfig] });
            })
        );
    }

    private _fieldRecipientConfigSubjectForEvent(): void {
        const self = this;
        self._destroySubscriptions.push(
            // Event
            this.fieldRecipientsConfig.events$.pipe(
                filter(data => data.type === FieldEventType.select)
            ).subscribe((data) => {
                let filtersConfig;
                if (data.payload.type === STANDARD) {
                    filtersConfig = self._mapFilterService.getNewFilterDefaultInstance(data.payload.text, data.payload.value, false, false, false, this.recipientListData);

                    if (filtersConfig.metadataType === FilterMetadataType.Entities) {
                        filtersConfig.isHiddenRelationship = true;
                    }
                } else {
                    filtersConfig = self._mapFilterService.getAdhocFilterDefaultInstance(data.payload);
                }
                if (filtersConfig.metadataType === FilterMetadataType.SpecificContacts) {
                    filtersConfig.source = self._getRecipientsContactSource();
                    // remove the selected dropdown item
                    self.fieldRecipientsConfig.config.standardItem = self.fieldRecipientsConfig.config.standardItem.filter((item) => item.text !== filtersConfig.metadataType);
                    self.fieldRecipientsConfig.actions$.next({ type: FieldActionType.changeRecipientList, payload: {} });
                    self.filterRecipientsConfig.action$.next({ type: FilterPanelActions.addFilter, payload: [filtersConfig] });
                } else {
                    // remove the selected dropdown item
                    self.fieldRecipientsConfig.config.standardItem = self.fieldRecipientsConfig.config.standardItem.filter((item) => item.text !== filtersConfig.metadataType);
                    self.fieldRecipientsConfig.actions$.next({ type: FieldActionType.changeRecipientList, payload: {} });
                    self.filterRecipientsConfig.action$.next({ type: FilterPanelActions.addFilter, payload: [filtersConfig] });
                }
                this._handleRecipientsErrorMessage();
            })
        );
    }

    private _formatFilterEventAdhocData(): void {
        const currentUser = this._storeQuerier.getCurrentUser();
        this._metadataFilterModelForEvent.filters[0] = new NotificationFilterContent();
        this._metadataFilterModelForEvent.filters[0].field = webBusinessConstants.common.entry;
        if (this.filterRulesConfig.filtersConfigList.length > 0) {
            this._metadataFilterModelForEvent.filters[0] = this._mapFilterService.getFilters(this.filterRulesConfig.filtersConfigList, this._metadataFilterModelForEvent.filters[0], currentUser);
        }
    }

    private _getRecipientsContactSource() {
        const source = [];
        if (this.contactList && this.contactList.contacts && this.contactList.contacts.length > 0) {
            this.contactList.contacts.forEach(item => {
                if (!item?.isLogin) {
                    source.push({
                        'id': item.id,
                        'name': item.name
                    });
                }
            });
            ArrayHelperService.sort(source, NOTIFICATION_NAME, true);
        }
        return source;
    }

    private _handleRecipientsErrorMessage() {
        // handle error message
        let isRecipientUnchecked = false;
        if (this.filterRecipientsConfig.filtersConfigList && this.filterRecipientsConfig.filtersConfigList.length > 0) {
            this.filterRecipientsConfig.filtersConfigList.forEach(f => {
                if (!f.value || (f.value && f.value.length === 0)) {
                    isRecipientUnchecked = true;
                    return;
                }
            });
            if (!isRecipientUnchecked) {
                this.isRecipientsExist = true;
                return true;
            } else {
                this.isRecipientsExist = false;
                return false;
            }
        } else {
            this.isRecipientsExist = false;
            return false;
        }
    }

    /**
     * Initialize controls in order, then assign the value for the controls in edit mode
     */
    private _initContorls() {
        this._initTypeDropDown();

        this._initSelectDateControl();

        this._initEntryActionControl();

        this._initFilterPanel();

        this._initTimezoneList();
        this._initTimezone();

        this._initActionTypeDropDown();
        this._initCardActionControl();

        this._initTimeTypeModel();
        this._initEntryDetailAttachmentsDropdown();
        // for edit
        this._editorInit();
    }

    private _initFieldSelectorForEvent(result): void {
        // Event
        this.filterRulesConfig = new FilterPanelConfig();
        this.fieldRulesConfig = new FieldConfig();
        this.fieldRulesConfig.config = new FieldConfigData();
        this.fieldRulesConfig.config.standardItem = [];
        StandardFilterItemsNotificationRule.forEach(item => {
            this.fieldRulesConfig.config.standardItem.push(item);
        });
        this.fieldRulesConfig.config.showSearch = true;
        this.fieldRulesConfig.config.showAdhoc = false;
        this.fieldRulesConfig.config.eventAdhoc = result.title;
        this.fieldRulesConfig.fieldIndex = 1;

        this.filterRecipientsConfig = new FilterPanelConfig();
        this.fieldRecipientsConfig = new FieldConfig();
        this.fieldRecipientsConfig.config = new FieldConfigData();
        this.fieldRecipientsConfig.config.standardItem = RecipientsFilterItemsEvent;
        this.fieldRecipientsConfig.config.showSearch = false;
        this.fieldRecipientsConfig.config.showAdhoc = false;
        this.fieldRecipientsConfig.fieldIndex = 2;
    }

    private _initTimeTypeModel(): void {
        this.dayTypeList.push(...DayTypeData);
        this.frequencyList.push(...FrequencyData);
        this.criteriaList.push(...CriteriaData);
        this.sendTypeList.push(...SendTypeData);
    }

    /**
    * used by notification type dropdown control.
    */
    private _initTypeDropDown() {
        this.typeDropdownConfigData.push(...TypeDropdownData);
        this.typeDropdownConfig.hideSearchBox = true;
        this.typeDropdownConfig.hideHeader = true;
        // subscribethe event when init the control
        this._destroySubscriptions.push(
            this.typeDropdownConfig.onChangeValue$.subscribe(data => this.valueChangeSource(data))
        );
    }

    /**
    * used by notification action type dropdown control.
    */
    private _initActionTypeDropDown() {
        this.actionsDropdownConfig.hideSearchBox = true;
        this.actionsDropdownConfig.hideHeader = true;
        this._destroySubscriptions.push(
            this.actionsDropdownConfig.onChangeValue$.subscribe(data => this.actionValueChangeSource(data))
        );
    }

    private _initCardActionControl() {
        this.actionsDropdownConfigData.push({
            id: GENERATESIDENOTE_ID,
            name: GENERATESIDENOTE_NAME
        });
        this.actionsDropdownConfigData.push({
            id: VIEWTHREAD_ID,
            name: VIEWTHREAD_NAME
        });
    }

    private _initEntryDetailAttachmentsDropdown() {
        this.entryDetailAttachmentsConfig.hideSearchBox = true;
        this.entryDetailAttachmentsConfig.hideHeader = true;
        this._destroySubscriptions.push(
            this.entryDetailAttachmentsConfig.onChangeValue$.subscribe(data => this.entryDetailAttachmentsValueChange(data))
        );
    }

    /**
     * used by select date control
     */
    private _initSelectDateControl() {
        this.dateTypeAdHocConfig.data = DateTypeAdhocBasicData;
        this._destroySubscriptions.push(
            this.dateTypeAdHocConfig.onChangeValue$.subscribe(data => this._onAdhocDateTypeChange(data))
        );
    }

    private _initEntryActionControl() {
        this.entryActionConfig.data = _lodash.cloneDeep(this.entryActionData);
        this._destroySubscriptions.push(
            this.entryActionConfig.onChangeValue$.subscribe(data => this._onEntryActionChange(data)),
        );
    }

    private _initFilterPanel(ids = [ALL_NAME]) {
        // Event
        this._adhocService.getAdhocsByTypeIds('entryTypes', ids).pipe(take(1)).subscribe(response => {
            const result = this._adhocService.parseResponse(response);
            this._initFieldSelectorForEvent(result);
            this._fieldConfigSubjectForEvent();
            this._filterConfigSubjectForEvent();
            this._fieldRecipientConfigSubjectForEvent();
            this._filterRecipientConfigSubjectForEvent();
            // set default value for notification rules filter
            this._setDefaultValueForFiltersEdit();
            this.loading = false;
            this._updateChangesToUI();
        });
    }

    private _initTimezone() {
        const scheduler = this._emailDigestService.getDefaultScheduler();
        this.selectedTimezone = this.timezoneList.find(item => item.zonename === scheduler.configuration);
    }

    private _initTimezoneList() {
        this.timezoneList = this._emailDigestService.timezoneList;
    }

    private _onAdhocDateTypeChange(data): void {
        if (data) {
            this.dateType = data.name;
            this.dateTypeId = data.id;
            this.isDateControlSelected = !this.dateType || !(this.dateType === SELECTDATACONTROL);
        }
    }

    private _onEntryActionChange(data): void {
        if (data) {
            if (data.length === 0) {
                this.entryActionId = [];
                this.entryAction = SELECTENTRYACTIONCONTROL;
            } else if (data === ALL_NAME.toLowerCase()) {
                this.entryActionId = [];
                this.entryAction = '';
                this.entryActionData.forEach(item => {
                    this.entryAction += item.name + ',';
                    this.entryActionId.push(item.id);
                });
                this.entryAction = this.entryAction.substring(0, this.entryAction.length - 1);

                this.isEntryActionControlSelected = true;
            } else {
                this.entryAction = data.map(item => item.name).join(',');
                this.entryActionId = data.map(item => item.id);

                this.isEntryActionControlSelected = true;
            }
        }
    }

    private _onSubscribeActionMessage(action) {
        if (action.type === NotificationActionTypes.CREATENOTIFICATIONS_SUCCESS || action.type === NotificationActionTypes.EDITNOTIFICATIONS_SUCCESS) {
            this.onSlideSheetClose();
        } else if (!this._isLoading && action.type === NotificationActionTypes.CREATENOTIFICATIONS_FAILED && action.payload.includes('ERR_DUPLICATED_NAME')) {
            this.formErrorMsg.nameDuplicated = true;
            this.isProgressing = false;
        }
    }

    private _refreshCustomFieldsByEntryTypes(data): void {
        let entryTypeIds = [];
        const entryTypes = data.payload.filter(item => item.dataType === webBusinessConstants.dataType.noteType ||
            item.dataType === webBusinessConstants.dataType.eventType ||
            item.dataType === webBusinessConstants.dataType.entryType);

        if (entryTypes) {
            if (entryTypes.length > 0) {
                if (!Array.isArray(entryTypes[0].value)) {
                    if (entryTypes[0].value?.toLowerCase() === 'all') {
                        entryTypeIds = [ALL_NAME];
                    }
                } else {
                    entryTypeIds = entryTypes[0].value;
                }
            }
        }

        if (entryTypeIds && entryTypeIds.length === 0) {
            this.isEventTypeUnChecked = true;
        } else {
            this.isEventTypeUnChecked = false;
        }
        // get checked types
        let typeValue = data?.payload[0]?.typeValue ? data?.payload[0]?.typeValue : 'entryTypes';
        if (data?.payload[0].selectedOperator === 'Excludes') {
            typeValue = 'exclude' + typeValue.replace(typeValue[0], typeValue[0].toUpperCase());
        }
        this._refreshEventAndCustomFields(typeValue, entryTypeIds);
    }

    private _refreshEventAndCustomFields(typeValue, eventTypeIds) {
        const self = this;
        this._adhocService.getAdhocsByTypeIds(typeValue, eventTypeIds).pipe(take(1)).subscribe(response => {
            const result = this._adhocService.parseResponse(response);
            // set values for field event list
            this.fieldRulesConfig?.actions$.next({ type: FieldActionType.changeEventList, payload: result.title });
            // set values for data control and recipients
            const dateTypeAdHocTemp: SingleSelectDropdownFilterConfig = new SingleSelectDropdownFilterConfig(true);
            dateTypeAdHocTemp.data = [];
            self.recipientListData = [];
            result.title.forEach(item => {
                if (item.dataType === 'date') {
                    dateTypeAdHocTemp.data.push({
                        id: item.attrDefId,
                        name: item.title,
                        selected: false
                    });
                } else if (item.dataType === 'entity') {
                    self.recipientListData.push({
                        id: item.attrDefId,
                        name: item.title,
                    });
                }
            });
            self._initSelectDateControl();
            ArrayHelperService.sort(dateTypeAdHocTemp.data, NOTIFICATION_NAME);
            self.dateTypeAdHocConfig.data = self.dateTypeAdHocConfig.data.concat(dateTypeAdHocTemp.data);
            if (this.notificationData) {
                this.dateTypeAdHocConfig.data?.forEach(item => {
                    if (item.id && item.id.toString() === this.notificationData?.configuration?.trigger?.scheduledStart?.attDefId?.toString()) {
                        this.dateType = item.name;
                        this.dateTypeId = item.id;
                        return;
                    }
                });
            }
            // set date type to unselected if it's not in the drop down list
            const isDateTypeExist = self.dateTypeAdHocConfig.data.find(d => d.name === this.dateType);
            if (!isDateTypeExist) {
                self.dateType = SELECTDATACONTROL;
            }
            // refresh the custom field recipient filter dropdown items if event type is changed
            if (self.filterRecipientsConfig?.filtersConfigList && self.filterRecipientsConfig?.filtersConfigList?.length > 0) {
                self.filterRecipientsConfig.filtersConfigList.forEach(recipientFilter => {
                    const recipientFilterTemp = recipientFilter;
                    if (recipientFilter.metadataType === FilterMetadataType.CustomField) {
                        Object.assign(recipientFilter, self._mapFilterService.getNewFilterDefaultInstance(FilterMetadataType.CustomField, FilterMetadataType.CustomField, false, false, false, this.recipientListData));
                        // remove item if it's not in the dropdown list any more
                        if (recipientFilter.value && recipientFilter.value.length > 0) {
                            recipientFilter.value.forEach(v => {
                                const sourceFilter = recipientFilterTemp.source.find(s => s.id.toString() === v.toString());
                                const rFilter = this.recipientListData.find(r => r.id.toString() === v.toString());
                                if (!sourceFilter && !rFilter) {
                                    recipientFilter.value = recipientFilter.value.filter(item => item !== v);
                                }
                            });
                        }
                    }
                });
                self.filterRecipientsConfig.action$.next({ type: FilterPanelActions.reloadFilter, payload: self.filterRecipientsConfig.filtersConfigList });
            }

            this._updateChangesToUI();
        });
    }

    private _setDefaultValueForFiltersEdit() {
        if (this.notificationData?.configuration) {
            // 1.set values for trigger event
            if (this.notificationData.configuration.trigger) {
                this.selectedDateType = this.dayTypeList.find((item) => item.value === this.notificationData.configuration.trigger?.scheduledStart?.unit);
                this.selectedFrequency = this.frequencyList.find((item) => item.value === this.currNotification.configuration.trigger?.triggerPeriod?.frequency);
                this.selectedTimezone = this.timezoneList.find((item) => item.zonename === this.currNotification.configuration.trigger?.timezone);
                this.selectTime = this.currNotification.configuration.trigger?.scheduledStart?.time;
            }
            if (this.currNotification.configuration.numberLimit) {
                this.selectedCriteria = this.criteriaList.find((item) => item.value === this.currNotification.configuration.numberLimit);
            }
            this.selectedSendType = this.sendTypeList.find((item) => item.value === this.currNotification.configuration.emailActionDetail.groupMail);
            this.isIncludeSubmitterInBCC = this.currNotification.configuration.emailActionDetail.submitterBCC;
            this.isUseSubmitterSecureEmailAddress = this.currNotification.configuration.emailActionDetail.secureEmail;

            // 2.rules filters
            this._metadataFilterModelForEvent = _lodash.cloneDeep(this.notificationData.configuration.metadataFilter);
            // to handle the case which exists filtersConfigList
            if (this.notificationData.configuration.filtersConfigList && this.notificationData.configuration.filtersConfigList.length > 0) {
                this.filterRulesConfig.filtersConfigList = this.notificationData.configuration.filtersConfigList;
                this.filterRulesConfig.filtersConfigList.forEach(filterConfig => {
                    // to handle tamaleweb old data
                    if (filterConfig.dataType.toLowerCase() === FilterMetadataType.EventType.toLowerCase() ||
                        filterConfig.dataType.toLowerCase() === FilterMetadataType.NoteType.toLowerCase() ||
                        filterConfig.dataType.toLowerCase() === FilterMetadataType.EntryType.toLowerCase()) {
                        filterConfig.isShowTypeFilter = true;
                        filterConfig.isHiddenCloseIcon = true;
                        filterConfig.dataType = webBusinessConstants.dataType.noteType;
                        filterConfig.metadataType = FilterMetadataType.NoteType;
                        filterConfig.typeValue = 'eventTypes';
                        filterConfig.title = 'Entry type';
                    }
                });
            } else {
                const filterConfigs = this._notificationEditorService.convertMetadataFilterToFilterConfigList(this._metadataFilterModelForEvent, this._dropdownSource, this.notificationData.metadataType);
                // convert metadataFilter to filtersConfigList
                this.filterRulesConfig.filtersConfigList.push(...filterConfigs);
                // set value for entity fields, it will affect the performance becuase it need to load the related entities from server. change anthor way to save the brief entity when saving the entity realted control.
                this._notificationEditorService.getNewEntityInfoForFilterConfig(this.filterRulesConfig, this.specificActionType);
            }
            if (this.specificActionType === SpecificActionType.TIME) {
                setTimeout(() => {
                    this.filterRulesConfig?.action$.next({ type: FilterPanelActions.restrictFilter, payload: this.specificActionType });
                }, 1000);
            }

            // 3.set filtersConfigList for recipient filters
            this.filterRecipientsConfig.filtersConfigList = this._notificationEditorService.convertRecipientsToFilterConfigList(this.notificationData.configuration.recipients);

            // set value of source back since we remove it when saving the reminder to improve performance
            this.filterRecipientsConfig.filtersConfigList?.forEach(fr => {
                if (fr.metadataType === FilterMetadataType.SpecificTeams || fr.metadataType === FilterMetadataType.SpecificUsers) {
                    fr.source = this._filterService.generateFilterSource(fr.metadataType);
                } else if (fr.metadataType === FilterMetadataType.SpecificContacts) {
                    fr.source = this._getRecipientsContactSource();
                } else if (fr.metadataType === FilterMetadataType.CustomField) {
                    fr.source = this._mapFilterService.getNewFilterDefaultInstance(FilterMetadataType.CustomField, FilterMetadataType.CustomField, false, false, false, this.recipientListData).source;
                }
            });
            // update fieldRecipients
            if (this.fieldRecipientsConfig?.config?.standardItem?.length > 0) {
                this.fieldRecipientsConfig.config.standardItem.forEach(s => {
                    this.filterRecipientsConfig.filtersConfigList?.forEach(f => {
                        if (s.value === f.metadataType) {
                            this.fieldRecipientsConfig.config.standardItem = this.fieldRecipientsConfig.config.standardItem.filter(i => i.value !== f.metadataType);
                        }
                    });
                });
            }
            this.fieldRecipientsConfig.actions$.next({ type: FieldActionType.changeRecipientList, payload: {} });

            // 4.load adhocs by entry types
            let entryTypeIds = [];
            let typeValue = '';
            const entryTypes = this.filterRulesConfig.filtersConfigList?.filter(item => (
                item.dataType === webBusinessConstants.dataType.noteType ||
                item.dataType === webBusinessConstants.dataType.entryType ||
                item.dataType === webBusinessConstants.dataType.eventType));
            if (entryTypes && entryTypes.length > 0) {
                entryTypeIds = entryTypes[0].value;
                typeValue = entryTypes[0].typeValue;
            }
            this._refreshEventAndCustomFields(typeValue, entryTypeIds);
        } else {
            // add default entry type when new email notification
            const entryTypeFilter = this._mapFilterService.getNewFilterDefaultInstance('Entry type', 'NoteType');
            entryTypeFilter.value = '';
            entryTypeFilter.isShowTypeFilter = true;
            entryTypeFilter.isHiddenCloseIcon = true;
            this.filterRulesConfig.filtersConfigList.push(entryTypeFilter);
        }
    }

    private _setNoChangeFilterData() {
        if (!this._metadataFilterModelForEvent.filters[0]) {
            this._metadataFilterModelForEvent.filters[0] = new NotificationFilterContent();
            this._metadataFilterModelForEvent.filters[0].field = webBusinessConstants.common.entry;
        }
    }

    private _setNotificationType() {
        this.typeDropdownConfig.data = [];
        this.typeDropdownConfigData.forEach(item => {
            let isCheckItem = true;
            if (!this.notificationData) {
                // new notification
                if (item.id === EMAIL_ID) {
                    isCheckItem = true;
                } else {
                    isCheckItem = false;
                }
            } else if (this.notificationData.configuration && this.notificationData.configuration.actionType && this.notificationData.configuration.actionType.findIndex(a => a === item.id) > -1) {
                // edit notification
                isCheckItem = true;
            } else {
                isCheckItem = false;
            }
            this.typeDropdownConfig.data.push(MultiSelDropdownConfig.parse(item.id, isCheckItem, item.name, false));
        });
        this.typeName = this.typeDropdownConfig.data.filter(a => a.checked).map(b => b.name).join(', ');
        this.typeIds = this.typeDropdownConfig.data.filter(a => a.checked).map(b => b.id).join(',');
        if (this.typeIds.includes(API_SUBSCRIPTION_ID)) {
            this.isAPISubscriptionSelected = true;
        } else {
            this.isAPISubscriptionSelected = false;
        }
    }

    private _setNotificationEntryDetailAttachments(notificationData) {
        this.entryDetailAttachmentsConfig.data = [];
        this.entryDetailAttachmentsData.forEach(item => {
            let isCheckItem = false;
            if (item.id === IncludeEntryDetails) {
                isCheckItem = true;
                if (notificationData && notificationData.configuration && notificationData.configuration.cardOption) {
                    isCheckItem = notificationData.configuration.cardOption.includeEntryDetailTable;
                }
            }
            if (item.id === IncludeAttachments) {
                isCheckItem = true;
                if (notificationData && notificationData.configuration && notificationData.configuration.cardOption) {
                    isCheckItem = notificationData.configuration?.cardOption.includeWebAttachment;
                }
            }
            if (item.id === IncludeLinkToAttachments) {
                if (notificationData && notificationData.configuration && notificationData.configuration.cardOption) {
                    isCheckItem = notificationData.configuration.cardOption.includeWebAttachementLink;
                }
            }
            this.entryDetailAttachmentsConfig.data.push(MultiSelDropdownConfig.parse(item.id, isCheckItem, item.name, false));
        });
        this.entryDetailAttachmentsNames = this.entryDetailAttachmentsConfig.data.filter(a => a.checked).map(b => b.name).join(',');
        this.entryDetailAttachmentsIds = this.entryDetailAttachmentsConfig.data.filter(a => a.checked).map(b => b.id).join(',');
    }

    /**
     * set value for notification action type control
     */
    private _setNotificationActionType() {
        this.actionsDropdownConfig.data = [];
        this.actionsDropdownConfigData.forEach(item => {
            let isCheckItem = true;
            if (!this.notificationData) {
                // new notification
                isCheckItem = false;
            } else if (this.notificationData.configuration?.cardOption?.action?.findIndex(a => a === item.id) > -1) {
                // edit notification
                isCheckItem = true;
            } else {
                isCheckItem = false;
            }
            this.actionsDropdownConfig.data.push(MultiSelDropdownConfig.parse(item.id, isCheckItem, item.name, false));
        });
        this.actionTypeName = this.actionsDropdownConfig.data.filter(a => a.checked).map(b => b.name).join(',');
        this.actionTypeIds = this.actionsDropdownConfig.data.filter(a => a.checked).map(b => b.id).join(',');
    }

    private _updateChangesToUI() {
        if (!this._changeDetectorRef[webBusinessConstants.common.changeDetectorDestroyedIdentifier]) {
            this._changeDetectorRef.detectChanges();
        }
    }
    //#endregion
}
