<div class="container">
    <note-detail-header [note]="note" [thread]="thread" [isPrintMode]='isPrintMode' [isEmbedMode]='isEmbedMode'
        [isShowMaximun]="isShowMaximun" (editNoteSideNote)="editNoteSideNoteClick($event)"
        (deleteSideNote)="deleteSideNoteClick($event)" (deleteAttachment)="deleteAttachmentClick($event)"
        [highlightText]="highlightText" (toggle)="onBodyToggle()" [isSideSheet]="isSideSheet"
        [partialDisplay]="partialDisplay">
    </note-detail-header>
    <div class="blurb" *ngIf="note.blurb">
        <p [innerHTML]="note.blurb | safeHtml"></p>
    </div>
    <note-detail-body [body]="note.body" [highlightText]="highlightText" [style.display]="showNoteBody ? '' : 'none'">
    </note-detail-body>
</div>