/**
 * Created by Yoyo Fang on 09/25/2018.
 * Description:
 * Web only messages for Informing user
 * ------ maintenance history ------
 */
export const UserMessages = {
    onSessionTimeout: 'Your login session is expired, please login again.',
    onLogout: 'You\'ve successfully logged out.',
    onLoginInvalid: 'The Username or Password you entered doesn\'t match our record. Please check your entry, and try again.',
    onAPIDown: 'No response from server, please check with IT or Support.',
    onNeedAdminLogin: 'Only authenticated user can login.'
};
