
/**
 * Created by Alex Xia on 03/11/2021.
 * Description: The LoginModule will import ButtonModule instead of WidgetModule to improve performance
 *
 * ------ maintenance history ------
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form.component';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '../../widgets/button/button.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
    ],
    declarations: [
        LoginFormComponent,
    ],
    exports: [
        CommonModule,
        FormsModule,
        LoginFormComponent
    ],
})
export class LoginFormModule { }
