/**
 * Created by Ella Ma on 11/02/2020.
 * Description: for TAM-31539, package context-menu has open issue, so use kendo rewrite a componnet
 * Email Digest component
 *
 * ------ maintenance history ------
 */

import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContextMenuEventTypes, ContextMenuClickEvent, ContextMenuShowAction, ContextMenuConfig } from './tam-context-menu.model';
import { fromEvent, Subscription } from 'rxjs';

@Component({
    selector: 'tam-context-menu-kendo',
    templateUrl: './tam-context-menu-kendo.component.html',
    styleUrls: ['./tam-context-menu.component.scss']
})
export class TamContextMenuKendoComponent implements OnInit, OnDestroy {
    @Input() config: ContextMenuConfig;

    contextAnchor;
    showContextPopUp;

    private data: any;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _ref: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this._destroySubscriptions.push(
            this.config.show$.subscribe(e => this.showMenu(e)),
            fromEvent(document, 'click').subscribe((event: MouseEvent) => {
                this.showContextPopUp = false;
                this.contextAnchor = null;
            })
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    emptyClick($event) {
        if ($event.preventDefault) {
            $event.preventDefault();
        }
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
    }

    onItemClick($event, menuItem) {
        if (menuItem.disabled) {
            return;
        }
        let itemData = this.data;
        if ($event.item) {
            itemData = $event.item;
        }
        let event;
        if ($event.event) {
            event = $event.event;
        } else {
            event = $event;
        }
        this.config.events$.next({
            type: ContextMenuEventTypes.click,
            payload: new ContextMenuClickEvent(menuItem.id, itemData, event, menuItem),
        });
        this.showContextPopUp = false;
        this.contextAnchor = null;
    }

    onItemMousedown($event) {
        if (this.config.eventKey) {
            $event[this.config.eventKey] = this.config.eventValue;
        }
    }

    onPassiveItemClick($event, menuItem) {
        if (menuItem.disabled) {
            return;
        }
        menuItem.checked = !menuItem.checked;
        this.config.events$.next({
            type: ContextMenuEventTypes.checkChanged,
            payload: { id: menuItem.id, checked: menuItem.checked }
        });
    }

    showMenu(action: ContextMenuShowAction) {
        if (this.config.forceRefresh) {
            this._ref.detectChanges();
        }
        this.contextAnchor = action.event;
        this.showContextPopUp = true;
    }

}
