import { StringLiteralsPipe } from '../../../../../pipes/translate.pipe';
import { EntityLocationInSubject, MessageContextType } from '../../../../../tamalelibs/models/notification.model';

export const ALL_NAME = 'All';
export const EMPTY_SOURCE_NAME = 'None';
export const NOTIFICATION_NAME = 'name';
export const AFTER3TIMES = StringLiteralsPipe.translate('notification.after_3_times_notification');
export const AFTER5TIMES = StringLiteralsPipe.translate('notification.after_5_times_notification');
export const AFTER10TIMES = StringLiteralsPipe.translate('notification.after_10_times_notification');
export const NEVEREND = StringLiteralsPipe.translate('notification.never');

export const ENTRYDETAIL_ID = StringLiteralsPipe.translate('notification.Generate_default_sidenote_id');
export const ENTRYDETAIL_NAME = StringLiteralsPipe.translate('notification.Create_default_sidenote_name');
export const ENTRYATTACHMENTS_ID = StringLiteralsPipe.translate('notification.View_thread_id');
export const ENTRYATTACHMENTS_NAME = StringLiteralsPipe.translate('notification.View_thread_id');

export const SENDASGROUPEMAIL = StringLiteralsPipe.translate('notification.send_as_group_email');
export const SENDASINDIVIDUALEMAIL = StringLiteralsPipe.translate('notification.send_as_individual_email');
export const SELECTDATACONTROL = StringLiteralsPipe.translate('notification.select_date_control');
export const SELECTENTRYACTIONCONTROL = StringLiteralsPipe.translate('notification.select_entry_action_control');
export const CRITERIAINFOTIP = StringLiteralsPipe.translate('notification.criteria_info_tip');

export const GENERATESIDENOTE_ID = StringLiteralsPipe.translate('notification.Generate_default_sidenote_id');
export const GENERATESIDENOTE_NAME = StringLiteralsPipe.translate('notification.Create_default_sidenote_name');
export const VIEWTHREAD_ID = StringLiteralsPipe.translate('notification.View_thread_id');
export const VIEWTHREAD_NAME = StringLiteralsPipe.translate('notification.View_thread_name');

export const EMAIL_ID = 'Email';
export const EMAIL_NAME = 'Email';
export const API_SUBSCRIPTION_ID = 'MqPublish';
export const API_SUBSCRIPTION_Name = 'API Subscription';

export const IncludeEntryDetails = 'includeEntryDetails';
export const IncludeAttachments = 'includeAttachments';
export const IncludeLinkToAttachments = 'includeLinkToAttachments';

export const DefaultTimeToSendNotification = '4:00';

export const CriteriaData = [{ text: AFTER3TIMES, value: 3 }, { text: AFTER5TIMES, value: 5 }, { text: AFTER10TIMES, value: 10 }, { text: NEVEREND, value: -1 }];

export const DateTypeAdhocBasicData = [{
    id: ['submitDate'],
    name: 'Submitted date',
    selected: true
}, {
    id: ['displayDate'],
    name: 'Display date',
    selected: true
}, {
    id: ['startDate'],
    name: 'Start date',
    selected: true
}, {
    id: ['endDate'],
    name: 'End date',
    selected: true
}];

export const DayTypeData = [{ text: 'Day', value: 'day' }, { text: 'Workday', value: 'workday' }];
export const FrequencyData = [{ text: 'Daily', value: 'daily' }, { text: 'Weekly', value: 'weekly' }, { text: 'Monthly', value: 'monthly' }];

export const EntryActionData = [{
    id: 'created',
    checked: false,
    name: 'Create'
}, {
    id: 'updated',
    checked: false,
    name: 'Edit'
}];
export const EntryDetailAttachmentsData = [{
    id: IncludeEntryDetails,
    name: StringLiteralsPipe.translate('notification.include_entry_details_table')
}, {
    id: IncludeAttachments,
    name: StringLiteralsPipe.translate('notification.include_attachments')
}, {
    id: IncludeLinkToAttachments,
    name: StringLiteralsPipe.translate('notification.include_link_to_attachments')
}];
export const IncludeEntityListInSubjectDropdownConfigData = [{
    value: EntityLocationInSubject.START,
    text: StringLiteralsPipe.translate('notification.start_with_the_entity_list')
}, {
    value: EntityLocationInSubject.END,
    text: StringLiteralsPipe.translate('notification.end_with_the_entity_list')
}, {
    value: EntityLocationInSubject.NONE,
    text: StringLiteralsPipe.translate('notification.none')
}];
export const MessageContentData = [{
    value: MessageContextType.SINGLE_ENTRY,
    text: StringLiteralsPipe.translate('notification.single_entry')
}, {
    value: MessageContextType.FULL_THREAD,
    text: StringLiteralsPipe.translate('notification.full_thread')
}, {
    value: MessageContextType.NO_TEXT,
    text: StringLiteralsPipe.translate('notification.no_text')
}];

export const PrivacyData = [{ value: 'enforce', text: 'Enforce privacy' }, { value: 'override', text: 'Override privacy' }];
export const SelectedCriteria = { text: AFTER3TIMES, value: 3 };
export const SelectedDateType = { text: 'Day', value: 'day' };
export const SelectedEntityListInSubject = { value: EntityLocationInSubject.START, text: StringLiteralsPipe.translate('notification.start_with_the_entity_list') };
export const SelectedFrequency = { text: 'Daily', value: 'daily' };
export const SelectedMessageContent = { value: MessageContextType.SINGLE_ENTRY, text: StringLiteralsPipe.translate('notification.single_entry') };
export const SelectedPrivacy = { value: 'enforce', text: 'Enforce privacy' };
export const SendTypeData = [{ text: SENDASGROUPEMAIL, value: true }, { text: SENDASINDIVIDUALEMAIL, value: false }];
export const TypeDropdownData = [{ id: EMAIL_ID, name: EMAIL_NAME }, { id: API_SUBSCRIPTION_ID, name: API_SUBSCRIPTION_Name }];
