/**
 *  Created by Alan Yang.  3/21/2017
 *  Description:
 *      User is including token and credential info.
 *      credential may be dup with Contact.Permisions, put it here for convenience.
 *      primaryEmail: Contact.primaryEmail
 *      secondaryEmail: Contact.secondaryEmail
 *      userid: Contact.id
 *      username: Contact.Name
 *
 * ------ maintenance history ------
 * Modified by Lucas Wang on 09/02/21
 * Modified by Cathy Yao on 12/08/21: Add Password Management for Web-Only Users with Local Authentication
 */

import { Contact } from './contact.model';

export class Credential {
    name: string;
    id: string;
    hasCredential?: boolean;
}

export class User extends Contact {
    credential: string;
    token: string;
    password?: string;
    oldpassword?: string;
    confirmedPWD?: string;
    username?: string;
    credentials?: Credential[];
    inTeams?: any;

    static parse(res): User {
        if (!res) {
            return null;
        }
        const result = new User();
        result.id = res['id'];
        result.credential = res['credential'];
        result.token = res['token'];
        result.username = res['username'];
        result.primaryEmail = res['secure-email'];
        result.inTeams = res['teams'];
        result.credentials = res['credentials'];
        return result;
    }
}

export enum UserPasswordTemplateType {
    ChangePassword = 'Change password',
}

/**
 * Permissions granted to a system user.
 *
 * Maintenance history:
 * Modified by Simon Zhao on 8/23/2022 Moved from TamaleWeb project and renamed it in order to distinguishing it with the same name one in TamaleLib project.
 */
 export class SystemCredential {
    admin: boolean;
    change: boolean;
    compliance: boolean;
    editFirmwideEmailRules: boolean;
    manageTeam: boolean;
    mobileUser: boolean;
    portfolioViewer: boolean;
    proxyAPIUser: boolean;
    pubToPrivEntityConversion: boolean;
    publishPubConfiguration: boolean;
    publishPubLayout: boolean;
    publishTeamLayout: boolean;
    pythonEditor: boolean;
    streetEvents: boolean;
    updateEntityAccessList: boolean;
    editRelationshipTree: boolean;

    constructor(code: string) {
        this.admin = code[0] === '1' ? true : false;
        this.change = code[1] === '1' ? true : false;
        this.compliance = code[2] === '1' ? true : false;
        this.editFirmwideEmailRules = code[3] === '1' ? true : false;
        this.manageTeam = code[4] === '1' ? true : false;
        this.mobileUser = code[5] === '1' ? true : false;
        this.portfolioViewer = code[6] === '1' ? true : false;
        this.proxyAPIUser = code[7] === '1' ? true : false;
        this.pubToPrivEntityConversion = code[8] === '1' ? true : false;
        this.publishPubConfiguration = code[9] === '1' ? true : false;
        this.publishPubLayout = code[10] === '1' ? true : false;
        this.publishTeamLayout = code[11] === '1' ? true : false;
        this.pythonEditor = code[12] === '1' ? true : false;
        this.streetEvents = code[13] === '1' ? true : false;
        this.updateEntityAccessList = code[14] === '1' ? true : false;
        this.editRelationshipTree = code[15] === '1' ? true : false;
    }
}

/**
 * This is used for tamaleweb and save to store, can get it via the currentUser selector.
 *
 * @export
 * @class User
 * ------ maintenance history ------
 * Modified by Marcus Zhao update on 06/24/2022 : change name equals longName ,add loginName equals userName.
 * Modified by Simon Zhao 08/23/2022 Moved it from TamaleWeb project to Tamalelib project and rename it to distinguish it from the other one in Tamalelib project.
 */
 export class SystemUser {
    id: string;
    /**
     * login name means user name from login input.
     * If you want to set login name please use loginName.
     * @type {string}
     * @memberof SystemUser
     */
    loginName?: string;
    /**
     *  we need to keep token as the third properties since it retrieve the third property to get the token in web front sheet,
     *  will do some refactor in web front sheet after 22.2 release.
     * @type {string}
     * @memberof SystemUser
     */
    token: string;
    /**
     *
     * name means longName of User
     * If you want to get long name ,please use name.
     * @type {string}
     * @memberof SystemUser
     */
    name: string;
    /**
     * short name means user short name
     * If you want to get short name ,please use shortName.
     * @type {string}
     * @memberof SystemUser
     */
    shortName?: string;
    credential: SystemCredential;

    public static parse(response): SystemUser {
        const user = new SystemUser();
        if (response.userinfo) {
            user.id = response.userinfo.userID;
            user.loginName = response.userinfo.userName;
            user.token = response.userinfo.token;
            user.name = response.userinfo.longName;
            user.shortName = response.userinfo.shortName;
            user.credential = new SystemCredential(response.userinfo.credential);
        }
        return user;
    }
}

const USR_WORKFLOW_PREFERENCE_NODE_NAME = 'workflowPreference';
export const USER_SETTING_ROOT_NODE_NAME = 'configuration';

/**
 * This serves as the model of user workflow preferences.
 */
export class UserWorkflowPreference {
    /**
     * a flag indicating whether the progress bar is expanded.
     */
    isProgressExpanded: boolean;

    static toUserSettings(preference: UserWorkflowPreference) {
        const usrSetting = {};
        usrSetting[USR_WORKFLOW_PREFERENCE_NODE_NAME] = preference;
        return usrSetting;
    }

    static parseUserSetting(userSetting: any) {
        const workflowPreference = new UserWorkflowPreference();
        if (userSetting[USR_WORKFLOW_PREFERENCE_NODE_NAME] && userSetting[USR_WORKFLOW_PREFERENCE_NODE_NAME][USER_SETTING_ROOT_NODE_NAME]) {
            const preference = userSetting[USR_WORKFLOW_PREFERENCE_NODE_NAME][USER_SETTING_ROOT_NODE_NAME];
            workflowPreference.isProgressExpanded = preference.isProgressExpanded;
        }

        return workflowPreference;
    }
}

