import { Subject } from 'rxjs';

export class SingleSelectDropdownFilterConfig {
    data: SingleSelectDropdownConfig[];
    disabledInput: boolean;
    hideInput: boolean;
    sourceFixed: boolean;
    placeholder?: string;
    clear$: Subject<any> = new Subject<any>();
    onChangeValue$: Subject<any>;
    open$: Subject<any> = new Subject<any>();
    sortCfg: DropdownSortConfig;

    constructor(hideInput?: boolean, disabledInput?: boolean) {
        this.onChangeValue$ = new Subject<any>();
        this.data = [];
        this.hideInput = !!hideInput;
        this.disabledInput = !!disabledInput;
    }
}

export class SingleSelectDropdownConfig {
    id: any; // it may be string[] in some case
    selected: boolean;
    name: string;

    static parse(id: string, selected: boolean, name: string): SingleSelectDropdownConfig {
        return {
            'id': id,
            'selected': selected,
            'name': name
        };
    }
}

export class DropdownSortConfig {
    propName: string;
    isAscOrder = true;
}
