/**
 * Created by Alan Yang on 7/28/17.
 * Description: a list of entity or contact,
 *
 * ------ maintenance history ------
 * Marcus Zhao 07/01/2022 add totalCount for contact list model.
 */
import { TSItem } from './ts-item.model';
import { Entity } from './entity.model';
import { Contact } from './contact.model';
import { CalendarEntry } from './calendar-entry.model';

export class EntityList extends TSItem {
    next = '';
    entities: Entity[] = [];
}

export class ContactList extends TSItem {
    next = '';
    contacts: Contact[] = [];
    totalCount = 0;
}

export class CalendarList extends TSItem {
    next = '';
    calendars: CalendarEntry[] = [];
    totalCount = 0;
}
