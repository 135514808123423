/**
 * Created by Abner Sui on 01/30/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { TSItem } from './ts-item.model';
import { BlastEmail } from './blastemail.model';

export class BlastEmailList extends TSItem {
    count = 0;
    next = '';
    blastEmails: BlastEmail[] = [];
}
