/**
 * Created by Ella Ma on 2/14/2019.
 * Description:
 *
 * ------ maintenance history ------
 * 12/01/2020   Ella Ma Fix When the grid drop-down scrolls to the next page, the grid scrolling position remains unchanged [TAM-30314]
 */
import { Component, HostListener, OnInit, OnDestroy, Input } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DEFAULT_PAGE_SIZE, GRID_OPTIONS } from './grid.model';
import { GridConfig, GridEventType, DataList, GridActionType } from './grid.config';

@Component({
    selector: 'tam-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})

export class GridComponent implements OnInit, OnDestroy {

    @Input() config: GridConfig;

    columnDefs: Array<ColDef>;
    gridOptions: GridOptions;
    private _loadingMore = false;
    private _gridApi;
    private _gridColumnApi;
    private _threadList: DataList<any> = new DataList<any>();
    private _currentPageIndex = 1;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    ngOnInit() {
        this.columnDefs = this.config.columnDefs;
        this.gridOptions = this.config.gridOptions || GRID_OPTIONS;
        this._destroySubscriptions.push(
            this.config.action$.pipe(
                filter((item) => item.type === GridActionType.refreshGrid)
            ).subscribe((data) => {
                this._onSubscribeThreadListItem(data.payload || new DataList());
            }),
            this.config.action$.pipe(
                filter((item) => item.type === GridActionType.loadMore)
            ).subscribe((data) => {
                this._threadList.data = [].concat(this._threadList.data, data.payload.data);
                this._onSubscribeThreadListItem(this._threadList);
            }),
            this.config.action$.pipe(
                filter((item) => item.type === GridActionType.resetPage)
            ).subscribe(() => {
                this._currentPageIndex = 1;
                this._loadingMore = false;
            }),
            this.config.action$.pipe(
                filter((item) => item.type === GridActionType.setSortModel)
            ).subscribe((data) => {
                this._gridColumnApi.applyColumnState({ state: data.payload });
            })
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (!this._gridApi) {
            return;
        }
        this.onDisplayedColumnsChanged();
    }

    onEventSubject() {
        this.config.events$.next({
            type: GridEventType.onBodyScroll,
            payload: {
                page: this._currentPageIndex,
                rpp: DEFAULT_PAGE_SIZE
            }
        });
    }

    onDisplayedColumnsChanged() {
        if (this._gridApi) {
            this._gridApi.sizeColumnsToFit();
        }
    }

    onBodyScroll(e) {
        if (e.direction === 'vertical' && e.api.gridBodyCtrl) {
            const hasNext = this._threadList.total > this._currentPageIndex * DEFAULT_PAGE_SIZE;
            if (hasNext && !this._loadingMore) {
                if (e.top + e.api.gridBodyCtrl.eBodyViewport.clientHeight + 1000 > e.api.gridBodyCtrl.eBodyViewport.scrollHeight) {
                    this._loadingMore = true;
                    this._currentPageIndex++;
                    this.onEventSubject();
                }
            }
        }
    }

    onGridReady(params) {
        this._gridApi = params.api;
        this._gridColumnApi = params.columnApi;
        this._setSelectedRow();
    }

    onSortChanged(e) {
        const columnState = this._gridColumnApi.getColumnState();
        const sortModel = columnState
            .filter(column => column.sort)
            .map(column => ({ colId: column.colId, sort: column.sort }));

        if (sortModel && sortModel.length > 0) {
            const sortBy = sortModel[0].colId;
            const sortOrder = sortModel[0].sort;
            this.config.events$.next({
                type: GridEventType.onSort,
                payload: {
                    sortby: sortBy,
                    sortorder: sortOrder
                }
            });
        }
    }

    private _onSubscribeThreadListItem(data: DataList<any>) {
        if (!this._threadList || !this._threadList.data || !this._gridApi) {
            return;
        }
        this._threadList = data;
        if (this._loadingMore) {
            this._loadingMore = false;

            const startRowIndex = (this._currentPageIndex - 1) * DEFAULT_PAGE_SIZE;
            const endRowIndex = this._currentPageIndex * DEFAULT_PAGE_SIZE;
            this._gridApi.updateRowData({
                add: this._threadList.data.slice(startRowIndex, endRowIndex)
            });
        } else {
            this._gridApi.setRowData(this._threadList.data);
            this._gridApi.forEachNode((node) => {
                node.setSelected(node.rowIndex === 0);
            });
        }
        this._gridApi.sizeColumnsToFit();
    }

    private _setSelectedRow() {
        if (this._threadList && this._threadList.data) {
            this._gridApi.setRowData(this._threadList.data);
            this._gridApi.sizeColumnsToFit();
            this._gridApi.forEachNode((node) => {
                node.setSelected(node.rowIndex === 0);
            });
        }
    }
}
