/**
 * Created by Alex on 8/10/2020.
 * Description:
 *
 * ------ maintenance history ------
 */

import {
    EDIT_AUTO_TAG_SETTINGS_FAIL, EDIT_AUTO_TAG_SETTINGS_SUCCESS,
    GET_AUTO_TAG_MODEL_SUCCESS, GET_AUTO_TAG_MODEL_FAIL
} from './auto-tagging.actions';

export function autoTaggingReducer(state: any = null, action) {
    switch (action.type) {
        case EDIT_AUTO_TAG_SETTINGS_SUCCESS:
        case EDIT_AUTO_TAG_SETTINGS_FAIL:
            return action.payload;
        default:
            return state;
    }
}

export function autoTagModelReducer(state: any = null, action) {
    switch (action.type) {
        case GET_AUTO_TAG_MODEL_SUCCESS:
        case GET_AUTO_TAG_MODEL_FAIL:
            return action.payload;
        default:
            return state;
    }
}
