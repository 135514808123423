<div class="entity-editor-container">
    <div class="header-container">
        <label>{{ title }}</label>
        <smart-icon name="close" (click)="onSlideSheetClose()" class="light"></smart-icon>
    </div>
    <div class="input-container">
        <div class="tam-form-field" [ngClass]="{'has-error':nameDuplicated||name.invalid}">
            <label class="required">{{'general.name' | stringLiterals}}</label>
            <input [(ngModel)]="currEntityData['name']" class="tam-input tam-textbox" type="text"
                (blur)="configChanged()" placeholder="Please enter a unique name" value="{{ currEntityData['name'] }}"
                [formControl]="name">
            <div class="tam-form-help" *ngIf="nameDuplicated">
                {{'general.name_already_exist' | stringLiterals}}
            </div>
            <div class="tam-form-help" *ngIf="name.invalid && name.errors['required']">
                {{'general.required_error_message' | stringLiterals}}
            </div>
            <div class="tam-form-help" *ngIf="name.invalid && name.errors['maxlength']">
                {{'general.name_exceed_maximum' | stringLiterals: '128'}}
            </div>
        </div>
        <div class="display-web-container">
            <label class="required">{{'general.display_in_tamaleWeb' | stringLiterals}}</label>
            <label class="checkbox" tam-checkbox [(ngModel)]="currEntityData['display-web']" (click)="configChanged()"
                style="padding-left: 8px; vertical-align: text-bottom;"></label>
        </div>
    </div>
    <div class="button-container">
        <button tam-button [tamType]="'primary'" [tamSize]="'normal'" [tamLoading]="isProgressing"
            [disabled]="isProgressing" class="button" (click)="onSubmitClick()">
            {{ title === 'Create New Entity Type' ? 'Create' : 'Submit'}}
        </button>
        <button tam-button [tamType]="'tertiary'" [tamSize]="'normal'" class="button" (click)="onSlideSheetClose()">
            {{'general.cancel' | stringLiterals}}
        </button>
    </div>
</div>