/**
 * Created by Marcus Zhao on 06/02/20.
 * Description:
 *
 * ------ maintenance history ------
 * 06/02/2020 Marcus Zhao - add validInputSubject .
 * 09/03/2020 Bowen - add jobTitleSubject and relationshipTypeSubject
 * 012/04/2020 Bowen - add companySubject
 * 09/15/2020 Bowen - change jobTitleSubject,relationshipTypeSubject,companySubject to actionSubject
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ContactTemplateService {
    dialogOpen$: Subject<any> = new Subject<any>();
    minimized = false;
    validInputSubject$: Subject<any> = new Subject<any>();
    addDynimacLayoutSubject$: Subject<any> = new Subject<any>();
    changeValueStatus = false;
    setFullAddressForStreet = false;
    actionSubject$: Subject<any> = new Subject<any>();
}
