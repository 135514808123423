/**
 * Created by Abner Sui on 1/5/2021
 * -------------------------------------
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { FieldDefinitionConfiguration, FieldType } from '../../../tamalelibs/models/workflow.model';
import { ControlConfigEvents, IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';

@Component({
    selector: 'tam-number-config',
    templateUrl: './number-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class NumberConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {

    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;
    @ViewChild('decimalskendo', { static: false }) decimalskendo;
    @ViewChild('staticDefaultValuekendo', { static: false }) staticDefaultValuekendo;
    @ViewChild('maxkendo', { static: false }) maxkendo;
    @ViewChild('minkendo', { static: false }) minkendo;

    dataType = FieldType.NUMBER;
    format = {
        style: 'decimal',
        useGrouping: true,
        maximumFractionDigits: 9,
        minimumFractionDigits: 0,
    };
    decimalsData: Array<number> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    private _controlType = ControlType.NUMBER;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    ngOnInit(): void {
        // this._destroySubscriptions.push();
        if (!this.config.decimals && this.config.decimals !== 0) {
            this.format.maximumFractionDigits = 9;
            this.format.minimumFractionDigits = 0;
        } else {
            this.format.maximumFractionDigits = this.format.minimumFractionDigits = this.config.decimals;
        }
    }

    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    blur(event): void {
        if (this.config.min || this.config.min === 0) {
            if (this.config.staticDefaultValue && this.config.staticDefaultValue < this.config.min) {
                this.config.staticDefaultValue = null;
            }
        }
        if (this.config.max || this.config.max === 0) {
            if (this.config.staticDefaultValue && this.config.staticDefaultValue > this.config.max) {
                this.config.staticDefaultValue = null;
            }
        }
    }

    focusDecimals(): void {
        this.decimalskendo.toggle(true);
    }

    getInvalidDetail(): FieldValidationError {
        return this.base.getInvalidDetail();
    }

    onKeyupMax(event): void {
        if (event.keyCode === 13) { // Enter
            this.maxkendo.blur();
        }
    }

    onKeyupMin(event): void {
        if (event.keyCode === 13) { // Enter
            this.minkendo.blur();
        }
    }

    onKeyupStaticDefaultValue(event): void {
        if (event.keyCode === 13) { // Enter
            this.staticDefaultValuekendo.blur();
        }
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        const localValid = true;
        const result = baseValid && localValid;
        if (result) {
            this.config.field.fieldDefinition.configuration = FieldDefinitionConfiguration.parse(this.config);
        }
        return result;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }

    valueChangeDecimals(event): void {
        if (!this.config.decimals && this.config.decimals !== 0) {
            this.format.maximumFractionDigits = 9;
            this.format.minimumFractionDigits = 0;
        } else {
            this.format.maximumFractionDigits = this.format.minimumFractionDigits = this.config.decimals;
        }
        this.maxkendo.notifyValueChange();
        this.minkendo.notifyValueChange();
        this.staticDefaultValuekendo.notifyValueChange();
        this.valueChange(event);
    }

    valueChangeMax(event): void {
        this.config.max = event;
        this.valueChange(event);
    }

    valueChangeMin(event): void {
        this.config.min = event;
        this.valueChange(event);
    }

    valueChangeSeparator(event): void {
        this.format.useGrouping = this.config.separator;
        this.maxkendo.notifyValueChange();
        this.minkendo.notifyValueChange();
        this.staticDefaultValuekendo.notifyValueChange();
        this.valueChange(event);
    }

    valueChangeStaticDefaultValue(event): void {
        this.config.staticDefaultValue = event;
        this.valueChange(event);
    }

}
