/**
 * Created by Rick on 10/19/2023.
 * Description: Editor Component for Relationship
 */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, Subject, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../redux';
import * as _lodash from 'lodash';

import { SlideSheetService } from '../../../../../services/slide-sheet.service';
import { SlideSheetActionTypes } from '../../../../slide-sheet/slide-sheet.model';
import { ToastService } from '../../../../../widgets/toast/toast.service';
import { businessConstants } from '../../../../../tamalelibs/constants/business.constants';
import { ArrayHelperService } from '../../../../../tamalelibs/services/array-helper.service';
import { catchError, debounceTime, take } from 'rxjs/operators';
import { RelationshipTypeService } from '../../../../../tamalelibs/services/relationshipType.service';
import { NotificationStyles } from '../../../../../widgets/notification/notification.model';
import { EntityType } from '../../../../../tamalelibs/models/entity-type.model';
import { EntityTypeService } from '../../../../../tamalelibs/services/entity-type.service';
import { CreateValidRelationshipType } from '../../../../../redux/actions/relationship-type.actions';
import { StringLiteralsPipe } from '../../../../../pipes/translate.pipe';
import { AlertWindowService } from '../../../../../widgets/alert-window/alert-window.service';

export const NAME = 'name';
export enum ControlType {
    relationshipType = 'relationshipType',
    parentEntityType = 'parentEntityType',
    childEntityType = 'childEntityType',
    reciprocalRelationshipType = 'reciprocalRelationshipType'
}

@Component({
    selector: 'relationships-types-editor',
    templateUrl: './relationships-types-editor.component.html',
    styleUrls: ['./relationships-types-editor.component.scss']
})
export class RelationshipsTypesEditorComponent implements OnInit, OnDestroy {
    @ViewChild('relationshiplist', { static: false }) relationshiplist;
    @ViewChild('dropDownReciprocalRelationshipType', { static: false }) dropDownReciprocalRelationshipType;
    childEntityTypeItems: Array<EntityType> = [];
    contactIdList: Array<any> = [];
    childDataItems: Array<any> = [];
    dataItems: Array<any> = []; // data items based on search, sub set of dataItems
    parentEntityTypeItems: Array<EntityType> = [];
    parentDataItems: Array<any> = [];
    isProgressing = false;
    isHasSearchValue = false;
    relationshipMaxLength = false;
    reciprocalMaxLength = false;
    selectedParentEntityType: EntityType;
    selectedChildEntityType: EntityType;
    selectedReciprocalRelationshipType: any;
    searchValue: string;
    suggestionItems: Array<any> = [];
    selectedItem: any; // selected items, sub set of dataItems
    title = 'Create relationship';
    isChanged = false;

    //#region private properties
    private _destroySubscriptions: Array<Subscription> = [];
    private _filterHandler$: Subject<any> = new Subject();

    constructor(
        private _alertWindow: AlertWindowService,
        private _entityTypeService: EntityTypeService,
        private _relationshipTypeService: RelationshipTypeService,
        private _store: Store<AppState>,
        private _slideSheetService: SlideSheetService,
        private _toast: ToastService,
    ) { }

    ngOnInit() {
        this._initExistRelationshipTypes();
        this._initEntityTypes();
        this._filterHandler$.pipe(
            debounceTime(250),
        ).subscribe(obj => this._searching(obj));
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    configChanged() {
        this.isChanged = true;
    }

    childSelectionChange(event: any): void {
        if (event && this.selectedParentEntityType) {
            if (this.selectedParentEntityType.id === businessConstants.EntityTypes.Contact_To_Contact_Id && event.id !== businessConstants.EntityTypes.Contact_Id) {
                this.selectedParentEntityType = { name: businessConstants.EntityTypesName.corporate, id: businessConstants.EntityTypes.Corporate_Id };
            } else if (this.selectedParentEntityType.id === businessConstants.EntityTypes.Contact_Id && event.id !== businessConstants.EntityTypes.Contact_To_Contact_Id) {
                this.selectedParentEntityType = { name: businessConstants.EntityTypesName.corporate, id: businessConstants.EntityTypes.Corporate_Id };
            } else {
                if (event.id === businessConstants.EntityTypes.Contact_To_Contact_Id) {
                    this.selectedParentEntityType = { name: businessConstants.EntityTypesName.contact_to_entity, id: businessConstants.EntityTypes.Contact_To_Contact_Id };
                    this.selectedReciprocalRelationshipType = this.selectedItem;
                } else if (event.id === businessConstants.EntityTypes.Contact_Id) {
                    this.selectedParentEntityType = { name: businessConstants.EntityTypesName.contact, id: businessConstants.EntityTypes.Contact_Id };
                }
            }
        }
    }

    createRelationshipTypeName() {
        if (this.searchValue && (this.searchValue !== null && this.searchValue !== '')) {
            if (this.searchValue.toString().length > 128) {
                this.relationshipMaxLength = true;
                this.relationshiplist.clearFilter();
                this.relationshiplist.toggle(false);
            } else {
                this.suggestionItems = this.dataItems;
                this.relationshiplist.clearFilter();
                this.relationshiplist.toggle(false);
                this._relationshipTypeService.createRelationshipType(this.searchValue).pipe(
                    catchError((e: any) => {
                        this._toast.notify({
                            style: NotificationStyles.Error,
                            message: e,
                        });
                        return of(null);
                    }),
                    take(1)
                ).subscribe(res => {
                    this.selectedItem = this.selectedReciprocalRelationshipType = {
                        name: res.name,
                        id: res.id
                    };
                    this.suggestionItems.push(this.selectedItem);
                    if (res) {
                        this._toast.notify({
                            style: NotificationStyles.Success,
                            message: StringLiteralsPipe.translate('general.create_relationship_type_successfully'),
                        });
                    }
                });
            }
        }
    }

    /**
     * create reciprocal relationship type name
     */
    createReciprocalRelationshipTypeName(): void {
        if (this.searchValue && (this.searchValue !== null && this.searchValue !== '')) {
            if (this.searchValue.toString().length > 128) {
                this.reciprocalMaxLength = true;
                this.dropDownReciprocalRelationshipType.clearFilter();
                this.dropDownReciprocalRelationshipType.toggle(false);
            } else {
                this.suggestionItems = this.dataItems;
                this.dropDownReciprocalRelationshipType.clearFilter();
                this.dropDownReciprocalRelationshipType.toggle(false);
                // create relationship type
                this._relationshipTypeService.createRelationshipType(this.searchValue).pipe(take(1)).subscribe(res => {
                    this.selectedReciprocalRelationshipType = {
                        name: res.name,
                        id: res.id
                    };
                    // add the new relationship type to source.
                    this.suggestionItems.push(this.selectedReciprocalRelationshipType);
                    // change the relationship type by condition
                    this.reciprocalValueChange(this.selectedReciprocalRelationshipType);
                    if (res) {
                        this._toast.notify({
                            style: NotificationStyles.Success,
                            message: StringLiteralsPipe.translate('general.create_relationship_type_successfully'),
                        });
                    } else {
                        this._toast.notify({
                            style: NotificationStyles.Error,
                            message: StringLiteralsPipe.translate('general.create_relationship_type_failed'),
                        });
                    }
                });
            }
        }
    }

    handleFilter(value, type) {
        const obj = { value, type };
        this._filterHandler$.next(obj);
    }

    onSlideSheetClose() {
        if (this.isChanged) {
            const message = `Are you sure you want to discard all your changes?`;
            const subscription = this._alertWindow.warn('You have unsaved changes',
                [message], StringLiteralsPipe.translate('general.discard'), StringLiteralsPipe.translate('general.go_back'))
                .subscribe((result: boolean) => {
                    if (result) {
                        this._slideSheetService.slideSheetActionSubject$.next({
                            type: SlideSheetActionTypes.CLOSE
                        });
                    }
                    subscription.unsubscribe();
                });
        } else {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE
            });
        }
    }

    onSubmitClick() {
        if (this.selectedItem && this.selectedParentEntityType && this.selectedChildEntityType && this.selectedReciprocalRelationshipType) {
            // to handle the special cases
            if (this.selectedParentEntityType.id && this.selectedParentEntityType.id === businessConstants.EntityTypes.Contact_Id && this.selectedChildEntityType.id && this.selectedChildEntityType.id === businessConstants.EntityTypes.Contact_Id) {
                this.selectedParentEntityType.id = businessConstants.EntityTypes.Contact_To_Contact_Id;
                this.selectedChildEntityType.id = businessConstants.EntityTypes.Contact_To_Contact_Id;
            } else if (this.selectedParentEntityType.id && this.selectedParentEntityType.id === businessConstants.EntityTypes.Contact_To_Contact_Id && this.selectedChildEntityType.id && this.selectedChildEntityType.id === businessConstants.EntityTypes.Contact_To_Contact_Id) {
                this.selectedParentEntityType.id = businessConstants.EntityTypes.Contact_Id;
                this.selectedChildEntityType.id = businessConstants.EntityTypes.Contact_Id;
            }

            this._store.dispatch(new CreateValidRelationshipType({
                parent: this.selectedParentEntityType,
                child: this.selectedChildEntityType,
                relationshipType: this.selectedItem,
                reciprocal: this.selectedReciprocalRelationshipType,
            }));
            this.isChanged = false;
        }
    }

    onRelationshipOpen(event: any): void {
        this.relationshipMaxLength = false;
        if (this.suggestionItems.length === 0) {
            event.preventDefault();
        } else {
            this.relationshiplist.toggle(true);
        }
    }

    onReciprocalOpen(event: any): void {
        this.reciprocalMaxLength = false;
        if (this.suggestionItems.length === 0) {
            event.preventDefault();
        } else {
            this.dropDownReciprocalRelationshipType.toggle(true);
        }
    }

    onEnter(event) {
        event.stopImmediatePropagation();
    }

    parentSelectionChange(event: any): void {
        if (this.selectedChildEntityType.id === businessConstants.EntityTypes.Contact_To_Contact_Id && event.id !== businessConstants.EntityTypes.Contact_Id) {
            this.selectedChildEntityType = { name: businessConstants.EntityTypesName.corporate, id: businessConstants.EntityTypes.Corporate_Id };
        } else if (this.selectedChildEntityType.id === businessConstants.EntityTypes.Contact_Id && event.id !== businessConstants.EntityTypes.Contact_To_Contact_Id) {
            this.selectedChildEntityType = { name: businessConstants.EntityTypesName.corporate, id: businessConstants.EntityTypes.Corporate_Id };
        } else {
            if (event.id === businessConstants.EntityTypes.Contact_To_Contact_Id) {
                this.selectedChildEntityType = { name: businessConstants.EntityTypesName.contact_to_entity, id: businessConstants.EntityTypes.Contact_To_Contact_Id };
                this.selectedReciprocalRelationshipType = this.selectedItem;
            } else if (event.id === businessConstants.EntityTypes.Contact_Id) {
                this.selectedChildEntityType = { name: businessConstants.EntityTypesName.contact, id: businessConstants.EntityTypes.Contact_Id };
            }
        }
    }

    reciprocalValueChange(tappedItem) {
        if (this.selectedChildEntityType.id === businessConstants.EntityTypes.Contact_To_Contact_Id && this.selectedParentEntityType.id === businessConstants.EntityTypes.Contact_To_Contact_Id) {
            this.selectedItem = tappedItem;
        }
    }

    valueChange(tappedItem) {
        this.selectedReciprocalRelationshipType = tappedItem;
    }

    private _initExistRelationshipTypes() {
        this._destroySubscriptions.push(
            this._relationshipTypeService.getAll().subscribe(res => {
                if (res) {
                    let relationshipTypes = res['relationship-type-list'].map(obj => ({ name: obj.name, id: obj.id }));
                    relationshipTypes = relationshipTypes.filter(item => item.id !== businessConstants.RelationshipTypes.IS_IN_TEAM_ID && item.id !== businessConstants.RelationshipTypes.TOPIC_MEMBER_ID);
                    this.dataItems = this.suggestionItems = relationshipTypes;
                    ArrayHelperService.sort(this.suggestionItems, NAME);
                    this.selectedItem = this.selectedReciprocalRelationshipType = this.suggestionItems[0];
                }
            })
        );
    }

    private _initEntityTypes() {
        this._destroySubscriptions.push(
            this._entityTypeService.getAdminPageEntityList().subscribe(res => {
                const entityTypeItems = res['entity-type-list'].map(i => ({ name: i.name, id: i.id }));
                this.childDataItems = this.childEntityTypeItems = entityTypeItems.concat({ name: businessConstants.EntityTypesName.entity_to_contact, id: businessConstants.EntityTypes.Contact_To_Contact_Id });
                this.parentDataItems = this.parentEntityTypeItems = entityTypeItems.concat({ name: businessConstants.EntityTypesName.contact_to_entity, id: businessConstants.EntityTypes.Contact_To_Contact_Id });
                ArrayHelperService.sort(this.childEntityTypeItems, NAME);
                ArrayHelperService.sort(this.parentEntityTypeItems, NAME);
                this.selectedParentEntityType = this.selectedChildEntityType = entityTypeItems.filter(item => item.name === businessConstants.EntityTypesName.corporate)[0];
            })
        );
    }

    private _searching(obj) {
        this.searchValue = '';
        if (obj.type === ControlType.relationshipType || obj.type === ControlType.reciprocalRelationshipType) {
            this.suggestionItems = this.dataItems.filter((content) => content?.name?.toLowerCase().indexOf(obj.value?.toLowerCase()) !== -1).sort();
        }
        if (obj.type === ControlType.parentEntityType) {
            this.parentEntityTypeItems = this.parentDataItems.filter((content) => content?.name?.toLowerCase().indexOf(obj.value?.toLowerCase()) !== -1).sort();
        }
        if (obj.type === ControlType.childEntityType) {
            this.childEntityTypeItems = this.childDataItems.filter((content) => content?.name?.toLowerCase().indexOf(obj.value?.toLowerCase()) !== -1).sort();
        }
        this.isHasSearchValue = this.dataItems.findIndex((content) => content?.name?.toLowerCase() === obj.value?.toLowerCase()) !== -1;
        if (!this.isHasSearchValue && obj.value) {
            this.searchValue = obj.value;
        }
    }
}
