<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{this.config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container" tid="operator-content-container">
        <div class="operator" [ngClass]="{'width-180': operator.value === 'range'}" *ngIf="!this.config.hideOperators">
            <kendo-dropdownlist [(ngModel)]="operator" [data]="operators" [textField]="'name'" [valueField]="'value'"
                style="width: 100%;" (valueChange)="valueChange()">
            </kendo-dropdownlist>
        </div>
        <div class="content">
            <kendo-multiselect #entitylist [data]="suggestionItems" [filterable]="true"
                (filterChange)="handleFilter($event)" [textField]="'shortName'" [valueField]="'id'"
                [(ngModel)]="selectedItems" (valueChange)="valueChange()">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
                </ng-template>
            </kendo-multiselect>
        </div>
    </div>
    <div *ngIf="!this.config.isHiddenRelationship">
        <multi-sel-dropdown-filter [dropdownConfig]="dropdownConfig">
            <ng-template #multiCustomContent>
                <div class="relationship-row">
                    <span
                        [ngClass]="{'relationship-text-unclick': selectedRelationships && selectedRelationships.length > 0,'relationship-text-click': !selectedRelationships || selectedRelationships.length == 0}"
                        (click)="onAddRelationClick($event, 1)">{{btnText}}</span>
                    <span class="relationship-click" (click)="onAddRelationClick($event)">{{relationshipNames}}</span>
                    <smart-icon name="trash" (click)="onBtnRemoveRelationshipClicked()"
                        *ngIf="selectedRelationships && selectedRelationships.length > 0"></smart-icon>
                </div>
            </ng-template>
        </multi-sel-dropdown-filter>
    </div>
</div>