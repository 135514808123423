export class Dataprovider {
    id: string;
    name: string;
    fileName: string;
    entityReference: string;
    columns: Array<ProviderColumn>;
    description: string;
    providerColumn: string;
    type: number;
    aliasPrefix: string;
    previewRows: Array<Array<string>>;
    serverName: string;
    lastEditedDate: number;

    static parse(serverResponse): Dataprovider {
        const result = new Dataprovider();
        result.id = serverResponse['id'];
        result.type = serverResponse['type'];
        result.name = serverResponse['name'];
        result.fileName = serverResponse['filename'];
        result.entityReference = serverResponse['entityReference'];
        result.columns = serverResponse['columns'];
        result.description = serverResponse['description'];
        result.providerColumn = serverResponse['providerColumn'];
        result.aliasPrefix = serverResponse['aliasPrefix'];
        result.previewRows = serverResponse['previewRows'];
        result.serverName = serverResponse['servername'];
        result.lastEditedDate = serverResponse['lastEditedDate'];
        return result;
    }
}

export class ProviderColumn {
    name: string;
    type: ProviderColumnType;

    constructor(name: string, type: ProviderColumnType) {
        this.name = name;
        this.type = type;
    }
}

export enum ProviderColumnType {
    STRING = 'character varying(255)',
    DATE = 'date',
    NUMBER = 'numeric',
    TEXT = 'text',
    TIMESTAMP_WITH_TIME_ZONE = 'timestamp with time zone',
    TIMESTAMP = 'timestamp',
    TIME = 'time',
    BYTEA = 'bytea',
    INT = 'int',
}
