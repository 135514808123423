<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{this.config.title}}</div>
        </div>
        <div class="restrict-list" *ngIf="config.isShowTypeFilter" [ngClass]="{'disabled-restrict-list':isOnlyEvent}">
            <label>Restrict list to: </label>
            <div>
                <multi-sel-dropdown-filter [dropdownConfig]="typeConfig">
                    <ng-template #multiCustomContent>
                        <div class="selected-item">
                            <a (click)="onSelectTypeClick($event)">{{typeName}}</a>
                        </div>
                    </ng-template>
                </multi-sel-dropdown-filter>
            </div>
        </div>
        <div class="action" [style.visibility]="(!config.isHiddenCloseIcon && isItemHoverOn)?'visible':'hidden'">
            <smart-icon [ngClass]="" name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container body-3" tid="operator-content-container">
        <div class="operator" *ngIf="!this.config.hideOperators">
            <kendo-dropdownlist [popupSettings]="{appendTo: config.viewContainer}" [(ngModel)]="defaultItem"
                [data]="operators" [textField]="'name'" [valueField]="'value'" style="width: 100%;"
                (valueChange)="valueChange($event)" [disabled]="config.disable">
            </kendo-dropdownlist>
        </div>
        <div class="content">
            <multi-sel-dropdown-filter [dropdownConfig]="dropdownConfig"></multi-sel-dropdown-filter>
        </div>
    </div>
</div>