/**
 * Created by Yu Zhang on 8/14/17.
 * Description:
 *
 * ------ maintenance history ------
 * modified by Marcus Zhao 6/11/2019
 * add prefix and smartReducer for ngrxstore
 */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
    GET_ENTRY_TYPE, CLEAN_ENTRY_TYPE,
    GET_ENTRY_TEMPLATE_CONFIGUARTION, CLEAN_ENTRY_TEMPLATE_CONFIGURATION,
    CLEAN_TEMPLATE_MSG,
    CREATE_RELATIONSHIP, CREATE_RELATIONSHIPS,
    GET_PRIVACY_TEAMS, CLEAN_PRIVACY_TEAMS,
    GET_PRIVACY_CONFIGUARTION, CLEAN_PRIVACY_CONFIGURATION,
    GET_THREAD_ATTACHMENT, CLEAR_THREAD_ATTACHMENT,
    UPDATE_PARENTNOTE_ENTITIES,
} from '../../tamalelibs/redux/template.actions';
import { GET_NOTE_BODY_FOR_EDIT, CLEAN_NOTE_BODY_FOR_EDIT, CREATE_NOTE, UPDATE_NOTE } from '../../tamalelibs/redux/note.actions';
import {
    getAttachmentsState, getEntryTemplateConfigurationState, getEntryTypeState,
    getNoteBodyState, getPrivacyConfigurationState, getPrivacyTeamsState,
    getTemplateMsgState, getTemplateValueSetState, getTemplateAutoSaveState,
    getEntryForEditState, getEntryTypeForWebState, getEntityTemplateConfigurationState
} from '../../tamalelibs/redux/template.reducer';
import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { smartSelector } from '../../redux';
import { IAppState } from '../../tamalelibs/redux/app.state';
import { GetEntityTemplateConfiguration, GetCleanTemplateValueSet, CleanTemplateMsg } from '../../redux/actions/template-web.actions';


@Injectable()
export class WebTemplateViewModel {
    constructor(private _store: Store<IAppState>) {
    }

    noteType(prefixId: string = ''): Observable<any> { // May refactor native code to delete this. Web should not use this
        return this._store.select(getEntryTypeState);
        // return this._store.select(this.relativeSelector(prefixId, getEntryTypeState));
    }

    noteTypeForWeb(prefixId: string = ''): Observable<any> {
        // return this._store.select(getEntryTypeForWebState);
        return this._store.select(this.relativeSelector(prefixId, getEntryTypeForWebState));
    }

    noteForEdit(prefixId: string = ''): Observable<any> {
        //  return this._store.select(getEntryForEditState);
        return this._store.select(this.relativeSelector(prefixId, getEntryForEditState));
    }

    autoSave(prefixId: string = ''): Observable<any> {
        return this._store.select(this.relativeSelector(prefixId, getTemplateAutoSaveState));
    }

    noteTemplateConfiguration(prefixId: string = '') {
        //  return this._store.select<any>(getEntryTemplateConfigurationState);
        return this._store.select(this.relativeSelector(prefixId, getEntryTemplateConfigurationState));
    }

    private relativeSelector(selectid, selector) {
        return (state) => {
            return selector(smartSelector(selectid, 'entryTemplateReducer')(state));
        };
    }

    entityTemplateConfiguration(prefixId: string = '') {
        //    return this._store.select<any>(getEntityTemplateConfigurationState);
        return this._store.select(this.relativeSelector(prefixId, getEntityTemplateConfigurationState));
    }

    templateValueSet(prefixId: string = '') {
        // return this._store.select<any>(getTemplateValueSetState);
        return this._store.select(this.relativeSelector(prefixId, getTemplateValueSetState));
    }

    templateMsg(prefixId: string = '') {
        // return this._store.select<any>(getTemplateMsgState);
        return this._store.select(this.relativeSelector(prefixId, getTemplateMsgState));
    }

    privacyTeams(prefixId: string = ''): Observable<any> {
        //  return this._store.select<any>(getPrivacyTeamsState);
        return this._store.select(this.relativeSelector(prefixId, getPrivacyTeamsState));
    }

    privacyConfiguration(prefixId: string = '') {
        // return this._store.select<any>(getPrivacyConfigurationState);
        return this._store.select(this.relativeSelector(prefixId, getPrivacyConfigurationState));
    }

    noteBodyForEdit(prefixId: string = '') {
        // return this._store.select<any>(getNoteBodyState);
        return this._store.select(this.relativeSelector(prefixId, getNoteBodyState));
    }

    attachments(prefixId: string = '') {
        //  return this._store.select<any>(getAttachmentsState);
        return this._store.select(this.relativeSelector(prefixId, getAttachmentsState));
    }

    getNoteTypes(entryId: string = '', entryClass: string = 'note') {
        const action: any = {
            type: GET_ENTRY_TYPE,
            payload: {
                entryId: entryId,
                entryclass: entryClass
            }
        };
        this._store.dispatch(action);
    }

    getNoteTemplateConfiguration(id: string) {
        this._store.dispatch({ type: GET_ENTRY_TEMPLATE_CONFIGUARTION, payload: id });
    }

    getEntityTemplateConfiguration(id: string, prefixId: string = '') {
        this._store.dispatch(
            new GetEntityTemplateConfiguration(
                id,
                'prefix',
                prefixId
            )
        );
    }

    getPrivacyConfiguration(name: string) {
        this._store.dispatch({ type: GET_PRIVACY_CONFIGUARTION, payload: name });
    }

    getThreadAttachmentsById(editingEntryId: string) {
        this._store.dispatch({ type: GET_THREAD_ATTACHMENT, payload: editingEntryId });
    }

    getPrivacyTeams(configData: any) {
        this._store.dispatch({ type: GET_PRIVACY_TEAMS, payload: configData });
    }

    getNoteBodyForEdit(id: string) {
        this._store.dispatch({ type: GET_NOTE_BODY_FOR_EDIT, payload: id });
    }

    cleanTemplateValueSet(prefixId: string = '') {
        this._store.dispatch(
            new GetCleanTemplateValueSet(
                '',
                'prefix',
                prefixId
            )
        );
    }

    cleanTemplateMsg(prefixId: string = '') {
        this._store.dispatch(
            new CleanTemplateMsg(
                { type: CLEAN_TEMPLATE_MSG },
                'prefix',
                prefixId
            )
        );
    }

    cleanEntryType() {
        this._store.dispatch({ type: CLEAN_ENTRY_TYPE });
    }

    cleanTemplateConfiguration() {
        this._store.dispatch({ type: CLEAN_ENTRY_TEMPLATE_CONFIGURATION });
    }

    cleanNoteBodyForEdit() {
        this._store.dispatch({ type: CLEAN_NOTE_BODY_FOR_EDIT });
    }

    cleanPrivacyTeams() {
        this._store.dispatch({ type: CLEAN_PRIVACY_TEAMS });
    }

    cleanPrivacyConfiguration() {
        this._store.dispatch({ type: CLEAN_PRIVACY_CONFIGURATION });
    }

    clearThreadAttachments() {
        AppConfig.fileUploadedDictionary.clear();
        this._store.dispatch({ type: CLEAR_THREAD_ATTACHMENT });
    }

    cleanEntryTemplate() {
        this.cleanEntryType();
        this.cleanTemplateConfiguration();
        this.cleanTemplateMsg();
        this.cleanTemplateValueSet();
        this.cleanNoteBodyForEdit();
        this.cleanPrivacyConfiguration();
        this.cleanPrivacyTeams();
        this.clearThreadAttachments();
    }

    createNote(params, wrappedData, draft: boolean = false, auto: boolean = false, updateThreadEntity = null, intralinkData?) {
        this._store.dispatch({
            type: CREATE_NOTE,
            payload: {
                params: params,
                data: wrappedData,
                draft: draft,
                auto: auto,
                updateThreadEntity: updateThreadEntity,
                intralinkData: intralinkData
            }
        });
    }

    updateNote(noteId, params, wrappedData, draft: boolean = false, auto: boolean = false, updateThreadEntity = null, updateAddress = false) {
        this._store.dispatch({
            type: UPDATE_NOTE,
            payload: {
                noteId: noteId,
                params: params,
                data: wrappedData,
                draft: draft,
                auto: auto,
                updateThreadEntity: updateThreadEntity,
                updateAddress: updateAddress
            }
        });
    }
    // TODD need to delete this
    createRelationships(relationshipArray) {
        this._store.dispatch({ type: CREATE_RELATIONSHIPS, payload: relationshipArray });
    }

    createRelationship(relationship) {
        this._store.dispatch({ type: CREATE_RELATIONSHIP, payload: relationship });
    }

    // updateThreadEntities(parentNoteId, entityIds) {
    //     this._store.dispatch({ type: UPDATE_PARENTNOTE_ENTITIES, payload: { parentNoteId: parentNoteId, entityIds: entityIds } });
    // }

}
