/*
    * input data
    * dataItem has dot type property, include 'success', 'info' and 'error', if not has type property, default 'info';
    * data item right content support templete
    *
    * usage 1
    * <tam-timeline [data]="data">
    * </tam-timeline>
    *
    * usage 2
    * <tam-timeline [data]="data">
    *   <ng-template #content let-dataItem="dataItem">
    *       <span>{{dateitem.userName}}</span>
    *   </ng-template>
    * </tam-timeline>
*/

import { Component, OnInit, Input, TemplateRef, ContentChild } from '@angular/core';
import { CIRCLE_TYPE } from './timeline.model';

@Component({
    selector: 'tam-timeline',
    templateUrl: './timeline.component.html',
    styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

    @Input() data: Array<any> = [];
    @ContentChild('content', { static: false }) content: TemplateRef<any>;

    circle_color = CIRCLE_TYPE;

    constructor() { }

    ngOnInit() { }

    trackByFn(index) {
        return index;
    }
}
