/**
 * Created by Lucas Wang 09/02/2021
 *
 * Filter used for keyword search for users in users editor
 *
 * ------ maintenance history ------
 * Modified by Mitty Zhang on 7/20/2022: develop for All user should be listed in the dropdown without typing to search[TAM-37947]
 *
 */

import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user.model';

@Pipe({ name: 'userListFilter' })
export class UserListFilter implements PipeTransform {
    transform(userList: User[], keyword: string): any {
        // Remove the check if the keyword is empty.All user should be listed in the dropdown without typing to search.
        if (!userList) {
            return [];
        }

        if (keyword === '') {
            return userList.sort((a, b) => {
                return a.username.localeCompare(b.username);
            });
        }

        keyword = keyword.toLowerCase();
        return userList.filter((user: User) => {
            return user.username.toLowerCase().indexOf(keyword) !== -1;
        });
    }
}
