/**
 * Created by Marcus Zhao on 6/14/19.
 * Description: add entityDialogOpenOption for Create Entity Dialog
 *
 * ------ maintenance history ------
 * Modified by Lucas Wang on 09/06/21.
 * Description: add optional primaryEmail, jobTitle, companyWeb for User Editor
 */

import { Entity } from '../../tamalelibs/models/entity.model';

export class EntityDialogOpenOptions {
    openType: EntityDialogType;
    minimize: boolean;
    entityId: string;
    entityName: string;
    instanceId = '';
    controlId: string;
    searchValue: string;
    isReOpen = false;
    entityType?: Array<any>; // create on the fly send entityType
    openTab = 0;
    eventKey?: string;
    eventValue?: string;
    isCreateValidEntity = true;
    isOfficeAddinMode = false;
}

export enum EntityDialogType {
    NewEntity = 'New Entity',
    EditEntity = 'Edit Entity'
}

export class EntityBack {
    controlId: string;
    searchValue: string;
    entityId: string;
    entity: Entity;
    isCreateValidEntity = true;
    currentId: string;
    primaryEmail?: string;
    jobTitle?: string;
    companyWeb?: object;
    isEditMode = false;
}

export enum EntityEditStatusActionType {
    EDIT_ENTITY_CREATE_ENTRY_ALL_SUCCESS = 'EDIT_ENTITY_CREATE_ENTRY_ALL_SUCCESS',
}

export enum EntityAddressType {
    Primary = 'Primary',
    Other = 'Other '
}

