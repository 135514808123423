/**
 * Created by Alex, Xia on 10/22/2021.
 * Description:
 * {"UT Code Coverage":{"Reviewed":"12/3/2021","Priority":"Low","Comments":"Do not need to add UT.","TargetRate":"0","Author":"Daniel Wang"}}
 * PluginError Component
 * ------ maintenance history ------
 */
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CopyService } from '../../services/copy.service';
@Component({
    selector: 'tam-plugin-error',
    templateUrl: './plugin-error.component.html',
    styleUrls: ['./plugin-error.component.scss']
})
export class PluginErrorComponent implements OnInit, AfterViewInit {
    @ViewChild('copyArea', { static: false }) copyArea;
    isCopySuccess = false;
    pluginUrl: string;
    constructor(
        private _copyService: CopyService,
    ) { }
    ngOnInit() {
        this.pluginUrl = this._getDesktopPlugins();
    }

    ngAfterViewInit(): void {
        this._copyService.copyArea = this.copyArea.nativeElement;
    }

    onCopyLinkClick(): void {
        const success = this._copyService.copy(this.pluginUrl);
        if (success) {
            this.isCopySuccess = true;
        } else {
            this.isCopySuccess = false;
        }
    }

    private _getDesktopPlugins(): string {
        const _hashtime = new Date().getTime();
        const url = 'https://' + window.location.host + '/installer/Advent.Tamale.NextGen.Plugins.Setup.msi?version=' + _hashtime;
        return url;
    }

}
