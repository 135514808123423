import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from './formula-builder.model';

@Injectable()
export class FormulaBuilderService {

    _open$: Subject<Config> = new Subject<Config>();
    _result$: Subject<any> = new Subject<any>();
    _error$: Subject<any> = new Subject<any>();
    _close$: Subject<any> = new Subject<any>();

    open(config = new Config()) {
        this._open$.next(config);
        return this._result$;
    }

    showError(error?) {
        this._error$.next(error);
    }

    close() {
        this._close$.next();
    }
}
