/**
 * Created by Daniel on 12/16/18.
 * Description:
 *
 * ------ maintenance history ------
 * 08/25/2020 marcus add getUnCategorizedId()
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../models/app-config.model';
import { SavedScreen } from '../models/saved-screen.model';
import { TransportService } from './transport.service';

@Injectable()
export class ScreenService {
    constructor(
        private _transportService: TransportService
    ) { }

    create(screenData: SavedScreen): Observable<any> {
        const url = `${AppConfig.screenEndpoint}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const formData = new URLSearchParams();
        formData.set('screen', JSON.stringify(screenData));
        formData.set('showpermission', 'true');
        const params = {
            expand: 'entity',
            showpermission: true,
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    deleteById(id): Observable<any> {
        const url = AppConfig.screenEndpoint + id;
        return this._transportService.delete(url);
    }

    getById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.screenEndpoint}${id}`;
        const params = {
            expand: 'entity',
            showpermission: true,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getVisibleScreens(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.screenEndpoint}`;
        const params = {
            fields: 'id;name;type',
            showpermission: true,
            visible: true
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getAllScreens(): Observable<any> {
        const url = AppConfig.screenEndpoint;
        const params = {
            expand: 'entity',
            showpermission: true,
        };

        const options = {
            params: params
        };
        return this._transportService.get(url, options);
    }

    update(screenData: SavedScreen) {
        const url = `${AppConfig.screenEndpoint}${screenData.id}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const formData = new URLSearchParams();
        formData.set('screen', JSON.stringify(screenData));
        formData.set('showpermission', 'true');
        const params = {
            expand: 'entity',
            showpermission: true,
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, formData.toString(), options);
    }

    getUnCategorizedId(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.defaultUnCategorizedId}`;
        const options = {
            headers: headers,
            responseType: 'text',
        };
        return this._transportService.get(url, options);
    }
}
