/**
 * Created by Daniel Wang on 1/20/2024.
 * Description: Button Component
 * ------ maintenance history ------
 */

import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Subject } from 'rxjs';
import { AdminPageFeedback, AdminPageFeedbackTypes } from '../admin-page-setting.model';

@Component({
    selector: 'tam-admin-page-button',
    templateUrl: './admin-page-button.component.html',
    styleUrls: ['./admin-page-button.component.scss']
})
export class AdminPageButtonComponent implements OnInit, ICellRendererAngularComp {
    private _data: any;
    private _feedbackSubject$: Subject<AdminPageFeedback>;
    constructor() { }

    ngOnInit() { }

    /** Initialize this widget's data within the grid */
    agInit(params: any) {
        this._data = params.data;
        this._feedbackSubject$ = params.feedbackSubject$();
    }

    /** Refresh widget within the grid */
    refresh(params: any): boolean {
        return false;
    }

    /** Called button click. Dispatches action to grid page */
    onClick() {
        this._feedbackSubject$.next({
            type: AdminPageFeedbackTypes.RUN,
            payload: {
                data: this._data
            }
        });
    }
}
