/**
 * The view type supported by Alert Dialog.
 */
export enum AlertViewType {
    /**
     * The normal view, usually for tamale web.
     */
    NORMAL = 0,

    /**
     * The compact view, usually for tamale add-in.
     */
    COMPACT = 1
}

export class AlertConfig {
    type: AlertTypes;
    title: string;
    icon?: AlertHeaderIcon;
    contents: string[];
    closeButton: AlertButton;
    confirmButton?: AlertButton;
    alertWindowWidth?: number;
    extraButton?: AlertButton;
    eventKey?: string;
    eventValue?: any;
    viewType?: AlertViewType;
    // tslint:disable-next-line: whitespace
    isResetHorizontalMargin? = false;
}

export enum AlertTypes {
    info,	// 0
    warn,	// 1
    error,	// 2
    custom, // 3
    confirm,
}

export class AlertButton {
    text?: string;
    type?: string;
    visible?: boolean;
}

export class AlertHeader {
    title?: string;
    icon?: AlertHeaderIcon;
}

export class AlertHeaderIcon {
    name: string;
    color?: string;
}

export const AlertBtnTypes = {
    primary: 'primary',
    tertiary: 'tertiary',
    destructive: 'destructive',
};

export const COMPACT_VIEW_WIDTH = 270;
