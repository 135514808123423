<div class="container tam-form-field" [ngClass]="{'has-error':requireInvalid}">
    <div class="tam-form-label label-area">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name body-3 text-ellipsis"></field-label>
    </div>
    <kendo-dropdownlist [popupSettings]="{appendTo:'component',width:350}" #kendo class="tam-textbox field-control"
        [data]="kDataSource|async" [textField]="'shortName'" [valueField]="'id'" [listHeight]="210" [filterable]="true"
        [(ngModel)]="value" (valueChange)="onValueChange($event)" (focus)="onFocus()"
        (filterChange)="onHandleFilter($event)" (blur)="onBlur()" [disabled]="!config.editable||config.disabled"
        [ngClass]="{'field-disabled':config.disabled||!config.editable}">
        <ng-template kendoMultiSelectHeaderTemplate>
            <div class="popup-template-clear-header">
                <span class="single-select-Clear" (click)="clearValue()">Clear</span>
            </div>
        </ng-template>
    </kendo-dropdownlist>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
</div>