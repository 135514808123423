/**
 * Created by Yu Zhang on 8/14/17.
 * Description:
 *
 * ------ maintenance history ------
 * 8/12/2021 Simon Zhao added the process for MDL config update action to entryTemplatesConfigurationReducer.
 * 8/18/2021 Simon Zhao removed the handler for the action UPDATE_ENTRY_OR_ENTITY_TEMPLATE_MDL as the case has been covered by the silent update of cached templates.
 * 05/21/2021 marcus zhao added defaultEntryTypeNameReducer for get default entry type name.
 */
import { combineReducers, createSelector } from '@ngrx/store';

import {
    GET_ENTRY_TYPE_SUCCESS, CLEAN_ENTRY_TYPE,
    GET_ENTRY_TEMPLATE_CONFIGUARTION_SUCCESS, GET_ENTRY_TEMPLATE_CONFIGUARTION_FAILURE,
    CLEAN_ENTRY_TEMPLATE_CONFIGURATION, CHANGE_TEMPLATE_VALUE_SET, CLEAN_TEMPLATE_VALUE_SET,
    CLEAN_TEMPLATE_MSG, CREATE_RELATIONSHIP_SUCCESS, CREATE_RELATIONSHIP_FAILURE,
    GET_PRIVACY_CONFIGUARTION_SUCCESS, CLEAN_PRIVACY_CONFIGURATION,
    GET_PRIVACY_TEAMS_SUCCESS, CLEAN_PRIVACY_TEAMS,
    GET_THREAD_ATTACHMENT_SUCCESS, GET_THREAD_ATTACHMENT_FAILURE, CLEAR_THREAD_ATTACHMENT,
    GET_ENTITY_TEMPLATE_CONFIGUARTION_SUCCESS, GET_ENTITY_TEMPLATE_CONFIGUARTION_FAILURE, CLEAN_ENTITY_TEMPLATE_CONFIGURATION, TemplateActions, TemplateActionTypes, UPdateEntryOrEntityTemplateMDL
} from './template.actions';
import {
    CREATE_NOTE_SUCCESS, CREATE_NOTE_FAILURE, UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAILURE,
    GET_NOTE_BODY_FOR_EDIT_SUCCESS, GET_NOTE_BODY_FOR_EDIT_FAILURE, CLEAN_NOTE_BODY_FOR_EDIT,
    CREATE_DRAFT_SUCCESS, UPDATE_DRAFT_SUCCESS, CREATE_DRAFT_FAILURE, UPDATE_DRAFT_FAILURE,
    AUTO_CREATE_DRAFT_SUCCESS, AUTO_UPDATE_DRAFT_SUCCESS, AUTO_CREATE_DRAFT_FAILURE,
    AUTO_UPDATE_DRAFT_FAILURE, DELETE_NOTE_SUCCESS, DELETE_SIDENOTE_SUCCESS, DELETE_NOTE_FAILURE,
    DELETE_SIDENOTE_FAILURE, CREATE_EVENT_SUCCESS, UPDATE_EVENT_SUCCESS, CREATE_EVENT_FAILURE,
    UPDATE_EVENT_FAILURE, DELETE_EVENT_SUCCESS, DELETE_EVENT_FAILURE, DELETE_AUTOSAVED_DRAFT_SUCCESS,
    DELETE_AUTOSAVED_DRAFT_FAILURE,
    UPDATE_EVENT_ADDRESS_SUCCESS
} from './note.actions';
import { EntryType } from '../models/entry-type.model';
import { NoteEntry } from '../models/note-entry.model';
import { IMDLTemplate } from '../models/template-mdl.model';

export function entryTemplateReducer(state, action) {
    return combineReducers({
        entryType: combineReducers({
            entry: entryReducer,
            entryTypes: entryTypesReducer,
            defaultEntryTypeId: defaultEntryTypeIdReducer,
        }),
        entryTypeForWeb: combineReducers({
            entryTypes: entryTypesReducer,
            defaultEntryTypeId: defaultEntryTypeIdReducer,
            defaultEntryTypeName: defaultEntryTypeNameReducer,
        }),
        entryTemplateConfiguration: entryTemplateConfigurationReducer,
        entityTemplateConfiguration: entityTemplateConfigurationReducer,
        templateValueSet: templateValueSetReducer,
        templateMsg: templateMsgReducer,
        privacyTeams: privacyTeamsReducer,
        privacyConfiguration: privacyConfigurationReducer,
        noteBody: noteBodyReducer,
        attachments: attachmentsReducer,
        autoSave: autoSaveReducer,
        entry: entryReducer,
    })(state, action);
}

export function entryTemplateReducerA(state, action) {
    return entryTemplateReducer(state, action);
}

// supports lazy loading
export const getEntryTemplateState = (state: any) => state;
//  = createFeatureSelector<any>('entryTemplate'); old code
export const getEntryTypeState = createSelector(getEntryTemplateState, state => state.entryType);
export const getEntryTemplateConfigurationState = createSelector(getEntryTemplateState, state => state.entryTemplateConfiguration);
export const getEntityTemplateConfigurationState = createSelector(getEntryTemplateState, state => state.entityTemplateConfiguration);
export const getTemplateValueSetState = createSelector(getEntryTemplateState, state => state.templateValueSet);
export const getTemplateMsgState = createSelector(getEntryTemplateState, state => state.templateMsg);
export const getPrivacyTeamsState = createSelector(getEntryTemplateState, state => state.privacyTeams);
export const getPrivacyConfigurationState = createSelector(getEntryTemplateState, state => state.privacyConfiguration);
export const getNoteBodyState = createSelector(getEntryTemplateState, state => state.noteBody);
export const getAttachmentsState = createSelector(getEntryTemplateState, state => state.attachments);
export const getTemplateAutoSaveState = createSelector(getEntryTemplateState, state => state.autoSave);
export const getEntryForEditState = createSelector(getEntryTemplateState, state => state.entry);
export const getEntryTypeForWebState = createSelector(getEntryTemplateState, state => state.entryTypeForWeb);

export const entryTemplatesSelector = (state: any) => state.entryTemplates;
export const entityTemplatesSelector = (state: any) => state.entityTemplates;

export function entryReducer(state: NoteEntry = new NoteEntry(), action) {
    switch (action.type) {
        case GET_ENTRY_TYPE_SUCCESS:
        case AUTO_CREATE_DRAFT_SUCCESS:
        case AUTO_UPDATE_DRAFT_SUCCESS:
            return action.payload.entry;
        case CLEAN_ENTRY_TYPE:
            return new NoteEntry();
        default:
            return state;
    }
}

export function entryTypesReducer(state: Array<EntryType> = [], action) {
    switch (action.type) {
        case GET_ENTRY_TYPE_SUCCESS:
            return action.payload.entryTypes;
        case CLEAN_ENTRY_TYPE:
            return [];
        default:
            return state;
    }
}

export function defaultEntryTypeIdReducer(state: string = '', action) {
    switch (action.type) {
        case GET_ENTRY_TYPE_SUCCESS:
            return action.payload.defaultEntryTypeId;
        case CLEAN_ENTRY_TYPE:
            return '';
        default:
            return state;
    }
}

export function defaultEntryTypeNameReducer(state: string = '', action) {
    switch (action.type) {
        case GET_ENTRY_TYPE_SUCCESS:
            return action.payload.defaultEntryTypeName;
        case CLEAN_ENTRY_TYPE:
            return '';
        default:
            return state;
    }
}

export function entryTemplateConfigurationReducer(state: any = {}, action) {
    switch (action.type) {
        case GET_ENTRY_TEMPLATE_CONFIGUARTION_SUCCESS:
            return action.payload;
        case GET_ENTRY_TEMPLATE_CONFIGUARTION_FAILURE:
            return {};
        case CLEAN_ENTRY_TEMPLATE_CONFIGURATION:
            return {};
        default:
            return state;
    }
}

export function entryTemplatesConfigurationReducer(state: Map<string, any> = new Map(), action: TemplateActions) {
    let newState: Map<string, any>;
    switch (action.type) {
        case TemplateActionTypes.ADD_ONE_ENTRY_TEMPLATE_CONFIGURATION:
            newState = new Map(state);
            newState.set(action.payload.id, action.payload.template);
            return newState;
        default:
            return state;
    }
}

export function entityTemplateConfigurationReducer(state: any = {}, action) {
    switch (action.type) {
        case GET_ENTITY_TEMPLATE_CONFIGUARTION_SUCCESS:
            return Object.assign({}, action.payload);
        case GET_ENTITY_TEMPLATE_CONFIGUARTION_FAILURE:
            return {};
        case CLEAN_ENTITY_TEMPLATE_CONFIGURATION:
            return {};
        default:
            return state;
    }
}

export function entityTemplatesConfigurationReducer(state: Map<string, any> = new Map(), action: TemplateActions) {
    let newState: Map<string, any>;
    switch (action.type) {
        case TemplateActionTypes.ADD_ONE_ENTITY_TEMPLATE_CONFIGURATION:
            newState = new Map(state);
            newState.set(action.payload.id, action.payload.template);
            return newState;
        default:
            return state;
    }
}

export function templateValueSetReducer(state: any = {}, action) {
    switch (action.type) {
        case CHANGE_TEMPLATE_VALUE_SET:
            const valueSet = Object.assign({}, state);
            valueSet[action.payload.controlId] = Object.assign({}, action.payload);
            return valueSet;
        case CLEAN_TEMPLATE_VALUE_SET:
            return {};
        default:
            return state;
    }
}

export function templateMsgReducer(state: any = '', action) {
    switch (action.type) {
        case CREATE_NOTE_SUCCESS:
        case UPDATE_NOTE_SUCCESS:
        case CREATE_DRAFT_SUCCESS:
        case UPDATE_DRAFT_SUCCESS:
        case AUTO_CREATE_DRAFT_SUCCESS:
        case AUTO_UPDATE_DRAFT_SUCCESS:
        case CREATE_RELATIONSHIP_SUCCESS:
        case DELETE_NOTE_SUCCESS:
        case DELETE_EVENT_SUCCESS:
        case DELETE_SIDENOTE_SUCCESS:
        case DELETE_AUTOSAVED_DRAFT_SUCCESS:
        case CREATE_EVENT_SUCCESS:
        case UPDATE_EVENT_SUCCESS:
        case UPDATE_EVENT_ADDRESS_SUCCESS:
            return action.type;
        case CREATE_NOTE_FAILURE:
        case UPDATE_NOTE_FAILURE:
        case CREATE_DRAFT_FAILURE:
        case UPDATE_DRAFT_FAILURE:
        case AUTO_CREATE_DRAFT_FAILURE:
        case AUTO_UPDATE_DRAFT_FAILURE:
        case CREATE_RELATIONSHIP_FAILURE:
        case DELETE_NOTE_FAILURE:
        case DELETE_EVENT_FAILURE:
        case DELETE_AUTOSAVED_DRAFT_FAILURE:
        case CREATE_EVENT_FAILURE:
        case UPDATE_EVENT_FAILURE:
        case DELETE_SIDENOTE_FAILURE:
            return action.type + ': ' + action.payload;
        case CLEAN_TEMPLATE_MSG:
            return '';
        default:
            return state;
    }
}

export function privacyTeamsReducer(state: any = {}, action) {
    switch (action.type) {
        case GET_PRIVACY_TEAMS_SUCCESS:
            return action.payload;
        case CLEAN_PRIVACY_TEAMS:
            return {};
        default:
            return state;
    }
}

export function privacyConfigurationReducer(state: any = {}, action) {
    switch (action.type) {
        case GET_PRIVACY_CONFIGUARTION_SUCCESS:
            return action.payload;
        case CLEAN_PRIVACY_CONFIGURATION:
            return {};
        default:
            return state;
    }
}

export function noteBodyReducer(state: any = '', action) {
    switch (action.type) {
        case GET_NOTE_BODY_FOR_EDIT_SUCCESS:
            return action.payload;
        case GET_NOTE_BODY_FOR_EDIT_FAILURE:
            return '';
        case CLEAN_NOTE_BODY_FOR_EDIT:
            return '';
        default:
            return state;
    }
}

export function attachmentsReducer(state: any = {}, action) {
    switch (action.type) {
        case GET_THREAD_ATTACHMENT_SUCCESS:
            return action.payload;
        case GET_THREAD_ATTACHMENT_FAILURE:
        case CLEAR_THREAD_ATTACHMENT:
            return {};
        default:
            return state;
    }
}

export function autoSaveReducer(state: any = {}, action) {
    switch (action.type) {
        case AUTO_CREATE_DRAFT_SUCCESS:
        case AUTO_UPDATE_DRAFT_SUCCESS:
            return action;
        default:
            return state;
    }
}

//#region snippets for updating MDL configs for cached templates
// keep code below for a little while longer in case we want to combine this with current silent template update triggered by websocket messages in consideration of performance.

/**
 * traverse through the template to find the control with the given id.
 * @param targetCtrl the out parameter for passing out the target control with the given control id.
 * @param template the template may contain the target control
 * @param controlId the id of the target control.
 */
export function searchControlWithinTemplate(targetCtrl: { value: any }, template: { contains: Array<any> }, controlId: string) {
    if (!!template && !!template['contains']) {
        template.contains.forEach(tcArray => {
            Array.from(tcArray).forEach(
                (tc: any) => {
                    if (tc['controlID'] === controlId) {
                        // target control is found
                        targetCtrl.value = tc;
                        return;
                    } else if ('contains' in tc) {
                        // another array of controls, make a recursive call of self.
                        searchControlWithinTemplate(targetCtrl, tc, controlId);
                    }
                }
            );
        });
    }
}

/**
 * traverse through the template to find the control of the given type.
 * @param targetCtrls the out parameter for passing out the target controls of the given type.
 * @param template the template may contain the target control
 * @param controlType the type of the target controls.
 */
export function searchControlsWithinTemplateByType(targetCtrls: any[], template: { contains: Array<any> }, controlType: string) {
    if (!!template && !!template['contains']) {
        template.contains.forEach(tcArray => {
            Array.from(tcArray).forEach(
                (tc: any) => {
                    if (tc['componentType'] === controlType) {
                        // target control is found
                        targetCtrls.push(tc);
                    } else if ('contains' in tc) {
                        // another array of controls, make a recursive call of self.
                        searchControlsWithinTemplateByType(targetCtrls, tc, controlType);
                    }
                }
            );
        });
    }
}

function refreshMDLConfigForTemplate(newState: Map<string, any>, typeId: string, mdlTemplate: IMDLTemplate) {
    if (newState.has(typeId) && mdlTemplate) {
        // the related template has been cached, so we have to find it out and update MDL config for it.
        const typeTemplate = newState.get(typeId);
        const mdlCtrls = [];
        // collection all mdl inputs within the template.
        searchControlsWithinTemplateByType(mdlCtrls, typeTemplate, 'mdl-input');
        // clear the existing mdl configs for all mdl controls.
        mdlCtrls.forEach(mc => {
            if (mc.mdlProvider) {
                delete mc.mdlProvider;
            }
        });
        const mdlMap = mdlTemplate.mdlTemplate;
        mdlMap.forEach(
            (mdlCfg, contrlId) => {
                // loop affected controls.
                const ctrl = { value: undefined };
                searchControlWithinTemplate(ctrl, typeTemplate, contrlId);
                if (!!ctrl.value) {// update the mdl config for the corresponding control on the entry type template.
                    ctrl.value.mdlProvider = mdlCfg.mdlProvider;
                }
            }
        );
    }
}

//#endregion
