/**
 * Created by Yu Zhang on 4/17/17.
 * Description:
 *
 * ------ maintenance history ------
 * 02/06/20 Marcus Zhao add CONTACT_TEMPLATE;
 */


// entity object
export const GET_ENTITY = 'GET_ENTITY';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_FAILURE = 'GET_ENTITY_FAILURE';
export const GET_SLIDESHEET_ENTITY = 'GET_SLIDESHEET_ENTITY';
export const GET_SLIDESHEET_ENTITY_SUCCESS = 'GET_SLIDESHEET_ENTITY_SUCCESS';
export const GET_SLIDESHEET_ENTITY_FAILURE = 'GET_SLIDESHEET_ENTITY_FAILURE';
export const CLEAN_ENTITY = 'CLEAN_ENTITY';
export const CLEAN_ENTITY_MSG = 'CLEAN_ENTITY_MSG';

// contact search list
export const GET_CONTACT_FIRST_PAGE_WITH_KEYWORD = 'GET_CONTACT_FIRST_PAGE_WITH_KEYWORD';
export const GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_SUCCESS = 'GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_SUCCESS';
export const GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_FAILURE = 'GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_FAILURE';
export const GET_CONTACT_NEXT_PAGE_WITH_KEYWORD = 'GET_CONTACT_NEXT_PAGE_WITH_KEYWORD';
export const GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_SUCCESS = 'GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_SUCCESS';
export const GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_FAILURE = 'GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_FAILURE';
export const CLEAN_CONTACT_SEARCH_LIST = 'CLEAN_CONTACT_SEARCH_LIST';
export const CLEAN_CONTACT_SEARCH_LIST_MSG = 'CLEAN_CONTACT_SEARCH_LIST_MSG';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const CREATE_CONTACT_FAILURE = 'CREATE_CONTACT_FAILURE';
export const CREATE_CONTACT_SUCCESS = 'CREATE_CONTACT_SUCCESS';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS';
export const EDIT_CONTACT_FAILURE = 'EDIT_CONTACT_FAILURE';
export const CONTACT_STATUS = 'CONTACT_STATUS';
export const CONTACT_STATUS_CREATE = 'CONTACT_STATUS_CREATE';
export const CONTACT_STATUS_EDIT = 'CONTACT_STATUS_EDIT';
export const CHANGE_CONTACT_DETAIL = 'CHANGE_CONTACT_DETAIL';

// contact object
export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_SUCCESS = 'GET_CONTACT_SUCCESS';
export const GET_CONTACT_FAILURE = 'GET_CONTACT_FAILURE';
export const GET_SLIDESHEET_CONTACT = 'GET_SLIDESHEET_CONTACT';
export const GET_SLIDESHEET_CONTACT_SUCCESS = 'GET_SLIDESHEET_CONTACT_SUCCESS';
export const GET_SLIDESHEET_CONTACT_FAILURE = 'GET_SLIDESHEET_CONTACT_FAILURE';

export const CLEAN_CONTACT = 'CLEAN_CONTACT';
export const CLEAN_CONTACT_MSG = 'CLEAN_CONTACT_MSG';

// templateConfig
export const GET_TEMPLATE_CONFIG = 'GET_TEMPLATE_CONFIG';
export const GET_TEMPLATE_CONFIG_SUCCESS = 'GET_TEMPLATE_CONFIG_SUCCESS';
export const GET_TEMPLATE_CONFIG_FAILURE = 'GET_TEMPLATE_CONFIG_FAILURE';
export const CLEAN_TEMPLATE_CONFIG = 'CLEAN_TEMPLATE_CONFIG';
export const CLEAN_TEMPLATE_CONFIG_MSG = 'CLEAN_TEMPLATE_CONFIG_MSG';

// templateConfig
export const GET_SLIDESHEET_TEMPLATE_CONFIG = 'GET_SLIDESHEET_TEMPLATE_CONFIG';
export const GET_SLIDESHEET_TEMPLATE_CONFIG_SUCCESS = 'GET_SLIDESHEET_TEMPLATE_CONFIG_SUCCESS';
export const GET_SLIDESHEET_TEMPLATE_CONFIG_FAILURE = 'GET_SLIDESHEET_TEMPLATE_CONFIG_FAILURE';
export const CLEAN_SLIDESHEET_TEMPLATE_CONFIG = 'CLEAN_SLIDESHEET_TEMPLATE_CONFIG';
export const CLEAN_SLIDESHEET_TEMPLATE_CONFIG_MSG = 'CLEAN_SLIDESHEET_TEMPLATE_CONFIG_MSG';

// entity focus
export const CHANGE_ENTITY_FOCUS = 'CHANGE_ENTITY_FOCUS';
export const CLEAR_ENTITY_FOCUS = 'CLEAR_ENTITY_FOCUS';

// routing valid
export const VALIDATE_THREAD_ROUTING = 'VALIDATE_THREAD_ROUTING';
export const THREAD_ROUTING_VALID = 'THREAD_ROUTING_VALID';
export const THREAD_ROUTING_INVALID = 'THREAD_ROUTING_INVALID';
export const VALIDATE_NOTE_ROUTING = 'VALIDATE_NOTE_ROUTING';
export const NOTE_ROUTING_VALID = 'THREAD_ROUTING_VALID';
export const NOTE_ROUTING_INVALID = 'THREAD_ROUTING_INVALID';
export const CLEAN_ROUTING_VALID = 'CLEAN_ROUTING_VALID';

// application status
export const APP_SUSPEND = 'APP_SUSPEND';
export const APP_RESUME = 'APP_RESUME';

// new contact
export const CONTACT_TEMPLATE = 'CONTACT_TEMPLATE';

