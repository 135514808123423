<div *ngIf="checked==='all'" (click)="onSelected('none')">
    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-checked">
        <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
    </div>
</div>
<div *ngIf="checked==='none'" (click)="onSelected('all')">
    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-unchecked">
        <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
    </div>
</div>
<div *ngIf="checked==='some'" class="label-container" (click)="onSelected('all')">
    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-indeterminate">
        <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
    </div>
</div>