import { combineReducers, createSelector, Action } from '@ngrx/store';
import { AppState } from '..';
import { recentNotesReducer } from '../../tamalelibs/redux/thread-list.reducer';
import { entityReducer, templateConfigReducer, entityMsgReducer, slideSheetEntityReducer, templateSlideSheetConfigReducer, slideSheetEntityMsgReducer } from '../../tamalelibs/redux/entity.reducer';

export const getEntityDetailViewState = (state: AppState) => state.entityDetailView;
export const getEntityState = createSelector(getEntityDetailViewState, (state: any) => state.entity);
export const getSlideSheetEntityState = createSelector(getEntityDetailViewState, (state: any) => state.sideSheetEntity);
export const getEntityMsgState = createSelector(getEntityDetailViewState, (state: any) => state.entityMsg);
export const getSlideSheetEntityMsgState = createSelector(getEntityDetailViewState, (state: any) => state.slideSheetEntityMsg);
export const getTemplateConfigState = createSelector(getEntityDetailViewState, (state: any) => state.templateConfig);
export const getTemplateSlideSheetConfigState = createSelector(getEntityDetailViewState, (state: any) => state.templateSlideSheetConfig);
export const getRecentNotesState = createSelector(getEntityDetailViewState, (state: any) => state.recentNotes);

const entityDetailViewReducer = combineReducers({
    entity: entityReducer,
    entityMsg: entityMsgReducer,
    sideSheetEntity: slideSheetEntityReducer,
    slideSheetEntityMsg: slideSheetEntityMsgReducer,
    recentNotes: recentNotesReducer,
    templateConfig: templateConfigReducer,
    templateSlideSheetConfig: templateSlideSheetConfigReducer
});

export function getEntityDetailViewRedcuer(state, action) {
    return entityDetailViewReducer(state, action);
}
