import { SystemUser } from '../../tamalelibs/models/user.model';
import { AuthActions, AuthActionTypes } from '../actions/auth.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const globalStateSelector = createFeatureSelector<any>('global');
export const userStateSelector = createSelector(globalStateSelector, state => state.user);

export function userReducer(state: SystemUser = null, action: AuthActions) {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS: {
            return action.payload;
        }
        case AuthActionTypes.SESSION_TIMEOUT:
        case AuthActionTypes.LOGOUT: {
            return null;
        }
        default:
            return state;
    }
}
