/**
 * Created by Abner Sui on 04/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum DataproviderActionTypes {
    INITIALIZE = '[DATAPROVIDER] INITIALIZE',
    INITIALIZE_SUCCESS = '[DATAPROVIDER] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[DATAPROVIDER] INITIALIZE_FAILED',
    INSERT_UPDATE_CONFIG = '[DATAPROVIDER] INSERT_UPDATE_CONFIG',
    DELETE_CONFIG = '[DATAPROVIDER] DELETE_CONFIG',
}

export class Initialize implements Action {
    readonly type = DataproviderActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = DataproviderActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = DataproviderActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export class InsertUpdate implements Action {
    readonly type = DataproviderActionTypes.INSERT_UPDATE_CONFIG;

    constructor(public payload: any) { }
}

export class Delete implements Action {
    readonly type = DataproviderActionTypes.DELETE_CONFIG;

    constructor(public payload: any) { }
}

export type DataproviderActions = Initialize | InitializeSuccess | InitializeFailed
    | InsertUpdate | Delete;
