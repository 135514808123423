<div class="multi-sel-dropdown-constainer" tamDropDownOutside (clickOutside)="onClickOutside($event)">
    <input #anchor type="text" readonly [value]="inputValue" (focus)="showPopup($event)" *ngIf="!hideInput"
        class="textInput" [disabled]='dropdownConfig.disabledInput'
        [placeholder]="dropdownConfig.placeholder ? dropdownConfig.placeholder : ''">
    <div class="multi-custom-content-container" *ngIf="hideInput">
        <ng-container *ngTemplateOutlet="multiCustomContent;"></ng-container>
    </div>
    <kendo-popup [anchor]="anchor" *ngIf="showSel" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign">
        <div class="selContainer">
            <div class="row" *ngIf="!dropdownConfig.hideHeader">
                <div class="all-left">
                    <span (click)="selAll()" [class.disabled]="getSelData().length === dropdownConfig.data.length">
                        Select All
                    </span>
                </div>
                <div class="all-right">
                    <span (click)="clearSelAll()" [class.disabled]="getSelData().length === 0">
                        Clear All
                    </span>
                </div>
            </div>
            <div class="row" style="position: relative;" *ngIf="!dropdownConfig.hideSearchBox">
                <input type="text" #searchInput placeholder="Search" [(ngModel)]="searchValue" class="searchInput"
                    (input)="searchData()">
                <div class="icon-search">
                    <smart-icon name="search"></smart-icon>
                </div>
            </div>
            <div class="checkContainer">
                <div *ngIf="checkedData.length > 0" [class.line]="checkedData.length > 0 && unCheckedData.length > 0">
                    <div *ngFor="let item of checkedData;trackBy:trackByFn" class="row">
                        <label tam-checkbox [(ngModel)]="item.checked" [disabled]="item.isDisabled"
                            (click)="changeCheckedState(item, $event)" class="label-container">
                            {{item.name}}
                        </label>
                    </div>
                </div>
                <div *ngIf="unCheckedData.length > 0">
                    <div *ngFor="let item of unCheckedData;trackBy:trackByFn" class="row">
                        <label tam-checkbox [(ngModel)]="item.checked" (click)="changeCheckedState(item, $event)"
                            class="label-container">
                            {{item.name}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </kendo-popup>
</div>
