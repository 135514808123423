/**
 * Created by simon zhao on 4/8/2022.
 * Description:
 * CheckListField Component
 * ------ maintenance history ------
 */
import { KeyValue } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { TypeUtilities } from '../../../../services/utilities/type-utilities';
import { ArrayHelperService } from '../../../../tamalelibs/services/array-helper.service';
import { FieldActions, FieldConfig, FieldEvents } from '../field.model';
import * as _lodash from 'lodash';

@Component({
    selector: 'tam-check-list-field',
    templateUrl: './check-list-field.component.html',
    styleUrls: ['./check-list-field.component.scss']
})
export class CheckListFieldComponent implements OnInit, OnDestroy {
    //#region public properties

    @Input() config: FieldConfig;

    @Input() viewMode: 'full' | 'fullWithoutBorder' | 'viewOnly' = 'viewOnly';

    fieldValue: Array<KeyValue<string, boolean>> = [];

    isFieldValueInvalid: boolean;

    //#endregion

    //#region private properties

    /**
    * the subscription array that would be unsubscribed on destroying.
    */
    private _destroySubscriptions: Array<Subscription> = [];

    /**
     * a flag indicating whether the form current field belong to is submitted.
     */
    private _isFieldInvalidStatusVisible: boolean;

    private _originalFieldValue: Array<KeyValue<string, boolean>> = [];

    //#endregion

    //#region public methods

    afterItemCheckChanged(newValue: boolean, index: string) {
        const matchedItem = this.fieldValue.find(it => it.key === index);
        if (matchedItem) {
            matchedItem.value = newValue;
            this.onValueChange();
        }
    }

    /**
     * obtains the description of the check item indicated by the given index.
     * @param index the key of the check item.
     * @returns the description of the check item.
     */
    getItemDescription(index: string) {
        const matchedItem = this.config.checkItems.find(it => it.index.toString() === index);
        return matchedItem && matchedItem.description;
    }

    isFieldItemInvalid(item: KeyValue<string, boolean>) {
        return !!item && !item.value && this.config.checkItems && this.config.checkItems.findIndex(it => it.index.toString() === item.key && it.isRequired) >= 0;
    }

    isPreviewMode() {
        return this.viewMode === 'viewOnly';
    }

    isFullMode() {
        return ['full', 'fullWithoutBorder'].includes(this.viewMode);
    }

    isBorderless() {
        return this.viewMode === 'fullWithoutBorder';
    }

    isAnyFieldInvalid() {
        let isInValid = false;
        if (this.config.checkItems && this.fieldValue) {
            // found any required item has not yet checked.
            isInValid = this.fieldValue.some(it => this.isFieldItemInvalid(it));
        }

        return isInValid;
    }

    ngOnInit() {
        if (this.isPreviewMode()) {
            return;
        }
        this._loadData();
        if (this.config.editable && !this.config.disabled) {
            this._destroySubscriptions.push(
                this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
            );

            this.config.config.feedbackSubject$.next({
                type: FieldEvents.VALIDATE_CHANGE,
                payload: {
                    id: this.config.field.fieldDefinition.id,
                    invalid: this.isAnyFieldInvalid(),
                },
            });
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    onValueChange() {
        if (this._isFieldInvalidStatusVisible) {
            // update the field invalid status only after the form submission is executed once.
            this.isFieldValueInvalid = this.isAnyFieldInvalid();
        }
        // get the changed field values
        const changedFieldValue = this.fieldValue.filter(item => !this._originalFieldValue.some(ele => ele.key === item.key && ele.value === item.value));
        this.config.field.value = TypeUtilities.convertArrayToObj<boolean>(changedFieldValue);

        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: this.config.field.value,
            },
        });

        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.isAnyFieldInvalid(),
            },
        });

    }


    //#endregion

    //#region private methods
    private _loadData() {
        if (this.config && this.config.field && this.config.field.value && TypeUtilities.isValidObj(this.config.field.value)) {
            // load existing check item values.
            this.fieldValue = TypeUtilities.convertObjToArray<boolean>(this.config.field.value);
        }

        if (this.config.checkItems) {
            // found configuration.
            const existingCfgKeys = [];
            this.config.checkItems.forEach(item => {
                if (this.fieldValue.findIndex(it => it.key === item.index.toString()) < 0) {
                    // add new configured items to the list and default them to unchecked.
                    this.fieldValue.push({ key: item.index.toString(), value: false });
                }
                existingCfgKeys.push(item.index.toString());
            });
            // remove those not exist in configuration.
            const existingValueKeys = this.fieldValue.map(it => it.key);
            existingValueKeys.forEach(k => {
                const indexOfExistingValue = this.fieldValue.findIndex(it => it.key === k);
                if (!existingCfgKeys.includes(k) && indexOfExistingValue >= 0) {
                    this.fieldValue.splice(indexOfExistingValue, 1);
                }
            });
        }

        ArrayHelperService.sort<KeyValue<string, boolean>>(this.fieldValue, 'key', true, true);
        this._originalFieldValue = _lodash.cloneDeep(this.fieldValue);
    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.isFieldValueInvalid = this.isAnyFieldInvalid();
            this._isFieldInvalidStatusVisible = true;
        }
    }

    //#endregion
}

export class CheckListItemValue {
    /**
     * the item index, unique in a check list.
     */
    index: number;

    /**
     * a flag indicating whether current item is checked.
     */
    isChecked: boolean;
}
