<div class="container drag-title" (click)="onClick($event)" (mousedown)="mousedown($event)" (mouseup)="mouseup()"
    (mouseover)="mouseover()" (mouseout)="mouseout()" [class.mouseover]="mouseovering" tamTabDrag
    [draggable]="draggable" [showInput]="!!showInput" (documentmouseup)="documentmouseup()"
    (contextmenu)="mouseRightClick()" [class.active-tab]="!!active">
    <div *ngIf="!!iconName" class="icon drag-title">
        <smart-icon [name]="iconName" class="light drag-title" [style.color]="iconColor"></smart-icon>
    </div>
    <span class="title title-1 drag-title" [class.active-span]="!!active" [class.disabled]="!!disabled"
        [hidden]="!!showInput" (dblclick)="dblclick($event)" title="{{inputValue}}">
        {{inputValue || ""}}
    </span>
    <input type="text" [(ngModel)]="inputValue" class="drag-title" (blur)="onBlur()" (keydown)="onkeydown($event)"
        #inputEl [ngClass]="!!showInput ? 'input-title' : 'input-none'" *ngIf="editable" />
</div>
<ng-container *ngIf="!!active && showTempUnderline">
    <div class="js-tab-underline"></div>
</ng-container>