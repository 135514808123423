import { Action } from '@ngrx/store';

import { EntityBrief } from '../../models/entity-brief.model';

export enum SystemActionTypes {
    INIT_CURRENT_CONTACT = '[SYSTEM] INIT_CURRENT_CONTACT',
    INIT_CURRENT_CONTACT_SUCCESS = '[SYSTEM] INIT_CURRENT_CONTACT_SUCCESS',
    INIT_CURRENT_CONTACT_FAILED = '[SYSTEM] INIT_CURRENT_CONTACT_FAILED',
    SET_BROWSER_INFO = '[SYSTEM] SET_BROWSER_INFO',
    // visible screen
    GET_VISIBLE_SCREENS = '[SYSTEM][VISIBLE_SCREEN] GET_VISIBLE_SCREENS',
    GET_VISIBLE_SCREENS_SUCCESS = '[SYSTEM][VISIBLE_SCREEN] GET_VISIBLE_SCREENS_SUCCESS',
    GET_VISIBLE_SCREENS_FAILED = '[SYSTEM][VISIBLE_SCREEN] GET_VISIBLE_SCREENS_FAILED',
    ADD_SCREEN = '[SYSTEM][VISIBLE_SCREEN] ADD_SCREEN',
    DELETE_SCREEN = '[SYSTEM][VISIBLE_SCREEN] DELETE_SCREEN',
    EDIT_SCREEN = '[SYSTEM][VISIBLE_SCREEN] EDIT_SCREEN',
    SWITCH_VISIBLE = '[SYSTEM][VISIBLE_SCREEN] SWITCH_VISIBLE',
    GET_SCREENS = '[MANAGESCREENS] LOAD',
    GET_SCREENS_SUCCESS = '[MANAGESCREENS] LOAD_SUCCESS',
    GET_SCREENS_FAILED = '[MANAGESCREENS] LOAD_FAILED',
    GET_CONTACTS = '[CONTACTS] LOAD',
    GET_CONTACTS_SUCCESS = '[CONTACTS] LOAD_SUCCESS',
    GET_CONTACTS_FAILED = '[CONTACTS] LOAD_FAILED',
    GET_UNCATEGORUZEEID = '[SYSTEM] GET_UNCATEGORUZEEID',
    GET_UNCATEGORUZEEID_SUCCESS = '[SYSTEM] GET_UNCATEGORUZEEID_SUCCESS',
    GET_UNCATEGORUZEEID_FAILED = '[SYSTEM] GET_UNCATEGORUZEEID_FAILED',
}

// get all
export class LoadScreens implements Action {
    readonly type = SystemActionTypes.GET_SCREENS;
}

export class LoadScreensSuccess implements Action {
    readonly type = SystemActionTypes.GET_SCREENS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadScreensFailed implements Action {
    readonly type = SystemActionTypes.GET_SCREENS_FAILED;
    constructor(public payload: any) { }
}

export class LoadContacts implements Action {
    readonly type = SystemActionTypes.GET_CONTACTS;
}

export class LoadContactsSuccess implements Action {
    readonly type = SystemActionTypes.GET_CONTACTS_SUCCESS;
    constructor(public payload: any) { }
}

export class LoadContactsFailed implements Action {
    readonly type = SystemActionTypes.GET_CONTACTS_FAILED;
    constructor(public payload: any) { }
}

export class SystemInitCurrentContactSuccess implements Action {
    readonly type = SystemActionTypes.INIT_CURRENT_CONTACT_SUCCESS;

    constructor(public payload: EntityBrief) { }
}

export class SystemInitCurrentContactFailed implements Action {
    readonly type = SystemActionTypes.INIT_CURRENT_CONTACT_FAILED;

    constructor(public payload: any) { }
}

export class SystemGetVisibleScreenSuccess implements Action {
    readonly type = SystemActionTypes.GET_VISIBLE_SCREENS_SUCCESS;

    constructor(public payload: any) { }
}

export class SystemGetVisibleScreenFailed implements Action {
    readonly type = SystemActionTypes.GET_VISIBLE_SCREENS_FAILED;

    constructor(public payload: any) { }
}

export class SystemAddScreen implements Action {
    readonly type = SystemActionTypes.ADD_SCREEN;

    constructor(public payload: any) { }
}

export class SystemDeleteScreen implements Action {
    readonly type = SystemActionTypes.DELETE_SCREEN;

    constructor(public payload: any) { }
}

export class SystemEditScreen implements Action {
    readonly type = SystemActionTypes.EDIT_SCREEN;

    constructor(public payload: any) { }
}

export class SystemEditScreenVisible implements Action {
    readonly type = SystemActionTypes.SWITCH_VISIBLE;

    constructor(public payload: any) { }
}

export class UnCategorizedId implements Action {
    readonly type = SystemActionTypes.GET_UNCATEGORUZEEID;
    constructor(public payload: any) { }
}

export class UnCategorizedIdSuccess implements Action {
    readonly type = SystemActionTypes.GET_UNCATEGORUZEEID_SUCCESS;

    constructor(public payload: any) { }
}

export class UnCategorizedIdFailed implements Action {
    readonly type = SystemActionTypes.GET_UNCATEGORUZEEID_FAILED;

    constructor(public payload: any) { }
}


export type SystemActions =
    SystemInitCurrentContactSuccess |
    SystemInitCurrentContactFailed |
    SystemGetVisibleScreenSuccess |
    SystemGetVisibleScreenFailed |
    SystemAddScreen |
    SystemDeleteScreen |
    SystemEditScreen |
    SystemEditScreenVisible |
    LoadScreens |
    LoadScreensSuccess |
    LoadScreensFailed |
    LoadContacts |
    LoadContactsSuccess |
    LoadContactsFailed |
    UnCategorizedId |
    UnCategorizedIdSuccess |
    UnCategorizedIdFailed;
