/**
 * ---------maintenance history-------------
 * 2019.5.22 By Bowen Li: make the home screen tabs to fixed width
 * 2019.5.26 By Bowen Li: can direct to the url for once on ipad
 * 06/26/2019 Yoyo Fang right click event handler added.
 */
import {
    Component, OnInit, Input, OnDestroy, AfterViewInit, ElementRef, OnChanges, SimpleChanges, ViewChild, AfterViewChecked, ChangeDetectorRef
} from '@angular/core';
import { TabEventType, TabActionType } from './tab.config';
import { TabSetComponent } from './tab-set.component';
import { LONG_TOUCH_INTERVAL } from '../../tamalelibs/constants/business.constants';
import { Subscription, Subject } from 'rxjs';
import { IdHelperService } from '../../services/id-helper.service';

@Component({
    selector: 'tam-tab',
    templateUrl: './tab.component.html',
    styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges, AfterViewChecked {

    @Input() title?: string;
    @Input() disabled?: boolean;
    @Input() iconName?: string;
    @Input() iconColor?: string;
    @Input() editable?: boolean;
    @Input() editing?: boolean;

    @ViewChild('inputEl', { static: false }) inputEl: ElementRef;

    active: boolean;
    draggable: boolean;
    feedbackSubject$;
    showInput: boolean;
    inputValue: string;
    mouseovering = false;
    showTempUnderline = false;

    private _destroySubscriptions: Array<Subscription> = [];
    private _isMoveTarget = false;
    private _id: string;

    private _triggerClick: boolean;
    private _firstTime = 0;
    private _lastTime = 0;
    private _element: HTMLElement;
    private _afterViewChecked: boolean;
    private _defaultWidth;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _tabSetComponent: TabSetComponent,
        private elementRef: ElementRef) { }

    ngOnInit() {
        this.feedbackSubject$ = new Subject();
        this._element = this.elementRef.nativeElement;
        this.draggable = !!this._tabSetComponent.draggable;
        this._tabSetComponent.subscribeEvent(this);
        this._destroySubscriptions.push(
            this._tabSetComponent.tabActionSubject$.subscribe((event) => {
                this._tabActionHandler(event);
            })
        );
        // set input select, input shold have value before.
        this.inputValue = this.title;

        // set tab-set ids
        this._id = IdHelperService.createGUID(1);
        this.feedbackSubject$.next({
            type: TabEventType.initTabId,
            payload: { id: this._id }
        });
    }

    ngAfterViewInit() {
        this.feedbackSubject$.next({
            type: TabEventType.initTabInfo,
            payload: {
                id: this._id,
                width: this._element.clientWidth,
                left: this._element.offsetLeft,
                title: this.title
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.editing && this.editable && !changes.editing.previousValue && changes.editing.currentValue) {
            this.dblclick();
        }
    }

    ngAfterViewChecked() {
        if (this._afterViewChecked === false) {
            // input blur
            if (this.showInput === false) {
                this._afterViewChecked = true;
                this.showInput = undefined;
                this._onResetTabWidth(this._element.clientWidth, true);
            }
            // dbclick title
            if (this.showInput) {
                this._afterViewChecked = true;
                this._onResetTabWidth(this._element.clientWidth);
            }
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach((item) => item.unsubscribe());
        this.feedbackSubject$.next({
            type: TabEventType.onDeleteTab,
            payload: this._id,
        });
    }

    documentmouseup() {
        this._isMoveTarget = false;
    }

    onClick(event) {
        if (this.disabled) {
            return;
        }
        if ((this._triggerClick && this.draggable) || !event.isTrusted || !this.draggable) {
            this._triggerClick = false;
            this.feedbackSubject$.next({
                type: TabEventType.onTabClick,
                payload: {
                    id: this._id,
                    width: this._element.clientWidth,
                    left: this._element.offsetLeft
                }
            });
        }
    }

    mouseRightClick() {
        this._tabSetComponent.dragging = false;
    }

    mousedown(event) {
        if (this.showInput) {
            return;
        }
        if (!this.draggable) {
            return;
        }
        this._firstTime = Date.now();
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        this._isMoveTarget = true;
        this.mouseovering = false;
        this._tabSetComponent.dragging = true;

        this.feedbackSubject$.next({
            type: TabEventType.onMouseDown,
            payload: this._id
        });
    }

    mouseover() {
        if (this._tabSetComponent.dragging && !this._isMoveTarget) {
            this.mouseovering = true;
        }
    }

    mouseout() {
        this.mouseovering = false;
    }

    mouseup() {
        if (!this.draggable) {
            return;
        }

        this._lastTime = Date.now();
        this._tabSetComponent.dragging = false;
        this.mouseovering = false;

        if (this._lastTime - this._firstTime < LONG_TOUCH_INTERVAL) {
            this._triggerClick = true;
            return;
        }

        this.feedbackSubject$.next({
            type: TabEventType.onMouseUp,
            payload: this._id
        });
    }

    dblclick(event?) {
        if (event) {
            event.stopPropagation();
        }
        if (!this.editable || this.disabled) {
            return;
        }
        this._defaultWidth = this._element.clientWidth;
        this._afterViewChecked = false;
        this.showInput = true;
        this.inputEl.nativeElement.focus();
        this.inputEl.nativeElement.select();
    }

    onBlur() {
        this.showInput = false;
        if (!this.inputValue.trim() || this.inputValue.trim().length > 32) {
            const newValue = this.inputValue;
            this.inputValue = this.title;
            this._onResetTabWidth(this._defaultWidth, true, newValue);
            return;
        }
        this.title = this.inputValue;
        this._onResetTabWidth(this._defaultWidth, true, this.inputValue);
        this._afterViewChecked = false;
    }

    onkeydown(event) {
        if (event && event.keyCode === 13) {
            event.target.blur();
        }
    }

    private _tabActionHandler(event) {
        if (event.type === TabActionType.setActiveTab) {
            this.active = event.payload === this._id;
            this.showInput = false;
            this._changeDetectorRef.detectChanges();
        } else if (event.type === TabActionType.showTempUnderline) {
            this.showTempUnderline = event.payload;
            this._element.style.position = this.showTempUnderline ? 'relative' : '';
            this._changeDetectorRef.detectChanges();
        }
    }

    private _onResetTabWidth(width, blur?, newValue?) {
        this.feedbackSubject$.next({
            type: TabEventType.onEdit,
            payload: {
                id: this._id,
                width: width,
                title: this.title,
                blur: blur,
                newValue: newValue
            }
        });
    }
}
