import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { CustomPreloadingStrategyService } from './custom-preloading-strategy.service';

const appRoutes: Routes = [
    {
        path: 'error',
        data: { preload: false },
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
    },
    {
        path: 'filedownload',
        data: { preload: false },
        loadChildren: () => import('./components/file-download/file-download.module').then(m => m.FileDownloadModule)
    },
    {
        path: 'demo',
        data: { preload: false },
        loadChildren: () => import('./pages/demo/demo.module').then(m => m.DemoModule)
    },
    {
        path: 'sso',
        data: { preload: false },
        loadChildren: () => import('./pages/sso/sso.module').then(m => m.SsoModule)
    },
    {
        path: 'printpreview',
        data: { preload: false },
        loadChildren: () => import('./pages/print/print.module').then(m => m.PrintModule)
    },
    {
        path: 'pluginentry/:id',
        data: { preload: false },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'entitydialog',
        data: { preload: false },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'contactdialog',
        data: { preload: false },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'addinall',
        data: { preload: true },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'addinonlyemail',
        data: { preload: true },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'addinonlyfile',
        data: { preload: true },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'pluginevent/:id',
        data: { preload: false },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'new/popoutentry/:id',
        data: { preload: false },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'draft/popoutentry/:id',
        data: { preload: false },
        loadChildren: () => import('./pages/home-plugin/home-plugin.module').then(m => m.HomePluginModule)
    },
    {
        path: 'rc-dashboard/:id',
        data: { preload: false },
        loadChildren: () => import('./pages/rich-client-dashboard/rich-client-dashboard.module').then(m => m.RichClientDashboardModule)
    },
    {
        path: 'print-dashboard/:id',
        data: { preload: false },
        loadChildren: () => import('./pages/print-dashboard/print-dashboard.module').then(m => m.PrintDashboardModule)
    },
    {
        path: 'webplugin',
        data: { preload: true },
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
    },
    {
        path: '',
        data: { preload: true, loadAfterSeconds: 3 },
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
    },
    { path: '**', redirectTo: 'error' }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, { preloadingStrategy: CustomPreloadingStrategyService, useHash: environment.isOfficeAddin })],
    providers: [CustomPreloadingStrategyService],
    exports: [RouterModule],
})
export class AppRoutingModule { }
