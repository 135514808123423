/**
 * Created by Yoyo Fang on 01/17/2019.
 * Description:
 *
 * ------ maintenance history ------
 * 04/02/2022 Simon Zhao changed all functions into static methods.
 */

export class ScheduleHelperService {
    static getScheduledAt(scheduler) {
        if (!scheduler) {
            return '';
        }
        const time = ScheduleHelperService.handleTimeFormat(scheduler.time);
        if (scheduler.frequency === 'daily') {
            return time + ' Daily';
        } else if (scheduler.frequency === 'weekly') {
            let weekdaysString = '';
            if (scheduler.on.length <= 8) { // choose one or two weekdays. Monday & Thursday
                weekdaysString = scheduler.on.split(',').map((weekday) => ScheduleHelperService.mapToWeekdayString(weekday)).join(' & ');
            } else { // choose more than two weekdays. Monday , Thursday & SUNDAY
                const beforeLastComma = scheduler.on.substring(0, scheduler.on.lastIndexOf(','));
                const afterLastComma = scheduler.on.substring(scheduler.on.lastIndexOf(',') + 1);
                weekdaysString = beforeLastComma.split(',').map((weekday) => ScheduleHelperService.mapToWeekdayString(weekday)).join(', ') + ' & ' + ScheduleHelperService.mapToWeekdayString(afterLastComma);
            }
            if (scheduler.every === 1) {
                return time + ' every ' + weekdaysString;
            } else {
                return time + ' every ' + scheduler.every + ScheduleHelperService.mapToOrdinalSuffix(scheduler.every) + ' ' + weekdaysString;
            }
        } else if (scheduler.frequency === 'monthly') {
            const occurParts = scheduler.on.split(':');
            const first = occurParts[0]; // day, 1,2,3,4, L
            const second = occurParts[1]; // L, 1,2,3,4,5,6,7
            const monthStr = (scheduler.every < 2 ? 'month' : scheduler.every + ' months');
            if (first === 'day') {
                if (second !== 'L') { // 1- 30
                    return time + ' Day ' + second + ' every ' + monthStr;
                } else {
                    return time + ' Last day every ' + monthStr;
                }
            } else { // 1, 2, 3, 4, L
                return time + ' ' + ScheduleHelperService.mapToOrderString(first) + ' ' + ScheduleHelperService.mapToWeekdayString(second) + ' every ' + monthStr;
            }
        }
    }

    static handleTime24Format(time: string): string {
        let time24 = '';
        const timeParts = time.split(' ')[0].split(':');
        let hours = parseInt(timeParts[0], 0);
        const minutes = parseInt(timeParts[1], 0);

        if (time.indexOf('AM') > 0) {
            if (hours === 12) {
                hours = 0;
            }
        } else if (time.indexOf('PM') > 0) {
            if (hours !== 12) {
                hours = hours + 12;
            }
        }
        time24 = hours + ':' + minutes;
        return time24;
    }

    static handleTimeFormat(time: string): string {
        const timeParts = time.split(':');
        let hours = parseInt(timeParts[0], 0);
        const minutes = parseInt(timeParts[1], 0);
        let suffix = 'AM';
        if (hours === 0) {
            hours = 12;
        } else if (hours === 12) {
            suffix = 'PM';
        } else if (hours > 12) {
            hours = hours - 12;
            suffix = 'PM';
        }
        let minstr = minutes.toString();
        if (minutes < 10) {
            minstr = '0' + minutes;
        }
        return hours + ':' + minstr + ' ' + suffix;
    }

    static is12TimeFormat(time: string): boolean {
        let validation = false;
        const time12AReg = /^0[0-9]:[0-5][0-9]\s[A|P]M$/;
        const time12BReg = /^1[0-2]:[0-5][0-9]\s[A|P]M$/;

        if (time12AReg.test(time) || time12BReg.test(time)) {
            validation = true;
        }
        return validation;
    }

    static is24TimeFormat(time: string): boolean {
        let validation = false;
        const time24Reg = /^[0-2][0-3]:[0-5][0-9]$/;

        if (time24Reg.test(time)) {
            validation = true;
        }
        return validation;
    }

    static mapIndexToWeek(index: number): string {
        let result = '';
        switch (index) {
            case 0:
                result = 'SUN';
                break;
            case 1:
                result = 'MON';
                break;
            case 2:
                result = 'TUE';
                break;
            case 3:
                result = 'WED';
                break;
            case 4:
                result = 'THU';
                break;
            case 5:
                result = 'FRI';
                break;
            case 6:
                result = 'SAT';
                break;
            default:
                break;
        }
        return result;
    }

    static mapToOrderString(order: string): string {
        let result = '';
        switch (order) {
            case '1':
                result = 'First';
                break;
            case '2':
                result = 'Second';
                break;
            case '3':
                result = 'Third';
                break;
            case '4':
                result = 'Fourth';
                break;
            case 'L':
                result = 'Last';
                break;
            default:
                break;
        }
        return result;
    }

    static mapToOrdinalSuffix(number: number): string {
        let result = 'th';
        switch (number) {
            case 1:
                result = 'st';
                break;
            case 2:
                result = 'nd';
                break;
            case 3:
                result = 'rd';
                break;
            default:
                result = 'th';
                break;
        }
        return result;
    }

    static mapToWeekdayString(weekdayNumOrWeekdayShort: string): string {
        let result = '';
        switch (weekdayNumOrWeekdayShort) {
            case '2':
            case 'MON':
                result = 'Monday';
                break;
            case '3':
            case 'TUE':
                result = 'Tuesday';
                break;
            case '4':
            case 'WED':
                result = 'Wednesday';
                break;
            case '5':
            case 'THU':
                result = 'Thursday';
                break;
            case '6':
            case 'FRI':
                result = 'Friday';
                break;
            case '7':
            case 'SAT':
                result = 'Saturday';
                break;
            case '1':
            case 'SUN':
                result = 'Sunday';
                break;
            default:
                break;
        }
        return result;
    }

    static mapWeekdayToIndex(weekday: string): number {
        let result = 0;
        switch (weekday) {
            case 'SUN':
                result = 0;
                break;
            case 'MON':
                result = 1;
                break;
            case 'TUE':
                result = 2;
                break;
            case 'WED':
                result = 3;
                break;
            case 'THU':
                result = 4;
                break;
            case 'FRI':
                result = 5;
                break;
            case 'SAT':
                result = 6;
                break;
            default:
                break;
        }
        return result;
    }
}
