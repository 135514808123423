<kendo-popup [anchor]="contextAnchor" *ngIf="showContextPopUp" class="new-popup">
    <ul *ngIf="!config.stateful" class="popup-content">
        <ng-container *ngFor="let menuItem of config.items">
            <li class="li-item" *ngIf="!menuItem.hidden" [class.disabled]="menuItem.disabled"
                (click)="config.clickOnDiv?emptyClick($event):onItemClick($event, menuItem)">
                <ng-container *ngIf="menuItem.divider">
                    <div class="divider"></div>
                </ng-container>
                <ng-container *ngIf="!menuItem.divider">
                    <div class="line" *ngIf="config.clickOnDiv" (click)="onItemClick($event, menuItem)"
                        (mousedown)="onItemMousedown($event)">
                        <smart-icon *ngIf="config.showItemIcon" [name]="menuItem.icon"
                            [style.color]="menuItem.iconColor">
                        </smart-icon>
                        <span [class.menuItem]="config.showItemIcon">{{menuItem.text}}</span>
                    </div>
                    <div class="line" *ngIf="!config.clickOnDiv">
                        <smart-icon *ngIf="config.showItemIcon" [name]="menuItem.icon"
                            [style.color]="menuItem.iconColor">
                        </smart-icon>
                        <span [class.menuItem]="config.showItemIcon">{{menuItem.text}}</span>
                    </div>
                </ng-container>
            </li>
        </ng-container>
    </ul>
    <ul *ngIf="config.stateful" class="popup-content">
        <ng-container *ngFor="let menuItem of config.items">
            <li class="li-item" *ngIf="!menuItem.hidden" [class.disabled]="menuItem.disabled"
                (click)="config.clickOnDiv?emptyClick($event):config.single?onItemClick($event, menuItem):onPassiveItemClick($event, menuItem)">
                <ng-container *ngIf="menuItem.divider">
                    <div class="divider"></div>
                </ng-container>
                <ng-container *ngIf="!menuItem.divider">
                    <div class="line" *ngIf="config.clickOnDiv"
                        (click)="config.single?onItemClick($event, menuItem):onPassiveItemClick($event, menuItem)"
                        (mousedown)="onItemMousedown($event)">
                        <smart-icon *ngIf="menuItem.checked === true" name="check" class="checkIcon"></smart-icon>
                        <div class="inlineb placeholder" *ngIf="menuItem.checked == null || menuItem.checked === false">
                        </div>
                        <smart-icon *ngIf="menuItem.hideItemSpace?!menuItem.hideItemSpace:config.showItemIcon"
                            [name]="menuItem.icon" class="menuItem" [style.color]="menuItem.iconColor"></smart-icon>
                        <span class="menuItem">{{menuItem.text}}</span>
                    </div>
                    <div class="line" *ngIf="!config.clickOnDiv">
                        <smart-icon *ngIf="menuItem.checked === true" name="check" class="checkIcon"></smart-icon>
                        <div class="inlineb placeholder" *ngIf="menuItem.checked == null || menuItem.checked === false">
                        </div>
                        <smart-icon *ngIf="config.showItemIcon" [name]="menuItem.icon" class="menuItem"
                            [style.color]="menuItem.iconColor"></smart-icon>
                        <span class="menuItem">{{menuItem.text}}</span>
                    </div>
                </ng-container>
            </li>
        </ng-container>
    </ul>
</kendo-popup>