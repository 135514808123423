export class SmartReducerResult {
    public originalReducers: any;
    public smartReducer: any;
    public getSmartSelector: any;
    public getSmartEffectAction: any;
}

export class SmartReducerService {
    public generate(configs: SmartReducerConfig[]): SmartReducerResult {
        const result = new SmartReducerResult();
        result.smartReducer = this._getComplexReducer(configs);
        result.originalReducers = this._getDefaultReducer(configs);
        result.getSmartSelector = (originalReducerParentSelectors: Map<string, any>, smartReducerParentSelector: any) => {
            return this._getSmartSelector(configs, originalReducerParentSelectors, smartReducerParentSelector);
        };
        result.getSmartEffectAction = (originalAction, effectAction) => {
            const action = Object.assign({}, effectAction);
            for (let i = 0; i < configs.length; i++) {
                if (originalAction[configs[i].keyNameInAction] && !action[configs[i].keyNameInAction]) {
                    action[configs[i].keyNameInAction] = originalAction[configs[i].keyNameInAction];
                }
            }
            return action;
        };
        return result;
    }

    private _getComplexReducer(configs: SmartReducerConfig[]) {
        return (state: any = {}, action: any) => {
            for (let i = 0; i < configs.length; i++) {
                const key = action[configs[i].keyNameInAction];
                if (key) {
                    switch (action.type) {
                        case configs[i].addType:
                            state[configs[i].keyAliasMapper(key)] = configs[i].reducer(undefined, action);
                            break;
                        case configs[i].deleteType:
                            delete state[configs[i].keyAliasMapper(key)];
                            break;
                        default:
                            if (typeof state[configs[i].keyAliasMapper(key)] !== 'undefined') {
                                state[configs[i].keyAliasMapper(key)] = configs[i].reducer(state[configs[i].keyAliasMapper(key)], action);
                            }
                    }
                }
            }
            return Object.assign({}, state);
        };
    }

    private _getDefaultReducer(configs: SmartReducerConfig[]) {
        const result = {};
        for (let i = 0; i < configs.length; i++) {
            result[configs[i].reducerName] = (state: null, action: any) => {
                if (!action[configs[i].keyNameInAction]) {
                    return configs[i].reducer(state, action);
                } else {
                    return state;
                }
            };
        }

        return result;
    }

    private _getSmartSelector(configs: SmartReducerConfig[],
        originalReducerParentSelectors: Map<string, any>, smartReducerParentSelector: any) {
        return (key, reducerName) => {
            if (reducerName) {
                const reducer = configs.reduce((acc, cur) => cur.reducerName === reducerName ? cur : acc, null);
                if (reducer) {
                    if (key) {
                        return (state) => smartReducerParentSelector(state)[reducer.keyAliasMapper(key)];
                    } else {
                        return (state) => originalReducerParentSelectors.get(reducerName)(state);
                    }
                } else {
                    throw new Error('Cannot select while reducer name not valid');
                }
            } else {
                throw new Error('Cannot select while reducer name is missing');
            }
        };
    }
}

export class SmartReducerConfig {
    constructor(
        public reducer,
        public reducerName: string, // setup the name this reducer when generated the name in originalReducers
        public addType: string,
        public deleteType: string,
        public keyNameInAction: string, // action[keyName] will be used as index of the state in the map
        public keyAliasMapper = keyname => keyname
    ) {
    }
}

