/**
 * Created by Abner Sui 07/02/2019.
 * Description:
 * print preview
 *
 * ------ maintenance history ------
 */
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ThreadDetailConfig, ThreadDetailMode } from '../../components/thread-detail/thread-detail.view-model';
import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';
import { ActivatedRoute } from '@angular/router';
import { Thread } from '../../tamalelibs/models/thread.model';
import { ThreadService } from '../../tamalelibs/services/thread.service';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Component({
    selector: 'tam-print-preview',
    templateUrl: './print-preview.component.html',
    styleUrls: ['./print-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrintPreviewComponent implements OnInit {

    includeSideNotes: boolean;
    includeAttachments: boolean;
    threadList: Array<Thread> = [];
    detailConfigs: Array<ThreadDetailConfig> = [];
    attachments: Array<Array<DocumentEntry>> = [];
    attachmentsCount = 0;
    isLoading = true;

    private _threadIdList: Array<string> = [];

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _threadService: ThreadService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.isLoading = true;
        const params = this._activatedRoute.snapshot.queryParams;
        this._threadIdList = params['ids'] ? params['ids'].toString().split(';') : [];
        this.includeSideNotes = params['includeSideNotes'].toString() === 'true';
        this.includeAttachments = params['includeAttachments'].toString() === 'true';
        const observableArray: Array<Observable<any>> = [];
        this._threadIdList.forEach(id => {
            observableArray.push(
                this._threadService.getThreadById(id).pipe(
                    catchError(err => {
                        return of(null);
                    }),
                    take(1),
                ),
            );
        });
        forkJoin(observableArray).subscribe(responses => {
            this.attachments = [];
            responses.forEach(res => {
                this.threadList = [];
                if (res) {
                    this.threadList.push(Thread.parse(res));
                } else {
                    this.threadList.push(null);
                }
                this.attachmentsCount = 0;
                this.threadList.forEach(item => {
                    const tempArray: Array<DocumentEntry> = [];
                    Array.from(item.attachments.values()).forEach(attachment => {
                        if (attachment.extension && attachment.extension.toLowerCase() === 'pdf') {
                            tempArray.push(attachment);
                        }
                    });
                    this.attachmentsCount += tempArray.length;
                    this.attachments.push(tempArray);
                });
                this._initThreadDisplayData();
            });
            this._changeDetectorRef.detectChanges();
        });
    }

    trackByFnAttachment(index, item) {
        return item.id;
    }

    trackByFnDetail(index, item) {
        return item.keyInStore;
    }

    private _initThreadDisplayData() {
        this.threadList.forEach((thread, index, arr) => {
            const detail = new ThreadDetailConfig();
            detail.keyInStore = index.toString(); // Useless for logic, used in trackBy to improve performance on Edge
            detail.mode = ThreadDetailMode.PRINT;
            if (!this.includeSideNotes) {
                thread.notes = [thread.parentEntry];
            }
            detail.thread = thread;
            this.detailConfigs.push(detail);
        });
    }

}
