/**
 * Created by Tamale Dev
 *  Description:
 *  Offers the capability of calling up common dialogs, like confirmation dialog, info dialog and etc.
 * ------ maintenance history ------
 * 10/28/2022 Simon Zhao - exposed the view type argument on confirm dialog function.
 */
import { Injectable } from '@angular/core';
import { AlertConfig, AlertTypes, AlertButton, AlertBtnTypes, AlertHeader, AlertHeaderIcon, AlertViewType } from './alert-window.model';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AlertWindowService {

    alert$: Subject<AlertConfig> = new Subject<AlertConfig>();
    result$: Subject<boolean> = new Subject<boolean>();
    close$: Subject<any> = new Subject();
    openForSideSheet$: Subject<any> = new Subject<any>();

    info(title: string, contents: string[], eventKey?: string, eventValue?: any): Subject<boolean> {
        const config: AlertConfig = {
            type: AlertTypes.info,
            title: title,
            contents: contents,
            closeButton: {
                text: 'OK',
                type: AlertBtnTypes.primary,
                visible: true
            },
            eventKey: eventKey,
            eventValue: eventValue,
        };
        this.alert$.next(config);
        return this.result$;
    }

    warn(title: string, contents: string[], confirmButtonText: string = 'Discard', closeButtonText: string = 'Go Back', eventKey?: string, eventValue?: any, confirmButtonType?: string): Subject<boolean> {
        const config: AlertConfig = {
            type: AlertTypes.warn,
            title: title,
            contents: contents,
            closeButton: {
                text: closeButtonText,
                type: AlertBtnTypes.tertiary,
                visible: true
            },
            confirmButton: {
                text: confirmButtonText,
                type: confirmButtonType ? confirmButtonType : AlertBtnTypes.destructive,
                visible: true
            },
            eventKey: eventKey,
            eventValue: eventValue,
        };
        this.alert$.next(config);
        return this.result$;
    }

    error(title: string, contents: string[], eventKey?: string, eventValue?: any): Subject<boolean> {
        const config: AlertConfig = {
            type: AlertTypes.error,
            title: title,
            contents: contents,
            closeButton: {
                text: 'OK',
                type: AlertBtnTypes.primary,
                visible: true
            },
            eventKey: eventKey,
            eventValue: eventValue,
        };
        this.alert$.next(config);
        return this.result$;
    }

    custom(header: AlertHeader | string, contents: string[], closeButton?: AlertButton, confirmButton?: AlertButton, alertWindowWidth?: number, eventKey?: string, eventValue?: any, isResetHorizontalMargin?: boolean): Subject<boolean> {
        const closeVisible = closeButton ? (closeButton.visible === undefined ? true : closeButton.visible) : false;
        const confirmVisible = confirmButton ? (confirmButton.visible === undefined ? true : closeButton.visible) : false;
        let title: string, icon: AlertHeaderIcon;
        if (typeof header === 'string') {
            title = header;
        } else {
            title = header.title || '';
            icon = header.icon;
        }
        const config: AlertConfig = {
            type: AlertTypes.custom,
            title: title,
            icon: icon,
            contents: contents,
            alertWindowWidth: alertWindowWidth,
            closeButton: {
                text: closeVisible && closeButton.text || 'OK',
                type: closeVisible && closeButton.type || AlertBtnTypes.primary,
                visible: closeVisible
            },
            confirmButton: {
                text: confirmVisible && confirmButton.text || 'OK',
                type: confirmVisible && confirmButton.type || AlertBtnTypes.primary,
                visible: confirmVisible
            },
            eventKey: eventKey,
            eventValue: eventValue,
            isResetHorizontalMargin: isResetHorizontalMargin
        };
        this.alert$.next(config);
        return this.result$;
    }

    close() {
        this.close$.next();
    }

    confirm(title: string, contents: string[], confirmButtonText: string = 'Discard', extraButtonText: string = 'Keep', eventKey?: string, eventValue?: any, viewType: AlertViewType = AlertViewType.NORMAL): Subject<boolean> {
        const config: AlertConfig = {
            type: AlertTypes.confirm,
            title: title,
            contents: contents,
            closeButton: {
                text: 'Go Back',
                type: AlertBtnTypes.tertiary,
                visible: true
            },
            confirmButton: {
                text: confirmButtonText,
                type: AlertBtnTypes.destructive,
                visible: true
            },
            extraButton: {
                text: extraButtonText,
                type: AlertBtnTypes.primary,
                visible: true
            },
            eventKey: eventKey,
            eventValue: eventValue,
            viewType: viewType
        };
        this.alert$.next(config);
        return this.result$;
    }
}
