import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { CustomAction } from '../actions/custom.actions';
import { CREATE_ENTITY_SUCCESS, CREATE_ENTITY_FAILURE, EDIT_ENTITY_SUCCESS, EDIT_ENTITY_FAILURE } from '../../tamalelibs/redux/entity.actions';
import { SystemActionTypes } from '../../tamalelibs/redux/actions/system.actions';
import { EmailDigestActionTypes } from '../actions/email-digest.actions';
import { EDIT_CONTACT_SUCCESS, CREATE_CONTACT_SUCCESS, CREATE_CONTACT_FAILURE } from '../../tamalelibs/redux/app.actions';
import { UPDATE_NOTE_SUCCESS, DELETE_SIDENOTE_SUCCESS, DELETE_ATTACHMENT_SUCCESS, DELETE_NOTE_SUCCESS, DELETE_NOTE } from '../../tamalelibs/redux/note.actions';
import { EDIT_AUTO_TAG_SETTINGS_FAIL, EDIT_AUTO_TAG_SETTINGS_SUCCESS, CHANGE_AUTO_TAG_STATUS_SUCCESS, CHANGE_AUTO_TAG_STATUS_FAIL, CREATE_AUTO_TAG_MODEL_SUCCESS } from '../../tamalelibs/redux/auto-tagging.actions';
import { WorkflowTaskActionTypes } from '../../tamalelibs/redux/actions/workflow-task.actions';
import { GET_MAP_TILE_STATUS_FAIL, GET_MAP_TILE_STATUS_SUCCESS } from '../../tamalelibs/redux/map-tile.actions';
import { WorkflowActionTypes } from '../../tamalelibs/redux/actions/workflow.actions';
import { MDLTemplateActionTypes } from '../actions/mdl-template.actions';
import { NotificationActionTypes } from '../actions/notification.actions';
import { RelationshipTypeActionTypes } from '../actions/relationship-type.actions';
import { PortfolioActionTypes } from '../actions/portfolio.action';
import { AdminPageActionTypes } from '../actions/admin-page.actions';
import { ActionHistoryActionTypes } from '../actions/action-history.actions';
import { DELETE_CONTACT_FAIL, DELETE_CONTACT_SUCCESS } from '../../tamalelibs/redux/contact-list.actions';
import { DELETE_ENTITY_FAIL, DELETE_ENTITY_SUCCESS } from '../../tamalelibs/redux/entity-list.actions';

export const lastActionSelector = createSelector(
    (state: AppState) => state.actionHistory,
    (actions: CustomAction[]) => actions[0].type
);
export const lastCustomActionSelector = createSelector(
    (state: AppState) => state.actionHistory,
    (actions: any[]) => actions[0]
);

export function actionHistoryReducer(state: any = [], action: any) {
    const newState: any[] = Object.assign([], state);
    const returnedAction: any = {
        prefix: action.prefix ? action.prefix : '',
        type: action.type
    };

    if (action.prefix) {
        returnedAction.prefix = action.prefix;
    } else if (action.prefixName) {
        returnedAction[action.prefixName] = action[action.prefixName];
    }
    switch (action.type) {
        case ActionHistoryActionTypes.REMOVE_ITEM:
            const actionItems = Object.assign([], state);
            const deletedItem = action.payload.type;
            const newItems = actionItems.filter(item => item.type !== deletedItem);
            return newItems;
        case SystemActionTypes.EDIT_SCREEN:
        case CREATE_ENTITY_SUCCESS:
        case CREATE_ENTITY_FAILURE:
        case EDIT_ENTITY_SUCCESS:
        case EDIT_ENTITY_FAILURE:
        case CREATE_CONTACT_FAILURE:
        case EDIT_ENTITY_FAILURE:
        case EmailDigestActionTypes.SWITCH_ACTIVE_SUCCESS:
        case EmailDigestActionTypes.SWITCH_ACTIVE_FAILED:
        case EmailDigestActionTypes.SWITCH_SUBSCRIPTION_SUCCESS:
        case EmailDigestActionTypes.SWITCH_SUBSCRIPTION_FAILED:
        case NotificationActionTypes.CREATENOTIFICATIONS_SUCCESS:
        case NotificationActionTypes.CREATENOTIFICATIONS_FAILED:
        case NotificationActionTypes.EDITNOTIFICATIONS_SUCCESS:
        case NotificationActionTypes.EDITNOTIFICATIONS_FAILED:
        case EmailDigestActionTypes.SWITCH_SUBSCRIPTION_FAILED:
        case PortfolioActionTypes.CREATEPORTFOLIOS_SUCCESS:
        case PortfolioActionTypes.CREATEPORTFOLIOS_FAILED:
        case PortfolioActionTypes.EDITPORTFOLIOS_SUCCESS:
        case PortfolioActionTypes.EDITPORTFOLIOS_FAILED:
        case EDIT_AUTO_TAG_SETTINGS_FAIL:
        case EDIT_AUTO_TAG_SETTINGS_SUCCESS:
        case CHANGE_AUTO_TAG_STATUS_SUCCESS:
        case CREATE_AUTO_TAG_MODEL_SUCCESS:
        case CHANGE_AUTO_TAG_STATUS_FAIL:
        case GET_MAP_TILE_STATUS_SUCCESS:
        case GET_MAP_TILE_STATUS_FAIL:
        case CREATE_CONTACT_SUCCESS:
        case EDIT_CONTACT_SUCCESS:
        case DELETE_NOTE:
        case DELETE_NOTE_SUCCESS:
        case DELETE_SIDENOTE_SUCCESS:
        case UPDATE_NOTE_SUCCESS:
        case DELETE_ATTACHMENT_SUCCESS:
        case WorkflowTaskActionTypes.EDIT_QUICK_TASK_SUCCESS:
        case WorkflowTaskActionTypes.CHANGE_QUICK_TASK_STATUS_SUCCESS:
        case WorkflowTaskActionTypes.CHANGE_QUICK_TASK_STATUS_FAILED:
        case WorkflowTaskActionTypes.EDIT_QUICK_TASK_REMIND_DATE_SUCCESS:
        case WorkflowTaskActionTypes.DELETE_TASK_SUCCESS:
        case WorkflowActionTypes.PROCESS_INSTANCE_DELETE_SUCCESS:
        case WorkflowActionTypes.PROCESS_INSTANCE_DELETE_FAILED:
        case MDLTemplateActionTypes.CREATE_OR_UPDATE_SUCCESS:
        case MDLTemplateActionTypes.CREATE_OR_UPDATE_FAILED:
        case RelationshipTypeActionTypes.CREATE_VALID_RELATIONSHIP_TYPE_FAILED:
        case RelationshipTypeActionTypes.DELETE_RELATIONSHIP_TYPE_FAILED:
        case RelationshipTypeActionTypes.EDIT_RELATIONSHIP_TYPE_FAILED:
        case AdminPageActionTypes.GET_SHARED_PAGE_SUCCESS:
        case AdminPageActionTypes.GET_SHARED_PAGE_FAILURE:
        case AdminPageActionTypes.CREATE_SHARED_PAGE_SUCCESS:
        case AdminPageActionTypes.CREATE_SHARED_PAGE_FAILURE:
        case AdminPageActionTypes.DELETE_SHARED_PAGE_SUCCESS:
        case AdminPageActionTypes.DELETE_SHARED_PAGE_FAILURE:
        case AdminPageActionTypes.EDIT_SHARED_PAGE_SUCCESS:
        case AdminPageActionTypes.EDIT_SHARED_PAGE_FAILURE:
        case AdminPageActionTypes.GET_GENERAL_PAGE_SUCCESS:
        case AdminPageActionTypes.GET_GENERAL_PAGE_FAILURE:
        case AdminPageActionTypes.CREATE_GENERAL_PAGE_SUCCESS:
        case AdminPageActionTypes.CREATE_GENERAL_PAGE_FAILURE:
        case AdminPageActionTypes.DELETE_GENERAL_PAGE_SUCCESS:
        case AdminPageActionTypes.DELETE_GENERAL_PAGE_FAILURE:
        case AdminPageActionTypes.EDIT_GENERAL_PAGE_SUCCESS:
        case AdminPageActionTypes.EDIT_GENERAL_PAGE_FAILURE:
        case DELETE_CONTACT_SUCCESS:
        case DELETE_CONTACT_FAIL:
        case DELETE_ENTITY_SUCCESS:
        case DELETE_ENTITY_FAIL:
            returnedAction.payload = action.payload;
        // tslint:disable-next-line: no-switch-case-fall-through
        default: {
            newState.unshift(returnedAction);
            if (newState.length > 10) {
                newState.pop();
            }
            return newState;
        }
    }
}
