/**
 * Created by simon zhao on 11/2/2021.
 * Description:
 * TextAreaField Component
 * ------ maintenance history ------
 */

import { Component, Input, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { FieldConfig } from './field.model';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { FieldEvents } from './field.model';
import { FieldActions } from './field.model';

@Component({
    selector: 'tam-text-area-field',
    templateUrl: './text-area-field.component.html',
    styleUrls: ['./field.component.scss']
})
export class TextAreaFieldComponent implements OnInit, AfterViewInit, OnDestroy {
    //#region public properties

    @Input()
    config: FieldConfig;

    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false })
    tooltipDir: TooltipDirective;

    isIPAD = false;
    invalid = false;
    requireInvalid = false;

    //#endregion

    //#region private properties

    /**
    * the subscription array that would be unsubscribed on destroying.
    */
    private _destroySubscriptions: Array<Subscription> = [];

    //#endregion

    constructor(
        private _utils: UtilsService,
        private _deviceService: DeviceDetectorService,
    ) { }



    //#region public methods

    ngOnInit() {
        if (this.config.disabled) {
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this.invalid = this.validate();
        this._destroySubscriptions.push(
            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validate() || this.validateRequire(),
            },
        });
    }

    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validate(): boolean {
        return this.config.field.value && (this.config.minlength || this.config.minlength === 0) && this.config.minlength > this.config.field.value.length;
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && !this.config.field.value) {
            return true;
        } else {
            return false;
        }
    }

    valueChange(event): void {
        this.requireInvalid = false;
        this.invalid = this.validate();
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: this.config.field.value,
            },
        });
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validate() || this.validateRequire(),
            },
        });
    }
    //#endregion

    //#region private methods
    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        }
    }
    //#endregion
}
