export class WebLink {
    id: string;
    title: string;
    url: string;

    static parse(serverResponse): WebLink {
        return {
            id: serverResponse.data.id,
            title: serverResponse.data.title,
            url: serverResponse.data.url
        };
    }
}
