/**
 * Created by Alex on 1/29/2021
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';

@Injectable({
    providedIn: 'root'
})
export class MapTileService {
    tripAutoCompleteActionSubject$: Subject<any> = new Subject();

    constructor(
        private _transportService: TransportService,
    ) { }

    editMapTileStatus(enable: boolean): Observable<any> {
        const url = `${AppConfig.maptileChangeStatusEndPoint}`;
        const formData: FormData = new FormData();
        if (enable !== null) {
            formData.append('enable', String(enable));
        }

        return this._transportService.put(url, formData, {});
    }

    getCoordinatesForContactAndEntity() {
        const url = `${AppConfig.contactCorporateEntityEndpoint}`;
        const formData: FormData = new FormData();
        return this._transportService.patch(url, formData, {});
    }
}

export enum TripAutocompleteActionType {
    LessThanTwoAutocompletes,
    ClearErrorMessage,
    RefreshTripMap,
    ZERORESULTS,
    GETTRIP,
}
