import { AttachmentView } from './../attachment-list/attachment-view.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PopoverConfig } from './popover.model';
import { FileHelperService } from '../../services/file-helper.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { AlertButton, AlertBtnTypes } from '../../widgets/alert-window/alert-window.model';
import { MAX_PREVIEW_FILE_SIZE, PDFPreviewExtension } from '../../../../../tamalelibs/src/app/constants/business.constants';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
    @Input() config: PopoverConfig;
    @Output() delete = new EventEmitter();

    isIPAD = false;

    constructor(private _fileHelperService: FileHelperService,
        private _deviceService: DeviceDetectorService,
        private _alertWindow: AlertWindowService) {
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
    }

    ngOnInit() {

    }

    downloadFile(attach: AttachmentView) {
        if (attach) {
            this._fileHelperService.downloadFile$.next();
            const fileUrl = attach.fileDataLink + attach.id;
            this._fileHelperService.downloadFile(fileUrl);
        }
    }

    getIconName(fileExt: string) {
        let iconName = 'unknown';
        iconName = this._fileHelperService.getIconName(fileExt);
        return iconName;
    }

    onCopyLinkClick(item) {
        this._fileHelperService.copyFileLink(item.id);
    }

    onDeleteClick(item) {
        const cancelBtn: AlertButton = {
            text: 'Cancel',
            type: AlertBtnTypes.tertiary
        };
        const confirmButton: AlertButton = {
            text: 'Delete',
            type: AlertBtnTypes.destructive
        };
        this._alertWindow.openForSideSheet$.next('');
        const subscription = this._alertWindow.
            custom('Delete confirmation required', ['Are you sure you want to delete this attachment?'], cancelBtn, confirmButton)
            .subscribe((result: boolean) => {
                if (result) {
                    this.delete.emit(item);
                }
                subscription.unsubscribe();
            });
    }

    onDownloadItem(item) {
        this._fileHelperService.downloadFile$.next();
        const attachInfo = item;
        const fileUrl = attachInfo.fileDataLink + attachInfo.id;
        this._fileHelperService.downloadFile(fileUrl);
    }

    onFileAction(attach: AttachmentView) {
        if (attach) {
            if (attach.extension && PDFPreviewExtension.includes(attach.extension.toLowerCase())) {
                const fileId = attach.id;
                const fileLink = attach.fileDataLink;
                const fileExtension = attach.extension;
                const fileName = attach.fileName;
                const fileSize = attach.fileSize;
                if (fileSize > MAX_PREVIEW_FILE_SIZE) {
                    this._unablePreviewAlert(attach);
                } else {
                    this._fileHelperService.previewFile(fileId, fileLink, fileExtension, fileName);
                }
            } else {
                this.downloadFile(attach);
            }
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    private _unablePreviewAlert(attach: AttachmentView) {
        const downloadBtn: AlertButton = {
            text: 'Download',
            type: AlertBtnTypes.primary
        };
        const cancelBtn: AlertButton = {
            text: 'OK',
            type: AlertBtnTypes.tertiary
        };
        const subscription = this._alertWindow.custom('Warning', ['You can\'t preview this file because the size exceeds limitation(15M).'], cancelBtn, downloadBtn)
            .subscribe((result: boolean) => {
                if (result) {
                    this.downloadFile(attach);
                }
                subscription.unsubscribe();
            });
    }
}
