/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { AppState } from '../redux';
import { SystemUser } from '../tamalelibs/models/user.model';

export const STORE_KEY_USER = 'user';

@Injectable()
export class StoreQuerierService {

    constructor(
        private _store: Store<AppState>,
    ) { }

    getCurrentUser(): SystemUser {
        let result: SystemUser;
        this._store.pipe(
            select(STORE_KEY_USER),
            take(1)
        ).subscribe(data => result = data);
        return result;
    }

    /**
     * synchronous fetch data from Ngrx Store
     * @param selector path or mapFn
     */
    queryBySelector(selector) {
        if (!selector) {
            return null;
        }
        let result;
        this._store.pipe(
            select(selector),
            take(1)
        ).subscribe(data => result = data);
        return result;
    }
}
