/**
 * Created by Ella Ma on 10/16/2020. custom date column filter
 * ------ maintenance history ------
 * Update by Daniel on 07/20/2021. convert the component to widget
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'tam-custom-grid-checkbox',
    templateUrl: './custom-grid-checkbox.component.html',
    styleUrls: ['./custom-grid-column-filter.component.scss']
})

export class TamCustomGridCheckboxComponent {

    @Input() checked: String;
    // tslint:disable-next-line: no-output-on-prefix
    @Output() onChecked: EventEmitter<any> = new EventEmitter();

    constructor() { }

    onSelected(value) {
        this.onChecked.emit(value);
    }
}
