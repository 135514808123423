/**
 * Created by Abner Sui on 05/22/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { combineReducers, createSelector, Action } from '@ngrx/store';

import { DataList } from '../../tamalelibs/models/data-list.model';
import { FileViewListActionTypes, FileViewActionTypes, FileViewActions, FileViewListActions } from '../actions/file-view.actions';
import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';
import { Thread } from '../../tamalelibs/models/thread.model';

export function fileViewListItemsReducer(state: DataList<DocumentEntry> = new DataList<DocumentEntry>(), action: FileViewListActions) {
    switch (action.type) {
        case FileViewListActionTypes.RELOAD_SUCCESS:
            return action.payload.fileList;
        case FileViewListActionTypes.LOAD_MORE_SUCCESS: {
            // below logic is used to prevent duplicated data in the list
            // the basic logic is load previous page with the next page and doing data comparaion
            if (action.payload.pageIndex === 2) {
                return action.payload.files;
            } else {
                const files = Object.assign(new DataList<DocumentEntry>(), state);
                files.splice((action.payload.pageIndex - 2) * action.payload.pageSize, action.payload.pageSize); // remove the previous page

                // generate set from existing liste
                const fileMap = new Map();
                files.forEach((item, index) => {
                    fileMap.set(item.id, index);
                });

                action.payload.files.forEach((item: DocumentEntry) => {
                    if (fileMap.has(item.id)) {
                        return;
                    }
                    files.push(item);
                });

                files.totalCount = action.payload.files.totalCount;
                files.hasNext = action.payload.files.hasNext;

                return files;
            }
        }
        default:
            return state;
    }
}

export function fileViewListErrorReducer(state: any, action: FileViewListActions) {
    switch (action.type) {
        case FileViewListActionTypes.RELOAD_FAILED:
        case FileViewListActionTypes.LOAD_MORE_FAILED:
            return action.payload;
        default:
            return state;
    }
}

export function fileViewListSelectedIndexReducer(state = null, action: FileViewListActions) {
    switch (action.type) {
        case FileViewListActionTypes.RELOAD_SUCCESS:
            if (action.payload && action.payload.fileList && action.payload.fileList.length > 0) {
                return {
                    index: 0,
                    id: action.payload.fileList[0].id,
                    threadId: action.payload.fileList[0].threadId,
                };
            } else {
                return null;
            }
        case FileViewListActionTypes.SET_SELECTED_INDEX:
            return action.payload;
        default:
            return state;
    }
}

export function fileViewListMatchedAttachmentsReducer(state: Set<string> = new Set(), action: FileViewListActions) {
    switch (action.type) {
        case FileViewListActionTypes.RELOAD_SUCCESS:
            return action.payload.matchedAttachmentSet;
        case FileViewListActionTypes.LOAD_MORE_SUCCESS:
            const newState = Object.assign(new Set(), state);
            action.payload.matchedAttachmentSet.forEach(id => {
                newState.add(id);
            });
            return newState;
        default:
            return state;
    }
}

export function fileViewListCurrentPageIndexReducer(state = 0, action: FileViewListActions) {
    switch (action.type) {
        case FileViewListActionTypes.RELOAD:
        case FileViewListActionTypes.LOAD_MORE:
            return action.payload.pageIndex;
        default:
            return state;
    }
}

export function fileViewThreadReducer(state: Thread, action: FileViewListActions) {
    switch (action.type) {
        case FileViewListActionTypes.GET_THREAD_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

const fileViewListReducer = combineReducers({
    currentPageIndex: fileViewListCurrentPageIndexReducer,
    items: fileViewListItemsReducer,
    matchedAttachmentSet: fileViewListMatchedAttachmentsReducer,
    selectedIndex: fileViewListSelectedIndexReducer,
    error: fileViewListErrorReducer,
    thread: fileViewThreadReducer,
});

export function getFileViewListReducer(state, action) {
    return fileViewListReducer(state, action);
}

export function queryInfoReducer(state: any = {}, action: FileViewActions) {
    switch (action.type) {
        case FileViewActionTypes.SET_QUERY_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function keywordReducer(state: string = '', action: FileViewActions) {
    switch (action.type) {
        case FileViewActionTypes.SET_KEY_WORD:
            return action.payload;
        default:
            return state;
    }
}

export function sortInfoReducer(state: any = { sortBy: 'lastediteddate', sortOrder: 'desc' }, action: FileViewActions) {
    switch (action.type) {
        case FileViewActionTypes.SET_SORT_INFO:
            return action.payload;
        default:
            return state;
    }
}

export function aggridColumnStatusReducer(state: any = {}, action: FileViewActions) {
    switch (action.type) {
        case FileViewActionTypes.SET_AGGRID_COLUMN_STATUS:
            return action.payload;
        default:
            return state;
    }
}

export function actionMessageReducer(state: string = '', action: Action) {
    switch (action.type) {
        case FileViewListActionTypes.RELOAD:
        case FileViewListActionTypes.RELOAD_SUCCESS:
        case FileViewListActionTypes.LOAD_MORE:
        case FileViewListActionTypes.LOAD_MORE_SUCCESS:
        case FileViewListActionTypes.SET_SELECTED_INDEX:
        case FileViewListActionTypes.DELETE_FILE:
        case FileViewListActionTypes.DELETE_FILE_SUCESS:
        case FileViewListActionTypes.DELETE_FILE_FAILED:
            return action.type;
        default:
            return state;
    }
}

const fileViewReducer = combineReducers({
    actionMessage: actionMessageReducer,
    keyword: keywordReducer,
    queryInfo: queryInfoReducer,
    sortInfo: sortInfoReducer,
    fileViewList: getFileViewListReducer,
    aggridColumnStatus: aggridColumnStatusReducer,
});

export function getFileViewRedcuer(state, action) {
    return fileViewReducer(state, action);
}

export const getFileViewState = (state: any = {}) => state;
export const getFileViewListState = createSelector(getFileViewState, (state: any) => state.fileViewList || {});
export const getFileViewListItemsState = createSelector(getFileViewListState, (state: any) => state.items);
export const getFileViewListCurrentPageIndexState = createSelector(getFileViewListState, (state: any) => state.currentPageIndex);
export const getFileViewListMatchedAttachmentState = createSelector(getFileViewListState, (state: any) => state.matchedAttachmentSet);
export const getFileViewListSelectedIndex = createSelector(getFileViewListState, (state: any) => state.selectedIndex);
export const getActiveFileViewThreadState = createSelector(getFileViewListState, (state: any) => state.thread);
export const getQueryInfoState = createSelector(getFileViewState, (state: any) => state.queryInfo);
export const getKeywordState = createSelector(getFileViewState, (state: any) => state.keyword);
export const getSortInfoState = createSelector(getFileViewState, (state: any) => state.sortInfo);
export const getActionMessageState = createSelector(getFileViewState, (state: any) => state.actionMessage);
export const getFocusState = createSelector(getFileViewState, (state: any) => Object.assign({}, state.focus));
export const getAGGridColumnState = createSelector(getFileViewState, (state: any) => state.aggridColumnStatus);

