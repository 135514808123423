<div class="container tam-form-field" [ngClass]="{'has-error':requireInvalid}">
    <div class="tam-form-label label-area">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name body-3 text-ellipsis"></field-label>
    </div>
    <kendo-multiselect #entityDropDown [data]="suggestionItems" [filterable]="true" class="tam-textbox field-control"
        (filterChange)="handleFilter($event)" textField="shortName" valueField="id" (open)="onOpen($event)"
        [(ngModel)]="value" (valueChange)="onValueChange($event)" [disabled]="!config.editable||config.disabled"
        [autoClose]="false" [ngClass]="{'field-disabled':config.disabled||!config.editable}">
        <ng-template kendoMultiSelectItemTemplate let-dataItem>
            <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
        </ng-template>
        <ng-template kendoMultiSelectFooterTemplate let-dataItem *ngIf="searchValue">
            <div *ngIf="isShowCreateContact" (click)="createContact()" class="dropdown-fly-item">
                <span class="fly-entity-tag"> {{searchValue}} (New contact)</span>
            </div>
            <div *ngIf="isShowCreateEntity" (click)="createEntity()" class="dropdown-fly-item">
                <span class="fly-entity-tag">{{searchValue}} (New entity)</span>
            </div>
        </ng-template>
    </kendo-multiselect>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
</div>