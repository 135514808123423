/**
 * Created by Simon Zhao on 7/20/21.
 * Description:
 * responsible for bridging between the dialog invoker and the dialog component.
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MdlDialogOpenOptions } from './mdl-dialog.model';

@Injectable()
export class MdlDialogService {

    dialogClose$: Subject<any> = new Subject<any>();
    dialogOpen$: Subject<MdlDialogOpenOptions> = new Subject<MdlDialogOpenOptions>();

}
