/**
 * Created by Simon Zhao on 7/20/2021.
 * Description:
 * responsible for interacting MDL Template data with server.
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfig } from '../models/app-config.model';
import { IFieldMDLProvider, IMDLTemplate } from '../models/template-mdl.model';
import { TransportService } from './transport.service';

@Injectable({
    providedIn: 'root'
})
export class MdlTemplateService {

    constructor(
        private _transportService: TransportService
    ) { }

    /*
      fetch existing MDL templates.
     */
    getMDLTemplates(): Observable<Map<string, IMDLTemplate>> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.mdlTemplateEndPoint;
        const params = {
        };

        const options = {
            headers: headers,
            params: params
        };

        return this._transportService.get(url, options).pipe(map(r => this._fromTemplatesResponse(r)));
    }

    saveOrUpdateMDLTemplate(typeId: string, template: IMDLTemplate): Observable<any> {
        const url = AppConfig.mdlTemplateEndPoint + typeId;
        const payload = new FormData();
        payload.append('mdltemplate', this._toTemplateString(template));
        const options = {
        };
        return this._transportService.post(url, payload, options);
    }

    private _fromTemplatesResponse(res: any): Map<string, IMDLTemplate> {
        const templatesMap = new Map<string, IMDLTemplate>();
        const toTemplateFunc = (templateObj: any): IMDLTemplate => {
            const singleTemplateMap = new Map<string, IFieldMDLProvider>();
            Object.keys(templateObj).forEach(
                ctrlIdOrName => {
                    if (ctrlIdOrName !== 'name') {// skip the name attribute and all other attributes are control ids.
                        singleTemplateMap.set(ctrlIdOrName, templateObj[ctrlIdOrName]);
                    }
                }
            );

            return { mdlTemplate: singleTemplateMap };
        };

        Object.keys(res).forEach(
            typeId => templatesMap.set(typeId, toTemplateFunc(res[typeId]))
        );

        return templatesMap;
    }

    private _toTemplateString(template: IMDLTemplate): string {
        const templateObj = {};
        template.mdlTemplate.forEach((value, key) => {
            templateObj[key] = value;
        });

        return JSON.stringify(templateObj);
    }

}
