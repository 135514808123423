
/**
 * Created by Ella Ma on 3/26/2020.
 * Description:
 *
 */

import { Injectable, OnDestroy } from '@angular/core';
import { QueryService } from '../../services/query.service';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { TamDashboardTileData } from './tam-dashboard-column-chart-tile/tam-dashboard-column-chart-tile.config';
import { groupBy } from '@progress/kendo-data-query';
import { DashboardService } from '../../pages/dashboard/dashboard.service';
import { TileModel } from '../../tamalelibs/models/tile.model';
import { ThreadDetailConfig, ThreadDetailMode } from '../thread-detail/thread-detail.view-model';
import { Thread } from '../../tamalelibs/models/thread.model';
import { NoteTileSettingsService } from '../dashboard/tile-setting/tile-chart-setting/note-tile-chart-setting/note-tile-chart-setting.service';
import { NoteEntry } from '../../tamalelibs/models/note-entry.model';
import { defaultSubmitter, defaultLongName, defaultShortName, defaultSource, defaultEntryClass } from '../../tamalelibs/models/tile-column.model';
import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';
import { EntryType } from '../../tamalelibs/models/entry-type.model';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';
import { PROPERTY_FIELD_MAP } from '../dashboard/tile-setting/tile-chart-setting/note-tile-chart-setting/note-tile-chart-setting.model';
import { QueryNoteMapperService } from '../../services/query-note-mapper.service';
import { Observable, of } from 'rxjs';
import { Base64Service } from '../../tamalelibs/services/base64.service';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { ToastService } from '../../widgets/toast/toast.service';
import { NotificationStyles } from '../../widgets/notification/notification.model';
import { MapsAPILoader } from '@agm/core';
import { NearbyData } from './tam-dashboard-map-tile/tam-dashboard-map-tile.config';
import { AddressDetail, WebAddress } from '../../tamalelibs/models/job-function';


@Injectable({
    providedIn: 'root'
})

export class DashboardTileService implements OnDestroy {
    private geocoder: any; // for map tile

    constructor(
        private _queryService: QueryService,
        private _dashboardService: DashboardService,
        private _mapLoader: MapsAPILoader,
        private _toast: ToastService,
    ) {
    }

    ngOnDestroy() {
    }

    pieChartLoadData(tileConfig, focusedEntityIds, useConfig?) {
        return this._queryService.executeQueryForPieChart(tileConfig, focusedEntityIds, useConfig)
            .pipe(
                map((columns: any[]) => {
                    const categoryAxis = tileConfig.visualization.settings.categoryAxis;
                    const categoryColumn = tileConfig.columns.filter(item => item.guid === categoryAxis);
                    const isEntityPrivacyColumn = categoryColumn && categoryColumn[0] && this._dashboardService.isEntityPrivacyColumn(categoryColumn[0]);
                    if (tileConfig.visualization.settings.enableBlankCategory) {
                        return columns.filter(column => column.category).map(item => {
                            const category = isEntityPrivacyColumn ? this._dashboardService.cellRenderer(categoryColumn[0], { value: item.category }) : item.category;
                            return new TamDashboardTileData(
                                category,
                                item.value,
                                item.valueText);
                        });
                    }
                    return columns.map(item => {
                        const category = isEntityPrivacyColumn ? this._dashboardService.cellRenderer(categoryColumn[0], { value: item.category }) : item.category;
                        return new TamDashboardTileData(
                            category || '(Blank)',
                            item.value,
                            item.valueText);
                    }

                    );
                }),
                take(1),
                catchError((err) => {
                    this._errorHandler(err, tileConfig.tileName);
                    return of(null);
                })
            );
    }

    columnChartLoadData(tileConfig, focusedEntityIds, useConfig?) {
        return this._queryService.executeQueryForColumnChart(tileConfig, focusedEntityIds, useConfig)
            .pipe(
                map((columns: any[]) => {
                    const categoryAxis = tileConfig.visualization.settings.categoryAxis;
                    const categoryColumn = tileConfig.columns.filter(item => item.guid === categoryAxis);
                    const isEntityPrivacyColumn = categoryColumn && categoryColumn[0] && this._dashboardService.isEntityPrivacyColumn(categoryColumn[0]);
                    if (tileConfig.visualization.settings.enableBlankCategory) {
                        return columns.filter(column => column.category).map(item => {
                            const category = isEntityPrivacyColumn ? this._dashboardService.cellRenderer(categoryColumn[0], { value: item.category }) : item.category;
                            return new TamDashboardTileData(
                                category,
                                item.value,
                                item.valueText);
                        });
                    }
                    return columns.map(item => {
                        const category = isEntityPrivacyColumn ? this._dashboardService.cellRenderer(categoryColumn[0], { value: item.category }) : item.category;
                        return new TamDashboardTileData(
                            category || '(Blank)',
                            item.value,
                            item.valueText);
                    });
                }),
                map((data) => {
                    const result = this._queryService.sortChartDataByXAxisIfNoValueSort(data, tileConfig);
                    return result;
                }),
                take(1),
                catchError((err) => {
                    this._errorHandler(err, tileConfig.tileName);
                    return of(null);
                })
            );
    }

    getAddress(webAddressItem): string {
        let webAddress = '';

        if (webAddressItem['street2']) {
            webAddress += webAddressItem['street2'] + ' ';
        }
        if (webAddressItem['street']) {
            webAddress += webAddressItem['street'] + ' ';
        }
        if (webAddressItem['city']) {
            webAddress += webAddressItem['city'] + ' ';
        }
        if (webAddressItem['state']) {
            webAddress += webAddressItem['state'] + ' ';
        }
        if (webAddressItem['country']) {
            webAddress += webAddressItem['country'] + ' ';
        }
        if (webAddressItem['zip']) {
            webAddress += webAddressItem['zip'] + ' ';
        }
        webAddress = webAddress.trim();

        return webAddress;
    }

    /*
     * get latitude or longitude via web address and coordinate type
     *
     */
    getCoordinateByAddress(webAddressItem, coordinateType): Object {
        let coordinate = 0;
        let coordinate_work = 0;
        let coordinate_home = 0;
        let coordinate_other = 0;
        let coordinate_type = '';
        let coordinate_otehr_type = '';
        if (webAddressItem) {
            if (Array.isArray(webAddressItem) && webAddressItem.length > 0) {
                webAddressItem.forEach(element => {
                    if (element['name'] === 'Work') {
                        coordinate_work = element[coordinateType];
                        coordinate_type = 'Work';
                    } else if (element['name'] === 'Home') {
                        coordinate_home = element[coordinateType];
                        coordinate_type = 'Home';
                    } else {
                        coordinate_other = element[coordinateType];
                        coordinate_otehr_type = element['name'];
                    }
                });
                if (coordinate_work) {
                    coordinate = coordinate_work;
                    coordinate_type = 'Work';
                } else if (coordinate_home) {
                    coordinate = coordinate_home;
                    coordinate_type = 'Home';
                } else {
                    coordinate = coordinate_other;
                    coordinate_type = coordinate_otehr_type;
                }
            } else {
                coordinate = webAddressItem[coordinateType];
                coordinate_type = webAddressItem['name'];
            }
        }

        return [coordinate, coordinate_type];
    }

    /**
     * Simplify the way you get addresses
     * parse web address and get one address
     * if work valid return .else use home or other address
     * // TODO talk with Pan ,it will enhance TAM-34334 (Enhance Map Tile config)
     * @memberof DashboardTileService
     */
    getMatchAddressByWebAddress(webAddressItem: Array<AddressDetail>) {
        let address: AddressDetail;
        if (webAddressItem) {
            if (Array.isArray(webAddressItem) && webAddressItem.length > 0) {
                for (const _address of webAddressItem) {
                    if (_address.latitude && _address.longitude) {
                        address = _address;
                        // add fullAddress for set contact detail
                        address['fullAddress'] = this.getAddress(_address);
                        break;
                    }
                }
                return address;
            }
        }
    }

    getNoLatLngAddresByWebAddress(webAddressItem: Array<AddressDetail>) {
        let address: AddressDetail;
        if (webAddressItem) {
            if (Array.isArray(webAddressItem) && webAddressItem.length > 0) {
                for (const _address of webAddressItem) {
                    if (!_address.latitude && _address.longitude) {
                        address = _address;
                        break;
                    }
                }
                return address;
            }
        }
    }

    /*
     * get home address
     */
    getHomeAddress(webAddressItem): Object {
        let webAddress_home = '';
        let isCallGoogle = false;
        if (webAddressItem) {
            if (Array.isArray(webAddressItem) && webAddressItem.length > 0) {
                webAddressItem.forEach(element => {
                    if (element['name'] === 'Home') {
                        webAddress_home = this.getAddress(element);
                        isCallGoogle = element.isCallGoogle;
                    }
                });

            } else {
                // The current server response data should not have a String webAddress,maybe this code not used.
                webAddress_home = this.getAddress(webAddressItem);
                isCallGoogle = webAddressItem.isCallGoogle;
            }
        }

        return { webAddress_home: webAddress_home, isCallGoogle: isCallGoogle };
    }

    /*
     * get work address
     */
    getWorkAddress(webAddressItem): Object {
        let webAddress_work = '';
        let isCallGoogle = false;
        if (webAddressItem) {
            if (Array.isArray(webAddressItem) && webAddressItem.length > 0) {
                webAddressItem.forEach(element => {
                    if (element['name'] === 'Work') {
                        webAddress_work = this.getAddress(element);
                        isCallGoogle = element.isCallGoogle;
                    }
                });
            } else {
                // The current server response data should not have a String webAddress,maybe this code not used.
                webAddress_work = this.getAddress(webAddressItem);
                isCallGoogle = webAddressItem.isCallGoogle;
            }
        }

        return { webAddress_work: webAddress_work, isCallGoogle: isCallGoogle };
    }

    /*
     * get other type address
     */
    getOtherAddress(webAddressItem): object {
        let name = '';
        let webAddress_other = '';
        let isCallGoogle = false;
        if (webAddressItem) {
            if (Array.isArray(webAddressItem) && webAddressItem.length > 0) {
                for (const item of webAddressItem) {
                    if (item['name'] !== 'Work' && item['name'] !== 'Home') {
                        webAddress_other = this.getAddress(item);
                        name = item['name'];
                        isCallGoogle = item.isCallGoogle;
                        if (webAddress_other.length > 0) {
                            break;
                        }
                    }
                }
            } else {
                // The current server response data should not have a String webAddress,maybe this code not used.
                webAddress_other = this.getAddress(webAddressItem);
                name = webAddressItem.name;
                isCallGoogle = webAddressItem.isCallGoogle;
            }
        }

        return { name: name, webAddress_other: webAddress_other, isCallGoogle: isCallGoogle };
    }


    /*
     * get predicitons via google api
     */
    getMapSearchPredictions(address: string, caller: any, callback: Function) {
        const displaySuggestions = function (
            predictions: google.maps.places.QueryAutocompletePrediction[],
            status: google.maps.places.PlacesServiceStatus
        ) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                callback.call(caller, predictions);
            } else {
                callback.call(caller, null);
            }
        };
        // added mapLoader.load to fix the issue that google is not defined when running on contact on the fly in office addin mode.
        this._mapLoader.load().then(() => {
            const service = new google.maps.places.AutocompleteService();
            service.getQueryPredictions({ input: address }, displaySuggestions);
        });
    }

    /*
    * get nearby via google api
    */
    getMapSearchNearby(nearbyItem: NearbyData, caller: any, callback: Function) {
        const displaySuggestions = function (
            predictions: google.maps.places.PlaceResult[],
            status: google.maps.places.PlacesServiceStatus,
        ) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                callback.call(caller, predictions);
            } else {
                callback.call(caller, null);
            }
        };
        const service = new google.maps.places.PlacesService(document.createElement('div'));
        const content = {
            location: null,
            types: nearbyItem.type,
            radius: nearbyItem.radius * 1000,
        };
        if (!nearbyItem.longitude && !nearbyItem.latitude) {
            this.getMapSearchGeocodeAddress(nearbyItem.searchText).subscribe(_location => {
                content.location = _location;
                service.nearbySearch(content, displaySuggestions);
            });
        } else {
            // const googleLocation = new google.maps.LatLng(39.926002, 116.45364);
            const googleLocation = new google.maps.LatLng(nearbyItem.latitude, nearbyItem.longitude);
            content.location = googleLocation;

        }
        if (content.location) {
            service.nearbySearch(content, displaySuggestions);
        }
    }

    /*
     * get lat and lng via google api
     */
    getMapSearchGeocodeAddress(location: string): Observable<any> {
        this._initGeocoder();
        return new Observable(observer => {
            this.geocoder.geocode({ 'address': location }, (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    observer.next({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng()
                    });
                } else {
                    console.log('Error - ', results, ' & Status - ', status);
                    observer.next({ lat: 0, lng: 0 });
                }
                observer.complete();
            });
        });
    }

    lineChartLoadData(tileConfig, focusedEntityIds, useConfig?) {
        return this._queryService.executeQueryForLineChart(tileConfig, focusedEntityIds, useConfig)
            .pipe(
                map((result: any) => {
                    const groupId = tileConfig.visualization.settings.groupBy;
                    const groupByColumn = tileConfig.columns.filter(item => item.guid === groupId);
                    const isEntityPrivacyColumn = groupByColumn && groupByColumn[0] && this._dashboardService.isEntityPrivacyColumn(groupByColumn[0]);
                    if (result.data) {
                        result.data = result.data.map(item => {
                            const groupName = isEntityPrivacyColumn ? this._dashboardService.cellRenderer(groupByColumn[0], { value: item.groupName }) : item.groupName;
                            return new TamDashboardTileData(
                                item.category || '(Blank)',
                                item.value,
                                item.valueText,
                                groupName,
                                item.category_value);
                        });
                    } else {
                        result.data = [];
                    }
                    if (!result.misc) {
                        result.misc = {
                            minDate: new Date(),
                            maxDate: new Date()
                        };
                    }
                    return result;
                }),
                map((result) => {
                    let payload: any = { data: result.data };
                    payload = this._extendPayloadByDateType(payload, result.dateType, result.misc);
                    payload.series = groupBy(result.data, [{ field: 'groupName', dir: 'asc' }]);
                    payload.series.forEach(item => {
                        item.value = item.value && item.value.toString() || '(Blank)';
                    });
                    payload.series.sort((a, b) => a.value.localeCompare(b.value));
                    payload.widthCalculator = this._getChartWidthCalculator(result.misc.minDate, result.misc.maxDate, result.dateType);
                    return payload;
                }),
                take(1),
                catchError((err) => {
                    this._errorHandler(err, tileConfig.tileName);
                    return of(null);
                })
            );
    }

    valueTileLoadData(tileConfig, focusedEntityIds, useConfig?) {
        return this._queryService.executeQueryForValueTile(tileConfig, focusedEntityIds, useConfig)
            .pipe(
                map((data) => {
                    const columnId = tileConfig.visualization.settings.column;
                    const column = tileConfig.columns.filter(item => item.guid === columnId);
                    const isEntityPrivacyColumn = column && column[0] && this._dashboardService.isEntityPrivacyColumn(column[0]);
                    if (isEntityPrivacyColumn && data && data.valueText) {
                        data.valueText = this._dashboardService.cellRenderer(column[0], { value: data.valueText });
                        data.value = this._dashboardService.cellRenderer(column[0], { value: data.value });
                    }
                    const fontSizeCalculator = this.getFontSizeCalculator(data && data.valueText || null);
                    return {
                        data,
                        fontSizeCalculator
                    };
                }),
                take(1),
                catchError((err) => {
                    this._errorHandler(err, tileConfig.tileName);
                    return of(null);
                })
            );
    }

    getFontSizeCalculator(text) {
        return (parentWidth) => {
            // ignore "," when counting length as it is too narrow
            if (!text) {
                return;
            }
            const tmpStr = text.toString().split(',').join('');
            const length = tmpStr.length;
            let fontSize = Math.floor(parentWidth / length * 5);
            fontSize = fontSize > 52 ? 52 : fontSize;
            fontSize = fontSize < 14 ? 14 : fontSize;
            return fontSize;
        };
    }

    /**
     * calculator font size by the width and length for metadata tile
     * @returns
     */
    getFontSizeCalculatorForMetadataTile() {
        return (parentWidth, text) => {
            // ignore "," when counting length as it is too narrow
            if (!text) {
                return;
            }
            const tmpStr = text.toString().split(',').join('');
            const length = tmpStr.length;
            let fontSize = Math.floor(parentWidth / length * 5);
            fontSize = fontSize > 16 ? 16 : fontSize;
            fontSize = fontSize < 12 ? 12 : fontSize;
            return fontSize;
        };
    }

    metadataLoadData(tileConfig, focusedEntityIds, useConfig?) {
        return this._queryService.executeQueryForMetadataTile(tileConfig, focusedEntityIds, useConfig)
            .pipe(
                map((result) => {
                    return this._metadataProcessData(result, tileConfig);
                }),
                take(1),
                catchError((err) => {
                    this._errorHandler(err, tileConfig.tileName);
                    return of([]);
                })
            );
    }

    gridLoadData(tileConfig, focusedEntityIds, useConfig = false) {
        return this._queryService.executeQueryForGridTile(tileConfig, focusedEntityIds, useConfig)
            .pipe(
                take(1),
                catchError((err) => {
                    this._errorHandler(err, tileConfig.tileName);
                    return of(null);
                }));
    }

    noteTileLoadData(config: any, widget, hardRefresh = false) {
        const tileConfig = (config.tileConfig as TileModel);
        const threadDetailConfigs = new Map<string, ThreadDetailConfig>();
        return this._queryService.executeQueryForNoteTile(tileConfig, config.focusedEntityIds, config.useConfig).pipe(
            map((data) => {
                if (data && data['Items'] && data['Items'].length > 0) {
                    const recordPerPage = tileConfig && tileConfig.pageDesc ? tileConfig.pageDesc.rpp : 1;
                    let existingThreads: Thread[] = [];
                    for (let i = 0; i < data['Items'].length; i++) {
                        existingThreads = this._columns2thread(data['Items'][i], widget, existingThreads);
                    }
                    // if (existingThreads.length > recordPerPage) {
                    //     console.log('Query service returned more threads than expected: ' + existingThreads.length + ' VS ' + recordPerPage);
                    // }
                    for (let i = 0; i < recordPerPage && i < existingThreads.length; i++) {
                        const thread = existingThreads[i];
                        // set config
                        const threadDetailConfig = new ThreadDetailConfig();
                        threadDetailConfig.mode = ThreadDetailMode.EMBED;
                        threadDetailConfig.thread = thread;
                        threadDetailConfigs.set(thread.id, threadDetailConfig);
                    }
                }
                const displaySettings = NoteTileSettingsService.threadTileConfig2DisplaySettings(tileConfig);
                return {
                    displaySettings: displaySettings,
                    threadConfigs: threadDetailConfigs,
                    hardRefresh: hardRefresh
                };
            }),
            take(1),
            catchError((err) => {
                this._errorHandler(err, tileConfig.tileName);
                return of(null);
            })
        );
    }

    richTextLoadData(widget) {
        const observable = new Observable(subscriber => {
            subscriber.next();
            subscriber.complete();
        });
        return observable.pipe(
            map(() => {
                if (!widget || !widget.tile) {
                    return null;
                }
                const data = Object.assign({}, widget.tile.visualization.settings);
                return data;
            })
        );
    }

    updateGridRowItems(gridRowNodes, data) {
        const observable = new Observable(subscriber => {
            subscriber.next();
            subscriber.complete();
        });
        return observable.pipe(
            map(() => {
                const removeRows = [];
                gridRowNodes.forEach((rowNode) => {
                    const rowData = rowNode.data;
                    const rowEntityIds = rowData[`${defaultShortName.guid}_attribute`].entityId;
                    data.removeEntityIds.forEach((ids) => {
                        if (rowEntityIds.indexOf(ids) !== -1) {
                            removeRows.push(rowData);
                        }
                    });
                });
                return {
                    remove: removeRows,
                    add: data.addRows
                };
            }),
            take(1)
        );
    }

    setGridTotalCount(model) {
        let count = 0;
        if (model.rowsToDisplay) {
            model.rowsToDisplay.forEach((item) => {
                if (item.group) {
                    if (item.level === 0) {
                        count += item.allChildrenCount;
                    }
                } else {
                    if (item.level === 0 && !item.group) {
                        count += 1;
                    }
                }
            });
        }
        return count;
    }

    formatSeriesClickCategoryValue(allColumns, columnId, value) {
        if (value === '(Blank)') {
            return '';
        }
        const column = allColumns.filter(item => item.guid === columnId);
        if (column && column[0] && this._dashboardService.isEntityPrivacyColumn(column[0])) {
            if (value === 'Public') {
                return 'true';
            }
            if (value === 'Private') {
                return 'false';
            }
        }
        return value;
    }

    private _metadataProcessData(data: any[], tileConfig) {
        if (data == null || !Array.isArray(data)) {
            return [];
        }

        return data.map(element => {
            const item: any = {
                color: element.color,
                title: element.title,
                rawValue: element.value,
                fontSizeCalculator: null,
                isFileColumn: false
            };
            const temColumn = tileConfig.columns.filter(column => column.guid === element.id);
            const isEntityPrivacyColumn = temColumn && temColumn[0] && this._dashboardService.isEntityPrivacyColumn(temColumn[0]);
            if (isEntityPrivacyColumn) {
                element.value = this._dashboardService.cellRenderer(temColumn[0], { value: element.value });
            }
            item.isFileColumn = this._dashboardService.isFileType(temColumn[0]?.guid);
            if (typeof element.value === 'string') {
                element.value = this._dashboardService.formatLabelStrToString(element.value);
            }
            if (element.dataType === 'text' && typeof element.value === 'string') {
                item.value = this._dashboardService.formatLabelStrToLink(element.value);
            } else {
                item.value = element.value;
            }
            item.fontSizeCalculator = this.getFontSizeCalculatorForMetadataTile();
            return item;
        });
    }

    private _extendPayloadByDateType(payload, dateType, misc) {
        //  0-Date and Time, 1-Date Only, 2-Month and Year, 3-Year Only
        const result = Object.assign({}, payload);
        switch (dateType) {
            case '0':
            case '1':
                result.categoryAxisItems = [{
                    baseUnit: 'days',
                    visible: false,
                    max: misc.maxDate,
                    min: misc.minDate,
                }, {
                    baseUnit: 'months',
                    visible: true,
                }];
                result.chartLabelFormat = 'MMM yyyy';
                result.chartToolTipFormat = 'MM/dd/yyyy';
                break;
            case '2':
                result.categoryAxisItems = [{
                    baseUnit: 'months',
                    visible: true
                }];
                result.chartLabelFormat = 'MMM yyyy';
                result.chartToolTipFormat = 'MMM yyyy';
                break;
            case '3':
                result.categoryAxisItems = [{
                    baseUnit: 'years',
                    visible: true,
                }];
                result.chartLabelFormat = 'yyyy';
                result.chartToolTipFormat = 'yyyy';
                break;
        }
        return result;
    }

    private _getChartWidthCalculator(minDate, maxDate, dateType) {
        const yearOnly = dateType === '3';

        let scaleNum = 0;
        if (maxDate && minDate) {
            if (yearOnly) {
                scaleNum = maxDate.getFullYear() - minDate.getFullYear() + 1;
            } else {
                scaleNum = (maxDate.getFullYear() - minDate.getFullYear()) * 12 +
                    minDate.getMonth() - minDate.getMonth();
            }
        }
        let chartWidth = 0;
        if (scaleNum === 0) {
            return containerWidth => containerWidth;

        } else {
            // each scale width in line chart should larger than 42, which is an experienced value
            return (containerWidth) => {
                if (Math.abs(containerWidth / scaleNum) < 42) {
                    chartWidth = scaleNum * 42;
                } else {
                    chartWidth = containerWidth;
                }
                return Math.abs(chartWidth);
            };
        }
    }

    private _columns2thread(columns: object, widget: any, threads: Thread[]): Thread[] {
        let thread: Thread;
        let note: NoteEntry;
        const mapper: QueryNoteMapperService = new QueryNoteMapperService();
        const entity: any = { id: null, name: null, shortname: null };
        if (widget && widget.tile && widget.tile.columns && widget.tile.columns.length > 0) {
            // prepare thread
            // show full thread = true, will construct notes by thread id into real thread
            // show full thread = false, will make each note a fake thread
            const threadId = columns[defaultSubmitter.guid + '_attribute']['threadId'];
            // widget.tile.options['showFullThread'] ? columns[defaultSubmitter.guid + '_attribute']['threadId'] : columns[defaultSubmitter.guid + '_attribute']['entryId'];
            const existingThreads = threads.filter(item => item.id === threadId);
            if (existingThreads.length === 0) {
                thread = new Thread();
                thread.id = threadId;
                threads.push(thread);
            } else {
                thread = existingThreads[0];
            }

            // prepare note
            const entryId = columns[defaultSubmitter.guid + '_attribute']['entryId'];
            const existingNotes = thread.notes.filter(onenote => onenote.id === entryId);
            if (existingNotes.length === 0) {
                note = new NoteEntry();
                note.id = entryId;
                note.entities = [];
                note.attachments = new Map<string, DocumentEntry>();
                note.type = new EntryType(EntryType.TAMALE_NOTE.id, EntryType.TAMALE_NOTE.name);
                thread.notes.push(note);
            } else {
                note = existingNotes[0];
            }

            widget.tile.columns.forEach((columnDef) => {
                const value = columns[columnDef.guid] || null;
                const attribute = columns[columnDef.guid + '_attribute'] || {};
                if (attribute) {
                    if (attribute['entityId']) {
                        // entity info
                        // prepare source/submitter/entities
                        switch (columnDef.field) {
                            case defaultLongName.field:
                                entity.id = attribute['entityId'];
                                entity.name = value;
                                break;
                            case defaultShortName.field:
                                entity.id = attribute['entityId'];
                                entity.shortname = value;
                                break;
                            case defaultSource.field: {
                                const entityId = Array.isArray(attribute['entityId']) ? attribute['entityId'][0] : attribute['entityId'];
                                note.source = new EntityBrief(entityId, value);
                                note.source.company = attribute['company'];
                                break;
                            }
                            case defaultSubmitter.field: {
                                const entityId = Array.isArray(attribute['entityId']) ? attribute['entityId'][0] : attribute['entityId'];
                                note.submitter = new EntityBrief(entityId, value);
                                break;
                            }
                        }
                    } else if (attribute['entryId']) {
                        switch (columnDef.field) {
                            case PROPERTY_FIELD_MAP.get('subject'):
                                note.subject = value;
                                note.calculatedSubject = value;
                                break;
                            case PROPERTY_FIELD_MAP.get('noteType'):
                                if (value === EntryType.TAMALE_EVENT.name) {
                                    note.type = new EntryType(EntryType.TAMALE_EVENT.id, EntryType.TAMALE_EVENT.name);
                                } else if (value === EntryType.TAMALE_NOTE.name) {
                                    note.type = new EntryType(EntryType.TAMALE_NOTE.id, EntryType.TAMALE_NOTE.name);
                                } else if (value === EntryType.DEPOSITED_BLAST_EMAIL.name) {
                                    note.type = new EntryType(EntryType.DEPOSITED_BLAST_EMAIL.id, EntryType.DEPOSITED_BLAST_EMAIL.name);
                                } else {
                                    note.type = new EntryType(null, value);
                                }
                                break;
                            case PROPERTY_FIELD_MAP.get('displayDate'):
                                note.displayDate = new Date(columns[columnDef.guid + '_value']);
                                break;
                            case PROPERTY_FIELD_MAP.get('submittedDate'):
                                note.submittedDate = new Date(columns[columnDef.guid + '_value']);
                                break;
                            case PROPERTY_FIELD_MAP.get('sentiment'):
                                note.sentiment = mapper.property2Sentiment(value);
                                break;
                            case PROPERTY_FIELD_MAP.get('priority'):
                                note.priority = mapper.property2Priority(value);
                                break;
                            case PROPERTY_FIELD_MAP.get('noteBody'):
                                note.bodyLink = '/restapi/2.1/entry/' + value + '/body/';
                                break;
                            case PROPERTY_FIELD_MAP.get('blurb'):
                                if (value) {
                                    note.blurb = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
                                }
                                break;
                            case defaultEntryClass.field:
                                note.entryClass = parseInt(value, 10);
                                break;
                        }
                    }
                }
            });

            if (entity.id && entity.name) {
                if (typeof entity.id === 'object') {
                    for (let i = 0; i < entity.id.length && i < entity.name.length; i++) {
                        const newEntity = new EntityBrief(entity.id[i], entity.name[i]);
                        if (entity.shortname.length > i) {
                            newEntity.shortName = entity.shortname[i];
                        }
                        note.entities.push(newEntity);
                    }
                } else {
                    const newEntity = new EntityBrief(entity.id, entity.name);
                    newEntity.shortName = entity.shortname;
                    note.entities.push(newEntity);
                }
            }
            note.backdated = columns[defaultSubmitter.guid + '_attribute']['isBackdated'];
            note.editable = true;
            note.deletable = true;
        }

        note.adhocs = mapper.array2AdhocTable(columns['adhoc_table']);

        // prepare attachments
        let attachments = new Map<string, Array<DocumentEntry>>();
        for (const property in columns) {
            if (property.includes('_attachment')) {
                attachments = mapper.array2Attachments(columns[property], property.slice(0, property.length - '_attachment'.length));
                break;
            }
        }

        // set note attachemnts
        note = mapper.addAttachments2Note(attachments, note);

        thread = mapper.addNote2Thread(note, thread);

        for (const property in columns) {
            if (property.includes('_thread')) {
                thread = mapper.addOtherEntries(columns[property], thread);
                break;
            }
        }

        return threads;
    }

    private _errorHandler(error, name) {
        if (error && error.message) {
            if (error.message.includes('Timeout has occurred')) {
                this._toast.notify({
                    message: `Tile ${name} loads timeout.`,
                    style: NotificationStyles.Error
                });
            }
        }
    }

    private _initGeocoder() {
        this.geocoder = new google.maps.Geocoder();
    }

    private _waitForMapsToLoad(): Observable<boolean> {
        if (!this.geocoder) {
            return fromPromise(this._mapLoader.load())
                .pipe(
                    tap(() => this._initGeocoder()),
                    map(() => true)
                );
        }
        return of(true);
    }
}
