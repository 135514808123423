/**
 * created by Daniel Wang on 4/21/2022.
 * Description:
 * provides open/close subject by type for dialogs.
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum DialogType {
    ExchangeDuplicateDialog = 'ExchangeDuplicateDialog',
    MapTileDialog = 'MapTileDialog',
    WebSitesDialog = 'WebSitesDialog'
}

export class DialogAction {
    type: DialogType;
    payload?: any;

    constructor(_type: DialogType, _payload?) {
        this.type = _type;
        this.payload = _payload;
    }
}

@Injectable()
export class DialogService {
    dialogClose$: Subject<DialogAction> = new Subject<DialogAction>();
    dialogOpen$: Subject<DialogAction> = new Subject<DialogAction>();
}
