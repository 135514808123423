/**
 * created by Yu Zhang on 12/15/17.
 * Description:
 *
 * ------ maintenance history ------
 */

// get note
export const GET_NOTE = 'GET_NOTE';
export const GET_NOTE_SUCCESS = 'GET_NOTE_SUCCESS';
export const GET_NOTE_FAILURE = 'GET_NOTE_FAILURE';

// get note body
export const GET_NOTE_BODY = 'GET_NOTE_BODY';
export const GET_NOTE_BODY_SUCCESS = 'GET_NOTE_BODY_SUCCESS';
export const GET_NOTE_BODY_FAILURE = 'GET_NOTE_BODY_FAILURE';
export const GET_NOTE_BODY_FOR_EDIT = 'GET_NOTE_BODY_FOR_EDIT';
export const GET_NOTE_BODY_FOR_EDIT_SUCCESS = 'GET_NOTE_BODY_FOR_EDIT_SUCCESS';
export const GET_NOTE_BODY_FOR_EDIT_FAILURE = 'GET_NOTE_BODY_FOR_EDIT_FAILURE';
export const CLEAN_NOTE_BODY_FOR_EDIT = 'CLEAN_NOTE_BODY_FOR_EDIT';

// note message
export const CLEAR_NOTE_MSG = 'CLEAR_NOTE_MSG';

// note body focus
export const CHANGE_NOTE_BODY_FOCUS = 'CHANGE_NOTE_BODY_FOCUS';
export const CLEAN_NOTE_BODY_FOCUS = 'CLEAN_NOTE_BODY_FOCUS';

// create note
export const CREATE_NOTE = 'CREATE_NOTE';
export const CREATE_NOTE_SUCCESS = 'CREATE_NOTE_SUCCESS';
export const CREATE_NOTE_FAILURE = 'CREATE_NOTE_FAILURE';
export const CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS';
export const CREATE_DRAFT_FAILURE = 'CREATE_DRAFT_FAILURE';
export const AUTO_CREATE_DRAFT_SUCCESS = 'AUTO_CREATE_DRAFT_SUCCESS';
export const AUTO_CREATE_DRAFT_FAILURE = 'AUTO_CREATE_DRAFT_FAILURE';

// save note
export const SAVE_NOTE = 'SAVE_NOTE';

// update note
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_NOTE_SUCCESS = 'UPDATE_NOTE_SUCCESS';
export const UPDATE_NOTE_FAILURE = 'UPDATE_NOTE_FAILURE';
export const UPDATE_DRAFT_SUCCESS = 'UPDATE_DRAFT_SUCCESS';
export const UPDATE_DRAFT_FAILURE = 'UPDATE_DRAFT_FAILURE';
export const AUTO_UPDATE_DRAFT_SUCCESS = 'AUTO_UPDATE_DRAFT_SUCCESS';
export const AUTO_UPDATE_DRAFT_FAILURE = 'AUTO_UPDATE_DRAFT_FAILURE';

// delete note
export const DELETE_NOTE = 'DELETE_NOTE';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAILURE = 'DELETE_NOTE_FAILURE';
export const DELETE_NOTES = 'DELETE_NOTES';
export const DELETE_NOTES_SUCCESS = 'DELETE_NOTES_SUCCESS';
export const DELETE_NOTES_FAILURE = 'DELETE_NOTES_FAILURE';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';
export const DELETE_DRAFT = 'DELETE_DRAFT';
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';
export const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE';
export const DELETE_AUTOSAVED_DRAFT = 'DELETE_AUTOSAVED_DRAFT';
export const DELETE_AUTOSAVED_DRAFT_SUCCESS = 'DELETE_AUTOSAVED_DRAFT_SUCCESS';
export const DELETE_AUTOSAVED_DRAFT_FAILURE = 'DELETE_AUTOSAVED_DRAFT_FAILURE';

// delete sidenote
export const DELETE_SIDENOTE = 'DELETE_SIDENOTE';
export const DELETE_SIDENOTE_SUCCESS = 'DELETE_SIDENOTE_SUCCESS';
export const DELETE_SIDENOTE_FAILURE = 'DELETE_SIDENOTE_FAILURE';

// delete attachment
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const DELETE_ATTACHMENT_SUCCESS = 'DELETE_ATTACHMENT_SUCCESS';
export const DELETE_ATTACHMENT_FAILURE = 'DELETE_ATTACHMENT_FAILURE';


// create event
export const CLEAR_EVENT_MSG = 'CLEAR_EVENT_MSG';
export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

// update event
export const UPDATE_EVENT = 'UPDATE_EVENT';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';
// update event address
export const UPDATE_EVENT_ADDRESS_SUCCESS = 'UPDATE_EVENT_ADDRESS_SUCCESS';

// remove entities
export const REMOVE_ENTITIES = 'REMOVE_ENTITIES';
export const CLEAN_REMOVE_ENTITIES = 'CLEAN_REMOVE_ENTITIES';
