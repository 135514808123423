/**
 * Created by Abner Sui on 03/14/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { createSelector, combineReducers } from '@ngrx/store';
import { AppState } from '..';
import { AdhocActions, AdhocActionTypes } from '../actions/adhoc.actions';
import { Adhoc } from '../../tamalelibs/models/adhoc.model';

export const adhocSelector = (state: AppState) => state.adhoc;
export const adhocTitleSelector = createSelector(adhocSelector, (state: any) => state.title);
export const adhocDBSelector = createSelector(adhocSelector, (state: any) => state.db);

export function adhocTitleReducer(state: Map<string, Adhoc>, action: AdhocActions): Map<string, Adhoc> {
    switch (action.type) {
        case AdhocActionTypes.INITIALIZE_SUCCESS:
            return action.payload.title;
        case AdhocActionTypes.INITIALIZE_FAILED:
            return action.payload.title;
        default:
            return state;
    }
}

export function adhocDBReducer(state: Map<string, Adhoc>, action: AdhocActions): Map<string, Adhoc> {
    switch (action.type) {
        case AdhocActionTypes.INITIALIZE_SUCCESS:
            return action.payload.db;
        case AdhocActionTypes.INITIALIZE_FAILED:
            return action.payload.db;
        default:
            return state;
    }
}

const adhocReducer = combineReducers({
    title: adhocTitleReducer,
    db: adhocDBReducer,
});

export function getAdhocReducer(state, action) {
    return adhocReducer(state, action);
}
