/**
 * Created by Yoyo Fang on 05/05/19.
 * Description:
 *
 * ------ maintenance history ------
 */

import { RELOAD_ENTITIES_SUCCESS, RELOAD_ENTITIES_FAIL, DELETE_ENTITY_SUCCESS, DELETE_ENTITY_FAIL } from './entity-list.actions';
import { EntityList } from '../models/entity-list.model';

export function entityListReducer(state: any = null, action) {
    switch (action.type) {
        case RELOAD_ENTITIES_SUCCESS:
            if (action.params.workflow !== 'scroll') {
                return {
                    entityList: action.payload,
                    params: action.params
                };
            } else {
                const newEntityList = new EntityList();
                newEntityList.id = action.payload.id;
                newEntityList.entities = state.entityList.entities.concat(action.payload.entities);
                newEntityList.next = action.payload.next;
                return {
                    entityList: newEntityList,
                    params: action.params
                };
            }
        case RELOAD_ENTITIES_FAIL:
            return null;
        case DELETE_ENTITY_SUCCESS:
            const newData = Object.assign({}, state);
            const deletedEntityId = action.payload.id;
            const newEntities = newData?.entityList?.entities.filter(item => item.id !== deletedEntityId);
            newData.entityList.entities = newEntities;
            return newData;
        case DELETE_ENTITY_FAIL:
            return state;
        default:
            return state;
    }
}
