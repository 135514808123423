/**
 * Created by Marcus Zhao on 8/25/2020.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum FlyDialogActionTypes {
    INCREMENT = 'INCREMENT',
    DECREMENT = 'DECREMENT',
    RESET = 'REST'
}


export class IncremnetIndex implements Action {
    readonly type = FlyDialogActionTypes.INCREMENT;
    constructor(public payload: any) { }
}

export class DecremnetIndex implements Action {
    readonly type = FlyDialogActionTypes.DECREMENT;
    constructor(public payload: any) { }
}

export class ResetIndex implements Action {
    readonly type = FlyDialogActionTypes.RESET;
    constructor(public payload: any) { }
}
export type FlyIndexActions = IncremnetIndex | DecremnetIndex | ResetIndex;
