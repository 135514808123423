/**
 * Created by Abner Sui on 01/29/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';
import { DocumentEmail } from '../../tamalelibs/models/document-email.model';

export class AttachmentView {
    id: string;
    fileName: string;
    extension: string;
    fileDataLink: string;
    highlight: boolean;
    fileSize: number;
    fileId: string;
    editable: boolean;
    deletable: boolean;
    isUsedFile: boolean;
    constructor() {
        this.highlight = false;
        this.isUsedFile = false;
    }

    static parseFromDocumentEntry(source: DocumentEntry): AttachmentView {
        const attachment = new AttachmentView();
        // use serverFileName as file id.
        attachment.id = source.id;
        // add fileId for support attchment preview with new file api
        attachment.fileId = source.serverFileName;
        attachment.fileName = source.fileName;
        attachment.extension = source.extension;
        attachment.fileDataLink = source.fileDataLink;
        attachment.editable = source.editable;
        attachment.deletable = source.deletable;
        attachment.fileSize = source.size;
        return attachment;
    }

    static parseFromDocumentEmail(source: DocumentEmail): AttachmentView {
        const attachment = new AttachmentView();
        attachment.id = source.id;
        // add fileId for support attchment preview with new file api
        attachment.fileId = source.id;
        attachment.fileName = source.fileName;
        attachment.extension = source.extension;
        attachment.fileDataLink = source.fileDataLink;
        attachment.editable = true;
        attachment.deletable = true;
        // set a flag for use file usr
        attachment.isUsedFile = true;
        return attachment;
    }
}
