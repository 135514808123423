/**
 * Created by Abner Sui on 01/02/19.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref';

export enum GlobalAction {
    FOCUS = 'FOCUS',
}

@Injectable()
export class PlatformService {

    constructor(
        private _winRef: WindowRef,
    ) { }

    public cleanWindowSelection() {
        // chrome
        if (this._winRef.nativeWindow.getSelection) {
            if (this._winRef.nativeWindow.getSelection().empty) {
                this._winRef.nativeWindow.getSelection().empty();
            } else if (this._winRef.nativeWindow.getSelection().removeAllRanges) {
                this._winRef.nativeWindow.getSelection().removeAllRanges();
            }
        } else if (this._winRef.nativeDocument.selection) {
            this._winRef.nativeDocument.selection.empty();
        }
    }

}
