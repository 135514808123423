/**
 * Created by Ella Ma on 10/16/2020. custom grid filter panel - has two types(date column + !date column)
 * ------ maintenance history ------
 * Updated by Daniel on 07/19/2021. moved the component to widget from dashboard component
 */

import { Component, ViewChild, ElementRef } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, RowNode, IDoesFilterPassParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';
import { NOT_FORMAT_LINK_PARA_NAME } from '../../tamalelibs/services/workflow.service';
import { CustomDateColumnFilterConfig, CustomDateColumnFilterConfigActionType, CustomDateColumnFilterConfigEventType } from './custom-grid-column-filter.model';
import { CustomGridColumnFilterService } from './custom-grid-column-filter.service';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../redux';
import { lastCustomActionSelector } from '../../redux/reducers/action-history.reducer';
import { CREATE_NOTE_SUCCESS, UPDATE_NOTE_SUCCESS } from '../../tamalelibs/redux/note.actions';

const BLANK_VALUE = '(Blanks)';

@Component({
    selector: 'tam-custom-grid-column-filter',
    templateUrl: './custom-grid-column-filter.component.html',
    styleUrls: ['./custom-grid-column-filter.component.scss']
})

export class TamCustomGridColumnFilterComponent implements IFilterAngularComp {
    search = '';
    // 1.check 2.show(for multi column filter) 3.default(for search) 4.text(default value)5.textFormat(show value)
    data = [];
    selectAll = 'all';
    isDateColumn = false;
    customDateColumnFilterConfig = new CustomDateColumnFilterConfig();

    @ViewChild('searchInput', { static: false }) searchInputEle: ElementRef;

    private _params: IFilterParams;
    private _valueGetter: any;
    private _doesRowPassOtherFilter: (rowNode: RowNode) => any;
    private _columnSetting: any;
    private _oldSearch = '';
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _store: Store<AppState>,
    ) { }

    /**
     * ag grid IFilterComp required
     * @param params
     */
    agInit(params: IFilterParams): void {
        const columnSetting = {
            dataType: 'date',
            settings: {
                display: 0
            }
        };
        this._params = params;
        this._valueGetter = params.valueGetter;
        this._doesRowPassOtherFilter = params.doesRowPassOtherFilter;
        this._columnSetting = params['columnSetting'] ? params['columnSetting'] : columnSetting;
        this.isDateColumn = this._columnSetting.dataType === 'date';
        this._initFilterData();
        this._initSubscriptions();
    }

    /**
     * ag grid IFilterComp optional
     */
    destroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    /**
     * ag grid IFilterComp optional
     * @param params
     */
    afterGuiAttached() {
        this.searchInputEle.nativeElement.focus();
        this._resetDataCheckStatus();
    }

    /**
     * ag grid IFilterComp required
     * @param params
     */
    isFilterActive(): boolean {
        const showLen = this.data.filter(item => item.show).length;
        const checkLen = this.data.filter(item => item.show && item.check).length;
        const defaultShowLen = this.data.filter(item => item.default).length;
        const search = this.search !== null && this.search !== undefined && this.search.trim() !== '';
        return search || showLen !== checkLen || defaultShowLen !== showLen;
    }

    /**
     * ag grid IFilterComp required
     * @param params
     */
    doesFilterPass(params: IDoesFilterPassParams): boolean {
        const value = this._valueGetter(params);
        const formatValue = this._formatText(value);
        const tempData = this.data.filter(item => item.check && item.textFormat === formatValue);
        return tempData.length > 0;
    }

    /**
     * ag grid IFilterComp required
     * @param params
     */
    getModel(): any {
        const values = this.getDisplayedValues();
        let filterType = '';
        let isNotBlank = false;
        let isIncludeUncheckedItems = false;
        if (values.length !== this.data.length) {
            filterType = 'set';
            // not include blank && only not include blank, isNotBlank set true.
            const unCheckItems = this.data.filter(item => !item.check);
            if (unCheckItems.length === 1 && unCheckItems[0].textFormat === BLANK_VALUE) {
                isNotBlank = true;
            }
            isIncludeUncheckedItems = this._isIncludeUncheckFilterItem();
        }
        return { values, filterType, isNotBlank, isIncludeUncheckedItems };
    }

    /**
     * ag grid IFilterComp required
     * @param params
     */
    setModel(model: any): void {
    }

    /**
     * ag grid IFilterComp custom function - ourself custom
     */
    onRefreshFilter() {
        this._initFilterData();
    }

    /**
     * ag grid IFilterComp custom function - ourself custom
     */
    getDisplayedValues() {
        const checkItems = this.data.filter(item => item.check && item.default);
        return checkItems.map(item => item.text);
    }

    /**
     * ag grid IFilterComp custom function - ourself custom
     */
    selectValue(values) {
        // set selectAll to active filter
        this.selectAll = this._setSelectedALl();
        this.search = '';
        const tempValues = [];
        values.forEach(item => {
            tempValues.push(this._formatText(item));
        });
        this.data.forEach(item => {
            item.check = tempValues.indexOf(item.textFormat) !== -1;
        });
        this._onFilterChange();
    }

    /**
     * TamCustomGridColumnFilterComponent page bind function
     * @param newValue
     */
    onSearchChange(newValue): void {
        const searchValue = newValue.trim().toLowerCase();
        if (searchValue === this._oldSearch) {
            return;
        }
        this._oldSearch = searchValue;
        this.data.forEach((item) => {
            if (item.default) {
                // default value is null | undefined | '' - format to show (Blanks) should not be search
                const textIsNull = item.text || searchValue === '';
                if (textIsNull && item.textFormat.toLowerCase().indexOf(searchValue) >= 0) {
                    item.check = true;
                    item.show = true;
                } else {
                    item.check = false;
                    item.show = false;
                }
            }
        });
        this.selectAll = this._setSelectedALl();
        this._onFilterChange();
        if (this.isDateColumn) {
            this._resetDateColumnData(true);
        }
    }

    /**
     * TamCustomGridColumnFilterComponent page bind function
     * @param newValue
     */
    clearSearch() {
        this.search = '';
        this.onSearchChange('');
    }

    /**
     * TamCustomGridColumnFilterComponent page bind function
     * @param newValue
     */
    onSelectedAll(value) {
        this.data.forEach(item => {
            if (item.show) {
                item.check = value === 'all';
            }
        });
        this.customDateColumnFilterConfig.actions$.next({
            type: CustomDateColumnFilterConfigActionType.onSelectAll,
            payload: value
        });
        this.selectAll = this._setSelectedALl();
        this._onFilterChange();
    }

    /**
     * TamCustomGridColumnFilterComponent page bind function
     * @param newValue
     */
    onSelected(value) {
        this.data.forEach(item => {
            if (item.textFormat === value.textFormat) {
                item.check = !value.check;
            }
        });
        this.selectAll = this._setSelectedALl();
        this._onFilterChange();
    }

    private _isIncludeUncheckFilterItem(): boolean {
        const checkItems = this.data.filter(item => !item.check && item.show);
        if (checkItems.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    private _onFilterChange() {
        this._params.filterChangedCallback();
    }

    private _resetDataCheckStatus() {
        const showItems = [];
        this._params.api.forEachNode((node) => {
            if (!node.group) {
                const show = this._doesRowPassOtherFilter(node);
                if (show) {
                    node['node'] = node;
                    showItems.push(this._valueGetter(node));
                }
            }
        });
        const tempItems = this._removeDuplicateData(showItems);
        const tempFormatItems = [];
        const minusItems = [];
        tempItems.forEach(element => {
            const formatText = this._formatText(element);
            tempFormatItems.push(formatText);
            /**
             * if grid no filter column, cases:
             * (1) add row, delete row, edit row update filter panel data by 251-262, no filter column, no call onRefreshFilter function
             * if grid has filter column, filter panel data updata by onRefreshFilter function.
             * (1) Add row - if isNotBlank = true, new row checked, if isNotBlank = false, new rows unchecked.
             * (2) Edit row - the edit row must checked. onRefreshFilter update filter panel data, _updateGridRowItems update filter selected values
             */
            const temp = this.data.find(item => item.textFormat === formatText);
            if (!temp) {
                minusItems.push({
                    text: element,
                    textFormat: formatText,
                    check: true,
                    show: true,
                    default: true
                });
            }
        });
        // use [].contact, because the data should be sort by pipe.
        this.data = [].concat(this.data, minusItems);
        // get all the filters
        const filters = this._params.api.getFilterModel();
        if (filters) {
            // to set the properties if the number of filters is less than 2
            if (Object.values(filters).length <= 1) {
                this.data.forEach(item => {
                    // comment the below line of code to fix TAM-42047, The item should not be removed from filter if the item didn't show in grid
                    item.show = this._formatShow(item.textFormat, tempFormatItems);
                    item.default = this._formatShow(item.textFormat, tempFormatItems);
                });
            }
        }

        this.selectAll = this._setSelectedALl();
        this.search = '';
        if (this.isDateColumn) {
            this._resetDateColumnData();
        }
    }

    private _initFilterData() {
        let tempData = [];
        const data = [];
        this._params.api.forEachNode((node) => {
            if (!node.group) {
                node['node'] = node;
                const value = this._valueGetter(node);
                if (!ArrayHelperService.arrayContains(tempData, value)) {
                    tempData.push(value);
                }
            }
        });
        tempData = this._removeDuplicateData(tempData);
        // Remove duplicate data
        tempData.forEach(item => {
            data.push({
                text: item,
                textFormat: this._formatText(item),
                check: true,
                show: true,
                default: true
            });
        });
        this.data = data;
        this.selectAll = this._setSelectedALl();
        this.search = '';
        this.customDateColumnFilterConfig.items = data.filter(item => item.show);
        this.customDateColumnFilterConfig.column = this._columnSetting;
    }

    private _removeDuplicateData(values) {
        const tempData = Array.from(new Set(values));
        // if has string default value is (Blanks) will remove multi (Blanks) items.
        const blankIndex = tempData.indexOf(BLANK_VALUE);
        if (blankIndex !== -1 && tempData.some(item => item === '' || item === undefined || item === null)) {
            tempData.splice(blankIndex, 1);
        }
        let arr;
        // if one item is '', anthor one is undefined, remove dulpicate item
        const blankItems = tempData.filter(item => !item && item !== false && item !== 0);
        if (blankItems.length > 1) {
            const notBlankItems = tempData.filter(item => !!item);
            arr = notBlankItems.concat([blankItems[0]]);
        } else {
            arr = tempData;
        }
        // remove duplicate Date data, Date object remove repeat data should not use Array.from(new Set())
        if (this.isDateColumn) {
            const data = [];
            // use map to ensure the data's uniqueness rather than running searches against an array which lead the iteration becomes a Cartesian product.
            const dateMap = new Map();
            arr.forEach(item => {
                if (item !== null && item !== undefined) {
                    if (!dateMap.has(item)) {
                        dateMap.set(item, true);
                        data.push(item);
                    }
                } else {
                    data.push('');
                }
            });
            arr = data;
        }
        return arr;
    }

    private _setSelectedALl() {
        const showLen = this.data.filter(item => item.show).length;
        const checkLen = this.data.filter(item => item.show && item.check).length;
        if (checkLen === 0) {
            return 'none';
        } if (checkLen === showLen) {
            return 'all';
        }
        return 'some';
    }

    private _formatShow(value, showRows) {
        let data;
        if (this.isDateColumn) {
            data = showRows.find(item => item.toString() === value.toString());
        } else {
            data = showRows.find(item => item === value);
        }
        return !!data;
    }

    private _formatText(value) {
        const paraObj = { value };
        paraObj[NOT_FORMAT_LINK_PARA_NAME] = true;
        const tempValue = CustomGridColumnFilterService.cellRender(this._columnSetting, paraObj);
        if (!tempValue && tempValue !== false && tempValue !== 0) {
            return BLANK_VALUE;
        }
        if (typeof tempValue === 'boolean') {
            return tempValue.toString();
        }
        return tempValue;
    }

    private _initSubscriptions() {
        this._destroySubscriptions.push(
            this._store.pipe(
                select(lastCustomActionSelector)
            ).subscribe(action => this._actionMessageHandler(action)),

            this.customDateColumnFilterConfig.events$.subscribe((events) => {
                if (events.type === CustomDateColumnFilterConfigEventType.onFilter) {
                    this.data.forEach(element => {
                        const data = events.payload.find(item => item.value === element.textFormat);
                        if (data) {
                            element.check = data.check;
                        }
                    });
                    this.selectAll = this._setSelectedALl();
                    this._onFilterChange();
                }
            })
        );
    }

    /**
     * refresh grid column filter after adding notes
     * @param action
     */
    private _actionMessageHandler(action) {
        if (action.type === UPDATE_NOTE_SUCCESS || action.type === CREATE_NOTE_SUCCESS) {
            setTimeout(() => {
                const newEntryTypeName = localStorage.getItem('EntryTypeName');
                if (newEntryTypeName) {
                    // Check if the entry already exists in the data array
                    const existingItem = this.data.find(item => item.textFormat === newEntryTypeName);

                    if (existingItem) {
                        // If the entry exists, set its 'check' property to true
                        existingItem.check = true;
                    } else {
                        // If the entry does not exist, add a new entry
                        this.data.push({
                            check: true,
                            default: true,
                            show: true,
                            text: newEntryTypeName,
                            textFormat: newEntryTypeName
                        });
                    }
                }
            }, 1000);
        }
    }

    private _resetDateColumnData(expand?) {
        const data = this.data.filter(item => item.show);
        this.customDateColumnFilterConfig.items = data;
        this.customDateColumnFilterConfig.actions$.next({
            type: CustomDateColumnFilterConfigActionType.resetData,
            payload: !!expand
        });
    }
}
