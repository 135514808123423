/**
 * Created by Alex Xia on 29/05/2023.
 * Description:
 *
 * ------ maintenance history ------
 */

import { AppState } from '..';
import { createSelector } from '@ngrx/store';
import { NotificationActions, NotificationActionTypes } from '../actions/notification.actions';

export const notificationSelector = createSelector((state: AppState) => state, state => state.notification);

export function notificationReducer(state: Array<Notification>, action: NotificationActions) {
    switch (action.type) {
        case NotificationActionTypes.GETNOTIFICATIONS_SUCCESS:
            return action;
        case NotificationActionTypes.CREATENOTIFICATIONS_SUCCESS:
            return action.payload;
        case NotificationActionTypes.CREATENOTIFICATIONS_FAILED:
            return action;
        default:
            return state;
    }
}
