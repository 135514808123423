/**
 * Created by Jiu Chen on 09/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 * 20181212 Yoyo Fang   'user' object is the only usecase of this service by now.
 *                      In web 1.0, 'user' object is stored in cookie storage.
 *                      To keep consistant to web 1.0, use cookie instead of localstorage.
 */
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class StorageService {
    constructor(
        private cookieService: CookieService,
    ) { }

    getItem(key: string): any {
        const valueStr = this.cookieService.get(key);
        if (valueStr) {
            return JSON.parse(valueStr);
        } else {
            return null;
        }
    }

    setItem(key: string, value: any) {
        this.cookieService.set(key, JSON.stringify(value), 0, '/');
    }

    removeItem(key: string): any {
        this.cookieService.delete(key, '/');
    }
}
