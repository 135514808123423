/**
 * Created by mzhao on 09/09/2022.
 * Description:
 * EmailSignatureConfig
 * ------ maintenance history ------
 */

import { Subject } from 'rxjs';

/**
 * signature data ,inlucde all data ,duplicate, none duplicate.
 */
export class SignatureData {
    allSignatureData: Array<any> = [];
    signatureWithDuplicateData: Array<any> = [];
    signatureWithoutDuplicateData: Array<any> = [];
}

export class EmailSignatureConfig {
    signatureData: SignatureData;
    /**set or get data from parent to child */
    actionSubject$: Subject<any>;
    /**set or get data form child to parent */
    feedbackSubject$: Subject<any>;
    parentConfig: any;

    constructor() {
        this.signatureData = new SignatureData();
        this.actionSubject$ = new Subject();
        this.feedbackSubject$ = new Subject();
    }
}

export enum SignatureAction {
    setValue = 'setValue',
    setStatus = 'setStatus'
}
