/**
 * Created by Abner Sui on 04/19/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Subject } from 'rxjs';

export class BriefConfig {
    actionSubject$: Subject<any>;
    feedbackSubject$: Subject<any>;

    constructor() {
        this.actionSubject$ = new Subject();
        this.feedbackSubject$ = new Subject();
    }
}
