/**
 * Create by
 * Description:
 *
 * ------ maintenance history ------
 * 2020/2/26  Bowen: change the parse function: because the relationship tree data model changed
 */
import { RelationshipType } from './relationship-type.model';
import { EntityBrief } from './entity-brief.model';
import { EntityType } from './entity-type.model';

export class Relationship {
    childEntity: EntityBrief;
    parentEntity: EntityBrief;
    relationshipType: RelationshipType;
    relationshipId: string;
    nodesType?: string;
    startYear?: string;
    endYear?: string;

    /**
     * Parse the relationship data from response coming from "entity" endpoint.
     * @param serverResponse The entity data in the response (entity endpoint)
     * @returns A array of Relationship objects
     */
    static parse(serverResponse): Array<Relationship> {
        if (!serverResponse.relationships) {
            return null;
        }
        const relationships: Array<Relationship> = new Array<Relationship>();
        const parentEntityItem = {
            // tslint:disable-next-line: max-line-length
            company: serverResponse['contact-details'] && serverResponse['contact-details']['company'] ? serverResponse['contact-details']['company'] : '',
            // tslint:disable-next-line: max-line-length
            title: serverResponse['contact-details'] && serverResponse['contact-details']['job-title'] ? serverResponse['contact-details']['job-title'] : '',
            id: serverResponse['id'],
            name: serverResponse['long-name'],
            shortName: serverResponse['short-name'],
            isPublic: serverResponse['is-public'] === 'true',
            type: EntityType.parse(serverResponse['entity-type'])
        };

        if (serverResponse.relationships.teams && serverResponse.relationships.teams.length > 0) {
            serverResponse.relationships.teams.forEach(element => {
                if (element.children && element.children.length > 0 && element.type.id !== RelationshipType.TOPIC_MEMBER.id) {
                    element.children.forEach(item => {
                        relationships.push({
                            childEntity: EntityBrief.parseRelTreeNode(item),
                            parentEntity: parentEntityItem,
                            relationshipType: RelationshipType.parse(element.type),
                            relationshipId: item['relationship-id']
                        });
                    });
                }
            });
        }
        if (serverResponse.relationships.entities && serverResponse.relationships.entities.length > 0) {
            serverResponse.relationships.entities.forEach(element => {
                if (element.children && element.children.length > 0 && element.type.id !== RelationshipType.TOPIC_MEMBER.id) {
                    element.children.forEach(item => {
                        relationships.push({
                            childEntity: EntityBrief.parseRelTreeNode(item),
                            parentEntity: parentEntityItem,
                            relationshipType: RelationshipType.parse(element.type),
                            relationshipId: item['relationship-id']
                        });
                    });
                }
            });
        }
        if (serverResponse.relationships.contacts && serverResponse.relationships.contacts.length > 0) {
            serverResponse.relationships.contacts.forEach(element => {
                if (element.children && element.children.length > 0 && element.type.id !== RelationshipType.TOPIC_MEMBER.id) {
                    element.children.forEach(item => {
                        relationships.push({
                            childEntity: EntityBrief.parseRelTreeNode(item),
                            parentEntity: parentEntityItem,
                            relationshipType: RelationshipType.parse(element.type),
                            relationshipId: item['relationship-id']
                        });
                    });
                }
            });
        }
        if (serverResponse.relationships['current_employment'] &&
            serverResponse.relationships['current_employment'].length > 0) {
            serverResponse.relationships['current_employment'].forEach(element => {
                if (element.children && element.children.length > 0 && element.type.id !== RelationshipType.TOPIC_MEMBER.id) {
                    element.children.forEach(item => {
                        relationships.push({
                            childEntity: EntityBrief.parseRelTreeNode(item),
                            parentEntity: parentEntityItem,
                            relationshipType: RelationshipType.parse(element.type),
                            relationshipId: item['relationship-id'],
                            nodesType: 'currentEmployment',
                            startYear: item['start_date'],
                        });
                    });
                }
            });
        }

        if (serverResponse.relationships['former_employment'] &&
            serverResponse.relationships['former_employment'].length > 0) {
            serverResponse.relationships['former_employment'].forEach(element => {
                if (element.children && element.children.length > 0 && element.type.id !== RelationshipType.TOPIC_MEMBER.id) {
                    element.children.forEach(item => {
                        relationships.push({
                            childEntity: EntityBrief.parseRelTreeNode(item),
                            parentEntity: parentEntityItem,
                            relationshipType: RelationshipType.parse(element.type),
                            relationshipId: item['relationship-id'],
                            nodesType: 'formerEmployment',
                            startYear: item['start_date'],
                            endYear: item['end_date']
                        });
                    });
                }
            });
        }
        return relationships;
    }
}
