<div class="container tam-form-field" [ngClass]="{'has-error':requireInvalid}">
    <div class="tam-form-label label-area">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name body-3 text-ellipsis"></field-label>
    </div>
    <kendo-combobox [popupSettings]="{appendTo: 'component'}" #kendo class="tam-textbox field-control" [data]="data"
        [filterable]="true" [(ngModel)]="config.field.value" (valueChange)="valueChange($event)" (focus)="focus()"
        (filterChange)="handleFilter($event)" [disabled]="isDisabled" [ngClass]="{'field-disabled':isDisabled}">
    </kendo-combobox>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
</div>