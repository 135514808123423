/**
 * Created by Jiu Chen on 01/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 * Modified by Cathy Yao on 09/9/2021:add delete success case
 */
import { UsersActions, UsersActionTypes } from '../actions/users.actions';
import { AppState } from '..';
import { createSelector } from '@ngrx/store';
import { AdminPageActions, AdminPageActionTypes } from '../actions/admin-page.actions';
import { User } from '../../tamalelibs/models/user.model';
import { EDIT_CONTACT_SUCCESS } from '../../tamalelibs/redux/app.actions';

export const fullUsersSelector = createSelector((state: AppState) => state, (state: AppState) => state.users);
export const userSettingSelector = createSelector((state: AppState) => state, (state: AppState) => state.userSetting);
export const usersAndTeamsSelector = createSelector(
    (state: AppState) => state.users,
    (state: AppState) => state.teams,
    (allUser, allTeam) => {
        if (allUser.length === 0 && allTeam.length === 0) {
            return [];
        }
        allUser.forEach(user => {
            user['type'] = 'user';
        });
        allTeam.forEach(team => {
            team['type'] = 'team';
        });
        const usersAndTeams = allUser.concat(allTeam);
        usersAndTeams.sort(function (a, b) {
            return a.name.localeCompare(b.name);
        });
        return usersAndTeams;
    }
);

export function usersReducer(state: Array<any> = [], action: UsersActions | AdminPageActions | any) {
    switch (action.type) {
        case UsersActionTypes.INITIALIZE_SUCCESS:
            return action.payload;
        case UsersActionTypes.INITIALIZE_FAILED:
            return [];
        case AdminPageActionTypes.DELETE_USERS_SUCCESS: {
            state = state.filter(item => item.id !== action.payload.id);
            return Object.assign([], state);
        }
        case AdminPageActionTypes.CREATE_USER_SUCCESS: {
            const newUser = new User();
            newUser.id = action.payload.newUser.id;
            newUser.shortName = action.payload.newUser['fullname'];
            newUser.name = action.payload.newUser['fullname'];
            state.push(newUser);
            return Object.assign([], state);
        }

        case EDIT_CONTACT_SUCCESS:
            state.forEach(item => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                    item.shortName = action.payload.shortName;
                    if (action.payload.primaryEmail) {
                        item.primaryEmail = action.payload.primaryEmail;
                    }
                }
            });
            return Object.assign([], state);
        default:
            return state;
    }
}

export function userSettingReducer(state, action: UsersActions) {
    switch (action.type) {
        case UsersActionTypes.LOAD_USER_SETTING_SUCCESS:
            return action.payload;
        case UsersActionTypes.LOAD_USER_SETTING_FAILED:
            return null;
        case UsersActionTypes.UPDATE_USER_SETTING_SUCCESS:
            return action.payload;
        case UsersActionTypes.UPDATE_USER_SETTING_FAILED:
            return null;
        default:
            return state;
    }
}
