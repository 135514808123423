import { AuthActions, AuthActionTypes } from '../actions/auth.actions';

export enum AuthStatusTypes {
    INIT = '',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    LOGOUT = 'LOGOUT',
    SESSION_TIMEOUT = 'SESSION_TIMEOUT',
    TOKEN_MISSED = 'TOKEN_MISSED'
}

export interface AuthState {
    status: AuthStatusTypes;
    errorMessage: string;
    returnUrl: string;
    needLogin: boolean;
}

const INITIAL_LOGIN_PAGE_STATE: AuthState = {
    status: AuthStatusTypes.INIT,
    errorMessage: '',
    returnUrl: '',
    needLogin: false
};

export function authReducer(state: AuthState = INITIAL_LOGIN_PAGE_STATE, action: AuthActions) {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS: {
            return {
                status: AuthStatusTypes.LOGIN_SUCCESS,
                errorMessage: '',
                returnUrl: state.returnUrl,
                needLogin: false
            };
        }
        case AuthActionTypes.LOGIN_FAILED: {
            return {
                status: AuthStatusTypes.LOGIN_FAILED,
                errorMessage: action.payload,
                returnUrl: state.returnUrl,
                needLogin: true
            };
        }
        case AuthActionTypes.SESSION_TIMEOUT: {
            return {
                status: AuthStatusTypes.SESSION_TIMEOUT,
                errorMessage: action.payload,
                returnUrl: state.status === AuthStatusTypes.SESSION_TIMEOUT ? state.returnUrl : action.fromUrl,
                needLogin: true
            };
        }
        case AuthActionTypes.LOGOUT: {
            return {
                status: AuthStatusTypes.LOGOUT,
                errorMessage: '',
                returnUrl: '',
                needLogin: true
            };
        }
        default:
            return state;
    }
}
