/**
 * Created by Ella Ma on 11/24/2020. custom grid group row render
 * ------ maintenance history ------
 * Update by Daniel on 07/20/2021. convert the component to widget
 */

import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { RowNode } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { NOT_FORMAT_LINK_PARA_NAME } from '../../tamalelibs/services/workflow.service';

import { CustomGridServiceTriggerType, TamCustomGridColumnFilterComponentsActions } from './custom-grid-column-filter.model';
import { CustomGridColumnFilterService } from './custom-grid-column-filter.service';

const BLANK_VALUE = '(Blanks)';

@Component({
    selector: 'tam-custom-group-row-inner-renderer',
    templateUrl: './custom-group-row-inner-renderer.component.html',
    styleUrls: ['./custom-group-row-inner-renderer.component.scss']
})

// tslint:disable-next-line: component-class-suffix
export class TamCustomGroupRowInnerRenderer implements ICellRendererAngularComp, OnDestroy {

    aggFuncColumns = [];
    groupValue;

    private _destroySubscriptions: Array<Subscription> = [];
    private _node: RowNode;
    private _tileConfig: any;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    /**
    * ag grid IFilterComp optional
    */
    agInit(params: any): void {
        this._node = params.node;
        this._tileConfig = params.tileConfig;
        this._initAggFun(params);
        setTimeout(() => { // to avoid tileConfig empty case. such as loading workflow on home screen
            this.groupValue = this._groupRowInnerRenderer(params);
            this._changeDetectorRef.detectChanges();
        });

        this._destroySubscriptions.push(
            params.action$.subscribe(action => {
                if (action.type === TamCustomGridColumnFilterComponentsActions.UPDATE_COLUMN_WIDTH_BY_OPERATION) {
                    this._resetStyle(params);
                } else if (action.type === TamCustomGridColumnFilterComponentsActions.UPDATE_COLUMN_WIDTH_BY_SCROLL) {
                    this._updateGroupAggFuncValuePosition(action.payload);
                }
            })
        );
    }

    /**
     * ag grid IFilterComp optional
     * Get the cell to refresh. Return true if successful. Return false if not (or you don't have refresh logic),
     * then the grid will refresh the cell for you
     */
    refresh(): boolean {
        return false;
    }

    /**
    * ag grid IFilterComp optional
    */
    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    // calcute style by the ag-grid header
    private _calcuteStyle(params, key) {
        let left = 0;
        let width = 0;
        const columns = CustomGridColumnFilterService.initGridHeader(params.columnApi);
        for (const column of columns) {
            if (column.id === key) {
                width = column.width;
                return { left, width };
            } else {
                left += column.width;
            }
        }
        return { left, width };
    }

    private _groupRowInnerRenderer(params) {
        if (this._tileConfig && this._tileConfig.columns && this._tileConfig.columns.length > 0) {
            const index = this._tileConfig.columns.findIndex(item => item.guid === params.node.field);
            if (index !== -1) {
                const paraObj = {
                    value: params.node.key
                };
                paraObj[NOT_FORMAT_LINK_PARA_NAME] = true;
                const value = CustomGridColumnFilterService.cellRender(this._tileConfig.columns[index], paraObj, CustomGridServiceTriggerType.Group);
                return value || BLANK_VALUE;
            }
        }
        return params.node.key;
    }

    private _initAggFun(params) {
        if (params.node.aggData) {
            const aggDataKeys = Object.keys(params.node.aggData);
            for (const key of aggDataKeys) {
                const columnState = params.columnApi.getColumn(key);
                const column = this._tileConfig.columns.find(item => item.guid === key);
                column.aggFunc = columnState.aggFunc;
                if (columnState.aggFunc && columnState.aggFunc !== 'none') {
                    const style = this._calcuteStyle(params, key);
                    this.aggFuncColumns.push({
                        key: key,
                        name: columnState.colDef.headerName,
                        aggFunc: columnState.aggFunc,
                        left: style.left,
                        width: style.width,
                        value: CustomGridColumnFilterService.cellRender(column, {
                            column: columnState,
                            node: {
                                group: true
                            },
                            value: this._node.aggData[key],
                        }, CustomGridServiceTriggerType.Group)
                    });
                }
            }
        }
    }

    private _resetStyle(params) {
        if (this.aggFuncColumns.length === 0) {
            return;
        }
        // if one column width change, will reset all columns width and left
        const columns = params.columnApi.getColumnState();
        for (const column of columns) {
            const tempColumn = this.aggFuncColumns.find(item => item.key === column.colId);
            if (tempColumn) {
                // use calcuteStyle, not use column left, because if column has pin, left value is error
                const style = this._calcuteStyle(params, column.colId);
                tempColumn.left = style.left;
                tempColumn.width = style.width;
            }
        }
    }

    private _updateGroupAggFuncValuePosition(moveValue) {
        this.aggFuncColumns.forEach(item => {
            item.left = item.left - moveValue;
        });
    }
}
