import { TileColumn, defaultShortName, defaultLongName, TileColumnGroupSetting } from './tile-column.model';

export const DYNAMIC_DATE = 'dynamicDate';

export class TileSettingsFocus {
    type: number;
    relationshipTypes?: Array<any>;
    entityId?: any;
    includeSpecificEntityItself?: boolean;
    constructor() {
        this.type = 0;
        this.relationshipTypes = [];
        this.entityId = null;
        this.includeSpecificEntityItself = false;
    }
}

export class Visualization {
    type: number;
    settings: any;
    constructor() {
        this.type = null;
        this.settings = {};
    }
}

export interface FilterList {
    id: string;
    longName: string;
    isPublic: string;
    company: string;
}

export interface Filters {
    field?: string;
    operator?: string;
    value?: string;
    dataType?: string;
    name?: string;
    list?: FilterList[];
}

export class TileFilter {
    logicalOperator: string;
    filters: Array<any>;
    constructor() {
        this.logicalOperator = 'and';
        this.filters = [];
    }
}

export class GridVisualizationSettings {
    showGridLines: boolean;
    showColumnHeaders: boolean;
    showRowBackground: boolean;
    showTableBorder: boolean;
    showGroupingPanel: boolean;
    showItemCount: boolean;
    automaticSizing: boolean;
    columnWithShowType: string;
    pageBreak: boolean;
    constructor() {
        this.showGridLines = true;
        this.showColumnHeaders = true;
        this.showRowBackground = true;
        this.showTableBorder = true;
        this.showGroupingPanel = true;
        this.showItemCount = true;
        this.automaticSizing = true;
        this.columnWithShowType = 'autoSize';
        this.pageBreak = false;
    }
}

export enum NoteTileDisplayStrategy {
    LatestSidenote = 'sidenote',
    ParentNote = 'parent'
}

export class TileOptions {
    showRowForEveryEntity: boolean;
    showRowAsThread: boolean;
    parentOrLastSidenote?: NoteTileDisplayStrategy;
    showFullThread?: boolean;
    constructor() {
        this.showRowForEveryEntity = false;
        this.showRowAsThread = false;
    }
}

export class TileDisplaySettings {
    public showBackdate?: boolean;
    public showEntityArray?: boolean;
    public showSourceInfo?: boolean;
    constructor(
        public fullThreadAttachments: boolean,
        public individualEntryAttachments: boolean,
        public adhocTable: boolean,
    ) {
    }
}

export class TilePageDesc {
    constructor(public rpp: number, public page: number) {
    }
}

export class TileSorts {
    constructor(public field: string, public order: string) {
    }
}

export class TileModel {
    id?: string;
    tileName?: string;
    tileDescription?: string;
    focus: TileSettingsFocus;
    filters: TileFilter;
    mapFilters?: any;
    visualization: any;
    columns: Array<TileColumn>;
    columnGroups: Array<TileColumnGroupSetting>;
    options: TileOptions;
    displaySettings: TileDisplaySettings;
    lastModifiedDate: string;
    author: string;
    createDate?: string;
    conditionalFormattings?: any;
    pageDesc?: TilePageDesc;
    sorts?: TileSorts;
    constructor() {
        this.visualization = new Visualization();
        this.filters = new TileFilter();
        this.columnGroups = [];
        this.options = new TileOptions();
        this.columns = [defaultShortName, defaultLongName];
        this.focus = new TileSettingsFocus();
    }
}

export const WIZARD_MENU = {
    CHOOSE: 0,
    FILTER: 1,
    CONFIGURE: 2,
    VISUALIZATION: 3,
    TILE_SETTING: 4
};
