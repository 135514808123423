<div class="relationshiptype-editor-container">
    <div class="header-container">
        <label>{{ title }}</label>
        <smart-icon name="close" (click)="onSlideSheetClose()" class="light"></smart-icon>
    </div>
    <div class="input-container tam-form-field" [ngClass]="{'has-error':relationshipMaxLength || reciprocalMaxLength}">
        <label class="required">{{'general.relationship_type_name' | stringLiterals}}</label>
        <kendo-dropdownlist #relationshiplist style="width: 100%;" [data]="suggestionItems" [textField]="'name'"
            (click)="configChanged()" [valueField]="'id'" [filterable]="true" [popupSettings]="{appendTo: 'component'}"
            (filterChange)="handleFilter($event,'relationshipType')" (open)="onRelationshipOpen($event)"
            [(ngModel)]="selectedItem" (focus)="relationshiplist.toggle(true)" (mousedown)="$event.preventDefault()"
            (valueChange)="valueChange($event)" (keydown.esc)="onEnter($event)">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <span>{{dataItem.name}}</span>
            </ng-template>

            <ng-template kendoDropDownListFooterTemplate>
                <div *ngIf="searchValue" class="dropdown-fly-item" (click)="createRelationshipTypeName()"
                    (click)="configChanged()">
                    <span class='overflow-hidden'>{{searchValue}}</span>
                    <span>(New relationship type)</span>
                </div>
            </ng-template>
        </kendo-dropdownlist>
        <div class="tam-form-help" style="margin-top: -5px;" *ngIf="relationshipMaxLength">
            {{'general.name_exceed_maximum' | stringLiterals: '128'}}
        </div>

        <label class="required"> {{'general.parent_entity_type' | stringLiterals}}</label>
        <kendo-dropdownlist #dropDownParentEntityType [data]="parentEntityTypeItems"
            [(ngModel)]="selectedParentEntityType" [textField]="'name'" class="control-content" [valueField]="'id'"
            (click)="configChanged()" (selectionChange)="parentSelectionChange($event)"
            (focus)="dropDownParentEntityType.toggle(true)" (mousedown)="$event.preventDefault()" [filterable]="true"
            (filterChange)="handleFilter($event,'parentEntityType')">
        </kendo-dropdownlist>

        <label class="required"> {{'general.child_entity_type' | stringLiterals}}</label>
        <kendo-dropdownlist #dropDownChildEntityType [data]="childEntityTypeItems" [(ngModel)]="selectedChildEntityType"
            (click)="configChanged()" [textField]="'name'" class="control-content" [valueField]="'id'"
            (selectionChange)="childSelectionChange($event)" (focus)="dropDownChildEntityType.toggle(true)"
            (mousedown)="$event.preventDefault()" [filterable]="true"
            (filterChange)="handleFilter($event,'childEntityType')">
        </kendo-dropdownlist>

        <label class="required"> {{'general.reciprocal_relationship_type' |
            stringLiterals}}</label>
        <kendo-dropdownlist #dropDownReciprocalRelationshipType [data]="suggestionItems" (click)="configChanged()"
            [(ngModel)]="selectedReciprocalRelationshipType" class="control-content" [textField]="'name'"
            [popupSettings]="{appendTo: 'component'}" [valueField]="'id'" (open)="onReciprocalOpen($event)"
            (focus)="dropDownReciprocalRelationshipType.toggle(true)" [filterable]="true"
            (filterChange)="handleFilter($event,'reciprocalRelationshipType')"
            (focus)="dropDownReciprocalRelationshipType.toggle(true)" (mousedown)="$event.preventDefault()"
            (valueChange)="reciprocalValueChange($event)">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <span>{{dataItem.name}}</span>
            </ng-template>

            <ng-template kendoDropDownListFooterTemplate>
                <div *ngIf="searchValue" class="dropdown-fly-item" (click)="createReciprocalRelationshipTypeName()"
                    (click)="configChanged()">
                    <span class='overflow-hidden'>{{searchValue}}</span>
                    <span>(New relationship type)</span>
                </div>
            </ng-template>
        </kendo-dropdownlist>
        <div class="tam-form-help" style="margin-top: -5px;" *ngIf="reciprocalMaxLength">
            {{'general.name_exceed_maximum' | stringLiterals: '128'}}
        </div>
    </div>
    <div class="button-container">
        <button tam-button [tamType]="'primary'" [tamSize]="'normal'" class="button" (click)="onSubmitClick()"
            [tamLoading]="isProgressing" [disabled]="isProgressing">
            {{'general.create' | stringLiterals}}
        </button>
        <button tam-button [tamType]="'tertiary'" [tamSize]="'normal'" class="button" (click)="onSlideSheetClose()">
            {{'general.cancel' | stringLiterals}}
        </button>
    </div>
</div>