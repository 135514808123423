/**
 * Created by Ella Ma on 1/4/2018.
 * Description:
 *	pure filter items for reusable.
 *
 * ------ maintenance history ------
 */

import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../tamalelibs/components/filter-widget.config';
import { FilterWidgetAciontType } from './tam-text-filter.component';

interface Rating {
    readonly id: number;
    rating: number;
}

/**
 * Filter widget for metadataType is Priority
 *
 * @export
 * @class TamPriorityFilterComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'tam-priority-filter',
    templateUrl: 'tam-priority-filter.component.html',
    styleUrls: ['tam-filter-container.component.scss']
})

export class TamPriorityFilterComponent implements OnInit {

    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event

    defaultItem: { name: string, value: string };
    operators: Array<{ name: string, value: string }>;
    isItemHoverOn = false;
    rating: Rating = {
        id: Math.random(),
        rating: 0
    };

    constructor(private _deviceService: DeviceDetectorService) { }

    ngOnInit() {
        this.operators = this.config.operators;
        this.defaultItem = this.config.operators.find(item => item.value === this.config.selectedOperator);
        this.rating.rating = this.config.value;

        // For use within normal web clients
        if (this._deviceService.isMobile() || this._deviceService.isTablet()) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }

        // added for initial value to save into store.
        this._feedback(FilterWidgetAciontType.TEXT_CHANGE, this.rating.rating);
    }

    ratingComponentClick(clickObj: any): void {
        const rating = clickObj.rating;
        if (this.rating.rating !== rating) {
            this.rating.rating = rating;
            this._feedback(FilterWidgetAciontType.TEXT_CHANGE, rating);
        }
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    valueChange(changeItem: { name: string, value: string }): void {
        // emits event for value change
        this._feedback(FilterWidgetAciontType.SELECTOR_CHANGE, changeItem.value);
    }

    private _feedback(type: FilterWidgetAciontType, value: any) {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        if (FilterWidgetAciontType.SELECTOR_CHANGE === type) {
            this.config.selectedOperator = value;
        } else if (FilterWidgetAciontType.TEXT_CHANGE === type) {
            this.config.value = value;
        }

        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }
}
