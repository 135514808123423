/**
 * Created by Daniel on 12/11/2020.
 * Description: single select dropdown filter for fixed data source
 *
 * ------ maintenance history ------
 * 10/15/2021 Simon Zhao Added a freezing items array, all of which would be fixed at the top of the dropdown list.
 */

import { Component, ContentChild, ElementRef, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';
import { SingleSelectDropdownConfig, SingleSelectDropdownFilterConfig } from './single-select-dropdown-filter.model';

@Component({
    selector: 'tam-single-select-dropdown-filter',
    templateUrl: './single-select-dropdown-filter.component.html',
    styleUrls: ['./single-select-dropdown-filter.component.scss']
})
export class SingleSelectDropdownFilterComponent implements OnInit, OnDestroy {
    @ContentChild('customContent', { static: false }) customContent: TemplateRef<any>;
    @Input() dropdownConfig: SingleSelectDropdownFilterConfig;
    @Input()
    freezingItemsConfig: SingleSelectDropdownConfig[];

    showSel = false;
    searchValue = '';
    hideInput = true;
    anchor: ElementRef;
    inputValue = '';
    selectedItem = '';
    items = [];

    private _subscription: Subscription[] = [];

    constructor() {
    }

    ngOnInit() {
        this._subscription.push(
            this.dropdownConfig.open$.subscribe((anchor) => {
                this.showPopup(anchor);
                if (anchor.type === 'refresh') {
                    this.setDataSource();
                }
            })
        );
        this.hideInput = this.dropdownConfig.hideInput;
        this.setDataSource();
    }

    ngOnDestroy() {
        this._subscription.forEach(item => item.unsubscribe());
    }

    changeSelectedState(item: SingleSelectDropdownConfig, event) {
        this.showSel = false;
        item.selected = true;
        this.dropdownConfig.onChangeValue$.next(item);
        event.stopPropagation();
    }

    clearSearch(): void {
        this.searchValue = '';
    }

    getItems() {
        const dataSource = this.dropdownConfig.data;
        if (this.dropdownConfig.sortCfg) {
            ArrayHelperService.sort<SingleSelectDropdownConfig>(dataSource, this.dropdownConfig.sortCfg.propName, this.dropdownConfig.sortCfg.isAscOrder);
        }

        return dataSource.filter(item => item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
    }

    getSelectItem() {
        return this.dropdownConfig.data.filter(item => item.selected);
    }

    getSelectItemValue() {
        let str = '';
        const len = this.getSelectItem().length;
        if (len !== 0) {
            str = this.getSelectItem.name;

        }
        return str;
    }

    onClickOutside(event: Object) {
        if (event && event['value'] === true) {
            this.showSel = false;
            this.clearSearch();
        }
    }

    searchData() {
        this.items = this.getItems();
    }

    setDataSource() {
        const str = this.getSelectItemValue();
        this.inputValue = str;
        this.items = this.getItems();
    }

    showPopup(event: any) {
        this.anchor = event.target || event;
        this.showSel = true;
    }

    trackByFn(index, item) {
        return item.id;
    }
}
