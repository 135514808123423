<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{this.config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon [ngClass]="" name="trash" (click)="_onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container body-3" tid="operator-content-container">
        <div class="operator" *ngIf="!this.config.hideOperators">
            <kendo-dropdownlist [(ngModel)]="defaultItem" [data]="operators" [textField]="'name'" [valueField]="'value'"
                style="width: 100%;" (valueChange)="_valueChange($event)">
            </kendo-dropdownlist>
        </div>
        <div class="content" [class.disabled]="isCheckBlank">
            <ng-container *ngIf="!isCheckBlank">
                <input type="text" [(ngModel)]="textValue" (ngModelChange)="onTextInputChanged($event)"
                    class="textInput">
            </ng-container>
        </div>
    </div>
</div>