/**
 * Created by Alan Yang on 8/11/17.
 * Description:
 *
 * ------ maintenance history ------
 * 06/02/2020 Marcus Zhao - add getContactTemplate and templateValueSet cleanTemplateValueSet
 * 08/25/2020 Marcus Zhao - change cleanTemplateMsg and cleanTemplateValueSet for smart actions
 * 09/21/2020 Marcus Zhao - add  increment decrement reset for contact email
 */
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GET_CONTACT, CONTACT_STATUS_CREATE, CONTACT_STATUS_EDIT, CONTACT_TEMPLATE, GET_SLIDESHEET_CONTACT } from '../redux/app.actions';
import { CLEAR_CONTACT_MSG, CreateContact, EditContact, CleanTemplateMsg, CleanContactTemplateValueSet, IncremnetIndex, ResetIndex, DecremnetIndex } from '../redux/contact.actions';
import { Contact } from '../models/contact.model';
import { smartSelector } from '../../redux';
import { getContactTemplateLayoutSetState, getContactTemplateValueSetState, getContactEmailCountState } from '../redux/contact.reducer';

@Injectable()
export class ContactViewModel {
    constructor(private _store: Store<any>) { }

    cleanTemplateValueSet(prefixId: string = '') {
        this._store.dispatch(
            new CleanContactTemplateValueSet(
                '',
                'prefix',
                prefixId
            )
        );
    }

    getContact(id: string = '', parseTreeNode: boolean = false) {
        if (id.length > 0) {
            this._store.dispatch({
                type: GET_CONTACT,
                payload: {
                    id: id,
                    parseTreeNode: parseTreeNode
                }
            });
        }
    }

    getContactTemplate(id) {
        if (id.length > 0) {
            this._store.dispatch({
                type: CONTACT_TEMPLATE,
                payload: {
                    id: id
                }
            });
        }
    }

    getSlideSheetContact(id: string = '') {
        if (id.length > 0) {
            this._store.dispatch({
                type: GET_SLIDESHEET_CONTACT,
                payload: {
                    id: id
                }
            });
        }
    }

    clearContactMsg() {
        this._store.dispatch({
            type: CLEAR_CONTACT_MSG
        });
    }

    cleanTemplateMsg(prefixId: string = '') {
        this._store.dispatch(
            new CleanTemplateMsg(
                '',
                'prefix',
                prefixId
            )
        );
    }

    createContact(contact: Contact, prefixId: string = '') {
        if (contact) {
            this._store.dispatch(
                new CreateContact(
                    {
                        data: contact
                    },
                    'prefix',
                    prefixId
                )
            );
        }
    }

    editContact(contact: Contact, prefixId: string = '') {
        if (contact) {
            this._store.dispatch(
                new EditContact(
                    {
                        data: contact
                    },
                    'prefix',
                    prefixId
                )
            );
        }
    }

    setContactStatusCreate() {
        this._store.dispatch({
            type: CONTACT_STATUS_CREATE,
        });
    }

    setContactStatusEdit() {
        this._store.dispatch({
            type: CONTACT_STATUS_EDIT,
        });
    }

    templateValueSet(prefixId: string = '') {
        return this._store.select(this._relativeSelector(prefixId, getContactTemplateValueSetState));
    }

    templateLayoutSet(prefixId: string = '') {
        return this._store.select(this._relativeSelector(prefixId, getContactTemplateLayoutSetState));
    }

    private _relativeSelector(selectid, selector) {
        return (state) => {
            return selector(smartSelector(selectid, 'contactTemplateReducer')(state));
        };
    }

    increment(emailId, prefixId: string = '') {
        this._store.dispatch(
            new IncremnetIndex(
                {
                    emailId: emailId
                },
                'prefix',
                prefixId
            )
        );
    }

    decrement(emailId, prefixId: string = '') {
        this._store.dispatch(
            new DecremnetIndex(
                {
                    emailId: emailId
                },
                'prefix',
                prefixId
            )
        );
    }

    reset(prefixId: string = '') {
        this._store.dispatch(
            new ResetIndex(
                '',
                'prefix',
                prefixId
            )
        );
    }

    getContactEamilCount(prefixId: string = '') {
        return this._store.select(this._relativeSelector(prefixId, getContactEmailCountState));
    }
}
