import { Component, OnInit, Input } from '@angular/core';
import { WizardMenuConfig, MenuItem, WizardMenuEventType, Status, ValidStatus } from './wizard-menu.config';

@Component({
    selector: 'tam-wizard-menu',
    templateUrl: './wizard-menu.component.html',
    styleUrls: ['./wizard-menu.component.scss']
})
export class WizardMenuComponent implements OnInit {

    @Input() config: WizardMenuConfig;

    status = Status;
    validStatus = ValidStatus;

    constructor() { }

    ngOnInit() {
    }
    changeSelect(item, index) {
        if (item.status === Status.disabled || item.status === Status.notSet) {
            return;
        }
        this.config.events$.next({ type: WizardMenuEventType.changeSelect, payload: index });
    }
}
