/**
 * Created by Abner Sui on 10/16/2019
 * -------------------------------------
 */

import { Component, OnInit, Input, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FieldActions, FieldConfig, FieldEvents } from './field.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Subscription, fromEvent } from 'rxjs';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'tam-date-field',
    templateUrl: './date-field.component.html',
    styleUrls: ['./field.component.scss', './date-field.component.scss']
})
export class DateFieldComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() config: FieldConfig;
    @Input() viewMode: 'viewOnly' | 'full' = 'viewOnly';
    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false })
    tooltipDir: TooltipDirective;
    isIPAD = false;
    requireInvalid = false;

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _utils: UtilsService,
        private _deviceService: DeviceDetectorService,
    ) { }

    ngOnInit() {
        if (this.config.disabled) {
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this._destroySubscriptions.push(
            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
    }

    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && (this.config.field.value === null || this.config.field.value === undefined)) {
            return true;
        } else {
            return false;
        }
    }

    valueChange(event) {
        this.requireInvalid = false;
        if (!this._isIncludeHMS(event)) {
            const currentDate = new Date();
            event = new Date(event.getFullYear(), event.getMonth(), event.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
            this.config.field.value = event;
        }
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: event,
            },
        });

        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
    }

    private _isIncludeHMS(date: Date): boolean {
        let isIncludeHMS = false;
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        if (hours === 0 && minutes === 0 && seconds === 0) {
            isIncludeHMS = false;
        } else {
            isIncludeHMS = true;
        }
        return isIncludeHMS;
    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        }
    }
}
