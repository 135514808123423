/**
 * Created by Yu Zhang on 8/11/17.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';

@Injectable()
export class ConfigurationDataService {
    constructor(private _transportService: TransportService) { }

    public getConfigurationById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.configurationDataEndpoint}${id}`;
        return this._transportService.get(url, { headers: headers });
    }

    public getConfigurationByName(name: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.configurationDataEndpoint}`;
        const options = {
            headers: headers,
            params: {
                name: name
            }
        };
        return this._transportService.get(url, options);
    }

    public getDefaultEntryForm(htmlTemplateID: string): Observable<any> {
        const options = {
            // must be text for HTML response.
            responseType: 'text'
        };
        let url = `${AppConfig.configurationDataEndpoint}`;
        url = url + 'entryform/' + htmlTemplateID;
        return this._transportService.get(url, options);
    }
}
