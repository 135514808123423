import { Action } from '@ngrx/store';
import { SystemUser } from '../../tamalelibs/models/user.model';

export enum AuthActionTypes {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    UPDATE_GLOBAL_USER = '[Auth] UPDATE_GLOBAL_USER',
    SESSION_TIMEOUT = 'SESSION_TIMEOUT'
}

export class LoginSuccess implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS;

    constructor(public payload: SystemUser) { }
}

export class LoginFailed implements Action {
    readonly type = AuthActionTypes.LOGIN_FAILED;

    constructor(public payload: string) { }
}

export class Logout implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export class SessionTimeout implements Action {
    readonly type = AuthActionTypes.SESSION_TIMEOUT;

    constructor(public payload: string, public fromUrl: string) { }
}

// export type AuthActions = LoginSuccess | LoginFailed | Logout | SessionTimeout;
export type AuthActions = LoginSuccess | LoginFailed | Logout | SessionTimeout;
