/**
 * Created by Abner Sui on 05/22/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

// blast email list related actions
export enum FileViewListActionTypes {
    SET_SELECTED_INDEX = '[FILEVIEW][FILEVIEW_LIST] SET_SELECTED_INDEX',
    SET_CURRENT_PAGE_INDEX = '[FILEVIEW][FILEVIEW_LIST] SET_CURRENT_PAGE_INDEX', // uselss, could use RELOAD and LOAD_MORE
    RELOAD = '[FILEVIEW][FILEVIEW_LIST] RELOAD',
    RELOAD_SUCCESS = '[FILEVIEW][FILEVIEW_LIST] RELOAD_SUCCESS',
    RELOAD_FAILED = '[FILEVIEW][FILEVIEW_LIST] RELOAD_FAILED',
    LOAD_MORE = '[FILEVIEW][FILEVIEW_LIST] LOAD_MORE',
    LOAD_MORE_SUCCESS = '[FILEVIEW][FILEVIEW_LIST] LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILED = '[FILEVIEW][FILEVIEW_LIST] LOAD_MORE_FAILED',
    DELETE_FILE = '[FILEVIEW][FILEVIEW_LIST] DELETE_FILEVIEW',
    DELETE_FILE_SUCESS = '[FILEVIEW][FILEVIEW_LIST] DELETE_FILE_SUCESS',
    DELETE_FILE_FAILED = '[FILEVIEW][FILEVIEW_LIST] DELETE_FILE_FAILED',
    GET_THREAD = '[FILEVIEW][FILEVIEW_LIST] GET_THREAD',
    GET_THREAD_SUCCESS = '[FILEVIEW][FILEVIEW_LIST] GET_THREAD_SUCCESS',
    GET_THREAD_FAILED = '[FILEVIEW][FILEVIEW_LIST] GET_THREAD_FAILED',
}
export class FileViewAction {
    constructor(public payload, prefixName: string, prefixValue: string) {
        if (prefixName && prefixValue) {
            this[prefixName] = prefixValue;
        }
    }
}
export class FileViewListSetSelectedIndex extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.SET_SELECTED_INDEX;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListSetCurrentPageIndex extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.SET_CURRENT_PAGE_INDEX;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListReload extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.RELOAD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListReloadSuccess extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.RELOAD_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListReloadFailed extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.RELOAD_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListLoadMore extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.LOAD_MORE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListLoadMoreSuccess extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.LOAD_MORE_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListLoadMoreFailed extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.LOAD_MORE_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListDelete extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.DELETE_FILE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListDeleteSucess extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.DELETE_FILE_SUCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListDeleteFailed extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.DELETE_FILE_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListGetThread extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.GET_THREAD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListGetThreadSucess extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.GET_THREAD_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewListGetThreadFailed extends FileViewAction implements Action {
    readonly type = FileViewListActionTypes.GET_THREAD_FAILED;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export type FileViewListActions = FileViewListSetSelectedIndex | FileViewListSetCurrentPageIndex | FileViewListReload
    | FileViewListReloadSuccess | FileViewListReloadFailed | FileViewListLoadMore | FileViewListLoadMoreSuccess
    | FileViewListLoadMoreFailed | FileViewListDelete | FileViewListDeleteSucess | FileViewListDeleteFailed
    | FileViewListGetThread | FileViewListGetThreadSucess | FileViewListGetThreadFailed;

// blast email view related actions
export enum FileViewActionTypes {
    SET_QUERY_INFO = '[FILEVIEW][QUERY_INFO] SET_VALUE',
    SET_KEY_WORD = '[FILEVIEW][KEYWORD] SET_VALUE',
    SET_SORT_INFO = '[FILEVIEW][SORT_INFO]_SET_VALUE',
    SET_AGGRID_COLUMN_STATUS = '[FILEVIEW][AGGRID] SET_COLUMN_STATUS',
}

export class FileViewSetQueryInfo extends FileViewAction implements Action {
    readonly type = FileViewActionTypes.SET_QUERY_INFO;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewSetKeyWord extends FileViewAction implements Action {
    readonly type = FileViewActionTypes.SET_KEY_WORD;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewSetSortInfo extends FileViewAction implements Action {
    readonly type = FileViewActionTypes.SET_SORT_INFO;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class FileViewSetAGGridColumnStatus extends FileViewAction implements Action {
    readonly type = FileViewActionTypes.SET_AGGRID_COLUMN_STATUS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export type FileViewActions = FileViewSetQueryInfo | FileViewSetKeyWord | FileViewSetSortInfo | FileViewSetAGGridColumnStatus;
