/**
 * Created by Abner Sui on 02/20/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RefinebySubConfig, RefinebyObject, RefinebySubWidgetAction } from './refineby-view.model';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BriefConfig } from '../widgets.model';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'refineby-priority',
    templateUrl: './refineby-priority.component.html',
    styleUrls: ['./refineby-widget.component.scss']
})
export class RefinebyPriorityComponent implements OnInit, OnDestroy {

    @Input()
    dataConfig: RefinebySubConfig;
    @Input()
    config: BriefConfig;

    isHoverOn = false;
    options: Array<RefinebyObject> = [];
    selectedOptions: Array<RefinebyObject> = [];
    categoryFilter: Object;
    searchResult: Array<RefinebyObject>;
    isIPAD: boolean;

    private _categoryFilter: Array<string>;

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(private _deviceService: DeviceDetectorService,
    ) { }

    ngOnInit() {
        this._destroySubscriptions.push(this.config.actionSubject$.subscribe(action => this._onSubscribeActions(action)));

        this.options = this.dataConfig.options;
        this.options.sort((item1, item2) => {
            return item1.id.localeCompare(item2.id);
        });
        this.selectedOptions = this.dataConfig.selectedOptions;
        this.selectedOptions.forEach(option => {
            option.checked = true;
            const index = this.options.findIndex(item => item.id === option.id);
            if (index > -1) {
                this.options[index].checked = true;
                option.count = this.options[index].count;
            } else {
                option.count = 0;
            }
        });

        // For use within normal web clients
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (this.isIPAD) {
            this.isHoverOn = true;
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    changeSelectedValue(item: RefinebyObject, event) {
        item.checked = !item.checked;
        let index = this.options.findIndex(data => data.id === item.id);
        this.options[index].checked = item.checked;
        if (item.checked) {
            this.selectedOptions.push(item);
        } else {
            index = this.selectedOptions.findIndex(data => data.id === item.id);
            this.selectedOptions.splice(index, 1);
        }
        event.stopPropagation();
        this._changeValue();
    }

    collapse() {
        this.dataConfig.status.collapsed = !this.dataConfig.status.collapsed;
        this.config.feedbackSubject$.next({
            type: RefinebySubWidgetAction.STATUS_CHANGE,
            payload: {
                categoryId: this.dataConfig.categoryId,
                status: this.dataConfig.status
            }
        });
    }

    onMeatball(event) {
        if (this.isIPAD) {
            event.clientX = event.pageX;
            event.clientY = event.pageY;
        }
        this.config.feedbackSubject$.next({
            type: RefinebySubWidgetAction.CLICK_MEATBALL,
            payload: {
                event: event,
                categoryId: this.dataConfig.categoryId
            }
        });
    }

    onMouseOver() {
        this.isHoverOn = true;
    }

    onMouseOut() {
        this.isHoverOn = false;
    }

    private _changeValue() {
        this._categoryFilter = [];
        this.selectedOptions.forEach(option => {
            this._categoryFilter.push(option.id);
        });
        this.config.feedbackSubject$.next({
            type: RefinebySubWidgetAction.SELECTED_OPTION_CHANGE,
            payload: {
                categoryId: this.dataConfig.categoryId,
                selectedOptions: this.selectedOptions,
                categoryFilter: this._categoryFilter
            }
        });
    }

    private _onSubscribeActions(action) {
        if (action.type === RefinebySubWidgetAction.SEARCH) {
            this.searchResult = [];
            this.options.forEach(option => {
                if (option.checked) {
                    this.searchResult.push(option);
                }
            });
            this.config.feedbackSubject$.next({
                type: RefinebySubWidgetAction.SEARCH_RESULT,
                payload: {
                    categoryId: this.dataConfig.categoryId,
                    searchResult: this.searchResult,
                }
            });
        }
    }
}
