import { Subject } from 'rxjs';

const snippetView = {
    refinedByWidth: 20,
    refinedByMax: 30,
    refinedByMin: 10,

    middleWidth: 30,
    middleMax: 40,
    middleMin: 20,
    collapsedMiddleWidth: 30,

    previewWidth: 50,
    previewMax: 70,
    previewMin: 30,
    collapsedpreviewWidth: 70,
    collapsedpreviewMax: 80,
    collapsedpreviewMin: 60
};

const sidelistView = {
    refinedByWidth: 20,
    refinedByMax: 30,
    refinedByMin: 10,


    middleWidth: 50,
    middleMax: 60,
    middleMin: 40,
    collapsedMiddleWidth: 60,

    previewWidth: 30,
    previewMax: 50,
    previewMin: 30,
    collapsedpreviewWidth: 40,
    collapsedpreviewMax: 60,
    collapsedpreviewMin: 30
};

const toplistView = {
    refinedByWidth: 20,
    refinedByMax: 30,
    refinedByMin: 10,

    previewWidth: 80,
    collapsedpreviewWidth: 100,

    toplistHeight: 40,
    toplistMax: 70,
    toplistmin: 30,

    previewHeight: 60,
    previewMax: 70,
    previewmin: 30,

};

interface LayoutViewType {
    refinedByWidth: number;
    refinedByMax: number;
    refinedByMin: number;

    middleWidth: number;
    middleMax: number;
    middleMin: number;

    previewWidth: number;
    previewMax: number;
    previewMin: number;
}

class ResearchLayoutConfig {
    layoutMode: number;
    isCollapsed: boolean;
    disableCollapse: boolean;
    actions$: Subject<any>;
    events$: Subject<any>;

    constructor(mode: number = 1, isCollapsed: boolean = false, disableCollapse = false) {
        this.layoutMode = mode;
        this.isCollapsed = isCollapsed;
        this.disableCollapse = disableCollapse;
        this.actions$ = new Subject();
        this.events$ = new Subject();
    }
}

enum ResearchLayoutMode {
    SNIPPET = 1,
    TOP_LIST = 2,
    SIDE_LIST = 3
}

enum ResearchLayoutActions {
    SET_COLLAPSE = 'SET_COLLAPSE',
    SET_LAYOUT = 'SET_LAYOUT'
}

enum ResearchLayoutEvents {
    COLLAPSE_CHANGE = 'COLLAPSE_CHANGE',
    LAYOUTMODE_CHANGE = 'LAYOUTMODE_CHANGE'
}

export {
    snippetView,
    sidelistView,
    toplistView,
    LayoutViewType,
    ResearchLayoutConfig,
    ResearchLayoutMode,
    ResearchLayoutActions,
    ResearchLayoutEvents
};
