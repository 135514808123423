<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" [attr.tid]="config.tId">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container" tid="operator-content-container">
        <div class="operator" *ngIf="!config.hideOperators">
            <kendo-dropdownlist [(ngModel)]="operator" [data]="operators" [textField]="'name'" [valueField]="'value'"
                style="width: 100%;" (valueChange)="valueChange()">
            </kendo-dropdownlist>
        </div>
        <div class="content" [class.disabled]="isCheckBlank">
            <ng-container *ngIf="!isCheckBlank">
                <div class="date-last-next" *ngIf="operator.value === 'last' || operator.value === 'next'">
                    <kendo-numerictextbox [(ngModel)]="interval" [format]="'n0'" [min]="1" [max]="999"
                        [autoCorrect]="true" (valueChange)="valueChange()" style="width: 100%;">
                    </kendo-numerictextbox>
                    <kendo-dropdownlist [(ngModel)]="unit" [data]="units" [textField]="'name'" [valueField]="'value'"
                        (valueChange)="valueChange()" style="width: 100%;">
                    </kendo-dropdownlist>
                </div>
                <div class="date-range" *ngIf="operator.value === 'range'">
                    <kendo-daterange style="width: 100%;display: flex;">
                        <label style="width: 100%;">
                            <kendo-dateinput kendoDateRangeStartInput [(ngModel)]="range.start" [min]="minDate"
                                [max]="maxDate" style="width: 100%;" placeholder="mm/dd/yyyy"
                                (valueChange)="valueChange()" (blur)="inputBlur()">
                            </kendo-dateinput>
                        </label>
                        <span>—</span>
                        <label style="width: 100%;">
                            <kendo-dateinput kendoDateRangeEndInput [(ngModel)]="range.end" [min]="minDate"
                                [max]="maxDate" style="width: 100%;" placeholder="mm/dd/yyyy"
                                (valueChange)="valueChange()" (blur)="inputBlur()">
                            </kendo-dateinput>
                        </label>
                    </kendo-daterange>
                </div>
                <div class="date-value" *ngIf="operator.value === 'before' || operator.value === 'after'">
                    <kendo-datepicker calendarType="classic" [(ngModel)]="valueBeforeAfter" [rangeValidation]="false"
                        (valueChange)="valueChange()" [min]="minDate" [max]="maxDate" style="width: 100%;"
                        (blur)="inputBlur()"></kendo-datepicker>
                </div>
                <div class="dynamic-date" *ngIf="operator.value === 'dynamicDate'">
                    <kendo-dropdownlist [filterable]="false" [data]="dynamicDateList" [textField]="'name'"
                        [valueField]="'value'" [(ngModel)]="dynamicDate" (valueChange)="valueChange()"
                        style="width: 100%;">
                    </kendo-dropdownlist>
                </div>
            </ng-container>
        </div>
    </div>
</div>