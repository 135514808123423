/**
 * Created by Alan Yang on 12/07/2018.
 * Description: Data source for filter
 *
 * ------ maintenance history ------
 */

import { EntityBrief } from '../models/entity-brief.model';
import { ViewContainerRef } from '@angular/core';

export class FiltersConfig {
    id: string;
    fields: Array<string>;
    title: string;

    // Entities, ED, MED, etc... Fitler container uses it to do ngswith validating.
    metadataType: string;

    // server stores selectedOperator.
    selectedOperator: string;
    operators: Array<{ name: string, value: string }>;

    // server stores value of source
    value: any;
    // for texbox to support multiple items to filter
    realValue?: any;
    source: any;

    // tile designer uses it
    disable: boolean;
    dataType: string;

    relationshipTypeSource: any;

    // the relationship ids
    relationshipType: any;

    // filter item disabled delete btn
    deletable: boolean;

    // hide operators
    hideOperators: boolean;

    // show current user
    currentUser: EntityBrief;

    // for QA
    tId?: string;
    // for kendo popup
    viewContainer: ViewContainerRef | string = 'component';
    isHiddenRelationship = false;
    isIncludeCurrentEmployment = true;
    // tslint:disable-next-line: whitespace
    typeValue? = 'entryTypes';
    isShowTypeFilter = false;
    isHiddenCloseIcon = false; // all the other isHiddenTrashIcon should be replaced by this one.
}

export enum FilterMetadataType {
    CheckBox = 'CheckBox',
    Date = 'Date',
    DisplayDate = 'DisplayDate',
    Dropdown = 'Dropdown',
    ED = 'ED',
    Entities = 'Entities',
    EntryEntity = 'EntryEntity', // Entity filter in note type column groups
    EntityType = 'EntityType',
    EntryType = 'EntryType',
    FileName = 'FileName',
    FileType = 'FileType',
    LastModifiedDate = 'LastModifiedDate',
    Location = 'Location',
    MED = 'MED',
    MET = 'MET',
    MSD = 'MSD',
    SpecificUsers = 'Specific users',
    SpecificContacts = 'Specific contacts',
    SpecificTeams = 'Specific teams',
    CustomField = 'Custom field',
    NoteType = 'NoteType',
    Priority = 'Priority',
    Sentiment = 'Sentiment',
    Subject = 'Subject',
    SubmittedDate = 'SubmittedDate',
    Submitter = 'Submitter',
    Source = 'Source',
    Team = 'Team',
    TextBox = 'TextBox',
    Text = 'Text',
    User = 'User',
    Where = 'Where',
    Action = 'Action',
    DateAndTime = 'DateAndTime',
    Entity = 'Entity',
    FlyEntity = 'FlyEntity',
    FlyContact = 'FlyContact',
    Company = 'Company',
    JobTitle = 'JobTitle',
    JobFunction = 'JobFunction',
    Alias = 'Alias',
    City = 'City',
    CountryState = 'Country&State',
    FormerCompany = 'FormerCompany',
    Status = 'Status',
    Relationship = 'Relationship',
    // to support event
    EventType = 'EventType',
    Participants = 'Participants',
    StartTime = 'StartTime',
    EndTime = 'EndTime'
}
