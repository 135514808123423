/**
 * Created by Abner Sui on 03/14/2019.
 * Description:
 *
 * ------ maintenance history ------
 * update by Marcus 7/25/2021  getEntityAdhocsByEntityType() for search adhoc control
 * updated by Simon Zhao 4/27/2022 add an API for obtaining ad-hoc attributes associated with the given entry type.
 *
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { Adhoc } from '../models/adhoc.model';
import { AdhocWorkflowField } from '../models/adhoc-field.model';
import { ArrayHelperService } from './array-helper.service';

@Injectable()
export class AdhocService {
    constructor(
        private _transportService: TransportService,
    ) { }

    getAll(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.adhocEndpoint}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * get ad-hoc attributes associated with the given type (event type/entry type)
     * @param ids  the given event/entry type ids
     * @returns  an array of ad-hoc fields
     */
    getAdhocsByTypeIds(typeName = 'entryTypes', ids): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.adhocEndpoint}switch`;
        const formData = new FormData();
        if (!Array.isArray(ids)) {
            ids = [];
        }
        formData.append(typeName, JSON.stringify(ids));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData, options);
    }

    /**
     * get available adhoc by entityTypes filter
     *
     * @returns {Observable<any>}
     * @memberof AdhocService
     */
    getEntityAdhocsByEntityType(ids): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.adhocEndpoint}switch`;
        const formData = new FormData();
        formData.append('entityTypes', JSON.stringify(ids));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData, options);
    }

    /**
     * gets ad-hoc attributes associated with the given entry type.
     * @param id the given entry type id
     * @returns an array of ad-hoc fields of workflow
     */
    getEntryAdhocsByEntryType(id: string): Observable<Array<AdhocWorkflowField>> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.adhocAttributeEndpoint}${id}`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }

    /**
     * get ad-hoc attributes associated with the given event type
     * @param ids the given event type ids
     * @returns  an array of ad-hoc fields
     */
    getEventAdhocsByEventTypeId(ids): Observable<any> {
        const headers = {
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.adhocEndpoint}switch`;
        const formData = new FormData();
        formData.append('eventTypes', JSON.stringify(ids));
        const options = {
            headers: headers,
        };
        return this._transportService.post(url, formData, options);
    }

    parseResponse(response): any {
        const title: Map<string, Adhoc> = new Map();
        const db: Map<string, Adhoc> = new Map();
        response['adhoc-list'].forEach(oneResponse => {
            const adhoc = Adhoc.parse(oneResponse);
            if (adhoc.type === 'title') {
                title.set(adhoc.id, adhoc);
            } else {
                db.set(adhoc.id, adhoc);
            }
        });
        return {
            title: title,
            db: db,
        };
    }
}
