/**
 * Created by Daniel Wang on 12/20/2021.
 * Description: RichEditorConfig Component
 * ------ maintenance history ------
 */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FieldType } from '../../../tamalelibs/models/workflow.model';
import { IControlConfigComponent, } from './../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';

@Component({
    selector: 'tam-rich-editor-config',
    templateUrl: './rich-editor-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class RichEditorConfigComponent implements OnInit, OnDestroy, IControlConfigComponent {
    //#region public properties
    @Input() config: FieldConfig;
    @ViewChild('base', { static: false }) base;

    dataType = FieldType.RICH_EDITOR;
    //#endregion

    //#region private properties
    // the subscription array that would be unsubscribed on destroying.
    private _destroySubscriptions: Array<Subscription> = [];
    //#endregion

    constructor() { }

    //#region public methods
    ngOnInit() {
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    getInvalidDetail(): FieldValidationError {
        return this.base.getInvalidDetail();
    }


    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run base validate
        return baseValid;
    }
    //#endregion
}
