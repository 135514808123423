/**
 * Created by Marcus Zhao on 6/14/19.
 * Description: add openDialog and feeddbackSubject
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class OpenDynamicComponentService {
    openDialog$: Subject<any> = new Subject<any>();
    feedbackSubject$: Subject<any> = new Subject<any>();
    openContactDialog$: Subject<any> = new Subject<any>();
}
