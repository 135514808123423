/**
 * Created by Abner Sui on 07/03/2019.
 * Description:
 *
 * ------ maintenance history ------
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrintPreviewComponent } from './print-preview.component';
import { PrintGuard } from './print.guard';

const routes: Routes = [
    {
        path: '',
        component: PrintPreviewComponent,
        canActivate: [PrintGuard],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PrintRoutingModule { }
