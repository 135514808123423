<!-- if add track by, entity name will not refresh -->
<div *ngFor="let filterItem of filtersConfigList;">
    <div [ngSwitch]="filterItem.metadataType | lowercase">
        <div *ngSwitchCase="'FlyEntity' | lowercase">
            <tam-add-entity-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-add-entity-filter>
        </div>
        <div *ngSwitchCase="'FlyContact' | lowercase">
            <tam-add-entity-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-add-entity-filter>
        </div>
        <div *ngSwitchCase="'Subject' | lowercase">
            <tam-text-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-text-filter>
        </div>
        <div *ngSwitchCase="'Location' | lowercase">
            <tam-text-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-text-filter>
        </div>
        <div *ngSwitchCase="'TextBox' | lowercase">
            <tam-text-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-text-filter>
        </div>
        <div *ngSwitchCase="'Sentiment' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'Priority' | lowercase">
            <tam-priority-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-priority-filter>
        </div>
        <div *ngSwitchCase="'NoteType' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'EntryType' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'EntityType' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'EventType' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"
                [isHiddenTrashIcon]=true>
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'DisplayDate' | lowercase">
            <tam-date-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-date-filter>
        </div>
        <div *ngSwitchCase="'SubmittedDate' | lowercase">
            <tam-date-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-date-filter>
        </div>
        <div *ngSwitchCase="'LastModifiedDate' | lowercase">
            <tam-date-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-date-filter>
        </div>
        <div *ngSwitchCase="'StartTime' | lowercase">
            <tam-date-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-date-filter>
        </div>
        <div *ngSwitchCase="'EndTime' | lowercase">
            <tam-date-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-date-filter>
        </div>
        <div *ngSwitchCase="'Date' | lowercase">
            <tam-date-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-date-filter>
        </div>
        <div *ngSwitchCase="'Entities' | lowercase">
            <tam-entities-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-entities-filter>
        </div>
        <div *ngSwitchCase="'Where' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'DateAndTime' | lowercase">
            <tam-date-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-date-filter>
        </div>
        <div *ngSwitchCase="'Action' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'Dropdown' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'CheckBox' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'MSD' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'FileName' | lowercase">
            <tam-text-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-text-filter>
        </div>
        <div *ngSwitchCase="'FileType' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'JobTitle' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'JobFunction' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>

        <div *ngSwitchCase="'Status' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-dropdown-filter>
        </div>

        <div *ngSwitchCase="'Alias' | lowercase">
            <tam-dropdown-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-dropdown-filter>
        </div>
        <div *ngSwitchCase="'City' | lowercase">
            <tam-text-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-text-filter>
        </div>
        <div *ngSwitchCase="'Country&State' | lowercase">
            <tam-country-state-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-country-state-filter>
        </div>
        <div *ngSwitchCase="'Relationship' | lowercase">
            <tam-relationship-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig">
            </tam-relationship-filter>
        </div>
        <div *ngSwitchCase="'Specific contacts' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"
                [isHiddenTrashIcon]=false>
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'Specific users' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"
                [isHiddenTrashIcon]=false>
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'Specific teams' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"
                [isHiddenTrashIcon]=false>
            </tam-multi-popup-filter>
        </div>
        <div *ngSwitchCase="'Custom field' | lowercase">
            <tam-multi-popup-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"
                [isHiddenTrashIcon]=false>
            </tam-multi-popup-filter>
        </div>

        <!-- Included types: Source\Submitter\User\Entity\EntryEntity\ED\MED\MET\Team\Company\JobTitle\FormerCompany-->
        <div *ngSwitchDefault>
            <tam-entity-filter [config]="filterItem" [filterWidgetConfig]="filterWidgetConfig"></tam-entity-filter>
        </div>
    </div>
</div>