/**
 * Created by Abner Sui on 10/16/2019
 * -------------------------------------
 * Update by Marcus Zhao 12/26/2021
 * 1/22/2022 Marcus add function for support on the fly
 * 10/12/2022 Simon Zhao exposed the logic of determining the value's validity via a static function.
 */

import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { EntityBrief } from '../../../tamalelibs/models/entity-brief.model';
import { Subject, Subscription, of, fromEvent } from 'rxjs';
import { debounceTime, catchError, take } from 'rxjs/operators';
import { EntityService } from '../../../tamalelibs/services/entity.service';
import { WorkflowService } from '../../../tamalelibs/services/workflow.service';
import { FieldActions, FieldConfig, FieldEvents } from './field.model';
import { EntityType } from '../../../tamalelibs/models/entity-type.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Entity } from '../../../tamalelibs/models/entity.model';
import { FlyService, ValidShow } from '../../../tamalelibs/services/fly.service';
import { ALL_VALUE } from '../../../tamalelibs/constants/business.constants';

@Component({
    selector: 'tam-entity-field',
    templateUrl: './entity-field.component.html',
    styleUrls: ['./field.component.scss']
})
export class EntityFieldComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() config: FieldConfig;
    @ViewChild('entityDropDown', { static: false }) entityDropDown;
    @ViewChild('anchor', { static: false }) anchor;

    isIPAD = false;
    isShowCreateContact = false;
    isShowCreateEntity = false;
    requireInvalid = false;
    searchValue = '';
    suggestionItems: Array<EntityBrief> = new Array<EntityBrief>();
    tooltipDir: TooltipDirective;
    value: Array<EntityBrief> = [];

    private _filterHandlerSubject$: Subject<string> = new Subject();
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _deviceService: DeviceDetectorService,
        private _entityService: EntityService,
        private _flyService: FlyService,
        private _utils: UtilsService,
        private _workflowService: WorkflowService,
    ) { }

    /**
     * Determines each individual of given field value is valid.
     * @param fldValue the field value
     * @param source Available entity types
     * @returns An array of validity of field value.
     */
    static isFieldValueValid(fldValue: any, source: string[]) {
        const saveValues: Array<Entity> = Array.from(fldValue);
        const fldValueStatusArray = [];
        if (saveValues && saveValues.length > 0) {
            // filter control value type is typo config source
            saveValues.forEach(data => {
                const fieldEntityTypeId = data.type.id;
                if (source.findIndex(item => item === EntityType.ALL.id) !== -1 ||
                    source.findIndex(item => item === fieldEntityTypeId) !== -1) {
                    fldValueStatusArray.push(true);
                } else {
                    fldValueStatusArray.push(false);
                }
            });
        }
        return fldValueStatusArray;
    }

    ngOnInit() {
        if (this.config.disabled) {
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this._destroySubscriptions.push(
            this._filterHandlerSubject$.pipe(
                debounceTime(500),
            ).subscribe(filterStr => this.searching(filterStr)),
            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        // multi enity control is an Array data.

        const saveValues: Array<Entity> = this.config.field.value;
        if (saveValues && saveValues.length > 0) {
            // filter control value type is typo config source
            const valueStatus = EntityFieldComponent.isFieldValueValid(saveValues, this.config.source);
            for (let i = 0; i < valueStatus.length; i++) {
                if (valueStatus[i]) {
                    this.value.push(saveValues[i]);
                }
            }
        } else {
            this.value = [];
        }

        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });

        // show on the fly
        const validShow: ValidShow = this._flyService.invalidOnTheFly(this.config.source);
        this.isShowCreateContact = validShow.isShowCreateContact;
        this.isShowCreateEntity = validShow.isShowCreateEntity;
        this._destroySubscriptions.push(
            // get entity value
            this._flyService.getEntityValue(this.config.id),
            this._flyService.feedbackEntity$.subscribe(res => {
                if (res.id === this.config.id) {
                    this.value.push(res.entity);
                    this.onValueChange(this.value);
                }
            })
        );
    }

    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    /**
    * Create Entity
    */
    createEntity() {
        this._flyService.createEntity(this.searchValue, this.config.id, this.config.source);
    }
    /**
     * Create Contact
     */
    createContact() {
        this._flyService.createContact(this.searchValue, this.config.id);
    }

    getDisplayValueForEntities(entities: Array<EntityBrief>): string {
        return this._workflowService.getDisplayValueForEntities(entities);
    }

    handleFilter(value) {
        this._filterHandlerSubject$.next(value);
    }

    onOpen(event: any): void {
        const inputValue = this.entityDropDown.wrapper.nativeElement.querySelector('input', '.k-multiselect-wrap .k-floatwrap').value;
        if (!inputValue) {
            event.preventDefault();
        }
    }

    searching(text) {
        const searchValue = text;
        this.entityDropDown.loading = true;
        const source = (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? {} : { entityType: this.config.source };
        this._entityService.getEntityListBySearchTextQuick(1, 20, text, source).pipe(
            catchError((e) => {
                return of(null);
            }),
            take(1),
        ).subscribe(res => {
            this.searchingDone(res, searchValue);
            this.entityDropDown.loading = false;
        });
    }

    searchingDone(res, searchValue) {
        this.searchValue = null;
        this.suggestionItems = this._entityService.mapEntityBriefList(res);
        if (this.suggestionItems.findIndex(item => item.shortName.toLowerCase() === searchValue.toLowerCase() || item.name.toLowerCase() === searchValue.toLowerCase()) === -1) {
            this.searchValue = searchValue;
        }
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && (!this.config.field.value || !this.value || this.config.field.value.length === 0)) {
            return true;
        } else {
            return false;
        }
    }

    onValueChange(event) {
        this.requireInvalid = false;
        this.config.field.value = event;
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: event,
            },
        });

        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });

    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        }
    }
}
