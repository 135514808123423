/**
 * Created by Alan Yang on 12/30/2018.
 * Description:
 * Filter Type: Entities
 *
 * ------ maintenance history ------
 */
import { Component, OnInit, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { of as observableOf, Subject } from 'rxjs';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../tamalelibs/components/filter-widget.config';
import { Subscription } from 'rxjs';
import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { catchError, take, debounceTime, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../redux';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';
import { MultiSelDropdownFilterConfig, MultiSelDropdownConfig } from '../multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { RelationshipType } from '../../tamalelibs/models/relationship-type.model';
import { ALL_VALUE } from '../../tamalelibs/constants/business.constants';
import { DeviceDetectorService } from 'ngx-device-detector';
import { relationshipTypeSelector } from '../../redux/reducers/relationship-type.reducer';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';

/**
 * Filter widget for metadataType is Entities
 *
 * @export
 * @class TamEntitiesFilterComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'tam-entities-filter',
    templateUrl: 'tam-entities-filter.component.html',
    styleUrls: ['tam-filter-container.component.scss']
})
export class TamEntitiesFilterComponent implements OnInit, OnDestroy {
    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event

    @ViewChild('entitylist', { static: false }) entitylist;

    dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);

    // the selected operatordropdownConfig
    operator: { name: string, value: string };
    operators: Array<{ name: string, value: string }>;

    suggestionItems: Array<EntityBrief> = []; // suggestion items based on search, sub set of dataItems
    selectedItems: Array<EntityBrief> = []; // Array<EntityBrief>. the selected entities, sub set of dataItems.
    relationshipNames: string; // the checked relationship names;
    selectedRelationships: Array<RelationshipType>;
    btnText: string;
    isItemHoverOn = false;


    private _filterValue: string;
    private _page = 0;
    private _pageSize: number = AppConfig.searchingPageSize;
    private _source: Array<string>; // mark data source configuration
    private _relationshipTypeSource: Array<RelationshipType> = []; // relationshipType, gets from stor
    private _allRel = false;

    // subscriptions
    private _getEntitListBySearchTextSubscription$: Subscription;
    private _storeSubscription$: Subscription;
    private _filterHandler$: Subject<string> = new Subject();

    constructor(
        private _entityService: EntityService,
        private _deviceService: DeviceDetectorService,
        private _store: Store<AppState>,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {

        this._filterHandler$.pipe(
            debounceTime(250),
            filter(filterStr => filterStr != null && filterStr.length > 0)
        ).subscribe(filterStr => this.searching(filterStr));

        // operator list
        this.operators = this.config.operators;
        // the selected operator
        this.operator = this.config.operators.find(item => item.value === this.config.selectedOperator);

        this._storeSubscription$ = this._store.pipe(select(relationshipTypeSelector)).subscribe(items => this.initRelationship(items));
        this.selectedItems = this.config.value;
        // For use within normal web clients
        const isiPad = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (isiPad) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }
    }

    ngOnDestroy() {
        if (this._getEntitListBySearchTextSubscription$) {
            this._getEntitListBySearchTextSubscription$.unsubscribe();
        }
        if (this._storeSubscription$) {
            this._storeSubscription$.unsubscribe();
        }
        if (this.dropdownConfig.onChangeValue$) {
            this.dropdownConfig.onChangeValue$.unsubscribe();
        }
    }

    /**
     * when input value, will trigger handleFilter
     * when valueChange, kendo-multiselect will trigger handleFilter
     * BA need:
     * when clear selectedItems, init all list
     * when clear input value, init all list
     * when selected item, keep last search value, to reduce the request.
     */
    handleFilter(value) {
        const filterStr = value.trim();
        if (this._filterValue && !filterStr) {
            this.searchingFirstPage();
        }
        this._filterHandler$.next(filterStr);
        this._filterValue = filterStr;
    }

    initRelationship(items) {
        this._relationshipTypeSource = items;
        ArrayHelperService.sort(this._relationshipTypeSource, 'name');
        // init relationship data
        let checkedRel: Array<RelationshipType>;
        if (this.config.relationshipType === ALL_VALUE) {
            this._allRel = true;
            this.relationshipNames = ALL_VALUE;
            this._relationshipTypeSource.forEach(data => {
                this.dropdownConfig.data.push(MultiSelDropdownConfig.parse(data.id, true, data.name));
            });
            checkedRel = items;
        } else {
            this._allRel = false;
            checkedRel = [];
            const checkedItems: Array<string> = this.config.relationshipType || [];
            this._relationshipTypeSource.forEach(data => {
                const checked: boolean = checkedItems.includes(data.id);
                this.dropdownConfig.data.push(MultiSelDropdownConfig.parse(data.id, checked, data.name));
                if (checked) {
                    checkedRel.push(data);
                }
            });
        }
        this._relationshipChage(checkedRel, false);

        // subscribe relationship event
        this.dropdownConfig.onChangeValue$.subscribe(data => {
            let temp: Array<RelationshipType>;
            if (data === ALL_VALUE) {
                this._allRel = true;
                temp = this._relationshipTypeSource;
            } else {
                this._allRel = false;
                temp = data;
            }
            this._relationshipChage(temp);
        });
        this.searchingFirstPage();
    }

    onAddRelationClick(event, val: number = 0) {
        if (this.btnText !== 'With relationship:' || val !== 1) {
            this.dropdownConfig.open$.next(event.target);
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    onBtnRemoveRelationshipClicked() {
        this.dropdownConfig.data.forEach(item => item.checked = false);
        this._allRel = false;
        this._relationshipChage([]);
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    searchingFirstPage() {
        this.searching('');
    }

    searching(text) {
        this._page = 0;
        if (this.entitylist) {
            this.entitylist.loading = true;
        }
        this._getEntitListBySearchTextSubscription$ = this._entityService.getEntityListBySearchTextQuick(++this._page,
            this._pageSize, text, this._source).pipe(
                take(1),
                catchError((e) => {
                    return observableOf();
                })).subscribe(
                    res => {
                        this.suggestionItems = this.searchingDone(res);
                        this.entitylist.loading = false;
                        this._changeDetectorRef.detectChanges();
                    }
                );
    }

    searchingDone(entityList): Array<EntityBrief> {
        return this._entityService.mapEntityBriefList(entityList);
    }

    /**
     * on selected entities change
     *
     * @memberof TamEntitiesFilterComponent
     */
    valueChange() {
        if (this.selectedItems.length === 0) {
            this.searchingFirstPage();
        }
        this._filterValue = '';
        this._feedback();
    }

    private _feedback() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        this.config.selectedOperator = this.operator.value;
        this.config.value = this.selectedItems;
        this.config.relationshipType = this._allRel ? ALL_VALUE : this.selectedRelationships.map(item => item.id);
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    private _relationshipChage(data: any, feedBack: boolean = true) {
        this.selectedRelationships = data;
        this.relationshipNames = this._allRel ? ALL_VALUE : this.selectedRelationships.map(item => item.name).join(', ');
        if (!this.relationshipNames) {
            this.btnText = '+ Add Relationship Filter';
        } else {
            this.btnText = 'With relationship:';
        }
        if (feedBack) {
            this._feedback();
        }
    }

}
