/**
 * Created by Abner Sui on 08/20/2019
 * -----------------------------------
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { FieldType, FileInfo, ProcessInstance, Task, TaskStatus, TaskType } from '../models/workflow.model';

@Injectable()
export class WorkflowTaskService {

    constructor(
        private _transportService: TransportService,
    ) { }

    addComment(id: string, comment: any): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.workFlowTaskEndpoint}${id}/`;
        const params = {
            expand: 'process-instance;process-definition;task-definition;stage;entity;field;file',
            'operate-type': 'comment',
        };
        if (comment) {
            params['comment'] = JSON.stringify(comment);
        }
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, null, options);
    }

    approveTaskById(id: string, approve: boolean, statusValue: string, comment: any): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.workFlowTaskEndpoint}${id}/`;
        let operateType = 'complete';
        if (!approve) {
            operateType = 'reject';
        }
        const params = {
            expand: 'process-instance;process-definition;task-definition;stage;entity;field',
            'operate-type': operateType,
        };
        if (statusValue) {
            params['status-value'] = statusValue;
        }
        if (comment) {
            params['comment'] = JSON.stringify(comment);
        }
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, null, options);
    }

    // Task Management - change task status
    changeQuickTaskStatus(statusData): Observable<any> {
        const taskId = statusData.subTaskId;
        const remindDate = statusData.remindDate;
        const type = statusData.type;
        const url = `${AppConfig.taskManagementTaskAssigned}${taskId}`;
        const formData = new FormData();

        if (type === 'TaskStatusChange') {
            formData.append('status', 'done');
        } else if (type === 'RemindDateChange') {
            formData.append('remind-date', remindDate);
        }

        return this._transportService.put(url, formData);
    }

    // Task Management - create a new quick task
    createQuickTask(taskData): Observable<any> {
        const url = `${AppConfig.taskManagementQuickTask}`;
        const formData = new FormData();
        formData.append('configuration', JSON.stringify(taskData));
        return this._transportService.post(url, formData);
    }

    // Task Management - delete a quick task
    deleteTask(task, type): Observable<any> {
        let url = '';
        if (type === 0) {
            url = `${AppConfig.taskManagementTaskAssigned}${task.subTaskId}`;
        } else {
            url = `${AppConfig.taskManagementQuickTask}${task.id}`;
        }

        return this._transportService.delete(url);
    }

    // Task Management - edit a quick task
    editQuickTask(taskData): Observable<any> {
        const url = `${AppConfig.taskManagementQuickTask}${taskData.definitionId}`;
        const formData = new FormData();
        formData.append('configuration', JSON.stringify(taskData));
        return this._transportService.put(url, formData);
    }

    // Task Management - get all tasks
    getAllTasks() {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.taskManagementQuickTask;
        const params = {
            expand: 'entity',
        };

        const options = {
            headers: headers,
            params: params
        };

        return this._transportService.get(url, options);
    }

    // Task Management - get task by status
    getTaskByStatus(taskStatus?: number): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.taskManagementTaskCreator;
        const params = {
            expand: 'entity',
        };
        if (taskStatus === 1) {
            params['status'] = 'done';
        } else {
            params['status'] = 'not-done';
        }
        const options = {
            headers: headers,
            params: params
        };

        return this._transportService.get(url, options);
    }

    // Task Management - get task by processor
    getTaskByProcessor(taskStatus: number): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.taskManagementTaskAssigned;
        const params = {
            expand: 'entity;task-definition',
        };
        if (taskStatus === 1) {
            params['status'] = 'done';
        } else {
            params['status'] = 'todo';
        }
        const options = {
            headers: headers,
            params: params
        };

        return this._transportService.get(url, options);
    }

    // Task Management - get task by contact and processor
    getTaskByAssignee(contactId: string, taskStatus: number): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.taskManagementTaskAssigned;
        const params = {
            expand: 'task-definition',
            contact: contactId,
        };
        if (taskStatus === 1) {
            params['status'] = 'done';
        } else {
            params['status'] = 'todo';
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // Task Management - get sub task by id
    getSubTaskById(id): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.taskManagementTaskAssigned}${id}/`;
        const params = {
            expand: 'entity;task-definition',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // Task Management - get task by id
    getQuickTaskDefinitionById(id): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.taskManagementQuickTask}${id}/`;
        const params = {
            expand: 'entity',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getTaskDefList(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.workFlowTaskDefinitionEndpoint;
        const params = {
            expand: 'stage;entity;field;file',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getTaskEditable(task: Task, currentUser): boolean {
        let taskEditable = false;
        if (task.taskDefinition.type === TaskType.APPROVAL) {
            taskEditable = (task.status === TaskStatus.IN_PROGRESS || task.status === TaskStatus.TODO) && task.assignee.findIndex(item => item.id === currentUser.id) > -1 && task.approvalTask.findIndex(item => item.user.id === currentUser.id) < 0;
        } else if (task.taskDefinition.type === TaskType.INPUT) {
            taskEditable = (task.status === TaskStatus.TODO && task.assignee.findIndex(ass => ass.id === currentUser.id) > -1)
                || (task.status === TaskStatus.IN_PROGRESS && task.assignee.findIndex(ass => ass.id === currentUser.id) > -1);
        }
        return taskEditable;
    }

    getTaskList(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = AppConfig.workFlowTaskEndpoint;
        const params = {
            expand: 'process-definition;process-instance;task;task-definition;stage;entity;file',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getTaskById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.workFlowTaskEndpoint}${id}/`;
        const params = {
            expand: 'process-definition;process-instance;task;task-definition;stage;entity;file',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * Gets a task candidate list, each of them the given approval task can be sent back to.
     * @param id the id of an approval task.
     * @returns an array of tasks to which the given approval task can be sent back.
     */
    getSendBackTarget(id: string): Observable<Array<{ taskID: string, taskName: string, completeTime?: Date }>> {
        const headers = {
            'Content-Type': 'application/json'
        };

        const url = AppConfig.workflowSendBackTargetEndPoint.replace('{id}', id);
        const options = {
            headers: headers
        };

        return this._transportService.get(url, options);
    }

    getAllFilesByInstance(instance: ProcessInstance): Map<string, Array<FileInfo>> {
        const allFiles: Map<string, Array<FileInfo>> = new Map();

        instance.task.forEach((task: Task) => {
            // because not all the task have taskDefinition, need to add condition to filter the data
            if (task.taskDefinition) {
                const fields = Array.from(task.taskDefinition.fields.values()).filter(item => (item.fieldDefinition.type === FieldType.FILE && item.value && item.value !== null));
                fields.forEach(item => {
                    allFiles.set(item.id, item.value);
                });
            }
        });
        return allFiles;
    }

    saveTask(id: string, fields: any, deletedFiles: any, complete = false): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.workFlowTaskEndpoint}${id}/`;
        let operateType = 'edit';
        if (complete) {
            operateType = 'complete';
        }
        const params = {
            expand: 'process-instance;process-definition;task-definition;stage;entity;field;file',
            'operate-type': operateType,
        };
        const options = {
            headers: headers,
            params: params,
        };
        const body = new URLSearchParams();
        body.set('field-values', JSON.stringify(fields));
        body.set('field-files-deleted', JSON.stringify(deletedFiles));
        return this._transportService.post(url, body.toString(), options);
    }

    sendBackTaskById(id: string, backTaskId: string, comment: any): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.workFlowTaskEndpoint}${id}/`;
        const params = {
            expand: 'process-instance;process-definition;task-definition;stage;entity;field;file',
            'operate-type': 'sendback',
            'back-task-id': backTaskId,
        };
        if (comment) {
            params['comment'] = JSON.stringify(comment);
        }
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, null, options);
    }

    takeTaskById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.workFlowTaskEndpoint}take/${id}/`;
        const params = {
            expand: 'process-instance;process-definition;task-definition;stage;entity;field;file',
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.post(url, null, options);
    }

    /**
     * Update the value of fields of the given completed task.
     * @param id the completed task's id
     * @param fields the map of fields of the given task.
     * @param updatedFields an array of field ids, each of them is changed.
     * @returns
     */
    updateTask(id: string, fields: any, updatedFields: string[], deletedFileIds): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache',
        };
        const url = `${AppConfig.workFlowTaskEndpoint}${id}/`;
        const operateType = 'complete-update';
        const params = {
            expand: 'process-instance;process-definition;task-definition;stage;entity;field;file',
            'operate-type': operateType,
        };
        const options = {
            headers: headers,
            params: params,
        };

        const body = new URLSearchParams();
        body.set('field-values', JSON.stringify(fields));
        body.set('completed-updated-feilds', JSON.stringify(updatedFields));
        body.set('field-files-deleted', JSON.stringify(deletedFileIds));
        return this._transportService.post(url, body.toString(), options);
    }
}
