/**
 * Created by Simon Zhao on 12/10/2021.
 * Description:
 * Serve for a single template configuration component instance as a bride between the template configuration component and field config components.
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AutomationModelUtility } from '../../tamalelibs/models/automation.model';
import { GatewayType } from '../../tamalelibs/models/workflow.model';
import { WorkflowConfigService } from '../../tamalelibs/services/workflow-config.service';
import { DiagramNode } from '../../widgets/diagram/diagram-service';
import { DiagramService } from '../../widgets/diagram/diagram.service';
import { FieldConfig } from './../work-flow/field/field.model';

@Injectable()
export class TemplateBubbleService {
    /**
     * Notify observers that the label of a field is changed.
     *
     * @memberof TemplateBubbleService
     */
    fieldLabelChangeSubject$ = new Subject<FieldConfig>();

    diagramService: DiagramService;

    workflowConfigService: WorkflowConfigService;

    /**
     * Determines whether the given field is involved with any conditional gateway.
     * @param fieldId the given field id
     * @returns a flag indicating whether the given field has been involved with any conditional gateway.
     */
    isFieldInvolvedWithPrimaryEntity(fieldId: string): boolean {
        if (this.workflowConfigService && fieldId && this.workflowConfigService.getDefinitionSettingConfig && this.workflowConfigService.getDefinitionSettingConfig.primaryEntity) {
            return fieldId === this.workflowConfigService.getDefinitionSettingConfig.primaryEntity.fieldDefId;
        }
        return false;
    }

    /**
     * Determines whether the given field is involved with any conditional gateway.
     * @param fieldId the given field id
     * @returns a flag indicating whether the given field has been involved with any conditional gateway.
     */
    isFieldInvolvedWithConditionalGateway(fieldId: string): boolean {
        if (this.diagramService && fieldId) {
            const conditionalGateways = this.diagramService.getAllGatewayNodes().filter(n => n.type === GatewayType.CONDITIONAL && !!n.branchs);
            const isGatewayInvolveField = (node: DiagramNode, fId: string) => {
                return node.branchs.some(nb => !!nb.condition && nb.condition.toString().indexOf(fieldId) >= 0);
            };
            return conditionalGateways.some(g => isGatewayInvolveField(g, fieldId));
        }
        return false;
    }

    /**
     * Determines whether the given field is involved with any automation config.
     * @param fieldId the given field id
     * @returns a flag indicating whether the given field has been involved with any automation config.
     */
    isFieldInvolvedWithAutomation(fieldId: string): boolean {
        if (this.diagramService && fieldId) {
            const taskNodesWithAutomation = this.diagramService.getAllTaskNodes().filter(n => n.automationConfigs && n.automationConfigs.length > 0);
            const gatewayNodesWithAutomation = this.diagramService.getAllGatewayNodes().filter(n => n.automationConfigs && n.automationConfigs.length > 0);
            const isAutomationInvolveField = (node: DiagramNode, fId: string) => {
                const isAutomationInvolved = node.automationConfigs.some(autoCfg => AutomationModelUtility.isFieldInvolved(autoCfg, fieldId));
                return isAutomationInvolved;
            };
            const isNodeCollectionInvolveField = (nodes: DiagramNode[], fId: string) => {
                const isNodeInvolved = nodes.length > 0 && nodes.some(n => isAutomationInvolveField(n, fId));
                return isNodeInvolved;
            };
            const isInvolved = isNodeCollectionInvolveField(taskNodesWithAutomation, fieldId) || isNodeCollectionInvolveField(gatewayNodesWithAutomation, fieldId);
            return isInvolved;
        }
        return false;
    }

    /**
     * Determines whether the given field is involved with any existing config.
     * @param fieldId the given field id
     * @returns a flag indicating whether the given field is involved with any existing config
     */
    isFieldInvolvedWithConfig(fieldId: string): boolean {
        return this.isFieldInvolvedWithAutomation(fieldId) || this.isFieldInvolvedWithConditionalGateway(fieldId) || this.isFieldInvolvedWithPrimaryEntity(fieldId);
    }
}
