/**
 * Created by Marcus Zhao on 8/27/19.
 * Description: add calendarDialog option for Create/Edit Calendar Event
 *
 * ------ maintenance history ------
 *
 */

import { OfficeAddIn } from '../../tamalelibs/models/office-addin.model';

export class CalendarDialogOpenOptions {
    openType: CalendarDialogType;
    isPluginInEditEventMode: boolean;
    minimizable: boolean;
    entryId: string;
    entryTypeId?: string;
    entity: any;
    attachments?: any[];
    isOfficeAddinMode?: boolean;
    officeAddIn?: OfficeAddIn;
    startDate?: Date;
    endDate?: Date;
    triggerSource?: CalendarDialogTriggerSource;
}

export enum CalendarDialogType {
    NewEvent = 'New Event',
    EditEvent = 'Edit Event'
}

export enum CalendarDialogTriggerSource {
    NoteDialog = 'Note Dialog'
}

export class LocationExpand {
    addressName: string;
    isExistCoordinate: boolean;
    latitude: number;
    linkedEntityId: string;
    longitude: number;

    constructor(linkedEntityId: string, addressName: string, isExistCoordinate: boolean, latitude: number, longitude: number) {
        this.linkedEntityId = linkedEntityId;
        this.addressName = addressName;
        this.isExistCoordinate = isExistCoordinate;
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

