/**
 * Created by Yoyo Fang on 08/06/2020.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Daniel on 6/27/2022, load splash image by sperate api
 */

import { Injectable } from '@angular/core';
import { of as observableOf, Subject, Subscription } from 'rxjs';
import { take, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../redux';

import { IntralinksService } from '../../tamalelibs/services/intralinks.service';
import { IntralinksValidationObject } from '../../tamalelibs/models/intralinks.model';
import { IntralinksValidationResult } from '../../tamalelibs/models/intralinks.model';
import { ToastService } from '../../widgets/toast/toast.service';
import { NotificationOptions } from '../../widgets/notification/notification.model';
import { NotificationStyles } from './../../widgets/notification/notification.model';
import { businessConstants } from '../../tamalelibs/constants/business.constants';
import { INTRALINKS_ACTIONS } from '../../tamalelibs/redux/intralinks.actions';
import { StringLiteralsPipe } from '../../pipes/translate.pipe';

@Injectable()
export class IntralinksValidationService {
    private _occupied = false;
    private _validationObject: IntralinksValidationObject;
    private _workspaceId: string;
    private _feedbackSubject$: Subject<any>;
    private _validationComponent$: Subject<any>;
    private _subscription: Subscription;

    constructor(
        private _intralinkService: IntralinksService,
        private _toast: ToastService,
        private _router: Router,
        private _store: Store<AppState>
    ) {
        this._feedbackSubject$ = new Subject<any>();
    }

    breakByNavigation() {
        this._feedbackSubject$.next(false);
        this._occupied = false;
        this._validationObject = null;
        if (this._validationComponent$) {
            this._validationComponent$.next({ type: 'skip' });
        }
    }

    breakValidation() {
        this._feedbackSubject$.next(false);
        this._occupied = false;
        this._validationObject = null;
        let url = window.location.href;
        const start = url.indexOf('/feeds');
        let end = url.indexOf('/folder');
        if (end === -1) {
            end = url.indexOf('/recents'); // account level recents
            if (end === -1) {
                end = url.length;
            }
        }
        url = url.slice(start, end);
        this._router.navigateByUrl(url);
    }

    // register IntralinksValidationComponent rxjs Channel
    register(channel: Subject<any>) {
        if (this._subscription && !this._subscription.closed) {
            this._subscription.unsubscribe();
        }
        this._validationComponent$ = channel;
        this._subscription = this._validationComponent$.subscribe(this._componentMessageHandler.bind(this));
    }

    validate(screenId: string, workspaceId: string): Subject<any> {
        if (this._occupied === false) {
            this._occupied = true;
            this._validationComponent$.next({
                type: 'init',
                payload: null
            });
            this._validationObject = new IntralinksValidationObject(screenId);
            this._workspaceId = workspaceId;
            this._intralinkService.splashMFACheck(workspaceId, this._validationObject)
                .pipe(
                    take(1),
                    catchError(this._errorHandler.bind(this))
                ).subscribe((res) => {
                    this._responseMapping(res, screenId, workspaceId);
                });
        }
        return this._feedbackSubject$;
    }

    private _componentMessageHandler(msg) {
        switch (msg.type) {
            case 'accept':
                this._validationObject.acceptSplash = true;
                this._intralinkService.acceptSplash(msg.payload['workspaceId'], this._validationObject).pipe(
                    take(1),
                    catchError(this._errorHandler.bind(this))
                ).subscribe((res) => {
                    this._responseMapping(res, msg.payload['screenId'], msg.payload['workspaceId']);
                });
                break;
            case 'cancel':
                this.breakValidation();
                break;
            case 'choose':
                this._validationObject.accountEmail = msg.payload['accountEmail'];
                this._validationObject.mobileNumber = msg.payload['mobileNumber'];
                this._intralinkService.chooseMFA(msg.payload['workspaceId'], this._validationObject).pipe(
                    take(1),
                    catchError(this._errorHandler.bind(this))
                ).subscribe((res) => {
                    this._responseMapping(res, msg.payload['screenId'], msg.payload['workspaceId']);
                });
                break;
            case 'password':
                this._validationObject.oneTimePassword = msg.payload;
                this._intralinkService.validateMFAPassword(this._workspaceId, this._validationObject).pipe(
                    take(1),
                    catchError((err) => {
                        const note = new NotificationOptions();
                        note.style = NotificationStyles.Error;
                        note.message = 'Incorrect code. Please reenter the code.';
                        // console.warn(err);
                        this._toast.notify(note);
                        return observableOf({});
                    })
                ).subscribe((res) => {
                    this._responseMapping(res, msg.payload['screenId'], msg.payload['workspaceId']);
                });
                break;
            case 'splash':
                if (msg && msg.payload && msg.payload['splash-image-Name']) {
                    this._intralinkService.getSplashImage(this._workspaceId, this._validationObject.screenId).subscribe((res) => {
                        res.state = IntralinksValidationResult.SPLASH_IMAGE;
                        this._responseMapping(res, msg.payload['screenId'], msg.payload['workspaceId']);
                    });
                }
                break;
        }
    }

    private _errorHandler(err) {
        if (err.indexOf(businessConstants.intralinks.onSessionWarning.original) > -1) {
            this._store.dispatch({
                type: INTRALINKS_ACTIONS.REACH_SESSION_LIMIT,
                payload: { message: businessConstants.intralinks.onSessionWarning.userReadable }
            });
            this.breakValidation();
        } else {
            const note = new NotificationOptions();
            note.style = NotificationStyles.Error;
            let errorMsg = err;
            if (errorMsg.includes('ERR_INTRALINKS_SERVER')) {
                errorMsg = StringLiteralsPipe.translate('general.the_configuration_is_not_correct');
            }
            note.message = errorMsg;
            this._toast.notify(note);
            this._router.navigate(['/error']);
        }
        return observableOf({});
    }

    private _passValidation() {
        this._occupied = false;
        this._validationObject = null;
        if (this._validationComponent$) {
            this._validationComponent$.next({ type: 'pass' });
        }
        this._feedbackSubject$.next(true);
    }
    private _responseMapping(res, screenId, workspaceId) {
        switch (res['state']) {
            case IntralinksValidationResult.ALLOW:
                this._passValidation();
                break;
            case IntralinksValidationResult.MFA_REQUIRED:
                this._showMFA(screenId, workspaceId, res);
                break;
            case IntralinksValidationResult.SPLASH_REQUIRED:
                this._showSplash(screenId, workspaceId);
                break;
            case IntralinksValidationResult.ONE_TIME_PASSWORD:
                break;
            case IntralinksValidationResult.SPLASH_IMAGE:
                this._showSplashImage(res);
                break;
            default:
                console.log('Error found when parsing response');
                // this.breakValidation();
                break;
        }
    }

    private _showMFA(screenId, workspaceId, response) {
        if (this._validationComponent$) {
            this._validationComponent$.next({
                type: 'mfa',
                payload: Object.assign({
                    screenId: screenId,
                    workspaceId: workspaceId
                }, response)
            });
        }
    }

    private _showSplash(screenId, workspaceId) {
        if (this._validationComponent$) {
            this._intralinkService.getSplashPage(screenId, workspaceId)
                .pipe(
                    take(1),
                    catchError(this._errorHandler.bind(this))
                ).subscribe(response => {
                    this._validationComponent$.next({
                        type: 'splash',
                        payload: Object.assign({
                            screenId: screenId,
                            workspaceId: workspaceId
                        }, response['splash'])
                    });
                });
        }
    }

    private _showSplashImage(res) {
        if (this._validationComponent$) {
            this._validationComponent$.next({
                type: 'splashImage',
                payload: res
            });
        }
    }
}
