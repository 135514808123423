/**
 * Created by Ella Ma on 10/16/2020. custom grid filter panel model
 * ------ maintenance history ------
 * Updated by Daniel on 06/29/2022. Added enum to handle file
 */

import { Subject } from 'rxjs';

export class CustomDateColumnFilterConfig {
    column: Object;
    items: Array<any>;
    events$: Subject<{ type: CustomDateColumnFilterConfigEventType, payload?: any }>;
    actions$: Subject<{ type: CustomDateColumnFilterConfigActionType, payload?: any }>;
    constructor() {
        this.items = [];
        this.column = {};
        this.events$ = new Subject();
        this.actions$ = new Subject();
    }
}

export enum CustomDateColumnFilterConfigActionType {
    onSelectAll,
    resetData
}

export enum CustomDateColumnFilterConfigEventType {
    onFilter
}

export enum CustomGridServiceTriggerType {
    Filter = 'Filter',
    Group = 'Group'
}

export enum GridColumnType {
    CONTACT_COLUMN = 'contactColumn',
    ENTITY_COLUMN = 'entityColumn',
    ENTRY_COLUMN = 'entryColumn',
    RELATIONSHIP_COLUMN = 'Relationship',
    MDL_COLUMN = 'MDL',
    FORMULA_COLUMN = 'Formula',
}

export enum TamCustomGridColumnFilterComponentsActions {
    UPDATE_COLUMN_WIDTH_BY_OPERATION = 'UPDATE_COLUMN_WIDTH_BY_OPERATION',
    UPDATE_COLUMN_WIDTH_BY_SCROLL = 'UPDATE_COLUMN_WIDTH_BY_SCROLL'
}

