<div class="container" kendoTooltip position="right">
    <ng-container *ngFor="let item of config.config.menuData; let i = index">
        <div class="icon" [title]="item.title" [attr.tid]="item.tid" (click)="changeSelect(item, i)">
            <div class="validIcon" *ngIf="item.validStatus !== validStatus.default && item.status !== status.disabled">
                <smart-icon class="validFailed" *ngIf="item.validStatus === validStatus.validFailed"
                    name="times-circle">
                </smart-icon>
                <smart-icon class="validPass" *ngIf="item.validStatus === validStatus.validPass" name="check-circle">
                </smart-icon>
            </div>
            <smart-icon class="light" [name]="item.icon" [class.selected]="config.config.active===i"
                [class.disabled]="item.status === status.disabled" [class.notSet]="item.status === status.notSet">
            </smart-icon>
        </div>
    </ng-container>
</div>