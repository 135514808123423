/**
 * Created by Abner Sui on 08/18/2020
 * -------------------------------------
 * 1/22/2022 Marcus add function for support on the fly
 * 10/12/2022 Simon Zhao exposed the logic of determining the value's validity via a static function.
 * Updated by Daniel on 7/20/2023, to support relationship filter of datasource.
 */

import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FieldConfig, FieldEvents, FieldActions } from './field.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { EntityBrief } from '../../../tamalelibs/models/entity-brief.model';
import { Subscription, fromEvent, BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { debounceTime, filter, take, map } from 'rxjs/operators';
import { EntityService } from '../../../tamalelibs/services/entity.service';
import { ALL_VALUE } from '../../../tamalelibs/constants/business.constants';
import { EntityType } from '../../../tamalelibs/models/entity-type.model';
import { FlyService, ValidShow } from '../../../tamalelibs/services/fly.service';
import { ProcessInstance, TemplateControlInfo } from '../../../tamalelibs/models/workflow.model';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { WorkflowService } from '../../../tamalelibs/services/workflow.service';

@Component({
    selector: 'tam-single-entity-dropdown-field',
    templateUrl: './single-entity-dropdown-field.component.html',
    styleUrls: ['./field.component.scss']
})
export class SingleEntityDropdownFieldComponent implements OnInit, AfterViewInit, OnDestroy {
    // #region public properties
    @Input() config: FieldConfig; // for the basic information of the control.
    @Input() instance: ProcessInstance; // to get the dependent control value.
    @Input() valueChangeSubject: Subject<TemplateControlInfo>; // to subscribe the change of the dependent control.

    @ViewChild('kendo', { static: false }) kendo;
    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false }) tooltipDir: TooltipDirective;

    kDataSource: BehaviorSubject<Array<EntityBrief>> = new BehaviorSubject<Array<EntityBrief>>([]);
    isIPAD = false;
    isLoading = false;
    isShowCreateContact = false;
    isShowCreateEntity = false;
    requireInvalid = false;
    searchValue = '';
    value: EntityBrief;
    // #endregion

    // #region private properties
    private _destroySubscriptions: Array<Subscription> = [];
    private _filterHandler$: Subject<string> = new Subject();
    private _focused = false;
    private _getEntityListByRelationshipsSubscription$: Subscription;
    private _validEntityBriefList: Array<EntityBrief>;
    private _valueChangeSubscription$: Subscription;
    private _watchControlValue: Array<EntityBrief>;
    // #endregion

    // #region constructor
    constructor(
        private _deviceService: DeviceDetectorService,
        private _entityService: EntityService,
        private _flyService: FlyService,
        private _utils: UtilsService,
        private _workflowService: WorkflowService,
    ) { }
    // #endregion

    // #region OnInit
    ngOnInit() {
        // whether the control is disabled or not
        if (this.config.disabled) {
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this._destroySubscriptions.push(
            this._filterHandler$.pipe(
                debounceTime(250),
                filter(filterStr => filterStr !== null && filterStr !== undefined)
            ).subscribe(filterStr => this._filterHandler(filterStr)),

            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        // to monitor the changes of other dependent controls
        if (this.valueChangeSubject) {
            if (this.config.isRelationshipChecked && this.config.relationshipTypeId && this.config.customFieldId) {
                this._valueChangeSubscription$ = this.valueChangeSubject.pipe(
                    filter(res => res.fieldId === this.config.customFieldId)
                ).subscribe(res => this._watchControlValueChange(res));

                // get the value from dependent control and set the datasource
                this._watchControlValue = this._workflowService.getControlValueFromInstanceByFieldId(this.config.customFieldId, this.instance);
                const templateControlValue = new TemplateControlInfo(this.config.fieldDefinitionId, this._watchControlValue, ControlType.SINGLE_ENTITY_DROP_DOWN);
                this._watchControlValueChange(templateControlValue);
            }
        }
        // set the value for the control, and broadcast it to subscribers.
        if (this._isFieldValueValid(this.config.field.value, this.config.source, this._validEntityBriefList)) {
            this.value = this.config.field.value[0];
            const templateControlValue = new TemplateControlInfo(this.config.fieldDefinitionId, [this.value], ControlType.SINGLE_ENTITY_DROP_DOWN);
            if (this.valueChangeSubject) {
                this.valueChangeSubject.next(templateControlValue);
            }
        } else {
            this.value = null;
        }
        // broadcast the validate change to subscribers.
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
        // show on the fly
        const validShow: ValidShow = this._flyService.invalidOnTheFly(this.config.source);
        this.isShowCreateContact = validShow.isShowCreateContact;
        this.isShowCreateEntity = validShow.isShowCreateEntity;

        this._destroySubscriptions.push(
            // get entity value
            this._flyService.getEntityValue(this.config.id),
            this._flyService.feedbackEntity$.subscribe(res => {
                if (res.id === this.config.id) {
                    this.value = res.entity;
                    this.valueChange(this.value, true);
                }
            }));
    }
    // #endregion

    // #region AfterViewInit
    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
        if (this.value) {
            setTimeout(
                () => this.kDataSource.next([this.value]));
        }
    }
    // #endregion

    // #region OnDestroy
    ngOnDestroy() {
        if (this._getEntityListByRelationshipsSubscription$) {
            this._getEntityListByRelationshipsSubscription$.unsubscribe();
        }
        if (this._valueChangeSubscription$) {
            this._valueChangeSubscription$.unsubscribe();
        }
        if (this._destroySubscriptions && this._destroySubscriptions.length > 0) {
            this._destroySubscriptions.forEach(item => item.unsubscribe());
        } 32;
    }
    // #endregion

    // #region public functions
    blur(): void {
        this._focused = false;
    }

    /**
     * Create Contact
     */
    createContact() {
        this._flyService.createContact(this.searchValue, this.config.id);
    }
    /**
    * Create Entity
    */
    createEntity(event) {
        this._flyService.createEntity(this.searchValue, this.config.id, this.config.source);
    }

    focus(): void {
        this._focused = true;
        if (this.config.field.value) {
            this.kendo.toggle(true);
        } else {
            this.handleFilter('');
        }
    }

    handleFilter(event: string): void {
        if (this._focused) {
            this.kendo.toggle(false);
            this._filterHandler$.next(event);
        }
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && (!this.config.field.value || !this.value || this.config.field.value.length === 0)) {
            return true;
        } else {
            return false;
        }
    }

    valueChange(event, isTriggerByFly: boolean): void {
        this.requireInvalid = false;
        if (isTriggerByFly) {
            if (this.config.isRelationshipChecked) {
                this._getValidDataSouceByCondition('', false).pipe(
                    take(1),
                    map(response => this._entityService.mapEntityBriefList(response))
                ).subscribe(data => {
                    if (event && data.some(item => item.id === event.id)) {
                        this._setChangeValueForControl(event);
                    }
                });
            } else {
                if (event) {
                    if ((this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ||
                        this.config.source.includes(event.type.id)) {
                        this._setChangeValueForControl(event);
                    }
                }
            }
        } else {
            this._setChangeValueForControl(event);
        }
    }
    // #endregion

    // #region private functions
    private _filterDone(entityList) {
        this._validEntityBriefList = this._entityService.mapEntityBriefList(entityList);
        this.kDataSource.next(this._validEntityBriefList);
        if (this._validEntityBriefList && this._validEntityBriefList.length > 0 && this.value) {
            if (!this._validEntityBriefList.some(item => item.id === this.value.id)) {
                this.value = null;
            }
        } else {
            this.value = null;
        }
        this.isLoading = false;
    }

    private _filterHandler(filterStr: string): void {
        this.searchValue = '';
        if (this._focused) {
            this.isLoading = true;
            // set source for search entity request, and default dropdown list equals 20 by requirements.
            this._getValidDataSouceByCondition(filterStr).pipe(
                take(1),
                map(response => this._entityService.mapEntityBriefList(response))
            ).subscribe(data => {
                this.kDataSource.next(data);
                this._validEntityBriefList = data;
                this.isLoading = false;
                this.kendo.toggle(true);
                if (data.findIndex(item => item.shortName.toLowerCase() === filterStr.toLowerCase() || item.name.toLowerCase() === filterStr.toLowerCase()) === -1) {
                    this.searchValue = filterStr;
                }
            });
        }
    }

    private _getRelationedEntities(data) {
        if (data['relationship-list'] && data['relationship-list'].length > 0) {
            const items: Array<string> = [];
            data['relationship-list'].forEach(item => {
                if (item['child-entity']) {
                    items.push(item['child-entity'].data.id);
                } else if (item['parent-entity']) {
                    items.push(item['parent-entity'].data.id);
                }
            });
            this._entityService.getEntityListByIdsWithSourceQuick(items, this.config.source).subscribe(res => this._filterDone(res));
        } else {
            this.value = null;
            this.kDataSource.next([]);
        }
    }

    private _getValidDataSouceByCondition(filterStr: string, isPagination: boolean = true): Observable<any> {
        const source = (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? {} : { entityType: this.config.source };
        if (this.config.relationshipTypeId && this.config.isRelationshipChecked) {
            source['relationshipFilter'] = {
                relationshipTypeID: this.config.relationshipTypeId
            };
        }
        let relationshipBase = '';
        if (this._watchControlValue) {
            relationshipBase = this._watchControlValue.map(res => res['id']).join(',');
        }
        if (isPagination) {
            return this._entityService.getEntityListBySearchTextQuick(1, 20, filterStr, source, relationshipBase);
        } else {
            return this._entityService.getEntityListBySearchTextQuick(-1, -1, filterStr, source, relationshipBase);
        }
    }

    /**
     * Determines whether the given field value is valid.
     * @param fieldValue the value of an entity-typed field.
     * @param fieldSourceCfg the source config of an entity-typed field.
     * @returns A flag indicating whether the given field value is valid.
     */
    private _isFieldValueValid(fieldValue: any, fieldSourceCfg: string[], validEntityList: Array<EntityBrief>): boolean {
        let isValid = false;
        if (fieldValue && fieldValue.length) {
            const fieldEntityTypeId = fieldValue[0].type.id;
            if (fieldSourceCfg.findIndex(item => item === EntityType.ALL.id) !== -1 ||
                fieldSourceCfg.findIndex(item => item === fieldEntityTypeId) !== -1) {
                if (validEntityList && validEntityList.length > 0) {
                    if (validEntityList.some(item => item.id === fieldValue[0].id)) {
                        isValid = true;
                    }
                } else {
                    isValid = true;
                }
            }
        }
        return isValid;
    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        }
    }

    private _setChangeValueForControl(event): void {
        let value = event;
        if (event) {
            value = [event];
            this.kDataSource.next(value);
            this._focused = false;
        } else {
            this._focused = true;
            this.handleFilter('');
        }

        this.config.field.value = value;
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: value,
            },
        });
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
        if (this.valueChangeSubject) {
            const templateControlValue = new TemplateControlInfo(this.config.fieldDefinitionId, value, ControlType.SINGLE_ENTITY_DROP_DOWN);
            this.valueChangeSubject.next(templateControlValue);
        }
    }

    private _watchControlValueChange(watchedCtrlValue): void {
        this._watchControlValue = watchedCtrlValue.controlValue;
        if (this._watchControlValue) {
            const entityIds = this._watchControlValue.map(item => item.id);
            this._getEntityListByRelationshipsSubscription$ = this._entityService.getEntityListByRelationships(entityIds, this.config.relationshipTypeId)
                .subscribe((data) => this._getRelationedEntities(data));
        } else {
            this._filterHandler('');
        }
    }
    // #endregion
}
