import { EntryType } from './entry-type.model';
import { EntryClass } from './entry-class.model';
import { EntityBrief } from './entity-brief.model';
import { Priority } from './priority.model';
import { Sentiment } from './sentiment.model';

export class DocumentEntry {
    blurb?: string;
    calculatedSubject?: string;
    deletable?: boolean;
    displayDate?: Date;
    entryClass?: EntryClass;
    editable?: boolean;
    entities?: Array<EntityBrief>;
    id: string;
    lastEditDate?: Date;
    priority?: Priority;
    source?: EntityBrief;
    subject?: string;
    submittedDate?: Date;
    submitter?: EntityBrief;
    sentiment?: Sentiment;
    type?: EntryType;
    // above properties should be inherited from Entry model

    extension: string;
    fileDataLink: string; // the real binary data path.
    fileName: string;
    isEmbeddedImg: boolean;
    noteId: string; // Can't get from server response; Don't know where to use it;
    serverFileName: string;
    threadId: string; // The thread which owns this file, the pointer to root.
    size: number;

    constructor() {
        this.entryClass = EntryClass.File;
        this.entities = [];
        this.size = 0;
    }

    static parse(serverResponse): DocumentEntry {
        const entry = new DocumentEntry();

        if (serverResponse.hasOwnProperty('link')) {
            entry.fileName = serverResponse.link.phid;
            entry.id = serverResponse.link.href.slice(-33, -1);
            serverResponse = serverResponse.data;
        } else {
            entry.fileName = serverResponse.filename;
            entry.id = serverResponse.id;
        }
        // to make sure the extension will be true extention or undefined
        if (entry.fileName.lastIndexOf('.') > -1) {
            entry.extension = entry.fileName.substring(entry.fileName.lastIndexOf('.') + 1);
        } else {
            // to handle the case which don't include extension in filename
            if (serverResponse && serverResponse.filename && serverResponse.filename.lastIndexOf('.') > -1) {
                entry.extension = serverResponse.filename.substring(serverResponse.filename.lastIndexOf('.') + 1);
            } else {
                entry.extension = '';
            }
        }

        if (serverResponse) {
            // handle the case that only has "link" node but no "data" node
            entry.displayDate = serverResponse['display-date'];
            entry.submittedDate = serverResponse['submitted-date'];
            entry.lastEditDate = serverResponse['last-edited-date'];
            entry.editable = serverResponse.editable;
            entry.deletable = serverResponse.deletable;
            entry.serverFileName = serverResponse['serverfilename'];
            entry.fileDataLink = serverResponse.filedata.link.href;
            entry.isEmbeddedImg = serverResponse['is-embedded-image'] === 'true';
            if (serverResponse.thread.data) {
                entry.threadId = serverResponse.thread.data.id;
            } else {
                entry.threadId = serverResponse.thread.link.phid;
            }
            if (serverResponse.entities && serverResponse.entities.data) {
                entry.entities = serverResponse.entities.data.map(item => EntityBrief.parse(item.data));
            }
            if (serverResponse['entry-type']) {
                entry.type = EntryType.parse(serverResponse['entry-type']);
            }
            if (serverResponse.source && serverResponse.source.data) {
                entry.source = EntityBrief.parse(serverResponse.source.data);
            }
            if (serverResponse.submitter && serverResponse.submitter.data) {
                entry.submitter = EntityBrief.parse(serverResponse.submitter.data);
            }
            entry.sentiment = serverResponse.sentiment;
            entry.priority = serverResponse.priority;
            if (serverResponse.size) {
                entry.size = serverResponse.size;
            }
        }

        return entry;
    }

}
