/**
 * Created by Abner Sui on 05/27/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FileHelperService } from '../../services/file-helper.service';
import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';

@Component({
    selector: 'tam-download-aggrid',
    templateUrl: './download-aggrid.component.html',
    styleUrls: ['./download-aggrid.component.scss']
})
export class DownloadAggridComponent implements ICellRendererAngularComp {

    private _data: DocumentEntry;

    constructor(
        private _fileHelper: FileHelperService,
    ) { }

    agInit(params: any): void {
        this._data = params.data;
    }

    onClick(event) {
        const fileUrl = this._data.fileDataLink + this._data.id;
        this._fileHelper.downloadFile(fileUrl);
    }

    refresh(): boolean {
        return false;
    }

}
