/**
 * created by Simon Zhao on 5/5/2022.
 * Description:
 * defines the base model related to workflow tamale automation feature.
 * ------ maintenance history ------
 */

/**
 * The base of automation related models.
 */
export abstract class AutomationModelBase {
    /**
    * generated by Tamale Web, 8 characters.
    */
    id: string;
    /**
    * the object type associated with current automation config.
    */
    objectType: 'entry' | 'entity' | 'relationship' | 'sideEntry';

    /**
    * action type
    */
    action: 'create' | 'update';

    /**
     * the content of this attribute is depended on the action type,
     * it's entry/entity/relationship Type ID when action is create,
     * and one real entry/entity object ID when action is update.
     */
    objectTypeID: string;

    /**
     * the properties are used to move task / automation
     */
    name?: string; // show the name on the context menu to move the automation
    enable?: boolean; // whehter the automation can be moved or not
    attachedNodeId?: string; // get the original attached node id

    /**
     * Sync property values from the source model to the target model.
     * @param src the source automation model instance.
     * @param tar the target automation model instance.
     */
    static syncTo(src: AutomationModelBase, tar: AutomationModelBase) {
        Object.keys(src).forEach(prop => {
            if (Array.isArray(src[prop])) {
                tar[prop] = Array.from(src[prop]).slice();
            } else {
                tar[prop] = src[prop];
            }
        });
    }
}
