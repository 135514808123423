<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" [attr.tid]="config.tId">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon [ngClass]="" name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container body-3" tid="operator-content-container">
        <div class="operator" *ngIf="!config.hideOperators">
            <kendo-dropdownlist [(ngModel)]="defaultItem" [data]="operators" [textField]="'name'" [valueField]="'value'"
                style="width: 100%;" (valueChange)="valueChange($event)">
            </kendo-dropdownlist>
        </div>
        <div class="content filter-country" [class.disabled]="isCheckBlank">
            <ng-container *ngIf="!isCheckBlank">
                <kendo-combobox [(ngModel)]="selItemCountry" [data]="selectItemsCountry"
                    (valueChange)="onCountrySelect()" placeholder="country/region" [allowCustom]="false">
                </kendo-combobox>
                <kendo-combobox [(ngModel)]="selItemState" [data]="selectItemsState" (valueChange)="onStateSelect()"
                    [allowCustom]="false">
                </kendo-combobox>
            </ng-container>
        </div>
    </div>
</div>