/**
 * Created by Marcus Zhao on 8/25/19.
 * Description:
 * created time select for timepicker
 * ------ maintenance history ------
 * 11/15/2019 Marcus Zhao Add highlightForSelectTime() to judge time list.
 */

import { Component, OnInit, Input, ElementRef, Output, EventEmitter, SimpleChanges, OnChanges, HostListener, } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'time-select-dropdown',
    templateUrl: './time-select-dropdown.component.html',
    styleUrls: ['./time-select-dropdown.component.scss']
})
export class TimeSelectDropdownComponent implements OnInit, OnChanges {

    showSel = false;
    anchor: ElementRef;
    times: Array<string> = [];
    showList = false;

    @Input() disabled = false;
    @Input() readonly = false;
    @Output() clickss = new EventEmitter<any>();
    @Output() timeBlur = new EventEmitter<any>();
    @Output() timeModelChange: EventEmitter<any> = new EventEmitter();
    private _timeModel;

    constructor(private element: ElementRef) {
    }

    ngOnInit() {
        this.initTime();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.inputTime && changes.inputTime.currentValue !== changes.inputTime.previousValue) {
        }
    }

    @Input()
    get myModel() {
        return this._timeModel;
    }

    set myModel(value) {
        this._timeModel = value;
        this.timeModelChange.emit(value);
    }

    initTime() {
        const now = new Date();
        for (let i = 0; i < 48; i++) {
            const hour = Math.floor(i / 2);
            if (i % 2 === 1) {
                this.times.push(hour + ':30');
            } else {
                this.times.push(hour + ':00');
            }
        }
        this.showList = true;
    }

    @HostListener('document:click', ['$event'])
    clickSomewhere(event) {
        if (this.showSel && !event['field-selector']) {
            this.showSel = false;
        }
    }

    hourClick(event: Event) {
        event.stopPropagation();
    }

    showPopup(event: any) {
        event['field-selector'] = true;
        this.showSel = !this.showSel;
        this.highlightForSelectTime();
    }

    highlightForSelectTime() {
        setTimeout(() => {
            if (this._timeModel) {
                const timeArray = this._timeModel.split(':');
                const currentIndex = timeArray[1] >= 30 ? timeArray[0] * 2 + 1 : timeArray[0] * 2;
                const el = this.element.nativeElement.querySelector(`#timedropdown${currentIndex}`);
                if (el) {
                    this.element.nativeElement.querySelector('.checkContainer').scrollTop = el.offsetTop;
                }
            }
        });
    }

    inputBlur() {
        this.timeBlur.emit(this._timeModel);
    }

    changeCheckedState(item: string, event) {
        event['field-selector'] = true;
        this.showSel = false;
        this._timeModel = item;
        this.clickss.emit(this._timeModel);

    }
}


