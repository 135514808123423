/**
 * Created by Yu Zhang on 12/1/17.
 * Description:
 *      implement the methods in each platform folder and use Angular provider to provide the right instance
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';

@Injectable()
export class ILoggerInstanceService {
    log(name, msg, category?) { }
    info(name, msg, category?) { }
    warn(name, msg, category?) { }
    error(name, msg, category?) { }
    debug(name, msg, category?) { }
}
