/**
 * created by Simon Zhao on 4/14/2022.
 * Description:
 * provides utilities for type determination and type conversion.
 * ------ maintenance history ------
 */
import { KeyValue } from '@angular/common';

export class TypeUtilities {
    /**
     * converts an array to an object.
     * @param m source map
     * @returns an object including all array members.
     */
    static convertArrayToObj<vT>(m: Array<KeyValue<string, vT>>): object {
        const obj = {};
        m.forEach((it: KeyValue<string, vT>) => {
            obj[it.key] = it.value;
        });

        return obj;
    }

    /**
     * converts an object to an array.
     * @param obj source object
     * @returns an array including all properties on the source object
     */
    static convertObjToArray<vT>(obj: object): Array<KeyValue<string, vT>> {
        const tarArray = [];
        Object.keys(obj).forEach((k: string) => tarArray.push({ key: k, value: obj[k] }));

        return tarArray;
    }

    /**
     * determines whether the given object is valid.
     * @param obj the given object
     * @returns a flag indicating whether the given object is valid(at least one member)
     */
    static isValidObj(obj: object): boolean {
        return obj && Object.keys(obj).length > 0;
    }

    /**
     * determines whether the given object is a number.
     * @param n the given object to determine
     * @returns  flag indicating whether the given object is a number
     */
    static isNumber(n: any): boolean {
        return n && typeof(n) === 'number';
    }

    /**
     * converts a hex color value into rgb value.
     * @param hex The color value in hexadecimal.
     * @returns The color value with rgb functionn call.
     */
    static hexToRGB(hex: string) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        // tslint:disable-next-line: no-shadowed-variable
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const r = parseInt(result[1], 16);
        const g = parseInt(result[2], 16);
        const b = parseInt(result[3], 16);
        return `rgb(${Math.round(r * 0.7)},${Math.round(g * 0.7)},${Math.round(b * 0.7)})`;
    }

    static isString(obj: any): boolean {
        return typeof(obj) === 'string';
    }
}
