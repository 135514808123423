/**
 * Created by Abner Sui on 06/05/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
const BLANK_VALUE = '(Blanks)';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    plusOneResultMap: Map<string, string> = new Map();

    /**
     *  prevents further propagation of the current event in the capturing and bubbling phases.
     * @param event the event context.
     */
    static stopPropagation(event: any) {
        if (event) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (event.stopPropagation) {
                event.stopPropagation();
            }
        }
    }

    constructor() {
        this.plusOneResultMap.set('0', '1');
        this.plusOneResultMap.set('1', '2');
        this.plusOneResultMap.set('2', '3');
        this.plusOneResultMap.set('3', '4');
        this.plusOneResultMap.set('4', '5');
        this.plusOneResultMap.set('5', '6');
        this.plusOneResultMap.set('6', '7');
        this.plusOneResultMap.set('7', '8');
        this.plusOneResultMap.set('8', '9');
        this.plusOneResultMap.set('9', '0');
    }

    emptyClick(event) {
        if (event) {
            if (event.preventDefault) {
                event.preventDefault();
            }
            if (event.stopPropagation) {
                event.stopPropagation();
            }
        }
    }

    /**
     * Export the given grid as an XLS file.
     * @param gridApi The grid API.
     * @param fileName String to use as the file name. If missing, the file name 'export.csv' will be used.
     * @param customHeader Content to put at the top of the file export. A 2D array of ExcelCell objects, see Custom Headers and Footers below. Alternatively, if you're exporting to CSV only, you can pass a multi-line string that is simply appended to the top of the file content.
     * @param sheetName The only sheet name of the exported excel file.
     * @param skipHeader Set to true if you don't want the first line to be the column header names. Default: false
     */
    exportToXLS(columnApi: any, gridColDefs: Array<any>, gridApi: any, sheetName: string = 'Sheet1', fileName: string) {
        const columns = columnApi.getColumnState();
        const groupColumn = columns.filter(item => item.rowGroupIndex !== null);
        const groupColumnSettings = [];
        const customHeader = [];
        const allCustomColumns = this._initGridHeader(columnApi);
        groupColumn.forEach(column => {
            const index = gridColDefs.findIndex(item => item.guid === column.colId);
            if (index !== -1) {
                groupColumnSettings.push({ ...gridColDefs[index], rowGroupIndex: column.rowGroupIndex });
            }
        });
        groupColumnSettings.sort((a, b) => a.rowGroupIndex - b.rowGroupIndex);
        allCustomColumns.forEach(column => {
            customHeader.push({
                data: {
                    type: 'String',
                    value: column.name,
                },
                styleId: 'font_size10'
            });
        });
        const paramsForExport = {
            fileName: fileName,
            sheetName: sheetName,
            skipColumnHeaders: false,
            processCellCallback: (param) => {
                if (typeof param.value === 'string' && !param.value) {
                    return '';
                }
                if (param.node.group) {// to handle group
                    if (typeof param.value === 'string' && param.value) {
                        const values = param.value.trim().split('->');
                        values.splice(0, 1);
                        let value = '';
                        if (values.length === 0) {
                            value = `-> ${BLANK_VALUE}`;
                        } else {
                            values.forEach((item, i) => {
                                const tempValue = item.trim();
                                value = `${value} -> ${tempValue || BLANK_VALUE}`;
                            });
                        }
                        return value;
                    }
                }
                if (param.column.colDef.cellRenderer) {
                    const data = param.column.colDef.cellRenderer(param);
                    return data;
                }
                return param.value;
            }
        };
        gridApi.exportDataAsExcel(paramsForExport);
    }

    formatNumberStr(str: string, decimals: number, zeroize: boolean, separator: boolean) {
        const reg = /(\d)(?=(?:\d{3})+$)/g;
        const strNums = str.split('.');
        if (strNums.length === 2) {
            let decimal = strNums[1];
            const decimalLen = decimal.length;
            let plus = false;
            if (decimalLen < decimals) {
                if (zeroize) {
                    for (let i = 0; i < decimals - decimalLen; i++) {
                        decimal += '0';
                    }
                }
            } else {
                decimal = (+`0.${decimal}`).toFixed(decimals);
                if (+decimal >= 1) {
                    plus = true;
                }
                decimal = decimal.slice(decimal.indexOf('.') + 1);
            }
            if (plus) {
                str = this.plusNForIntegerString(strNums[0], 1);
            } else {
                str = strNums[0];
            }
            if (separator) {
                str = str.replace(reg, '$1,');
            }
            if (decimals > 0) {
                str = str + '.' + decimal;
            }
        } else {
            if (separator) {
                str = str.replace(reg, '$1,');
            }
            if (decimals > 0) {
                let decimal = '';
                if (zeroize) {
                    decimal = '.';
                    for (let i = 0; i < decimals; i++) {
                        decimal += '0';
                    }
                }
                str = str + decimal;
            }
        }
        return str;
    }

    getBackgroundColorById(id: string): string {
        let result = 'rgb(';
        let rgb: number = Math.abs(this.hashcode(id.substring(0, 10)));
        rgb = rgb % 256;
        result += rgb + ',';
        rgb = Math.abs(this.hashcode(id.substring(10, 20)));
        rgb = rgb % 256;
        result += rgb + ',';
        rgb = Math.abs(this.hashcode(id.substring(20, 30)));
        rgb = rgb % 256;
        result += rgb + ')';
        return result;
    }

    hashcode(str: string): number {
        let hash = 0;
        if (!str) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            const chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    /**
     * param value
     * param other
     */
    isEqual(value: any, other: any, ignoreArrayOrder: boolean = false): boolean {
        const t1 = typeof value;
        const t2 = typeof other;
        if (t1 === t2) {
            switch (t1) {
                case 'object':
                    if (value === null) {
                        return other === null;
                    } else if (value instanceof Date) {
                        return other instanceof Date && value.getTime() === other.getTime();
                    } else if (Array.isArray(value)) { // Array
                        if (ignoreArrayOrder) {
                            if (Array.isArray(other)) {
                                if (value.length === other.length) {
                                    let result = true;
                                    const usedIndex: Set<number> = new Set();
                                    for (let i = 0; i < value.length; i++) {
                                        let hasTargetValue = false;
                                        for (let j = 0; j < value.length; j++) {
                                            if (!usedIndex.has(j) && this.isEqual(value[i], other[j], ignoreArrayOrder)) {
                                                usedIndex.add(j);
                                                hasTargetValue = true;
                                                break;
                                            }
                                        }
                                        if (!hasTargetValue) {
                                            result = false;
                                            break;
                                        }
                                    }
                                    return result;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        } else {
                            if (Array.isArray(other)) {
                                if (value.length === other.length) {
                                    let result = true;
                                    for (let i = 0; i < value.length; i++) {
                                        if (!this.isEqual(value[i], other[i], ignoreArrayOrder)) {
                                            result = false;
                                            break;
                                        }
                                    }
                                    return result;
                                } else {
                                    return false;
                                }
                            } else {
                                return false;
                            }
                        }
                    } else { // Object
                        const keys1 = Object.keys(value);
                        const keys2 = Object.keys(other);
                        if (keys1.length === keys2.length) {
                            let result = true;
                            for (let i = 0; i < keys1.length; i++) {
                                if (!this.isEqual(value[keys1[i]], other[keys1[i]], ignoreArrayOrder)) {
                                    result = false;
                                    break;
                                }
                            }
                            return result;
                        } else {
                            return false;
                        }
                    }
                case 'string':
                case 'number':
                case 'boolean':
                case 'undefined':
                    return value === other;
                default: // Do not support 'symbol', 'function' right now
                    return false;
            }
        } else {
            return false;
        }
    }

    /**
     * (3, 4, true) will get 30000; (3, 4, false) will get 0.0003.
     * param value
     * param power
     * param isMultiply
     */
    multiplyPowerX10ForString(value: string, power: number = 2, isMultiply: boolean = true): string {
        let result: string = value;
        if (power === 0) {
            return result;
        }
        if (power < 0) {
            isMultiply = !isMultiply;
            power = -power;
        }
        let prefix = '';
        if (value.startsWith('-')) {
            prefix = '-';
            value = value.substring(1);
        }
        const decimalIndex: number = value.indexOf('.');
        let integer: string;
        let decimal: string;
        if (isMultiply) {
            if (decimalIndex > -1) {
                integer = value.substring(0, decimalIndex);
                decimal = value.substring(decimalIndex + 1);
            } else {
                integer = value.toString();
                decimal = '';
            }
            for (let i = 0; i < power; i++) {
                decimal = decimal + '0';
            }
            integer = integer + decimal.substring(0, power);
            decimal = decimal.substring(power);
            let tempDecimal = '';
            for (let i = decimal.length - 1; i > 0; i--) {
                if (+decimal[i] === 0 && +decimal[i - 1] !== 0) {
                    tempDecimal = '.' + decimal.substring(0, i);
                    break;
                }
            }
            result = prefix + integer + tempDecimal;
        } else {
            if (decimalIndex > -1) {
                integer = value.substring(0, decimalIndex);
                decimal = value.substring(decimalIndex + 1);
            } else {
                integer = value.toString();
                decimal = '';
            }
            for (let i = 0; i < power; i++) {
                integer = '0' + integer;
            }
            decimal = integer.substring(integer.length - power) + decimal;
            integer = integer.substring(0, integer.length - power);
            let tempInteger = '0';
            for (let i = 0; i < integer.length - 1; i++) {
                if (+integer[i] === 0 && +integer[i + 1] !== 0) {
                    tempInteger = integer.substring(i + 1);
                    break;
                }
            }
            result = prefix + tempInteger + '.' + decimal;
        }
        return result;
    }

    plusNForIntegerString(num: string, n: number): string {
        const isNegative = num.startsWith('-');
        const array = num.split('').reverse();
        if (isNegative) {
            array.pop();
        }
        for (let j = 0; j < n; j++) {
            const len = array.length;
            let plus = true;
            for (let i = 0; i < len; i++) {
                const oneDigit = array[i];
                array[i] = this.plusOneResultMap.get(oneDigit);
                if (oneDigit === '9') {
                    plus = true;
                    continue;
                } else {
                    plus = false;
                    break;
                }
            }
            if (plus) {
                array.push('1');
            }
        }
        let result = array.reverse().join('');
        if (isNegative) {
            result = '-' + result;
        }
        return result;
    }

    sort(a: string, b: string): number {
        const alow = a.toLowerCase();
        const blow = b.toLowerCase();
        return alow === blow ? 0 : (alow > blow ? 1 : -1);
    }

    /**
     * parse 1.23e+4 to 12300; paser scientific notation to no notation.
     * param value
     */
    wipeOffScientificNotation(value: string): string {
        const snIndex: number = value.indexOf('e');
        if (snIndex > 0 && value.length > snIndex + 2) {
            const isPositiveSN: boolean = value[snIndex + 1] === '+';
            const power: number = +value.substring(snIndex + 2);
            let numberPart: string = value.substring(0, snIndex);
            if (isPositiveSN) {
                numberPart = this.multiplyPowerX10ForString(numberPart, power);
            } else {
                numberPart = this.multiplyPowerX10ForString(numberPart, power, false);
            }
            return numberPart;
        } else {
            return value;
        }
    }

    private _initGridHeader(gridColumnApi) {
        const infoColumns = gridColumnApi.getColumns();
        const allColumns = gridColumnApi.getColumnState();
        const tempColumns = [];
        const pinnedLeftColumns = [];
        const pinnedRightColumns = [];
        const noPinnedColumns = [];
        let columns = [];
        allColumns.forEach((column, i) => {
            if (!column.hide) {
                if (column.pinned === 'left') {
                    pinnedLeftColumns.push(column);
                } else if (column.pinned === 'right') {
                    pinnedRightColumns.push(column);
                } else {
                    noPinnedColumns.push(column);
                }
            }
        });
        columns = [].concat(pinnedLeftColumns, noPinnedColumns, pinnedRightColumns);
        columns.forEach((column) => {
            const index = infoColumns.findIndex(item => item.colId === column.colId);
            if (index !== -1) {
                tempColumns.push({
                    name: infoColumns[index].colDef.headerName,
                    width: column.width,
                    id: column.colId
                });
            }
        });
        return tempColumns;
    }
}
