import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IntralinksMappingDialogParam } from './intralinks-mapping-dialog.view-model';

@Injectable()
export class IntralinksMappingDialogService {

    open$: Subject<any> = new Subject();

    constructor() { }

    open(param: IntralinksMappingDialogParam) {
        this.open$.next(param);
    }
}
