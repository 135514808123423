import { Component, OnInit, Input, HostBinding, HostListener, Output, EventEmitter, OnChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'tam-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RadioComponent),
        multi: true
    }]
})
export class RadioComponent implements ControlValueAccessor, OnInit {
    @HostBinding('class.checked') get hostChecked(): boolean { return this.model === this.value; }
    @HostBinding('class.disabled') get hostDisabled(): boolean { return this.disabled; }
    private model: string;
    @Input() disabled: boolean;
    @Input() value: string;
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() focus = this.change;
    constructor() { }

    ngOnInit() {
    }

    @HostListener('click', [])
    handleClick(): void {
        if (!this.disabled) {
            this.writeValue(this.value);
        }
    }

    writeValue(value: any) {
        if (this.model === this.value && this.model !== value) {
            this.blur.emit();
        }
        this.model = value;
        this.onChange(value);
        if (value === this.value) {
            this.change.emit(value);
        }
    }

    onChange = (value: any) => { };
    onTouched = () => { };
    registerOnChange(fn: (_: any) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

}
