import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { parseScript } from 'esprima';
import { Field, Config, FormulaBuilderEventType } from './formula-builder.model';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { FormulaService } from '../../services/formula-service';
import { FormulaBuilderService } from './formula-builder.service';

@Component({
    selector: 'tam-formula-builder',
    templateUrl: './formula-builder.component.html',
    styleUrls: ['./formula-builder.component.scss']
})
export class FormulaBuilderComponent implements OnInit, OnDestroy {

    config: Config;
    dialogOpened = false;
    selectedIndex = 0;
    functions: any[];
    funcSyntax: string;
    funcIntroduction: string;
    formula: string;
    hasFormulaError: boolean;
    formulaSyntaxErrorMessage: string;
    fields: Array<Field> = [];
    initTabSet = false;

    private _cursorIndex: number;
    private _destroySubscriptions: Array<Subscription> = [];
    private _formulaForData: string;
    private _keys: string[] = [];
    private _isConditionalFormatting = false;

    @ViewChild('textarea', { static: false }) textarea: ElementRef;
    constructor(
        private _alertWindow: AlertWindowService,
        private _formulaService: FormulaService,
        private _dialogService: FormulaBuilderService) { }

    ngOnInit() {
        this._destroySubscriptions.push(
            this._dialogService._open$.subscribe((config) => {
                this.config = config;
                this.initPage();
                this.dialogOpened = true;
                setTimeout(() => {
                    this.initTabSet = true;
                });
            }),
            this._dialogService._error$.subscribe((error) => {
                this.formulaSyntaxErrorMessage = 'Invalid formula error.';
                this.hasFormulaError = true;
            }),
            this._dialogService._close$.subscribe((error) => {
                this.dialogOpened = false;
                this.initTabSet = false;
            }),
        );
        this.functions = this._formulaService.getAll();
        this.functions.forEach((data) => { data.name = data.type; });
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    initPage() {
        this.formula = this.config.formula || '';
        this.fields = this.config.fields || [];
        this._cursorIndex = this.formula.length;
        this._formulaForData = this.config.formulaForData || '';
        this._isConditionalFormatting = this.config.isConditionalFormatting;
        this.funcSyntax = '';
        this.funcIntroduction = '';
        this.hasFormulaError = false;
        this.formulaSyntaxErrorMessage = '';
    }

    onClose() {
        const cancelFunc = () => {
            this.selectedIndex = 0;
            this._dialogService._result$.next({
                type: FormulaBuilderEventType.cancel,
            });
        };
        if (this.formula !== this.config.formula) {
            const subscription = this._alertWindow.warn('You have unsaved changes', ['Are you sure you want to discard all your changes?'])
                .subscribe((result: boolean) => {
                    if (result) {
                        this.dialogOpened = false;
                        this.initTabSet = false;
                        cancelFunc();
                    }
                    subscription.unsubscribe();
                });
        } else {
            this.dialogOpened = false;
            this.initTabSet = false;
            cancelFunc();
        }
    }

    onSave() {
        // replace field name to guid
        let reg;
        this._formulaForData = this.formula;
        this.fields.forEach((item, key) => {
            reg = new RegExp('\\[\/\/' + item.title
                .replace(/\\/g, '\\\\')
                .replace(/\(/g, '\\(')
                .replace(/\)/g, '\\)')
                .replace(/\&/g, '\\&')
                .replace(/\[/g, '\\[')
                .replace(/\]/g, '\\]')
                .replace(/\{/g, '\\{')
                .replace(/\}/g, '\\}')
                .replace(/\\'/g, '\\\'>')
                .replace(/\\"/g, '\\\">')
                .replace(/\^/g, '\\^')
                .replace(/\*/g, '\\*')
                .replace(/\./g, '\\.')
                .replace(/\?/g, '\\?')
                .replace(/\//g, '\\/')
                .replace(/\+/g, '\\+')
                .replace(/\|/g, '\\|')
                .replace(/\$/g, '\\$') + '\\s*\\]', 'g');
            this._formulaForData = this._formulaForData.replace(reg, item.guid || '');
        });
        // after replaced all columns with guid, replace all = with == except in quoted string
        this._formulaForData = this._formulaForData
            .replace(/[^<>]=/g, function (replacement) {
                return replacement.replace(/=/, '==');
            })
            .replace(/\"(.*?)\"/g, function (replacement) {
                return replacement.replace(/==/g, '=');
            })
            .replace(/\'(.*?)\'/g, function (replacement) {
                return replacement.replace(/==/g, '=');
            });

        this._formulaForData = this._formulaService.handleSpecialSignForFormula(this._formulaForData, '<>', '!=');

        try {
            const formulaForErrorCheck = this._formulaService.handleForFormulaJS(this._formulaForData);
            parseScript(formulaForErrorCheck);
        } catch (e) {
            this.formulaSyntaxErrorMessage = e.description + ', line number: ' + e.lineNumber;
            this.hasFormulaError = true;
            return;
        }

        // add Formula prefix to function name
        this._formulaForData = this._formulaService.handleForFormulaJS(this._formulaForData);
        this.dialogOpened = this.config.validLoop;
        this._dialogService._result$.next({
            type: this._isConditionalFormatting ? FormulaBuilderEventType.saveConditionalFormatting : FormulaBuilderEventType.saveFormula,
            payload: {
                sourceId: this.config.sourceId,
                formula: this.formula,
                formulaForData: this._formulaForData
            }
        });
    }

    selectChange(index) {
        this.selectedIndex = index;
    }

    handleCollapse(node) {
        this._keys = this._keys.filter(k => k !== node.index);
    }

    handleExpand(node) {
        this._keys = this._keys.concat(node.index);
    }

    selectNode(event) {
        const node = event.item.dataItem;
        if (node.items && node.items.length > 0) {
            return;
        }
        this.hasFormulaError = false;
        this.funcSyntax = node.syntax || '';
        this.funcIntroduction = node.introduction || '';
    }

    inputFormula() {
        this.hasFormulaError = false;
        this._cursorIndex = this.formula.length;
    }

    dblclickField(field) {
        this.hasFormulaError = false;
        const textareaEl = this.textarea.nativeElement;
        const firstHalf = this.formula.substr(0, this._cursorIndex);
        const secondHalf = this.formula.substr(this._cursorIndex);
        this._cursorIndex += ('[//' + field.title + ']').length;
        this.formula = firstHalf + '[//' + field.title + ']' + secondHalf;
        textareaEl.focus();
        setTimeout(() => {
            const selectionStart = (firstHalf + '[//' + field.title + ']').length;
            const selectionEnd = (firstHalf + '[//' + field.title + ']').length;
            textareaEl.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    }

    dblClickNode(event) {
        const node = event.item.dataItem;
        if (node.items && node.items.length > 0) {
            return;
        }
        this.hasFormulaError = false;
        const textareaEl = this.textarea.nativeElement;
        const firstHalf = this.formula.substr(0, this._cursorIndex);
        const secondHalf = this.formula.substr(this._cursorIndex);
        this._cursorIndex += (node.name + '(').length;
        this.formula = firstHalf + node.name + '()' + secondHalf;
        textareaEl.focus();
        setTimeout(() => {
            const selectionStart = (firstHalf + node.name + '()').length - 1;
            const selectionEnd = (firstHalf + node.name + '()').length - 1;
            textareaEl.setSelectionRange(selectionStart, selectionEnd);
        }, 0);
    }

    showInfo() {
        const textareaEl = this.textarea.nativeElement;
        this._cursorIndex = textareaEl.selectionStart;
        const firstHalf = this.formula.substr(0, textareaEl.selectionStart);
        const arr = firstHalf.split('(');
        const rightSquares = firstHalf.match(/\)/g) ? firstHalf.match(/\)/g).length : 0;
        if (arr && arr.length - rightSquares > 1 && arr[arr.length - rightSquares - 2].length > 0) {
            const temp = arr[arr.length - rightSquares - 2];
            let name = '';
            const reg = new RegExp('[a-zA-Z0-9]');
            for (let m = temp.length - 1; m >= 0; m--) {
                if (reg.test(temp.substr(m, 1))) {
                    name = temp.substr(m, 1) + name;
                } else {
                    break;
                }
            }
            this.findFunction(name);
        }

    }

    findFunction(name) {
        if (name) {
            let found = false;
            for (let k = 0; k < this.functions.length; k++) {
                for (let l = 0; l < this.functions[k].items.length; l++) {
                    if (this.functions[k].items[l].name.toLowerCase() === name.toLowerCase()) {
                        this.funcSyntax = this.functions[k].items[l].syntax;
                        this.funcIntroduction = this.functions[k].items[l].introduction;
                        found = true;
                        break;
                    }
                }
                if (found) {
                    break;
                }
            }
        }
    }

    trackByFn(index) {
        return index;
    }
}
