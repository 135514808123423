/**
 * Created by Daniel on 05/14/2019.
 * Description:
 * dashboard actions
 * ------ maintenance history ------
*/
import { CustomAction } from './custom.actions';

export enum DashboardScreenActionTypes {
    // focus related
    INITIALIZE_FOCUS = '[DYNAMIC][DASHBOARD][FOCUS] INITIALIZE'
}

export class DashboardScreenInitializeFocus implements CustomAction {
    readonly type = DashboardScreenActionTypes.INITIALIZE_FOCUS;

    constructor(
        public prefix,
        public payload: any
    ) { }
}

export type DashboardScreenActions =
    // focus
    DashboardScreenInitializeFocus;
