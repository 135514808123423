<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" [attr.tid]="config.tId">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container" tid="operator-content-container">
        <div class="operator" [ngClass]="{'width-180': operator.value === 'range'}" *ngIf="!config.hideOperators">
            <kendo-dropdownlist [(ngModel)]="operator" [data]="operators" [textField]="'name'" [valueField]="'value'"
                style="width: 100%;" (valueChange)="valueChangeOperate($event)">
            </kendo-dropdownlist>
        </div>
        <div class="content" [class.disabled]="readonlySource">
            <div [hidden]="readonlySource" [style.display]="readonlySource?'none':'flex'" style="width: 100%;">
                <kendo-multiselect #entitylist [data]="dataItems" [filterable]="true"
                    (filterChange)="handleFilter($event)" [textField]="'shortName'" [valueField]="'id'"
                    [(ngModel)]="selectedItems" (valueChange)="valueChange()">
                    <ng-template kendoMultiSelectItemTemplate let-dataItem>
                        <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
                    </ng-template>
                </kendo-multiselect>
            </div>
        </div>
    </div>
</div>