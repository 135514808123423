/**
 * Created by Jiu Chen on 2/26/2019.
 * Description:
 *	pure filter items for reusable.
 *
 * ------ maintenance history ------
 */
import { Subject } from 'rxjs';
import { FiltersConfig } from '../../../tamalelibs/components/filter-config';

export enum FilterPanelEvents {
    delete,
    change,
}

export enum FilterPanelActions {
    addFilter,
    resetFilter,
    reloadFilter,
    restrictFilter
}

export class FilterPanelConfig {
    filtersConfigList: Array<FiltersConfig>;
    isShowDynamicDate?: boolean;
    isHiddenTrashIcon?: boolean;
    action$: Subject<{ type: FilterPanelActions, payload?: any }>;
    events$: Subject<{ type: FilterPanelEvents, payload?: any, changedItem?: any }>;

    constructor() {
        this.filtersConfigList = [];
        this.isShowDynamicDate = false;
        this.isHiddenTrashIcon = false;
        this.events$ = new Subject();
        this.action$ = new Subject();
    }
}
