/**
 * Created by Yu Zhang on 3/10/17.
 * Description:
 * ------ maintenance history ------
 * Updated by Daniel Wang on 4/18/2024 Moved web token des key to server from UI
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';

import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';
import * as moment from 'moment';
import 'moment-timezone';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../redux/app.state';
import { take } from 'rxjs/operators';
import { AppLoadService } from '../../app-load.service';

@Injectable()
export class AuthService {
    constructor(
        private _appLoadService: AppLoadService,
        private _store: Store<IAppState>,
        private _transportService: TransportService) {
    }

    decryptPassword(encrypted) {
        const key = CryptoJS['enc'].Utf8.parse(this._appLoadService.getWebTokenDesKey);
        const plain = CryptoJS['DES'].decrypt(encrypted, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS['pad'].Pkcs7
        });
        return plain.toString(CryptoJS.enc.Utf8);
    }

    encryptPassword(password) {
        return this._encryptPassword(password);
    }

    encryptPasswordForWeb(password, val) {
        const key = CryptoJS['enc'].Utf8.parse(val);
        const encrypted = CryptoJS['DES'].encrypt(password, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS['pad'].Pkcs7
        }).toString();
        return encrypted;
    }

    /**
     * Since encryptPassword has been used by iphone and web, create a new method for common usage.
     * @param str
     * @returns
     */
    encryptString(str) {
        const key = CryptoJS['enc'].Utf8.parse(this._appLoadService.getWebTokenDesKey);
        const encrypted = CryptoJS['DES'].encrypt(str, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS['pad'].Pkcs7
        }).toString();
        return encrypted;
    }

    getExistedTokenFromServer(emailAddress: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('secureEmail', this.encryptString(emailAddress));
        const url = `${AppConfig.tokenServerEndpoint}`;
        return this._transportService.post(url, formData.toString(), options);
    }

    isTokenValid(token): Observable<any> {
        const url = '/restapi/2.0/token/' + token;
        return this._transportService.get(url);
    }

    login(username, password, isForPlugin): Observable<any> {
        // encrypt password before sending out the request
        const key = CryptoJS['enc'].Utf8.parse(this._appLoadService.getWebTokenDesKey);
        const encrypted = this.encryptPassword(password);
        const headers = {
            'Content-Type': 'application/json',
            'username': username,
            'Authorization': 'Basic ' + encrypted,
            'SameSite': 'None;Secure'
        };
        let url = `${AppConfig.tokenEndpoint}?outputformat=json`;
        if (isForPlugin) {
            url = `${AppConfig.tokenEndpointPlugin}?outputformat=json`;
        } else {
            headers['timezoneid'] = moment.tz.guess();
        }

        return this._transportService.post(url, null, {
            headers: headers
        });
    }

    logout(token): Observable<any> {
        const url = '/restapi/2.0/token/' + token;
        return this._transportService.delete(url);
    }

    /**
    * to get mdl token des key from server
    * @returns the mdl token des key
    */
    mdlTokenDesKey(): string {
        let systemConfig = null;
        this._store.pipe<string>(select('systemConfig')).pipe(take(1)).subscribe(data => systemConfig = data);
        return systemConfig.mdlEncryptDesKey;
    }

    private _encryptPassword(password) {
        const key = CryptoJS['enc'].Utf8.parse(this._appLoadService.getWebTokenDesKey);
        const encrypted = CryptoJS['DES'].encrypt(password, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS['pad'].Pkcs7
        }).toString();
        return encrypted;
    }
}
