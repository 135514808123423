/**
 * Created by Yu Zhang on 3/14/18.
 * Description:
 *
 * ------ maintenance history ------
 * Update by Marcus Zhao 11/3/2022 use img to show svg file in IE 11.
 */
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AddInUtilities } from '../../services/utilities/addin-utilities';
import { requirementSetVersion } from '../../components/note-dialog/note-dialog.model';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'smart-icon',
    templateUrl: './smart-icon.component.html',
    styleUrls: ['./smart-icon.component.scss']
})
export class SmartIconComponent implements OnInit {
    iconLink: string;
    @Input() name: Observable<string>;

    @Input() iconClassList = 'icon';

    constructor(
        // @Attribute('name') public name: string
    ) { }

    ngOnInit() {

    }

    getHref(): string {
        return `./assets/tamale.svg#icons-blk-${this.name}`;
    }

    getIcon(): string {
        return `./assets/images/singlesvg/${this.name}.svg`;
    }

    isUseSmartIcon() {
        if (AddInUtilities.isOfficeEnvironment() && !AddInUtilities.isOfficeSupportedVersion(requirementSetVersion.version1_8)) {
            return false;
        } else {
            return true;
        }
    }
}
