<div class="container tam-form-field height-30" [ngClass]="{'has-error':requireInvalid}">
    <div class="tam-form-label label-area" [title]="config.field.fieldDefinition.type">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name"></field-label>
    </div>
    <kendo-datepicker #datepicker calendarType="classic" [popupSettings]="{appendTo:'component'}"
        [ngClass]="{'field-disabled':config.disabled||!config.editable}" class="tam-textbox field-control"
        [disabled]="!config.editable||config.disabled" [(value)]="config.field.value" [rangeValidation]="false"
        (valueChange)="valueChange($event)">
    </kendo-datepicker>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
</div>