/**
 * Created by Todd Yu on 06/20/2020.
 * Description:
 * All actions for the admin pages
 * ------ maintenance history ------
 * Modified by Cathy Yao on 09/9/2021:add delete user function
 */

import { Action } from '@ngrx/store';
import { SystemCredential } from '../../tamalelibs/models/user.model';
import { User } from '../../tamalelibs/models/user.model';

export enum AdminPageActionTypes {
    // note
    UPDATE_DEFAULT_SIDENOTE_TYPE = 'UPDATE_DEFAULT_SIDENOTE_TYPE', // change default sidenote type
    UPDATE_DEFAULT_SIDENOTE_TYPE_SUCCESS = 'UPDATE_DEFAULT_SIDENOTE_TYPE_SUCCESS',
    UPDATE_DEFAULT_SIDENOTE_TYPE_FAILED = 'UPDATE_DEFAULT_SIDENOTE_TYPE_FAILED',

    // note types
    GET_NOTE_TYPES = 'get-note-types',
    CREATE_NEW_NOTE_TYPE = 'create-new-note-type',
    GET_NOTE_TYPES_SUCCESS = 'get-note-types-success',
    CREATE_NOTE_TYPE_SUCCESS = 'create-note-type-success',
    DELETE_NOTE_TYPE = 'delete-note-type',
    DELETE_NOTE_TYPE_SUCCESS = 'delete-note-type-success',
    EDIT_NOTE_TYPE = 'edit-note-type',
    EDIT_NOTE_TYPE_SUCCESS = 'edit-note-type-success',
    EDIT_DEFAULT_NOTE = 'edit-default-note',
    EDIT_DEFAULT_NOTE_SUCCESS = 'edit-default-note-success',
    TOGGLE_NOTE_DISPLAY = 'toggle-note-display',
    TOGGLE_NOTE_DISPLAY_SUCCESS = 'toggle-note-display-success',
    // entity types
    GET_ENTITY_TYPES = 'get-entity-types',
    GET_ENTITY_TYPES_SUCCESS = 'get-entity-types-success',
    CREATE_NEW_ENTITY_TYPE = 'create-new-entity-type',
    CREATE_NEW_ENTITY_TYPE_SUCCESS = 'create-new-entity-type-success',
    EDIT_ENTITY_TYPE = 'edit-entity-type',
    EDIT_ENTITY_TYPE_SUCCESS = 'edit-entity-type-success',
    DELETE_ENTITY_TYPE = 'delete-entity-type',
    DELETE_ENTITY_TYPE_SUCCESS = 'delete-entity-type-success',
    EDIT_DEFAULT_ENTITY = 'edit-default-entity',
    EDIT_DEFAULT_ENTITY_SUCCESS = 'edit-default-entity-success',
    TOGGLE_ENTITY_DISPLAY = 'toggle-entity-display',
    TOGGLE_ENTITY_DISPLAY_SUCCESS = 'toggle-entity-display-success',
    // users
    GET_USERS = 'get-users',
    CREATE_NEW_USER = 'create-new-user',
    GET_USERS_SUCCESS = 'get-users-success',
    CREATE_USER_SUCCESS = 'create-new-user-success',
    EDIT_USER = 'edit-user',
    EDIT_USER_SUCCESS = 'edit-user-success',
    DELETE_USERS = 'delete-users',
    DELETE_USERS_SUCCESS = 'delete-users-success',
    TOGGLE_USER_DISPLAY = 'toggle-user-display',
    TOGGLE_USER_DISPLAY_SUCCESS = 'toggle-user-display-success',
    // system credentials
    GET_SYSTEM_CREDENTIALS = 'get-system-credentials',
    GET_SYSTEM_CREDENTIALS_SUCCESS = 'get-system-credentials-success',
    GET_SYSTEM_CREDENTIALS_FAILURE = 'get-system-credentials-failure',
    // admin shared page
    GET_SHARED_PAGE = '[ADMIN_PAGEE] GET_SHARED_PAGE',
    GET_SHARED_PAGE_SUCCESS = '[ADMIN_PAGEE] GET_SHARED_PAGE_SUCCESS',
    GET_SHARED_PAGE_FAILURE = '[ADMIN_PAGEE] GET_SHARED_PAGE_FAILURE',
    CREATE_SHARED_PAGE = '[ADMIN_PAGEE] CREATE_SHARED_PAGE',
    CREATE_SHARED_PAGE_SUCCESS = '[ADMIN_PAGEE] CREATE_SHARED_PAGE_SUCCESS',
    CREATE_SHARED_PAGE_FAILURE = '[ADMIN_PAGEE] CREATE_SHARED_PAGE_FAILURE',
    DELETE_SHARED_PAGE = '[ADMIN_PAGEE] DELETE_SHARED_PAGE',
    DELETE_SHARED_PAGE_SUCCESS = '[ADMIN_PAGEE] DELETE_SHARED_PAGE_SUCCESS',
    DELETE_SHARED_PAGE_FAILURE = '[ADMIN_PAGEE] DELETE_SHARED_PAGE_FAILURE',
    EDIT_SHARED_PAGE = '[ADMIN_PAGEE] EDIT_SHARED_PAGE',
    EDIT_SHARED_PAGE_SUCCESS = '[ADMIN_PAGEE] EDIT_SHARED_PAGE_SUCCESS',
    EDIT_SHARED_PAGE_FAILURE = '[ADMIN_PAGEE] EDIT_SHARED_PAGE_FAILURE',
    // admin general page
    GET_GENERAL_PAGE = '[ADMIN_PAGEE] GET_GENERAL_PAGE',
    GET_GENERAL_PAGE_SUCCESS = '[ADMIN_PAGEE] GET_GENERAL_PAGE_SUCCESS',
    GET_GENERAL_PAGE_FAILURE = '[ADMIN_PAGEE] GET_GENERAL_PAGE_FAILURE',
    CREATE_GENERAL_PAGE = '[ADMIN_PAGEE] CREATE_GENERAL_PAGE',
    CREATE_GENERAL_PAGE_SUCCESS = '[ADMIN_PAGEE] CREATE_GENERAL_PAGE_SUCCESS',
    CREATE_GENERAL_PAGE_FAILURE = '[ADMIN_PAGEE] CREATE_GENERAL_PAGE_FAILURE',
    DELETE_GENERAL_PAGE = '[ADMIN_PAGEE] DELETE_GENERAL_PAGE',
    DELETE_GENERAL_PAGE_SUCCESS = '[ADMIN_PAGEE] DELETE_GENERAL_PAGE_SUCCESS',
    DELETE_GENERAL_PAGE_FAILURE = '[ADMIN_PAGEE] DELETE_GENERAL_PAGE_FAILURE',
    EDIT_GENERAL_PAGE = '[ADMIN_PAGEE] EDIT_GENERAL_PAGE',
    EDIT_GENERAL_PAGE_SUCCESS = '[ADMIN_PAGEE] EDIT_GENERAL_PAGE_SUCCESS',
    EDIT_GENERAL_PAGE_FAILURE = '[ADMIN_PAGEE] EDIT_GENERAL_PAGE_FAILURE',
    // misc
    GENERAL_FAILURE = 'general-failure',
    CLEAR_STATUS = 'clear-status'
}

export class UpdateDefaultSidenoteType implements Action {
    readonly type = AdminPageActionTypes.UPDATE_DEFAULT_SIDENOTE_TYPE;
    constructor(
        public payload: any
    ) { }
}

export class UpdateDefaultSidenoteTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.UPDATE_DEFAULT_SIDENOTE_TYPE_SUCCESS;
    constructor(
        public payload: any
    ) { }
}

export class UpdateDefaultSidenoteTypeFailed implements Action {
    readonly type = AdminPageActionTypes.UPDATE_DEFAULT_SIDENOTE_TYPE_FAILED;
    constructor(
        public payload: any
    ) { }
}

/** Called upon navigating to the note types page, retrieves all note types from server */
export class NavNoteTypes implements Action {
    readonly type = AdminPageActionTypes.GET_NOTE_TYPES;

}

/** Returned by getNoteTypesEffect$ after successfully retrieving note types from server */
export class NavNoteTypesSuccess implements Action {
    readonly type = AdminPageActionTypes.GET_NOTE_TYPES_SUCCESS;

    constructor(public payload: any) { }
}

/** Create a new note type */
export class CreateNewNoteType implements Action {
    readonly type = AdminPageActionTypes.CREATE_NEW_NOTE_TYPE;

    constructor(public payload: { name: string; data: any; successMessage: string; }) { }
}

/** Returned by effect upon successfully creating new note type */
export class CreateNewNoteTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.CREATE_NOTE_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

/** Delete a user with the given id */
export class DeleteUsers implements Action {
    readonly type = AdminPageActionTypes.DELETE_USERS;

    constructor(public payload: { id: string; data: any, successMessage: string; }) { }
}

/** Returned by effect upon successfully deleting users */
export class DeleteUsersSuccess implements Action {
    readonly type = AdminPageActionTypes.DELETE_USERS_SUCCESS;

    constructor(public payload: any) { }
}

/** Delete a note type with the given id */
export class DeleteNoteType implements Action {
    readonly type = AdminPageActionTypes.DELETE_NOTE_TYPE;

    constructor(public payload: { id: string; data: any, successMessage: string; }) { }
}

/** Returned by effect upon successfully deleting note type */
export class DeleteNoteTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.DELETE_NOTE_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

/** Edit a user with the given id */
export class EditUser implements Action {
    readonly type = AdminPageActionTypes.EDIT_USER;

    constructor(public payload: { contactId: string, user: User, successMessage: string }) { }
}

/** Returned by effect upon successfully editing note type */
export class EditUserSuccess implements Action {
    readonly type = AdminPageActionTypes.EDIT_USER_SUCCESS;

    constructor(public payload: any) { }
}

/** Edit a note type with the given id */
export class EditNoteType implements Action {
    readonly type = AdminPageActionTypes.EDIT_NOTE_TYPE;

    constructor(public payload: { name: string; id: string; data: any, successMessage: string; }) { }
}

/** Returned by effect upon successfully editing note type */
export class EditNoteTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.EDIT_NOTE_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

/** Change the default note type to the note with the specified id */
export class EditDefaultNoteType implements Action {
    readonly type = AdminPageActionTypes.EDIT_DEFAULT_NOTE;

    constructor(public payload: { name: string; id: string; data: any; defaultTypeField: string; successMessage: string; }) { }
}

/** Returned by effect upon successfully editing default note type */
export class EditDefaultNoteTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.EDIT_DEFAULT_NOTE_SUCCESS;

    constructor(public payload: { defaultTypeField: string; data: any; successMessage: string; }) { }
}

/** Change a note's 'display in TamaleWeb' status */
export class ToggleNoteDisplay implements Action {
    readonly type = AdminPageActionTypes.TOGGLE_NOTE_DISPLAY;

    constructor(public payload: { name: string; id: string; data: any, credentialField: string, successMessage: string; }) { }
}

/** Returned by effect upon successfully changing note type's 'display in TamaleWeb' status */
export class ToggleNoteDisplaySuccess implements Action {
    readonly type = AdminPageActionTypes.TOGGLE_NOTE_DISPLAY_SUCCESS;

    constructor(public payload: { credentialField: string; data: any, successMessage: string; }) { }
}

/** Called upon navigating to the entity types page, retrieves all entity types from server */
export class NavEntityTypes implements Action {
    readonly type = AdminPageActionTypes.GET_ENTITY_TYPES;

}

/** returned by getEntityTypesEffect upon successfully retrieving entity types from server */
export class NavEntityTypesSuccess implements Action {
    readonly type = AdminPageActionTypes.GET_ENTITY_TYPES_SUCCESS;

    constructor(public payload: any) { }
}

/** Create a new entity type */
export class CreateNewEntityType implements Action {
    readonly type = AdminPageActionTypes.CREATE_NEW_ENTITY_TYPE;

    constructor(public payload: { name: string; data: any, successMessage: string; }) { }
}

/** Returned by effect upon successfully creating a new entity type */
export class CreateNewEntityTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.CREATE_NEW_ENTITY_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

/** Delete an entity type with the given id */
export class DeleteEntityType implements Action {
    readonly type = AdminPageActionTypes.DELETE_ENTITY_TYPE;

    constructor(public payload: { id: string; data: any, successMessage: string; }) { }
}

/** Returned by effect upon successfully creating a new entity type */
export class DeleteEntityTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.DELETE_ENTITY_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

/** Edit an entity type with the given id */
export class EditEntityType implements Action {
    readonly type = AdminPageActionTypes.EDIT_ENTITY_TYPE;

    constructor(public payload: { name: string; id: string; data: any, successMessage: string; }) { }
}

/** Returned by effect upon successfully editing an entity type */
export class EditEntityTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.EDIT_ENTITY_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

/** Change the default entity type to be the entity type with the given id */
export class EditDefaultEntityType implements Action {
    readonly type = AdminPageActionTypes.EDIT_DEFAULT_ENTITY;

    constructor(public payload: { name: string; id: string; data: any; defaultTypeField: string; successMessage: string; }) { }
}

/** Returned by effect upon successfully changing the default entity type */
export class EditDefaultEntityTypeSuccess implements Action {
    readonly type = AdminPageActionTypes.EDIT_DEFAULT_ENTITY_SUCCESS;

    constructor(public payload: { defaultTypeField: string; data: any, successMessage: string; }) { }
}

/** Change a given entity's 'display in TamaleWeb' status */
export class ToggleEntityDisplay implements Action {
    readonly type = AdminPageActionTypes.TOGGLE_ENTITY_DISPLAY;

    constructor(public payload: { name: string; id: string; data: any; credentialField: string; successMessage: string; }) { }
}

/** Returned by effect upon successfully changing a given entity's 'display in TamaleWeb' status */
export class ToggleEntityDisplaySuccess implements Action {
    readonly type = AdminPageActionTypes.TOGGLE_ENTITY_DISPLAY_SUCCESS;

    constructor(public payload: { credentialField: string; data: any, successMessage: string; }) { }
}

/** Called upon navigating to the users page, currently retrieves current users */
export class NavUsers implements Action {
    readonly type = AdminPageActionTypes.GET_USERS;

}

/** Returned by effect upon successfully retrieving current user's users */
export class NavUsersSuccess implements Action {
    readonly type = AdminPageActionTypes.GET_USERS_SUCCESS;

    constructor(public payload: any) { }
}

/** Create a new user */
export class CreateNewUser implements Action {
    readonly type = AdminPageActionTypes.CREATE_NEW_USER;

    constructor(public payload: { contactId: string, user: User, successMessage: string }) { }
}

/** Returned by effect upon successfully creating new user */
export class CreateNewUserSuccess implements Action {
    readonly type = AdminPageActionTypes.CREATE_USER_SUCCESS;

    constructor(public payload: any) { }
}

/** Returned by effect upon any server failure response */
export class GeneralFailure implements Action {
    readonly type = AdminPageActionTypes.GENERAL_FAILURE;

    constructor(public payload: string) { }
}

/** Used by admin pages to clear failure/success status and reset state */
export class ClearStatus implements Action {
    readonly type = AdminPageActionTypes.CLEAR_STATUS;

}

/** Used by admin pages to get credentials */
export class GetCredentials implements Action {
    readonly type = AdminPageActionTypes.GET_SYSTEM_CREDENTIALS;
}

/** Used by admin pages to get credentials success */
export class GetCredentialsSuccess implements Action {
    readonly type = AdminPageActionTypes.GET_SYSTEM_CREDENTIALS_SUCCESS;
    constructor(public payload: Array<SystemCredential>) { }
}

/** Used by admin pages to get credentials failure */
export class GetCredentialsFailure implements Action {
    readonly type = AdminPageActionTypes.GET_SYSTEM_CREDENTIALS_FAILURE;
    constructor(public payload: Array<SystemCredential>) { }
}

//#region admin shard page
export class GetSharedPage implements Action {
    readonly type = AdminPageActionTypes.GET_SHARED_PAGE;
}
export class GetSharedPageSuccess implements Action {
    readonly type = AdminPageActionTypes.GET_SHARED_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class GetSharedPageFailure implements Action {
    readonly type = AdminPageActionTypes.GET_SHARED_PAGE_FAILURE;
    constructor(public payload: any) { }
}
export class CreateSharedPage implements Action {
    readonly type = AdminPageActionTypes.CREATE_SHARED_PAGE;
}
export class CreateSharedPageSuccess implements Action {
    readonly type = AdminPageActionTypes.CREATE_SHARED_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class CreateSharedPageFailure implements Action {
    readonly type = AdminPageActionTypes.CREATE_SHARED_PAGE_FAILURE;
    constructor(public payload: any) { }
}
export class DeleteSharedPage implements Action {
    readonly type = AdminPageActionTypes.DELETE_SHARED_PAGE;
}
export class DeleteSharedPageSuccess implements Action {
    readonly type = AdminPageActionTypes.DELETE_SHARED_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteSharedPageFailure implements Action {
    readonly type = AdminPageActionTypes.DELETE_SHARED_PAGE_FAILURE;
    constructor(public payload: any) { }
}
export class EditSharedPage implements Action {
    readonly type = AdminPageActionTypes.EDIT_SHARED_PAGE;
}
export class EditSharedPageSuccess implements Action {
    readonly type = AdminPageActionTypes.EDIT_SHARED_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class EditSharedPageFailure implements Action {
    readonly type = AdminPageActionTypes.EDIT_SHARED_PAGE_FAILURE;
    constructor(public payload: any) { }
}
//#endregion


//#region admin general page
export class GetGeneralPage implements Action {
    readonly type = AdminPageActionTypes.GET_GENERAL_PAGE;
}
export class GetGeneralPageSuccess implements Action {
    readonly type = AdminPageActionTypes.GET_GENERAL_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class GetGeneralPageFailure implements Action {
    readonly type = AdminPageActionTypes.GET_GENERAL_PAGE_FAILURE;
    constructor(public payload: any) { }
}
export class CreateGeneralPage implements Action {
    readonly type = AdminPageActionTypes.CREATE_GENERAL_PAGE;
}
export class CreateGeneralPageSuccess implements Action {
    readonly type = AdminPageActionTypes.CREATE_GENERAL_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class CreateGeneralPageFailure implements Action {
    readonly type = AdminPageActionTypes.CREATE_GENERAL_PAGE_FAILURE;
    constructor(public payload: any) { }
}
export class DeleteGeneralPage implements Action {
    readonly type = AdminPageActionTypes.DELETE_GENERAL_PAGE;
}
export class DeleteGeneralPageSuccess implements Action {
    readonly type = AdminPageActionTypes.DELETE_GENERAL_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class DeleteGeneralPageFailure implements Action {
    readonly type = AdminPageActionTypes.DELETE_GENERAL_PAGE_FAILURE;
    constructor(public payload: any) { }
}
export class EditGeneralPage implements Action {
    readonly type = AdminPageActionTypes.EDIT_GENERAL_PAGE;
}
export class EditGeneralPageSuccess implements Action {
    readonly type = AdminPageActionTypes.EDIT_GENERAL_PAGE_SUCCESS;
    constructor(public payload: any) { }
}
export class EditGeneralPageFailure implements Action {
    readonly type = AdminPageActionTypes.EDIT_GENERAL_PAGE_FAILURE;
    constructor(public payload: any) { }
}

/** Admin page Actions */
export type AdminPageActions =
    NavNoteTypes |
    NavEntityTypes |
    NavUsers |
    NavUsersSuccess |
    CreateNewUser |
    CreateNewUserSuccess |
    DeleteUsers |
    DeleteUsersSuccess |
    NavNoteTypesSuccess |
    NavEntityTypesSuccess |
    GeneralFailure |
    GetCredentials |
    GetCredentialsSuccess |
    GetCredentialsFailure |
    ClearStatus |
    CreateNewNoteType |
    CreateNewNoteTypeSuccess |
    DeleteNoteType |
    DeleteNoteTypeSuccess |
    EditUser |
    EditUserSuccess |
    EditNoteType |
    EditNoteTypeSuccess |
    EditDefaultNoteType |
    EditDefaultNoteTypeSuccess |
    ToggleNoteDisplay |
    ToggleNoteDisplaySuccess |
    CreateNewEntityType |
    CreateNewEntityTypeSuccess |
    DeleteEntityType |
    DeleteEntityTypeSuccess |
    EditEntityType |
    EditEntityTypeSuccess |
    EditDefaultEntityType |
    EditDefaultEntityTypeSuccess |
    ToggleEntityDisplay |
    ToggleEntityDisplaySuccess |
    GetSharedPage |
    GetSharedPageSuccess |
    GetSharedPageFailure |
    CreateSharedPage |
    CreateSharedPageSuccess |
    CreateSharedPageFailure |
    DeleteSharedPage |
    DeleteSharedPageSuccess |
    DeleteSharedPageFailure |
    EditSharedPage |
    EditSharedPageSuccess |
    EditSharedPageFailure |
    GetGeneralPage |
    GetGeneralPageSuccess |
    GetGeneralPageFailure |
    CreateGeneralPage |
    CreateGeneralPageSuccess |
    CreateGeneralPageFailure |
    DeleteGeneralPage |
    DeleteGeneralPageSuccess |
    DeleteGeneralPageFailure |
    EditGeneralPage |
    EditGeneralPageSuccess |
    EditGeneralPageFailure;
