<div class="container tam-form-field" [ngClass]="{'has-error':invalid||requireInvalid}">
    <div class="tam-form-label label-area">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name body-3 text-ellipsis"></field-label>
    </div>
    <kendo-numerictextbox #kendo
        [ngClass]="{'tam-textbox':config.editable,'field-control':config.editable,'field-value':!config.editable,'body-2':!config.editable,'field-disabled':isDisabled}"
        [value]="config.field.value" [min]="config.min" [max]="config.max" [format]="format" [spinners]="false"
        [step]="0" (valueChange)="valueChange($event)" (keyup)="onKeyup($event)" [disabled]="isDisabled">
    </kendo-numerictextbox>
    <div class="tam-form-help" *ngIf="config.editable&&invalid">
        Only number from {{config.min}} to
        {{config.max}} allowed
    </div>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
</div>