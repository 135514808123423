import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { LoginFormConfig } from '../../components/login-form/login-form.config';
import { AppState } from '../../redux';
import { AppLoadService } from '../../app-load.service';
import { GlobalService } from '../../tamalelibs/services/global.service';

@Component({
    selector: 'tam-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    loginFormConfig: LoginFormConfig;

    constructor(
        private _router: Router,
        private _store: Store<AppState>,
        private _appLoadService: AppLoadService,
        private _globalService: GlobalService
    ) { }

    ngOnInit() {
        this.loginFormConfig = new LoginFormConfig();
        this.loginFormConfig.eventSubject$.subscribe(() => this.onLoginSuccess());
    }

    ngOnDestroy() {
        this.loginFormConfig.eventSubject$.unsubscribe();
    }

    onLoginSuccess() {
        this._appLoadService.initSsoSetting().then(data => {
            let returnUrl;
            this._store.select('auth').subscribe(data => {
                returnUrl = data.returnUrl;
            }).unsubscribe();
            if (returnUrl) {
                this._router.navigateByUrl(returnUrl);
            } else {
                this._router.navigate(['/']);
            }
        });
    }
}
