/**
 * Created by Abner Sui on 1/5/2021
 *
 * ------ maintenance history ------
 * Added an option for setting current user as default value.
 * Updated by Daniel on 7/18/2023, To support relationship filter on source
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, take } from 'rxjs/operators';
import { AppState } from '../../../redux';
import { fullEntityTypeSelector } from '../../../redux/reducers/entity-type.reducer';
import { relationshipTypeSelector } from '../../../redux/reducers/relationship-type.reducer';
import { StoreQuerierService } from '../../../services/store-querier.service';
import { ALL_VALUE } from '../../../tamalelibs/constants/business.constants';
import { EntityBrief } from '../../../tamalelibs/models/entity-brief.model';
import { EntityType } from '../../../tamalelibs/models/entity-type.model';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { FieldType } from '../../../tamalelibs/models/workflow.model';
import { currentWorkflowAllFieldsSelector } from '../../../tamalelibs/redux/reducers/workflow.reducer';
import { EntityService } from '../../../tamalelibs/services/entity.service';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from '../../../widgets/multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { SingleSelectDropdownFilterConfig } from '../../../widgets/single-select-dropdown-filter/single-select-dropdown-filter.model';
import { ControlConfigEvents, IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';
import { ArrayHelperService } from '../../../tamalelibs/services/array-helper.service';

export const RELATIONSHIP_TYPE_DEFAULT_VALUE = 'select a relationship';
export const CUSTOM_FIELDS_DEFAULT_VALUE = 'select a custom field';
export const EMPTY_SOURCE_NAME = 'None';

@Component({
    selector: 'tam-single-entity-dropdown-config',
    templateUrl: './single-entity-dropdown-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class SingleEntityDropdownConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {
    // #region public properties
    @Input() config: FieldConfig;

    @ViewChild('base', { static: false }) base;
    @ViewChild('sourcekendo', { static: false }) sourcekendo;
    @ViewChild('staticDefaultValuekendo', { static: false }) staticDefaultValuekendo;
    /**
     * the attribute name of the config object, which indicates how to set the default value for current ED.
     */
    checkedDefaultItem: string;
    customFields = CUSTOM_FIELDS_DEFAULT_VALUE;
    customFieldsConfig: SingleSelectDropdownFilterConfig = new SingleSelectDropdownFilterConfig(true);
    dataType = FieldType.ENTITY;
    dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    isRelationshipChecked = false;
    relationshipInvalid = false;
    relationshipType = RELATIONSHIP_TYPE_DEFAULT_VALUE;
    relationshipTypeConfig: SingleSelectDropdownFilterConfig = new SingleSelectDropdownFilterConfig(true);
    staticDefaultValueDataSource: BehaviorSubject<Array<EntityBrief>> = new BehaviorSubject<Array<EntityBrief>>([]);
    staticDefaultValueLoading = false;
    sourceName = EMPTY_SOURCE_NAME;
    sourceInvalid = false;
    // #endregion

    // #region private properties
    private _destroySubscriptions: Array<Subscription> = [];
    private _filterHandlerStaticDefaultValue$: Subject<string> = new Subject();
    private _focusedStaticDefaultValue = false;
    private _sourceData: Array<EntityType>;
    // #endregion

    // #region constructor
    constructor(
        private _entityService: EntityService,
        private _store: Store<AppState>,
        private _storeQueriery: StoreQuerierService,
    ) { }
    // #endregion

    // #region OnInit
    ngOnInit(): void {
        this._initRelationType();
        this._initCustomFields();
        this.isRelationshipChecked = this.config.isRelationshipChecked;
        this._destroySubscriptions.push(
            this._store.pipe(
                select(fullEntityTypeSelector),
            ).subscribe(res => {
                this._sourceData = res;
                ArrayHelperService.sort(this._sourceData, 'name');
                this._sourceData.forEach(item => {
                    const checked = this.config.source.indexOf(item.id) > -1 || (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE);
                    this.dropdownConfig.data.push(MultiSelDropdownConfig.parse(item.id, checked, item.name));
                });
                // Initialize this.config.source to an empty array if it's null
                this.config.source = this.config.source || [];
                this.config.source = (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? [ALL_VALUE] : this.dropdownConfig.data.filter(item => item.checked).map(item => item.id);
                if (this.validateSource()) {
                    const sources: Array<any> = (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? [{ id: ALL_VALUE, name: 'All' }] : this.dropdownConfig.data.filter(item => item.checked);
                    this.sourceName = sources.map(item => item.name).join(', ');
                }
            }),

            this._filterHandlerStaticDefaultValue$.pipe(
                debounceTime(250),
                filter(filterStr => filterStr !== null && filterStr !== undefined)
            ).subscribe(filterStr => this._filterHandlerStaticDefaultValue(filterStr)),

            this.dropdownConfig.onChangeValue$.subscribe(data => this.valueChangeSource(data)),
        );
        if (this.config.staticDefaultValue) {
            this.staticDefaultValueDataSource.next([this.config.staticDefaultValue]);
        }
    }

    // #endregion

    // #region AfterViewInit
    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }
    // #endregion

    // #region OnDestroy
    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }
    // #endregion

    // #region public functions
    blurStaticDefaultValue(): void {
        this._focusedStaticDefaultValue = false;
    }

    filterStaticDefaultValue(event): void {
        if (this._focusedStaticDefaultValue) {
            this.staticDefaultValuekendo.toggle(false);
            this._filterHandlerStaticDefaultValue$.next(event);
        }
    }

    focusStaticDefaultValue(): void {
        this._focusedStaticDefaultValue = true;
        if (this.config.staticDefaultValue) {
            this.staticDefaultValuekendo.toggle(true);
        } else {
            this.filterStaticDefaultValue('');
        }
    }

    getInvalidDetail(): FieldValidationError {
        const baseValidationDetail: FieldValidationError = this.base.getInvalidDetail();
        baseValidationDetail.isSourceInvalid = !this.validateSource() || !this.validateRelationship();
        return baseValidationDetail;
    }

    onClickSource(event) {
        this.dropdownConfig.open$.next(event.target);
    }

    onCustomFieldsItemClick(event) {
        this.customFieldsConfig.open$.next(event.target);
    }

    onRelationshipCheckedChange(event) {
        this.config.isRelationshipChecked = this.isRelationshipChecked;

        if (!this.isRelationshipChecked) {
            this.relationshipType = RELATIONSHIP_TYPE_DEFAULT_VALUE;
            this.customFields = CUSTOM_FIELDS_DEFAULT_VALUE;
            this.config.relationshipTypeId = '';
            this.config.customFieldId = '';
        }
    }

    onRelationshipTypeItemClick(event) {
        this.relationshipTypeConfig.open$.next(event.target);
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        this.sourceInvalid = !this.validateSource();
        this.relationshipInvalid = !this.validateRelationship();
        return baseValid && !this.sourceInvalid && !this.relationshipInvalid;
    }

    validateRelationship(): boolean {
        let isValid = true;
        if (this.isRelationshipChecked) {
            if (this.relationshipType === RELATIONSHIP_TYPE_DEFAULT_VALUE ||
                this.customFields === CUSTOM_FIELDS_DEFAULT_VALUE) {
                isValid = false;
            }
        }
        return isValid;
    }

    validateSource(): boolean {
        return this.config.source && this.config.source.length > 0;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }

    valueChangeCustomFields(event): void {
        this.customFields = event.name;
        this.config.customFieldId = event.id;
        this.relationshipInvalid = !this.validateRelationship();
    }

    valueChangeRelationshipType(event): void {
        this.relationshipType = event.name;
        this.config.relationshipTypeId = event.id;
        this.relationshipInvalid = !this.validateRelationship();
    }

    valueChangeSource(event): void {
        const value = event === ALL_VALUE ? [{ id: event, name: 'All' }] : event;
        this.config.source = value.map(item => item.id);
        this.sourceInvalid = !this.validateSource();
        this.sourceName = this.sourceInvalid ? EMPTY_SOURCE_NAME : value.map(item => item.name).join(', ');
        if (this.config.staticDefaultValue) {
            const checked = this.config.source.indexOf(this.config.staticDefaultValue.type.id) > -1 || (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE);
            if (checked) {
                this.staticDefaultValueDataSource.next([this.config.staticDefaultValue]);
            } else {
                this.config.staticDefaultValue = null;
            }
        }
        this.valueChange(event);
    }

    valueChangeStaticDefaultValue(event): void {
        if (event) {
            this.staticDefaultValueDataSource.next([event]);
            this._focusedStaticDefaultValue = false;
        } else {
            this._focusedStaticDefaultValue = true;
            this.filterStaticDefaultValue('');
        }
        this.valueChange(event);
    }
    // #endregion

    // #region private functions
    private _filterHandlerStaticDefaultValue(filterStr: string): void {
        if (!this.sourceInvalid && this._focusedStaticDefaultValue) {
            this.staticDefaultValueLoading = true;
            const source = (this.config.source.length === 1 && this.config.source[0] === ALL_VALUE) ? {} : { entityType: this.config.source };
            this._entityService.getEntityListBySearchTextQuick(1, 20, filterStr, source).pipe(
                take(1),
                map(response => this._entityService.mapEntityBriefList(response))
            ).subscribe(data => {
                this.staticDefaultValueDataSource.next(data);
                this.staticDefaultValueLoading = false;
                this.staticDefaultValuekendo.toggle(true);
            });
        }
    }

    private _initCustomFields(): void {
        let selectedCustomFieldId;
        // set the saved value for custom field control.
        if (this.config.customFieldId && this.config.isRelationshipChecked) {
            selectedCustomFieldId = this.config.customFieldId;
        }
        // set datasource for the custom field control.
        const allFieldControls = this._storeQueriery.queryBySelector(currentWorkflowAllFieldsSelector);
        const applicableFieldTypes = [ControlType.SINGLE_ENTITY_DROP_DOWN];
        const fields = allFieldControls.filter(fld => applicableFieldTypes.includes(fld.type) && fld.fieldDefinitionId !== this.config.fieldDefinitionId).map(fldCfg => ({ name: fldCfg.label, id: fldCfg.fieldDefinitionId }));
        fields.forEach(item => {
            this.customFieldsConfig.data.push({
                id: item.id,
                name: item.name,
                selected: selectedCustomFieldId === item.id,
            });
        });

        // set the new value when relationship type is changed.
        this._destroySubscriptions.push(
            this.customFieldsConfig.onChangeValue$.subscribe(data => this.valueChangeCustomFields(data)),
        );

        // set the selected value of relationship type
        if (this.config.customFieldId) {
            const customField = this.config.customFieldId;
            this.customFieldsConfig.data.filter(item => {
                if (item.id === customField) {
                    this.customFields = item.name;
                }
            });
        }
    }

    private _initRelationType(): void {
        let selectedRelationshipTypeId;
        // set the saved value for relationship type control.
        if (this.config.relationshipTypeId && this.config.isRelationshipChecked) {
            selectedRelationshipTypeId = this.config.relationshipTypeId;
        } else {
            selectedRelationshipTypeId = RELATIONSHIP_TYPE_DEFAULT_VALUE;
        }
        // set datasource for the relationship type control.
        this._store.pipe(select(relationshipTypeSelector),
            take(1)
        ).subscribe(res => {
            if (res && res.length > 0) {
                res.forEach(item => {
                    this.relationshipTypeConfig.data.push({
                        id: item.id,
                        name: item.name,
                        selected: selectedRelationshipTypeId === item.id,
                    });
                });
            }
        });

        // set the new value when relationship type is changed.
        this._destroySubscriptions.push(
            this.relationshipTypeConfig.onChangeValue$.subscribe(data => this.valueChangeRelationshipType(data)),
        );

        // set the selected value of relationship type
        if (this.config.relationshipTypeId) {
            const relationshipType = this.config.relationshipTypeId;
            this.relationshipTypeConfig.data.filter(item => {
                if (item.id === relationshipType) {
                    this.relationshipType = item.name;
                }
            });
        }
    }
    // #endregion
}
