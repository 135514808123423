/**
 * Created by Ella Ma 11/07/2020
 * Description:
 * If CellA default value is null | undefined(CellA show '(Blanks)'), CellB default value is number | boolean | string | date -> CellA, CellB
 * If CellA, CellB default value is string(CellA='a', CellB='b'), sort by alphabetical -> CellA, CellB
 * If CellA, CellB default value is number(CellA = 0, CellB =1), sort by asc -> CellA, CellB
 * If CellA, CellB default value is boolean(CellA = false, CellB =true), sort by asc -> CellA, CellB
 * If CellA, CellB column type is Date, Year, Month, Date sort by asc -> Year: 2020 -> 2021, Month: Jan-Dec, Date: 1-31
 * (Blanks) should be always listed on the top of filter
 * Note: date column use pipe has performance problem, so data column do not use pipe
 * ------ maintenance history ------
 * Update by Daniel on 07/20/2021. convert the component to widget
 */

import { Pipe, PipeTransform } from '@angular/core';
import { bizConstants } from '../../constants/biz.constants';

@Pipe({ name: 'sortBy' })

export class CustomGridSortPipe implements PipeTransform {

    transform(data: any[]): any {
        return data.sort((a: any, b: any) => {
            // the blank value should be sticked top.
            if (a.text === bizConstants.blankValue || !a.text) {
                return -1;
            } else if (b.text === bizConstants.blankValue || !b.text) {
                return 1;
            }

            if (typeof a.text === 'string' && typeof b.text === 'string') {
                return a.textFormat.localeCompare(b.textFormat);
            } else if (!a.text && b.text && typeof b.text === 'string') {
                return -1;
            } else if (!b.text && a.text && typeof a.text === 'string') {
                return 1;
            } else if (typeof a.text === 'number' && typeof b.text === 'number') {
                return a.text - b.text;
            } else if ((!a && typeof b.text === 'number') ||
                (!b && typeof a.text === 'number')) {
                return a.textFormat.localeCompare(b.textFormat);
            } else if (typeof a.text === 'boolean' && typeof b.text === 'boolean') {
                return a.text - b.text;
            } else if ((!a.text && typeof a.text !== 'boolean' && typeof b.text === 'boolean') ||
                (!b.text && typeof b.text !== 'boolean' && typeof a.text === 'boolean')) {
                return a.textFormat.localeCompare(b.textFormat);
            }
            return a.text - b.text;
        });
    }
}
