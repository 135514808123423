<div class="container">
    <div tid="label" class="tam-form-field one-field"
        [ngClass]="{'has-error':labelRequireInvalid||validateLabelDuplicate()}">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.label' | stringLiterals}}&nbsp;<span class="required-field">*</span>
            </div>
        </div>
        <input class="tam-textbox field-control" type="text" [(ngModel)]="config.label" [maxlength]="64"
            [disabled]="fieldLoading" (keyup)="valueChangeLabel($event)" />
        <div class="tam-form-help" *ngIf="labelRequireInvalid">
            {{'general.required_error_message' | stringLiterals}}
        </div>
        <div class="tam-form-help" *ngIf="validateLabelDuplicate()">
            {{'general.label_name_already_exists' | stringLiterals}}
        </div>
    </div>
    <div tid="width" class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.width' | stringLiterals}}
            </div>
        </div>
        <div class="tam-textbox field-control width-control">
            <div class="one-width"
                [ngClass]="{'one-width-selected':config.width===25,'one-width-before':config.width===50,'one-width-disabled':config.fixWidth&&config.fixWidth!==25}"
                (click)="valueChangeWidth($event,25)">25%</div>
            <div class="one-width"
                [ngClass]="{'one-width-selected':config.width===50,'one-width-before':config.width===75,'one-width-disabled':50>config.maxWidth||(config.fixWidth&&config.fixWidth!==50)}"
                (click)="valueChangeWidth($event,50)">50%</div>
            <div class="one-width"
                [ngClass]="{'one-width-selected':config.width===75,'one-width-before':config.width===100,'one-width-disabled':75>config.maxWidth||(config.fixWidth&&config.fixWidth!==75)}"
                (click)="valueChangeWidth($event,75)">75%</div>
            <div class="one-width"
                [ngClass]="{'one-width-selected':config.width===100,'one-width-disabled':100>config.maxWidth||(config.fixWidth&&config.fixWidth!==100)}"
                (click)="valueChangeWidth($event,100)">100%</div>
        </div>
    </div>
    <div tid="required" class="tam-form-field one-field field-bottom-padding">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.required' | stringLiterals}}
            </div>
        </div>
        <kendo-switch [disabled]="config.isRequiredDisabled" class="kendo-switch-medium-with-label" [(ngModel)]="config.required"
        onLabel="{{'general.yes' | stringLiterals}}"
        offLabel="{{'general.no' | stringLiterals}}" (valueChange)="valueChange($event)">
        </kendo-switch>
    </div>
    <div tid="read-only" class="tam-form-field one-field field-bottom-padding" *ngIf="isReadonlyVisible">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.read_only' | stringLiterals}}
            </div>
        </div>
        <kendo-switch class="kendo-switch-medium-with-label"
        onLabel="{{'general.on' | stringLiterals}}" offLabel="{{'general.off' | stringLiterals}}"
            [(ngModel)]="config.isReadOnly" (valueChange)="valueChange($event)">
        </kendo-switch>
    </div>
    <div tid="tooltip" class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.tooltip' | stringLiterals}}
            </div>
        </div>
        <textarea class="tam-textbox field-control fixed-size-textarea tooltip-textarea" type="text"
            [(ngModel)]="config.description" [maxlength]="128" (keyup)="valueChange($event)"></textarea>
    </div>
    <div tid="change-after-submission" class="change-after-submission tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                {{'general.allow_change_after_submission' | stringLiterals}}
            </div>
        </div>
        <kendo-switch class="kendo-switch-medium-with-label" [(ngModel)]="config.isChangeAfterSubmissionEnabled"
        onLabel="{{'general.yes' | stringLiterals}}"
        offLabel="{{'general.no' | stringLiterals}}"
        (valueChange)="valueChange($event)">
        </kendo-switch>
    </div>
</div>