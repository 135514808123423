<div class="container">
    <div class="icon-area">
        <smart-icon class="small" name="search"></smart-icon>
    </div>
    <div class="input-area" kendoTooltip #tooltip showOn="none" position="bottom">
        <input tid="keywordSearch" #keywordInput type="text" [(ngModel)]="keyword" (keyup)="onKeyup($event)"
            autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false"
            title="Minimum 3 characters to start keyword search">
    </div>
    <div tid="clearKeyword" class="clear-text-button" (click)="clear()" *ngIf="showClearButton()">
        <smart-icon name="close"></smart-icon>
    </div>
</div>