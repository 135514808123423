/**
 * Created by Bowen on 10/12/19.
 * Description:
 * Setting screen service
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { TransportService } from '../tamalelibs/services/transport.service';
import { AppConfig } from '../tamalelibs/models/app-config.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingScreenService {

    manageRelDialogActionSubject$: Subject<any> = new Subject();
    constructor(
        private _transportService: TransportService,
    ) { }

    public updateRelTreeEditableUsers(isAbleEditRelTree): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.firmSettingEndpoint}`;
        const configuration = {
            'public': isAbleEditRelTree,
        };
        const relationshipManage = {
            'relationshipManage': configuration
        };

        const params = {
            outputformat: 'json',
            'configuration': JSON.stringify(relationshipManage)
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, null, options);
    }
}
