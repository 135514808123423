/**
 * Created by Jiu Chen on 03/26/2019.
 * Description:
 *
 * ------ maintenance history ------
 * 04/22/2019  Ella Ma  add insertTile,updateTile
 * 05/20/2019  Ella Ma  add saveGridTile function
 */

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';

@Injectable()
export class TileService {
    constructor(
        private _transportService: TransportService
    ) { }

    getById(id: string) {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.tileEndPoint}${id}`;
        const params = {
            showpermission: true,
        };
        const options = {
            headers: headers,
            params: params
        };

        return this._transportService.get(url, options).pipe(
            map(response => {
                return this._saveSuccessHandler(response);
            })
        );
    }
    insertTile(tile) {
        const options = {
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
        };
        const url = `${AppConfig.tileEndPoint}`;
        const data = this._transportService.getUrlEncodedForm({ config: tile });
        return this._transportService.post(url, data, options).pipe(
            map(response => {
                return this._saveSuccessHandler(response);
            })
        );
    }

    updateTile(tile) {
        const url = `${AppConfig.tileEndPoint}${tile.id}`;
        const data = new FormData();
        data.append('config', JSON.stringify(tile));
        return this._transportService.put(url, data, {}).pipe(
            map(response => {
                return this._saveSuccessHandler(response);
            })
        );
    }

    saveGridTile(tileId, config) {
        const url = `${AppConfig.layoutEndPoint}`;
        const options = {
            params: {
                tile_id: tileId
            }
        };
        const data = new FormData();
        data.append('config', JSON.stringify(config));
        return this._transportService.post(url, data, options);
    }
    private _saveSuccessHandler(response) {
        response.config.id = response.id;
        response.config.lastModifiedDate = response.lastModifiedDate;
        response.config.author = response.author;
        response.config.createDate = response.createDate;
        return response.config;
    }
}
