/**
 * Created by Bowen on 06/10/2019.
 * Description: change liveware name
 *
 * ------ maintenance history ------
 */

import { SubChromeActions, SubChromeActionTypes } from '../actions/sub-chrome-name.action';

export function subChromeNameReducer(state: string, action: SubChromeActions) {
    switch (action.type) {
        case SubChromeActionTypes.CHANGE_LIVEWIRE_SUBCHORME_NAME_SUCCESS:
            return action.payload;
        case SubChromeActionTypes.GET_LIVEWIRE_SUBCHORME_NAME_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}
