/**
 * Created by
 * Description:
 *
 * ------ maintenance history ------
 * 06/02/2020 Marcus Zhao - add firstName lastName jobFunction
 * 07/22/2020 Marcus Zhao - add companyWeb and jobTitleWeb  for TamaleWeb
 *                           jobTitle and company are used for iPhone.
 * 08/26/2022 Marucs Zhao - add function for parse email Signature data to contact
 *
 */
import { Entity } from './entity.model';
import { EntityType } from './entity-type.model';
import { RelationshipType } from './relationship-type.model';
import { EmailDetail, PhoneDetail, AddressDetail, SocialMedia } from './job-function';

export class Contact extends Entity {
    birthday?: string;
    businessAddress?: string;
    businessFax?: string;
    businessPhone?: string;
    defaultRelationshipType?: RelationshipType;
    homeAddress?: string;
    homePhone?: string;
    imHandle?: string;
    imService?: string;
    mobilePhone?: string;
    notes?: string;
    primaryEmail?: string;
    secondaryEmail?: string;
    firstName?: string;
    lastName?: string;
    jobFunction?: any;
    template?: any;
    isLogin?: boolean;
    // tslint:disable-next-line: whitespace
    webPhone? = new Array<PhoneDetail>();
    // tslint:disable-next-line: whitespace
    webEmail? = new Array<EmailDetail>();
    // tslint:disable-next-line: whitespace
    webAddress? = new Array<AddressDetail>();
    // tslint:disable-next-line: whitespace
    socialMedia? = new Array<SocialMedia>();
    // tslint:disable-next-line: whitespace
    relationship? = [];
    employment?: any;
    lastEdit?: string; // web or desktop
    jobTitle?: any;
    // tslint:disable-next-line: whitespace
    jobTitleWeb? = new Array<any>();
    companyWeb?: any;
    company?: any;
    attribute?: any;
    syncType?: number;
    suspend?: boolean;
    ownerID?: string;
    ownerName?: string;
    duplicateContact?: Array<any> = [];
    uuid?: string;
    deletable?: boolean;

    constructor() {
        super();
        this.type = EntityType.CONTACT;
        this.isPublic = true;
        this.defaultRelationshipType = RelationshipType.OTHER;
        this.birthday = '';
        this.aliases = [];
        this.template = { 'jobTitleHeight': '', 'jobFunctionHeight': '', 'aliasHeight': '' };
        this.attribute = {};
    }

    static parse(serverResponse, parseTreeNode: boolean = false): Contact {
        const contact = Entity.parse(serverResponse, parseTreeNode) as Contact;

        if (serverResponse['contact-details']) {
            contact.aliases = serverResponse['contact-details']['alias'];
            contact.birthday = serverResponse['contact-details']['birthday'];
            contact.businessAddress = serverResponse['contact-details']['business-address'];
            contact.businessFax = serverResponse['contact-details']['business-fax'];
            contact.businessPhone = serverResponse['contact-details']['business-phone'];
            contact.company = serverResponse['contact-details']['company'];
            contact.companyWeb = serverResponse['contact-details']['web-company'];
            contact.homeAddress = serverResponse['contact-details']['home-address'];
            contact.homePhone = serverResponse['contact-details']['home-phone'];
            contact.imHandle = serverResponse['contact-details']['im-handle'];
            contact.imService = serverResponse['contact-details']['im-service'];
            contact.jobTitle = serverResponse['contact-details']['job-title'];
            contact.jobTitleWeb = serverResponse['contact-details']['web-job-title'];
            contact.mobilePhone = serverResponse['contact-details']['mobile-phone'];
            contact.notes = serverResponse['contact-details']['notes'];
            contact.primaryEmail = serverResponse['contact-details']['primary-email'];
            contact.secondaryEmail = serverResponse['contact-details']['secondary-email'];
            contact.template = serverResponse['contact-details']['template'];
            contact.webAddress = serverResponse['contact-details']['webAddress'];
            contact.webPhone = serverResponse['contact-details']['webPhone'];
            contact.webEmail = serverResponse['contact-details']['webEmail'];
            contact.socialMedia = serverResponse['contact-details']['socialMedia'];
            contact.jobFunction = serverResponse['contact-details']['jobFunction'];
            contact.attribute = serverResponse['contact-details']['attribute'];
            // contact.relationship = serverResponse['contact-details']['relationship'];
            if (serverResponse['contact-details']['defaultRelationshipType']) {
                contact.defaultRelationshipType = RelationshipType.parse(serverResponse['contact-details']['defaultRelationshipType']);
            } else {
                // handle server before version 19.1
                // tslint:disable-next-line: max-line-length
                contact.defaultRelationshipType = new RelationshipType('', serverResponse['contact-details']['default-type']); // server only return relationship type name
            }
            if (serverResponse['contact-details']['first-name']) {
                contact.firstName = serverResponse['contact-details']['first-name'];
            }
            if (serverResponse['contact-details']['last-name']) {
                contact.lastName = serverResponse['contact-details']['last-name'];
            }
            if (serverResponse['contact-details']['latest-edit']) {
                contact.lastEdit = serverResponse['contact-details']['latest-edit'];
            }

            if (serverResponse['contact-details']['syncType']) {
                contact.syncType = serverResponse['contact-details']['syncType'];
            }
            // server need handle old version.
            if (serverResponse['contact-details']['suspend'] !== undefined) {
                contact.suspend = serverResponse['contact-details']['suspend'];
            }
            if (serverResponse['contact-details']['ownerID']) {
                contact.ownerID = serverResponse['contact-details']['ownerID'];
            }

            if (serverResponse['contact-details']['ownerName']) {
                contact.ownerName = serverResponse['contact-details']['ownerName'];
            }

            if (serverResponse['contact-details']['isLogin']) {
                contact.isLogin = serverResponse['contact-details']['isLogin'];
            }
            contact.deletable = !contact.isLogin;
        }
        return contact;
    }

    /**
     *
     * @param parse signature data to contact.
     * @param isFastModel
     * @returns contact
     */
    static parseForEmailSignature(item, isFastModel): Contact {
        const contact = new Contact();
        contact.webPhone = item.webPhone;
        contact.webEmail = item.webEmail;
        contact.webAddress = item.webAddress;
        contact.socialMedia = item.socialMedia;
        contact.firstName = item.firstName;
        contact.lastName = item.lastName;
        contact.aliases = item.alias;
        if (item.selectCompany) {
            contact.company = item.selectCompany;
            contact.companyWeb = item.selectCompany;
            // if create contact by fast company is string for name or id.
            if (isFastModel) {
                contact.company = item.selectCompany.id ? item.selectCompany.id : item.selectCompany.name;
            }
        } else {
            if (item.webCompany.length > 0) {
                const _company = item.webCompany[0];
                if (isFastModel) {
                    contact.company = _company.id ? _company.id : _company.name;
                } else {
                    contact.company = _company;
                    contact.companyWeb = _company;
                }
            }
        }

        if (isFastModel) {
            contact.jobTitle = item.webJobTitle.map(a => a.name);
        }
        contact.jobTitleWeb = item.webJobTitle;
        contact.duplicateContact = item.duplicates;
        contact.uuid = item.uuid;
        return contact;
    }
}

// take(one/two way) or remove ownership;
export enum ExchangeBuklActionType {
    takeOneWay = 1,
    takeTwoWay = 2,
    remove = 3
}

