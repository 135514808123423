import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { DateHelperService } from './date-helper.service';

export class Params {
    objecttype?: string;
    page?: number;
    rpp?: number;
    sortby?: string;
    sortorder?: string;
    minuteoffset?: string;
}

@Injectable()
export class AuditTrailService {
    constructor(
        private _transportService: TransportService,
        private _dateHelper: DateHelperService,
    ) { }

    getAuditTrail(noteId, objecttype): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.auditTrail}`;
        const params = {
            objectid: noteId,
            objecttype: objecttype
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }
    getAduitList(params, objectType): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.auditTrail}`;

        let filter;

        if (params && params.filter) {
            filter = this._transportService.getUrlEncodedForm({ filter: params.filter });
        }
        const param: Params = {
            page: params.page,
            rpp: params.rpp,
            sortby: params.sortby,
            sortorder: params.sortorder,
            minuteoffset: this._dateHelper.getContryTimezoneId()
        };
        if (objectType) {
            param.objecttype = objectType;
        }
        const options = {
            headers: headers,
            params: param
        };
        return this._transportService.post(url, filter, options);
    }

    downloadAduitList(sortParams, filterParams, objectType): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.auditTrailDownload}`;
        let filter = null;

        if (filterParams) {
            filter = this._transportService.getUrlEncodedForm({ filter: filterParams });
        }
        const params: Params = {
            sortby: sortParams && sortParams.sortby || 'operate_time',
            sortorder: sortParams && sortParams.sortorder || 'desc',
            minuteoffset: this._dateHelper.getContryTimezoneId()
        };
        if (objectType) {
            params.objecttype = objectType;
        }
        const options = {
            headers: headers,
            params: params,
            responseType: 'arraybuffer'
        };
        return this._transportService.post(url, filter, options);
    }

    getUsageReport() {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.usageReport}`;
        const options = {
            headers: headers
        };
        return this._transportService.get(url, options);
    }
}
