<div class="multi-sel-dropdown-constainer">
    <div class="time-picker-border" #anchor>
        <input type="text" [(ngModel)]="myModel" (blur)="inputBlur()" [disabled]="disabled" [readonly]="readonly"
            [class]="disabled?'textInput  tam-input-disabled':'textInput'">
        <smart-icon name="angle-down" (click)="showPopup($event)"></smart-icon>
    </div>
    <kendo-popup [anchor]="anchor" *ngIf="showSel&&!disabled&&!readonly">
        <div class="selContainer">
            <div class="checkContainer note-event-dialog-controls-status">
                <div *ngFor="let item of times;let i = index" class="row" [id]="'timedropdown'+i">
                    <label (click)="changeCheckedState(item, $event)" class="label-container"
                        [class.selected]='_timeModel===item'>
                        {{item}}
                    </label>
                </div>
            </div>
        </div>
    </kendo-popup>
</div>