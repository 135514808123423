/**
 * Created by Abner Sui on 02/20/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { RefinebySubConfig, RefinebyObject, RefinebySubWidgetAction } from './refineby-view.model';
import { Subscription } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DateHelperWebService } from '../../tamalelibs/services/date-helper.web.service';
import { RefinebyService } from '../../services/refineby.service';
import { BriefConfig } from '../widgets.model';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'refineby-date',
    templateUrl: './refineby-date.component.html',
    styleUrls: ['./refineby-widget.component.scss']
})
export class RefinebyDateComponent implements OnInit, OnDestroy {

    @Input()
    dataConfig: RefinebySubConfig;
    @Input()
    config: BriefConfig;

    isHoverOn = false;
    options: Array<RefinebyObject> = [];
    selectedOptions: Array<any> = [];
    categoryFilter: Object;
    isIPAD: boolean;
    searchResult: Array<RefinebyObject>;
    fromMin: Date = new Date(1900, 1, 1);
    fromMax: Date = new Date(2099, 12, 12);
    toMin: Date = new Date(1900, 1, 1);
    toMax: Date = new Date(2099, 12, 12);
    from: Date = null;
    to: Date = null;
    inputFrom: string;
    inputTo: string;
    preInputFrom: string;
    preInputTo: string;
    isShowDateRange = false;

    private _categoryFilter: Array<any>;
    private _format = 'MM/dd/yyyy';
    private _range: Object;

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _deviceService: DeviceDetectorService,
        private _refinebyService: RefinebyService,
    ) { }

    ngOnInit() {
        this._destroySubscriptions.push(this.config.actionSubject$.subscribe(action => this._onSubscribeActions(action)));

        this.options = this.dataConfig.options;
        this.options.sort((item1, item2) => {
            return item1.id.localeCompare(item2.id);
        });
        this.selectedOptions = this.dataConfig.selectedOptions;
        this.selectedOptions.forEach(option => {
            if (option.hasOwnProperty('id')) {
                option.checked = true;
                const index = this.options.findIndex(item => item.id === option.id);
                if (index > -1) {
                    this.options[index].checked = true;
                    option.count = this.options[index].count;
                } else {
                    option.count = 0;
                }
            } else {
                if (option['from']) {
                    this.from = new Date(option['from']);
                    this.toMin = this.from;
                    this.inputFrom = DateHelperWebService.getDateString(this.from, this._format);
                    this.preInputFrom = this.inputFrom;
                    this.isShowDateRange = true;
                }
                if (option['to']) {
                    this.to = new Date(option['to']);
                    this.fromMax = this.to;
                    this.inputTo = DateHelperWebService.getDateString(this.to, this._format);
                    this.preInputTo = this.inputTo;
                    this.isShowDateRange = true;
                }
            }
        });
        this._refinebyService.handleRefinebyDate(this.options);

        // For use within normal web clients
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (this.isIPAD) {
            this.isHoverOn = true;
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    changeInputValue() {
        this._range = {};
        if (this.from) {
            this._range['from'] = DateHelperWebService.getDateString(this.from, this._format);
            this.toMin = this.from;
            this.inputFrom = DateHelperWebService.getDateString(this.from, this._format);
            this.preInputFrom = this.inputFrom;
        }
        if (this.to) {
            this._range['to'] = DateHelperWebService.getDateString(this.to, this._format);
            this.fromMax = this.to;
            this.inputTo = DateHelperWebService.getDateString(this.to, this._format);
            this.preInputTo = this.inputTo;
        }
        const index = this.selectedOptions.findIndex(item => !item.hasOwnProperty('id'));
        if (index > -1) {
            if (Object.keys(this._range).length > 0) {
                this.selectedOptions.splice(index, 1, this._range);
            } else {
                this.selectedOptions.splice(index, 1);
            }
        } else if (Object.keys(this._range).length > 0) {
            this.selectedOptions.push(this._range);
        }
        this._changeValue();
    }

    changeSelectedValue(item: RefinebyObject, event) {
        item.checked = !item.checked;
        let index = this.options.findIndex(data => data.id === item.id);
        this.options[index].checked = item.checked;
        if (item.checked) {
            this.selectedOptions.push(item);
        } else {
            index = this.selectedOptions.findIndex(data => data.id === item.id);
            this.selectedOptions.splice(index, 1);
        }
        event.stopPropagation();
        this._changeValue();
    }

    collapse() {
        this.dataConfig.status.collapsed = !this.dataConfig.status.collapsed;
        this.config.feedbackSubject$.next({
            type: RefinebySubWidgetAction.STATUS_CHANGE,
            payload: {
                categoryId: this.dataConfig.categoryId,
                status: this.dataConfig.status
            }
        });
    }

    focusout() {
        let inputChanged = false;
        if (this.inputFrom) {
            if (Date.parse(this.inputFrom) && this.inputFrom !== this.preInputFrom) {
                let tempDate = new Date(this.inputFrom);
                if (tempDate.getTime() < this.fromMin.getTime()) {
                    this.inputFrom = DateHelperWebService.getDateString(this.fromMin, this._format);
                    tempDate = new Date(this.inputFrom);
                } else if (tempDate.getTime() > this.fromMax.getTime()) {
                    this.inputFrom = DateHelperWebService.getDateString(this.fromMax, this._format);
                    tempDate = new Date(this.inputFrom);
                }
                this.preInputFrom = this.inputFrom;
                this.from = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
                this.toMin = this.from;
                inputChanged = true;
            } else {
                this.inputFrom = this.preInputFrom;
                if (!this.inputFrom) {
                    this.from = null;
                }
                this.preInputFrom = this.inputFrom;
            }
        } else {
            if (this.inputFrom !== this.preInputFrom) {
                inputChanged = true;
            }
            this.inputFrom = '';
            this.from = null;
            this.preInputFrom = this.inputFrom;
        }
        if (this.inputTo) {
            if (Date.parse(this.inputTo) && this.inputTo !== this.preInputTo) {
                let tempDate = new Date(this.inputTo);
                if (tempDate.getTime() < this.toMin.getTime()) {
                    this.inputTo = DateHelperWebService.getDateString(this.toMin, this._format);
                    tempDate = new Date(this.inputTo);
                } else if (tempDate.getTime() > this.toMax.getTime()) {
                    this.inputTo = DateHelperWebService.getDateString(this.toMax, this._format);
                    tempDate = new Date(this.inputTo);
                }
                this.preInputTo = this.inputTo;
                this.to = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
                this.fromMax = this.to;
                inputChanged = true;
            } else {
                this.inputTo = this.preInputTo;
                if (!this.inputTo) {
                    this.to = null;
                }
                this.preInputTo = this.inputTo;
            }
        } else {
            if (this.inputTo !== this.preInputTo) {
                inputChanged = true;
            }
            this.inputTo = '';
            this.to = null;
            this.preInputTo = this.inputTo;
        }
        if (inputChanged) {
            this.changeInputValue();
        }
    }

    onKeyup(event) {
        if (event.keyCode === 13) {
            this.focusout();
        }
    }

    onMeatball(event) {
        if (this.isIPAD) {
            event.clientX = event.pageX;
            event.clientY = event.pageY;
        }
        this.config.feedbackSubject$.next({
            type: RefinebySubWidgetAction.CLICK_MEATBALL,
            payload: {
                event: event,
                categoryId: this.dataConfig.categoryId
            }
        });
    }

    onMouseOver() {
        this.isHoverOn = true;
    }

    onMouseOut() {
        this.isHoverOn = false;
    }

    onShowDatePicker() {
        this.isShowDateRange = !this.isShowDateRange;
        if (!this.isShowDateRange && (this.inputFrom || this.inputTo)) {
            this.inputFrom = '';
            this.from = null;
            this.inputTo = '';
            this.to = null;
            this.changeInputValue();
        }
    }

    private _changeValue() {
        this._categoryFilter = [];
        this.selectedOptions.forEach(option => {
            if (option.hasOwnProperty('id')) {
                this._categoryFilter.push(option.id);
            } else {
                const fromto = {};
                if (option['from']) {
                    fromto['from'] = new Date(option['from']).getTime() - 1;
                }
                if (option['to']) {
                    fromto['to'] = new Date(option['to']).getTime() + 24 * 60 * 60 * 1000;
                }
                this._categoryFilter.push(fromto);
            }
        });
        this.config.feedbackSubject$.next({
            type: RefinebySubWidgetAction.SELECTED_OPTION_CHANGE,
            payload: {
                categoryId: this.dataConfig.categoryId,
                selectedOptions: this.selectedOptions,
                categoryFilter: this._categoryFilter
            }
        });
    }

    private _onSubscribeActions(action) {
        if (action.type === RefinebySubWidgetAction.SEARCH) {
            this.searchResult = [];
            this.options.forEach(option => {
                if (option.checked || option.name.toLowerCase().includes(action.payload.toLowerCase())) {
                    this.searchResult.push(option);
                }
            });
            this.config.feedbackSubject$.next({
                type: RefinebySubWidgetAction.SEARCH_RESULT,
                payload: {
                    categoryId: this.dataConfig.categoryId,
                    searchResult: this.searchResult,
                }
            });
        }
    }
}
