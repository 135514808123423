/**
 * Created by Todd Yu on 06/20/2020.
 * Description:
 * Checkbox and Radio Button Widget for Admin Console
 * ------ maintenance history ------
 * Modified by Teddy Ding on 12/15/2020. Fix the problem of displaying and changing the data of notetype and entitytype.
 * Modified by Todd Yu on 8/15/2021. Refine the code to match coding standard
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import { Subject, Subscription } from 'rxjs';
import { businessConstants } from '../../../../tamalelibs/constants/business.constants';

import { AdminPageFeedback, AdminPageFeedbackTypes, NoteTypesSubjectTypes, EntityTypesSubjectTypes, GeneralPageSubjectTypes } from '../../admin-page-setting.model';

@Component({
    selector: 'tam-admin-page-widget',
    templateUrl: './admin-page-widget.component.html',
    styleUrls: ['./admin-page-widget.component.scss']
})
export class AdminPageWidgetComponent implements OnInit, OnDestroy, ICellRendererAngularComp {
    checkboxValue: boolean;
    isDefaultType: boolean;
    type: string;
    toggleRow = false;
    isDisabled = false;

    private _data: any;
    private _feedbackSubject$: Subject<AdminPageFeedback>;
    private _field: string;
    private _feedbackType: NoteTypesSubjectTypes | EntityTypesSubjectTypes | GeneralPageSubjectTypes;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    /** Initialize component type and data when created by grid. Data params are
          * found in admin page constants
          */
    agInit(params: any) {
        this._data = params.data;
        this._feedbackSubject$ = params.feedbackSubject$();
        this.type = params.type;
        this._feedbackType = params.feedbackType;
        this._initDefaultValue(params);
    }

    /** Refresh called by grid */
    refresh(params: any): boolean {
        return false;
    }

    ngOnInit() { }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    /** Initialize widget's appearance based on params in admin page constants and entity/note data */
    private _initDefaultValue(params: any) {
        switch (params.feedbackType) {
            case NoteTypesSubjectTypes.DEFAULT_EVENT_TYPE:
                this.isDefaultType = this._data['default-event'] ? this._data['default-event'] : false;
                // Default Event Type cannot be chosen until the entry can display in TamaleWeb
                if ('display-web' in this._data && this._data.usage) {
                    this.isDisabled = (!this._data['display-web'] || !this._data.usage.includes(businessConstants.EntryTypes.event)) ? true : this.isDisabled;
                }
                break;
            case NoteTypesSubjectTypes.DEFAULT_NOTE_TYPE:
                this.isDefaultType = this._data['default-note'] ? this._data['default-note'] : false;
                // Default Entry Type cannot be chosen until the entry can display in TamaleWeb
                if ('display-web' in this._data && this._data.usage) {
                    this.isDisabled = (!this._data['display-web'] || !this._data.usage.includes(businessConstants.EntryTypes.note)) ? true : this.isDisabled;
                }
                break;
            case NoteTypesSubjectTypes.DISPLAY_TAM_WEB:
                this.isDisabled = (this._data['name'] === 'TamaleNote') ? true : false;
                // Users cannot undo Display in Tamaleweb if the entry is a default entry type
                if ('default-note' in this._data || 'default-event' in this._data) {
                    this.isDisabled = this._data['default-note'] || this._data['default-event'] ? true : this.isDisabled;
                }
                if ('display-web' in this._data) {
                    this.checkboxValue = this._data['display-web'];
                } else {
                    this.checkboxValue = true;
                }
                break;
            case EntityTypesSubjectTypes.DEFAULT_ENTITY_TYPE:
                this.isDisabled = (this._data['name'] === 'Contact') ? true : false;
                // Default Entity Type cannot be chosen until the entity can display in TamaleWeb
                if ('display-web' in this._data) {
                    this.isDisabled = (!this._data['display-web']) ? true : this.isDisabled;
                }
                this.isDefaultType = this._data['default-entity-type'] ? this._data['default-entity-type'] : false;
                break;
            case EntityTypesSubjectTypes.DISPLAY_TAM_WEB:
                this.isDisabled = (this._data['name'] === 'Contact' || this._data['name'] === 'Corporate') ? true : false;
                // Users cannot undo Display in Tamaleweb if the entity is a default entity type
                if ('default-entity-type' in this._data) {
                    this.isDisabled = this._data['default-entity-type'] ? true : this.isDisabled;
                }
                if ('display-web' in this._data) {
                    this.checkboxValue = this._data['display-web'];
                } else {
                    this.checkboxValue = true;
                }
                break;
            case GeneralPageSubjectTypes.FMR:
                if ('FMR' in this._data) {
                    this.checkboxValue = this._data['FMR'];
                } else {
                    this.checkboxValue = true;
                }
                break;
            default:
                break;
        }
    }

    /** Helper function that prepares the body of the http request according to widget state */
    private _generateChanges(): any {
        switch (this._feedbackType) {
            case NoteTypesSubjectTypes.DEFAULT_EVENT_TYPE:
                this._data['default-event'] = true;
                break;
            case NoteTypesSubjectTypes.DEFAULT_NOTE_TYPE:
                this._data['default-note'] = true;
                break;
            case NoteTypesSubjectTypes.DISPLAY_TAM_WEB:
                this._data['display-web'] = this.checkboxValue;
                break;
            case EntityTypesSubjectTypes.DEFAULT_ENTITY_TYPE:
                this._data['default-entity-type'] = true;
                break;
            case EntityTypesSubjectTypes.DISPLAY_TAM_WEB:
                this._data['display-web'] = this.checkboxValue;
                break;
            case GeneralPageSubjectTypes.FMR:
                this._data['FMR'] = this.checkboxValue;
                break;
            default:
                break;
        }
    }

    /** Called when the Radio Button of the widget is clicked. Dispatches switch default action to grid page */
    onRadioClick() {
        if (!this.isDisabled) {
            this._generateChanges();
            this._feedbackSubject$.next({
                type: AdminPageFeedbackTypes.SWITCH_DEFAULT,
                payload: {
                    data: this._data,
                    defaultTypeField: this._feedbackType
                }
            });
        }
    }

    /** Called when the Radio Button of the widget is clicked. Dispatches toggle web action to grid page */
    onCheckBoxClick() {
        if (!this.isDisabled) {
            this._generateChanges();
            this._feedbackSubject$.next({
                type: AdminPageFeedbackTypes.TOGGLE_WEB,
                payload: {
                    data: this._data,
                    credentialField: this._field ? this._field : this._feedbackType
                }
            });
        }
    }

    /** For future use with system credentials page */
    onToggleRow() {
    }
}
