/**
 * Created by Daniel Wang on 12/20/2021.
 * Description: TextAreaField Component
 * ------ maintenance history ------
 */

import { Component, Input, OnDestroy, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { fromEvent, Subscription } from 'rxjs';

import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { FieldEvents, FieldConfig, FieldActions } from './field.model';

@Component({
    selector: 'tam-rich-editor-field',
    templateUrl: './rich-editor-field.component.html',
    styleUrls: ['./field.component.scss', './rich-editor-field.component.scss']
})
export class RichEditorFieldComponent implements OnInit, AfterViewInit, OnDestroy {
    //#region public properties
    @Input() config: FieldConfig;
    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false }) tooltipDir: TooltipDirective;
    @Input() viewMode: 'viewOnly' | 'full' = 'viewOnly';
    @ViewChild('froalaEditor', { static: false }) froalaEditor: ElementRef;

    get isInnerBorder(): boolean {
        return this.viewMode === 'viewOnly';
    }

    invalid = false;
    isIPAD = false;
    requireInvalid = false;
    options: object;
    //#endregion

    //#region private properties
    /**
    * the subscription array that would be unsubscribed on destroying.
    */
    private _destroySubscriptions: Array<Subscription> = [];
    //#endregion

    constructor(
        private _deviceService: DeviceDetectorService,
        private _utils: UtilsService,
    ) {
        const _this = this; // define '_this' for froala event.
        this.options = {
            key: 'XAG4eH3A3A6A7C5B5C-11VKOJ1FGULVKHXDXNDXc1d1Kg1SNdD5B4A4D4H3I3G3B7A4C3==',
            charCounterCount: true,
            toolbarButtons: {
                'moreText': {
                    'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily',
                        'fontSize', 'textColor', 'backgroundColor', 'clearFormatting',
                        'paragraphFormat', 'align', 'outdent',
                        'indent', 'formatUL', 'formatOL', 'quote',
                        'insertLink', 'insertImage', 'insertTable', 'fontAwesome',
                        'insertHR', 'undo', 'redo', 'fullscreen',
                        'lineHeight', 'subscript', 'superscript', 'specialCharacters'],
                    'buttonsVisible': 23
                },
            },
            toolbarSticky: false,
            attribution: false,
            imageInsertButtons: ['imageUpload', 'imageBack'],
            fontFamilyDefaultSelection: 'Font',
            quickInsertButtons: ['image', 'table', 'ul', 'ol', 'hr'],
            imageUploadRemoteUrls: false,
            fontSizeSelection: true,
            fontSizeUnit: 'pt',
            useClasses: false,
            pasteAllowLocalImages: true,
            pastePlain: false,
            spellcheck: true,
            height: 240,
            imageDefaultWidth: 0,
            imageDefaultAlign: 'left',
            fontSizeDefaultSelection: '11pt',
            linkAlwaysBlank: true,
            htmlUntouched: true,
            events: {
                'image.beforePasteUpload': function (files) {
                    const editor = this;
                    if (files.length) {
                        // Create a File Reader.
                        const reader = new FileReader();
                        // Set the reader to insert images when they are loaded.
                        reader.onload = function (e) {
                            const result = reader.result;
                            editor.image.insert(result, null, null, editor.image.get());
                        };
                        // Read image as base64.
                        reader.readAsDataURL(files[0]);
                    }
                    editor.popups.hideAll();
                    return false;
                },
                'image.beforeUpload': function (files) {
                    const editor = this;
                    if (files.length) {
                        // Create a File Reader.
                        const reader = new FileReader();
                        // Set the reader to insert images when they are loaded.
                        reader.onload = function (e) {
                            const result = reader.result;
                            editor.image.insert(result, null, null, editor.image.get());
                        };
                        // Read image as base64.
                        reader.readAsDataURL(files[0]);
                    }
                    editor.popups.hideAll();
                    // Stop default upload chain.
                    return false;
                },
                'initialized': function () {
                    // view control should use editable and config control use disabled
                    const editor = this;
                    if (_this.config.disabled || !_this.config.editable) {
                        if (editor && editor.toolbar) {
                            editor.toolbar.hide();
                        }
                        this.edit.off(); // disabled froala editor
                    }
                }
            }
        };
    }

    //#region public methods
    ngOnInit() {
        if (this.config.disabled) {
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this.invalid = this.validate();
        this._destroySubscriptions.push(
            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validate() || this.validateRequire(),
            },
        });
    }

    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
        if (this.config.disabled || !this.config.editable) {
            const richEditor = this.froalaEditor.nativeElement.querySelector('#froala-editor');
            if (richEditor) {
                richEditor.froalaEditor('edit.off');
            }
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validate(): boolean {
        return this.config.field.value && (this.config.minlength || this.config.minlength === 0) && this.config.minlength > this.config.field.value.length;
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && !this.config.field.value) {
            return true;
        } else {
            return false;
        }
    }

    valueChange(event): void {
        this.requireInvalid = false;
        this.invalid = this.validate();
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: this.config.field.value,
            },
        });
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validate() || this.validateRequire(),
            },
        });
    }
    //#endregion

    //#region private methods
    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        }
    }
    //#endregion
}
