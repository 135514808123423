/**
 * Created by Abner Sui on 12/19/2018.
 * Description:
 *  Brief Object
 * ------ maintenance history ------
 */

export class BriefObject {
    id: any;
    value: string;

    constructor(id: string, value: string) {
        this.id = id;
        this.value = value;
    }
}
