/**
 * Created by Yu Zhang on 6/13/17.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';

@Injectable()
export class FileTransferService {
    constructor(private _transportService: TransportService) { }

    public getFileByDataLink(dataLink: string, filePath: string): Observable<any> {
        const options = {
            url: dataLink + '?userid=' + AppConfig.userId,
            headers: {
                'Authorization': '[token' + AppConfig.token + 'token]'
            }
        };
        return this._transportService.getFile(options, filePath);
    }

    public removeFileByName(fileNameList: string[]): Observable<any> {
        const url = `${AppConfig.fileEndpoint}`;
        const options = {
            params: { filename: fileNameList }
        };
        return this._transportService.delete(url, options);
    }
}
