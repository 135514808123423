/**
 * Create by Alex
 * Description:
 *
 * ------ maintenance history ------
 * 2024/8/22 added to support impacted configurations
 */

import { businessConstants } from '../constants/business.constants';

export class ImpactedDashboardConfig {
    name: string;
    type: string;
    setting: string;

    /**
     * Parse the impacted dashboards from response
     * @param serverResponse
     * @returns A array of impacted dashboards
     */
    static parse(serverResponse): Array<ImpactedDashboardConfig> {
        const serverRes = serverResponse['screen-list'];
        if (!serverRes) {
            return null;
        }
        const impactedDashboardsConfig: Array<ImpactedDashboardConfig> = new Array<ImpactedDashboardConfig>();

        if (serverRes && serverRes.length > 0) {
            serverRes.forEach(item => {
                impactedDashboardsConfig.push(
                    {
                        name: item.name,
                        type: businessConstants.impactedConfigType.dashboard,
                        setting: item.field
                    }
                );
            });
        }
        return impactedDashboardsConfig;
    }

    static highlightText(input: string): string {
        const escapeHtml = (unsafe: string) => {
            return unsafe
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;');
        };

        const regex = /<<(.*?)>>(ColumnGroup)?/g;

        const highlighted = input.replace(regex, (match, p1, p2) => {
            const escapedContent = escapeHtml(p1);
            if (p2) {
                return `<span style="color: #1e71d5;">&lt;&lt;${escapedContent}&gt;&gt;${p2}</span>`;
            } else {
                return `<span style="color: #1e71d5;">&lt;&lt;${escapedContent}&gt;&gt;</span>`;
            }
        });

        return highlighted;
    }
}
