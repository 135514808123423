import { DataModelBase } from './base.model';

export class CorpOfficer extends DataModelBase {
    age: string;
    position: string;

    static parse(serverResponse): CorpOfficer {
        return {
            age: serverResponse.data.age,
            id: serverResponse.data.id,
            name: serverResponse.data.name,
            position: serverResponse.data.position
        };
    }
}
