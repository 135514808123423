/**
 * Created by Daniel on 11/01/18.
 * Description:
 *
 * ------ maintenance history ------
 * 04/02/2022 Simon Zhao changed all functions into static methods.
 */

export class DateHelperWebService {
    /**
     * format date according to the format option
     * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
     * @param dd: day
     * @param mm: month
     * @param yyyy: year
     * @param format: format option
     * @returns string: formatted date string
     */
    static formatDate(month: number, day: number, year: number, format: string): string {
        const dd = DateHelperWebService.zeroize(day);
        const mm = DateHelperWebService.zeroize(month);
        const yyyy = DateHelperWebService.zeroize(year, 4);
        switch (format) {
            case 'mm-dd-yyyy':
                return mm + '-' + dd + '-' + yyyy;
            default:
                return mm + '/' + dd + '/' + yyyy;
        }
    }

    static formatEntireDate(date, format): number {
        if (date !== null && date !== undefined) {
            const value = new Date(date);
            let result: Date;
            if (value instanceof Date && !isNaN(value.getTime())) {
                if (format === '0') { // MM/dd/yyyy hh:mm TT
                    result = new Date(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), 0);
                } else if (format === '2') { // MMM yyyy
                    result = new Date(value.getFullYear(), value.getMonth(), 1, 0, 0, 0);
                } else if (format === '3') { // yyyy
                    result = new Date(value.getFullYear(), 0, 1, 0, 0, 0);
                } else {
                    result = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0);
                }
                return result.getTime();
            }
            return date;
        }
        return date;
    }

    /**
     * get formatted string of date object. This method coming from internet.
     * param {Date} dateVal The date object
     * param {String} format The format string
     */
    static getDateString(dateVal: Date, format: string): string {
        const d = dateVal;
        const zeroize = DateHelperWebService.zeroize;

        const returnValue = format.replace(/"[^"]*"|'[^']*'|\b(?:d{1,4}|M{1,4}|yy(?:yy)?|([hHmstT])\1?|[lLZ])\b/g, function ($1: any) {
            switch ($1) {
                case 'd': return d.getDate();
                case 'dd': return zeroize(d.getDate());
                case 'ddd': return ['Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'][d.getDay()];
                case 'dddd': return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()];
                case 'M': return d.getMonth() + 1;
                case 'MM': return zeroize(d.getMonth() + 1);
                case 'MMM': return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()];
                // tslint:disable-next-line: max-line-length
                case 'MMMM': return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()];
                case 'yy': return String(d.getFullYear()).substr(2);
                case 'yyyy': return d.getFullYear();
                case 'h': return d.getHours() % 12 || 12;
                case 'hh': return zeroize(d.getHours() % 12 || 12);
                case 'H': return d.getHours();
                case 'HH': return zeroize(d.getHours());
                case 'm': return d.getMinutes();
                case 'mm': return zeroize(d.getMinutes());
                case 's': return d.getSeconds();
                case 'ss': return zeroize(d.getSeconds());
                case 'l': return zeroize(d.getMilliseconds(), 3);
                case 'L': let m = d.getMilliseconds();
                    if (m > 99) { m = Math.round(m / 10); }
                    return zeroize(m);
                case 'tt': return d.getHours() < 12 ? 'am' : 'pm';
                case 'TT': return d.getHours() < 12 ? 'AM' : 'PM';
                case 'Z': return d.toUTCString().match(/[A-Z]+$/);
                // Return quoted strings with the surrounding quotes removed
                default: return $1.substr(1, $1.length - 2);
            }
        });

        return returnValue;
    }

    /**
     * reference: https://codepen.io/paulund/pen/kkXJpa
     * returns {number}: timezone offset in minutes without daylight saving
     */
    static getTimezoneOffsetInMinutes() {
        const jan = new Date(new Date().getFullYear(), 0, 1);
        const jul = new Date(new Date().getFullYear(), 6, 1);
        return -Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }

    /**
    * get contry timezone id for daylight saving handle
    * returns {String}: timezone id like 'Asia/Shanghai'
    */
    static getContryTimezoneId() {
        return new Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    /**
         * get date of today with the required date format
         * current supported format: 'mm-dd-yyyy' and 'mm/dd/yyyy'
         * @param format: format option, as a string
         * @returns: formatted today as a string/ date
         */
    static getToday(format: string = null): any {
        const today = new Date();
        if (format == null) {
            return today;
        } else {
            const dd = today.getDate(),
                mm = today.getMonth() + 1,
                yyyy = today.getFullYear();
            return DateHelperWebService.formatDate(mm, dd, yyyy, format);
        }
    }

    /**
         * return today's date with 00:00:00.000
         */
    static getDateOnlyOfToday(): Date {
        const today = new Date();
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    }

    /**
     * public function      : get weekday by date
     * @param date          : input date
     */
    static getWeekdayByDate(date: any): any {
        if (date !== undefined) {
            const weekArray = new Array('Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat');
            const myDate = new Date(date);
            const week = weekArray[myDate.getDay()];
            return week;
        }
    }

    /**
     * determin if certain date is a future date or not
     * param strDate
     */
    static isFutureDateByTimeString(strDate) {
        return new Date().getTime().toString() < strDate;
    }

    /**
     * parse the data of date type safely, specially handle the compatible issue with firefox.
     * example1:
     * DateHelperWebService.parseDateFunc('01-01-2010'); // the return value same as new Date('01-01-2010'), but compatible with Firefox.
     * example2:
     * const today = new Date().getTime();
     * DateHelperWebService.parseDateFunc(today);// the return value same as new Date(), but compatible with Firefox.
     *
     * @param dateValue the given date value, both ticks and date in string format are supported, ex: 1648186665084, '01-01-2010', or '01/01/2011'.
     * @returns the date format value.
     */
    static parseDateFunc(dateValue: any): Date {
        const parseResult = Date.parse(dateValue);
        if (isNaN(dateValue) && isNaN(parseResult)) {
            // neither the given data nor the parse result is NaN.
            // an invalid date, try to replace hyphens with slashes for the case of Firefox.
            if (!!dateValue && dateValue.toString().length > 0 && dateValue.toString().indexOf('-') > 0) {
                // seems that Firefox can not recognize the date string with hyphens, so replace all hyphens with slashes.
                const compatibleDateStr = dateValue.toString().replace(/-/g, '/');
                const compatibleParseResult = Date.parse(compatibleDateStr);
                if (!isNaN(compatibleParseResult)) {
                    // valid date
                    return new Date(compatibleParseResult);
                } else {
                    throw new Error('Detect invalid date!');
                }
            } else {
                throw new Error('Detect invalid date!');
            }
        } else {
            // a valid date usually in a number format.
            return new Date(dateValue);
        }
    }


    static zeroize(val: number, length: number = 2): string {
        const value = String(val);
        let zeros = '';
        for (let i = 0; i < (length - value.length); i++) {
            zeros += '0';
        }
        return zeros + value;
    }
}
