/**
 * Created by Yoyo Fang on 06/06/2020.
 * Description:
 *
 * ------ maintenance history ------
 * 06/29/2020   Yoyo Fang   Add folder structure reducer
 */

import { INTRALINKS_ACTIONS } from './intralinks.actions';
import { combineReducers, createSelector } from '@ngrx/store';
import { IntralinksFile } from '../models/intralinks.model';

const intralinksReducer = combineReducers({
    status: pageStatusReducer,
    folders: folderStructureReducer,
    files: filesReducer
});

export const getIntralinksState = (state) => state.intralink;
export const getPageStatusState = createSelector(getIntralinksState, (state: any) => state.status);
export const getFolderStructureState = createSelector(getIntralinksState, (state: any) => state.folders);
export const getFolderContentState = createSelector(getIntralinksState, (state: any) => state.files);

function pageStatusReducer(state: string = '', action) {
    switch (action.type) {
        case INTRALINKS_ACTIONS.ADD_SCREEN:
            return { name: action.payload.name, workflow: 'add', id: action.payload.id };
        case INTRALINKS_ACTIONS.UPDATE_SCREEN:
            return { name: action.payload.name, workflow: 'edit' };
        case INTRALINKS_ACTIONS.CREATE_ACCOUNT_FAIL:
            return { workflow: 'add_fail', error: action.payload };
        case INTRALINKS_ACTIONS.UPDATE_ACCOUNT_FAIL:
            return { workflow: 'edit_fail', error: action.payload };
        case INTRALINKS_ACTIONS.DELETE_ACCOUNT_SUCCESS:
            return { workflow: 'delete', result: true };
        case INTRALINKS_ACTIONS.DELETE_ACCOUNT_FAILED:
            return { workflow: 'delete', result: false, error: action.payload };
        case INTRALINKS_ACTIONS.REACH_SESSION_LIMIT:
            return { workflow: 'fail' };
        default:
            return null;
    }
}

function folderStructureReducer(state: any = {}, action) {
    switch (action.type) {
        case INTRALINKS_ACTIONS.WORKSPACE_LOADED:
            // let changed = !state || !state.folders || state.folders.length !== action.payload.items.length;
            // for (let i = 0; !changed && i < state.folders.length; i++) {
            //     changed = changed || state.folders[i].id !== action.payload.items[i].id;
            // }
            // if (changed) {
            return {
                unloaded: action.payload.items.length,
                folders: action.payload.items,
                workflow: 'reload'
            };
        // } else {
        //     return state;
        // }
        case INTRALINKS_ACTIONS.FOLDER_LOADED:
            for (let i = 0; state.unloaded > 0 && i < state.folders.length; i++) {
                if (state.folders[i].id === action.payload.id) {
                    state.folders[i] = action.payload;
                    state.unloaded--;
                    return Object.assign({}, state);
                }
            }
            return state;
        case INTRALINKS_ACTIONS.WORKSPACE_LOAD_FAILED:
            return { error: action.payload };
        case INTRALINKS_ACTIONS.REACH_SESSION_LIMIT:
            return { error: action.payload, session: true };
        case INTRALINKS_ACTIONS.RELOAD_ITEM_CONTENT:
            if (action.payload.intralinkData) {
                const file = action.payload.intralinkData as IntralinksFile;
                file.noteId = action.payload.entryid;
                file.noteTitle = action.payload.subject;
                file.isSubmitted = true;
                return Object.assign({}, state, { workflow: 'submit', file: file });
            } else {
                return state;
            }
        case INTRALINKS_ACTIONS.CLEAR_FOLDER_CACHE:
            return {};
        default:
            return state;
    }
}

function filesReducer(state: any[] = [], action) {
    switch (action.type) {
        case INTRALINKS_ACTIONS.FOLDER_CONTENT_LOADED:
            return [...action.payload];
        case INTRALINKS_ACTIONS.RELOAD_ITEM_CONTENT:
            if (action.payload.intralinkData) {
                const files = action.payload.intralinkData as IntralinksFile[];
                files.forEach(item => {
                    item.noteId = action.payload.entryid;
                    item.noteTitle = action.payload.subject;
                    item.isSubmitted = true;
                });
                state = state.map(item => {
                    let flag = false;
                    let index = -1;
                    for (let i = 0; i < files.length; i++) {
                        if (item.id === files[i].id) {
                            flag = true;
                            index = i;
                            break;
                        }
                    }
                    if (flag) {
                        return files[index];
                    } else {
                        return item;
                    }
                });
                return [...state];
            } else {
                return state;
            }
        default:
            return state;
    }
}

export function getIntralinksgRedcuer(state, action) {
    return intralinksReducer(state, action);
}
