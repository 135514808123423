/**
 * Created by Abner Sui on 12/04/2019.
 * Description:
 *
 * ------ maintenance history ------
 * Add CREATE_WORKFLOW_BRIEF by Ella on 09/10/2020
 */

import { Action } from '@ngrx/store';

export enum WorkflowActionTypes {
    GET_ONE_PROCESS_DEFINITION = '[WORKFLOW] GET_ONE_PROCESS_DEFINITION',
    GET_ONE_PROCESS_DEFINITION_SUCCESS = '[WORKFLOW] GET_ONE_PROCESS_DEFINITION_SUCCESS',
    GET_ONE_PROCESS_DEFINITION_FAILED = '[WORKFLOW] GET_ONE_PROCESS_DEFINITION_FAILED',
    CLEAR_ONE_PROCESS_DEFINITION = '[WORKFLOW] CLEAR_ONE_PROCESS_DEFINITION',
    PROCESS_DEFINITION_ADD = '[WORKFLOW] PROCESS_DEFINITION_ADD',
    PROCESS_INSTANCE_ADD_OR_UPDATE = '[WORKFLOW] PROCESS_INSTANCE_ADD_OR_UPDATE',
    PROCESS_INSTANCE_ADD_OR_UPDATE_SUCCESS = '[WORKFLOW] PROCESS_INSTANCE_ADD_OR_UPDATE_SUCCESS',
    PROCESS_INSTANCE_ADD_OR_UPDATE_FAILED = '[WORKFLOW] PROCESS_INSTANCE_ADD_OR_UPDATE_FAILED',
    PROCESS_INSTANCE_DELETE = '[WORKFLOW] PROCESS_INSTANCE_DELETE',
    PROCESS_INSTANCE_DELETE_SUCCESS = '[WORKFLOW] PROCESS_INSTANCE_DELETE_SUCCESS',
    PROCESS_INSTANCE_DELETE_FAILED = '[WORKFLOW] PROCESS_INSTANCE_DELETE_FAILED',
    CREATE_WORKFLOW_BRIEF = '[WORKFLOW] CREATE_WORKFLOW_BRIEF',
    CLEAR_WORKFLOW_BRIEF = '[WORKFLOW] CLEAR_WORKFLOW_BRIEF',
    ADD_STAGE = '[WORKFLOW] CREATE_STAGE',
    SET_STAGE = '[WORKFLOW] SET_STAGE',
    DELETE_STAGE = '[WORKFLOW] DELETE_STAGE',
    DELETE_STAGE_SUCCESS = '[WORKFLOW] DELETE_STAGE_SUCCESS',
    DELETE_STAGE_FAILED = '[WORKFLOW] DELETE_STAGE_FAILED',
    CLEAR_STAGE = '[WORKFLOW] CLEAR_STAGE',
    GET_ALL_FIELDS = '[WORKFLOW] GET_ALL_FIELDS',
    GET_ALL_FIELDS_SUCCESS = '[WORKFLOW] GET_ALL_FIELDS_SUCCESS',
    GET_ALL_FIELDS_FAILED = '[WORKFLOW] GET_ALL_FIELDS_FAILED',
    ADD_ONE_FIELD = '[WORKFLOW] ADD_ONE_FIELD',
    UPDATE_MULTIPLE_FIELDS = '[WORKFLOW] UPDATE_MULTIPLE_FIELDS',
    USE_ONE_FIELD = '[WORKFLOW] USE_ONE_FIELD',
    UNUSE_ONE_FIELD = '[WORKFLOW] UNUSE_ONE_FIELD',
    SET_USED_FIELD = '[WORKFLOW] SET_USED_FIELD',
    CLEAR_USED_FIELD = '[WORKFLOW] CLEAR_USED_FIELD',
    SET_WORKFLOW_KANBAN_FILTER = '[WORKFLOW] SET_WORKFLOW_KANBAN_FILTER',
    CLEAR_WORKFLOW_KANBAN_FILTER = '[WORKFLOW] CLEAR_WORKFLOW_KANBAN_FILTER',
    SET_WORKFLOW_KANBAN_GRID_COLUMN_STATUS = '[WORKFLOW] SET_WORKFLOW_KANBAN_GRID_COLUMN_STATUS',
    CLEAR_WORKFLOW_KANBAN_GRID_COLUMN_STATUS = '[WORKFLOW] CLEAR_WORKFLOW_KANBAN_GRID_COLUMN_STATUS',
    SET_ADMIN_VISIBILITY = '[WORKFLOW] SET_ADMIN_VISIBILITY',
    ADMIN_VISIBILITY_CHANGE = '[WORKFLOW] ADMIN_VISIBILITY_CHANGE',
    ADMIN_VISIBILITY_CHANGE_FAILED = '[WORKFLOW] ADMIN_VISIBILITY_CHANGE_FAILED',
    UPDATE_CURRENT_WORKFLOW_FIELDS = '[WORKFLOW] UPDATE_CURRENT_WORKFLOW_FIELDS',
    SET_CURRENT_WORKFLOW_FIELDS = '[WORKFLOW] SET_CURRENT_WORKFLOW_FIELDS',
    CLEAR_CURRENT_WORKFLOW_FIELDS = '[WORKFLOW] CLEAR_CURRENT_WORKFLOW_FIELDS',
    UPDATE_CURRENT_WORKFLOW_ALL_FIELDS = '[WORKFLOW] UPDATE_CURRENT_WORKFLOW_ALL_FIELDS',
    DELETE_CURRENT_WORKFLOW_ONE_FIELD = '[WORKFLOW] DELETE_CURRENT_WORKFLOW_ONE_FIELD',
    SET_CURRENT_WORKFLOW_ALL_FIELDS = '[WORKFLOW] SET_CURRENT_WORKFLOW_ALL_FIELDS',
    CLEAR_CURRENT_WORKFLOW_ALL_FIELDS = '[WORKFLOW] CLEAR_CURRENT_WORKFLOW_ALL_FIELDS',
    GET_IDS = '[WORKFLOW] GET_IDS',
    GET_IDS_SUCCESS = '[WORKFLOW] GET_IDS_SUCCESS',
    GET_IDS_FAILED = '[WORKFLOW] GET_IDS_FAILED',
    DELETE_ONE_ID = '[WORKFLOW] DELETE_ONE_ID',
    CLEAR_IDS = '[WORKFLOW] CLEAR_IDS',
    GET_CURRENT_WORKFLOW_TASKS = '[WORKFLOW] GET_CURRENT_WORKFLOW_TASKS',
    ADD_CURRENT_WORKFLOW_TASK = '[WORKFLOW] ADD_CURRENT_WORKFLOW_TASK',
    DELETE_CURRENT_WORKFLOW_TASK = '[WORKFLOW] DELETE_CURRENT_WORKFLOW_TASK',
    CLEAR_CURRENT_WORKFLOW_TASK = '[WORKFLOW] CLEAR_CURRENT_WORKFLOW_TASK',
    UPDATE_CURRENT_WORKFLOW_TASK = '[WORKFLOW] UPDATE_CURRENT_WORKFLOW_TASK',
    GET_AUTOMATION_NODE = '[WORKFLOW] GET_AUTOMATION_NODE',
    ADD_AUTOMATION_NODE = '[WORKFLOW] ADD_AUTOMATION_NODE',
    UPDATE_AUTOMATION_NODE = '[WORKFLOW] UPDATE_AUTOMATION_NODE',
    DELETE_AUTOMATION_NODE = '[WORKFLOW] DELETE_AUTOMATION_NODE',
    CLEAR_AUTOMATION_NODE = '[WORKFLOW]CLEAR_AUTOMATION_NODE'
}

export class GetOneProcessDefinition implements Action {
    readonly type = WorkflowActionTypes.GET_ONE_PROCESS_DEFINITION;
    constructor(public payload: any) { }
}

export class GetOneProcessDefinitionSuccess implements Action {
    readonly type = WorkflowActionTypes.GET_ONE_PROCESS_DEFINITION_SUCCESS;
    constructor(public payload: any) { }
}

export class GetOneProcessDefinitionFailed implements Action {
    readonly type = WorkflowActionTypes.GET_ONE_PROCESS_DEFINITION_FAILED;
    constructor(public payload: any) { }
}


export class ClearOneProcessDefinition implements Action {
    readonly type = WorkflowActionTypes.CLEAR_ONE_PROCESS_DEFINITION;
    constructor(public payload: any) { }
}

export class ProcessInstanceAddOrUpdate implements Action {
    readonly type = WorkflowActionTypes.PROCESS_INSTANCE_ADD_OR_UPDATE;
    constructor(public payload: any) { }
}

export class ProcessInstanceAddOrUpdateSuccess implements Action {
    readonly type = WorkflowActionTypes.PROCESS_INSTANCE_ADD_OR_UPDATE_SUCCESS;
    constructor(public payload: any) { }
}

export class ProcessInstanceAddOrUpdateFailed implements Action {
    readonly type = WorkflowActionTypes.PROCESS_INSTANCE_ADD_OR_UPDATE_FAILED;
    constructor(public payload: any) { }
}

export class ProcessInstanceDelete implements Action {
    readonly type = WorkflowActionTypes.PROCESS_INSTANCE_DELETE;
    constructor(public payload: any) { }
}

export class ProcessInstanceDeleteSuccess implements Action {
    readonly type = WorkflowActionTypes.PROCESS_INSTANCE_DELETE_SUCCESS;
    constructor(public payload: any) { }
}

export class ProcessInstanceDeleteFailed implements Action {
    readonly type = WorkflowActionTypes.PROCESS_INSTANCE_DELETE_FAILED;
    constructor(public payload: any) { }
}

export class CreateWorkflowBrief implements Action {
    readonly type = WorkflowActionTypes.CREATE_WORKFLOW_BRIEF;
    constructor(public payload: any) { }
}

export class ClearWorkflowBrief implements Action {
    readonly type = WorkflowActionTypes.CLEAR_WORKFLOW_BRIEF;
    constructor(public payload: any) { }
}

export class AddStage implements Action {
    readonly type = WorkflowActionTypes.ADD_STAGE;
    constructor(public payload: any) { }
}

export class SetStage implements Action {
    readonly type = WorkflowActionTypes.SET_STAGE;
    constructor(public payload: any) { }
}

export class DeleteStage implements Action {
    readonly type = WorkflowActionTypes.DELETE_STAGE;
    constructor(public payload: any) { }
}

export class DeleteStageSuccess implements Action {
    readonly type = WorkflowActionTypes.DELETE_STAGE_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteStageFailed implements Action {
    readonly type = WorkflowActionTypes.DELETE_STAGE_FAILED;
    constructor(public payload: any) { }
}

export class ClearStage implements Action {
    readonly type = WorkflowActionTypes.CLEAR_STAGE;
    constructor(public payload: any) { }
}

export class GetAllFields implements Action {
    readonly type = WorkflowActionTypes.GET_ALL_FIELDS;
    constructor(public payload: any) { }
}

export class GetAllFieldsSuccess implements Action {
    readonly type = WorkflowActionTypes.GET_ALL_FIELDS_SUCCESS;
    constructor(public payload: any) { }
}

export class GetAllFieldsFailed implements Action {
    readonly type = WorkflowActionTypes.GET_ALL_FIELDS_FAILED;
    constructor(public payload: any) { }
}

export class AddOneField implements Action {
    readonly type = WorkflowActionTypes.ADD_ONE_FIELD;
    constructor(public payload: any) { }
}

export class UpdateMultipleFields implements Action {
    readonly type = WorkflowActionTypes.UPDATE_MULTIPLE_FIELDS;
    constructor(public payload: any) { }
}

export class UseOneField implements Action {
    readonly type = WorkflowActionTypes.USE_ONE_FIELD;
    constructor(public payload: any) { }
}

export class UnuseOneField implements Action {
    readonly type = WorkflowActionTypes.UNUSE_ONE_FIELD;
    constructor(public payload: any) { }
}

export class SetUsedField implements Action {
    readonly type = WorkflowActionTypes.SET_USED_FIELD;
    constructor(public payload: any) { }
}
export class ClearUsedField implements Action {
    readonly type = WorkflowActionTypes.CLEAR_USED_FIELD;
    constructor(public payload: any) { }
}

export class SetWorkflowKanbanFilter implements Action {
    readonly type = WorkflowActionTypes.SET_WORKFLOW_KANBAN_FILTER;
    constructor(public payload: any) { }
}

export class ClearWorkflowKanbanFilter implements Action {
    readonly type = WorkflowActionTypes.CLEAR_WORKFLOW_KANBAN_FILTER;
    constructor(public payload: any) { }
}

export class SetWorkflowKanbanGridColumnStatus implements Action {
    readonly type = WorkflowActionTypes.SET_WORKFLOW_KANBAN_GRID_COLUMN_STATUS;
    constructor(public payload: any) { }
}

export class ClearWorkflowKanbanGridColumnStatus implements Action {
    readonly type = WorkflowActionTypes.CLEAR_WORKFLOW_KANBAN_GRID_COLUMN_STATUS;
    constructor(public payload: any) { }
}

export class WorkflowAdminVisibility implements Action {
    type: string = WorkflowActionTypes.SET_ADMIN_VISIBILITY;
    constructor(
        public payload: boolean
    ) { }
}

export class WorkflowAdminVisibilityChange implements Action {
    readonly type = WorkflowActionTypes.ADMIN_VISIBILITY_CHANGE;
    payload: { path: Array<string>, value: any, isManually: boolean };
    constructor(path: Array<string>, value: boolean, isManually = false) {
        this.payload = { path: path, value: value, isManually: isManually };
    }
}

export class UpdateCurrentWorkflowFields implements Action {
    readonly type = WorkflowActionTypes.UPDATE_CURRENT_WORKFLOW_FIELDS;
    constructor(public payload: any) { }
}

export class SetCurrentWorkflowFields implements Action {
    readonly type = WorkflowActionTypes.SET_CURRENT_WORKFLOW_FIELDS;
    constructor(public payload: any) { }
}

export class ClearCurrentWorkflowFields implements Action {
    readonly type = WorkflowActionTypes.CLEAR_CURRENT_WORKFLOW_FIELDS;
    constructor(public payload: any) { }
}

export class UpdateCurrentWorkflowAllFields implements Action {
    readonly type = WorkflowActionTypes.UPDATE_CURRENT_WORKFLOW_ALL_FIELDS;
    constructor(public payload: any) { }
}

export class DeleteCurrentWorkflowOneField implements Action {
    readonly type = WorkflowActionTypes.DELETE_CURRENT_WORKFLOW_ONE_FIELD;
    constructor(public payload: any) { }
}

export class SetCurrentWorkflowAllFields implements Action {
    readonly type = WorkflowActionTypes.SET_CURRENT_WORKFLOW_ALL_FIELDS;
    constructor(public payload: any) { }
}

export class ClearCurrentWorkflowAllFields implements Action {
    readonly type = WorkflowActionTypes.CLEAR_CURRENT_WORKFLOW_ALL_FIELDS;
    constructor(public payload: any) { }
}

export class GetIds implements Action {
    readonly type = WorkflowActionTypes.GET_IDS;
    constructor(public payload: any) { }
}

export class GetIdsSuccess implements Action {
    readonly type = WorkflowActionTypes.GET_IDS_SUCCESS;
    constructor(public payload: any) { }
}

export class GetIdsFailed implements Action {
    readonly type = WorkflowActionTypes.GET_IDS_FAILED;
    constructor(public payload: any) { }
}

export class DeleteOneId implements Action {
    readonly type = WorkflowActionTypes.DELETE_ONE_ID;
    constructor(public payload: any) { }
}

export class ClearIds implements Action {
    readonly type = WorkflowActionTypes.CLEAR_IDS;
    constructor(public payload: any) { }
}

export class GetCrrentWorkflowTasks implements Action {
    readonly type = WorkflowActionTypes.GET_CURRENT_WORKFLOW_TASKS;
    constructor(public payload: any) { }
}

export class AddCrrentWorkflowTasks implements Action {
    readonly type = WorkflowActionTypes.ADD_CURRENT_WORKFLOW_TASK;
    constructor(public payload: any) { }
}

export class DeleteCrrentWorkflowTasks implements Action {
    readonly type = WorkflowActionTypes.DELETE_CURRENT_WORKFLOW_TASK;
    constructor(public payload: any) { }
}

export class ClearCrrentWorkflowTasks implements Action {
    readonly type = WorkflowActionTypes.CLEAR_CURRENT_WORKFLOW_FIELDS;
    constructor(public payload: any) { }
}

export class UpdateCrrentWorkflowTasks implements Action {
    readonly type = WorkflowActionTypes.UPDATE_CURRENT_WORKFLOW_TASK;
    constructor(public payload: any) { }
}

export class GetAutomationNode implements Action {
    readonly type = WorkflowActionTypes.GET_AUTOMATION_NODE;
    constructor(public payload: any) { }
}


export class AddAutomationNode implements Action {
    readonly type = WorkflowActionTypes.ADD_AUTOMATION_NODE;
    constructor(public payload: any) { }
}

export class UpdateAutomationNode implements Action {
    readonly type = WorkflowActionTypes.UPDATE_AUTOMATION_NODE;
    constructor(public payload: any) { }
}

export class DeleteAutomationNode implements Action {
    readonly type = WorkflowActionTypes.DELETE_AUTOMATION_NODE;
    constructor(public payload: any) { }
}

export class ClearAutomationNode implements Action {
    readonly type = WorkflowActionTypes.CLEAR_AUTOMATION_NODE;
    constructor(public payload: any) { }
}

export type WorkflowActions = GetOneProcessDefinition |
    GetOneProcessDefinitionSuccess |
    GetOneProcessDefinitionFailed |
    ClearOneProcessDefinition |
    ProcessInstanceAddOrUpdate |
    ProcessInstanceAddOrUpdateSuccess |
    ProcessInstanceAddOrUpdateFailed |
    ProcessInstanceDelete |
    ProcessInstanceDeleteSuccess |
    ProcessInstanceDeleteFailed |
    CreateWorkflowBrief |
    ClearWorkflowBrief |
    AddStage |
    SetStage |
    DeleteStage |
    DeleteStageSuccess |
    DeleteStageFailed |
    ClearStage |
    GetAllFields |
    GetAllFieldsSuccess |
    GetAllFieldsFailed |
    AddOneField |
    UseOneField |
    UnuseOneField |
    SetUsedField |
    ClearUsedField |
    SetWorkflowKanbanFilter |
    ClearWorkflowKanbanFilter |
    SetWorkflowKanbanGridColumnStatus |
    ClearWorkflowKanbanGridColumnStatus |
    WorkflowAdminVisibility |
    WorkflowAdminVisibilityChange |
    UpdateCurrentWorkflowFields |
    SetCurrentWorkflowFields |
    ClearCurrentWorkflowFields |
    GetIds |
    GetIdsSuccess |
    GetIdsFailed |
    DeleteOneId |
    ClearIds |
    GetCrrentWorkflowTasks |
    AddCrrentWorkflowTasks |
    DeleteCrrentWorkflowTasks |
    ClearCrrentWorkflowTasks |
    UpdateCrrentWorkflowTasks |
    UpdateMultipleFields |
    UpdateCurrentWorkflowAllFields |
    DeleteCurrentWorkflowOneField |
    SetCurrentWorkflowAllFields |
    ClearCurrentWorkflowAllFields |
    GetAutomationNode |
    AddAutomationNode |
    UpdateAutomationNode |
    DeleteAutomationNode |
    ClearAutomationNode;
