/**
 * Created by Daniel on 11/7/18.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { PriorityStarComponent } from '../priority-star/priority-star.component';

@Component({
    selector: 'tam-priority-star-aggrid',
    templateUrl: './priority-star-aggrid.component.html',
    styleUrls: ['./priority-star-aggrid.component.scss']
})
export class PriorityStarAggridComponent extends PriorityStarComponent implements ICellRendererAngularComp {
    value: number;

    agInit(params: any): void {
        this.value = params.value;
    }

    refresh(): boolean {
        return false;
    }

}
