/**
 * created by Yu Zhang on 12/7/17.
 * Description:
 *
 * ------ maintenance history ------
 */
// thread list
export const GET_FIRST_PAGE = 'GET_FIRST_PAGE';
export const GET_FIRST_PAGE_PARENTNOTEOWNATTACHMENT = 'GET_FIRST_PAGE_PARENTNOTEOWNATTACHMENT';
export const GET_FIRST_PAGE_SUCCESS = 'GET_FIRST_PAGE_SUCCESS';
export const GET_FIRST_PAGE_FAILURE = 'GET_FIRST_PAGE_FAILURE';
export const GET_NEXT_PAGE = 'GET_NEXT_PAGE';
export const GET_NEXT_PAGE_PARENTNOTEOWNATTACHMENT = 'GET_NEXT_PAGE_PARENTNOTEOWNATTACHMENT';
export const GET_NEXT_PAGE_SUCCESS = 'GET_NEXT_PAGE_SUCCESS';
export const GET_NEXT_PAGE_FAILURE = 'GET_NEXT_PAGE_FAILURE';
export const CLEAR_THREAD_LIST = 'CLEAR_THREAD_LIST';
export const CLEAR_THREAD_MSG = 'CLEAR_THREAD_MSG';
export const GET_THREAD_LIST_WITH_PAGGING = 'GET_THREAD_LIST_WITH_PAGGING';
export const GET_THREAD_LIST_WITH_PAGGING_SUCCESS = 'GET_THREAD_LIST_WITH_PAGGING_SUCCESS';
export const GET_THREAD_LIST_WITH_PAGGING_FAILURE = 'GET_THREAD_LIST_WITH_PAGGING_FAILURE';

// thread search list All and Current
export const GET_FIRST_PAGE_WITH_KEYWORD_ALL = 'GET_FIRST_PAGE_WITH_KEYWORD_ALL';
export const GET_FIRST_PAGE_WITH_KEYWORD_ALL_SUCCESS = 'GET_FIRST_PAGE_WITH_KEYWORD_ALL_SUCCESS';
export const GET_FIRST_PAGE_WITH_KEYWORD_ALL_FAILURE = 'GET_FIRST_PAGE_WITH_KEYWORD_ALL_FAILURE';
export const GET_NEXT_PAGE_WITH_KEYWORD_ALL = 'GET_NEXT_PAGE_WITH_KEYWORD_All';
export const GET_NEXT_PAGE_WITH_KEYWORD_ALL_SUCCESS = 'GET_NEXT_PAGE_WITH_KEYWORD_All_SUCCESS';
export const GET_NEXT_PAGE_WITH_KEYWORD_ALL_FAILURE = 'GET_NEXT_PAGE_WITH_KEYWORD_ALL_FAILURE';
export const CLEAN_THREAD_SEARCH_LIST_ALL = 'CLEAN_THREAD_SEARCH_LIST_ALL';
export const CLEAN_THREAD_SEARCH_LIST_ALL_MSG = 'CLEAN_THREAD_SEARCH_LIST_ALL_MSG';

export const GET_FIRST_PAGE_WITH_KEYWORD_CURRENT = 'GET_FIRST_PAGE_WITH_KEYWORD_CURRENT';
export const GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_SUCCESS = 'GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_SUCCESS';
export const GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_FAILURE = 'GET_FIRST_PAGE_WITH_KEYWORD_CURRENT_FAILURE';
export const GET_NEXT_PAGE_WITH_KEYWORD_CURRENT = 'GET_NEXT_PAGE_WITH_KEYWORD_CURRENT';
export const GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_SUCCESS = 'GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_SUCCESS';
export const GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_FAILURE = 'GET_NEXT_PAGE_WITH_KEYWORD_CURRENT_FAILURE';
export const CLEAN_THREAD_SEARCH_LIST_CURRENT = 'CLEAN_THREAD_SEARCH_LIST_CURRENT';
export const CLEAN_THREAD_SEARCH_LIST_CURRENT_MSG = 'CLEAN_THREAD_SEARCH_LIST_CURRENT_MSG';

// recent notes entity
export const GET_FIRST_PAGE_RECENT_NOTES_ENTITY = 'GET_FIRST_PAGE_RECENT_NOTES_ENTITY';
export const GET_FIRST_PAGE_RECENT_NOTES_ENTITY_SUCCESS = 'GET_FIRST_PAGE_RECENT_NOTES_ENTITY_SUCCESS';
export const GET_FIRST_PAGE_RECENT_NOTES_ENTITY_FAILURE = 'GET_FIRST_PAGE_RECENT_NOTES_ENTITY_FAILURE';
export const GET_NEXT_PAGE_RECENT_NOTES_ENTITY = 'GET_NEXT_PAGE_RECENT_NOTES_ENTITY';
export const GET_NEXT_PAGE_RECENT_NOTES_ENTITY_SUCCESS = 'GET_NEXT_PAGE_RECENT_NOTES_ENTITY_SUCCESS';
export const GET_NEXT_PAGE_RECENT_NOTES_ENTITY_FAILURE = 'GET_NEXT_PAGE_RECENT_NOTES_ENTITY_FAILURE';
export const CLEAN_RECENT_NOTES_ENTITY = 'CLEAN_RECENT_NOTES_ENTITY';
export const CLEAN_RECENT_NOTES_ENTITY_MSG = 'CLEAN_RECENT_NOTES_ENTITY_MSG';

// draft
export const GET_ALL_DRAFT = 'GET_ALL_DRAFT';
export const GET_ALL_DRAFT_SUCCESS = 'GET_ALL_DRAFT_SUCCESS';
export const GET_ALL_DRAFT_FAILURE = 'GET_ALL_DRAFT_FAILURE';

// recent notes contact
export const GET_FIRST_PAGE_RECENT_NOTES_CONTACT = 'GET_FIRST_PAGE_RECENT_NOTES_CONTACT';
export const GET_FIRST_PAGE_RECENT_NOTES_CONTACT_SUCCESS = 'GET_FIRST_PAGE_RECENT_NOTES_CONTACT_SUCCESS';
export const GET_FIRST_PAGE_RECENT_NOTES_CONTACT_FAILURE = 'GET_FIRST_PAGE_RECENT_NOTES_CONTACT_FAILURE';
export const GET_NEXT_PAGE_RECENT_NOTES_CONTACT = 'GET_NEXT_PAGE_RECENT_NOTES_CONTACT';
export const GET_NEXT_PAGE_RECENT_NOTES_CONTACT_SUCCESS = 'GET_NEXT_PAGE_RECENT_NOTES_CONTACT_SUCCESS';
export const GET_NEXT_PAGE_RECENT_NOTES_CONTACT_FAILURE = 'GET_NEXT_PAGE_RECENT_NOTES_CONTACT_FAILURE';
export const CLEAN_RECENT_NOTES_CONTACT = 'CLEAN_RECENT_NOTES_CONTACT';
export const CLEAN_RECENT_NOTES_CONTACT_MSG = 'CLEAN_RECENT_NOTES_CONTACT_MSG';

// recent notes web2.0
export const GET_RECENT_NOTES = 'GET_RECENT_NOTES';
export const GET_RECENT_NOTES_SUCCESS = 'GET_RECENT_NOTES_SUCCESS';
export const GET_RECENT_NOTES_FAILURE = 'GET_RECENT_NOTES_FAILURE';

// entity search list
export const GET_ENTITY_FIRST_PAGE_WITH_KEYWORD = 'GET_ENTITY_FIRST_PAGE_WITH_KEYWORD';
export const GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_SUCCESS = 'GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_SUCCESS';
export const GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_FAILURE = 'GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_FAILURE';
export const GET_ENTITY_NEXT_PAGE_WITH_KEYWORD = 'GET_ENTITY_NEXT_PAGE_WITH_KEYWORD';
export const GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_SUCCESS = 'GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_SUCCESS';
export const GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_FAILURE = 'GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_FAILURE';
export const CLEAN_ENTITY_SEARCH_LIST = 'CLEAN_ENTITY_SEARCH_LIST';
export const CLEAN_ENTITY_SEARCH_LIST_MSG = 'CLEAN_ENTITY_SEARCH_LIST_MSG';
