/**
 * Created by Alan Yang on 8/11/17.
 * Description:
 *
 * ------ maintenance history ------
 * 06/02/2020 Marcus Zhao add contactTemplateValueSetReducer
 * 08/20/2020 Marcus Zhao set contactReducer to smartReducer
 * 08/09/2020 Bowen add the new added job title selector and reducer
 * 09/22/2020 Marcus add contactEmailCountReducer for duplicate email
 */

import {
    GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_SUCCESS, GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_SUCCESS,
    EDIT_CONTACT_SUCCESS, EDIT_CONTACT_FAILURE, CREATE_CONTACT_SUCCESS, CREATE_CONTACT_FAILURE,
    GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_FAILURE, GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_FAILURE,
    CLEAN_CONTACT_SEARCH_LIST, CLEAN_CONTACT_SEARCH_LIST_MSG, CONTACT_STATUS_CREATE,
    CONTACT_STATUS_EDIT, GET_CONTACT, GET_SLIDESHEET_CONTACT_SUCCESS, GET_SLIDESHEET_CONTACT_FAILURE
} from './app.actions';
import { GET_CONTACT_SUCCESS, GET_CONTACT_FAILURE, CLEAN_CONTACT } from './app.actions';
import { Contact } from '../models/contact.model';
import { ContactList } from '../models/entity-list.model';
import {
    CLEAR_CONTACT_MSG, CHANGE_CONTACT_TEMPLATE_VALUE_SET,
    CLEAN_CONTACT_TEMPLATE_VALUE_SET, ADD_JOB_TITLE, REMOVE_JOB_TITLE,
    CLEAR_JOB_TITLE, CHANGE_CONTACT_TEMPLATE_LAYOUT_SET,
    ContactEmailIndexActions, ContactEmailActionTypes, ADD_DUPLICATED_CONTACT, CLEAR_DUPLICATED_CONTACT, SAVE_CONTACT_TEMPLATE
} from './contact.actions';
import { combineReducers, createSelector } from '@ngrx/store';

export const getContactValueState = (state: any) => state;
export const getContactTemplateValueSetState = createSelector(getContactValueState, (state: any) => state.templateValueSet);
export const getContactEmailCountState = createSelector(getContactValueState, (state: any) => state.contactEmailCount);
export const getContactTemplateLayoutSetState = createSelector(getContactValueState, (state: any) => state.templateLayoutSet);
// new added job title reducer in contact dialog
export const contactNewAddedJobTitleSelector = createSelector((state: any) => state, state => state.contactNewAddedJobTitle);
// duplicated contact feedback control set
export const duplicatedContactFeedbackSelector = createSelector((state: any) => state, state => state.duplicatedContactFeedbackControl);

export const contactTemplateConfigSelector = createSelector((state: any) => state, state => state.contactTemplateConfig);

const contactContentReducer = combineReducers({
    templateValueSet: contactTemplateValueSetReducer,
    contactEmailCount: contactEmailCountReducer,
    templateLayoutSet: contactTemplateLayoutSetReducer
});

export function getContactRedcuer(state, action) {
    return contactContentReducer(state, action);
}

export function contactReducer(state: Contact = null, action) {
    switch (action.type) {
        case GET_CONTACT_SUCCESS:
            return action.payload;
        case GET_CONTACT_FAILURE:
        case EDIT_CONTACT_FAILURE:
        case CLEAN_CONTACT:
            return null;
        default:
            return state;
    }
}

export function slideSheetContactReducer(state: Contact = null, action) {
    switch (action.type) {
        case GET_SLIDESHEET_CONTACT_SUCCESS:
            return action.payload;
        default:
            return state;
    }
}

export function contactListReducer(state: ContactList = new ContactList(), action) {
    switch (action.type) {
        case GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_SUCCESS:
            return action.payload;
        case GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_SUCCESS:
            const contactList = new ContactList();
            contactList.contacts = state.contacts.concat(action.payload.contacts);
            contactList.next = action.payload.next;
            return contactList;
        case GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_FAILURE:
        case GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_FAILURE:
        case CLEAN_CONTACT_SEARCH_LIST:
            return [];
        default:
            return state;
    }
}

export function contactMsgReducer(state: string = '', action) {
    switch (action.type) {
        case GET_CONTACT_SUCCESS:
        case GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_SUCCESS:
        case GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_SUCCESS:
            return action.type;
        case CREATE_CONTACT_SUCCESS:
        case EDIT_CONTACT_SUCCESS:
            return action;
        case GET_CONTACT_FAILURE:
        case GET_CONTACT_FIRST_PAGE_WITH_KEYWORD_FAILURE:
        case GET_CONTACT_NEXT_PAGE_WITH_KEYWORD_FAILURE:
        case CREATE_CONTACT_FAILURE:
        case EDIT_CONTACT_FAILURE:
            return `${action.type}: ${action.payload}`;
        case CLEAN_CONTACT_SEARCH_LIST_MSG:
        case CLEAN_CONTACT:
        case CLEAR_CONTACT_MSG:
            return '';
        default:
            return state;
    }
}

export function slideSheetContactMsgReducer(state: string = '', action) {
    switch (action.type) {
        case GET_SLIDESHEET_CONTACT_SUCCESS:
            return action.type;
        case GET_SLIDESHEET_CONTACT_FAILURE:
            return `${action.type}: ${action.payload}`;
        default:
            return state;
    }
}

export function contactStatusReducer(state, action) {
    switch (action.type) {
        case CONTACT_STATUS_CREATE:
        case CONTACT_STATUS_EDIT:
        case GET_CONTACT:
            return action.type;
        default:
            return state;
    }
}

export function contactTemplateValueSetReducer(state: any = {}, action) {
    switch (action.type) {
        case CHANGE_CONTACT_TEMPLATE_VALUE_SET:
            const valueSet = Object.assign({}, state);
            valueSet[action.payload.ctrl.controlID] = Object.assign({}, action.payload);
            return valueSet;
        case CLEAN_CONTACT_TEMPLATE_VALUE_SET:
            return {};
        default:
            return state;
    }
}

export function contactTemplateLayoutSetReducer(state: any, action) {
    switch (action.type) {
        case CHANGE_CONTACT_TEMPLATE_LAYOUT_SET:
            return Object.assign([], action.payload);
        default:
            return state;
    }
}

export function contactTemplateConfigReducer(state: any = {}, action) {
    switch (action.type) {
        case SAVE_CONTACT_TEMPLATE:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
}

export function contactNewAddedJobTitleValueReducer(state: any = [], action) {
    let index;
    let newState;
    switch (action.type) {
        case ADD_JOB_TITLE:
            newState = Object.assign([], state);
            index = newState.findIndex(element => element.id === action.payload.id);
            if (index > -1) {
                newState[index].name = action.payload.name;
                newState[index].count += 1;
            } else {
                newState.push({ name: action.payload.name, id: action.payload.id, count: 1 });
            }
            return newState;
        case REMOVE_JOB_TITLE:
            newState = Object.assign([], state);
            index = newState.findIndex(element => element.id === action.payload.id);
            if (index > -1) {
                if (newState[index].count > 1) {
                    newState[index].count -= 1;
                } else {
                    newState.splice(index, 1);
                }
                return newState;
            } else {
                return state;
            }
        case CLEAR_JOB_TITLE:
            return [];
        default:
            return state;
    }
}

export function contactEmailCountReducer(state: Array<any>, action: ContactEmailIndexActions) {
    let newState;
    switch (action.type) {
        case ContactEmailActionTypes.EMAIL_INCREMENT:
            newState = Object.assign([], state);
            newState.push(action.payload.emailId);
            return newState;
        case ContactEmailActionTypes.EMAIL_DECREMENT:
            const index = state.findIndex(item => item === action.payload.emailId);
            state.splice(index, 1);
            return state;
        case ContactEmailActionTypes.EMAIL_RESET:
            return [];

        default:
            return state;
    }
}

export function duplicatedContactFeedbackControlReducer(state: any = [], action) {
    let newState;
    switch (action.type) {
        case ADD_DUPLICATED_CONTACT:
            newState = Object.assign([], state);
            newState.push(action.payload);
            return newState;
        case CLEAR_DUPLICATED_CONTACT:
            return [];
        default:
            return state;
    }
}
