/**
 * Created by Abner Sui on 05/22/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { TSItem } from './ts-item.model';
import { DocumentEntry } from './document-entry.model';

export class FileList extends TSItem {
    count = 0;
    next = '';
    files: DocumentEntry[] = [];
}

/**
 * This model is used to download file using the new file endpoint
 */
export class FileDownload {
    displayName: string;
    extension: string;
    id: string; // server file name
    name: string;

    constructor(_id: string, _name: string, _extension: string) {
        const ext = _extension.includes('.') ? _extension : '.' + _extension;
        this.displayName = _name.includes('.') ? _name : _name + ext;
        this.extension = _extension;
        this.id = _id;
        this.name = _name;
    }

    static parse(res): FileDownload {
        if (!res) {
            return null;
        }

        const data = new FileDownload(res.serverfilename, res.file, res.extension);
        return data;
    }
}
