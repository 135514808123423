/**
 * Created by Yoyo? on 01/07/2020
 * Description:
 *
 * ------ maintenance history ------
 * 01/07/2020   Yoyo Fang   extract functionalities from generateShortId to a new public function nextGUID, which accept array of guid
 * 04/02/2022 Simon Zhao changed all functions into static methods.
 */
import { TileModel, TilePageDesc, TileSorts, NoteTileDisplayStrategy, TileDisplaySettings } from '../../../../../tamalelibs/models/tile.model';
import { ThreadsDisplayOrders, ThreadDisplaySettings } from '../../../../../tamalelibs/models/thread-display-settings.model';
import { PAGE_LAYOUT_SETTINGS, PROPERTY_FIELD_MAP, NOTE_TILE_DEFAULT_FIELDS } from './note-tile-chart-setting.model';
import { TileColumn, defaultShortName, defaultLongName, defaultEntryClass } from '../../../../../tamalelibs/models/tile-column.model';
import * as _lodash from 'lodash';
import { ENTRY_COLUMN_LIST } from '../../tile-column-setting/tile-column-setting.model';
import { TileSettingService } from '../../../../../services/tile-setting.service';

export class NoteTileSettingsService {

    static prepareDefaultConfig(config: TileModel, defaultListSettings: any, isNew: boolean = false): TileModel {
        let result = config;
        // As for entity tags, all note tiles need showEntitiesSameRow=true to make entities of note be returned seperately
        const shortNameColumns = config.columns.filter(item => item.field === defaultShortName.field);
        if (shortNameColumns && shortNameColumns.length) {
            shortNameColumns[0].showEntitiesSameRow = true;
        }
        const longNameColumns = config.columns.filter(item => item.field === defaultLongName.field);
        if (longNameColumns && longNameColumns.length) {
            longNameColumns[0].showEntitiesSameRow = true;
        }

        result = NoteTileSettingsService.prepareNoteTileOnlyFields(result, defaultListSettings);
        if (isNew) {
            PAGE_LAYOUT_SETTINGS[1].fields.forEach(layoutSetting => {
                // blurb should be by default unchecked
                if (layoutSetting['propertyName'] !== 'blurb') {
                    result = NoteTileSettingsService._prepareDisplaySettings(result, layoutSetting);
                }
            });
        } else {
            NOTE_TILE_DEFAULT_FIELDS.forEach(field => {
                // tslint:disable-next-line: no-shadowed-variable
                const column: TileColumn = NoteTileSettingsService.cloneColumnByfield(field);
                result = NoteTileSettingsService.pushColumn(result, column, false);
            });
        }
        // dates columns are needed not only for displaying but also for sorting.
        const column: TileColumn = NoteTileSettingsService.cloneColumnByfield('entry__last_edited_date');
        result = NoteTileSettingsService.pushColumn(result, column);
        return result;
    }

    static prepareNoteTileOnlyFields(config: TileModel, defaultListSettings: any): TileModel {
        const result = config;
        if (!result.pageDesc) {
            result.pageDesc = new TilePageDesc(defaultListSettings.threadsCount, 1);
        }
        if (!result.options.parentOrLastSidenote) {
            result.options.parentOrLastSidenote = defaultListSettings.threadsDisplayModes;
        }
        if (typeof result.options.showFullThread !== 'boolean') {
            result.options.showFullThread = defaultListSettings.threadsShowFullThread;
        }
        if (!result.sorts) {
            result.sorts = new TileSorts(ThreadsDisplayOrders.DISPLAY_DATE, 'desc');
        }
        if (!result.displaySettings) {
            result.displaySettings = new TileDisplaySettings(true, true, true);
            result.displaySettings.showEntityArray = true;
            result.displaySettings.showBackdate = true;
            result.displaySettings.showSourceInfo = true;
        }
        return result;
    }

    static pushColumn(config: TileModel, item: TileColumn, showIfHidden: boolean = true): TileModel {
        const result = config;
        const existings = result.columns.filter((column: TileColumn) => column.field === item.field);
        if (!existings || existings.length === 0) {
            const column: TileColumn = _lodash.cloneDeep(item);
            // to load note body instead of note body id, use hard coded guid bace8a0da8ee4acaad6e3ff4c95beXXX
            if (column.field === 'entry__id') {
                // TODO: generate new guid base on existing guids of all entry__id fields of all tiles on same dashboard
                column.guid = 'bace8a0da8ee4acaad6e3ff4c95be' + TileSettingService.nextGUID([]);
            } else {
                column.guid = TileSettingService.generateShortId(result.columns);
            }
            result.columns.push(column);
        } else {
            // for submitter and source
            if (showIfHidden) {
                existings[0].hidden = false;
            }
        }
        return result;
    }

    static popColumn(config: TileModel, item: TileColumn): TileModel {
        const result = config;
        if (NOTE_TILE_DEFAULT_FIELDS.includes(item.field)) {
            result.columns = result.columns.map((column: TileColumn) => {
                if (column.field === item.field) {
                    column.hidden = true;
                    // column.settings.display='1';
                }
                return column;
            });
        } else {
            result.columns = result.columns.filter((column: TileColumn) => column.field !== item.field);
        }
        return result;
    }

    static setNoneColumns(config: TileModel, propertyName, value): TileModel {
        const result = config;
        switch (propertyName) {
            case 'threadAttachments':
                result.displaySettings.fullThreadAttachments = value;
                break;
            case 'entryAttachments':
                result.displaySettings.individualEntryAttachments = value;
                break;
            case 'adhocTable':
                result.displaySettings.adhocTable = value;
                break;
            case 'blurb':
                break;
            case 'entities':
                const shortNameColumns = result.columns.filter(column => column.field === 'entity__short_name');
                if (shortNameColumns && shortNameColumns.length) {
                    shortNameColumns[0].hidden = !value;
                }
                const nameColumns = result.columns.filter(column => column.field === 'entity__name');
                if (nameColumns && nameColumns.length) {
                    nameColumns[0].hidden = !value;
                }
                break;
        }
        return result;
    }

    static cloneColumnByfield(fieldName: string): TileColumn {
        const columns = [defaultShortName, defaultLongName, defaultEntryClass, ...ENTRY_COLUMN_LIST].filter(column => column.field === fieldName);
        return columns.length > 0 ? _lodash.cloneDeep(columns[0]) : null;
    }

    static threadTileConfig2DisplaySettings(config: TileModel): ThreadDisplaySettings {
        const data = new ThreadDisplaySettings();
        data.threadsCount = config.pageDesc ? config.pageDesc.rpp : 1;

        const parentorlatest = config.options ? config.options.parentOrLastSidenote : NoteTileDisplayStrategy.LatestSidenote;
        const showfullthread = config.options && config.options.showFullThread ? true : false;
        data.threadsDisplayModes = parentorlatest;
        data.threadsShowFullThread = showfullthread;
        data.threadsOrder = config.sorts ? (config.sorts.field as ThreadsDisplayOrders) : ThreadsDisplayOrders.DISPLAY_DATE;

        PROPERTY_FIELD_MAP.forEach((fieldName, propertyName) => {
            const column = config.columns.find(item => item.field === fieldName);
            if (column) {
                data[propertyName] = NOTE_TILE_DEFAULT_FIELDS.includes(fieldName) ? !column.hidden : true;
            } else {
                data[propertyName] = false;
            }
        });

        const shortNameColumns = config.columns.filter(column => column.field === 'entity__short_name');
        if (shortNameColumns && shortNameColumns.length) {
            data['entities'] = !shortNameColumns[0].hidden;
        }


        if (!config.displaySettings) {
            config.displaySettings = new TileDisplaySettings(false, false, false);
        }
        data['threadAttachments'] = config.displaySettings.fullThreadAttachments;
        data['entryAttachments'] = config.displaySettings.individualEntryAttachments;
        data['adhocTable'] = config.displaySettings.adhocTable;

        return data;
    }

    static cleanupNoteTileOnlySettings(config: TileModel): TileModel {
        for (let i = 0; i < config.columns.length; i++) {
            if (config.columns[i].showEntitiesSameRow) {
                config.columns[i].showEntitiesSameRow = false;
            }
        }
        if (!config.displaySettings) {
            config.displaySettings = new TileDisplaySettings(false, false, false);
        }
        config.displaySettings.showBackdate = false;
        config.displaySettings.showEntityArray = false;
        config.displaySettings.showSourceInfo = false;
        config.displaySettings.adhocTable = false;
        return config;
    }

    static _prepareDisplaySettings(config: TileModel, layoutSetting: any): TileModel {
        let result = config;
        const field = PROPERTY_FIELD_MAP.get(layoutSetting.propertyName);
        if (field) {
            const column: TileColumn = NoteTileSettingsService.cloneColumnByfield(field);
            result = NoteTileSettingsService.pushColumn(result, column);
        } else {
            result = NoteTileSettingsService.setNoneColumns(result, layoutSetting.propertyName, true);
        }
        return result;
    }
}
