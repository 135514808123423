/**
 * Created by Todd Yu on 06/20/2020.
 * Description:
 * Editor Component for Note Types
 * ------ maintenance history ------
 * Modified by Teddy Ding on 12/15/20. Support tamale slide-sheet component.
 * Modified by Lucas Wang on 8/8/2021. Refine the code to match coding standard.
 * Updated by Daniel Wang on 5/31/2022. Set default type for sidenote.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { UntypedFormControl } from '@angular/forms';
import { AppState } from '../../../../../redux';
import * as _lodash from 'lodash';

import { AdminPageActionTypes, CreateNewNoteType, EditNoteType } from '../../../../../redux/actions/admin-page.actions';
import { AdminPageState, AdminPageStatusTypes } from '../../../../../redux/reducers/admin-page.reducer';
import { SlideSheetService } from '../../../../../services/slide-sheet.service';
import { SlideSheetActionTypes } from '../../../../slide-sheet/slide-sheet.model';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from '../../../../../widgets/multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { ToastService } from '../../../../../widgets/toast/toast.service';
import { businessConstants } from '../../../../../tamalelibs/constants/business.constants';
import { EntryType } from '../../../../../tamalelibs/models/entry-type.model';
import { ArrayHelperService } from '../../../../../tamalelibs/services/array-helper.service';
import { lastCustomActionSelector } from '../../../../../redux/reducers/action-history.reducer';
import { AlertWindowService } from '../../../../../widgets/alert-window/alert-window.service';
import { StringLiteralsPipe } from '../../../../../pipes/translate.pipe';

export const EMPTY_SOURCE_NAME = 'None';
export const ALL_SOURCE_NAME = 'All';

@Component({
    selector: 'note-types-editor',
    templateUrl: './note-types-editor.component.html',
    styleUrls: ['./note-types-editor.component.scss']
})
export class NoteTypesEditorComponent implements OnInit, OnDestroy {
    @Input() noteData?: any;
    currNoteData: any = {
        'name': '',
        'default-extensions': '',
        'display-web': true,
        'href': '',
        'id': '',
        'uses': '',
        'usage': {},
        'config': {}
    };
    dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    dropdownConfigData = ['Note', 'Event'];
    isProgressing = false;
    nameDuplicated = false;
    name = new UntypedFormControl('');
    noteTypeDefault: EntryType = new EntryType(businessConstants.EntryTypes.sameAsParentNoteId, 'Same as parent note'); // set the default not type;
    noteTypes: Array<EntryType> = new Array<EntryType>(); // set the note type dropdown data
    sourceName = 'Note';
    title = 'Create New Entry Type';
    isChanged = false;

    //#region private properties
    private _destroySubscriptions: Array<Subscription> = [];
    private _noteTypeId: string; // save the selected note type ID
    private _noteTypesSource: Array<EntryType>;
    //#endregion

    constructor(
        private _alertWindow: AlertWindowService,
        private _store: Store<AppState>,
        private _slideSheetService: SlideSheetService,
        private _toast: ToastService,
    ) { }

    ngOnInit() {
        this._initTypeDropDown();
        this.editorInit();
        this._destroySubscriptions.push(
            this._store.pipe(
                select('adminPage')
            ).subscribe((pageState: AdminPageState) => this._parsePageState(pageState)),

            this._store.pipe(
                select(lastCustomActionSelector)
            ).subscribe(action => {
                this._onSubscribeActionMessage(action);
            }),
        );
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
        this._destroySubscriptions = [];
    }

    configChanged() {
        this.isChanged = true;
    }

    /** Initialize editor data when menu is opened */
    editorInit() {
        if (!this.noteData) {
            this.title = 'Create New Entry Type';
            this.currNoteData = {
                'name': '',
                'default-event': false,
                'default-note': false,
                'default-extensions': '',
                'display-web': true,
                'href': '',
                'id': '',
                'uses': '',
                'usage': {},
                'config': {}
            };
        } else {
            this.title = 'Edit Entry Type';
            if ('display-web' in this.noteData) {
                this.currNoteData = _lodash.cloneDeep(this.noteData);
            } else {
                this.currNoteData = _lodash.cloneDeep(this.noteData);
                this.currNoteData['display-web'] = true;
            }
            if (!('config' in this.noteData)) {
                this.currNoteData['config'] = {};
            }
        }
        this._setSelectTypes();
    }

    handleFilter(value, dropDownEl) {
        if (!value) {
            this.noteTypes = this._noteTypesSource;
        } else {
            this.noteTypes = this._noteTypesSource.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            if (this.noteTypes.length >= 1) {
                setTimeout(() => {
                    dropDownEl.optionsList.content.nativeElement.scrollTop = 0;
                });
            }
        }
    }

    onSlideSheetClose() {
        if (this.isChanged) {
            const message = `Are you sure you want to discard all your changes?`;
            const subscription = this._alertWindow.warn('You have unsaved changes',
                [message], StringLiteralsPipe.translate('general.discard'), StringLiteralsPipe.translate('general.go_back'))
                .subscribe((result: boolean) => {
                    if (result) {
                        this._slideSheetService.slideSheetActionSubject$.next({
                            type: SlideSheetActionTypes.CLOSE
                        });
                    }
                    subscription.unsubscribe();
                });
        } else {
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE
            });
        }
    }

    /** Check the length of name. When the length of the name is valid, dispatches action to the store to create new entity or edit entity. */
    onSubmitClick() {
        this.name.setErrors(null);
        this.nameDuplicated = false;
        this.currNoteData.usage = this.sourceName.split(',');
        if (!this.name.value) {
            this.name.setErrors({
                required: true
            });
        } else if (this.name.value.length > 128) {
            this.name.setErrors({
                maxlength: true
            });
        } else {
            this.isProgressing = true;
            if (this.title === 'Create New Entry Type') {
                this._store.dispatch(new CreateNewNoteType({
                    name: this.currNoteData['name'],
                    data: this.currNoteData,
                    successMessage: `${this.currNoteData['name']} successfully created.`
                }));
            } else {
                this._store.dispatch(new EditNoteType({
                    name: this.currNoteData['name'],
                    id: this.currNoteData['id'],
                    data: this.currNoteData,
                    successMessage: `${this.currNoteData['name']} successfully edited.`
                }));
            }
            this.isChanged = false;
        }
    }

    onClickSource(event) {
        this.dropdownConfig.open$.next(event.target);
    }

    valueChangeSource(data): void {
        if (data.length === 0) {
            this.sourceName = null;
        } else if (data === ALL_SOURCE_NAME.toLowerCase()) {
            this.sourceName = this.dropdownConfigData.join(',');
        } else {
            this.sourceName = data.map(item => item.name).join(',');
        }
    }

    selectionChange(event: any): void {
        this._noteTypeChanged(event.id);
    }

    /**
    * init used by dropdown control.
    *
    * @private
    * @memberof NoteTypesEditorComponent
    */
    private _initTypeDropDown() {
        this.dropdownConfig.hideSearchBox = true;
        this.dropdownConfig.hideHeader = true;
        this.dropdownConfig.onChangeValue$.subscribe(data => this.valueChangeSource(data));
    }

    private _onSubscribeActionMessage(action) {
        if (action.type === AdminPageActionTypes.CREATE_NOTE_TYPE_SUCCESS ||
            action.type === AdminPageActionTypes.EDIT_NOTE_TYPE_SUCCESS) {
            this.onSlideSheetClose();
        }
    }

    private _noteTypeChanged(id) {
        if (id === businessConstants.EntryTypes.sameAsParentNoteId) {
            this.currNoteData.config = {};
        } else {
            this.currNoteData.config = {
                customDefaultSideNoteType: id.toString()
            };
        }
    }

    /** Notify user of error of duplicate name when create/edit note types */
    private _parsePageState(state: AdminPageState) {
        // set data source for entry types
        if (state && state.currentPageData) {
            const entryTypeList = state.currentPageData['entry-type-list'];
            if (entryTypeList) {
                this._setNoteTypeSource(entryTypeList);
            }
        }
        if (state.successStatus === AdminPageStatusTypes.FAILURE) {
            if (state.failureMessage.search('ERR_DUPLICATE_ENTRY_TYPE') !== -1) {
                this.nameDuplicated = true;
                this.isProgressing = false;
                return;
            }
        } else if (state.successStatus === AdminPageStatusTypes.SUCCESS) {
            this.isProgressing = false;
            this._slideSheetService.slideSheetActionSubject$.next({
                type: SlideSheetActionTypes.CLOSE,
                payload: null
            });
        }
    }

    private _setNotetypeForSidenote() {
        if (this.currNoteData && this.currNoteData.config && this.currNoteData.config.customDefaultSideNoteType) {
            const noteType = this.noteTypes.filter(item => item.id === this.currNoteData.config.customDefaultSideNoteType)[0];
            if (noteType) {
                this.noteTypeDefault = noteType;
            }
        }
    }

    private _setNoteTypeSource(entryTypeList): void {
        this.noteTypes.push(this.noteTypeDefault);
        if (entryTypeList) {
            const availableEntryTypes = entryTypeList.filter(item => item['display-web'] === true && item['usage'].includes(businessConstants.EntryTypes.note));
            ArrayHelperService.sort(availableEntryTypes, 'name');
            availableEntryTypes.forEach(item => {
                const noteType = new EntryType(item.id, item.name);
                this.noteTypes.push(noteType);
            });
            this._noteTypesSource = _lodash.cloneDeep(this.noteTypes);
        }
        this._setNotetypeForSidenote();
    }

    /**
     * set dropdown check value and status.
     * @private
     * @memberof NoteTypesEditorComponent
     */
    private _setSelectTypes() {
        this.dropdownConfig.data = [];
        this.dropdownConfigData.forEach(item => {
            let isCheckItem = true;
            let isNeedDisabled = false;
            // new entry type
            if (!this.noteData) {
                if (item === businessConstants.EntryTypes.note) {
                    isCheckItem = true;
                } else {
                    isCheckItem = false;
                }
                // edit entry type
            } else if (this.noteData.usage && this.noteData.usage.findIndex(a => a === item) > -1) {
                // ['default-note' or 'tamaleNote']['default-event' or 'tamaleEvent'] should disable item.
                if ((item === businessConstants.EntryTypes.note &&
                    (this.noteData['default-note'] || businessConstants.EntryTypes.Tamale_Note_Id === this.noteData.id))
                    ||
                    (item === businessConstants.EntryTypes.event &&
                        (this.noteData['default-event'] || businessConstants.EntryTypes.Tamale_Event_Id === this.noteData.id))
                ) {
                    isNeedDisabled = true;
                }
            } else {
                isCheckItem = false;
            }
            this.dropdownConfig.data.push(MultiSelDropdownConfig.parse(item, isCheckItem, item, isNeedDisabled));
        });
        this.sourceName = this.dropdownConfig.data.filter(a => a.checked).map(b => b.name).join(',');
    }

}
