import { Action } from '@ngrx/store';

// draft list related actions
export enum DraftViewActionTypes {
    SET_SELECTED_INDEX = '[RESEARCH][THREAD_LIST] SET_SELECTED_INDEX',
    SET_CURRENT_PAGE_INDEX = '[RESEARCH][THREAD_LIST] SET_CURRENT_PAGE_INDEX', // uselss, could use RELOAD and LOAD_MORE
    RELOAD = '[DRAFT_LIST] RELOAD',
    RELOAD_SUCCESS = '[DRAFT_LIST] RELOAD_SUCCESS',
    RELOAD_FAILED = '[DRAFT_LIST] RELOAD_FAILED',
    SET_QUERY_INFO = '[DRAFT_LIST][QUERY_INFO] SET_VALUE',
    SET_SORT_INFO = '[DRAFT_LIST][SORT_INFO]_SET_VALUE',
    LOAD_MORE = '[DRAFT_LIST] LOAD_MORE',
    LOAD_MORE_SUCCESS = '[DRAFT_LIST] LOAD_MORE_SUCCESS',
    LOAD_MORE_FAILED = '[DRAFT_LIST] LOAD_MORE_FAILED',
}

export class DraftListSetSelectedIndex implements Action {
    readonly type = DraftViewActionTypes.SET_SELECTED_INDEX;

    constructor(public payload: any) { }
}

export class DraftListSetCurrentPageIndex implements Action {
    readonly type = DraftViewActionTypes.SET_CURRENT_PAGE_INDEX;

    constructor(public payload: any) { }
}

export class DraftListReload implements Action {
    readonly type = DraftViewActionTypes.RELOAD;

    constructor(public payload: any) { }
}

export class DraftListReloadSuccess implements Action {
    readonly type = DraftViewActionTypes.RELOAD_SUCCESS;

    constructor(public payload: any) { }
}

export class DraftListReloadFailed implements Action {
    readonly type = DraftViewActionTypes.RELOAD_FAILED;

    constructor(public payload: any) { }
}

// tslint:disable-next-line: class-name
export class DraftListSET_QUERY_INFO implements Action {
    readonly type = DraftViewActionTypes.SET_QUERY_INFO;

    constructor(public payload: any) { }
}

// tslint:disable-next-line: class-name
export class DraftListSET_SORT_INFO implements Action {
    readonly type = DraftViewActionTypes.SET_SORT_INFO;

    constructor(public payload: any) { }
}

export class DraftListLoadMore implements Action {
    readonly type = DraftViewActionTypes.LOAD_MORE;

    constructor(public payload: any) { }
}

export class DraftListLoadMoreSuccess implements Action {
    readonly type = DraftViewActionTypes.LOAD_MORE_SUCCESS;

    constructor(public payload: any) { }
}

export class DraftListLoadMoreFailed implements Action {
    readonly type = DraftViewActionTypes.LOAD_MORE_FAILED;

    constructor(public payload: any) { }
}

export type DraftListActions = DraftListReload | DraftListReloadSuccess | DraftListSetSelectedIndex | DraftListSetCurrentPageIndex | DraftListSET_QUERY_INFO | DraftListSET_SORT_INFO | DraftListReloadFailed | DraftListLoadMore | DraftListLoadMoreSuccess | DraftListLoadMoreFailed;
