import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FiltersConfig, FilterMetadataType } from '../components/filter-config';

export class AuditFilterConfig {
    field: string;
    value: string;
    operator: string;
}

@Injectable()
export class AuditFilterService {

    constructor(
        private _datepipe: DatePipe,
    ) { }

    isAuditType(metadataType) {
        return metadataType === 'Where' || metadataType === 'User' || metadataType === 'Action' || metadataType === 'DateAndTime';
    }

    generateAdvFilterForSubject(setting: FiltersConfig): AuditFilterConfig {
        const filterObj: AuditFilterConfig = new AuditFilterConfig();
        filterObj.field = `entry__${setting.fields[0]}`;
        const operatorType = {
            'contains': 'like',
            'notcontains': 'not like',
            'startswith': 'like',
            'endswith': 'like',
            'blank': 'is',
            'notblank': 'is not'
        };
        filterObj.operator = operatorType[setting.selectedOperator];
        switch (setting.selectedOperator) {
            case 'contains':
            case 'notcontains':
                filterObj.value = setting.value ? `'%${setting.value}%'` : '';
                break;
            case 'startswith':
                filterObj.value = setting.value ? `'${setting.value}%'` : '';
                break;
            case 'endswith':
                filterObj.value = setting.value ? `'%${setting.value}'` : '';
                break;
            case 'blank':
            case 'notblank':
                filterObj.value = null;
                break;
            default:
                break;
        }
        return filterObj;
    }

    generateAdvFilterForPriority(setting: FiltersConfig): AuditFilterConfig {
        const filterObj: AuditFilterConfig = new AuditFilterConfig();
        filterObj.field = `entry__${setting.fields[0]}`;
        const operatorType = {
            'greater': '>',
            'greater_or_equal': '>=',
            'equals': '=',
            'not_equal': '<>',
            'less': '<',
            'less_or_equal': '<='
        };
        filterObj.operator = operatorType[setting.selectedOperator];
        filterObj.value = setting.value;
        return filterObj;
    }

    generateAdvFilterForEntity(setting: FiltersConfig): AuditFilterConfig {
        const filterObj: AuditFilterConfig = new AuditFilterConfig();
        if (this.isAuditType(setting.metadataType)) {
            filterObj.field = `audit__${setting.fields[0]}`;
        } else {
            filterObj.field = `entry__${setting.fields[0]}`;
        }
        if (setting.selectedOperator === 'includes') {
            filterObj.operator = 'in';
        } else {
            filterObj.operator = 'not in';
        }
        if (setting.value && setting.value.length > 0) {
            filterObj.value = `(${setting.value.map((item) => `'${item.id}'`).join(',')})`;
        } else {
            filterObj.value = '';
        }
        return filterObj;
    }

    generateAdvFilterForText(setting: FiltersConfig): AuditFilterConfig {
        const filterObj: AuditFilterConfig = new AuditFilterConfig();
        if (this.isAuditType(setting.metadataType)) {
            filterObj.field = `audit__${setting.fields[0]}`;
        } else {
            filterObj.field = `entry__${setting.fields[0]}`;
        }
        if (setting.selectedOperator === 'includes') {
            filterObj.operator = 'in';
        } else {
            filterObj.operator = 'not in';
        }
        if (setting.value && setting.value.length > 0) {
            filterObj.value = `(${setting.value.map(item => `'${item}'`).join(',')})`;
        } else {
            filterObj.value = '';
        }
        return filterObj;
    }

    generateAdvFilterForDate(setting: FiltersConfig): AuditFilterConfig {
        const filterObj: AuditFilterConfig = new AuditFilterConfig();
        if (this.isAuditType(setting.metadataType)) {
            filterObj.field = `audit__${setting.fields[0]}`;
        } else {
            filterObj.field = `entry__${setting.fields[0]}`;
        }
        const operatorType = {
            'last': 'between',
            'next': 'between',
            'range': 'between',
            'before': '<',
            'after': '>',
            'today': 'dynamic',
            // 'blank': 'is',
            // 'notblank': 'is not'
        };
        filterObj.operator = operatorType[setting.selectedOperator];
        switch (setting.selectedOperator) {
            case 'last':
                if (setting.value.indexOf('day') !== -1 || setting.value.indexOf('week') !== -1) {
                    filterObj.value = `startoftoday('0')+interval '1 day'-interval '${setting.value}' and endoftoday('0')`;
                } else if (setting.value.indexOf('month') !== -1 || setting.value.indexOf('year') !== -1) {
                    filterObj.value = `startoftoday('0')-interval '${setting.value}' and endoftoday('0')`;
                }
                break;
            case 'next':
                if (setting.value.indexOf('day') !== -1 || setting.value.indexOf('week') !== -1) {
                    filterObj.value = `startoftoday('0') and endoftoday('0')-interval '1 day'+interval '${setting.value}'`;
                } else if (setting.value.indexOf('month') !== -1 || setting.value.indexOf('year') !== -1) {
                    filterObj.value = `startoftoday('0') and endoftoday('0')+interval '${setting.value}'`;
                }
                break;
            case 'range':
                const startDate = setting.value.split(' ')[0];
                const endDate = setting.value.split(' ')[1];
                if (startDate && endDate) {
                    filterObj.value = `'${this._datepipe.transform(startDate, 'yyyy-MM-dd')
                        + ' 00:00:00.000+00'}' and '${this._datepipe.transform(endDate, 'yyyy-MM-dd') + ' 23:59:59.999+00'}'`;
                } else {
                    filterObj.value = '';
                }
                break;
            case 'before':
                if (setting.value) {
                    filterObj.value = `'${this._datepipe.transform(setting.value, 'yyyy-MM-dd') + ' 00:00:00.000+00'}'`;
                } else {
                    filterObj.value = '';
                }
                break;
            case 'after':
                if (setting.value) {
                    filterObj.value = `'${this._datepipe.transform(setting.value, 'yyyy-MM-dd') + ' 23:59:59.999+00'}'`;
                } else {
                    filterObj.value = '';
                }
                break;
            case 'today':
                filterObj.value = 'TODAY';
                break;
            case 'blank':
            case 'notblank':
                filterObj.value = null;
                break;
            default:
                break;
        }
        return filterObj;
    }

    parseAuditFilterArrSetting(settings: Array<FiltersConfig>) {
        const advfilterArr = [];

        settings.forEach(setting => {
            let filterObj: AuditFilterConfig;
            switch (setting.metadataType) {
                case FilterMetadataType.Subject:
                    filterObj = this.generateAdvFilterForSubject(setting);
                    break;
                case FilterMetadataType.Priority:
                    filterObj = this.generateAdvFilterForPriority(setting);
                    break;
                case FilterMetadataType.Source:
                case FilterMetadataType.Submitter:
                case FilterMetadataType.User:
                case FilterMetadataType.Entities:
                    filterObj = this.generateAdvFilterForEntity(setting);
                    break;
                case FilterMetadataType.EntityType:
                case FilterMetadataType.NoteType:
                case FilterMetadataType.Sentiment:
                case FilterMetadataType.Where:
                case FilterMetadataType.Action:
                    filterObj = this.generateAdvFilterForText(setting);
                    break;
                case FilterMetadataType.DateAndTime:
                case FilterMetadataType.DisplayDate:
                case FilterMetadataType.SubmittedDate:
                    if (setting.value) {
                        filterObj = this.generateAdvFilterForDate(setting);
                    }
                    break;
                default:
                    break;
            }
            if (filterObj && filterObj.value !== '') {
                advfilterArr.push(filterObj);
            }
        });
        return advfilterArr;
    }

    parseAuditFilterSetting(settings: Array<FiltersConfig>) {
        const filters = {
            filters: {
                logicalOperator: 'and',
                filters: this.parseAuditFilterArrSetting(settings)
            }
        };
        return filters;
    }
}
