
/**
 * Created by Juno Peng 1/30/2024
 * Description: api request
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';
import { PortfolioUploadModel } from '../models/portfolio-upload.model';

@Injectable()
export class PortfolioService {

    constructor(private _transportService: TransportService) { }

    getPortfolio(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.portfolio}`;
        const params = {
            expand: 'child-entity;entity&fields=child-entity;',
            outputformat: 'json'
        };
        const options = {
            header: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    createPortfolio(data: PortfolioUploadModel): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.portfolio}`;
        const options = {
            headers: headers,
        };
        const portfolioConfig = {
            configName: data.configName,
            targetEntityID: data.targetEntityID,
            ignoresList: data.ignoresList,
            errorEntityID: data.errorEntityID,
            entityMatching: data.entityMatching,
            startRowNum: Number(data.startRowNum),
            portfolioFileName: data.portfolioFileName,
            relationshipTypeID: data.relationshipTypeID,
            errorContactID: data.errorContactID,
            createReciprocals: data.createReciprocals,
            replaceExistingRelationships: data.replaceExistingRelationships,
            delimiter: data.delimiter,
            aliasPrefix: data.aliasPrefix,
            entityColumnNum: Number(data.entityColumnNum),
            portfolioFileTime: Number(data.portfolioFileTime),
        };

        const formData = new URLSearchParams();
        formData.set('portfolioConfig', JSON.stringify(portfolioConfig));
        return this._transportService.post(url, formData.toString(), options);
    }

    updatePortfolio(data: PortfolioUploadModel) {
        const url = `${AppConfig.portfolio}${data.configID}`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const options = {
            headers: headers,
        };

        const portfolioConfig = {
            configID: data.configID,
            configName: data.configName,
            targetEntityID: data.targetEntityID,
            ignoresList: data.ignoresList,
            errorEntityID: data.errorEntityID,
            entityMatching: data.entityMatching,
            startRowNum: Number(data.startRowNum),
            portfolioFileName: data.portfolioFileName,
            relationshipTypeID: data.relationshipTypeID,
            errorContactID: data.errorContactID,
            createReciprocals: data.createReciprocals,
            replaceExistingRelationships: data.replaceExistingRelationships,
            delimiter: data.delimiter,
            aliasPrefix: data.aliasPrefix,
            entityColumnNum: Number(data.entityColumnNum),
            portfolioFileTime: Number(data.portfolioFileTime),
        };

        const formData = new URLSearchParams();
        formData.set('portfolioConfig', JSON.stringify(portfolioConfig));
        return this._transportService.put(url, formData.toString(), options);
    }

    deletePortfolio(id: string) {
        const url = `${AppConfig.portfolio}${id}`;
        return this._transportService.delete(url);
    }
}
