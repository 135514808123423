<div class="plugin-error-container">
    <div class="center">
        <p class="captain">
            New version available.
        </p>
        <p class="color">
            Looks like you are using an old version of the plugin. Please download and install the latest version.
        </p>
        <div>
            <span class="position color">Download URL:</span>
            <span class="position">
                <smart-icon class="light" name="link-clone" (click)="onCopyLinkClick()" tid="copy_link_url"
                    title="Copy Link">
                </smart-icon>
            </span>
            <span class="color" [hidden]="!isCopySuccess">
                Copied.
            </span>
        </div>
        <div class="url">
            {{pluginUrl}}
        </div>
    </div>
    <div class="copy-area"><textarea #copyArea></textarea></div>
</div>