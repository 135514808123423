/**
 * Created by Abner Sui on 07/08/2020.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';

export enum GlobalActionType {
    DISABLE_GLOBAL_BUFFER = 'DISABLE_GLOBAL_BUFFER',
    LOCK_DISABLE_GLOBAL_BUFFER = 'LOCK_DISABLE_GLOBAL_BUFFER',
}

export class GlobalAction {
    type: GlobalActionType;
    payload?: any;
}

@Injectable()
export class GlobalService {
    constructor(
        private _transportService: TransportService
    ) { }
    actionSubject$: Subject<GlobalAction> = new Subject();

    getHelpLinkByKey(key: string, version: string): Observable<any> {
        const url = `${AppConfig.helpEndPoint}${key}` + '?version=' + version;
        const options = {
            responseType: 'text'
        };
        return this._transportService.get(url, options);
    }

    getGlobalConfigData(): Observable<any> {
        const url = `${AppConfig.globalConfigEndPoint}`;
        return this._transportService.get(url);
    }

    getInitialConfigData(): Observable<any> {
        const url = `${AppConfig.initialConfigEndPoint}`;
        return this._transportService.get(url);
    }
}
