/**
 * Created by Bowen Li on 05/10/2019.
 * Description:
 * Preview PDF files
 * ------ maintenance history ------
 * 2019/05/26 Bowen preview pdf files by service instead of store
 * 2019/07/12 Bowen PDF Preview - When open a preview viewer,it shouldn't display with previous preview file for 1 second, this is undesirable
 * 2023/2/2 Simon Zhao Support preview in another tab by generating hyperlink and click it.
 */

import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { AuthHelperService } from '../../services/auth-helper.service';
import { Subscription } from 'rxjs';
import { PdffilePreviewConfig } from './pdffile-preview.model';
import { FileHelperService } from '../../services/file-helper.service';
import { PDFPreviewExtension } from '../../../../../tamalelibs/src/app/constants/business.constants';
import { BriefConfig } from '../widgets.model';
import { AddInUtilities } from '../../services/utilities/addin-utilities';

const PREVIEW_IFRAME_ID = 'iframePreview';
const PREVIEW_LINK_ID = 'previewInNewTabLink';
@Component({
    selector: 'tam-pdffile-preview',
    templateUrl: './pdffile-preview.component.html',
    styleUrls: ['./pdffile-preview.component.scss']
})
export class PdffilePreviewComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() config: BriefConfig;

    @ViewChild(PREVIEW_IFRAME_ID, { static: false }) iframePreview: ElementRef;
    modalIsShow = false;
    private _destroySubscription: Array<Subscription> = [];

    constructor(
        private _renderer2: Renderer2,
        private _element: ElementRef,
        private _authHelper: AuthHelperService,
        private _fileHelperService: FileHelperService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this._destroySubscription.push(
            this._fileHelperService.previewPdfFile$.subscribe(
                (data: PdffilePreviewConfig) => this.previewFile(data)
            ),
        );
    }

    ngOnDestroy() {
        this._destroySubscription.forEach(item => item.unsubscribe());
    }

    // when user click the x button, need to close the modal by set modal style
    ngAfterViewInit() {
        // we do not need this logic for office add-in, and it would bring in
        // the console error 'Blocked a frame with origin "https://server" from accessing a cross-origin frame' if we use it in office addin.
        if (!AddInUtilities.isOfficeEnvironment()) {
            window.top['closePDFViewerWindow'] = (param) => {
                this.modalIsShow = false;
                this._renderer2.removeAttribute(this._element.nativeElement.querySelector(`#${PREVIEW_IFRAME_ID}`), 'src');
                // trigger listening on home page when there is an error in file preview
                if (param.code === 0) {
                    this.config.actionSubject$.next();
                }
                this._changeDetectorRef.detectChanges();
            };
        }
    }

    // preview PDF files according to fileURL
    // will refactor this function, need to move the userid and preview out of the wiget
    public previewFile(data: PdffilePreviewConfig) {
        if (PDFPreviewExtension.includes(data.fileExtension.toLowerCase())) {
            // according isUsedFile to choose api.
            let tempUrl = '';
            if (data.isUsedFile) {
                // support use file api for preview
                tempUrl = 'vendor/pdfjs/web/viewer.html?name=' + encodeURIComponent(data.fileName) + '&file=' + encodeURIComponent('/restapi/2.0/file/' + data.fileId + '?userid=' + this._authHelper.getUserId() + '&previewtopdf=true' + '&extension=' + data.fileExtension + '&filename=' + encodeURIComponent(data.fileName));
            } else {
                // support use entry api for preview
                let fileUrl = data.fileLink;
                if (!data.isFileIDIncluded) {
                    fileUrl += data.fileId;
                }
                if (PDFPreviewExtension.includes(data.fileExtension.toLowerCase())) {
                    if (data.directlyDownload) {
                        fileUrl = encodeURIComponent(fileUrl);
                    } else {
                        if (data.fileExtension === 'pdf') { // needn't to add 'previewtopdf=true' to avoid convert re-convert in server
                            fileUrl = encodeURIComponent(fileUrl + '?userid=' + this._authHelper.getUserId() + '');
                        } else {
                            fileUrl = encodeURIComponent(fileUrl + '?userid=' + this._authHelper.getUserId() + '&previewtopdf=true');
                        }
                    }
                    tempUrl = 'vendor/pdfjs/web/viewer.html?name=' + encodeURIComponent(data.fileName) + '&file=' + fileUrl;

                }
            }
            if (!data.isPreviewInNewTab) {
                this._renderer2.setAttribute(this._element.nativeElement.querySelector(`#${PREVIEW_IFRAME_ID}`), 'src', tempUrl);
                this.modalIsShow = true;
                this._changeDetectorRef.detectChanges();
            } else {
                this._renderer2.setAttribute(this._element.nativeElement.querySelector(`#${PREVIEW_LINK_ID}`), 'href', tempUrl);
                setTimeout(
                    () => {
                        document.getElementById(`${PREVIEW_LINK_ID}`).click();
                    }
                );
            }

        }
    }
}
