<div class="container" [ngSwitch]="value">
    <div *ngSwitchCase="1">
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star-o"></smart-icon>
        <smart-icon name="star-o"></smart-icon>
    </div>
    <div *ngSwitchCase="2">
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star-o"></smart-icon>
    </div>
    <div *ngSwitchCase="3">
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star"></smart-icon>
        <smart-icon name="star"></smart-icon>
    </div>
</div>