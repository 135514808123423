/**
 * Created by Youran Fang on 03/10/2020.
 * Description:
 *  Help mapping note data returned from query service. *
 * ------ maintenance history ------
 */
import * as _ from 'lodash';

import { EntryClass } from '../tamalelibs/models/entry-class.model';
import { NoteEntry } from '../tamalelibs/models/note-entry.model';
import { EntityBrief } from '../tamalelibs/models/entity-brief.model';
import { Sentiment } from '../tamalelibs/models/sentiment.model';
import { Priority } from '../tamalelibs/models/priority.model';
import { AdhocField } from '../tamalelibs/models/adhoc-field.model';
import { AppConfig } from '../tamalelibs/models/app-config.model';
import { DocumentEntry } from '../tamalelibs/models/document-entry.model';
import { Thread } from '../tamalelibs/models/thread.model';
import { EntryType } from '../tamalelibs/models/entry-type.model';

export class QueryNoteMapperService {

    constructor(
    ) { }

    mapJson2Note(properties: any): NoteEntry {
        const note = new NoteEntry();
        note.id = properties['entry_id'];

        note.source = new EntityBrief(properties['source_id'], properties['source_name']);
        note.source.company = properties['company'];
        note.submitter = new EntityBrief(properties['submitter_id'], properties['submitter_name']);
        note.subject = properties['title'];
        note.calculatedSubject = properties['title'];
        note.displayDate = new Date(properties['display_date']);
        note.submittedDate = new Date(properties['submitted_date']);
        note.bodyLink = '/restapi/2.1/entry/' + properties['entry_id'] + '/body/';
        note.blurb = properties['maxblurb'] ? properties['maxblurb'].replace(/(?:\r\n|\r|\n)/g, '<br>') : null;
        note.backdated = properties['is_backdated'];
        note.editable = true;
        note.deletable = true;

        note.type = this.property2NoteType(properties['entry_type_name'], properties['entry_type_id']);
        note.sentiment = this.property2Sentiment(properties['sentiment']);
        note.priority = this.property2Priority(properties['priority']);
        note.entryClass = this.property2EntryClass(properties['class']);
        note.entities = this.array2Entities(properties['entity']);
        note.adhocs = this.array2AdhocTable(properties['adhoc_table']);

        // note.attachments = new Map<string, DocumentEntry>();

        return note;
    }

    mapJson2Attachment(properties: any, threadId: string): DocumentEntry {
        const attachment = new DocumentEntry();
        attachment.id = properties['entry_id'];
        attachment.subject = properties['title'];

        const dotExtension: string = properties['extension'];
        attachment.fileName = properties['title'] + dotExtension;
        attachment.extension = dotExtension.replace('.', '');

        attachment.displayDate = new Date(properties['display_date']);
        attachment.priority = this.property2Priority(properties['priority']);
        attachment.deletable = true;

        attachment.type = this.property2NoteType(properties['entry_type_name'], properties['entry_type_id']);
        attachment.sentiment = this.property2Sentiment(properties['sentiment']);
        attachment.priority = this.property2Priority(properties['priority']);
        attachment.noteId = properties['parent_entry_id'];
        attachment.threadId = threadId;
        attachment.fileDataLink = AppConfig.entryEndpoint + attachment.id + '/filedata/';

        // following properties from query service, are not surly passed
        if (properties['source_id'] && properties['source_name']) {
            attachment.source = new EntityBrief(properties['source_id'], properties['source_name']);
            if (properties['company']) {
                attachment.source.company = properties['company'];
            }
        }
        if (properties['submitter_id'] && properties['submitter_name']) {
            attachment.submitter = new EntityBrief(properties['submitter_id'], properties['submitter_name']);
        }
        if (properties['submitted_date']) {
            attachment.submittedDate = new Date(properties['submitted_date']);
        }
        if (properties['class']) {
            attachment.entryClass = this.property2EntryClass(properties['class']);
        }
        if (properties['entity']) {
            attachment.entities = this.array2Entities(properties['entity']);
        }
        return attachment;
    }

    addOtherEntries(entries: any[], thread: Thread): Thread {
        // prepare notes without attachments
        const notes: NoteEntry[] = [];
        for (let i = 0; i < entries.length; i++) {
            if (entries[i]['class'] !== 'attachment') {
                const note = this.mapJson2Note(entries[i]);
                notes.push(note);
            }
        }

        // prepare attachments
        const attachments: Map<string, DocumentEntry[]> = new Map<string, DocumentEntry[]>();
        for (let i = 0; i < entries.length; i++) {
            if (entries[i]['class'] === 'attachment') {
                const attachment = this.mapJson2Attachment(entries[i], thread.id);
                const existingNoteAttachments = attachments.get(attachment.noteId);
                if (existingNoteAttachments && existingNoteAttachments.length) {
                    existingNoteAttachments.push(attachment);
                    attachments.set(attachment.noteId, existingNoteAttachments);
                } else {
                    attachments.set(attachment.noteId, [attachment]);
                }
            }
        }

        for (let i = 0; i < notes.length; i++) {
            // set note attachments
            notes[i] = this.addAttachments2Note(attachments, notes[i]);
            // put note into thread
            thread = this.addNote2Thread(notes[i], thread);
        }
        return thread;
    }

    addNote2Thread(note: NoteEntry, thread: Thread): Thread {
        // set thread if this note is parent note
        if (thread.id === note.id) {
            thread.displayDate = note.displayDate;
            thread.lastEditedDate = note.lastEditDate;
            thread.submittedDate = note.submittedDate;
            thread.lastModifiedDate = new Date(0);
        }

        // calculate thread last modified date
        if (note.lastEditDate > thread.lastModifiedDate) {
            thread.lastModifiedDate = note.lastEditDate;
        }

        thread.entities = this.mergeEntities([thread.entities, ...thread.notes.map(_note => _note.entities)]);

        // set thread attachments
        if (!thread.attachments) {
            thread.attachments = new Map<string, DocumentEntry>();
        }
        note.attachments.forEach((value, key) => {
            thread.attachments.set(key, value);
        });

        if (!thread.notes) {
            thread.notes = [];
        }

        const index = thread.notes.findIndex(item => item.id === note.id);
        // set note threadPosition
        if (thread.id === note.id) {
            note.threadPosition = 0;
        } else {
            if (thread.notes.length === 0) {
                note.threadPosition = 1;
            } else {
                note.threadPosition = thread.notes.length + (index >= 0 ? 0 : 1);
            }
        }

        // set note into thread
        if (index >= 0) {
            thread.notes[index] = note;
        } else {
            thread.notes.push(note);
        }

        return thread;
    }

    addAttachments2Note(attachments: Map<string, Array<DocumentEntry>>, note: NoteEntry): NoteEntry {
        // set note attachemnts
        note.attachments = new Map<string, DocumentEntry>();
        note.attachmentIds = [];
        const noteAttachments = attachments.get(note.id);
        if (noteAttachments) {
            noteAttachments.forEach((attachment: DocumentEntry) => {
                note.attachments.set(attachment.id, attachment);
                note.attachmentIds.push(attachment.id);
            });
        }
        return note;
    }

    array2Entities(array: any[]): EntityBrief[] {
        const result: EntityBrief[] = [];
        for (let i = 0; i < array.length; i++) {

            const newEntity = new EntityBrief(array[i]['id'], array[i]['name']);
            newEntity.shortName = array[i]['short_name'];
            result.push(newEntity);
        }
        return result;
    }

    property2EntryClass(property: string): number {
        switch (property) {
            case 'entry':
                return EntryClass.Note;
            case 'event':
                return EntryClass.Event;
            default:
                return EntryClass.File;
        }
    }

    property2Sentiment(property: string): number {
        switch (property) {
            case 'Positive':
                return Sentiment.Positive;
            case 'Neutral':
                return Sentiment.Neutral;
            case 'Negative':
                return Sentiment.Negative;
        }
        return -2;
    }

    property2Priority(property: string): number {
        switch (property) {
            case 'None':
                return Priority.None;
            case 'One':
                return Priority.One;
            case 'Two':
                return Priority.Two;
            case 'Three':
                return Priority.Three;
        }
    }

    array2AdhocTable(array: any[]): Map<string, AdhocField> {
        const result: Map<string, AdhocField> = new Map<string, AdhocField>();
        for (let i = 0; array && i < array.length; i++) {
            const adhoc = new AdhocField();
            adhoc.id = array[i]['attrDefID'];
            adhoc.name = array[i]['attrDefName'];
            // adhoc.title =columns['adhoc_table'][i]['attrDefName'];
            adhoc.value = array[i]['value'];
            result.set(adhoc.id, adhoc);
        }
        return result;
    }

    array2Attachments(array: any[], threadId: string): Map<string, Array<DocumentEntry>> {
        const attachments = new Map<string, Array<DocumentEntry>>();
        for (let i = 0; i < array.length; i++) {
            const currentAttachment = this.mapJson2Attachment(array[i], threadId);
            const existingNoteAttachments = attachments.get(currentAttachment.noteId);

            if (existingNoteAttachments && existingNoteAttachments.length > 0) {
                const existing = existingNoteAttachments.find((attachment: DocumentEntry) => attachment.id === currentAttachment.id);
                if (!existing) {
                    existingNoteAttachments.push(currentAttachment);
                    attachments.set(currentAttachment.noteId, existingNoteAttachments);
                }
            } else {
                attachments.set(currentAttachment.noteId, [currentAttachment]);
            }
        }
        return attachments;
    }

    property2NoteType(name: string, id: string): EntryType {
        if (name === EntryType.TAMALE_EVENT.name) {
            return new EntryType(EntryType.TAMALE_EVENT.id, EntryType.TAMALE_EVENT.name);
        } else if (name === EntryType.TAMALE_NOTE.name) {
            return new EntryType(EntryType.TAMALE_NOTE.id, EntryType.TAMALE_NOTE.name);
        } else if (name === EntryType.DEPOSITED_BLAST_EMAIL.name) {
            return new EntryType(EntryType.DEPOSITED_BLAST_EMAIL.id, EntryType.DEPOSITED_BLAST_EMAIL.name);
        } else {
            return new EntryType(id, name);
        }
    }

    mergeEntities(entityArrays: EntityBrief[][]): EntityBrief[] {
        const entityMap: Map<string, EntityBrief> = new Map<string, EntityBrief>();
        for (let i = 0; i < entityArrays.length; i++) {
            for (let j = 0; entityArrays[i] && j < entityArrays[i].length; j++) {
                const id = entityArrays[i][j].id;
                const existing = entityMap.get(id);
                if (!existing) {
                    entityMap.set(id, entityArrays[i][j]);
                }
            }
        }
        return Array.from(entityMap.values());
    }
}
