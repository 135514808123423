/**
 * Created by Abner Sui on 07/11/2019
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { EmailDigestEditorComponent } from './email-digest-editor.component';

@Injectable({
    providedIn: 'root',
})
export class EmailDigestGuard implements CanDeactivate<EmailDigestEditorComponent> {
    constructor(
        private _alertWindow: AlertWindowService,
    ) { }
    canDeactivate(component: EmailDigestEditorComponent): Observable<boolean> | Promise<boolean> | boolean {
        if (component.isDirty()) {
            return this._alertWindow.warn('You have unsaved changes', ['Are you sure you want to discard all your changes?']).pipe(
                map((result: boolean) => {
                    return result;
                }),
            );
        } else {
            return true;
        }
    }
}
