<tam-dialog [opened]="dialogOpened" [dialogWidth]="800" class="container">
    <ng-template #title>
        <span class="dialog-title">Formula Builder</span>
        <smart-icon class="dark dialog-close" name="close" (click)="onClose()">
        </smart-icon>
    </ng-template>
    <ng-template #body>
        <div class="dialog-body">
            <div class="left">
                <ng-container *ngIf="initTabSet">
                    <tam-tab-set (selectChange)="selectChange($event)" [selectedIndex]="selectedIndex">
                        <tam-tab title="Fields" style="margin-left: 42px;"></tam-tab>
                        <tam-tab title="Functions" style="margin-left: 25px;"></tam-tab>
                    </tam-tab-set>
                </ng-container>
                <div class="left-bottom">
                    <ng-container *ngIf="selectedIndex === 0">
                        <ng-container *ngIf="fields && fields.length > 0">
                            <ng-container *ngFor="let field of fields;trackBy:trackByFn">
                                <div class="field" (dblclick)="dblclickField(field)">{{field.title}}</div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="selectedIndex === 1">
                        <kendo-treeview [nodes]="functions" textField="name" childrenField="items"
                            kendoTreeViewExpandable kendoTreeViewHierarchyBinding (nodeClick)="selectNode($event)"
                            (nodeDblClick)="dblClickNode($event)" (collapse)="handleCollapse($event)"
                            (expand)="handleExpand($event)">
                        </kendo-treeview>
                    </ng-container>
                </div>
            </div>
            <div class="right">
                <div class="right-top">
                    <textarea class="textarea" #textarea placeholder="Please input formula here." [(ngModel)]="formula"
                        (input)="inputFormula()" (click)="showInfo()" (keyup)="showInfo()"></textarea>
                </div>
                <div class="right-bottom">
                    <ng-container *ngIf="hasFormulaError">
                        <span class="error">{{formulaSyntaxErrorMessage}}</span>
                    </ng-container>
                    <ng-container *ngIf="!hasFormulaError">
                        <div *ngIf="funcSyntax" class="bottom-span">
                            <span class="bottom-title">Syntax:</span><span>{{funcSyntax}}</span>
                        </div>
                        <div *ngIf="funcIntroduction" class="bottom-span">
                            <span class="bottom-title">Introduction:</span><span [innerHTML]="funcIntroduction"></span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #footer>
        <button class="footer-button footer-research-button" tam-button [tamType]="'tertiary'" [tamSize]="'normal'"
            (click)="onClose()">Cancel</button>
        <button class="footer-button footer-research-button" tam-button [tamType]="'primary'" [tamSize]="'normal'"
            (click)="onSave()">Save</button>
    </ng-template>
</tam-dialog>