/**
 * Create by Marcus 7/6/2020
 */

import { Observable } from 'rxjs';
import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ContactRelationshipService {
    constructor(
        private _transportService: TransportService,
    ) { }

    getValidRelationshipByName(name: string[]): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        };
        const url = `${AppConfig.validRelationshipTypeEndpointByName}`;
        const params = {
            name: JSON.stringify(name)
        };
        const options = {
            header: headers,
            params: params
        };
        return this._transportService.post(url, '', options);
    }

    createRelationshipType(name: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.relationshipTypeEndpoint}`;
        const params = {
            name: name
        };
        const options = {
            header: headers,
            params: params
        };
        const formData = new URLSearchParams();
        formData.set('name', name);
        return this._transportService.post(url, formData.toString(), options);
    }

    createValidRelationship(parentId: string, relatinshipTypeId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=uft-8'
        };
        const url = `${AppConfig.relationshipTypeEndpoint}` + `${relatinshipTypeId}` + '/valid-relationships/';
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('child', parentId);
        formData.set('parent', parentId);
        formData.set('reciprocal', relatinshipTypeId);
        return this._transportService.post(url, formData.toString(), options);
    }
}
