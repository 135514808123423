
/**
 * Created by Marcus Ma on 5/27/20.
 * Description:
 * ------ maintenance history ------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NewRelationshipService {
    dialogClose$: Subject<any> = new Subject<any>();
    dialogOpen$: Subject<any> = new Subject<any>();
    feedbackSubject$: Subject<any> = new Subject<any>();
}
