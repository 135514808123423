/**
 * Created by Abner Sui on 09/29/2019
 * -------------------------------------
 * 08/11/2021 Marcus Zhao update Message model add icon.
 * 07/01/2022 Marcus Zhao update message model for support bulk action.
 */

export class Message {
    id: string;
    header: string;
    icon?: string;
    title: string;
    subtitle?: string;
    type: string;
    receiveDate: number;
    date: Date;
    data?: any;
}

export enum MessageType {
    TASK = 'task',
    QUICK_TASK = 'quick_task',
    ENTRY_TEMPLATE = 'entry_template',
    ENTITY_TEMPLATE = 'entity_template',
    PULL_GEOCODE = 'pullGeocode',
    EXCHANGE_IMPORT = 'exchangeImport',
    EXCHANGE_SYNC = 'exchangeSync',
    BULK_OPERATOIN = 'bulkOperation',
}

export enum MessageSubtype {
    NEW_TASK = 'new',
    SENDBACK_TASK = 'sendback',
}

export enum MessageOperateType {
    DELETE = 'DELETE_MESSAGE',
}

export enum WorkflowMessageType {
    TASK_COMPLETION = 'task_completion',
}
