/**
 * Created by Marcus Zhao on 2/13/2021.
 * Description: exchange service for get data.
 * ------ maintenance history ------
 * Updated by Daniel Wang on 05/06/2022, added more model for exchange merge.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { businessConstants } from '../constants/business.constants';
import { AppConfig } from '../models/app-config.model';
import { ExchangeDataContainer, DuplicateItem, ExchangeImportDetailDataModel, IntegratedValue, SameItem, ExchangeContactDetail } from '../models/exchange-import.model';
import { ArrayHelperService } from './array-helper.service';
import { StringHelperService } from './string-helper.service';
import { TransportService } from './transport.service';
import * as _lodash from 'lodash';

@Injectable()
export class ExchangeService {
    constructor(
        private _transportService: TransportService,
    ) { }

    /**
    * Memo: The function used to get compare data from server.
    * @param data: The information which used to get the compare data from server.
    * @return: The Observable contains all the data which return from server.
    * @memberof ExchangeService
    */
    public exchangeCompareDataWithTamale(data): Observable<any> {
        const url = AppConfig.exchangeComparison;
        const headers = {
            'Content-Type': 'application/json',
        };
        const options = {
            headers: headers,
        };
        const formdata = new FormData();
        formdata.append('data', JSON.stringify(data));
        return this._transportService.post(url, formdata);
    }

    /*
    * @returns
    * @memberof ExchangeService
    */
    public exchangeDuplicateWithTamale(data): Observable<any> {
        const url = AppConfig.exchangeDuplicate;
        const formdata = new FormData();
        formdata.append('data', JSON.stringify(data));
        return this._transportService.post(url, formdata);
    }

    public getConfigDataSource() {
        const url = AppConfig.exchangeConfigDataSource;
        const headers = {
            'Content-Type': 'application/json',
        };
        const options = {
            headers: headers,
        };
        return this._transportService.get(url, options);
    }

    public getConfigInfo() {
        const url = AppConfig.exchangeConfigInfo;
        const headers = {
            'Content-Type': 'application/json',
        };
        const options = {
            headers: headers,
        };
        return this._transportService.get(url, options);
    }

    public getConfigMap() {
        const url = AppConfig.exchangeConfigMap;
        const headers = {
            'Content-Type': 'application/json',
        };
        const options = {
            headers: headers,
        };
        return this._transportService.get(url, options);
    }

    /**
    * Memo: The function used to get the contact detail information by no conflicte items
    * @param compareData: The information which used to get the compare data from server.
    * @returns: The Observable contains all the data which return from server.
    * @memberof ExchangeService
    */
    public getContactDetailByNoConflictateItems(noConflicateItems: Array<SameItem>, contactDetail: ExchangeContactDetail): void {
        noConflicateItems.forEach(item => {
            const fieldName = item.name;

            if (contactDetail[fieldName]) {
                if (Array.isArray(contactDetail[fieldName])) {
                    if (!ArrayHelperService.arrayContains(contactDetail[fieldName], item.value)) {
                        contactDetail[fieldName].push(item.value);
                    }
                }
            } else {
                // the item doestn't exist in the contactDetail object
                if (Array.isArray(item.value)) {
                    if (item.value.length > 0) {
                        contactDetail[fieldName] = [];
                        contactDetail[fieldName] = item.value;
                    }
                } else {
                    if (item.name === businessConstants.exchangeMerge.fields.webPhone ||
                        item.name === businessConstants.exchangeMerge.fields.email ||
                        item.name === businessConstants.exchangeMerge.fields.socialMedia ||
                        item.name === businessConstants.exchangeMerge.fields.webAddress) {
                        contactDetail[fieldName] = [item.value];
                    } else {
                        contactDetail[fieldName] = item.value;
                    }
                }
            }
        });
    }

    /**
    * Memo: The function used to get the duplicate items by the server response.
    * @param compareData: The information which used to get the compare data from server.
    * @returns: The Observable contains all the data which return from server.
    * @memberof ExchangeService
    */
    public getDuplicateData(compareData: ExchangeImportDetailDataModel): any {
        if (!compareData) {
            return null;
        }

        const exchangeDataContainer = new Array<ExchangeDataContainer>();
        compareData.needMergedContacts.forEach(compareItem => {
            const duplicateItems = new Array<DuplicateItem>(); // userd to store the duplicate items.
            const sameItems = new Array<SameItem>(); // used to store the conflict-free items.
            const tamaleContact = compareItem.duplicate.tamale; // used to store tamale data.
            const exchangeContact = compareItem.duplicate.exchange; // used to store exchange data.
            let tamaleModifyDate = 0;
            let exchangeModifyDate = 0;
            // used to store the all properties which exist in tamale and exchange
            const allProperties = new Array<string>();
            Object.entries(tamaleContact).map(item => {
                allProperties.push(item[0]);
            });
            Object.entries(exchangeContact).map(item => {
                if (!allProperties.includes(item[0])) {
                    allProperties.push(item[0]);
                }
            });
            allProperties.forEach(item => {
                // should use the exising property if the property doesn't exist in other container
                if (tamaleContact[item] === undefined) {
                    const sameItem = new SameItem(item, exchangeContact[item]);
                    sameItems.push(sameItem);
                } else if (exchangeContact[item] === undefined) {
                    const sameItem = new SameItem(item, tamaleContact[item]);
                    sameItems.push(sameItem);
                } else {
                    const exchangeValue = exchangeContact[item];
                    const tamaleValue = tamaleContact[item];
                    // handle string type. such as first name
                    if (typeof tamaleContact[item] === businessConstants.common.type.STRING) {
                        if (tamaleValue.length === 0) {
                            const sameItem = new SameItem(item, exchangeValue);
                            sameItems.push(sameItem);
                        } else if (exchangeValue.length === 0) {
                            const sameItem = new SameItem(item, tamaleValue);
                            sameItems.push(sameItem);
                        } else {
                            const isEqual = StringHelperService.isEqual(tamaleValue, exchangeValue);
                            if (!isEqual) {
                                const displayName = this._getDisplayNameShowOnUI(item);
                                const duplicateItem = this.generateDuplicateItemForString(item, displayName, tamaleValue, exchangeValue);
                                duplicateItems.push(duplicateItem);
                            } else {
                                const sameItem = new SameItem(item, tamaleValue);
                                sameItems.push(sameItem);
                            }
                        }
                        // handle number type. only modify data for now
                    } else if (typeof tamaleContact[item] === businessConstants.common.type.NUMBER) {
                        tamaleModifyDate = tamaleValue;
                        exchangeModifyDate = exchangeValue;
                    } else {
                        // handle array type. such as email
                        if (Array.isArray(tamaleValue)) {
                            if (tamaleValue.length === 0) {
                                const sameItem = new SameItem(item, exchangeValue);
                                sameItems.push(sameItem);
                            } else if (exchangeValue.length === 0) {
                                const sameItem = new SameItem(item, tamaleValue);
                                sameItems.push(sameItem);
                            } else {
                                // job title and alias
                                if (item === businessConstants.exchangeMerge.fields.jobTitle || item === businessConstants.exchangeMerge.fields.alias) {
                                    const orderBy = this.getOrderByValueByDuplicateItemName(item);
                                    if (!ArrayHelperService.isEqual(tamaleValue, exchangeValue, true)) {
                                        const tamaleList = new Array<IntegratedValue>();
                                        const exchangeList = new Array<IntegratedValue>();
                                        tamaleValue.forEach(data => {
                                            if (item.toLowerCase() === businessConstants.exchangeMerge.fields.alias) {
                                                tamaleList.push(new IntegratedValue(data, data, data));
                                            } else {
                                                tamaleList.push(new IntegratedValue(data.id, data.name, data.name));
                                            }
                                        });
                                        exchangeValue.forEach(data => {
                                            if (item.toLowerCase() === businessConstants.exchangeMerge.fields.alias) {
                                                exchangeList.push(new IntegratedValue(data, data, data));
                                            } else {
                                                exchangeList.push(new IntegratedValue(data.id, data.name, data.name));
                                            }
                                        });
                                        const displayName = this._getDisplayNameShowOnUI(item);
                                        const duplicateItem = new DuplicateItem(item, displayName, tamaleList, exchangeList, orderBy);
                                        duplicateItems.push(duplicateItem);
                                    } else {
                                        const sameItem = new SameItem(item, tamaleValue);
                                        sameItems.push(sameItem);
                                    }
                                } else if (item === businessConstants.exchangeMerge.fields.email ||
                                    item === businessConstants.exchangeMerge.fields.socialMedia ||
                                    item === businessConstants.exchangeMerge.fields.webPhone ||
                                    item === businessConstants.exchangeMerge.fields.webAddress) {
                                    const valueProperty = this.getValuePropertyByDuplicateItemName(item);
                                    const orderBy = this.getOrderByValueByDuplicateItemName(item);

                                    // create a continer to record items which has the same value
                                    // get the same value types
                                    const noConflicateItems = [];
                                    const cloneTamaleValue = _lodash.cloneDeep(tamaleValue);
                                    const cloneExchangeValue = _lodash.cloneDeep(exchangeValue);
                                    cloneTamaleValue.forEach(tamale => {
                                        tamale.primary = false;
                                        const matchItem = cloneExchangeValue.find(_exchange => {
                                            _exchange.primary = false;
                                            if (ArrayHelperService.isEqual(_exchange, tamale)) {
                                                return _exchange;
                                            }
                                        });
                                        if (matchItem) {
                                            noConflicateItems.push(matchItem);
                                        }
                                    });
                                    // only exist in tamale
                                    tamaleValue.forEach(tamale => {
                                        const matchItem = exchangeValue.find(_exchange => {
                                            if (_exchange.name === tamale.name) {
                                                return _exchange;
                                            }
                                        });
                                        if (!matchItem) {
                                            noConflicateItems.push(item);
                                            const sameItem = new SameItem(item, tamale);
                                            sameItems.push(sameItem);
                                        }
                                    });
                                    // only exist in exchange
                                    exchangeValue.forEach(_exchange => {
                                        const matchItem = tamaleValue.find(tamale => {
                                            if (tamale.name === _exchange.name) {
                                                return tamale;
                                            }
                                        });
                                        if (!matchItem) {
                                            const sameItem = new SameItem(item, _exchange);
                                            sameItems.push(sameItem);
                                        }
                                    });
                                    // handle duplicate items
                                    const types = [];
                                    tamaleValue.forEach(tamale => {
                                        if (!ArrayHelperService.arrayContains(noConflicateItems, tamale)) {
                                            const matchExchanges = exchangeValue.filter(_exchange => {
                                                if (_exchange.name === tamale.name) {
                                                    return _exchange;
                                                }
                                            });
                                            if (matchExchanges.length > 1) {
                                                if (!types.includes(tamale.name)) {
                                                    types.push(tamale.name);
                                                    const matchTamales = tamaleValue.filter(tml => {
                                                        if (tml.name === tamale.name) {
                                                            return tml;
                                                        }
                                                    });
                                                    const tamaleList = new Array<IntegratedValue>();
                                                    matchTamales.forEach(tml => {
                                                        tamaleList.push(new IntegratedValue(tml.id, tml.name, tml[valueProperty], tml.primary));
                                                    });
                                                    const exchangeList = new Array<IntegratedValue>();
                                                    matchExchanges.forEach(exc => {
                                                        exchangeList.push(new IntegratedValue(exc.id, exc.name, exc[valueProperty], exc.primary));
                                                    });
                                                    if (!ArrayHelperService.isEqual(tamaleList, exchangeList, true)) {
                                                        const duplicateNameShowOnUI = matchExchanges[0].name + ' ' + this._getDisplayNameShowOnUI(item);
                                                        const duplicateItem = new DuplicateItem(item, duplicateNameShowOnUI, tamaleList, exchangeList, orderBy);
                                                        duplicateItems.push(duplicateItem);
                                                    } else {
                                                        matchExchanges.forEach(exc => {
                                                            const sameItem = new SameItem(item, exc);
                                                            sameItems.push(sameItem);
                                                        });
                                                    }
                                                }
                                            } else if (matchExchanges.length === 1) {
                                                const matchExchange = matchExchanges[0];
                                                if (matchExchange) {
                                                    // array compare
                                                    if (noConflicateItems.length === 0 ||
                                                        noConflicateItems.filter(noConflicateItem => noConflicateItem.name === matchExchange.name).length === 0) {
                                                        if (ArrayHelperService.isEqual(matchExchange[valueProperty], tamale[valueProperty], true)) {
                                                            const sameItem = new SameItem(item, tamale);
                                                            sameItems.push(sameItem);
                                                        } else {
                                                            if (!types.includes(tamale.name)) {
                                                                types.push(tamale.name);
                                                                const tamaleList = new Array<IntegratedValue>();
                                                                let value = tamale[valueProperty];
                                                                if (tamale[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]) {
                                                                    value = Object.assign(value, tamale[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]);
                                                                }
                                                                tamaleList.push(new IntegratedValue(tamale.id, tamale.name, value, tamale.primary));

                                                                let matchTamale: IntegratedValue;
                                                                const sameNameItems = new Array<IntegratedValue>();
                                                                tamaleValue.forEach(_item => {
                                                                    // get tamale contact whose value is equal to the value
                                                                    if (ArrayHelperService.isEqual(matchExchange[valueProperty], _item[valueProperty], true)) {
                                                                        let _value = _item[valueProperty];
                                                                        if (_item[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]) {
                                                                            _value = Object.assign(_value, _item[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]);
                                                                        }
                                                                        matchTamale = new IntegratedValue(_item.id, _item.name, _value);
                                                                    }
                                                                    // get tamale contact whose name is equal to the name
                                                                    if (_item.name === tamale.name && !ArrayHelperService.isEqual(_item[valueProperty], tamale[valueProperty], true)) {
                                                                        let _value = _item[valueProperty];
                                                                        if (_item[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]) {
                                                                            _value = Object.assign(_value, _item[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]);
                                                                        }
                                                                        sameNameItems.push(new IntegratedValue(_item.id, _item.name, _value));
                                                                    }
                                                                });
                                                                if (matchTamale) {
                                                                    tamaleList.push(matchTamale);
                                                                }
                                                                if (sameNameItems.length > 0) {
                                                                    tamaleList.push(...sameNameItems);
                                                                }
                                                                const exchangeList = new Array<IntegratedValue>();
                                                                let combineValue = matchExchange[valueProperty];
                                                                if (matchExchange[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]) {
                                                                    combineValue = Object.assign(combineValue, matchExchange[businessConstants.exchangeMerge.valueProperty.addressOtherProperty]);
                                                                }
                                                                exchangeList.push(new IntegratedValue(matchExchange.id, matchExchange.name, combineValue, matchExchange.primary));
                                                                // handle the duplicate name which is shown on UI
                                                                const duplicateNameShowOnUI = matchExchange.name + ' ' + this._getDisplayNameShowOnUI(item);
                                                                const duplicateItem = new DuplicateItem(item, duplicateNameShowOnUI, tamaleList, exchangeList, orderBy);
                                                                duplicateItems.push(duplicateItem);
                                                            }
                                                        }
                                                    } else {
                                                        const sameItem = new SameItem(item, tamale);
                                                        sameItems.push(sameItem);
                                                    }
                                                }
                                            }
                                        } else {
                                            const sameItem = new SameItem(item, tamale);
                                            sameItems.push(sameItem);
                                        }
                                    });
                                }
                            }
                        } else {
                            // handle object, such as company
                            if (item === businessConstants.exchangeMerge.fields.company) {
                                if (tamaleValue.name === undefined || tamaleValue.name.length === 0) {
                                    const sameItem = new SameItem(item, exchangeValue);
                                    sameItems.push(sameItem);
                                } else if (exchangeValue.name === undefined || exchangeValue.name.length === 0) {
                                    const sameItem = new SameItem(item, tamaleValue);
                                    sameItems.push(sameItem);
                                } else {
                                    if (tamaleValue.name === exchangeValue.name) {
                                        const sameItem = new SameItem(item, tamaleValue);
                                        sameItems.push(sameItem);
                                    } else {
                                        const displayName = this._getDisplayNameShowOnUI(item);
                                        const duplicateItem = this.generateDuplicateItemForOjbect(item, displayName, tamaleValue, exchangeValue);
                                        duplicateItems.push(duplicateItem);
                                    }
                                }
                            }
                        }
                    }
                }
            });
            const exchange = compareItem.exchange;
            const tamaleId = compareItem.tamaleId;
            const syncType = compareItem.syncType;
            const dupContainer = new ExchangeDataContainer(exchange, tamaleId, duplicateItems, sameItems, false, tamaleModifyDate, exchangeModifyDate, syncType);
            exchangeDataContainer.push(dupContainer);
        });

        return exchangeDataContainer;
    }

    public getExchangeImportContactList(): Observable<any> {
        const url = AppConfig.exchangeImportContact;
        const headers = {
            'Content-Type': 'application/json',
        };
        const options = {
            headers: headers,
        };
        return this._transportService.get(url, options);
    }

    /**
     * Get firm configuration.
     *
     * @returns {Observable<any>}
     * @memberof ExchangeService
     */
    public getFirmSubscriptionConfig(): Observable<any> {
        const url = AppConfig.exchangeConfigSynchronization;
        return this._transportService.get(url);
    }

    /**
     * Get Firm Domain.
     *
     * @returns {Observable<any>}
     * @memberof ExchangeService
     */
    public getFirmSubscriptionDomain(): Observable<any> {
        const url = AppConfig.exchangeSubscriptionDomain;
        return this._transportService.get(url);
    }

    /**
     * Get individual Configuration.
     *
     * @returns {Observable<any>}
     * @memberof ExchangeService
     */
    public getIndividualSubscriptionConfig(): Observable<any> {
        const url = AppConfig.exchangeSubscription;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        };
        const params = {
            resourceType: 'contacts'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public isContainDuplicateData(compareData: ExchangeImportDetailDataModel): boolean {
        if (!compareData) {
            return false;
        }

        const isDuplicate = false;
        return isDuplicate;
    }

    public postExchangeImportContactList(data): Observable<any> {
        const url = AppConfig.exchangeImportContact;
        const formdata = new FormData();
        formdata.append('data', JSON.stringify(data));
        return this._transportService.post(url, formdata);
    }

    public putConfigCategory(data) {
        const url = AppConfig.exchangeConfigCategory;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(data));
        return this._transportService.put(url, formData.toString(), options);
    }

    public putConfigConnect(data) {
        const url = AppConfig.exchangeConfigConnect;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(data));
        return this._transportService.put(url, formData.toString(), options);
    }

    public putConfigMap(data) {
        const url = AppConfig.exchangeConfigMap;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify(data));
        return this._transportService.put(url, formData.toString(), options);
    }

    public putConfigSync(data) {
        const url = AppConfig.exchangeConfigSync;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('configuration', JSON.stringify({ 'syncRule': data }));
        return this._transportService.put(url, formData.toString(), options);
    }

    /**
     * Modify firm subscription
     *
     * @param {*} configs
     * @returns {Observable<any>}
     * @memberof ExchangeService
     */
    public putConfigSynchronization(configs, syncRule: string): Observable<any> {
        const url = AppConfig.exchangeConfigSynchronization;
        const formdata = new FormData();
        formdata.append('firmSubscriptionConfig', JSON.stringify(configs));
        formdata.append('syncRule', syncRule);
        return this._transportService.put(url, formdata);
    }

    /**
     * Modify individual subscription
     *
     * @param {*} configs
     * @returns {Observable<any>}
     * @memberof ExchangeService
     */
    public putIndividualSubscriptionConfig(configs): Observable<any> {
        const url = AppConfig.exchangeSubscription;
        const formdata = new FormData();
        formdata.append('config', JSON.stringify(configs));
        return this._transportService.put(url, formdata);
    }

    public resetMapping() {
        const url = AppConfig.exchangeConfigDefaultMap;
        const headers = {
            'Content-Type': 'application/json',
        };
        const options = {
            headers: headers,
        };
        return this._transportService.put(url, null, options);
    }

    /**
    * Change status for trun on/off AutoSync Setting.
    *
    * @param {*} enableStatus
    * @returns {Observable<any>}
    * @memberof ExchangeService
    */
    public updateAutoSycnSettingStatus(enableStatus): Observable<any> {
        const url = AppConfig.exchangeConfigAutomation;
        const formdata = new FormData();
        formdata.append('configuration', JSON.stringify({ 'enable': enableStatus }));
        return this._transportService.put(url, formdata);
    }

    /**
    * generate duplicate item for the object
    *
    * @param name: the name of the duplicate item
    * @param tamaleValue: the value of tamale
    * @param exchangeValue: the value of exchange
    * @returns the duplicate item for the object
    * @memberof ExchangeService
    */
    private generateDuplicateItemForOjbect(name: string, displayName: string, tamaleValue: any, exchangeValue: any) {
        const orderBy = this.getOrderByValueByDuplicateItemName(name);
        const tamale = new IntegratedValue(tamaleValue.id, tamaleValue.name, tamaleValue.name);
        const exchange = new IntegratedValue(exchangeValue.id, exchangeValue.name, exchangeValue.name);
        const duplicateItem = new DuplicateItem(name, displayName, [tamale], [exchange], orderBy, false, false, false, true);

        return duplicateItem;
    }

    /**
    * generate duplicate item for the string
    *
    * @param name: the name of the duplicate item
    * @param tamaleValue: the value of tamale
    * @param exchangeValue: the value of exchange
    * @returns the duplicate item for the string
    * @memberof ExchangeService
    */
    private generateDuplicateItemForString(name: string, displayName: string, tamaleValue: string, exchangeValue: string): DuplicateItem {
        const orderBy = this.getOrderByValueByDuplicateItemName(name);
        const tmlValue = new IntegratedValue(tamaleValue, tamaleValue, tamaleValue);
        const exgValue = new IntegratedValue(exchangeValue, exchangeValue, exchangeValue);
        const duplicateItem = new DuplicateItem(name, displayName, [tmlValue], [exgValue], orderBy, false, false, false, true);

        return duplicateItem;
    }

    /**
    * get display name which is used to show on UI
    *
    * @param propertyName: the name in model
    * @returns display name
    * @memberof ExchangeService
    */
    private _getDisplayNameShowOnUI(propertyName: string): string {
        let displayName = '';
        switch (propertyName) {
            case businessConstants.exchangeMerge.fields.firstName:
                displayName = businessConstants.exchangeMerge.valueProperty.firstName;
                break;
            case businessConstants.exchangeMerge.fields.lastName:
                displayName = businessConstants.exchangeMerge.valueProperty.lastName;
                break;
            case businessConstants.exchangeMerge.fields.jobTitle:
                displayName = businessConstants.exchangeMerge.valueProperty.jobTitle;
                break;
            case businessConstants.exchangeMerge.fields.company:
                displayName = businessConstants.exchangeMerge.valueProperty.company;
                break;
            case businessConstants.exchangeMerge.fields.alias:
                displayName = businessConstants.exchangeMerge.valueProperty.alias;
                break;
            case businessConstants.exchangeMerge.fields.birthday:
                displayName = businessConstants.exchangeMerge.valueProperty.birthday;
                break;
            case businessConstants.exchangeMerge.fields.email:
                displayName = businessConstants.exchangeMerge.valueProperty.email;
                break;
            case businessConstants.exchangeMerge.fields.webPhone:
                displayName = businessConstants.exchangeMerge.valueProperty.phone;
                break;
            case businessConstants.exchangeMerge.fields.webAddress:
                displayName = businessConstants.exchangeMerge.valueProperty.address;
                break;
            case businessConstants.exchangeMerge.fields.socialMedia:
                displayName = businessConstants.exchangeMerge.valueProperty.socialMedia;
                break;
            case businessConstants.exchangeMerge.fields.notes:
                displayName = businessConstants.exchangeMerge.valueProperty.notes;
                break;
            default:
                break;
        }
        return displayName;
    }

    /**
    * create order value for the duplicate item by name
    *
    * @param name: the name of the duplicate item
    * @returns the order of the dupliate item
    * @memberof ExchangeService
    */
    private getOrderByValueByDuplicateItemName(name: string): string {
        if (!name) {
            return '';
        }
        let orderBy = '';
        if (name === businessConstants.exchangeMerge.fields.firstName) {
            orderBy = 'a';
        } else if (name === businessConstants.exchangeMerge.fields.lastName) {
            orderBy = 'b';
        } else if (name === businessConstants.exchangeMerge.fields.jobTitle) {
            orderBy = 'c';
        } else if (name === businessConstants.exchangeMerge.fields.company) {
            orderBy = 'd';
        } else if (name === businessConstants.exchangeMerge.fields.alias) {
            orderBy = 'e';
        } else if (name === businessConstants.exchangeMerge.fields.birthday) {
            orderBy = 'f';
        } else if (name === businessConstants.exchangeMerge.fields.email) {
            orderBy = 'g';
        } else if (name === businessConstants.exchangeMerge.fields.webPhone) {
            orderBy = 'h';
        } else if (name === businessConstants.exchangeMerge.fields.webAddress) {
            orderBy = 'i';
        } else if (name === businessConstants.exchangeMerge.fields.socialMedia) {
            orderBy = 'j';
        } else if (name === businessConstants.exchangeMerge.fields.notes) {
            orderBy = 'k';
        }

        return orderBy;
    }

    /**
    * create value property for the duplicate item by name
    *
    * @param name: the name of the duplicate item
    * @returns the order of the dupliate item
    * @memberof ExchangeService
    */
    private getValuePropertyByDuplicateItemName(name: string): string {
        let valueProperty = '';
        if (name === businessConstants.exchangeMerge.fields.webPhone) {
            valueProperty = 'phone';
        } else if (name === businessConstants.exchangeMerge.fields.socialMedia) {
            valueProperty = 'link';
        } else if (name === businessConstants.exchangeMerge.fields.email ||
            name === businessConstants.exchangeMerge.fields.webAddress) {
            valueProperty = 'address';
        }
        return valueProperty;
    }
}
