/**
 * Created by Abner Sui on 09/26/2019.
 * Description:
 *
 * ------ maintenance history ------
 */


/**
 * The source of the slide-sheet related action.
 *
 * @export
 * @enum {number}
 */
export enum SlideSheetTriggerSource {
    /**
     * The task board
     */
    TASK_VIEW = 'TASK_VIEW',

    /**
     * The project board
     */
    PROJECT_VIEW = 'PROJECT_VIEW',
}


import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ProcessInstance, Task, ProcessDefinition, SubTask, OperationType, QuickTask, TaskDefinition } from '../tamalelibs/models/workflow.model';
import { SlideSheetActionTypes, SlideSheetEventTypes } from '../components/slide-sheet/slide-sheet.model';

@Injectable({
    providedIn: 'root'
})
export class SlideSheetService {

    slideSheetActionSubject$: Subject<{ type: SlideSheetActionTypes, payload?: any }> = new Subject();
    slideSheetFeedbackSubject$: Subject<{ type: SlideSheetEventTypes, payload?: any }> = new Subject();
    private _isBeingShown = false;

    get isBeingShown() {
        return this._isBeingShown;
    }

    set isBeingShown(value) {
        this._isBeingShown = value;
    }

    constructor(
    ) { }

    showSlideSheetForTask(event, task: Task, instance: ProcessInstance, processDefinition: ProcessDefinition, taskEditable: boolean, isTriggerByNewProject: boolean = false, src: SlideSheetTriggerSource = SlideSheetTriggerSource.TASK_VIEW) {
        this.slideSheetActionSubject$.next({
            type: SlideSheetActionTypes.TASK,
            payload: {
                task: task,
                instance: instance,
                processDefinition: processDefinition,
                slideSheetAutoClose: !taskEditable || !task.taskDefinition.fields.size, // TODO: The implementation has changed according to the requirements. will delete it after 21.4 release. Add by Daniel on 9/16/2012
                isTriggerByNewProject: isTriggerByNewProject,
                src: src
            },
        });
        if (event) {
            event['slideSheet'] = true;
        }
    }

    showSlideSheetForTaskDefinition(event, taskDefinition: TaskDefinition, actions$: Subject<any>, events$: Subject<any>, opType: string, isImport?: boolean) {
        this.slideSheetActionSubject$.next({
            type: SlideSheetActionTypes.TASK_DEFINITION,
            payload: {
                actionSubject$: actions$,
                feedbackSubject$: events$,
                taskDefinition: taskDefinition,
                opType: opType,
                isImport: isImport
            },
        });
        if (event) {
            event['slideSheet'] = true;
        }
    }

    showSlideSheetForTaskList(event) {
        this.slideSheetActionSubject$.next({
            type: SlideSheetActionTypes.TASK_LIST,
            payload: event.type
        });
        if (event) {
            event['slideSheet'] = true;
        }
    }

    showSlideSheetForQuickTask(event, operationType: OperationType, quickTask: QuickTask, subTask: SubTask, closeAction: { subject: Subject<any>, action: any }) {
        this.slideSheetActionSubject$.next({
            type: SlideSheetActionTypes.QUICK_TASK,
            payload: {
                operationType: operationType,
                quickTask: quickTask,
                subTask: subTask,
                closeAction: closeAction,
            }
        });
        if (event) {
            event['slideSheet'] = true;
        }
    }

    // show slide sheet for workflow trigger settings
    showSlideSheetForWorkflowTriggerSettings(event, feedbackSubject$: Subject<any>) {
        this.slideSheetActionSubject$.next({
            type: SlideSheetActionTypes.WORKFLOW_TRIGGER_SETTINGS,
            payload: {
                feedbackSubject$: feedbackSubject$
            },
        });
        if (event) {
            event['slideSheet'] = true;
        }
    }
}
