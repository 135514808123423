/**
 * Created by Abner Sui on 12/04/2019.
 * Description:
 *
 * ------ maintenance history ------
 * Add processDefinitionBrief by Ella on 09/10/2020
 * Updated by Daniel Wang on 9/9/2020 add current workflow fields in the store.
 * Updated by simon zhao on 11/18/2021 add new actions for update fields' name in store.
 * Updated by simon zhao on 1/28/2022 added a series of actions for all fields in a single workflow.
 * Updated by Daniel Wang on 9/18/2023 add automation node in the store to handle move automation node story.
 */
import { createSelector, combineReducers } from '@ngrx/store';
import { WorkflowActions, WorkflowActionTypes } from '../actions/workflow.actions';
import { FieldDefinition, ProcessDefinition, ProcessInstance, Stage, WorkflowConfig } from '../../models/workflow.model';
import { FieldBrief } from './../../models/workflow.model';
import { ArrayHelperService } from '../../services/array-helper.service';
import { AutomationModelBase } from '../../models/automation-base.model';

const SORT_BY_CONTROLNAME = 'controlName';
const SORT_BY_LABEL = 'label';
const SORT_BY_NAME = 'name';

export const workflowState = (state: any) => state.workflow;

export const workflowMapSelector = createSelector(workflowState, (state: any) => state.itemMap);
export const workflowBriefSelector = createSelector(workflowState, (state: any) => state.itemBrief);
export const currentWorkflowStageSelector = createSelector(workflowState, (state: any) => state.currentWorkflowStage);
export const currentWorkflowFieldsSelector = createSelector(workflowState, (state: any) => state.currentWorkflowFields);
export const currentWorkflowAllFieldsSelector = createSelector(workflowState, (state: any) => state.currentWorkflowAllFields);
export const workflowFieldsSelector = createSelector(workflowState, (state: any) => state.fields);
export const workflowUsedFieldsSelector = createSelector(workflowState, (state: any) => state.usedFields);
export const workflowKanbanFilterSelector = createSelector(workflowState, (state: any) => state.kanbanFilter);
export const workflowIdsSelector = createSelector(workflowState, (state: any) => state.ids); // TODO: May need to move to system level
export const workflowCurrentTasksSelector = createSelector(workflowState, (state: any) => state.currentWorkflowTasks);
export const workflowKanbanGridColumnStatusSelector = createSelector(workflowState, (state: any) => state.kanbanGridColumnStatus);
export const workflowAutomationSelector = createSelector(workflowState, (state: any) => state.automationNodes);

// tslint:disable-next-line: max-line-length
export function workflowMapReducer(state: Map<string, ProcessDefinition> = new Map(), action: WorkflowActions): Map<string, ProcessDefinition> {
    let newState: Map<string, ProcessDefinition>;
    let instance: ProcessInstance;
    let processDefinition: ProcessDefinition;
    let index: number;
    switch (action.type) {
        case WorkflowActionTypes.GET_ONE_PROCESS_DEFINITION_SUCCESS:
            processDefinition = action.payload;
            newState = new Map(state);
            newState.set(processDefinition.id, processDefinition);
            return newState;
        case WorkflowActionTypes.GET_ONE_PROCESS_DEFINITION_FAILED:
            return state;
        case WorkflowActionTypes.PROCESS_INSTANCE_ADD_OR_UPDATE_SUCCESS:
            instance = action.payload;
            processDefinition = state.get(instance.processDefinition.id);
            if (processDefinition) {
                index = processDefinition.processInstance.findIndex(item => item.id === instance.id);
                if (index > -1) {
                    processDefinition.processInstance.splice(index, 1, instance);
                } else {
                    processDefinition.processInstance.push(instance);
                }
                newState = new Map(state);
                newState.set(processDefinition.id, processDefinition);
                return newState;
            }
            return state;
        case WorkflowActionTypes.PROCESS_INSTANCE_DELETE_SUCCESS:
        case WorkflowActionTypes.PROCESS_INSTANCE_DELETE_FAILED:
            instance = action.payload.instance;
            if (instance) {
                processDefinition = state.get(instance.processDefinition.id);
                if (processDefinition) {
                    index = processDefinition.processInstance.findIndex(item => item.id === instance.id);
                    if (index > -1) {
                        processDefinition.processInstance.splice(index, 1);
                        newState = new Map(state);
                        newState.set(processDefinition.id, processDefinition);
                        return newState;
                    }
                }
            }
            return state;
        case WorkflowActionTypes.CLEAR_ONE_PROCESS_DEFINITION:
            newState = new Map(state);
            if (newState.has(action.payload)) {
                newState.delete(action.payload);
            }
            return newState;
        default:
            return state;
    }
}

export function workflowBriefReducer(state: WorkflowConfig, action: WorkflowActions) {
    switch (action.type) {
        case WorkflowActionTypes.CREATE_WORKFLOW_BRIEF:
            return action.payload;
        case WorkflowActionTypes.CLEAR_WORKFLOW_BRIEF:
            return null;
        default:
            return state;
    }
}

export function workflowKanbanFilterReducer(state = new Map(), action: WorkflowActions) {
    switch (action.type) {
        case WorkflowActionTypes.SET_WORKFLOW_KANBAN_FILTER:
            state.set(action.payload.key, action.payload.value);
            return state;
        case WorkflowActionTypes.CLEAR_WORKFLOW_KANBAN_FILTER:
            state.clear();
            return state;
        default:
            return state;
    }
}

// used to save the grid column status
export function workflowKanbanGridColumnStatusReducer(state = new Map(), action: WorkflowActions) {
    switch (action.type) {
        case WorkflowActionTypes.SET_WORKFLOW_KANBAN_GRID_COLUMN_STATUS:
            state.set(action.payload.key, action.payload.value);
            return state;
        case WorkflowActionTypes.CLEAR_WORKFLOW_KANBAN_GRID_COLUMN_STATUS:
            state.clear();
            return state;
        default:
            return state;
    }
}

export function currentWorkflowFieldsReducer(state: Array<FieldBrief> = [], action: WorkflowActions) {
    switch (action.type) {
        case WorkflowActionTypes.SET_CURRENT_WORKFLOW_FIELDS:
            const fields = action.payload;
            ArrayHelperService.sort(fields, SORT_BY_CONTROLNAME);
            return fields;
        case WorkflowActionTypes.UPDATE_CURRENT_WORKFLOW_FIELDS:
            const taskDefinitionId = action.payload.taskDefinitionId;
            const changedFields = action.payload.changedFields;
            const currentFields = Object.assign([], state);

            const newCurrentFields = currentFields.filter(item => item.taskDefinitionId !== taskDefinitionId);
            changedFields.forEach(field => {
                newCurrentFields.push(field);
            });
            ArrayHelperService.sort(newCurrentFields, SORT_BY_CONTROLNAME);
            return newCurrentFields;
        case WorkflowActionTypes.CLEAR_CURRENT_WORKFLOW_FIELDS:
            return [];
        default:
            return state;
    }
}

export function currentWorkflowAllFieldsReducer(state: Array<{ taskDefinitionId: string, label: string }> = [], action: WorkflowActions) {
    switch (action.type) {
        case WorkflowActionTypes.SET_CURRENT_WORKFLOW_ALL_FIELDS:
            const fields = action.payload;
            ArrayHelperService.sort(fields, SORT_BY_LABEL);
            return fields;
        case WorkflowActionTypes.UPDATE_CURRENT_WORKFLOW_ALL_FIELDS:
            const taskDefinitionId = action.payload.taskDefinitionId;
            const changedFields = action.payload.changedFields;
            const currentFields = Object.assign([], state);

            const newCurrentFields = currentFields.filter(item => item.taskDefinitionId !== taskDefinitionId);
            changedFields.forEach(field => {
                if (!newCurrentFields.some(item => item.fieldDefinitionId === field.fieldDefinitionId)) {
                    newCurrentFields.push(field);
                }
            });
            if (newCurrentFields && newCurrentFields.length > 0) {
                ArrayHelperService.sort(newCurrentFields, SORT_BY_LABEL);
            }
            return newCurrentFields;
        case WorkflowActionTypes.DELETE_CURRENT_WORKFLOW_ONE_FIELD:
            const savedFields = Object.assign([], state);
            const deletedFieldDefinitionId = action.payload.fieldDefinitionId;
            const newFields = savedFields.filter(item => item.fieldDefinitionId !== deletedFieldDefinitionId);
            return newFields;
        case WorkflowActionTypes.CLEAR_CURRENT_WORKFLOW_FIELDS:
            return [];
        default:
            return state;
    }
}

export function currentWorkflowStageReducer(state: Array<Stage> = [], action: WorkflowActions) {
    let newState;
    switch (action.type) {
        case WorkflowActionTypes.ADD_STAGE:
            newState = Object.assign([], state);
            newState.push(action.payload);
            ArrayHelperService.sort(newState, SORT_BY_NAME);
            return newState;
        case WorkflowActionTypes.DELETE_STAGE_SUCCESS:
            const index = state.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                newState = Object.assign([], state);
                newState.splice(index, 1);
                return newState;
            } else {
                return state;
            }
        case WorkflowActionTypes.SET_STAGE:
            return action.payload;
        case WorkflowActionTypes.CLEAR_STAGE:
            return [];
        default:
            return state;
    }
}

export function currentWorkflowTasksReducer(state: Array<any>, action: WorkflowActions) {
    let newState;
    switch (action.type) {
        case WorkflowActionTypes.GET_CURRENT_WORKFLOW_TASKS:
            newState = action.payload;
            ArrayHelperService.sort(newState, SORT_BY_CONTROLNAME);
            return newState;
        case WorkflowActionTypes.UPDATE_CURRENT_WORKFLOW_TASK:
            newState = Object.assign([], state);
            const taskInfo = action.payload;
            let updated = false;
            if (taskInfo.name) {
                newState.forEach(item => {
                    if (item.id === taskInfo.id) {
                        item.name = taskInfo.name;
                        updated = true;
                    }
                });
            } else {
                const _index = newState.findIndex(item => item.id === taskInfo.id);
                if (_index > -1) {
                    newState.splice(_index, 1);
                    updated = true;
                }
            }
            if (!updated) {
                newState.push(taskInfo);
                ArrayHelperService.sort(newState, SORT_BY_NAME);
            }
            return newState;
        case WorkflowActionTypes.ADD_CURRENT_WORKFLOW_TASK:
            newState = Object.assign([], state);
            newState.push(...action.payload);
            ArrayHelperService.sort(newState, SORT_BY_NAME);
            return newState;
        case WorkflowActionTypes.DELETE_CURRENT_WORKFLOW_TASK:
            const index = state.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                newState = Object.assign([], state);
                newState.splice(index, 1);
                return newState;
            } else {
                return state;
            }
        case WorkflowActionTypes.CLEAR_CURRENT_WORKFLOW_TASK:
            return [];
        default:
            return state;
    }
}

export function workflowFieldsReducer(state: Array<FieldDefinition>, action: WorkflowActions) {
    let newState: Array<FieldDefinition>;
    switch (action.type) {
        case WorkflowActionTypes.GET_ALL_FIELDS_SUCCESS:
            newState = action.payload;
            ArrayHelperService.sort(newState, SORT_BY_NAME);
            return newState;
        case WorkflowActionTypes.ADD_ONE_FIELD:
            newState = Object.assign([], state);
            newState.push(...action.payload);
            ArrayHelperService.sort(newState, SORT_BY_NAME);
            return newState;
        case WorkflowActionTypes.UPDATE_MULTIPLE_FIELDS:
            newState = Object.assign([], state);
            if (action.payload && Array.isArray(action.payload) && action.payload.length > 0) {
                action.payload.forEach(f => {
                    const oldFieldIndex = newState.findIndex(sf => sf.id === f.id);
                    if (oldFieldIndex >= 0) {
                        // for less impact, just update name for this action.
                        newState[oldFieldIndex].name = f.name;
                    }
                });
            }
            ArrayHelperService.sort(newState, SORT_BY_NAME);
            return newState;
        default:
            return state;
    }
}

export function workflowIdsReducer(state: Array<string> = [], action: WorkflowActions) {
    switch (action.type) {
        case WorkflowActionTypes.GET_IDS_SUCCESS:
            let newState = Object.assign([], state);
            newState.push(...action.payload);
            return newState;
        case WorkflowActionTypes.GET_IDS_FAILED:
            return [];
        case WorkflowActionTypes.DELETE_ONE_ID:
            let myNewState = state.slice();
            myNewState.shift();
            return myNewState;
        case WorkflowActionTypes.CLEAR_IDS:
            return [];
        default:
            return state;
    }
}

export function workflowUsedFieldsReducer(state: Map<string, FieldDefinition> = new Map(), action: WorkflowActions) {
    let newState: Map<string, FieldDefinition>;
    switch (action.type) {
        case WorkflowActionTypes.USE_ONE_FIELD:
            newState = new Map(state);
            newState.set(action.payload.fieldId, action.payload.fieldDefinition);
            return newState;
        case WorkflowActionTypes.UNUSE_ONE_FIELD:
            newState = new Map(state);
            newState.delete(action.payload.fieldId);
            return newState;
        case WorkflowActionTypes.SET_USED_FIELD:
            return action.payload;
        case WorkflowActionTypes.CLEAR_USED_FIELD:
            return new Map();
        default:
            return state;
    }
}

export function workflowAutomationNodesReducer(state: Array<AutomationModelBase>, action: WorkflowActions) {
    let newState;
    switch (action.type) {
        // get automation node from store.
        case WorkflowActionTypes.GET_AUTOMATION_NODE:
            newState = action.payload;
            ArrayHelperService.sort(newState, SORT_BY_NAME);
            return newState;
        // add automation node to store.
        case WorkflowActionTypes.ADD_AUTOMATION_NODE:
            newState = Object.assign([], state);
            newState.push(...action.payload);
            ArrayHelperService.sort(newState, SORT_BY_NAME);
            return newState;
        // update automation node in store.
        case WorkflowActionTypes.UPDATE_AUTOMATION_NODE:
            newState = Object.assign([], state);
            const indexExistItem = state.findIndex(item => item.id === action.payload.id);
            if (indexExistItem > 0) {
                newState.splice(indexExistItem, 1);
                newState.push(action.payload);
                ArrayHelperService.sort(newState, SORT_BY_NAME);
                return newState;
            } else {
                return state;
            }
        // delete automation node in store.
        case WorkflowActionTypes.DELETE_AUTOMATION_NODE:
            const index = state.findIndex(item => item.id === action.payload);
            if (index > -1) {
                newState = Object.assign([], state);
                newState.splice(index, 1);
                return newState;
            } else {
                return state;
            }
        // remove all automation node in store.
        case WorkflowActionTypes.CLEAR_AUTOMATION_NODE:
            return [];
        default:
            return state;
    }
}

const workflowReducer = combineReducers({
    itemBrief: workflowBriefReducer,
    itemMap: workflowMapReducer,
    currentWorkflowStage: currentWorkflowStageReducer,
    currentWorkflowFields: currentWorkflowFieldsReducer,
    currentWorkflowAllFields: currentWorkflowAllFieldsReducer,
    fields: workflowFieldsReducer,
    usedFields: workflowUsedFieldsReducer,
    kanbanFilter: workflowKanbanFilterReducer,
    ids: workflowIdsReducer,
    currentWorkflowTasks: currentWorkflowTasksReducer,
    kanbanGridColumnStatus: workflowKanbanGridColumnStatusReducer,
    automationNodes: workflowAutomationNodesReducer
});

export function getWorkflowReducer(state, action) {
    return workflowReducer(state, action);
}

