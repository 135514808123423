/**
 * 12/09/2020 Yoyo redirection service for user using iphone accessing web
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformRedirectionService {
  constructor(
  ) { }

  redirectIfOnMobile() {
    const userAgent = window.navigator.userAgent;
    const path = window.location.pathname;
    const iphoneReg = /iPhone OS/i;
    const entryLinkReg = /\/entry\/(\w{32})/;
    if (iphoneReg.test(userAgent) && entryLinkReg.test(path)) {
      const id = entryLinkReg.exec(path)[1];
      window.open('tamale://viewthread/?id=' + id);
      return true;
    } else {
      return false;
    }
  }
}
