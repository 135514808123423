/**
 * Create by Alex 8/10/2020
 */

import { Observable } from 'rxjs';
import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';
import { Injectable } from '@angular/core';

@Injectable()
export class AutoTaggingService {
    constructor(
        private _transportService: TransportService,
    ) { }

    getAutoTagModels(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.autoTagModelEndPoint}`;
        const params = {
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    editAutoTagSettings(enable: boolean, model: string, notetypes: string, confidence: number): Observable<any> {
        const url = `${AppConfig.autoTagSettingsEndPoint}`;
        const formData: FormData = new FormData();
        if (enable !== null) {
            formData.append('enable', String(enable));
        }
        if (model) {
            formData.append('model', model);
        }
        if (notetypes) {
            formData.append('notetypes', notetypes);
        }
        if (confidence >= 0.2 && confidence <= 1.0) {
            formData.append('confidence', String(confidence));
        }

        return this._transportService.put(url, formData, {});
    }

    createAutoTagModel(modelName: string, notetypes: string, number: number): Observable<string> {
        const url = `${AppConfig.createAutoTagModelEndPoint}`;

        const formData: FormData = new FormData();
        if (modelName !== null) {
            formData.append('name', modelName);
        }
        if (notetypes) {
            formData.append('ids', notetypes);
        }
        if (notetypes) {
            formData.append('number-per-notetype', String(number));
        }

        const options = {
            responseType: 'text'
        };

        return this._transportService.post(url, formData, options);
    }

    editAutoTagStatus(enable: boolean): Observable<any> {
        const url = `${AppConfig.autoTagChangeStatusEndPoint}`;
        const formData: FormData = new FormData();
        if (enable !== null) {
            formData.append('enable', String(enable));
        }

        return this._transportService.put(url, formData, {});
    }

    editAutoTagStatusForLiveWire(enableForLiveWire: boolean): Observable<any> {
        const url = `${AppConfig.autoTagChangeStatusEndPoint}`;
        const formData: FormData = new FormData();
        if (enableForLiveWire !== null) {
            formData.append('enable-for-livewire', String(enableForLiveWire));
        }

        return this._transportService.put(url, formData, {});
    }
}
