import { AttachmentView } from '../attachment-list/attachment-view.model';

export class PopoverConfig {
    showPopover: boolean;
    items: Array<AttachmentView>;
    el: any;

    constructor() {
        this.showPopover = false;
        this.items = [];
        this.el = { x: 0, y: 0 };
    }
}
