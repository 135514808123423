import { ContactTemplateOpenOptions } from '../components/contact-dialog-new/contact-dialog.model';
import { EntityDialogOpenOptions } from '../components/entity-dialog/entity-dialog.model';
import { User } from '../tamalelibs/models/user.model';

/**
 * Added by Alex Xia on 4/21/2022.
 * Description:
 *
 * ------ maintenance history ------
 */
export enum AddInType {
    OUTLOOK = 'Outlook',
    WORD = 'Word',
    EXCEL = 'Excel',
    POWERPOINT = 'PowerPoint',
    ONENOTE = 'OneNote'
}

export enum AddInDepositType {
    ADDINALL = 'AddInAll',
    ADDINONLYEMAIL = 'AddInOnlyEmail',
    ADDINONLYFILE = 'AddInOnlyFile',
}

export enum AddInDialogType {
    ENTITYDIALOG = 'EntityDialog',
    CONTACTDIALOG = 'ContactDialog',
}

export class AddInGlobalModel {
    static currentAddInDepositType: AddInDepositType;
}

export class AddInOnTheFlyModel {
    user: User;
    isShowEntityOnTheFly: boolean;
    contactDialogOpenOptions: ContactTemplateOpenOptions;
    entityDialogOpenOptions: EntityDialogOpenOptions;
}

