/**
 * Created by Todd Yu on 05/20/2020.
 * Description:
 * Admin Page Settings Constants
 * ------ maintenance history ------
 * modified by Teddy Ding on 12/15/20
 * modified by Lucas Wang on 08/19/21
 * Modified by Cathy Yao on 08/27/21 added a columnDef in page of Entry/Entities
 * Modified by Cathy Yao on 22/9/2021:deleted System Credentials page
 * Modified by Marcus Zhao add  12/10/2022 email setting
 * Updated by Daniel Wang on 1/14/2023 street events configuration
 */

import { Subject } from 'rxjs';

import { AdminPageSetting, IAdminSharedPageSetting } from './admin-page-setting.model';
import { UsersEditorComponent } from './admin-page-content/admin-page-editors/users-editor/users-editor.component';
import { NoteTypesEditorComponent } from './admin-page-content/admin-page-editors/note-types-editor/note-types-editor.component';
import { EntityTypesEditorComponent } from './admin-page-content/admin-page-editors/entity-types-editor/entity-types-editor.component';
import { RelationshipsTypesEditorComponent } from './admin-page-content/admin-page-editors/relationships-types-editor/relationships-types-editor.component';
import { NotificationEditorComponent } from './admin-page-content/admin-page-editors/notification-editor/notification-editor.component';
import { PortfolioUploadEditorComponent } from './admin-page-content/admin-page-editors/portfolio-upload-editor/portfolio-upload-editor.component';
import { AdminPageActionsComponent } from './admin-page-actions/admin-page-actions.component';
import { SlideSheetActionTypes } from '../slide-sheet/slide-sheet.model';
import { AdminPageButtonComponent } from './admin-page-actions/admin-page-button.component';

/** Main Admin Page Constants */
export const adminPagesConstants = {
    general: {
        id: 'General settings',
        routingUrl: 'general',
        searchString: 'general',
        group: 'GENERAL',
        index: 1
    } as AdminPageSetting,

    users: {
        id: 'Users',
        routingUrl: 'users',
        searchString: 'users',
        additionalActions: {
            create: {
                buttonName: 'Create user'
            }
        },
        editor: {
            component: UsersEditorComponent,
            actionSubject$: new Subject(),
            feedbackSubject$: new Subject()
        },
        feedbackSubject$: new Subject(),
        group: 'TAMALE MODULES',
        index: 1
    } as AdminPageSetting,

    noteTypes: {
        id: 'Entry types',
        routingUrl: 'entry-types',
        searchString: 'entry types',
        description: 'The following table lists the mappings between entry types and file extensions. Editing an entry type will maintain any mappings. Deleting an entry type with file extension mappings will cause new entries having attached files with the associated extensions to default to type TamaleEntry.',
        editor: {
            component: NoteTypesEditorComponent,
            actionSubject$: new Subject(),
            feedbackSubject$: new Subject()
        },
        additionalActions: {
            create: {
                buttonName: 'Create new entry type'
            }
        },
        feedbackSubject$: new Subject(),
        actionSubject$: new Subject(),
        group: 'TAMALE MODULES',
        index: 2
    } as AdminPageSetting,

    entityTypes: {
        id: 'Entity types',
        routingUrl: 'entity-types',
        searchString: 'entity types',
        additionalActions: {
            create: {
                buttonName: 'Create new entity type'
            }
        },
        editor: {
            component: EntityTypesEditorComponent,
            actionSubject$: new Subject(),
            feedbackSubject$: new Subject()
        },
        feedbackSubject$: new Subject(),
        group: 'TAMALE MODULES',
        index: 3
    } as AdminPageSetting,

    notifications: {
        id: 'Notification and subscription',
        routingUrl: 'notifications',
        searchString: 'notifications',
        editor: {
            component: NotificationEditorComponent,
            actionSubject$: new Subject(),
            feedbackSubject$: new Subject()
        },
        additionalActions: {
            create: {
                buttonName: 'Create new notification rule'
            }
        },
        feedbackSubject$: new Subject(),
        actionSubject$: new Subject(),
        group: 'INTEGRATION CONFIG',
        index: 4
    } as AdminPageSetting,

    relationships: {
        id: 'Relationships',
        routingUrl: 'relationships',
        searchString: 'relationships',
        editor: {
            component: RelationshipsTypesEditorComponent,
            actionSubject$: new Subject(),
            feedbackSubject$: new Subject()
        },
        additionalActions: {
            create: {
                buttonName: 'Create relationship'
            }
        },
        feedbackSubject$: new Subject(),
        group: 'TAMALE MODULES',
        index: 4
    } as AdminPageSetting,

    autoTag: {
        id: 'Auto-tagging',
        routingUrl: 'auto-tag',
        searchString: 'auto tagging settings',
        feedbackSubject$: new Subject(),
        group: 'TAMALE MODULES',
        index: 6
    } as AdminPageSetting,

    exchange: {
        id: 'Exchange server',
        routingUrl: 'exchange',
        searchString: 'exchange',
        group: 'INTEGRATION CONFIG',
        index: 1
    } as AdminPageSetting,

    intralinks: {
        id: 'Intralinks',
        routingUrl: 'intralinks',
        searchString: 'Intralinks',
        group: 'INTEGRATION CONFIG',
        index: 2
    } as AdminPageSetting,

    googleMapService: {
        id: 'Google map service',
        routingUrl: 'google-map-service',
        searchString: 'Google Map',
        feedbackSubject$: new Subject(),
        group: 'INTEGRATION CONFIG',
        index: 3
    },

    workflow: {
        id: 'Workflow',
        routingUrl: 'workflow',
        searchString: 'workflow',
        group: 'TAMALE MODULES',
        index: 5
    },

    templateSettings: {
        id: 'Templates',
        routingUrl: 'template-settings',
        searchString: 'templateSettings',
        group: 'TAMALE MODULES',
        index: 7
    } as AdminPageSetting,

    systemConfig: {
        id: 'System config',
        routingUrl: 'system-config',
        searchString: 'systemConfig',
        group: 'GENERAL',
        index: 2
    } as AdminPageSetting,

    centralConfig: {
        id: 'Central config',
        routingUrl: 'central-config',
        searchString: 'centralConfig',
        group: 'GENERAL',
        index: 3
    } as AdminPageSetting,

    portfolioUpload: {
        id: 'Portfolio upload',
        routingUrl: 'portfolio-upload',
        searchString: 'portfolioUpload',
        editor: {
            component: PortfolioUploadEditorComponent,
            actionSubject$: new Subject(),
            feedbackSubject$: new Subject()
        },
        additionalActions: {
            create: {
                buttonName: 'Create new portfolio upload configuration'
            }
        },
        feedbackSubject$: new Subject(),
        actionSubject$: new Subject(),
        group: 'INTEGRATION CONFIG',
        index: 5
    } as AdminPageSetting,

    'se-configuration': {
        id: 'Street event config',
        routingUrl: 'se-configuration',
        searchString: 'streetEventsConfiguration',
        feedbackSubject$: new Subject(),
        group: 'INTERFACE CONFIG',
        index: 1
    } as AdminPageSetting,

    'etl-scheduled-jobs': {
        id: 'ETL scheduled jobs',
        routingUrl: 'etl-scheduled-jobs',
        searchString: 'etlScheduledJobs',
        feedbackSubject$: new Subject(),
        group: 'INTERFACE CONFIG',
        index: 2
    } as AdminPageSetting,

    'etl-tasks': {
        id: 'ETL tasks',
        routingUrl: 'etl-tasks',
        searchString: 'etlTasks',
        feedbackSubject$: new Subject(),
        group: 'INTERFACE CONFIG',
        index: 3
    } as AdminPageSetting,

    'local-python-scripts': {
        id: 'Local python scripts',
        routingUrl: 'local-python-scripts',
        searchString: 'localPythonScripts',
        feedbackSubject$: new Subject(),
        group: 'INTERFACE CONFIG',
        index: 4
    } as AdminPageSetting,

    'token-management': {
        id: 'Token management',
        routingUrl: 'token-management',
        searchString: 'tokenManagement',
        feedbackSubject$: new Subject(),
        group: 'INTERFACE CONFIG',
        index: 5
    } as AdminPageSetting,

    'store-cache-to-file': {
        id: 'Cache store to file',
        routingUrl: 'store-cache-to-file',
        searchString: 'storeCacheToFile',
        feedbackSubject$: new Subject(),
        group: 'SERVER ADMIN',
        index: 2
    } as AdminPageSetting,

    'persistent-cache': {
        id: 'Persistent cache',
        routingUrl: 'persistent-cache',
        searchString: 'persistentCachePurge',
        feedbackSubject$: new Subject(),
        group: 'SERVER ADMIN',
        index: 3
    } as AdminPageSetting,

    'clamav-file-lookup': {
        id: 'ClamAV file lookup',
        routingUrl: 'clamav-file-lookup',
        searchString: 'clamavFileLookup',
        feedbackSubject$: new Subject(),
        group: 'SERVER ADMIN',
        index: 4
    } as AdminPageSetting,

    'web-sites': {
        id: 'Web sites',
        routingUrl: 'web-sites',
        searchString: 'webSites',
        feedbackSubject$: new Subject(),
        group: 'INTEGRATION CONFIG',
        index: 6
    } as AdminPageSetting,

    'server-monitoring': {
        id: 'Server monitoring',
        routingUrl: 'server-monitoring',
        searchString: 'serverMonitoring',
        feedbackSubject$: new Subject(),
        group: 'GENERAL',
        index: 4
    } as AdminPageSetting,

    email: {
        id: 'Email server config',
        routingUrl: 'email-settings',
        searchString: 'email',
        group: 'SERVER ADMIN',
        index: 1
    } as AdminPageSetting,
};

export const adminPageFeatures = ['general', 'users', 'noteTypes', 'entityTypes', 'relationships', 'notifications', 'autoTag', 'exchange', 'email', 'intralinks', 'googleMapService', 'workflow', 'templateSettings', 'systemConfig', 'portfolioUpload', 'centralConfig', 'se-configuration', 'etl-scheduled-jobs', 'etl-tasks', 'local-python-scripts', 'token-management', 'store-cache-to-file', 'persistent-cache', 'clamav-file-lookup', 'web-sites', 'server-monitoring'];

/** Map each page's route to the page constant for convenient route handling. */
export const adminPageRoutingMap = new Map<string, AdminPageSetting>([
    [adminPagesConstants.noteTypes.routingUrl, adminPagesConstants.noteTypes],
    [adminPagesConstants.entityTypes.routingUrl, adminPagesConstants.entityTypes],
    [adminPagesConstants.relationships.routingUrl, adminPagesConstants.relationships],
    [adminPagesConstants.users.routingUrl, adminPagesConstants.users],
    [adminPagesConstants.autoTag.routingUrl, adminPagesConstants.autoTag],
    [adminPagesConstants.intralinks.routingUrl, adminPagesConstants.intralinks],
    [adminPagesConstants.exchange.routingUrl, adminPagesConstants.exchange],
    [adminPagesConstants.email.routingUrl, adminPagesConstants.email],
    [adminPagesConstants.googleMapService.routingUrl, adminPagesConstants.googleMapService],
    [adminPagesConstants.workflow.routingUrl, adminPagesConstants.workflow],
    [adminPagesConstants.templateSettings.routingUrl, adminPagesConstants.templateSettings],
    [adminPagesConstants.general.routingUrl, adminPagesConstants.general],
    [adminPagesConstants.systemConfig.routingUrl, adminPagesConstants.systemConfig],
    [adminPagesConstants.centralConfig.routingUrl, adminPagesConstants.centralConfig],
    [adminPagesConstants.portfolioUpload.routingUrl, adminPagesConstants.portfolioUpload],
    [adminPagesConstants['se-configuration'].routingUrl, adminPagesConstants['se-configuration']],
    [adminPagesConstants['etl-scheduled-jobs'].routingUrl, adminPagesConstants['etl-scheduled-jobs']],
    [adminPagesConstants['etl-tasks'].routingUrl, adminPagesConstants['etl-tasks']],
    [adminPagesConstants['local-python-scripts'].routingUrl, adminPagesConstants['local-python-scripts']],
    [adminPagesConstants['token-management'].routingUrl, adminPagesConstants['token-management']],
    [adminPagesConstants['store-cache-to-file'].routingUrl, adminPagesConstants['store-cache-to-file']],
    [adminPagesConstants['persistent-cache'].routingUrl, adminPagesConstants['persistent-cache']],
    [adminPagesConstants['clamav-file-lookup'].routingUrl, adminPagesConstants['clamav-file-lookup']],
    [adminPagesConstants['web-sites'].routingUrl, adminPagesConstants['web-sites']],
    [adminPagesConstants['server-monitoring'].routingUrl, adminPagesConstants['server-monitoring']],

]);

export const adminSharedPageConstants = {
    'se-configuration': {
        id: 'se-configuration',
        name: 'Street Events Configuration',
        btnTitle: 'Run Street Events Adapter Now',
        type: 'streetEvents',
        columnHeader: {
            username: 'Username',
            password: 'Password',
            server: 'Server',
            path: 'Path',
            filename: 'Filename(Zipped)',
        },
        extraColumn: [{
            headerName: 'Edit/Delete',
            minWidth: 100,
            width: 100,
            maxWidth: 100,
            cellRenderer: AdminPageActionsComponent,
            cellRendererParams: { feedbackSubject$: () => adminPagesConstants['se-configuration'].feedbackSubject$ },
            sortable: false,
            suppressMovable: true,
            cellStyle: { 'text-align': 'center' },
        }],
        slideSheet: SlideSheetActionTypes.ADMIN_CONSOLE_STREET_EVENT_CONFIG // the slide sheet triggered by grid page
    } as IAdminSharedPageSetting,
    'etl-scheduled-jobs': {
        id: 'etl-scheduled-jobs', // A unique identifier, which is not used to initialize the Grid
        name: 'ETL Scheduled Jobs',
        btnTitle: 'Add new scheduled job', // The name of the button in the top right corner of the Grid page
        type: 'scheduledJobs', // endpoing of the corresponding server
        notes: '*Note: These are default jobs distributed with the system; they can not be edited or deleted.',
        columnHeader: { // Column information displayed on the grid
            name: 'Schedule Name',
            taskName: 'ETL Task Name',
            scheduleTime: 'Schedule Time',
            freq: 'Freq'
        },
        extraColumn: [{ // extra columns displayed on the grid
            headerName: 'Run',
            minWidth: 100,
            width: 100,
            maxWidth: 100,
            cellRenderer: AdminPageButtonComponent,
            cellRendererParams: { feedbackSubject$: () => adminPagesConstants['etl-scheduled-jobs'].feedbackSubject$ },
            sortable: false,
            suppressMovable: true,
            cellStyle: {
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis',
                'display': 'block',
                'border': 'none'
            },
        }, {
            headerName: 'Edit/Delete',
            minWidth: 105,
            width: 105,
            maxWidth: 105,
            cellRenderer: AdminPageActionsComponent,
            cellRendererParams: { feedbackSubject$: () => adminPagesConstants['etl-scheduled-jobs'].feedbackSubject$ },
            sortable: false,
            suppressMovable: true,
            cellStyle: { 'text-align': 'center' },
        }],
        slideSheet: SlideSheetActionTypes.ADMIN_CONSOLE_ETL_SCHEDULED_JOBS_EDITOR // the slide sheet triggered by grid page
    } as IAdminSharedPageSetting,
    'etl-tasks': {
        id: 'etl-tasks',
        name: 'ETL Tasks',
        btnTitle: 'New ETL Task',
        type: 'etlTask',
        notes: '*Note: These are default tasks distributed with the system; they can not be edited or deleted.',
        columnHeader: {
            name: 'ETL Task Name',
            className: 'ETL Operation Type'
        },
        extraColumn: [{
            headerName: 'Edit/Delete',
            minWidth: 105,
            width: 105,
            maxWidth: 105,
            cellRenderer: AdminPageActionsComponent,
            cellRendererParams: { feedbackSubject$: () => adminPagesConstants['etl-tasks'].feedbackSubject$ },
            sortable: false,
            suppressMovable: true,
            cellStyle: { 'text-align': 'center' },
        }],
        slideSheet: SlideSheetActionTypes.ADMIN_CONSOLE_ETL_TASKS_EDITOR // the slide sheet triggered by grid page
    } as IAdminSharedPageSetting,
    'local-python-scripts': {
        id: 'local-python-scripts',
        name: 'Local Python Scripts',
        btnTitle: 'Create a New Local Python Script',
        type: 'pythonScripts',
        notes: '*These scripts are propagated to each client connected to the server. Server scripts are stored directly in /home/tsdata/scripts/python.',
        columnHeader: {
            name: 'Client Python Scripts'
        },
        extraColumn: [{
            headerName: 'Edit/Delete',
            minWidth: 105,
            width: 105,
            maxWidth: 105,
            cellRenderer: AdminPageActionsComponent,
            cellRendererParams: { feedbackSubject$: () => adminPagesConstants['local-python-scripts'].feedbackSubject$ },
            sortable: false,
            suppressMovable: true,
            cellStyle: { 'text-align': 'center' },
        }],
        slideSheet: SlideSheetActionTypes.ADMIN_CONSOLE_LOCAL_PYTHON_SCRIPT_EDITOR // the slide sheet triggered by grid page
    } as IAdminSharedPageSetting,
    'token-management': {
        id: 'token-management',
        name: 'Token Management',
        isHiddenButton: true,
        btnTitle: '',
        type: 'tokenManagement',
        columnHeader: {
            username: 'Username',
            type: 'Type',
            device: 'Device',
            created: 'Created',
            lastUpdated: 'Last Updated'
        },
        extraColumn: [{
            headerName: 'Delete',
            minWidth: 105,
            width: 105,
            maxWidth: 105,
            cellRenderer: AdminPageActionsComponent,
            cellRendererParams: { feedbackSubject$: () => adminPagesConstants['token-management'].feedbackSubject$ },
            sortable: false,
            suppressMovable: true,
            cellStyle: { 'text-align': 'center' },
        }],
    } as IAdminSharedPageSetting,
    'web-sites': {
        id: 'web-sites',
        name: 'Web Sites',
        btnTitle: 'New web site',
        type: 'website',
        columnHeader: {
            name: 'Name',
            URL: 'URL',
            type: 'Type',
        },
        extraColumn: [{
            headerName: 'Edit/Delete',
            minWidth: 105,
            width: 105,
            maxWidth: 105,
            cellRenderer: AdminPageActionsComponent,
            cellRendererParams: { feedbackSubject$: () => adminPagesConstants['web-sites'].feedbackSubject$ },
            sortable: false,
            suppressMovable: true,
            menuTabs: [],
            cellStyle: { 'text-align': 'center' },
        }],
        slideSheet: SlideSheetActionTypes.ADMIN_CONSOLE_WEB_SITES_EDITOR // the slide sheet triggered by grid page
    } as IAdminSharedPageSetting
};
