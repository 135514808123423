/**
 * Created by Alex on 5/11/2022.
 * Description:
 *
 * ------ maintenance history ------
 * 08/17/2022 Alex add logic to support word add-in.
 * 09/24/2022 Marcus Zhao support show appointment.
 */

import { Injectable, NgZone } from '@angular/core';
import { OfficeAddinService } from '../../services/office-addin-service';
import { AddInUtilities } from '../../services/utilities/addin-utilities';
import { AddInType } from '../../models/addin.model';
import { OfficeAddIn, OfficeAttachment } from '../../tamalelibs/models/office-addin.model';
import { NoteDialogOpenOptions, NoteDialogType } from '../../components/note-dialog/note-dialog.model';
import { NoteDialogService } from '../../components/note-dialog/note-dialog.service';
import { businessConstants } from '../../constants/business.constants';
import { ContactDialogService } from '../../components/contact-dialog/contact-dialog.service';
import { CalendarDialogService } from '../../components/calendar-dialog/calendar-dialog.service';
import { CalendarDialogOpenOptions, CalendarDialogType } from '../../components/calendar-dialog/calendar-dialog.model';

@Injectable({
    providedIn: 'root',
})
export class HomePluginService {
    officeAddIn: OfficeAddIn;
    constructor(
        private _calendarDialogService: CalendarDialogService,
        private _noteDialogService: NoteDialogService,
        private _officeAddinService: OfficeAddinService,
        private _zone: NgZone,
    ) { }

    /**
     * get value from office and use them as parameters to open new note dialog
     */
    OpenNewNotePageForOfficeAddIn(isPinModeOfAddIn: boolean = false) {
        const self = this;

        if (AddInUtilities.isOfficeEnvironment()) {
            switch (Office.context.host.toString()) {
                case AddInType.OUTLOOK:
                    const officeMailboxItem = Office.context.mailbox.item;
                    if (officeMailboxItem) {
                        self.officeAddIn = new OfficeAddIn();

                        self.officeAddIn.itemType = officeMailboxItem.itemType;
                        self.officeAddIn.addInType = AddInType.OUTLOOK;
                        const startTime = new Date();
                        console.log('home-plugin get email data start: ' + startTime);
                        self.officeAddIn.isPinMode = isPinModeOfAddIn;
                        self.officeAddIn.subject = self._handleSubject(officeMailboxItem.subject);
                        self.officeAddIn.senderEmail = officeMailboxItem.sender && officeMailboxItem.sender.emailAddress ? officeMailboxItem.sender.emailAddress : officeMailboxItem.organizer && officeMailboxItem.organizer.emailAddress ? officeMailboxItem.organizer.emailAddress : '';

                        // compare with the current date to handle the time zone issue.
                        self.officeAddIn.receiveDate = officeMailboxItem.dateTimeCreated.getTime() <= new Date().getTime() ? officeMailboxItem.dateTimeCreated : new Date();
                        // retrieve body content
                        if (officeMailboxItem.body) {
                            officeMailboxItem.body.getAsync(Office.CoercionType.Html, function (asyncResult) {
                                if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                                    self.officeAddIn.body = asyncResult.value;
                                    const endTime = new Date();
                                    console.log('home-plugin get email data end: ' + endTime);
                                    console.log('home-plugin get email data time spent: (ms)' + (endTime.valueOf() - startTime.valueOf()));
                                    self._openNewNotePage(self.officeAddIn);
                                } else {
                                    this._router.navigate(['error']);
                                }
                            });
                        } else {
                            self._openNewNotePage(self.officeAddIn);
                        }
                    }
                    break;
                case AddInType.WORD:
                    this._handleAddInDocuments(AddInType.WORD);
                    break;
                case AddInType.EXCEL:
                    this._handleAddInDocuments(AddInType.EXCEL);
                    break;
                case AddInType.POWERPOINT:
                    this._handleAddInDocuments(AddInType.POWERPOINT);
                    break;
            }
        }
    }

    /**
     * get document subject and open note dialog
     * @param addInType support word or excel for now
     */
    private _handleAddInDocuments(addInType: AddInType) {
        if (Office.context.document) {
            this.officeAddIn = new OfficeAddIn();
            this.officeAddIn.addInType = addInType;
            // get subject
            const documentUrl = Office.context.document.url;
            if (documentUrl) {
                // for onedrive, the documentUrl will contain 'sharepoint.com'
                if (OfficeAddinService.isWebOffice() || Office.context.platform === Office.PlatformType.Mac || documentUrl.indexOf(businessConstants.officeAddIn.sharepoint) > -1) {
                    // web path or mac or onedrive
                    this.officeAddIn.subject = documentUrl.split('/')[documentUrl.split('/').length - 1];
                } else {
                    // local path
                    this.officeAddIn.subject = documentUrl.split('\\')[documentUrl.split('\\').length - 1];
                }
            }
            this._openNewNotePage(this.officeAddIn);
        }
    }
    /**
     * subject validation
     * @param subject
     */
    private _handleSubject(subject: string) {
        let result = '';
        if (!subject) {
            result = businessConstants.officeAddIn.noSubject;
        } else {
            result = subject.length <= businessConstants.officeAddIn.subjectMaxLength ? subject : subject.substring(0, businessConstants.officeAddIn.subjectMaxLength);
        }
        return result;
    }

    /**
     * open new note dialog for office add-in
     * @param officeAddIn
     */
    private _openNewNotePage(officeAddIn: OfficeAddIn) {
        if (officeAddIn.itemType === 'appointment') {
            // If appointment mode .should open calendar dialog.
            const noteParam: CalendarDialogOpenOptions = {
                openType: CalendarDialogType.NewEvent,
                isOfficeAddinMode: true,
                entity: null,
                officeAddIn: officeAddIn,
                minimizable: false,
                entryId: '',
                isPluginInEditEventMode: false
            };
            // In an Angular app, the UI sometimes does not update. This is because that part of the code runs out of the Angular zone. The solution is to put the code in the zone.
            this._zone.run(() => {
                this._calendarDialogService.dialogOpen$.next(noteParam);
            });
        } else {
            const noteParam: NoteDialogOpenOptions = {
                openType: NoteDialogType.CreateNote,
                isOfficeAddinMode: true,
                entity: null,
                officeAddIn: officeAddIn,
                minimizable: false,
                entryId: '',
                entityFieldEditable: false,
                isTransformByCalendar: this._calendarDialogService.opened,
                isSwitchFromCalendar: this._calendarDialogService.openedAddin
            };

            // In an Angular app, the UI sometimes does not update. This is because that part of the code runs out of the Angular zone. The solution is to put the code in the zone.
            this._zone.run(() => {
                this._calendarDialogService.dialogClose$.next(noteParam);
                this._noteDialogService.dialogOpen$.next(noteParam);
            });
        }
    }
}
