/**
 * created by Simon Zhao on 5/5/2022.
 * Description:
 * defines concrete models related to workflow tamale automation feature.
 * ------ maintenance history ------
 * 10/10/2022 Simon Zhao Separate the default metadata of note templates used by create note automation from the one used by note trigger workflow.
 */
import { TEMPLATE_FIELD_SYSTEM_VARIABLES } from './adhoc-field.model';
import { AutomationModelBase } from './automation-base.model';
import { ControlType } from './template-control.model';

/**
 * The model of a mapping item for Note Automations.
 */
export class AutomationMappingItem {
    /**
     * default metadata or adhoc field.
     */
    fieldInTemplate: string;

    /**
     * the mapping fields defined in one of input tasks of current workflow.
     * multiple field ids are concatenated by a comma, like field1,field2.
     */
    fieldInWorkflow: string;

    /**
     * Determines whether a given field is involved in the given mapping config item.
     * @param mappingItem the mapping config item
     * @param fieldId the given field id.
     * @returns a flag indicating whether the given field is involved in the given mapping config item.
     */
    static isFieldInvolved(mappingItem: AutomationMappingItem, fieldId: string) {
        const isInvolved = mappingItem && mappingItem.fieldInWorkflow && mappingItem.fieldInWorkflow.indexOf(fieldId) >= 0;
        return isInvolved;
    }
}

/**
 * The model of a note automation.
 */
export class AutomationNoteMappingModel extends AutomationModelBase {
    /**
     * a flag indicating whether a draft note needs to be created.
     * this only applicable for note's create.
     */
    isDraft: boolean;

    mapping: Array<AutomationMappingItem> = [];

    dependencyObject?: string; // it was used to create sidenote for automation.

    /**
     * Determines whether the given field is involved in the given mapping config collection.
     * @param noteMapping the note mapping config
     * @param fieldId the given field id.
     * @returns a flag indicating whether the given field is involved in the given mapping config collection.
     */
    static isFieldInvolved(noteMapping: AutomationNoteMappingModel, fieldId: string) {
        const isInvolved = noteMapping && noteMapping.mapping && noteMapping.mapping.some(mappingItem => AutomationMappingItem.isFieldInvolved(mappingItem, fieldId));
        return isInvolved;
    }
}

export class AutomationEntitySettingItem {
    /**
     * indicates what each id represents.
     */
    type: 'field' | 'entity';

    /**
     * a comma-separated id string.
     */
    ids: string;

}

export class AutomationRelationshipItem {
    /**
     * the setting for the parent entity.
     */
    parent: AutomationEntitySettingItem;
    /**
     * the relationship type id.
     */
    type: string;
    /**
     * the setting for the child entity.
     */
    child: AutomationEntitySettingItem;
    /**
     * a flag indicating whether current operation is append rather than replace.
     */
    append: boolean;

    /**
     * Determines whether the given field is involved in the given relationship config
     * @param relationItem the relationship config item.
     * @param fieldId the given field id.
     * @returns a flag indicating whether the given field is involved in the given relationship config.
     */
    static isFieldInvolved(relationItem: AutomationRelationshipItem, fieldId: string) {
        return (relationItem.parent && relationItem.parent.ids && relationItem.parent.ids.indexOf(fieldId) >= 0) || (relationItem.child && relationItem.child.ids && relationItem.child.ids.indexOf(fieldId) >= 0);
    }
}

/**
 * The model of a relationship automation.
 */
export class AutomationRelationshipModel extends AutomationModelBase {
    relationships: Array<AutomationRelationshipItem> = [];
    /**
     * Determines whether the given field is involved in the given relationship config collection.
     * @param relationModel the relationship config collection.
     * @param fieldId the given field id.
     * @returns a flag indicating whether the given field is involved in the given relationship config.
     */
    static isFieldInvolved(relationModel: AutomationRelationshipModel, fieldId: string) {
        return relationModel && relationModel.relationships && relationModel.relationships.some(rItem => AutomationRelationshipItem.isFieldInvolved(rItem, fieldId));
    }
}

export class AutomationModelUtility {

    static isRelationshipAutomation(automation: AutomationModelBase) {
        return 'relationships' in automation;
    }

    static isNoteAutomation(automation: AutomationModelBase) {
        return 'mapping' in automation;
    }

    /**
     * Determines whether the given field is involved in the given automation config.
     * @param automation an automation model
     * @param fieldId the given field id.
     */
    static isFieldInvolved(automation: AutomationModelBase, fieldId: string) {
        if (AutomationModelUtility.isRelationshipAutomation(automation)) {
            return AutomationRelationshipModel.isFieldInvolved(<AutomationRelationshipModel>automation, fieldId);
        } else if (AutomationModelUtility.isNoteAutomation(automation)) {
            return AutomationNoteMappingModel.isFieldInvolved(<AutomationNoteMappingModel>automation, fieldId);
        } else {
            throw new Error('Unsupported automation model!');
        }
    }
}

export const OWNER_PREFIX = { key: 'Submitter of ', value: 'owner_' };

export const VAR_PROJECT_ORIGINATOR = { key: 'Project originator', value: 'projectOriginator' };

/**
 * The default template field config for create note automation.
 */
export const DEFAULT_METDATA_OF_NOTE = [
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Entities',
        fieldInTemplate: 'Entities',
        fieldsInWorkflow: [],
        isMultipleSelection: true,
        isTaskSubmitterIncluded: true,
        applicableFieldTypes: [ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.MULTI_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN],
        applicableSystemVariables: [],
        fieldType: 'entity'
    },
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Source',
        fieldInTemplate: 'Source',
        fieldsInWorkflow: null,
        isMultipleSelection: false,
        isTaskSubmitterIncluded: true,
        applicableFieldTypes: [ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN],
        applicableSystemVariables: [],
        fieldType: 'entity'
    },
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Submitter',
        fieldInTemplate: 'Submitter',
        fieldsInWorkflow: null,
        isMultipleSelection: false,
        isTaskSubmitterIncluded: true,
        applicableFieldTypes: [ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN],
        applicableSystemVariables: [],
        fieldType: 'entity'
    },
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Display Date',
        fieldInTemplate: 'Display Date',
        fieldsInWorkflow: null,
        isMultipleSelection: false,
        applicableFieldTypes: [ControlType.DATE],
        applicableSystemVariables: TEMPLATE_FIELD_SYSTEM_VARIABLES[ControlType.DATE],
        fieldType: 'text'
    },
    {
        isPreloaded: true,
        isDeletable: true,
        nameOfFieldInTemplate: 'Subject',
        fieldInTemplate: 'Subject',
        fieldsInWorkflow: null,
        isMultipleSelection: false,
        applicableFieldTypes: [ControlType.TEXT, ControlType.TEXT_AREA, ControlType.NUMBER, ControlType.MULTI_TEXT_DROP_DOWN, ControlType.SINGLE_TEXT_DROP_DOWN],
        applicableSystemVariables: [{ key: 'general.project_name', value: 'projectName' }],
        fieldType: 'text'
    },
    {
        isPreloaded: false,
        isDeletable: true,
        nameOfFieldInTemplate: 'Attachment',
        fieldInTemplate: 'Attachment',
        fieldsInWorkflow: [],
        isMultipleSelection: true,
        applicableFieldTypes: [ControlType.FILE],
        fieldType: 'text'
    },
    {
        isPreloaded: false,
        isDeletable: true,
        nameOfFieldInTemplate: 'Note Body',
        fieldInTemplate: 'Note Body',
        fieldsInWorkflow: null,
        isMultipleSelection: false,
        applicableFieldTypes: [ControlType.RICH_EDITOR, ControlType.TEXT, ControlType.TEXT_AREA, ControlType.NUMBER, ControlType.SINGLE_TEXT_DROP_DOWN, ControlType.MULTI_TEXT_DROP_DOWN],
        fieldType: 'text'
    }
];

/**
 * The template field config for note trigger workflow.
 */
export const DEFAULT_METDATA_OF_NOTE_TRIGGER = [
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Entities',
        fieldInTemplate: 'Entities',
        fieldsInWorkflow: [],
        isMultipleSelection: true,
        applicableFieldTypes: [ControlType.MULTI_ENTITY_DROP_DOWN],
        applicableSystemVariables: [],
        fieldType: 'entity'
    },
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Source',
        fieldInTemplate: 'Source',
        fieldsInWorkflow: null,
        isMultipleSelection: true,
        isTaskSubmitterIncluded: true,
        applicableFieldTypes: [ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.MULTI_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN],
        applicableSystemVariables: [],
        fieldType: 'entity'
    },
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Submitter',
        fieldInTemplate: 'Submitter',
        fieldsInWorkflow: null,
        isMultipleSelection: true,
        isTaskSubmitterIncluded: true,
        applicableFieldTypes: [ControlType.SINGLE_ENTITY_DROP_DOWN, ControlType.MULTI_ENTITY_DROP_DOWN, ControlType.SINGLE_USER_DROP_DOWN],
        applicableSystemVariables: [],
        fieldType: 'entity'
    },
    {
        isPreloaded: true,
        isDeletable: false,
        nameOfFieldInTemplate: 'Display Date',
        fieldInTemplate: 'Display Date',
        fieldsInWorkflow: null,
        isMultipleSelection: true,
        applicableFieldTypes: [ControlType.DATE],
        applicableSystemVariables: TEMPLATE_FIELD_SYSTEM_VARIABLES[ControlType.DATE],
        fieldType: 'text'
    },
    {
        isPreloaded: true,
        isDeletable: true,
        nameOfFieldInTemplate: 'Subject',
        fieldInTemplate: 'Subject',
        fieldsInWorkflow: null,
        isMultipleSelection: true,
        applicableFieldTypes: [ControlType.TEXT, ControlType.TEXT_AREA],
        applicableSystemVariables: [{ key: 'general.project_name', value: 'projectName' }],
        fieldType: 'text'
    },
    {
        isPreloaded: false,
        isDeletable: true,
        nameOfFieldInTemplate: 'Attachment',
        fieldInTemplate: 'Attachment',
        fieldsInWorkflow: [],
        isMultipleSelection: true,
        applicableFieldTypes: [ControlType.FILE],
        fieldType: 'text'
    },
    {
        isPreloaded: false,
        isDeletable: true,
        nameOfFieldInTemplate: 'Note Body',
        fieldInTemplate: 'Note Body',
        fieldsInWorkflow: null,
        isMultipleSelection: true,
        applicableFieldTypes: [ControlType.RICH_EDITOR],
        fieldType: 'text'
    }
];
