export class Marketdata {
    name: string;
    idColumnName: string;
    fileName: string;
    fileHex: string;
    columns: Array<string>;
    idStrategy: string;
    entityId: string;
    idColumnIndex: number;

    static parse(serverResponse): Marketdata {
        const result = new Marketdata();
        result.name = serverResponse['name'];
        result.idColumnName = serverResponse['ID Column Name'];
        result.fileName = serverResponse['fileName'];
        result.fileHex = serverResponse['fileHex'];
        result.columns = serverResponse['columns'];
        result.idStrategy = serverResponse['ID Strategy'];
        result.entityId = serverResponse['entityID'];
        result.idColumnIndex = serverResponse['ID Column Index'];
        return result;
    }
}
