/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 */

import { combineReducers, createSelector } from '@ngrx/store';
import { EntryTypeActions, EntryTypeActionTypes } from '../actions/entry-type.actions';
import { AppState } from '..';
import { AdminPageActions, AdminPageActionTypes } from '../actions/admin-page.actions';
import { EntryType } from '../../tamalelibs/models/entry-type.model';
import { NoteTypesSubjectTypes } from '../../components/admin-pages/admin-page-setting.model';

export const entryTypeSelector = (state: AppState) => state.entryType;
export const availableEntryTypeSelector = createSelector(entryTypeSelector, (state: any) => state.available);
export const fullEntryTypeSelector = createSelector(entryTypeSelector, (state: any) => state.full);
export const defaultEntryTypeSelector = createSelector(entryTypeSelector, (state: any) => state.default);

export function entryTypeAvailableReducer(state: Array<any>, action: EntryTypeActions | AdminPageActions) {
    switch (action.type) {
        case EntryTypeActionTypes.INITIALIZE_SUCCESS:
            return action.payload.available;
        case EntryTypeActionTypes.INITIALIZE_FAILED:
            return action.payload.available;
        case AdminPageActionTypes.CREATE_NOTE_TYPE_SUCCESS: {
            if (!('display-web' in action.payload.newNote && action.payload.newNote['display-web'] === false)) {
                state.push(new EntryType(action.payload.newNote.id, action.payload.newNote.name, action.payload.newNote.useNER, action.payload.newNote.href, action.payload.newNote.usage, action.payload.newNote.config));
            }
            return Object.assign([], state);
        }
        case AdminPageActionTypes.DELETE_NOTE_TYPE_SUCCESS: {
            state = state.filter(item => item.id !== action.payload.id);
            return Object.assign([], state);
        }
        case AdminPageActionTypes.EDIT_NOTE_TYPE_SUCCESS: {
            if ('display-web' in action.payload.newNote && action.payload.newNote['display-web'] === false) {
                state = state.filter(item => item.id !== action.payload.newNote.id);
            } else {
                state.forEach(entry => {
                    if (entry.id === action.payload.newNote['id']) {
                        entry.name = action.payload.newNote['name'];
                        entry.useNER = action.payload.newNote['useNER'];
                        entry.href = action.payload.newNote['href'];
                        entry.usage = action.payload.newNote['usage'];
                        entry.config = action.payload.newNote['config'];
                    }
                });
            }
            return Object.assign([], state);
        }
        case AdminPageActionTypes.TOGGLE_NOTE_DISPLAY_SUCCESS: {
            if ('display-web' in action.payload.data && action.payload.data['display-web'] === false) {
                state = state.filter(item => item.id !== action.payload.data.id);
            } else {
                state.push(new EntryType(action.payload.data.id, action.payload.data.name));
            }
            return Object.assign([], state);
        }
        default:
            return state;
    }
}

export function entryTypeFullReducer(state: Array<any>, action: EntryTypeActions | AdminPageActions) {
    switch (action.type) {
        case EntryTypeActionTypes.INITIALIZE_SUCCESS:
            return action.payload.full;
        case EntryTypeActionTypes.INITIALIZE_FAILED:
            return action.payload.full;
        case AdminPageActionTypes.CREATE_NOTE_TYPE_SUCCESS: {
            state.push(new EntryType(action.payload.newNote.id, action.payload.newNote.name));
            return Object.assign([], state);
        }
        case AdminPageActionTypes.DELETE_NOTE_TYPE_SUCCESS: {
            state = state.filter(item => item.id !== action.payload.id);
            return Object.assign([], state);
        }
        case AdminPageActionTypes.EDIT_NOTE_TYPE_SUCCESS: {
            state.forEach(entry => {
                if (entry.id === action.payload.newNote['id']) {
                    entry.name = action.payload.newNote['name'];
                }
            });
            return Object.assign([], state);
        }
        default:
            return state;
    }
}

export function entryTypeDefaultReducer(state: EntryType, action: EntryTypeActions | AdminPageActions) {
    switch (action.type) {
        case EntryTypeActionTypes.INITIALIZE_SUCCESS:
            return action.payload.default;
        case EntryTypeActionTypes.INITIALIZE_FAILED:
            return action.payload.default;
        case AdminPageActionTypes.EDIT_NOTE_TYPE_SUCCESS: {
            if (state.id === action.payload.newNote['id']) {
                state.name = action.payload.newNote['name'];
            }
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.EDIT_DEFAULT_NOTE_SUCCESS: {
            if (action.payload.defaultTypeField === NoteTypesSubjectTypes.DEFAULT_NOTE_TYPE) {
                state.id = action.payload.data.id;
                state.name = action.payload.data.name;
            }
            return Object.assign({}, state);
        }
        default:
            return state;
    }
}

const entryTypeCombineReducer = combineReducers({
    available: entryTypeAvailableReducer,
    full: entryTypeFullReducer,
    default: entryTypeDefaultReducer
});

export function entryTypeReducer(state, action) {
    return entryTypeCombineReducer(state, action);
}
