<div class="container">
    <field-config-base #base [config]="config" [dataType]="dataType"></field-config-base>
    <div tid="default_value" class="tam-form-field one-field">
        <div class="tam-form-label label-area">
            <div class="field-name body-2 text-ellipsis">
                Default value
            </div>
        </div>
        <label tam-checkbox class="tam-textbox field-control" type="text" [(ngModel)]="config.staticDefaultValue"
            (click)="valueChange($event)">Use today as default value.</label>
    </div>
</div>