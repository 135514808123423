/**
 * Created by Daniel on 01/19/2021.
 * Description: single entity dropdown filter for dynamic data source.
 * ------ maintenance history ------
 */

import { EntityBrief } from './../../tamalelibs/models/entity-brief.model';
import { Component, ContentChild, ElementRef, Input, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { EntityService } from '../../tamalelibs/services/entity.service';
import { SingleEntityDropdownFilterConfig } from './single-entity-dropdown-filter.model';

export const PAGE_SIZE = 10;

@Component({
    selector: 'tam-single-entity-dropdown-filter',
    templateUrl: './single-entity-dropdown-filter.component.html',
    styleUrls: ['./single-entity-dropdown-filter.component.scss']
})
export class SingleEntityDropdownFilterComponent implements OnInit, OnDestroy {
    @Input() config: SingleEntityDropdownFilterConfig;
    @ContentChild('customContent', { static: false }) customContent: TemplateRef<any>;
    @ViewChild('entitylist', { static: true }) entitylist;

    anchor: ElementRef;
    hideInput = true;
    inputValue = '';
    items = [];
    searchValue = '';
    selectedItem = '';
    showEntityDropdown = false;

    private _page: number;
    private _pageSize: number = PAGE_SIZE;
    private _subscription: Subscription[] = [];

    constructor(private _entityService: EntityService) { }

    ngOnInit() {
        this._subscription.push(
            // suscribe dropdown open event
            this.config.open$.subscribe((anchor) => {
                this.showPopup(anchor);
            })
        );
        // trigger control status
        this.hideInput = this.config.hideInput;
        // init search data
        this.searchingInit();
    }

    ngOnDestroy() {
        this._subscription.forEach(item => item.unsubscribe());
    }

    clearSearch(): void {
        this.searchValue = '';
    }

    onClickOutside(event: Object) {
        // remove search text and hide entity dropdown
        if (event && event['value'] === true) {
            this.showEntityDropdown = false;
            this.clearSearch();
        }
    }

    onItemClick(item: EntityBrief, event) {
        this.showEntityDropdown = false;
        this.config.onChangeValue$.next(item);
        event.stopPropagation();
    }

    searchData() {
        // search data when input text in textbox
        this.searching(this.searchValue);
    }

    searching(text) {
        this._page = 0;
        this._subscription.push(
            this._entityService.getEntityListBySearchTextQuick(++this._page, this._pageSize, text).pipe(
                take(1),
                catchError(() => {
                    return of();
                }))
                .subscribe(res => {
                    if (res) {
                        // map the result to entity brief
                        this.items = this._entityService.mapEntityBriefList(res);
                    }
                })
        );
    }

    searchingInit() {
        this.searching('');
    }

    showPopup(event: any) {
        // show entity dropdown
        this.anchor = event.target || event;
        this.showEntityDropdown = true;
        this.searchValue = '';
        this.searching('');
    }
}
