/**
 * Created by Daniel on 5/23/2022.
 * Description:
 *
 * ------ maintenance history ------
 */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FileHelperService } from '../../services/file-helper.service';
import { businessConstants } from '../../tamalelibs/constants/business.constants';
import { FileDownload } from '../../tamalelibs/models/file-list.model';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';

@Component({
    selector: 'tam-multiple-files-renderer',
    templateUrl: './multiple-files-renderer.component.html',
    styleUrls: ['./multiple-files-renderer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleFilesRendererComponent implements ICellRendererAngularComp {
    //#region public properties
    data = [];
    title = '';
    //#endregion

    //#region constructor
    constructor(
        private _fileHelperService: FileHelperService) {
    }
    //#endregion

    //#region public methods
    agInit(params: any): void {
        // get data from files property
        if (params.files) {
            this.data = params.files;
        } else { // get data from default value property
            this.data = params.value;
        }
        // reorder the data by file name by asc
        if (this.data) {
            ArrayHelperService.sort<FileDownload>(this.data, 'name');
            this.data.forEach(item => {
                item.name = this._getFileNameNotIncludeExtension(item.name);
            });
            this.title = this.data.map(item => this._getFileNameIncludeExtension(item.name, item.extension)).join(', ');
        }
    }

    refresh(): boolean {
        return false;
    }

    downloadFile(file): void {
        this._fileHelperService.downloadFile$.next();
        this._fileHelperService.downloadFileNew(file.name, file.extension, file.id);
    }
    //#endregion

    //#region private functions
    private _getFileNameIncludeExtension(name: string, extension: string) {
        let fileName = '';
        if (name.includes(businessConstants.common.separator.DOT)) {
            fileName = name;
        } else {
            if (extension.includes(businessConstants.common.separator.DOT)) {
                fileName = name + extension;
            } else {
                fileName = name + businessConstants.common.separator.DOT + extension;
            }
        }
        return fileName;
    }
    private _getFileNameNotIncludeExtension(name: string) {
        let fileName = '';
        if (!name.includes(businessConstants.common.separator.DOT)) {
            fileName = name;
        } else {
            const index = name.indexOf(businessConstants.common.separator.DOT);
            fileName = name.substring(0, index);
        }
        return fileName;
    }
    //#endregion
}
