/**
 * Created by Lucas Wang 08/24/2021
 *
 * Filter used for keyword search for contacts in users editor
 *
 */

import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../models/contact.model';
import { ContactList } from '../models/entity-list.model';

@Pipe({ name: 'contactListFilter' })
export class ContactListFilter implements PipeTransform {
    transform(contactList: ContactList, keyword: string): any {
        if (!contactList || keyword === '') {
            return [];
        }
        keyword = keyword.toLowerCase();
        return contactList.contacts.filter((contact: Contact) => {
            const hasLongName = contact.name.toLowerCase().indexOf(keyword) !== -1;
            const hasShortName = contact.shortName.toLowerCase().indexOf(keyword) !== -1;
            const hasAlias = contact.aliases.filter(value => value.toLowerCase().indexOf(keyword) !== -1).length !== 0;
            return hasLongName || hasShortName || hasAlias;
        });
    }
}
