/**
 * Created by Marcus Zhao on 6/20/2021.
 * Description:
 * ------ maintenance history ------
 * Support search nearby entity,resturant,hotel and mark it on google map
 * Updated by Daniel Wang on 11/11/2022. To support show event on map tile.
 */
import { NearbyData } from '../tam-dashboard-map-tile.config';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContactService } from '../../../../tamalelibs/services/contact.service';
import { MultiSelDropdownConfig, MultiSelDropdownFilterConfig } from '../../../../widgets/multi-sel-dropdown-filter/multi-sel-dropdown-filter.model';
import { DashboardTileService } from '../../dashboard-tile.service';
import { AutocompleteActionType } from '../tam-dashboard-map-autocomplete/tam-dashboard-map-autocomplete.model';
import { TamDashboardTileData } from '../tam-dashboard-map-tile.config';
import { EntityType } from '../../../../tamalelibs/models/entity-type.model';
import { FieldEventType, FieldConfig, StandardFilterItemsContact, FieldConfigData, FieldActionType, StandardFilterItemsEvent } from '../../../../widgets/field-selector/field-selector.model';
import { FilterPanelActions, FilterPanelConfig, FilterPanelEvents } from '../../../../widgets/filter-item/filter-panel/filter-panel.view-model';
import { AdhocService } from '../../../../tamalelibs/services/adhoc.service';
import { catchError, filter, map, take } from 'rxjs/operators';
import { MapFilterService } from '../../../../tamalelibs/services/map-filter.service';
import { forkJoin, of } from 'rxjs';
import { MapFilterContent, MapFilterModel } from '../../../../tamalelibs/models/map-tile.model';
import { businessConstants } from '../../../../constants/business.constants';
import { NotificationStyles, NotificationOptions } from '../../../../widgets/notification/notification.model';
import { ToastService } from '../../../../widgets/toast/toast.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../redux';
import { availableEntityTypeSelector } from '../../../../redux/reducers/entity-type.reducer';
import { TamDashboardMapTileService } from '../tam-dashboard-map-tile.service';
import { MapAction } from '../tam-dashboard-map/tam-dashboard-map.model';
import { EntryService } from '../../../../tamalelibs/services/entry.service';
import { StoreQuerierService } from '../../../../services/store-querier.service';
import { FilterMetadataType } from '../../../../tamalelibs/components/filter-config';
import { ArrayHelperService } from '../../../../tamalelibs/services/array-helper.service';

export const ALL_SOURCE_NAME = 'All';
export const EMPTY_SOURCE_NAME = 'None';
export const STANDARD = 'standard';

@Component({
    selector: 'tam-dashboard-map-nearby',
    templateUrl: './tam-dashboard-map-nearby.component.html',
    styleUrls: ['./tam-dashboard-map-nearby.component.scss']
})

export class TamDashboardMapNearbyComponent implements OnInit, OnChanges {
    @Output() eventSubject$ = new EventEmitter<any>();
    @Input() location: TamDashboardTileData;
    @Input() id: string;
    @Input() isFromShowEventOnMapTile?: false;

    dropdownConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    dropdownConfigData = ['restaurant', 'hotel'];
    entityName = ALL_SOURCE_NAME;
    entityTypeConfig: MultiSelDropdownFilterConfig = new MultiSelDropdownFilterConfig(true);
    entityTypeData = [EntityType.CONTACT, EntityType.EVENT];
    filterConfig: FilterPanelConfig;
    filterEntityConfig: FilterPanelConfig;
    filterEventConfig: FilterPanelConfig;
    fieldConfig: FieldConfig;
    fieldEntityConfig: FieldConfig;
    fieldEventConfig: FieldConfig;
    hiddenTimeout: any;
    isBoxHoverOn = false;
    isBoxShow = true;
    isIconShow = true;
    isOuterBoxShow = true;
    isShowContactFilter = false;
    isShowEntityFilter = false;
    isShowEventFilter = false;
    radiusData = [
        { name: 'Within 0.5 miles', value: '0.5' },
        { name: 'Within 5 miles', value: '5' },
        { name: 'Within 15 miles', value: '15' },
        { name: 'Within 30 miles', value: '30' }
    ];
    radiusSelect = this.radiusData[1];
    sourceName = EMPTY_SOURCE_NAME;
    searchName = '';

    private _destroySubscriptions = [];
    private _entityNearbyData = [];
    private _eventNearbyData = [];
    private _isApply = false; // flag user click apply or not
    private _infoToast: NotificationOptions = {
        message: 'No result found',
        style: NotificationStyles.Info
    };
    private _mapfilterModel = new MapFilterModel();
    private _mapFilterModelForEvent = new MapFilterModel();
    private _sourceIds = [];

    constructor(
        private _contactService: ContactService,
        private _dashboardTileService: DashboardTileService,
        private _adhocService: AdhocService,
        private _entryServie: EntryService,
        private _mapFilterService: MapFilterService,
        private _toastService: ToastService,
        private _store: Store<AppState>,
        private _storeQuerier: StoreQuerierService,
        private _tamDashboardMapTileService: TamDashboardMapTileService,
        private _changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        if (this.isFromShowEventOnMapTile) {
            this.isShowEventFilter = true;
            this.isShowContactFilter = true;
            this.isShowEntityFilter = true;
        }
        this._initEntityTypes();
        this.dropdownConfigData.forEach(item => {
            this.dropdownConfig.data.push(MultiSelDropdownConfig.parse(item, false, item));
        });

        this.entityTypeData.forEach(item => {
            this.entityTypeConfig.data.push(MultiSelDropdownConfig.parse(item.id, true, item.name));
        });
        this.dropdownConfig.onChangeValue$.subscribe(data => this.valueChangeSource(data));
        this.entityTypeConfig.onChangeValue$.subscribe(data => this.valueChangeSourceEnity(data));
        this._isInitFilterPanel();
        this._initSubscribe();
        this._updateChangesToUI();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.location) {
            if (this.location.entityName === businessConstants.mapString.multipleResult) {
                this.searchName = this.location['originalName'];
            } else {
                this.searchName = this.location.entityName;
            }
        }
    }

    boxMouseOut() {
        this.isBoxHoverOn = false;
    }

    changeBoxStatus() {
        this.isBoxShow = !this.isBoxShow;
        this.isBoxHoverOn = false;
        if (this.isBoxShow) {
            clearTimeout(this.hiddenTimeout);
            this.isOuterBoxShow = true;

            /**
             * icons should appear after the box fully expands
             */
            setTimeout(() => {
                this.isIconShow = true;
            }, 300);
        } else {
            /**
             * outer-box should fully disappear after its width becomes 0px
             */
            this.hiddenTimeout = setTimeout(() => {
                this.isOuterBoxShow = false;
            }, 300);
            this.isIconShow = false;
        }
    }

    closeShowNearby() {
        this.eventSubject$.emit({
            type: AutocompleteActionType.closeNearby, payload: {
                location: this.location,
                isApply: this._isApply
            }
        });
        this._isApply = false;
    }

    onClickSource(event) {
        this.dropdownConfig.open$.next(event.target);
    }

    onClickEntity(event) {
        this.entityTypeConfig.open$.next(event.target);
    }

    onClickApply() {
        this._setNoChangeFilterData();
        this._isApply = true;
        const searchTypes = this.sourceName.split(',');
        let nearbyParams: NearbyData;
        if (this.location) {
            if (!this.location.latitude || !this.location.longitude) {
                this._dashboardTileService.getMapSearchGeocodeAddress(this.location.address)
                    .pipe(take(1))
                    .subscribe(_location => {
                        this.location.latitude = _location.lat;
                        this.location.longitude = _location.lng;
                        if (!this.location.entityType) {
                            this.location.entityType = businessConstants.addressType.google;
                        }
                        nearbyParams = new NearbyData(this.location.latitude, this.location.longitude, this.location.entityName, Number(this.radiusSelect.value), searchTypes);
                        this._judgeNearbyData(nearbyParams);
                    });
            } else {
                nearbyParams = new NearbyData(this.location.latitude, this.location.longitude, this.location.entityName, Number(this.radiusSelect.value), searchTypes);
                this._judgeNearbyData(nearbyParams);
            }
        }
        // Apply action has been triggered
        this._tamDashboardMapTileService.feedbackSubject$.next({
            type: MapAction.APPLY_NEARBY
        });
    }

    boxHoverOn() {
        this.isBoxHoverOn = true;
    }

    valueChangeSource(data): void {
        if (data.length === 0) {
            this.sourceName = EMPTY_SOURCE_NAME;
        } else if (data === ALL_SOURCE_NAME.toLowerCase()) {
            this.sourceName = this.dropdownConfigData.join(',');
        } else {
            this.sourceName = data.map(item => item.name).join(',');
        }
        this._hideFilterPanel();
    }

    valueChangeSourceEnity(data): void {
        if (data.length === 0) {
            this.entityName = EMPTY_SOURCE_NAME;
            this._sourceIds = [];
        } else if (data === ALL_SOURCE_NAME.toLowerCase()) {
            this.entityName = ALL_SOURCE_NAME;
            this._sourceIds = this.entityTypeData.map(item => item.id);
        } else {
            this.entityName = data.map(item => item.name).join(',');
            this._sourceIds = data.map(item => item.id);
        }
        this._hideFilterPanel();
        this._adhocService.getEntityAdhocsByEntityType(this._sourceIds).subscribe(response => {
            const result = this._adhocService.parseResponse(response);
            this.fieldEntityConfig.actions$.next({ type: FieldActionType.changeEntityList, payload: result.title });
        });
    }

    /**
     *
     * when entity or contact type is no-exist ,need hide entity/contact paneld and empty data list.
     * @private
     * @memberof TamDashboardMapNearbyComponent
     */
    private _hideFilterPanel() {
        this.isShowContactFilter = this.entityTypeConfig.data.some(item => item.id === EntityType.CONTACT.id && item.checked);
        this.isShowEntityFilter = this.entityTypeConfig.data.some(item => item.id !== EntityType.CONTACT.id && item.id !== EntityType.EVENT.id && item.checked);
        this.isShowEventFilter = this.entityTypeConfig.data.some(item => item.id === EntityType.EVENT.id && item.checked);
        if (!this.isShowContactFilter) {
            this.filterConfig.filtersConfigList = [];
        }
        if (!this.isShowEntityFilter) {
            this.filterEntityConfig.filtersConfigList = [];
        }
        if (!this.isShowEventFilter) {
            this.filterEventConfig.filtersConfigList = [];
            this._setDefaultValueForFilter();
        }
    }

    private _fieldConfigSubject() {
        this._destroySubscriptions.push(
            // Contact
            this.fieldConfig.events$.pipe(
                filter(data => data.type === FieldEventType.select)
            ).subscribe((data) => {
                let filtersConfig;
                if (data.payload.type === STANDARD) {
                    filtersConfig = this._mapFilterService.getNewFilterDefaultInstance(data.payload.text, data.payload.value);
                }
                this.filterConfig.filtersConfigList.push(filtersConfig);
                this.filterConfig.action$.next({ type: FilterPanelActions.addFilter, payload: [filtersConfig] });
            }),
            // Entity
            this.fieldEntityConfig.events$.pipe(
                filter(data => data.type === FieldEventType.select)
            ).subscribe((data) => {
                let filtersConfig;
                filtersConfig = this._mapFilterService.getAdhocFilterDefaultInstance(data.payload);
                this.filterEntityConfig.filtersConfigList.push(filtersConfig);
                this.filterEntityConfig.action$.next({ type: FilterPanelActions.addFilter, payload: [filtersConfig] });
            })
        );
    }

    private _fieldConfigSubjectForEvent(): void {
        this._destroySubscriptions.push(
            // Event
            this.fieldEventConfig.events$.pipe(
                filter(data => data.type === FieldEventType.select)
            ).subscribe((data) => {
                let filtersConfig;
                if (data.payload.type === STANDARD) {
                    filtersConfig = this._mapFilterService.getNewFilterDefaultInstance(data.payload.text, data.payload.value);
                    if (filtersConfig.metadataType === FilterMetadataType.Entities) {
                        filtersConfig.isHiddenRelationship = true;
                    }
                } else {
                    filtersConfig = this._mapFilterService.getAdhocFilterDefaultInstance(data.payload);
                }
                this.filterEventConfig.filtersConfigList.push(filtersConfig);
                this.filterEventConfig.action$.next({ type: FilterPanelActions.addFilter, payload: [filtersConfig] });
            })
        );
    }

    /**
     * contact/entity filter subscribe
     *
     * @memberof TamDashboardMapNearbyComponent
     */
    private _filterConfigSubject() {
        this._destroySubscriptions.push(
            this.filterConfig.events$.pipe(
                filter((data) => data.type === FilterPanelEvents.change || data.type === FilterPanelEvents.delete)
            ).subscribe((data) => {
                this.filterConfig.filtersConfigList = [].concat(data.payload);
                this._formatFilterData();
            }),
            this.filterEntityConfig.events$.pipe(
                filter((data) => data.type === FilterPanelEvents.change || data.type === FilterPanelEvents.delete)
            ).subscribe((data) => {
                this.filterEntityConfig.filtersConfigList = [].concat(data.payload);
                this._formFilterEntityAdhocData();
            }),
        );
    }

    private _filterConfigSubjectForEvent(): void {
        this._destroySubscriptions.push(
            this.filterEventConfig.events$.pipe(
                filter((data) => data.type === FilterPanelEvents.change || data.type === FilterPanelEvents.delete)
            ).subscribe((data) => {
                this.filterEventConfig.filtersConfigList = [].concat(data.payload);
                this._formatFilterEventAdhocData();

                const eventTypeIds = data.payload.filter(item => item.dataType === businessConstants.dataType.eventType)[0].value;
                this._adhocService.getEventAdhocsByEventTypeId(eventTypeIds).pipe(take(1)).subscribe(response => {
                    const result = this._adhocService.parseResponse(response);
                    this.fieldEventConfig.actions$.next({ type: FieldActionType.changeEventList, payload: result.title });
                });
            }),
        );
    }

    /**
     * contact filter format
     *
     * @private
     * @memberof TamDashboardMapNearbyComponent
     */
    private _formatFilterData() {
        this._mapfilterModel.filters[0] = new MapFilterContent();
        this._mapfilterModel.filters[0].field = businessConstants.common.contact;
        if (this.filterConfig.filtersConfigList.length > 0) {
            this._mapfilterModel.filters[0] = this._mapFilterService.getFilters(this.filterConfig.filtersConfigList, this._mapfilterModel.filters[0]);
        }
    }

    /**
     *entity filter format
     *
     * @private
     * @memberof TamDashboardMapNearbyComponent
     */
    private _formFilterEntityAdhocData() {
        this._mapfilterModel.filters[1] = new MapFilterContent();
        this._mapfilterModel.filters[1].field = businessConstants.common.adhoc;
        if (this.filterEntityConfig.filtersConfigList.length > 0) {
            this._mapfilterModel.filters[1] = this._mapFilterService.getFilters(this.filterEntityConfig.filtersConfigList, this._mapfilterModel.filters[1]);
        }
    }

    private _formatFilterEventAdhocData(): void {
        const currentUser = this._storeQuerier.getCurrentUser();
        this._mapFilterModelForEvent.filters[0] = new MapFilterContent();
        this._mapFilterModelForEvent.filters[0].field = businessConstants.common.entry;
        if (this.filterEventConfig.filtersConfigList.length > 0) {
            this._mapFilterModelForEvent.filters[0] = this._mapFilterService.getFilters(this.filterEventConfig.filtersConfigList, this._mapFilterModelForEvent.filters[0], currentUser);
        }
    }

    private _getCurrentFocusedEventByDuplicateItems(location: TamDashboardTileData): TamDashboardTileData {
        let targetLocation = location;
        if (location && location.duplicateAddress[0] && location.entityType === businessConstants.addressType.duplicate) {
            location.duplicateAddress[0].list.forEach(item => {
                if (item.entityId === location.entityId) {
                    if (item.entityType === businessConstants.addressType.duplicate) {
                        targetLocation = this._getCurrentFocusedEventByDuplicateItems(item);
                    } else {
                        targetLocation = item;
                    }
                }
            });
        }
        return targetLocation;
    }

    /**
     * send request for get nearby data
     *
     * @private
     * @param {*} nearbyParams
     * @memberof TamDashboardMapNearbyComponent
     */
    private _getNearbyData(nearbyParams) {
        // set location configuration
        const configuration = {
            location: {
                latitude: this.location.latitude,
                longitude: this.location.longitude,
            },
            radius: this.radiusSelect.value,
        };

        // tamale event is selected
        if (this._sourceIds && this._sourceIds.some(item => item === EntityType.EVENT.id)) {
            // only tamale event is selected
            if (this._sourceIds.length === 1) {
                this._entryServie.getEventListByNearby(configuration, this.location.entityId, this._mapFilterModelForEvent)
                    .pipe(
                        take(1)
                    ).subscribe(res => {
                        this._eventNearbyData = res && res['entryList'];
                        if (this._eventNearbyData) {
                            if (this.sourceName !== EMPTY_SOURCE_NAME) {
                                // send google
                                this._dashboardTileService.getMapSearchNearby(nearbyParams, this, this._getPredictions);
                            } else {
                                if (this._eventNearbyData.length > 0) {
                                    const targetLoation = this._getCurrentFocusedEventByDuplicateItems(this.location);
                                    this.eventSubject$.emit({ type: AutocompleteActionType.showNearby, payload: [[], [], targetLoation, this._eventNearbyData] });
                                } else {
                                    this._noDataTrigerEvent();
                                }
                            }
                        } else {
                            this._noDataTrigerEvent();
                        }
                    });
            } else {
                // entity types are selected besides tamale event
                const observables$ = [];
                observables$.push(this._entryServie.getEventListByNearby(configuration, this.location.entityId, this._mapFilterModelForEvent));
                observables$.push(this._contactService.getEntityListByNearby(configuration, this._sourceIds, this.location.entityId, this._mapfilterModel));
                forkJoin(observables$).subscribe(res => {
                    this._eventNearbyData = res[0] && res[0]['entryList'];
                    this._entityNearbyData = res[1] && res[1]['entity-list'];
                    if (this._entityNearbyData || this._eventNearbyData) {
                        if (this.sourceName !== EMPTY_SOURCE_NAME) {
                            // send google
                            this._dashboardTileService.getMapSearchNearby(nearbyParams, this, this._getPredictions);
                        } else {
                            if (this._entityNearbyData.length > 0 || this._eventNearbyData.length > 0) {
                                const targetLoation = this._getCurrentFocusedEventByDuplicateItems(this.location);
                                this.eventSubject$.emit({ type: AutocompleteActionType.showNearby, payload: [this._entityNearbyData, [], targetLoation, this._eventNearbyData] });
                            } else {
                                this._noDataTrigerEvent();
                            }
                        }
                    } else {
                        this._noDataTrigerEvent();
                    }
                });
            }
        } else {
            // tamale event isn't selected. handle the original logic which need to ingore tamale event id
            const entityTypeIds = this.entityTypeData.map(item => item.id);
            const entityTypeDataExceptEvent = entityTypeIds.filter(item => item !== EntityType.EVENT.id);
            if (this._sourceIds && this._sourceIds.toString() === entityTypeDataExceptEvent.toString()) {
                this._sourceIds = null;
            }

            this._contactService.getEntityListByNearby(configuration, this._sourceIds, this.location.entityId, this._mapfilterModel)
                .pipe(
                    catchError((err) => {
                        if (this.sourceName !== EMPTY_SOURCE_NAME) {
                            // send google
                            this._dashboardTileService.getMapSearchNearby(nearbyParams, this, this._getPredictions);
                        } else {
                            this._noDataTrigerEvent();
                        }
                        return of(null);
                    }),
                    take(1)
                ).subscribe(res => {
                    this._entityNearbyData = res && res['entity-list'];
                    if (this._entityNearbyData) {
                        if (this.sourceName !== EMPTY_SOURCE_NAME) {
                            // send google
                            this._dashboardTileService.getMapSearchNearby(nearbyParams, this, this._getPredictions);
                        } else {
                            if (this._entityNearbyData.length > 0) {
                                const targetLoation = this._getCurrentFocusedEventByDuplicateItems(this.location);
                                this.eventSubject$.emit({ type: AutocompleteActionType.showNearby, payload: [this._entityNearbyData, [], targetLoation, []] });
                            } else {
                                this._noDataTrigerEvent();
                            }
                        }
                    } else {
                        this._noDataTrigerEvent();
                    }
                });
        }
    }

    /**
     * listen open nearby function
     *
     * @private
     * @memberof TamDashboardMapNearbyComponent
     */
    private _initSubscribe() {
        this._destroySubscriptions.push(
            this._tamDashboardMapTileService.feedbackSubject$.
                pipe(filter(res => res.id === this.id))
                .subscribe(res => {
                    if (res.type === MapAction.OPEN_NEARBY) {
                        if (!this.isBoxShow) {
                            this.changeBoxStatus();
                        }
                    }
                }));
    }

    /**
     * Initialize the Contact and Entity filter separately
     * entity use entityAdhoc for set value.
     * @param {*} result
     * @memberof TamDashboardMapNearbyComponent
     */
    private _initFieldSelector(result) {
        // Contact
        this.filterConfig = new FilterPanelConfig();
        this.fieldConfig = new FieldConfig();
        this.fieldConfig.config = new FieldConfigData();
        this.fieldConfig.config.standardItem = StandardFilterItemsContact;
        this.fieldConfig.fieldIndex = 1;

        // Entity
        this.filterEntityConfig = new FilterPanelConfig();
        this.fieldEntityConfig = new FieldConfig();
        this.fieldEntityConfig.config = new FieldConfigData();
        this.fieldEntityConfig.config.standardItem = [];
        this.fieldEntityConfig.config.showSearch = true;
        this.fieldEntityConfig.config.showAdhoc = false;
        this.fieldEntityConfig.config.entityAdhoc = result.title;
        this.fieldEntityConfig.fieldIndex = 2;
    }

    private _initFieldSelectorForEvent(result): void {
        // Event
        this.filterEventConfig = new FilterPanelConfig();
        this.fieldEventConfig = new FieldConfig();
        this.fieldEventConfig.config = new FieldConfigData();
        this.fieldEventConfig.config.standardItem = StandardFilterItemsEvent;
        this.fieldEventConfig.config.showSearch = true;
        this.fieldEventConfig.config.showAdhoc = false;
        this.fieldEventConfig.config.eventAdhoc = result.title;
        this.fieldEventConfig.fieldIndex = 3;
    }

    private _isInitFilterPanel(ids = [ALL_SOURCE_NAME]) {
        this.isShowContactFilter = this.entityTypeConfig.data.some(item => item.id === EntityType.CONTACT.id && item.checked);
        this.isShowEntityFilter = this.entityTypeConfig.data.some(item => item.id !== EntityType.CONTACT.id && item.checked);
        this.isShowEventFilter = this.entityTypeConfig.data.some(item => item.id !== EntityType.EVENT.id && item.checked);
        // Entity and Contact
        this._adhocService.getEntityAdhocsByEntityType(ids).subscribe(response => {
            const result = this._adhocService.parseResponse(response);
            this._initFieldSelector(result);
            this._fieldConfigSubject();
            this._filterConfigSubject();
            this._updateChangesToUI();
        });
        // Event
        this._adhocService.getEventAdhocsByEventTypeId(ids).subscribe(response => {
            const result = this._adhocService.parseResponse(response);
            this._initFieldSelectorForEvent(result);
            this._fieldConfigSubjectForEvent();
            this._filterConfigSubjectForEvent();

            // set default value for event filter
            this._setDefaultValueForFilter();
            this._updateChangesToUI();
        });
    }

    private _initEntityTypes() {
        this._store.pipe(select(availableEntityTypeSelector),
            filter(res => res != null),
            take(1)
        ).subscribe(res => this._takeEntityType(res));
    }

    /**
    * Judge nearby filters for send request
    *
    * @private
    * @param {*} nearbyParams
    * @memberof TamDashboardMapNearbyComponent
    */
    private _judgeNearbyData(nearbyParams) {
        this._entityNearbyData = [];
        this._eventNearbyData = [];
        if (this.sourceName === EMPTY_SOURCE_NAME && this.entityName === EMPTY_SOURCE_NAME) {
            this._toastService.notify(this._infoToast);
            this.eventSubject$.emit({ type: AutocompleteActionType.noData, payload: this.location });
        } else if (this.entityName === EMPTY_SOURCE_NAME && this.sourceName !== EMPTY_SOURCE_NAME) {
            // send get google
            this._dashboardTileService.getMapSearchNearby(nearbyParams, this, this._getPredictions);
        } else if (this.entityName !== EMPTY_SOURCE_NAME) {
            // send get entity
            this._getNearbyData(nearbyParams);
        }
    }

    private _noDataTrigerEvent(): void {
        this._toastService.notify(this._infoToast);
        const targetEvent = this._getCurrentFocusedEventByDuplicateItems(this.location);
        this.eventSubject$.emit({ type: AutocompleteActionType.noData, payload: targetEvent });
    }

    /*
     * call back function for getMapSearchPredictions
     */
    protected _getPredictions(predictions) {
        if (predictions && predictions.length > 0) {
            this.eventSubject$.emit({ type: AutocompleteActionType.showNearby, payload: [this._entityNearbyData, predictions, this.location, this._eventNearbyData] });
        } else {
            this._toastService.notify(this._infoToast);
            this.eventSubject$.emit({ type: AutocompleteActionType.noData, payload: this.location });
        }
    }

    private _setDefaultValueForFilter() {
        // get event type filter
        const eventTypeFilter = this._mapFilterService.getNewFilterDefaultInstance('Event Type', 'EventType');
        this.filterEventConfig.filtersConfigList.push(eventTypeFilter);
        // get start time filter
        const startTimeFilter = this._mapFilterService.getNewFilterDefaultInstance('Start Time', 'StartTime');
        startTimeFilter.deletable = true;
        this.filterEventConfig.filtersConfigList.push(startTimeFilter);
    }

    private _setNoChangeFilterData() {
        if (!this._mapfilterModel.filters[0]) {
            this._mapfilterModel.filters[0] = new MapFilterContent();
            this._mapfilterModel.filters[0].field = businessConstants.common.contact;
        }
        if (!this._mapfilterModel.filters[1]) {
            this._mapfilterModel.filters[1] = new MapFilterContent();
            this._mapfilterModel.filters[1].field = businessConstants.common.adhoc;
        }
        if (!this._mapFilterModelForEvent.filters[0]) {
            this._mapFilterModelForEvent.filters[0] = new MapFilterContent();
            this._mapFilterModelForEvent.filters[0].field = businessConstants.common.entry;
        }
    }

    private _takeEntityType(res) {
        ArrayHelperService.sort(res, 'name');
        this.entityTypeData = this.entityTypeData.concat(res.slice());
        this._sourceIds = this.entityTypeData.map(item => item.id);
    }

    private _updateChangesToUI() {
        if (!this._changeDetectorRef[businessConstants.common.changeDetectorDestroyedIdentifier]) {
            this._changeDetectorRef.detectChanges();
        }
    }
}
