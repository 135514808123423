import { Component, OnInit, ViewChild, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ContextMenuService, ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { ContextMenuEventTypes, ContextMenuClickEvent, ContextMenuShowAction, ContextMenuConfig } from './tam-context-menu.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tam-context-menu',
    templateUrl: './tam-context-menu.component.html',
    styleUrls: ['./tam-context-menu.component.scss']
})
export class TamContextMenuComponent implements OnInit, OnDestroy {
    @Input() config: ContextMenuConfig;
    @ViewChild('tamMenu', { static: false }) menuEle: ContextMenuComponent<any>;

    private data: any;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(private contextMenuService: ContextMenuService<any>, private _ref: ChangeDetectorRef) { }

    ngOnInit() {
        this._destroySubscriptions.push(
            this.config.show$.subscribe(e => this.showMenu(e)),
        );
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    emptyClick($event) {
        if ($event.preventDefault) {
            $event.preventDefault();
        }
        if ($event.stopPropagation) {
            $event.stopPropagation();
        }
    }

    onItemClick($event, menuItem) {
        if (menuItem.disabled) {
            return;
        }

        let itemData = this.data;
        if ($event.item) {
            itemData = $event.item;
        }
        let event;
        if ($event.event) {
            event = $event.event;
        } else {
            event = $event;
        }
        this.config.events$.next({
            type: ContextMenuEventTypes.click,
            payload: new ContextMenuClickEvent(menuItem.id, itemData, event),
        });
    }

    onItemMousedown($event) {
        if (this.config.eventKey) {
            $event[this.config.eventKey] = this.config.eventValue;
        }
    }

    onPassiveItemClick($event, menuItem) {
        if (menuItem.disabled) {
            return;
        }
        menuItem.checked = !menuItem.checked;
        this.config.events$.next({
            type: ContextMenuEventTypes.checkChanged,
            payload: { id: menuItem.id, checked: menuItem.checked }
        });
    }

    showMenu(action: ContextMenuShowAction) {
        if (this.config.forceRefresh) {
            this._ref.detectChanges();
        }
        this.contextMenuService.show(this.menuEle, { x: action.event.clientX, y: action.event.clientY });
        this.data = action.data;
        action.event.preventDefault();
        action.event.stopPropagation();
    }

}
