/**
 * Created by Yu Zhang on 5/10/17.
 * Description:
 *
 * ------ maintenance history ------
 *  ver1.1: modified by Alan Yang 7/19/17.
 *         added entityListReducer
 *  ver1.2: modified by Alan Yang 7/32/17.
 *         added entityReducer
 *  ver1.3: modified by Alan Yang 8/07/17.
 *         added templateConfigReducer
 */
import {
    CHANGE_ENTITY_FOCUS, CLEAR_ENTITY_FOCUS, GET_SLIDESHEET_ENTITY_SUCCESS,
    GET_SLIDESHEET_ENTITY_FAILURE, GET_SLIDESHEET_TEMPLATE_CONFIG_SUCCESS,
    GET_SLIDESHEET_TEMPLATE_CONFIG_FAILURE, CLEAN_SLIDESHEET_TEMPLATE_CONFIG
} from './app.actions';
import {
    GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_SUCCESS, GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_SUCCESS,
    GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_FAILURE, GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_FAILURE,
    CLEAN_ENTITY_SEARCH_LIST, CLEAN_ENTITY_SEARCH_LIST_MSG
} from './thread-list.actions';
import { GET_ENTITY_SUCCESS, GET_ENTITY_FAILURE, CLEAN_ENTITY } from './app.actions';
import { GET_TEMPLATE_CONFIG_SUCCESS, GET_TEMPLATE_CONFIG_FAILURE, CLEAN_TEMPLATE_CONFIG } from './app.actions';
import {
    CLEAR_ENTITY_MSG, CREATE_ENTITY_SUCCESS, CREATE_ENTITY_FAILURE,
    EDIT_ENTITY_SUCCESS, EDIT_ENTITY_FAILURE
} from './entity.actions';
import { Entity } from '../models/entity.model';
import { EntityList } from '../models/entity-list.model';

export const NULL_ENTITY = 'NULL_ENTITY';

export function entityFocusReducer(state: string = '', action) {
    switch (action.type) {
        case CHANGE_ENTITY_FOCUS:
            return action.payload;
        case CLEAR_ENTITY_FOCUS:
            return NULL_ENTITY;
        default:
            return state;
    }
}


export function createntityReducer(state: Entity = null, action) {
    switch (action.type) {
        case CREATE_ENTITY_SUCCESS:
            return action.payload;
        case CREATE_ENTITY_FAILURE:
            return null;
        default:
            return state;
    }
}

export function entityReducer(state: Entity = null, action) {
    switch (action.type) {
        case GET_ENTITY_SUCCESS:
            // case EDIT_ENTITY_SUCCESS: // Keep this line for future refactor, need change to EDIT_ENTITY_ALL_SUCCESS
            return action.payload;
        case EDIT_ENTITY_FAILURE:
        case GET_ENTITY_FAILURE:
        case CLEAN_ENTITY:
            return null;
        default:
            return state;
    }
}

export function slideSheetEntityReducer(state: Entity = null, action) {
    switch (action.type) {
        case GET_SLIDESHEET_ENTITY_SUCCESS:
            return action.payload;
        case GET_SLIDESHEET_ENTITY_FAILURE:
            return null;
        default:
            return state;
    }
}

export function entityListReducer(state: EntityList = new EntityList(), action) {
    switch (action.type) {
        case GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_SUCCESS:
            return action.payload;
        case GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_SUCCESS: {
            const entityList = new EntityList();
            entityList.entities = state.entities.concat(action.payload.entities);
            entityList.next = action.payload.next;
            return entityList;
        }
        case GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_FAILURE:
        case GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_FAILURE:
        case CLEAN_ENTITY_SEARCH_LIST:
            return [];
        default:
            return state;
    }
}

export function entityMsgReducer(state: string = '', action) {
    switch (action.type) {
        case GET_ENTITY_SUCCESS:
        case GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_SUCCESS:
        case GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_SUCCESS:
        case CREATE_ENTITY_SUCCESS:
        case EDIT_ENTITY_SUCCESS:
            return action.type;
        case GET_ENTITY_FAILURE:
        case GET_ENTITY_FIRST_PAGE_WITH_KEYWORD_FAILURE:
        case GET_ENTITY_NEXT_PAGE_WITH_KEYWORD_FAILURE:
        case CREATE_ENTITY_FAILURE:
        case EDIT_ENTITY_FAILURE:
            return `${action.type}: ${action.payload}`;
        case CLEAR_ENTITY_MSG:
        case CLEAN_ENTITY_SEARCH_LIST_MSG:
            return '';
        default:
            return state;
    }
}

export function slideSheetEntityMsgReducer(state: string = '', action) {
    switch (action.type) {
        case GET_SLIDESHEET_ENTITY_SUCCESS:
            return action.type;
        case GET_SLIDESHEET_ENTITY_FAILURE:
            return `${action.type}: ${action.payload}`;
        default:
            return state;
    }
}


export function templateConfigReducer(state: any = null, action) {
    switch (action.type) {
        case GET_TEMPLATE_CONFIG_SUCCESS:
        case GET_TEMPLATE_CONFIG_FAILURE:
            return action.payload;
        case CLEAN_TEMPLATE_CONFIG:
            return null;
        default:
            return state;
    }
}

export function templateSlideSheetConfigReducer(state: any = null, action) {
    switch (action.type) {
        case GET_SLIDESHEET_TEMPLATE_CONFIG_SUCCESS:
        case GET_SLIDESHEET_TEMPLATE_CONFIG_FAILURE:
            return action.payload;
        case CLEAN_SLIDESHEET_TEMPLATE_CONFIG:
            return null;
        default:
            return state;
    }
}
