import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetsModule } from '../../widgets/widgets.module';
import { ThreadDetailComponent } from './thread-detail.component';
import { AttachmentListModule } from '../attachment-list/attachment-list.module';
import { NoteDetailModule } from '../note-detail/note-detail.module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    imports: [
        CommonModule,
        WidgetsModule,
        AttachmentListModule,
        NoteDetailModule,
        TooltipModule,
    ],
    declarations: [
        ThreadDetailComponent,
    ],
    exports: [
        ThreadDetailComponent,
    ]
})
export class ThreadDetailModule { }
