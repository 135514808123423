/**
 * Description:
 *
 * ------ maintenance history ------
 * 08/30/2019 Yoyo Fang - do not display spinner if loading time less than 1s.
 */
import { Component, OnInit, Input, ElementRef, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
    // tslint:disable-next-line: component-selector
    selector: '[loading-spin]',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingSpinnerComponent implements OnInit {

    loadingClass: string;
    private _isLoading: boolean;
    public show = false;
    private _pendingTask = null;
    private _loadingSize: string;
    private _loadingTransparent: string;
    private element: HTMLElement;
    private _zIndex: number;

    @Input()
    set isLoading(value: boolean) {
        this._isLoading = value;
        if (this._isLoading) {
            this.renderer.setStyle(this.element, 'position', 'relative');
            this.detectChange();
            // TPM requested on 08292019
            this.delayShow(1000);
        } else {
            this.renderer.removeStyle(this.element, 'postition');
            this.canceldShow();
            this.detectChange();
        }
    }

    get isLoading(): boolean {
        return this._isLoading;
    }

    @Input()
    set loadingSize(value: string) {
        this._loadingSize = value;
        switch (this._loadingSize) {
            case 'small':
            case 'normal':
            case 'big':
            case 'inline':
                this._loadingSize = 'loading-bar-container-' + this._loadingSize;
        }
        this.detectChange();
    }

    @Input()
    set loadingTransparent(value: boolean) {
        if (value) {
            this._loadingTransparent = 'loadingtransparent';
            this.detectChange();
        }
    }

    @Input()
    set loadingLevel(value: number) {
        if (value == null) {
            this._zIndex = 100;
        } else {
            this._zIndex = value;
        }
        this.detectChange();
    }

    get loadingLevel(): number {
        return this._zIndex;
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private _changeDetectorRef: ChangeDetectorRef) {
        this.element = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.loadingClass = this._loadingSize + ' ' + this._loadingTransparent;
    }

    private delayShow(ms: number) {
        this._pendingTask = setTimeout(() => {
            this.show = true;
            this._pendingTask = null;
            this.detectChange();
        }, ms);
    }

    private canceldShow() {
        this.show = false;
        if (this._pendingTask) {
            clearTimeout(this._pendingTask);
            this._pendingTask = null;
        }
    }

    private detectChange() {
        if (!this._changeDetectorRef['destroyed']) {
            setTimeout(() => this._changeDetectorRef.detectChanges());
        }
    }
}
