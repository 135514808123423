<div class="category" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" kendoTooltip position="bottom">
    <div class="item-header" title="{{dataConfig.name}}">
        <div class="left-header" (click)="collapse()">
            <smart-icon class="angle-icon light" name="{{dataConfig.status.collapsed ? 'angle-down' : 'angle-up'}}">
            </smart-icon>
            <div class="item-name">{{dataConfig.name}}</div>
        </div>
        <div class="right-header" [style.visibility]="isHoverOn?'visible':'hidden'">
            <smart-icon class="angle-icon light" name="meatball" (click)="onMeatball($event)"></smart-icon>
        </div>
    </div>
    <div class="item-content" [hidden]="dataConfig.status.collapsed">
        <div *ngIf="selectedOptions && selectedOptions.length > 0"
            [ngClass]="{'show-line': options && options.length > 0}">
            <div *ngFor="let option of selectedOptions">
                <div class="option-item" (click)="changeSelectedValue(option, $event)">
                    <div class="item-ckb">
                        <smart-icon name="check" class="light"></smart-icon>
                    </div>
                    <div class="name-count-panel" kendoTooltip position="bottom">
                        <div class="option-name" [ngClass]="{'blank':!option[displayField]}"
                            title="{{option[displayField]}}">
                            <span>{{!option[displayField]?'(Blank)':option[displayField]}}</span>
                        </div>
                        <div class="option-count">{{option.count}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="options && options.length > 0">
            <div *ngFor="let option of options | slice:0:(dataConfig.status.showMore?options.length:optionLimit)">
                <div class="option-item" (click)="changeSelectedValue(option, $event)">
                    <div class="item-ckb">
                        <smart-icon name="check" [style.display]="option.checked?'':'none'"></smart-icon>
                    </div>
                    <div class="name-count-panel" kendoTooltip position="bottom">
                        <div class="option-name" [ngClass]="{'blank':!option[displayField]}"
                            title="{{option[displayField]}}">
                            <span>{{!option[displayField]?'(Blank)':option[displayField]}}</span>
                        </div>
                        <div class="option-count">{{option.count}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-less" *ngIf="options && options.length > optionLimit" (click)="onShowMore()">
            <span>{{dataConfig.status.showMore?'Show less':'Show more'}}</span>
        </div>
    </div>
    <div *ngIf="(!options || options.length === 0)&&(!selectedOptions || selectedOptions.length === 0)"
        class="no-options">No options available.</div>
</div>