/**
 * Created by Yoyo Fang on 07/30/2019.
 * Description:
 *      research screen initialization related helper functions
 *
 * ------ maintenance history ------
 *
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResearchViewInfo, FinderInfo, FilterInfo } from '../pages/research/research.view-model';
import { businessConstants } from '../constants/business.constants';
import { ResearchFocusStatus, FinderSetting } from '../components/finder/finder.view-model';
import { getScreenDictionarySelector } from '../redux/reducers/screen.reducer';
import { StoreQuerierService } from './store-querier.service';
import { EntityService } from '../tamalelibs/services/entity.service';
import { ScreenService } from '../tamalelibs/services/screen.service';
import { FilterService } from '../tamalelibs/services/filter.service';
import { HomeScreenService } from './home-screen.service';
import { ResearchHelperService } from './research-helper.service';
import { map } from 'rxjs/operators';
import { FiltersConfig, FilterMetadataType } from '../tamalelibs/components/filter-config';
import { Entity } from '../tamalelibs/models/entity.model';
import { RelationshipTreeService } from './relationship-tree.service';
import { EntityBrief } from '../tamalelibs/models/entity-brief.model';
import { ThreadEntry } from '../tamalelibs/models/thread-entry.model';

@Injectable()
export class ResearchScreenService {

    constructor(
        private _storeQuerier: StoreQuerierService,
        private _entityService: EntityService,
        private _screenService: ScreenService,
        private _filterService: FilterService,
        private _homeScreenService: HomeScreenService,
        private _researchHelper: ResearchHelperService,
    ) {
    }

    getHasDeletePermissionThreadEntryIds(selectedItems: Array<ThreadEntry>) {
        const hasDeletePermissionThreadIds = new Array<string>();
        if (selectedItems && selectedItems.length > 0) {
            selectedItems.forEach(item => {
                if (item.threadEntryDeleable) {
                    hasDeletePermissionThreadIds.push(item.id);
                }
            });
        }
        return hasDeletePermissionThreadIds;
    }

    getSelectedItems(threadList: Array<ThreadEntry>, selectedIndexes: Set<number>) {
        const tempArray: Array<number> = Array.from(selectedIndexes.values());
        tempArray.sort((a: number, b: number) => {
            return a - b;
        });
        const tempList: Array<ThreadEntry> = [];
        tempArray.forEach((value, value2, set) => {
            tempList.push(threadList[value]);
        });
        return tempList;
    }

    isDeletable(thread) {
        const flag = thread.notes.find(note => {
            return note.deletable === false;
        });
        if (flag) {
            return false;
        } else {
            return true;
        }
    }
    // PREPARE_SCREEN/LOAD_CONFIGURATION -> MAP_CONFIGURATION -> INIT_OTHERS
    //                                                        -> PREPARE FILTER ->INIT_FILTER
    //                                                        -> PREPARE FOCUS ->INIT_FOCUS INIT_RELATIONSHIP
    loadScreenConfig(page, user): Observable<any> {
        return this._loadConfiguration(page.screenId, user, page.configuration);
    }

    mapScreenConfig(config, page, user): Observable<ResearchViewInfo> {
        return this._mapConfiguration(config, page, user);
    }

    loadFilterFromConfig(config): Observable<FilterInfo> {
        return this._loadFilterEntities(this._loadFilterOperators(config));
    }

    loadFinderFromConfig(config, page, user): Observable<any> {
        return this._loadFinderInfo(config, page, user);
    }

    loadFocusFromFinder(config, finder): FinderSetting {
        return this._mapFinderToFocus(config, finder);
    }

    loadRelationshipFromFinder(config, page, user, finder): any {
        return this._loadRelationship(config, page, user, finder);
    }

    private _loadConfiguration(screenId, user, pageConfig): Observable<any> {
        // prepare configuration wait
        if (screenId === businessConstants.allNotesScreenId) {
            if (!pageConfig || (pageConfig && JSON.stringify(pageConfig) === '{}')) {
                pageConfig = new ResearchViewInfo();
            }
            pageConfig.finderInfo = {
                entityId: user.id,
                status: ResearchFocusStatus.ALL
            };
            pageConfig.relationshipTreeInfo = {
                checkedNodeKeys: [],
                showAs: 'shortName',
            };
            pageConfig.layoutInfo.isEntityFocusHidden = true;
            pageConfig.layoutInfo.isRelationshipTreeHidden = true;
            pageConfig.layoutInfo.isEntityFocusDisable = true;
            pageConfig.layoutInfo.isRelationshipTreeDisable = true;
            return of({
                id: businessConstants.allNotesScreenId,
                name: 'All Notes',
                configuration: pageConfig
            });
        } else if (screenId === businessConstants.myNotesScreenId) {
            if (!pageConfig || (pageConfig && JSON.stringify(pageConfig) === '{}')) {
                pageConfig = new ResearchViewInfo();
            }
            pageConfig.finderInfo = {
                entityId: user.id,
                status: ResearchFocusStatus.NONE
            };
            pageConfig.relationshipTreeInfo = {
                checkedNodeKeys: [],
                showAs: 'shortName',
            };
            pageConfig.layoutInfo.isEntityFocusHidden = true;
            pageConfig.layoutInfo.isRelationshipTreeHidden = true;
            pageConfig.layoutInfo.isEntityFocusDisable = true;
            pageConfig.layoutInfo.isRelationshipTreeDisable = true;
            return of({
                id: businessConstants.myNotesScreenId,
                name: 'My Notes',
                configuration: pageConfig
            });
        } else {
            if (screenId && screenId.indexOf(businessConstants.homeScreen.prefixNewResearchViewID) === -1) {
                const savedScreenDictionary = this._storeQuerier.queryBySelector(getScreenDictionarySelector);
                if (savedScreenDictionary.has(screenId)) {
                    return of(savedScreenDictionary.get(screenId));
                } else {
                    return this._screenService.getById(screenId);
                }
            } else {
                return of(pageConfig);
            }
        }
    }

    private _mapConfiguration(rawConfig, page, user): Observable<ResearchViewInfo> {
        // prepare configuration again wait

        // 1. loading the configuration from current page if existing;
        // 2. loading and converting the configuration by screenId if the configuration version is 2.0;
        // 3. loading the configuration by screenId;
        if (page.configuration && JSON.stringify(page.configuration) !== '{}') {
            if (page.configuration.version === '2.0') {
                return this._homeScreenService.convertResearchViewInfoV2toV3(page.configuration, page.screenId);
            } else {
                if (page.configuration) {
                    this._homeScreenService.SetFocusRelationshipDiablesStatus(page.configuration, page.screenId);
                }
                return of(page.configuration);
            }
        } else {
            if (rawConfig) {
                if (rawConfig.configuration && rawConfig.configuration.version === '2.0') {
                    return this._homeScreenService.convertResearchViewInfoV2toV3(rawConfig.configuration, rawConfig.screenId);
                } else {
                    if (rawConfig.configuration) {
                        this._homeScreenService.SetFocusRelationshipDiablesStatus(rawConfig.configuration, rawConfig.screenId);
                    }
                    return of(rawConfig.configuration);
                }
            } else {
                const configuration = new ResearchViewInfo();
                const finderInfo: FinderInfo = {
                    entityId: user.id,
                    status: ResearchFocusStatus.ALL
                };
                configuration.finderInfo = finderInfo;
                configuration.relationshipTreeInfo = {
                    checkedNodeKeys: [],
                    showAs: 'shortName',
                };
                this._homeScreenService.SetFocusRelationshipDiablesStatus(configuration, page.screenId);

                return of(configuration);
            }
        }
    }

    private _loadFilterOperators(researchViewInfo: ResearchViewInfo): ResearchViewInfo {
        if (researchViewInfo.filterInfo && researchViewInfo.filterInfo.filterConfigs) {
            researchViewInfo.filterInfo.filterConfigs.forEach((config: FiltersConfig) => {
                config.operators = this._filterService.getOperatorOptions(config.metadataType);
            });
        }
        return researchViewInfo;
    }

    private _loadFilterEntities(researchViewInfo: ResearchViewInfo): Observable<FilterInfo> {
        if (researchViewInfo && researchViewInfo.filterInfo) {
            if (!researchViewInfo.filterInfo.filterConfigs || researchViewInfo.filterInfo.filterConfigs.length === 0) {
                return of(researchViewInfo.filterInfo);
            } else {
                const entityIds = [];
                researchViewInfo.filterInfo.filterConfigs.forEach(item => {
                    if (item.metadataType === FilterMetadataType.Entities ||
                        item.metadataType === FilterMetadataType.Source ||
                        item.metadataType === FilterMetadataType.Submitter) {
                        item.value.forEach(val => {
                            entityIds.push(val.id);
                        });
                    }
                });
                if (entityIds.length === 0) {
                    return of(researchViewInfo.filterInfo);
                } else {
                    return this._entityService.getEntityListByIdsQuick(entityIds).pipe(
                        map(response => this._entityService.mapEntityBriefList(response)),
                        map(entities => {
                            const entityMap = new Map();
                            entities.forEach(element => entityMap.set(element.id, element));
                            return entityMap;
                        }),
                        map(entityMap => {
                            researchViewInfo.filterInfo.filterConfigs.forEach(item => {
                                if (item.metadataType === FilterMetadataType.Entities ||
                                    item.metadataType === FilterMetadataType.Source ||
                                    item.metadataType === FilterMetadataType.Submitter) {
                                    item.value = item.value.map(element => {
                                        if (entityMap.has(element.id)) {
                                            return entityMap.get(element.id);
                                        } else {
                                            return element;
                                        }
                                    });
                                }
                            });
                            return researchViewInfo.filterInfo;
                        })
                    );
                }
            }
        } else {
            return of(null);
        }
    }

    private _loadFinderInfo(researchViewInfo: ResearchViewInfo, page, user): Observable<any> {
        if (!researchViewInfo.finderInfo) {
            researchViewInfo.finderInfo = {
                entityId: user.id,
                status: ResearchFocusStatus.NONE,
            };
        } else if (!researchViewInfo.finderInfo.entityId) {
            researchViewInfo.finderInfo.entityId = user.id;
        }

        return this._entityService.getEntityDetailWithoutAdhoc(researchViewInfo.finderInfo.entityId);
    }

    private _mapFinderToFocus(researchViewInfo: ResearchViewInfo, finderEntity: any): FinderSetting {
        // entity focus
        const finderSetting: FinderSetting = {
            entity: EntityBrief.parse(finderEntity),
            status: researchViewInfo.finderInfo.status
        };

        return finderSetting;
    }


    private _loadRelationship(researchViewInfo: ResearchViewInfo, page, user, finderEntity): any {
        // relationship tree (depends on entity focus)
        const entity = Entity.parse(finderEntity);
        const nodeData = RelationshipTreeService.parseToTreeViewModel(entity);
        const expandedKeys = nodeData.filter(node => !node.isLeaf).map(node => node.uuid);
        let checkedKeys = [];
        if (researchViewInfo.finderInfo && researchViewInfo.finderInfo.status === ResearchFocusStatus.ONE_DEGREE) {
            checkedKeys = nodeData.map(node => node.uuid);
        } else {
            if (researchViewInfo.relationshipTreeInfo && researchViewInfo.relationshipTreeInfo.checkedNodeKeys) {
                checkedKeys = this._researchHelper.restoreCheckedKeys(researchViewInfo.relationshipTreeInfo.checkedNodeKeys, nodeData);
            }
        }

        const relationship = {
            nodes: nodeData,
            expandedKeys: expandedKeys,
            checkedKeys: checkedKeys,
            showAs: researchViewInfo.relationshipTreeInfo && researchViewInfo.relationshipTreeInfo.showAs ? researchViewInfo.relationshipTreeInfo.showAs : 'shortName'
        };
        return relationship;
    }
}
