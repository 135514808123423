import { Component, OnInit, ViewChild, OnDestroy, Input, ElementRef, Renderer2 } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppState } from '../../redux';
import { AuthActionTypes } from '../../redux/actions/auth.actions';
import { AuthStatusTypes } from '../../redux/reducers/auth.reducer';
import { LoginFormConfig } from './login-form.config';
import { UserMessages } from '../../constants/userMessages.constants';
import { AdminUserNames } from '../../constants/business.constants';
import { StorageService } from '../../services/storage.service';
import { AppLoadService } from '../../app-load.service';
import { AddInUtilities } from '../../services/utilities/addin-utilities';
import { GlobalService } from '../../tamalelibs/services/global.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {
    @Input() config: LoginFormConfig;

    alertMessage: string;
    contactEmail: string;
    hasError = false;
    hasNotice = false;
    isAdminLoginForm = false;
    isWebPluginLoginForm = false;
    isBusy = false;
    password: string;
    username: string;
    webPluginVersion: string;
    @ViewChild(NgForm, { static: true }) inputForm;
    @ViewChild('usernameInput', { static: true }) usernameInput: ElementRef;

    private _authenticationSubscription$: Subscription;
    private _el: HTMLElement;
    private _formChangeSubscription$: Subscription;

    constructor(
        private _appLoadService: AppLoadService,
        private _element: ElementRef,
        private _renderer: Renderer2,
        private _router: Router,
        private _store: Store<AppState>,
        private _storageService: StorageService) {
        this._el = this._element.nativeElement;
    }

    ngOnInit() {
        this.contactEmail = 'tamalesupport@advent.com';
        this.isAdminLoginForm = this._router.url.toLowerCase().indexOf('admin-login') > -1;
        this.isWebPluginLoginForm = this._router.url.toLowerCase().indexOf('webplugin') > -1;
        // to solve TAM-25041 the error message page flashes away when firstly input incorrect login account.
        // direct to login page before user click log in button
        if (this.isWebPluginLoginForm) {
            this._storageService.setItem('isFromPlugin', true);
            this._router.navigateByUrl('login');
            return;
        }
        if (this._storageService.getItem('isFromPlugin')) {
            this.isWebPluginLoginForm = true;
            this._storageService.removeItem('isFromPlugin');
        }

        if (AddInUtilities.isOfficeJSLoaded()) {
            this._renderer.addClass(this._el.querySelector('.container'), 'addin_container');
        }

        this._authenticationSubscription$ = this._store.pipe(
            select('auth'),
        ).subscribe(state => {
            switch (state.status) {
                case AuthStatusTypes.LOGIN_SUCCESS:
                    this.hasError = false;
                    this.isBusy = false;
                    this.config.eventSubject$.next();
                    break;
                case AuthStatusTypes.SESSION_TIMEOUT:
                    this.usernameInput.nativeElement.focus();
                    this.hasError = true;
                    this.alertMessage = state.errorMessage;
                    this.isBusy = false;
                    break;
                case AuthStatusTypes.LOGIN_FAILED:
                    this.hasError = true;
                    this.alertMessage = state.errorMessage;
                    this.isBusy = false;
                    break;
                case AuthStatusTypes.LOGOUT:
                    this.hasNotice = true;
                    this.alertMessage = UserMessages.onLogout;
                    this.usernameInput.nativeElement.focus();
                    break;
                default:
                    this.hasError = false;
                    this.isBusy = false;
                    this.hasNotice = false;
            }
        });

        this._formChangeSubscription$ = this.inputForm.form.valueChanges.subscribe(formValue => {
            if (formValue.name || formValue.password) {
                this.hasError = false;
                this.hasNotice = false;
                this.alertMessage = '';
            }
        });
    }

    ngOnDestroy() {
        if (this._authenticationSubscription$) {
            this._authenticationSubscription$.unsubscribe();
        }
        if (this._formChangeSubscription$) {
            this._formChangeSubscription$.unsubscribe();
        }
    }

    login() {
        if (this.inputForm.form.invalid === true) {
            this._store.dispatch({
                type: AuthActionTypes.LOGIN_FAILED,
                payload: UserMessages.onLoginInvalid
            });
            return;
        }

        if (this.isAdminLoginForm && !AdminUserNames.includes(this.username)) {
            this._store.dispatch({
                type: AuthActionTypes.LOGIN_FAILED,
                payload: UserMessages.onNeedAdminLogin
            });
            return;
        }
        this.isBusy = true;
        // to run login logic if the web token des key is not empty
        if (this._appLoadService.getWebTokenDesKey) {
            this._store.dispatch({
                type: AuthActionTypes.LOGIN,
                payload: {
                    username: this.username,
                    password: this.password,
                    isForPlugin: this.isWebPluginLoginForm
                }
            });
        } else {
            // to get the web token des key if it's empty
            this._appLoadService.initSsoValue().then(() => {
                this._store.dispatch({
                    type: AuthActionTypes.LOGIN,
                    payload: {
                        username: this.username,
                        password: this.password,
                        isForPlugin: this.isWebPluginLoginForm
                    }
                });
            });
        }
    }
}
