import { businessConstants } from './../constants/business.constants';

export enum Alignment {
    RIGHT = 'right',
    LEFT = 'left'
}

export class TileColumn {
    columnType: TileColumnType;
    settings: TileColumnSetting;
    default?: boolean;
    field: string;
    hidden: boolean;
    dataType: TileColumnDataType;
    name: string;
    guid: string;
    title: string;
    top?: number;
    formattingConfig: any;
    label?: string;
    fixDataType?: boolean;
    attrDefId?: string;
    id?: string; // Adhoc id
    type?: TileColumnAdhocType;
    config?: TileColumnConfig;
    relationshipType?: string;
    // 0 serach without relationship; 1 include relationship.
    relationshipDisplayType?: number;
    entityTypeFilter?: string;
    showEntitiesSameRow?: boolean;
    fixDisplay?: boolean;
    formula?: string;
    formulaForData?: string;
    groupId?: string;
    groupIndex?: number;
    alignment?: Alignment = Alignment.LEFT;
    conditionalFormattings?: Array<ConditionalFormatting>;
}

export enum RelationshipDisplayType {
    EXCLUDE,
    INCLUDE
}

export enum TileColumnType {
    CONTACT_COLUMN = 'contactColumn',
    ENTITY_COLUMN = 'entityColumn',
    ENTRY_COLUMN = 'entryColumn',
    RELATIONSHIP_COLUMN = 'Relationship',
    MDL_COLUMN = 'MDL',
    FORMULA_COLUMN = 'Formula',
}

export class TileColumnSetting {
    display?: string;
    scale?: string;
    decimalPlaces?: string;
    negatives?: number;
    multiplyBy100?: boolean;
}

export enum TileColumnDataType {
    ENTITY = 'entity',
    TEXT = 'text',
    DATE = 'date',
    NUMBER = 'number',
    CURRENCY = 'currency',
    PERCENT = 'percent',
    RATIO = 'ratio',
}

export class ConditionalFormatting {
    formula: string;
    formulaForData: string;
    id: string;
    color?: string;
    backgroundColor?: string;

    constructor() {
        this.formula = '';
        this.formulaForData = '';
        this.id = '';
    }

}

export enum TileColumnAdhocType {
    DB = 'db',
}

export class TileColumnConfig {
    parameterValue: string;
    isMultipleMDL?: boolean;
    provider?: string;
    providerType?: number;
}

export class TileColumnGroupSetting {
    display: number;
    id: string;
    filters: any;

    constructor(id: string) {
        this.display = 2;
        this.id = id;
    }

}

export const defaultShortName: TileColumn = {
    field: 'entity__short_name',
    name: 'Short Name',
    title: 'Short Name',
    dataType: TileColumnDataType.ENTITY,
    columnType: TileColumnType.ENTITY_COLUMN,
    hidden: false,
    settings: {
        display: '0',
    },
    default: true,
    guid: businessConstants.Dashboard.ShortName_Entity_Id,
    formattingConfig: {},
    alignment: Alignment.LEFT
};

export const defaultLongName: TileColumn = {
    field: 'entity__name',
    name: 'Long Name',
    title: 'Long Name',
    dataType: TileColumnDataType.ENTITY,
    columnType: TileColumnType.ENTITY_COLUMN,
    hidden: false,
    settings: {
        display: '1',
    },
    default: true,
    guid: 't2bfe55d69c940e1b140cb30afd29926',
    formattingConfig: {},
    alignment: Alignment.LEFT
};

export const defaultSource: TileColumn = {
    'field': 'entry__source_id',
    'name': 'Source',
    'title': 'Source',
    'fixDisplay': true,
    'dataType': TileColumnDataType.ENTITY,
    'top': 0,
    'columnType': TileColumnType.ENTRY_COLUMN,
    'guid': 'a0b',
    'settings': { 'display': '0' },
    'hidden': false,
    'formattingConfig': {}
};

export const defaultSubmitter: TileColumn = {
    'field': 'entry__submitter_id',
    'name': 'Submitter',
    'title': 'Submitter',
    'fixDisplay': true,
    'dataType': TileColumnDataType.ENTITY,
    'top': 0,
    'columnType': TileColumnType.ENTRY_COLUMN,
    'guid': 'a0c',
    'settings': { 'display': '0' },
    'hidden': false,
    'formattingConfig': {}
};

export const defaultEntryClass: TileColumn = {
    'field': 'entry__class',
    'name': 'EntryClass',
    'title': 'EntryClass',
    'label': 'EntryClass',
    'dataType': TileColumnDataType.TEXT,
    'fixDataType': true,
    'columnType': TileColumnType.ENTRY_COLUMN,
    'guid': '',
    'settings': {
        display: '0',
    },
    'hidden': true,
    'formattingConfig': {}
};

export const EMPTY_MDL_COLUMN: TileColumn = {
    columnType: TileColumnType.MDL_COLUMN,
    settings: {},
    field: '',
    hidden: false,
    dataType: TileColumnDataType.TEXT,
    name: 'MDL',
    guid: '',
    title: 'MDL',
    formattingConfig: {},
    config: {
        parameterValue: '',
        isMultipleMDL: false,
        provider: '',
        providerType: null,
    },
};

export const EMPTY_RELATIONSHIP_COLUMN: TileColumn = {
    columnType: TileColumnType.RELATIONSHIP_COLUMN,
    settings: {
        display: '0',
    },
    field: 'relationship__child_entity_id',
    hidden: false,
    dataType: TileColumnDataType.ENTITY,
    name: 'Relationship',
    guid: '',
    title: 'Relationship',
    formattingConfig: {},
    relationshipType: '',
    config: {
        parameterValue: '',
    },
};

export const EMPTY_FORMULA_COLUMN: TileColumn = {
    columnType: TileColumnType.FORMULA_COLUMN,
    settings: {},
    field: '',
    hidden: false,
    dataType: TileColumnDataType.TEXT,
    name: 'Formula',
    guid: '',
    title: 'Formula',
    formattingConfig: {},
    formula: '',
    formulaForData: '',
};

export const COLORS = [
    '#e57373', '#ba68cb', '#7986cb', '#64b5f6', '#4dd0e1', '#4db6ac', '#81c784', '#aed581', '#fff176',
    '#ffb74d', '#a1887f', '#f5f5f5', '#90a4ae', '#f44336', '#9c27b0', '#3f51b5', '#2196f3', '#00bcd4',
    '#009688', '#4caf50', '#8bc34a', '#ffeb3b', '#ff9800', '#795548', '#9e9e9e', '#607d8b', '#b71c1c',
    '#4a148c', '#1a237e', '#0d47a1', '#006064', '#004d40', '#1b5e20', '#33691e', '#f57f17', '#ff6f00',
    '#3e2723', '#212121', '#263238'];
