
/**
 * Created by Alex Xia on 10/11/18.
 * Description:
 *
 */
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AppConfig } from '../models/app-config.model';
import { TransportService } from './transport.service';

@Injectable()
export class RelationshipTypeService {

    constructor(private _transportService: TransportService) { }

    getAll(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.relationshipTypeEndpoint}`;
        const params = {
            sortby: 'name',
            outputformat: 'json'
        };
        const options = {
            header: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getAllValidRelationship(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.validRelationshipTypeEndpoint}`;
        const params = {
            expand: 'relationship-type;entity-type'
        };
        const options = {
            header: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    createRelationshipType(name: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.relationshipTypeEndpoint}`;
        const params = {
            name: name,
        };
        const options = {
            header: headers,
            params: params
        };
        const formData = { 'name': name };
        return this._transportService.post(url, formData, options);
    }

    deleteOneVaildRelationship(relationshipId: string): Observable<any> {
        const url = `${AppConfig.validRelationshipTypeEndpoint}/${relationshipId}`;
        const options = {
            headers: {
                'Content-Type': 'application/json'
            },
        };
        return this._transportService.delete(url, options);
    }

    createOneValidRelationship(parentId: string, childId: string, relationshipTypeId: string, reciprocalId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
        };
        const url = `${AppConfig.validRelationshipTypeEndpoint}`;
        const params = {
            expand: 'relationship-type;entity-type'
        };
        const options = {
            headers: headers,
            params: params
        };
        const formData = new URLSearchParams();
        formData.set('parent', parentId);
        formData.set('child', childId);
        formData.set('reciprocal', reciprocalId);
        formData.set('relationship', relationshipTypeId);
        return this._transportService.post(url, formData.toString(), options);
    }

    editRelationshipType(name: string, relationshipTypeId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.relationshipTypeEndpoint}${relationshipTypeId}/`;
        const params = {
            name: name,
        };
        const options = {
            header: headers,
            params: params
        };
        const formData = { 'name': name };
        return this._transportService.put(url, formData, options);
    }

}
