/**
 * Created by Abner Sui on 07/03/2019.
 * Description:
 * print service
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Thread } from '../tamalelibs/models/thread.model';
import { PrintActions } from '../components/print/print.model';

@Injectable({
    providedIn: 'root'
})
export class PrintService {

    dialogSubject$: Subject<any> = new Subject();
    actionSubject$: Subject<any> = new Subject();

    open(threadList: Array<Thread>): void {
        this.dialogSubject$.next({
            type: PrintActions.OPEN,
            payload: threadList,
        });
    }

    printThreads(threadList: Array<Thread>, selectedIndexes: Set<number>): void {
        if (threadList && threadList.length && selectedIndexes && selectedIndexes.size) {
            const tempArray: Array<number> = Array.from(selectedIndexes.values());
            tempArray.sort((a: number, b: number) => {
                return a - b;
            });
            const tempList: Array<Thread> = [];
            tempArray.forEach((value, value2, set) => {
                if (threadList[value]) {
                    tempList.push(threadList[value]);
                }
            });
            this.open(tempList);
        }
    }

}
