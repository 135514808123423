<kendo-dialog tid="alert_window" *ngIf="open" (close)="close($event)" [minWidth]="viewMinWidth" [width]="viewWidth"
    [ngClass]="{'threaddetail-sidesheet-status-alertwindow':threaDetailSidesheetStatus,'compact-view': isCompactView}"
    (mousedown)="onClick($event)" (click)="onClick($event)" class="alert-window-container">
    <div class="alert-title"
        [ngClass]="isResetHorizontalMargin?'alert-title-horizontal-margin':'alert-title-default-horizontal-margin'">
        <div class="inlineb alert-title-icon-section">
            <smart-icon [name]='iconName' class="alert-title-icon" [style.color]="iconColor"></smart-icon>
        </div>
        <div class="inlineb alert-title-content"><span class="alert-title-style">{{title}}</span></div>
    </div>
    <div class="alert-body" tid="alert_body"
        [ngClass]="isResetHorizontalMargin?'alert-body-horizontal-margin':'alert-body-default-horizontal-margin'">
        <p class="alert-content"
            [ngStyle]="{'word-break': alertWindowWidth === 650 || isCompactView ? 'break-all' : 'normal' }"
            *ngFor="let content of contents">{{content}}
        </p>
    </div>
    <div *ngIf="!extraButton" class="alert-button">
        <button tam-button tid="cancel_button" [tamType]="closeButton.type" [tamSize]="'normal'" [tamLoading]="false"
            [ngClass]="alertCloseClass" [style.visibility]="closeButton.visible?'visible':'hidden'"
            (click)="close($event)">{{closeButton.text}}</button>
        <button class="fix-button" tam-button tid="confirm_button" [tamType]="confirmButton.type" [tamSize]="'normal'"
            [tamLoading]="false" [style.visibility]="confirmButton.visible?'visible':'hidden'" *ngIf="multipleButton"
            (click)="confirm($event)">{{confirmButton.text}}</button>
    </div>
    <div *ngIf="extraButton" class="alert-button">
        <button [ngClass]="{'extra':!isCompactView,'extraCompactView':isCompactView}" tam-button tid="cancel_button"
            [tamType]="closeButton.type" [tamSize]="'normal'" [tamLoading]="false"
            [style.visibility]="closeButton.visible?'visible':'hidden'"
            (click)="close($event)">{{closeButton.text}}</button>
        <button tam-button tid="confirm_button" [tamType]="confirmButton.type" [tamSize]="'normal'" [tamLoading]="false"
            [ngClass]="alertCloseClass" [style.visibility]="confirmButton.visible?'visible':'hidden'"
            *ngIf="multipleButton" (click)="confirm($event)">{{confirmButton.text}}</button>
        <button class="fix-button" tam-button tid="keep_button" [tamType]="extraButton.type" [tamSize]="'normal'"
            [tamLoading]="false" [style.visibility]="extraButton.visible?'visible':'hidden'" *ngIf="multipleButton"
            (click)="extra($event)">{{extraButton.text}}</button>
    </div>
</kendo-dialog>