/**
 * Created by Alan Yang on 6/20/2017
 * Description:
 * ---------maintenance history-------------
 * ver 1.1 modified by Yu Zhang on 7/3/18
 *    add NoConnectionError
 */
export class RestApiError extends Error {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, RestApiError.prototype);
    }
}

export class DuplicateNameError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, DuplicateNameError.prototype);
    }
}

export class DuplicateIdError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, DuplicateIdError.prototype);
    }
}

export class UnauthorizedError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, UnauthorizedError.prototype);
    }
}

export class ConnectionError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, ConnectionError.prototype);
    }
}

export class NotFoundError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, NotFoundError.prototype);
    }
}

export class WrongVersionError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, WrongVersionError.prototype);
    }
}

export class InValidTokenError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, InValidTokenError.prototype);
    }
}

export class ATSError extends RestApiError {
    constructor(m: string) {
        super(m);
        // App Transport Security policy requires the use of a secure connection

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, ATSError.prototype);
    }
}

export class ServiceUnavailableError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, ServiceUnavailableError.prototype);
    }
}

export class NoConnectionError extends RestApiError {
    constructor(m: string) {
        super(m);

        // Set the prototype explicitely.
        Object.setPrototypeOf(this, NoConnectionError.prototype);
    }
}
