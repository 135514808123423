/**
 * Created by Marcus Zhao on 3/16/2021
 * -------------------------------------
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { FieldType } from '../../../tamalelibs/models/workflow.model';
import { ControlConfigEvents, IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';

@Component({
    selector: 'tam-date-config',
    templateUrl: './date-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class DateConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {

    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;

    dataType = FieldType.DATE;
    staticDefaultValueInvalid = false;

    private _controlType = ControlType.DATE;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    getInvalidDetail(): FieldValidationError {
        return this.base.getInvalidDetail();
    }

    ngOnInit(): void {
        // this._destroySubscriptions.push();
    }

    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        const localValid = true;
        return baseValid && localValid;
    }

    valueChange(event): void {
        this.config.config.feedbackSubject$.next({
            type: ControlConfigEvents.VALUE_CHANGE,
            payload: this.config,
        });
    }
}
