/**
 * Created by Yu Zhang on 3/22/17.
 * Description:
 *
 * ------ maintenance history ------
 * ver1.1: modified by Alan Yang 5/5/17.
 *         added getThreadListWithKeyword.
 *
 * ver 2.0 modified by Yu Zhang on 2/15/18:
 *      use Angular 5 HTTP client
 */


import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { TransportService } from './transport.service';
import { ThreadList } from '../models/thread-list.model';
import { Thread } from '../models/thread.model';
import { NoteEntry } from '../models/note-entry.model';
import { DocumentEntry } from '../models/document-entry.model';
import { AppConfig } from '../models/app-config.model';
import { EntityBrief } from '../models/entity-brief.model';
import { EntryType } from '../models/entry-type.model';
import { ES6MapService } from './es6map.service';
import { businessConstants } from '../constants/business.constants';
import { DateHelperWebService } from './date-helper.web.service';

@Injectable()
export class ThreadService {
    constructor(private _transportService: TransportService, private _es6mapService: ES6MapService) { }

    public getLastMonthNotesNum(id): Observable<any> {
        const url = `${AppConfig.threadEndpoint}`;
        const now = new Date().getTime() - 2592000000;
        const params = {
            // tslint:disable-next-line: max-line-length
            advfilter: '(((submitted-date after "' + now + '") and (submitter id equals "' + id + '")) and ((entry-class equals "note") and ((! entities contains long-name "EMAIL_SPOOLER") and (! entities contains id "' + businessConstants.entity.EMAIL_SPOOLER_ID + '"))))',
            draft: 'false',
            outputformat: 'json',
            page: 1,
            rpp: 1
        };
        const headers = {
            'Content-Type': 'application/json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getRefinebyData(entityIds: string[] = [], searchText: string = '', category: Array<string>,
        advFilter: string = '', categoryFilter?: Object, timeZone: number = -new Date().getTimezoneOffset()): Observable<any> {
        const params: any = {
            outputformat: 'json',
            timeZoneId: DateHelperWebService.getContryTimezoneId(),
        };

        if (searchText) {
            params.searchstring = searchText;
        }

        const formData = new URLSearchParams();
        formData.set('category', JSON.stringify(category));

        if (entityIds && entityIds.length > 0) {
            formData.set('entities', entityIds.join(','));
        }

        if (advFilter) {
            formData.set('advfilter', advFilter);
        }

        if (categoryFilter) {
            formData.set('categoryfilter', JSON.stringify(categoryFilter));
        }

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const options = {
            headers: headers,
            params: params
        };
        const url = `${AppConfig.threadRefinebyEndpoint}`;
        return this._transportService.post(url, formData.toString(), options);
    }

    public getThreadList(page: number = 0, pageSize: number = 0, entityFocus: string = '',
        keyword: string = '', draft: boolean = false): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.threadEndpoint}`;
        const advfilter = encodeURIComponent(AppConfig.defaultAdvFilter);
        entityFocus = encodeURIComponent(entityFocus);
        let formData = 'advfilter=' + advfilter
            + ((entityFocus == null || entityFocus.length === 0) ? '' : '&' + 'entities=' + entityFocus);
        formData = formData.replace('r20', '+');
        const params = {
            expand: 'thread;attachments;entry;entities;entity;source;submitter;',
            page: page,
            rpp: pageSize,
            showpermission: true,
            sortby: 'displaydateofthread',
            sortorder: 'desc',
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            'exclude-embedded-img': true,
        };
        if (draft === true) {
            params['draft'] = true;
        }
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, formData, options);
    }

    public getThreadListWithPagging(page: number = 0, pageSize: number = 0, entityFocus: string = '',
        keyword: string = '', draft: boolean = false, sortBy: string = 'displaydateofthread', sortOrder: string = 'desc'): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.threadEndpoint}`;
        const advfilter = encodeURIComponent(AppConfig.defaultAdvFilter);
        entityFocus = encodeURIComponent(entityFocus);
        let formData = 'advfilter=' + advfilter
            + ((entityFocus == null || entityFocus.length === 0) ? '' : '&' + 'entities=' + entityFocus);
        formData = formData.replace('r20', '+');
        const params = {
            expand: 'thread;attachments;entry;entities;entity;source;submitter;',
            page: page,
            rpp: pageSize,
            showpermission: true,
            sortby: sortBy,
            sortorder: sortOrder,
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            'exclude-embedded-img': true,
        };
        if (draft === true) {
            params['draft'] = true;
        }
        if (keyword && keyword.length > 0) {
            params['searchstring'] = keyword;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.post(url, formData, options);
    }

    public getThreadById(threadId: string, showBlurb: boolean = true): Observable<any> {
        if (!threadId) {
            return observableThrowError({ message: 'Empty thread id' });
        }
        const headers = {
            'Content-Type': 'application/json',
        };
        const url = `${AppConfig.threadEndpoint}${threadId}`;
        const params = {
            expand: 'thread;attachments;entry;entities;entity;source;submitter;properties;property;propdef;entry-type',
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            showpermission: true,
            outputformat: 'json',
            'exclude-embedded-img': true,
        };
        if (!showBlurb) {
            params['showblurb'] = false;
        }
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.get(url, options);
    }

    public mapThreadList(response) {
        const threadList = new ThreadList();
        threadList.next = response.next;
        threadList.count = response.count;
        const dataItems = response['thread-list'];
        for (const dataItem of dataItems) {
            threadList.threads.push(this.mapThread(dataItem));
        }
        return threadList;
    }

    public mapThread(response) {
        const thread = new Thread();
        thread.id = response.id;
        for (const note of response.notes) {
            const noteEntry = new NoteEntry();

            // meta data
            noteEntry.id = note['data'].id;
            noteEntry.threadPosition = note['data']['thread-position'];
            noteEntry.subject = note['data'].title;
            noteEntry.calculatedSubject = note['data'].calculatedTitle || note['data'].title;
            // noteEntry.entities = EntityHelperService.getEntities(note['data'].entities);
            noteEntry.entities = note['data'].entities.data.map(item => EntityBrief.parse(item.data));
            noteEntry.lastEditDate = new Date(note['data']['last-edited-date']);
            noteEntry.displayDate = new Date(note['data']['display-date']);
            noteEntry.submittedDate = new Date(note['data']['submitted-date']);
            noteEntry.source = EntityBrief.parse(note.data.source.data);
            noteEntry.submitter = EntityBrief.parse(note.data.submitter.data);
            noteEntry.type = EntryType.parse(note.data['entry-type']);
            // noteEntry.sourceName = note['data'].source.link.phid;
            // noteEntry.sourceId = note['data'].source['data'].id;
            // //  [contact-details]
            // if (note['data'].source['data'].hasOwnProperty('contact-details')) {
            //     noteEntry.companyName = note['data'].source['data']['contact-details'].company;
            // }
            // noteEntry.submitterName = note['data'].submitter.link.phid;
            // noteEntry.submitterId = note['data'].submitter['data'].id;
            noteEntry.sentiment = note['data'].sentiment;
            noteEntry.priority = note['data'].priority;
            // noteEntry.noteType = note['data']['entry-type'].link.phid;
            noteEntry.backdated = note['data']['is-backdated'];

            // permissions
            noteEntry.editable = note['data'].editable;
            noteEntry.deletable = note['data'].deletable;

            // note body
            noteEntry.bodyLink = note['data'].body.link.href;

            // totoal number of notes
            noteEntry.totalNotesInThread = note['data']['thread-total'];

            // for 17.0.1 server, if subject is empty, show (<entry type>)
            if (!AppConfig.supportCalculatedSubject && noteEntry.calculatedSubject === '') {
                noteEntry.calculatedSubject = `(${noteEntry.type.name})`;
            }

            // record each sidenote attachment id
            if (note['data'].attachments && note['data'].attachments.hasOwnProperty('data')) {
                for (const attachment of note['data'].attachments.data) {
                    const attachmentLink = attachment['link'].href;
                    noteEntry.attachmentIds.push(attachmentLink.substring(attachmentLink.indexOf('/entry') + 7, attachmentLink.length - 1));
                }
            }

            thread.notes.push(noteEntry);
        }

        thread.parentEntry = thread.notes.find(noteEntry => noteEntry.threadPosition === 0);

        thread.attachments = new Map<string, DocumentEntry>();
        const firstNoteAttachmentIds: string[] = [];

        for (const attachmentObj of response['attachments']) {
            const attachment = new DocumentEntry();
            attachment.isEmbeddedImg = attachmentObj['data']['is-embedded-image'] === 'true';
            if (attachment.isEmbeddedImg === true) {
                continue;
            }
            attachment.id = attachmentObj['data'].id;
            attachment.threadId = thread.id.toString();
            attachment.fileName = attachmentObj['data'].filename;
            attachment.extension = attachment.fileName.substring(attachment.fileName.lastIndexOf('.') + 1);
            attachment.fileDataLink = attachmentObj['data'].filedata.link.href;
            attachment.noteId = attachmentObj['data'].thread['data'].id;
            attachment.deletable = attachmentObj['data'].deletable;
            attachment.editable = attachmentObj['data'].editable;
            thread.attachments.set(attachment.id, attachment);
            firstNoteAttachmentIds.push(attachment.id);
        }

        for (const sidenote of thread.notes) {
            const sidenoteAttachmentIds: string[] = [];
            for (const sidenoteAttachmentId of sidenote.attachmentIds) {
                if (thread.attachments.get(sidenoteAttachmentId.toString()) != null) {
                    sidenoteAttachmentIds.push(sidenoteAttachmentId.toString());
                }
            }
            sidenote.attachmentIds = sidenoteAttachmentIds;
        }

        thread.parentEntry.attachmentIds = firstNoteAttachmentIds;
        thread.parentEntry['attachments'] = thread.attachments;
        return thread;
    }

    public mapThreadList_ForParentNoteOwnAttachment(response) {
        const threadList = new ThreadList();
        threadList.next = response.next;
        threadList.count = response.count;
        const dataItems = response['thread-list'];
        for (const dataItem of dataItems) {
            threadList.threads.push(this.mapThread_ForParentNoteOwnAttachment(dataItem));
        }
        return threadList;
    }

    public mapThread_ForParentNoteOwnAttachment(response) {
        const thread = new Thread();
        thread.id = response.id;
        for (const note of response.notes) {
            const noteEntry = new NoteEntry();

            // meta data
            noteEntry.id = note['data'].id;
            noteEntry.threadPosition = note['data']['thread-position'];
            noteEntry.subject = note['data'].title;
            noteEntry.calculatedSubject = note['data'].calculatedTitle || note['data'].title;
            // noteEntry.entities = EntityHelperService.getEntities(note['data'].entities);
            noteEntry.entities = note['data'].entities.data.map(item => EntityBrief.parse(item.data));
            noteEntry.lastEditDate = new Date(note['data']['last-edited-date']);
            noteEntry.displayDate = new Date(note['data']['display-date']);
            noteEntry.submittedDate = new Date(note['data']['submitted-date']);
            noteEntry.source = EntityBrief.parse(note.data.source.data);
            noteEntry.submitter = EntityBrief.parse(note.data.submitter.data);
            noteEntry.type = EntryType.parse(note.data['entry-type']);
            // noteEntry.sourceName = note['data'].source.link.phid;
            // noteEntry.sourceId = note['data'].source['data'].id;
            // //  [contact-details]
            // if (note['data'].source['data'].hasOwnProperty('contact-details')) {
            //     noteEntry.companyName = note['data'].source['data']['contact-details'].company;
            // }
            // noteEntry.submitterName = note['data'].submitter.link.phid;
            // noteEntry.submitterId = note['data'].submitter['data'].id;
            noteEntry.sentiment = note['data'].sentiment;
            noteEntry.priority = note['data'].priority;
            // noteEntry.noteType = note['data']['entry-type'].link.phid;
            noteEntry.backdated = note['data']['is-backdated'];

            // permissions
            noteEntry.editable = note['data'].editable;
            noteEntry.deletable = note['data'].deletable;

            // note body
            noteEntry.bodyLink = note['data'].body.link.href;

            // totoal number of notes
            noteEntry.totalNotesInThread = note['data']['thread-total'];

            // for 17.0.1 server, if subject is empty, show (<entry type>)
            if (!AppConfig.supportCalculatedSubject && noteEntry.calculatedSubject === '') {
                noteEntry.calculatedSubject = `(${noteEntry.type.name})`;
            }

            // record each sidenote attachment id
            if (note['data'].attachments && note['data'].attachments.hasOwnProperty('data')) {
                for (const attachment of note['data'].attachments.data) {
                    const attachmentLink = attachment['link'].href;
                    noteEntry.attachmentIds.push(attachmentLink.substring(attachmentLink.indexOf('/entry') + 7, attachmentLink.length - 1));
                }
            }

            thread.notes.push(noteEntry);
        }

        thread.attachments = new Map<string, DocumentEntry>();

        for (const attachmentObj of response['attachments']) {
            const attachment = new DocumentEntry();
            attachment.isEmbeddedImg = attachmentObj['data']['is-embedded-image'] === 'true';
            if (attachment.isEmbeddedImg === true) {
                continue;
            }
            attachment.id = attachmentObj['data'].id;
            attachment.threadId = thread.id.toString();
            attachment.fileName = attachmentObj['data'].filename;
            attachment.extension = attachment.fileName.substring(attachment.fileName.lastIndexOf('.') + 1);
            attachment.fileDataLink = attachmentObj['data'].filedata.link.href;
            attachment.noteId = attachmentObj['data'].thread['data'].id;
            attachment.deletable = attachmentObj['data'].deletable;
            attachment.editable = attachmentObj['data'].editable;
            thread.attachments.set(attachment.id, attachment);
        }

        for (const sidenote of thread.notes) {
            const sidenoteAttachmentIds: string[] = [];
            for (const sidenoteAttachmentId of sidenote.attachmentIds) {
                if (thread.attachments.get(sidenoteAttachmentId.toString()) != null) {
                    sidenoteAttachmentIds.push(sidenoteAttachmentId.toString());
                    let attachment = new DocumentEntry();
                    attachment = thread.attachments.get(sidenoteAttachmentId.toString());
                    sidenote.attachments.set(sidenoteAttachmentId, attachment);
                }
            }
            sidenote.attachmentIds = sidenoteAttachmentIds;
        }

        return thread;
    }

    // use 2.1/entry for attachment of note and sidenote. Can't use thread here.
    public getThreadAttachmentsById(entryId: string): Observable<any> {
        if (!entryId) {
            return observableThrowError({ message: 'Empty thread id' });
        }
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryEndpoint}${entryId}`;
        const params = {
            expand: 'thread;attachments;entry;entities;entity;source;submitter;properties;property;propdef;',
            showpermission: true,
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            outputformat: 'json',
            'exclude-embedded-img': true,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public mapThreadAttachment(response): Thread {
        const thread = new Thread();
        const note = new NoteEntry();
        thread.id = response.id;
        // prepare entry attachments
        const rawNoteAttachments = (response['attachments'] && response['attachments']['data']) || [];
        note.attachments = this._retrieveAttachments(rawNoteAttachments, thread.id);
        thread.notes.push(note);
        // prepare thread attachments
        const rawThreadAttachments = (response['thread'] && response['thread']['data'] && response['thread']['data']['attachments']) || [];
        thread.attachments = this._retrieveAttachments(rawThreadAttachments, thread.id);
        return thread;
    }

    private _retrieveAttachments(attachments: any, threadId: any): Map<string, DocumentEntry> {
        const result = new Map<string, DocumentEntry>();
        for (let index = 0; index < attachments.length; index++) {
            const attachmentObj = attachments[index];

            const attachment = new DocumentEntry();
            attachment.isEmbeddedImg = attachmentObj['data']['is-embedded-image'] === 'true';
            if (attachment.isEmbeddedImg === true) {
                continue;
            }
            attachment.id = attachmentObj['data'].id;
            attachment.threadId = threadId.toString();
            attachment.fileName = attachmentObj['data'].filename;
            attachment.extension = attachment.fileName.substring(attachment.fileName.lastIndexOf('.') + 1);
            attachment.fileDataLink = attachmentObj['data'].filedata.link.href;
            attachment.noteId = attachmentObj['data'].id;
            attachment.deletable = attachmentObj['data'].deletable;
            attachment.editable = attachmentObj['data'].editable;
            attachment.serverFileName = attachmentObj['data'].serverfilename;
            result.set(attachment.id, attachment);
        }
        return result;
    }

    formatThread(threadObj: any) {
        const result = this._es6mapService.transformJSONToMap(threadObj);
        for (const note of result.notes) {
            note.lastEditDate = new Date(note.lastEditDate);
            note.displayDate = new Date(note.displayDate);
            note.submittedDate = new Date(note.submittedDate);
        }
        return result;
    }

    queryByParams(
        pageIndex: number = 0,
        pageSize: number = 20,
        sortBy: string = 'displaydateofthread',
        sortOrder: string = 'desc',
        advFilter: string = '',
        entityIds: string[] = [],
        searchText: string = '',
        draft: boolean = false,
        categoryFilter?: Object,
        groups?,
        groupSortOrder?,
        groupFilters?,
        timeZone: number = -new Date().getTimezoneOffset(),
    ) {
        const params: any = {
            page: pageIndex,
            rpp: pageSize,
            sortby: sortBy,
            sortorder: sortOrder,
            expand: 'attachments;entities;entity;entry;source;submitter;thread;properties;property;propdef;entry-type',
            draft: draft === true ? true : false,
            showattachmentsbyentry: true,
            showattachmentserverfilename: true,
            showpermission: true,
            outputformat: 'json',
            'exclude-embedded-img': true,
        };

        if (searchText) {
            params.searchstring = searchText;
        }

        const formData = new URLSearchParams();

        if (entityIds && entityIds.length > 0) {
            formData.set('entities', entityIds.join(','));
        }

        if (categoryFilter) {
            formData.set('categoryfilter', JSON.stringify(categoryFilter));
        }

        formData.set('timeZoneId', DateHelperWebService.getContryTimezoneId());

        if (advFilter) {
            formData.set('advfilter', advFilter);
        }

        if (groups) {
            formData.set('groups', groups);
            formData.set('group-sortorder', groupSortOrder);
            if (groupFilters) {
                formData.set('group-filters', groupFilters);
            }
        }

        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };

        const options = {
            headers: headers,
            params: params
        };
        const url = `${AppConfig.threadEndpoint}`;
        return this._transportService.post(url, formData.toString(), options);
    }

}
