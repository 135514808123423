/**
 * Description:
 * ------ maintenance history ------
 * 06/11/2019   Yoyo Fang   set keyword from External causes ExpressionChangedAfterItHasBeenCheckedError, use settimeout to resolve
 */
import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { StringHelperService } from '../../tamalelibs/services/string-helper.service';
import { DeviceDetectorService } from 'ngx-device-detector';

export class KeywordSearchConfig {
    actions$: Subject<any>;
    events$: Subject<any>;
    keyword: string;

    constructor(keyword?: string) {
        this.actions$ = new Subject();
        this.events$ = new Subject();
        if (keyword !== null && keyword !== undefined) {
            this.keyword = keyword;
        }
    }
}

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'keyword-search',
    templateUrl: './keyword-search.component.html',
    styleUrls: ['./keyword-search.component.scss']
})
export class KeywordSearchComponent implements OnInit, OnDestroy {

    @Input() config: KeywordSearchConfig;
    @ViewChild(TooltipDirective, { static: false }) tooltipDir: TooltipDirective;
    @ViewChild('keywordInput', { static: false }) keywordInputEle: ElementRef;

    keyword: string;

    private _destroySubscriptions: Array<Subscription> = [];
    private _isIPAD = false;

    constructor(
        private _deviceService: DeviceDetectorService,
    ) {
        this._isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
    }

    ngOnInit() {
        this._destroySubscriptions.push(
            this.config.actions$.subscribe(action => this._onSubscribeActions(action))
        );
        this.keyword = this.config.keyword;
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    clear() {
        this.keyword = '';
        this.config.events$.next({
            type: 'CHANGE',
            payload: {
                data: '',
                state: this.keyword
            }
        });
    }

    onKeyup(event) {
        if (event.keyCode === 13) {
            if (this.keyword === '') {
                // input nothing and press ENTER will trigger list refresh
                this.config.events$.next({
                    type: 'CHANGE',
                    payload: {
                        data: '',
                        state: this.keyword
                    }
                });
                return;
            }
            this.keyword = this.keyword.trim();
            if (this.keyword.length > 2) {
                this.config.events$.next({
                    type: 'CHANGE',
                    payload: {
                        data: StringHelperService.escapeSearchString(this.keyword),
                        state: this.keyword
                    }
                });
                if (this._isIPAD) {
                    this.blurKeywordInput();
                }
            } else {
                this.tooltipDir.show(this.keywordInputEle);
                setTimeout(() => {
                    this.tooltipDir.hide();
                }, 3000);
            }
        }
    }

    // blur
    blurKeywordInput() {
        this.keywordInputEle.nativeElement.blur();
    }

    showClearButton(): boolean {
        if (this.keyword) {
            return true;
        } else {
            return false;
        }
    }

    private _onSubscribeActions(action) {
        if (action.type === 'CLEAR') {
            this.keyword = '';
        } else if (action.type === 'SET') {
            setTimeout(() => {
                this.keyword = action.payload;
                if (!action.silentChange) {
                    this.onKeyup({ keyCode: 13 });
                }
            });
        }
    }
}
