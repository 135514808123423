/**
 * Created by Daniel Wang on 5/10/2023
 * -------------------------------------
 */

import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { Subscription, fromEvent, BehaviorSubject, Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs/operators';
import { FieldActions, FieldConfig, FieldEvents } from '../field.model';
import { User } from '../../../../tamalelibs/models/user.model';
import { UtilsService } from '../../../../tamalelibs/services/utils.service';
import { UsersService } from '../../../../tamalelibs/services/users.service';
import { ArrayHelperService } from '../../../../tamalelibs/services/array-helper.service';

@Component({
    selector: 'tam-single-user-dropdown-field',
    templateUrl: './single-user-dropdown-field.component.html',
    styleUrls: ['../field.component.scss']
})
export class SingleUserDropdownFieldComponent implements OnInit, AfterViewInit, OnDestroy {
    // #region properties
    // #region public properties
    @Input() config: FieldConfig;

    @ViewChild('kendo', { static: false }) kendo;
    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false }) tooltipDir: TooltipDirective;

    kDataSource: BehaviorSubject<Array<User>> = new BehaviorSubject<Array<User>>([]);
    isIPAD = false;
    requireInvalid = false;
    searchValue = '';
    value: User;
    // #endregion

    // #region private properties
    private _destroySubscriptions: Array<Subscription> = [];
    private _filterHandler$: Subject<string> = new Subject();
    private _focused = false;
    private _sourceData: Array<User>;
    // #endregion
    // #endregion

    // #region constructor
    constructor(
        private _deviceService: DeviceDetectorService,
        private _usersService: UsersService,
        private _utils: UtilsService
    ) { }
    // #endregion

    // #region ngOnInit
    ngOnInit() {
        if (this.config.disabled) {
            return;
        }
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this._destroySubscriptions.push(this._filterHandler$.pipe(
            filter(filterStr => filterStr !== null && filterStr !== undefined)
        ).subscribe(filterStr => this._filterHandler(filterStr)),

            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );

        if (this.config &&
            this.config.field &&
            this.config.field.value &&
            this.config.field.value.length > 0) {
            this.value = this.config.field.value[0];
        } else {
            this.value = null;
        }
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this._validateRequire(),
            },
        });
    }
    // #endregion

    // #region ngAfterViewInit
    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this._showTooltip(event, this.anchor, false);
                }),
            );
        }
        if (this.value) {
            setTimeout(
                () => this.kDataSource.next([this.value]));
        }
    }
    // #endregion

    // #region ngOnDestroy
    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }
    // #endregion

    // #region events
    onBlur(): void {
        this._focused = false;
    }

    onFocus(): void {
        this._focused = true;
        this.onHandleFilter('');
    }

    onHandleFilter(event: string): void {
        if (this._focused) {
            this._filterHandler$.next(event);
        }
    }

    onValueChange(event): void {
        this.requireInvalid = false;
        const value = event;

        this._focused = true;
        this.onHandleFilter('');

        this.config.field.value = value;
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: value,
            },
        });
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this._validateRequire(),
            },
        });
    }
    // #endregion

    // #region private functions
    clearValue() {
        const event = new UIEvent('kendodropdown_keydown');
        event['keyCode'] = 27;
        this.kendo.keydown(event);
        this.value = null;
        this.config.field.value = null;
    }

    private _getSourceByResponse(res) {
        if (!res || !res['entity-list']) {
            return [];
        }

        const list = res['entity-list'].map(element => {
            return {
                id: element.id,
                name: element['long-name'],
                shortName: element['short-name'],
                aliases: element['aliases'].data,
                primaryEmail: element['primary-email'],
                isPublic: element['is-public']
            };
        });
        ArrayHelperService.sort(list, 'name');
        return list;
    }

    private _filterHandler(filterStr: string): void {
        this.searchValue = '';
        if (this._focused) {
            // get the all user from store
            this._usersService.getAll().subscribe(res => {
                this._sourceData = this._getSourceByResponse(res);
                const data = this._sourceData.filter(item => item.shortName.toLowerCase().includes(filterStr.toLowerCase()));
                this.kDataSource.next(data);
            });
        }
    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this._validateRequire();
        }
    }

    private _showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    private _validateRequire(): boolean {
        if (this.config.editable && this.config.required && (!this.config.field.value || !this.value || this.config.field.value.length === 0)) {
            return true;
        } else {
            return false;
        }
    }
    // #endregion
}
