/**
 * Created by Alan Yang on 07/09/17.
 * Description:
 *  mapping error message to error class.
 *
 * ------ maintenance history ------
 * ver 1.1 modified by Yu Zhang on 7/3/18
 *    add NoConnectionError
 */

import { Injectable } from '@angular/core';
import { UnauthorizedError, InValidTokenError, ATSError, ServiceUnavailableError, NoConnectionError } from '../models/restapi-error.model';

@Injectable()
export class ErrorHandlerService {

    // put all error message logic here. Mapping to Error class.
    public static getError(error): Error {
        let msg = 'Unknown error happens!';
        let errObj: Error;

        // get error message
        if (!error) {
            errObj = new Error(msg);
            return errObj;
        }
        if (typeof (error) === 'string') {
            msg = error;
        } else if (error.hasOwnProperty('_body') && error['_body'].toString().length > 0) {
            msg = error['_body'].toString();
        }
        // check error.error since Angular http client returns error response with message in error.error property,
        // the function parameter error is a http response type
        if (error.hasOwnProperty('error') && error['error'].toString().length > 0) {
            msg = error['error'].toString();
        } else {
            msg = (error.message) ? error.message : (error.status != null ? error.status + '-'
                + error.statusText.toString() : 'Unknown networking exception happens!');
        }

        // console.debug('error-handler msg:' + msg);
        // mapping to specific error class
        if (msg.indexOf('400') && msg.indexOf('token whose ID') > -1 && msg.indexOf('is invalid') > -1) {
            errObj = new InValidTokenError(msg);
        } else if (msg.indexOf('401 Unauthorized') > -1) {
            errObj = new UnauthorizedError(msg);
        } else if (msg.indexOf('Service Temporarily Unavailable') > -1 || msg.indexOf('<body>Unauthorized</body></html>') > -1) {
            errObj = new ServiceUnavailableError(msg);
            // tslint:disable-next-line: max-line-length
        } else if (msg.indexOf('The resource could not be loaded because the App Transport Security policy requires the use of a secure connection') > -1) {
            errObj = new ATSError(msg);
        } else if (msg.indexOf('offline') > -1) {
            errObj = new NoConnectionError(msg);
        } else if (msg.indexOf('HTTP/1.1 400 Bad Request') > -1) {
            // TODO: check how to deal with this message type of error.  In my case, it is there after deleting token.
            if (error.error && error.error.length > 0) {
                msg += ' ' + error.error;
            }
            errObj = new Error(msg);
        } else {
            errObj = new Error(msg);
        }

        return errObj;
    }
}
