import { AppState } from '..';
import { adminPageType, GeneralGridType } from '../../tamalelibs/models/admin-page.model';
import { AdminPageActions, AdminPageActionTypes } from '../actions/admin-page.actions';

/** store save credential functions */
export const credentialSelector = (state: AppState) => state.credential;
export const adminSharedPageSelector = (state: AppState) => state.adminSharedPage;
export const adminGeneralPageSelector = (state: AppState) => state.adminGeneralPage;

export enum AdminPageTypes {
    GENERAL = 'general',
    NOTE_TYPES = 'entry-types',
    ENTITY_TYPES = 'entity-types',
    RELATIONSHIPS = 'relationships',
    NOTIFICATIONS = 'notifications',
    USERS = 'users',
    SYSTEM_CREDENTIALS = 'system-credentials',
    AUTO_TAG = 'auto-tag',
    INTRALINKS = 'intralinks',
    EXCHANGE = 'exchange',
    GOOGLE_MAP_SERVICE = 'google-map-service',
    WORKFLOW = 'workflow',
    TEMPLATE_SETTINGS = 'template-settings',
    SYSTEM_CONFIG = 'system-config',
    EMAIL_SETTINGS = 'email-settings',
    PORTFOLIO_UPLOAD = 'portfolio-upload',
    STREET_EVENTS_CONFIGURATION = 'se-configuration',
    ETL_SCHEDULED_JOBS = 'etl-scheduled-jobs',
    ETL_Tasks = 'etl-tasks',
    LOCAL_PYTHON_SCRIPTS = 'local-python-scripts',
    LOGIN_USAGE_REPORT = 'login-usage-report',
    TOKEN_MANAGEMENT = 'token-management',
    STORE_CACHE_TO_FILE = 'store-cache-to-file',
    PERSISTENT_CACHE = 'persistent-cache',
    CENTRAL_CONFIG = 'central-config',
    CLAMAV_FILE_LOOKUP = 'clamav-file-lookup',
    WEB_SITES = 'web-sites',
    SERVER_MONITORING = 'server-monitoring'
}

export enum AdminPageStatusTypes {
    SUCCESS = 'success',
    FAILURE = 'failure'
}

export interface AdminPageState {
    currentPage: AdminPageTypes;
    currentPageData: any;
    successStatus?: AdminPageStatusTypes;
    successMessage?: string;
    failureMessage?: string;
}

const DEFAULT_ADMIN_PAGE_STATE: AdminPageState = {
    currentPage: AdminPageTypes.USERS,
    currentPageData: {
        'user-list': []
    }
};

/** Main reducer for admin pages. Handles success and failure statuses returned by admin page effects */
export function adminPageReducer(state: AdminPageState = DEFAULT_ADMIN_PAGE_STATE, action: AdminPageActions) {
    switch (action.type) {
        case AdminPageActionTypes.GET_NOTE_TYPES_SUCCESS: {
            // add default sidenote type column on entry type grid
            const entryTypeList = action.payload['entry-type-list'];
            entryTypeList.forEach(entryType => {
                entryType['defaultSidenoteType'] = '';
                if (entryType.config && entryType.config.customDefaultSideNoteType) {
                    const data = entryTypeList.filter(item => item.id === entryType.config.customDefaultSideNoteType);
                    entryType['defaultSidenoteType'] = data[0].name;
                }
            });
            return {
                currentPage: AdminPageTypes.NOTE_TYPES,
                currentPageData: action.payload
            };
        }
        case AdminPageActionTypes.CREATE_NOTE_TYPE_SUCCESS: {
            const newNote = action.payload.newNote;
            const entryTypeList = state.currentPageData['entry-type-list'];
            const defaultSidenoteType = newNote && newNote.config ? newNote.config.customDefaultSideNoteType : '';
            const data = entryTypeList.filter(item => item.id === defaultSidenoteType);
            if (data.length > 0) {
                newNote['defaultSidenoteType'] = data[0].name;
            } else {
                newNote['defaultSidenoteType'] = '';
            }
            entryTypeList.push(newNote);
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.DELETE_NOTE_TYPE_SUCCESS: {
            state.currentPageData['entry-type-list'] = state.currentPageData['entry-type-list'].filter(
                item => item['id'] !== action.payload.id);
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.EDIT_NOTE_TYPE_SUCCESS: {
            const entryTypeList = state.currentPageData['entry-type-list'];
            entryTypeList.forEach(entryType => {
                if (entryType['id'] === action.payload.newNote['id']) {
                    Object.keys(action.payload.newNote).forEach(propName => {
                        entryType[propName] = action.payload.newNote[propName];
                    });
                    entryType['display-web'] = entryType['display-web'] === undefined ? true : entryType['display-web'];
                    if (entryType.config && entryType.config.customDefaultSideNoteType) {
                        const data = entryTypeList.filter(item => item.id === entryType.config.customDefaultSideNoteType);
                        if (data.length > 0) {
                            entryType['defaultSidenoteType'] = data[0].name;
                        } else {
                            entryType['defaultSidenoteType'] = '';
                        }
                    } else {
                        entryType['defaultSidenoteType'] = '';
                    }
                }
            });
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.EDIT_DEFAULT_NOTE_SUCCESS: {
            state.currentPageData['entry-type-list'].forEach(entry => {
                if (action.payload.data['id'] === entry['id']) {
                    entry[action.payload.defaultTypeField] = true;
                } else {
                    entry[action.payload.defaultTypeField] = false;
                }
            });
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.TOGGLE_NOTE_DISPLAY_SUCCESS: {
            state.currentPageData['entry-type-list'].forEach(entry => {
                if (action.payload.data['id'] === entry['id']) {
                    entry['display-web'] = action.payload.data['display-web'] === undefined ? true : action.payload.data['display-web'];
                }
            });
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.GET_ENTITY_TYPES_SUCCESS: {
            return {
                currentPage: AdminPageTypes.ENTITY_TYPES,
                currentPageData: action.payload
            };
        }
        case AdminPageActionTypes.CREATE_NEW_ENTITY_TYPE_SUCCESS: {
            state.currentPageData['entity-type-list'].push(action.payload.newEntity);
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.DELETE_ENTITY_TYPE_SUCCESS: {
            state.currentPageData['entity-type-list'] = state.currentPageData['entity-type-list'].filter(item => item['id'] !== action.payload.id);
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.EDIT_ENTITY_TYPE_SUCCESS: {
            state.currentPageData['entity-type-list'].forEach(entity => {
                if (entity['id'] === action.payload.newEntity['id']) {
                    entity['name'] = action.payload.newEntity['name'];
                    entity['display-web'] = action.payload.newEntity['display-web'] === undefined ? true : action.payload.newEntity['display-web'];
                }
            });
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.TOGGLE_ENTITY_DISPLAY_SUCCESS: {
            state.currentPageData['entity-type-list'].forEach(entity => {
                if (action.payload.data['id'] === entity['id']) {
                    entity['display-web'] = action.payload.data['display-web'] === undefined ? true : action.payload.data['display-web'];
                }
            });
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.EDIT_DEFAULT_ENTITY_SUCCESS: {
            state.currentPageData['entity-type-list'].forEach(entity => {
                if (action.payload.data['id'] === entity['id']) {
                    entity[action.payload.defaultTypeField] = true;
                } else {
                    entity[action.payload.defaultTypeField] = false;
                }
            });
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.GET_USERS_SUCCESS: {
            return {
                currentPage: AdminPageTypes.USERS,
                currentPageData: action.payload
            };
        }
        case AdminPageActionTypes.CREATE_USER_SUCCESS: {
            state.currentPageData['user-list'].push(action.payload.newUser);
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.EDIT_USER_SUCCESS: {
            state.currentPageData['user-list'].forEach(user => {
                if (user['id'] === action.payload.newUser['id']) {
                    user['username'] = action.payload.newUser['username'];
                    user['secure-email'] = action.payload.newUser['secure-email'];
                    user['credentials'] = action.payload.newUser['credentials'];
                    user['teams'] = action.payload.newUser['teams'];
                }
            });
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.DELETE_USERS_SUCCESS: {
            state.currentPageData['user-list'] = state.currentPageData['user-list'].filter(
                item => item['id'] !== action.payload.id);
            state.successStatus = AdminPageStatusTypes.SUCCESS;
            state.successMessage = action.payload.successMessage;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.GENERAL_FAILURE: {
            state.successStatus = AdminPageStatusTypes.FAILURE;
            state.failureMessage = action.payload;
            return Object.assign({}, state);
        }
        case AdminPageActionTypes.CLEAR_STATUS: {
            if (state.failureMessage) {
                state.failureMessage = undefined;
            }
            if (state.successStatus) {
                state.successStatus = undefined;
            }
            if (state.successMessage) {
                state.successMessage = undefined;
            }
            return Object.assign({}, state);
        }
        default:
            return state;
    }
}

/** store save credential functions */
export function credentialsReducer(state, action: AdminPageActions) {
    switch (action.type) {
        case AdminPageActionTypes.GET_SYSTEM_CREDENTIALS_SUCCESS:
        case AdminPageActionTypes.GET_SYSTEM_CREDENTIALS_FAILURE: {
            return action.payload;
        }
        default:
            return state;
    }
}


export function adminGeneralPageReducer(state, action: AdminPageActions) {
    switch (action.type) {
        case AdminPageActionTypes.GET_GENERAL_PAGE_SUCCESS:
            return action.payload;
        case AdminPageActionTypes.GET_GENERAL_PAGE_FAILURE:
            return action.payload;
        case AdminPageActionTypes.CREATE_GENERAL_PAGE_SUCCESS:
            const originalItems = Object.assign([], state);
            const newPage = action.payload.data;
            if (newPage) {
                originalItems.push(newPage);
            }
            return originalItems;
        case AdminPageActionTypes.CREATE_GENERAL_PAGE_FAILURE:
            return state;
        case AdminPageActionTypes.EDIT_GENERAL_PAGE_SUCCESS:
            const items = Object.assign([], state);
            const updatedItem = action.payload.data;
            const oldItem = action.payload.oldData;
            if (action.payload.type === GeneralGridType.ConfigureHTMLSecurity) {
                for (let i = 0, len = items.length; i < len; i++) {
                    if (items[i].tag === oldItem.tag) {
                        items[i] = updatedItem;
                        break;
                    }
                }
            } else {
                for (let i = 0, len = items.length; i < len; i++) {
                    if (items[i].ID === updatedItem.ID) {
                        items[i] = updatedItem;
                        break;
                    }
                }
            }
            return items;
        case AdminPageActionTypes.EDIT_GENERAL_PAGE_FAILURE:
            return state;
        case AdminPageActionTypes.DELETE_GENERAL_PAGE_SUCCESS:
            const sharedItems = Object.assign([], state);
            if (action.payload.type === GeneralGridType.ConfigureHTMLSecurity) {
                const deletedTag = action.payload.data.tag;
                const newItems = sharedItems.filter(item => item.tag !== deletedTag);
                return newItems;
            } else {
                const deletedItemId = action.payload.data.id;
                const newItems = sharedItems.filter(item => item.id !== deletedItemId && item.ID !== deletedItemId);
                return newItems;
            }
        case AdminPageActionTypes.DELETE_GENERAL_PAGE_FAILURE:
            return state;
        default:
            return state;
    }
}

export function adminSharedPageReducer(state, action: AdminPageActions) {
    switch (action.type) {
        case AdminPageActionTypes.GET_SHARED_PAGE_SUCCESS:
            return action.payload;
        case AdminPageActionTypes.GET_SHARED_PAGE_FAILURE:
            return action.payload;
        case AdminPageActionTypes.CREATE_SHARED_PAGE_SUCCESS:
            let originalItems = Object.assign([], state);
            const newPage = action.payload.data;
            if (newPage) {
                if (action.payload.type === adminPageType.webSites) {
                    originalItems.unshift(newPage);
                } else if (action.payload.type === adminPageType.webSitesByFile) {
                    originalItems = newPage;
                } else {
                    originalItems.push(newPage);
                }
            }
            return originalItems;
        case AdminPageActionTypes.CREATE_SHARED_PAGE_FAILURE:
            return state;
        case AdminPageActionTypes.EDIT_SHARED_PAGE_SUCCESS:
            const items = Object.assign([], state);
            const updatedItem = action.payload;
            for (let i = 0, len = items.length; i < len; i++) {
                if (items[i].id === updatedItem.id) {
                    items[i] = updatedItem;
                    break;
                }
            }
            return items;
        case AdminPageActionTypes.EDIT_SHARED_PAGE_FAILURE:
            return state;
        case AdminPageActionTypes.DELETE_SHARED_PAGE_SUCCESS:
            const sharedItems = Object.assign([], state);
            const deletedItemId = action.payload.id;
            const newItems = sharedItems.filter(item => item.id !== deletedItemId && item.ID !== deletedItemId);
            return newItems;
        case AdminPageActionTypes.DELETE_SHARED_PAGE_FAILURE:
            return state;
        default:
            return state;
    }
}
