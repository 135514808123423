import { Component, OnInit, Input, Renderer2, ElementRef, ContentChild, TemplateRef, OnChanges, AfterViewInit, AfterViewChecked } from '@angular/core';
import { TabOptions, TabItem, TabActionTypes } from './tabs.model';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterViewChecked {

    @Input() options: TabOptions;
    @ContentChild(TemplateRef, { static: false }) name: TemplateRef<any>;
    myContext;
    private _tabIndex;
    private element: HTMLElement;
    _underlineStyle: boolean;
    private _isTabsFirstInit = true; // used for ngAfterViewChecked, to set initial style of tabs
    currentIndex: number;
    @Input()
    set underlineStyle(value: boolean) {
        this._underlineStyle = value;
        if (this._underlineStyle) {
            // add style
        } else {
            // remove style
        }
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {
        this.element = this.elementRef.nativeElement;
    }

    ngOnInit() {
        this.myContext = { data: this.options.selectedIndex };
    }

    ngAfterViewChecked() {
        if (this._isTabsFirstInit || this.options.isRefresh) {
            this.currentIndex = this.options.selectedIndex;
            this._isTabsFirstInit = false;
            this.options.isRefresh = false;
        }

        const id: string = '#tab-label-' + this.currentIndex;
        if (this.element.querySelector(id) != null) {
            this.renderer.setStyle(this.element.querySelector('.js-tab-underline'), 'width', this.element.querySelector(id).clientWidth + 1 + 'px');
            this.renderer.setStyle(this.element.querySelector('.js-tab-underline'), 'left', this.element.querySelectorAll('label')[this.currentIndex].offsetLeft + 'px');
            this.myContext = { data: this.options.selectedIndex };
        }
    }


    private _tabItemOnClick(event: any, tabItem: TabItem, tabIndex: number) {
        this._isTabsFirstInit = false;
        this._tabIndex = tabIndex;
        this.currentIndex = tabIndex;
        this.myContext = { data: tabIndex };
        if (this._underlineStyle && !tabItem.disabled) {
            this.renderer.setStyle(this.element.querySelector('.js-tab-underline'), 'left', event.target.offsetLeft + 'px');
            this.renderer.setStyle(this.element.querySelector('.js-tab-underline'), 'width', event.target.clientWidth + 'px');
        }
        if (!tabItem.disabled) {
            this.options.selectedIndex = tabIndex;
            this.options.tabActionSubject$.next({
                type: TabActionTypes.click,
                payload: {
                    item: tabItem.displayText,
                    index: tabIndex
                }
            });
        }
        this.options.events$.next({
            type: 'TAB_CHANGED',
            payload: this._tabIndex
        });
    }

}
