/**
 * Created by Ella on 07/16/2019.
 * Description:
 * dashboard actions
 * ------ maintenance history ------
*/

import { Action } from '@ngrx/store';

export enum DashboardActionTypes {
    // focus related
    SET_FOCUS_VALUE = '[DASHBOARD][FOCUS]SET_FOCUS_VALUE',
    SAVE_TILE_DATA = 'SAVE_TILE_DATA',
    SAVE_TILE_INFO = 'SAVE_TILE_INFO',
    SAVE_TILE_CONFIG = 'SAVE_TILE_CONFIG',
    SAVE_GRID_TILE_LAYOUT = 'SAVE_GRID_TILE_LAYOUT',
    RESET_TILE_DATA = 'RESET_TILE_DATA',
    CLEAR_PRINT_TILE = 'CLEAR_PRINT_TILE',
    SET_NOTE_ENTITY_ID = 'SET_NOTE_ENTITY_ID'
}

export class DashboardSetFocusValue implements Action {
    readonly type = DashboardActionTypes.SET_FOCUS_VALUE;

    constructor(public payload: any) { }
}

export class PrintDahboardSaveTileData implements Action {
    readonly type = DashboardActionTypes.SAVE_TILE_DATA;

    constructor(public payload: any) { }
}

export class PrintDahboardSaveTileInfo implements Action {
    readonly type = DashboardActionTypes.SAVE_TILE_INFO;

    constructor(public payload: any) { }
}

export class PrintDahboardSaveTileConfig implements Action {
    readonly type = DashboardActionTypes.SAVE_TILE_CONFIG;

    constructor(public payload: any) { }
}

export class PrintDahboardSaveGridTileLayout implements Action {
    readonly type = DashboardActionTypes.SAVE_GRID_TILE_LAYOUT;

    constructor(public payload: any) { }
}

export class PrintDahboardResetTileData implements Action {
    readonly type = DashboardActionTypes.RESET_TILE_DATA;

    constructor(public payload: any) { }
}

export class PrintDahboardClearTile implements Action {
    readonly type = DashboardActionTypes.CLEAR_PRINT_TILE;

    constructor(public payload: any) { }
}

export class DahboardNoteInfo implements Action {
    readonly type = DashboardActionTypes.SET_NOTE_ENTITY_ID;

    constructor(public payload: any) { }
}

export type DashboardActions = DashboardSetFocusValue |
    PrintDahboardSaveTileData | PrintDahboardSaveTileInfo |
    PrintDahboardSaveTileConfig | PrintDahboardSaveGridTileLayout |
    PrintDahboardResetTileData | PrintDahboardClearTile | DahboardNoteInfo;
