/**
 * Created by Abner Sui on 07/02/2019.
 * Description:
 * Copy from nextGen 1.0
 *
 * ------ maintenance history ------
 * Updated by Abner Sui on 08/13/2019
 * Add special logic for IE/Edge in forkJoin, see detail comments in code
 */
import { Component, OnInit, OnDestroy, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription, Subject, Observable, forkJoin } from 'rxjs';
import { DocumentEntry } from '../../tamalelibs/models/document-entry.model';
import { StoreQuerierService } from '../../services/store-querier.service';
import { SystemUser } from '../../tamalelibs/models/user.model';
import { take } from 'rxjs/operators';
declare let pdfjsLib;
@Component({
    selector: 'note-attachment-pdf',
    templateUrl: './note-attachment-pdf.component.html',
    styleUrls: ['./note-attachment-pdf.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoteAttachmentPdfComponent implements OnInit, OnDestroy {
    @Input()
    attachment: DocumentEntry;
    currentUser: SystemUser;
    renderDone = false;
    canvasArray: Array<{ id: string, height?: number, width?: number }> = [];

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _storeQuerier: StoreQuerierService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        // In order to fixing issues ralated to cMaps and fonts, replace the simple file url with the file object per the usage in viewer.js.
        const fileObj = {
            url: null,
            cMapPacked: true,
            cMapUrl: 'vendor/pdfjs/web/cmaps/',
            disableAutoFetch: false,
            disableFontFace: false,
            disableRange: false,
            disableStream: false,
            docBaseUrl: '',
            enableXfa: true,
            fontExtraProperties: false,
            isEvalSupported: true,
            maxImageSize: -1,
            pdfBug: false,
            standardFontDataUrl: 'vendor/pdfjs/web/standard_fonts/',
            verbosity: 1
        };
        this.currentUser = this._storeQuerier.getCurrentUser();
        fileObj.url = this.attachment.fileDataLink + this.attachment.id + '?userid=' + this.currentUser.id;
        // The workerSrc property shall be specified.
        pdfjsLib.GlobalWorkerOptions.workerSrc = 'vendor/pdfjs/build/pdf.worker.js';
        const attachmentId = this.attachment.id;
        // Asynchronous download of PDF
        const loadingTask = pdfjsLib.getDocument(fileObj);
        loadingTask.promise.then((pdfDoc) => {
            const promiseArr = [];
            const numPages = pdfDoc.numPages;
            for (let pageIndex = 1; pageIndex <= numPages; ++pageIndex) {
                const canvasId = attachmentId + '-' + pageIndex;
                const canvas = { id: canvasId, };
                this.canvasArray.push(canvas);
                promiseArr.push(this.renderPage(pdfDoc, pageIndex, canvas));
            }
            this._changeDetectorRef.detectChanges();
            forkJoin(promiseArr).subscribe(() => {
                this.renderDone = true;
                this._changeDetectorRef.detectChanges();
            });
        });
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(subscription => subscription.unsubscribe());
    }

    // pdf render functions
    renderPage(pdfDoc, num, canvas): Observable<any> {
        const deferred = new Subject<string>();
        let scale = 0;
        pdfDoc.getPage(num).then((page) => {
            if (!scale) {
                scale = window.innerWidth / page.getViewport({ scale: 1 }).width;
            }
            const viewport = page.getViewport({ scale: scale });
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            const canvasEle = document.getElementById(canvas.id) as any;
            const renderContext = {
                canvasContext: canvasEle.getContext('2d'),
                viewport: viewport
            };
            const renderTask = page.render(renderContext);
            // Wait for rendering to finish
            renderTask.promise.then(() => {
                deferred.next('');
            });
            this._changeDetectorRef.detectChanges();
        });
        return deferred.pipe(take(1));
    }

}
