/**
 * Create by
 * Description:
 *
 * ------ maintenance history ------
 * 2020/2/26  Bowen: change the parse function: because the relationship tree data model changed
 */

import { EntityType } from './entity-type.model';
import { AdhocField } from './adhoc-field.model';
import { JstreeERService } from '../services/jstreeER.service';
import { EntityBrief } from './entity-brief.model';
import { CorporateDetail } from './corporate-detail.model';
import { WebLink } from './web-link.model';
import { Relationship } from './relationship.model';
import { businessConstants } from '../constants/business.constants';
import { AddressDetail, WebAddress } from './job-function';

export class Entity {
    id: string;
    name: string;

    adhocs?: Map<string, AdhocField> = new Map();
    aliases?: Array<string>;
    detail?: CorporateDetail;
    isPublic: boolean;
    members?: Array<EntityBrief>;
    shortName: string;
    topicMembers?: any;
    type: EntityType;
    webLinks?: Array<WebLink>;
    relationships?: Array<Relationship>;

    // obsolete; DO NOT USE!!
    relationshipNode?: any; // TODO: will be refined later for relationship data. Added by Daniel on 01/01/2021
    customMetadata?: Array<any>; // TODO: will be refined later
    webAddress?: Array<AddressDetail>;

    // tslint:disable-next-line: member-ordering
    static readonly UNCATEGORIZED: Entity = {
        id: businessConstants.entity.Uncategorized_Fixed_ID,
        name: 'Uncategorized',
        shortName: 'Uncategorized',
        isPublic: true,
        type: EntityType.CORPORATE
    };

    static parse(serverResponse, parseTreeNode: boolean = false): Entity {
        const entity: Entity = {
            id: serverResponse['id'],
            name: serverResponse['long-name'],
            shortName: serverResponse['short-name'],
            isPublic: serverResponse['is-public'] === 'true',
            type: EntityType.parse(serverResponse['entity-type'])
        };

        if (serverResponse['aliases'] && serverResponse['aliases'].data && serverResponse['aliases'].data.length > 0) {
            if (entity.type.id === EntityType.CONTACT.id) {
                entity.aliases = serverResponse['aliases'].data;
            } else {
                entity.aliases = serverResponse['aliases'].data.map(element => element.data.name);
            }
        }

        if (serverResponse['weblinks'] && serverResponse['weblinks'].data && serverResponse['weblinks'].data.length > 0) {
            entity.webLinks = serverResponse['weblinks'].data.map(data => WebLink.parse(data));
        }

        if ((!entity.isPublic || entity.type.id === EntityType.TEAM.id) && serverResponse['topic-members'] && serverResponse['topic-members'].data && serverResponse['topic-members'].data.length > 0) {
            entity.topicMembers = serverResponse['topic-members'].data.map(element => EntityBrief.parse(element.data));
        }

        if (entity.type.id === EntityType.CORPORATE.id && serverResponse['corporate-details']) {
            entity.detail = CorporateDetail.parse(serverResponse['corporate-details']);
        }

        if (entity.type.id === EntityType.TEAM.id && serverResponse['member-list'] && serverResponse['member-list'].data && serverResponse['member-list'].data.length > 0) {
            entity.members = serverResponse['member-list'].data.map(element => EntityBrief.parse(element.data));
        }

        if (serverResponse.properties && serverResponse.properties.data) {
            entity.adhocs = new Map();
            serverResponse.properties.data.forEach(element => {
                const adhoc = AdhocField.parse(element);
                if (adhoc) {
                    if (entity.adhocs == null) {
                        entity.adhocs = new Map();
                    }
                    entity.adhocs.set(adhoc.id, adhoc);
                }
            });
        }

        if (serverResponse.relationships &&
            ((serverResponse.relationships.teams && serverResponse.relationships.teams.length > 0) ||
                (serverResponse.relationships.contacts && serverResponse.relationships.contacts.length > 0) ||
                (serverResponse.relationships.formerEmployments && serverResponse.relationships.formerEmployments.length > 0)
                || (serverResponse.relationships.currentEmployments &&
                    serverResponse.relationships.currentEmployments.length > 0))) {
            entity.relationships = Relationship.parse(serverResponse);
        }

        // TODO: move to separate function
        if (parseTreeNode) {
            entity.relationshipNode = JstreeERService.parseJSTree(serverResponse);
        }

        if (serverResponse.webAddress) {
            entity.webAddress = serverResponse.webAddress;
        }
        return entity;
    }
}
