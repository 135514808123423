/**
 * Created by Alex Xia on 11/20/2020.
 * Description:
 * ------ maintenance history ------
 */

import { Contact } from '../../../../tamalelibs/models/contact.model';

export class MapContactModel {
    contact: Contact;
    selectedAddress: string;
    selectedAddressType: string;
    latitude: number;
    longitude: number;
    isHideNearby?: boolean;
    entityType?: string;
    entityName?: string;
    entityId?: string;
    // Records the location of the current address in the WebAddress Array.
    entityIndex?: number;
    eventProperties?: any;
    threadId?: string;
}

export enum AutocompleteActionType {
    predictionSelected,
    directToMapTrip,
    searchNearby,
    showNearbyEntity,
    showNearbyGoogle,
    showNearby,
    closeNearby,
    noData
}

