/**
 * Created by Cathy yao on 12/02/2021.
 * Description:
 * Add user password dialog component
 * ------ maintenance history ------
 * Allow web-only Users with local Authentication to change password
 *
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class UserPasswordService {

    constructor(
    ) { }
    dialogOpen$: Subject<any> = new Subject<any>();
}
