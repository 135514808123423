/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 * 2019/8/28    Bowen       change relationship type
 */

import { Action } from '@ngrx/store';
import { EntityType } from '../../tamalelibs/models/entity-type.model';

export enum RelationshipTypeActionTypes {
    INITIALIZE = '[RELATIONSHIP_TYPE] INITIALIZE',
    INITIALIZE_SUCCESS = '[RELATIONSHIP_TYPE] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[RELATIONSHIP_TYPE] INITIALIZE_FAILED',

    GETVALIDRELATIONSHIP = '[RELATIONSHIP_TYPE] GETVALIDRELATIONSHIP',
    GETVALIDRELATIONSHIP_SUCCESS = '[RELATIONSHIP_TYPE] GETVALIDRELATIONSHIP_SUCCESS',
    GETVALIDRELATIONSHIP_FAILED = '[RELATIONSHIP_TYPE] GETVALIDRELATIONSHIP_FAILED',

    DELETE_RELATIONSHIP_TYPE = '[RELATIONSHIP_TYPE] DELETE_RELATIONSHIP_TYPE',
    DELETE_RELATIONSHIP_TYPE_SUCCESS = '[RELATIONSHIP_TYPE] DELETE_RELATIONSHIP_TYPE_SUCCESS',
    DELETE_RELATIONSHIP_TYPE_FAILED = '[RELATIONSHIP_TYPE] DELETE_RELATIONSHIP_TYPE_FAILED',

    CREATE_VALID_RELATIONSHIP_TYPE = '[RELATIONSHIP_TYPE] CREATE_VALID_RELATIONSHIP_TYPE',
    CREATE_VALID_RELATIONSHIP_TYPE_SUCCESS = '[RELATIONSHIP_TYPE] CREATE_VALID_RELATIONSHIP_TYPE_SUCCESS',
    CREATE_VALID_RELATIONSHIP_TYPE_FAILED = '[RELATIONSHIP_TYPE] CREATE_VALID_RELATIONSHIP_TYPE_FAILED',

    EDIT_RELATIONSHIP_TYPE = '[RELATIONSHIP_TYPE] EDIT_RELATIONSHIP_TYPE',
    EDIT_RELATIONSHIP_TYPE_SUCCESS = '[RELATIONSHIP_TYPE] EDIT_RELATIONSHIP_TYPE_SUCCESS',
    EDIT_RELATIONSHIP_TYPE_FAILED = '[RELATIONSHIP_TYPE] EDIT_RELATIONSHIP_TYPE_FAILED',
}

export class Initialize implements Action {
    readonly type = RelationshipTypeActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = RelationshipTypeActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = RelationshipTypeActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export class GetValidRelationship implements Action {
    readonly type = RelationshipTypeActionTypes.GETVALIDRELATIONSHIP;

    constructor(public payload: any) { }
}

export class GetValidRelationshipSuccess implements Action {
    readonly type = RelationshipTypeActionTypes.GETVALIDRELATIONSHIP_SUCCESS;

    constructor(public payload: any) { }
}

export class GetValidRelationshipFailed implements Action {
    readonly type = RelationshipTypeActionTypes.GETVALIDRELATIONSHIP_FAILED;

    constructor(public payload: any) { }
}

export class DeleteRelationshipType implements Action {
    readonly type = RelationshipTypeActionTypes.DELETE_RELATIONSHIP_TYPE;

    constructor(public payload: { relationshipTypeId: string; relationshipId: string; }) { }
}

export class DeleteRelationshipTypeSuccess implements Action {
    readonly type = RelationshipTypeActionTypes.DELETE_RELATIONSHIP_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

export class DeleteRelationshipTypeFailed implements Action {
    readonly type = RelationshipTypeActionTypes.DELETE_RELATIONSHIP_TYPE_FAILED;

    constructor(public payload: any) { }
}

export class CreateValidRelationshipType implements Action {
    readonly type = RelationshipTypeActionTypes.CREATE_VALID_RELATIONSHIP_TYPE;

    constructor(public payload: {
        parent: EntityType;
        child: EntityType;
        relationshipType: EntityType;
        reciprocal: EntityType;
    }) { }
}

export class CreateValidRelationshipTypeSuccess implements Action {
    readonly type = RelationshipTypeActionTypes.CREATE_VALID_RELATIONSHIP_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

export class CreateValidRelationshipTypeFailed implements Action {
    readonly type = RelationshipTypeActionTypes.CREATE_VALID_RELATIONSHIP_TYPE_FAILED;

    constructor(public payload: any) { }
}

export class EditRelationshipType implements Action {
    readonly type = RelationshipTypeActionTypes.EDIT_RELATIONSHIP_TYPE;

    constructor(public payload: {
        oldRelationshipTypeName: string;
        oldRelationshipTypeId: string;
        newRelationshipTypeName: string;
        relationshipTypeId: string;
    }) { }
}

export class EditRelationshipTypeSuccess implements Action {
    readonly type = RelationshipTypeActionTypes.EDIT_RELATIONSHIP_TYPE_SUCCESS;

    constructor(public payload: any) { }
}

export class EditRelationshipTypeFailed implements Action {
    readonly type = RelationshipTypeActionTypes.EDIT_RELATIONSHIP_TYPE_FAILED;

    constructor(public payload: any) { }
}

export type RelationshipTypeActions = Initialize | InitializeSuccess | InitializeFailed | GetValidRelationship | GetValidRelationshipSuccess | GetValidRelationshipFailed | DeleteRelationshipType | DeleteRelationshipTypeSuccess | DeleteRelationshipTypeFailed | CreateValidRelationshipType | CreateValidRelationshipTypeSuccess | CreateValidRelationshipTypeFailed | EditRelationshipType | EditRelationshipTypeSuccess | EditRelationshipTypeFailed;
