/**
 * Created by Abner Sui on 04/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { AppState } from '..';
import { DataproviderActionTypes, DataproviderActions } from '../actions/dataprovider.actions';
import { Dataprovider } from '../../tamalelibs/models/dataprovider.model';

export const dataproviderSelector = (state: AppState) => state.dataprovider;

export function dataproviderReducer(state: Array<Dataprovider>, action: DataproviderActions): Array<Dataprovider> {
    let newState;
    let index;
    switch (action.type) {
        case DataproviderActionTypes.INITIALIZE_SUCCESS:
            return action.payload;
        case DataproviderActionTypes.INITIALIZE_FAILED:
            return action.payload;
        case DataproviderActionTypes.INSERT_UPDATE_CONFIG:
            newState = Object.assign([], state);
            index = newState.findIndex(item => item.id === action.payload.id);
            if (index > -1) {
                newState.splice(index, 1);
            }
            newState.unshift(action.payload);
            return newState;
        case DataproviderActionTypes.DELETE_CONFIG:
            index = state.findIndex(item => item.id === action.payload);
            if (index > -1) {
                newState = Object.assign([], state);
                newState.splice(index, 1);
                return newState;
            } else {
                return state;
            }
        default:
            return state;
    }
}
