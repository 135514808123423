/**
 * Created by Abner Sui on 12/1/2020
 * -------------------------------------
 */
import { Observable } from 'rxjs';
import { ControlType } from '../../tamalelibs/models/template-control.model';
import { BriefConfig } from '../../widgets/widgets.model';
import { FieldValidationError } from '../work-flow/field/field.model';

export class Template {
    disabled: boolean;
    width: number;
    id?: string;
    config?: BriefConfig;
    component?: any;
    configComponent?: IControlConfigComponent | any;
    type?: ControlType;
    contains?: Array<Array<any>>;
    showValidateInfo: boolean;
    maxWidth?: number;
    fixWidth?: number;
    isDuplicated?: boolean;
}

export class TemplateComponentConfiguration {
    title: string;
    controls: Array<ControlBrief>;
    template: Template;
    service: TemplateConfigurationService;
    config: BriefConfig = new BriefConfig();
    invalidControlId: Array<string> = [];
    invalidDetail = new Map<string, FieldValidationError>();
    configChanged = false;
    isImport = false;
}

export interface IControlConfigComponent {

    /**
     * true - valid, false - invalid
     */
    validate: () => boolean;

    /**
     * gets validation failure details.
     *
     * @memberof IControlConfigComponent
     */
    getInvalidDetail: () => FieldValidationError;
}

export interface TemplateConfigurationService {
    addControl: (control: ControlBrief, template: Template) => Template;
    cancel: (configChanged: boolean) => any;
    deleteControl: (control: Template, template: Template) => any;
    formatTemplate: (template: Template, id: string) => any;
    formatFields: () => any;
    getInvalidControlId: (template: Template) => Array<string>;
    initializeTemplate: (template: Template) => Observable<Template>;
}

export class ControlBrief {
    name: string;
    type: ControlType;
    icon?: string;
    tid?: string;
}

export enum ControlConfigEvents {
    VALUE_CHANGE = 'VALUE_CHANGE',
    CHANGE_WIDTH = 'CHANGE_WIDTH',
}

export enum TemplateComponentConfigurationActions {
    LEAVE_TEMPLATE_MENU = 'LEAVE_TEMPLATE_MENU'
}
