import { combineReducers, createSelector, Action } from '@ngrx/store';
import { AppState } from '..';
import { autoTagModelReducer } from '../../tamalelibs/redux/auto-tagging.reducer';

export const getAutoTagViewState = (state: AppState) => state.autoTag;
export const getAutoTagModelState = createSelector(getAutoTagViewState, (state: any) => state.autoTagModel);

const autoTagViewReducer = combineReducers({
    autoTagModel: autoTagModelReducer,
});

export function getAutoTagViewRedcuer(state, action) {
    return autoTagViewReducer(state, action);
}
