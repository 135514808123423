/**
 * Created by Abner Sui on 01/08/2019.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Component, OnInit, Input } from '@angular/core';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../tamalelibs/components/filter-widget.config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DateHelperWebService } from '../../tamalelibs/services/date-helper.web.service';
import { DYNAMIC_DATE } from '../../tamalelibs/models/tile.model';

@Component({
    selector: 'tam-date-filter',
    templateUrl: 'tam-date-filter.component.html',
    styleUrls: ['tam-filter-container.component.scss']
})

export class TamDateFilterComponent implements OnInit {
    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event

    interval = 1;
    isCheckBlank = false;
    isItemHoverOn = false;
    dynamicDate: { name: string, value: string } = { name: 'YTD', value: 'YTD' };
    // Keep consistent with other data structures in this feature, we'd better put the data to model
    dynamicDateList: Array<{ name: string, value: string }> = [
        { value: 'YTD', name: 'YTD' }, // Year to Date
        { value: 'PRIOR-YTD', name: 'Prior YTD' },
        { value: 'H1-TO-DATE', name: 'H1 to Date' },
        { value: 'H2-TO-DATE', name: 'H2 to Date' },
        { value: 'QTD', name: 'QTD' }, // Quarter To Date
        { value: 'MTD', name: 'MTD' }, // Month to Date
    ];
    maxDate: Date = new Date(2099, 12, 12);
    minDate: Date = new Date(1900, 1, 1);
    operators: Array<{ name: string, value: string }>;
    operator: { name: string, value: string };
    range: { start: Date, end: Date } = { start: null, end: null };
    unit: { name: string, value: string } = { name: 'Day', value: 'day' };
    units: Array<{ name: string, value: string }> = [
        { name: 'Day', value: 'day' },
        { name: 'Week', value: 'week' },
        { name: 'Month', value: 'month' },
        { name: 'Year', value: 'year' }
    ];
    valueBeforeAfter: Date;

    private _after = 'after';
    private _blank = 'blank';
    private _before = 'before';
    private _format = 'MM/dd/yyyy';
    private _last = 'last';
    private _null = 'null';
    private _next = 'next';
    private _oneday = '1 day';
    private _range = 'range';
    private _today = 'today';

    constructor(
        private _deviceService: DeviceDetectorService,
    ) { }

    ngOnInit() {
        if (this.filterWidgetConfig.isShowDynamicDate) {
            this.operators = this.config.operators;
        } else {
            this.operators = this.config.operators.filter(item => item.value !== DYNAMIC_DATE);
        }

        this.operator = this.config.operators.find(item => item.value.toLowerCase() === this.config.selectedOperator.toLowerCase());
        if (this.operator.value.toLowerCase().indexOf(this._blank) > -1 || this.operator.value.toLowerCase().indexOf(this._null) > -1) {
            this.isCheckBlank = true;
        } else if (this.config.value) {
            if (this.operator.value === this._last || this.operator.value === this._next) {
                const tempValue: Array<string> = this.config.value.split(' ');
                if (tempValue.length > 1) {
                    this.interval = +tempValue[0];
                    this.unit = this.units.find(item => item.value === tempValue[1]);
                }
            } else if (this.operator.value === this._before || this.operator.value === this._after) {
                this.valueBeforeAfter = new Date(this.config.value.replace(/-/g, '/'));
            } else if (this.operator.value === this._range) {
                const tempValue: Array<string> = this.config.value.split(' ');
                if (tempValue[0]) {
                    this.range.start = new Date(tempValue[0].replace(/-/g, '/'));
                }
                if (tempValue[1]) {
                    this.range.end = new Date(tempValue[1].replace(/-/g, '/'));
                }
            } else if (this.operator.value === this._today) {
                this.isCheckBlank = true;
            } else if (this.operator.value === DYNAMIC_DATE) {
                const tempValue = this.config.value;
                this.dynamicDate = this.dynamicDateList.find(item => item.value === tempValue);
            }
        } else {
            this.interval = null;
            this.valueBeforeAfter = null;
            this.range = { start: null, end: null };
        }

        // For use within normal web clients
        const isiPad = this._deviceService.isMobile() || this._deviceService.isTablet();
        if (isiPad) {
            this.isItemHoverOn = true && !this.filterWidgetConfig.isHiddenTrashIcon;
        } else {
            this.isItemHoverOn = !this.config.deletable && !this.filterWidgetConfig.isHiddenTrashIcon;
        }

        // update store for filtersConfigList
        this._feedback();
    }

    inputBlur() {
        const today = new Date();
        let valueChange = false;
        if (this.operator.value === this._before || this.operator.value === this._after) {
            if (!this._validateDate(this.valueBeforeAfter)) {
                this.valueBeforeAfter = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                valueChange = true;
            }
        } else if (this.operator.value === this._range) {
            if (this.range.start !== null && !this._validateDate(this.range.start)) {
                this.range.start = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                valueChange = true;
            }
            if (this.range.end !== null && !this._validateDate(this.range.end)) {
                this.range.end = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                valueChange = true;
            }
        }
        if (valueChange) {
            this.valueChange();
        }
    }

    onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    onDynamicDateChange(value): void {
        this.dynamicDate = value;
        this.config.value = this.dynamicDate.value;
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true && !this.filterWidgetConfig.isHiddenTrashIcon;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    valueChange(): void {
        this.isCheckBlank = (this.operator.value.toLocaleLowerCase().indexOf(this._blank) > -1) || (this.operator.value.toLocaleLowerCase().indexOf(this._null) > -1) || (this.operator.value === this._today);
        // emits event for value change
        this._feedback();
    }

    private _feedback() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        this.config.selectedOperator = this.operator.value;
        this.config.value = null;
        if (this.operator.value === this._last || this.operator.value === this._next) {
            if (this.interval) {
                this.config.value = this.interval + ' ' + this.unit.value;
            } else {
                this.config.value = '';
            }
        } else if (this.operator.value === this._before || this.operator.value === this._after) {
            if (this._validateDate(this.valueBeforeAfter)) {
                this.config.value = DateHelperWebService.getDateString(this.valueBeforeAfter, this._format);
            }
        } else if (this.operator.value === this._range) {
            let startEnd = null;
            if (this._validateDate(this.range.start)) {
                startEnd = DateHelperWebService.getDateString(this.range.start, this._format);
            }
            if (this._validateDate(this.range.end)) {
                if (startEnd == null) {
                    startEnd = '';
                }
                startEnd += ' ' + DateHelperWebService.getDateString(this.range.end, this._format);
            }
            this.config.value = startEnd;
        } else if (this.operator.value.indexOf(this._blank) > -1 || this.operator.value.indexOf(this._null) > -1) {
            this.config.value = this.operator.value;
        } else if (this.operator.value === this._today) {
            this.config.value = this._oneday;
        } else if (this.operator.value === DYNAMIC_DATE) {
            this.config.value = this.dynamicDate.value;
        }

        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    private _validateDate(data: Date): boolean {
        if (!data || data.getTime() > this.maxDate.getTime() || data.getTime() < this.minDate.getTime()) {
            return false;
        }
        return true;
    }

}
