<div class="split-button-container">
    <button tam-button [tamType]="'primary'" [tamSize]="'normal'" [disabled]="isDisabled"
        (click)="onPrimaryButtonClick()">
        {{config?.primaryAction?.displayText}}
    </button>
    <button tam-button [tamType]="'primary'" [tamSize]="'normal'" [disabled]="isDisabled"
        (click)="showContextMenu($event)">
        <smart-icon name="angle-down"></smart-icon>
    </button>
    <tam-context-menu [config]="contextMenuConfig"></tam-context-menu>
</div>