<div class="container container-filter">
    <div *ngFor="let year of data; let i = index">
        <div class="label-container">
            <ng-container *ngIf="year.displayType && year.displayType!=='3'">
                <div class="blank-div"></div>
            </ng-container>
            <ng-container *ngIf="year.months.length > 0">
                <span *ngIf="year.expand" class="ag-icon ag-icon-tree-open" (click)="expandGroup(i)"></span>
                <span *ngIf="!year.expand" class="ag-icon ag-icon-tree-closed" (mousedown)="expandGroup(i)"></span>
            </ng-container>
            <tam-custom-grid-checkbox [checked]="year.check" (onChecked)="onChecked($event, i)">
            </tam-custom-grid-checkbox>
            <span></span>
            <span class="item-span">{{year.year}}</span>
        </div>
        <ng-container *ngIf="year.months.length > 0">
            <div *ngFor="let month of year.months; let j = index" class="child-div-container" [hidden]="!year.expand">
                <div class="label-container">
                    <ng-container *ngIf="month.dates.length === 0">
                        <div class="blank-div"></div>
                    </ng-container>
                    <ng-container *ngIf="month.dates.length > 0">
                        <span *ngIf="month.expand" class="ag-icon ag-icon-tree-open" (click)="expandGroup(i, j)"></span>
                        <span *ngIf="!month.expand" class="ag-icon ag-icon-tree-closed"
                            (click)="expandGroup(i, j)"></span>
                    </ng-container>
                    <tam-custom-grid-checkbox [checked]="month.check" (onChecked)="onChecked($event, i, j)">
                    </tam-custom-grid-checkbox>
                    <span class="item-span">{{month.month}}</span>
                </div>
                <ng-container *ngIf="month.dates.length > 0">
                    <div *ngFor="let date of month.dates; let k = index" class="child-div-container-2"
                        [hidden]="!month.expand">
                        <div class="label-container">
                            <tam-custom-grid-checkbox [checked]="date.check" (onChecked)="onChecked($event, i, j, k)">
                            </tam-custom-grid-checkbox>
                            <span class="item-span">{{date.date}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>