<div class="container">
    <div class="row">
        <input type="text" #searchInput placeholder="Search" [(ngModel)]="search" class="searchInput"
            (ngModelChange)="onSearchChange($event)">
        <div class="icon-search" *ngIf="search.trim()">
            <smart-icon name="close" (click)="clearSearch()"></smart-icon>
        </div>
    </div>
    <div class="row select-all">
        <div *ngIf="selectAll==='all'" class="label-container" (click)="onSelectedAll('none')">
            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-checked">
                <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
            </div>
            <span class="item-span">{{search.trim() ? '(Select All Search Results)' : '(Select All)'}}</span>
        </div>
        <div *ngIf="selectAll==='none'" class="label-container" (click)="onSelectedAll('all')">
            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-unchecked">
                <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
            </div>
            <span class="item-span">{{search.trim() ? '(Select All Search Results)' : '(Select All)'}}</span>
        </div>
        <div *ngIf="selectAll==='some'" class="label-container" (click)="onSelectedAll('all')">
            <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-indeterminate">
                <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
            </div>
            <span class="item-span">{{search.trim() ? '(Select All Search Results)' : '(Select All)'}}</span>
        </div>
    </div>
    <ng-container *ngIf="!isDateColumn">
        <div *ngFor="let item of data | sortBy" class="row">
            <div *ngIf="item.show">
                <div *ngIf="item.check" class="label-container" (click)="onSelected(item)">
                    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-checked">
                        <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
                    </div>
                    <span class="item-span">{{item.textFormat}}</span>
                </div>
                <div *ngIf="!item.check" class="label-container" (click)="onSelected(item)">
                    <div ref="eWrapper" class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ag-unchecked">
                        <input ref="eInput" class="ag-input-field-input ag-checkbox-input" type="checkbox">
                    </div>
                    <span class="item-span">{{item.textFormat}}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isDateColumn">
        <tam-custom-date-column-filter [config]="customDateColumnFilterConfig"></tam-custom-date-column-filter>
    </ng-container>
</div>