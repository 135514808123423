/**
 * Created by Marcus Zhao on 8/25/2020.
 * Description:
 *
 * ------ maintenance history ------
 */


import { FlyDialogActionTypes, FlyIndexActions } from '../actions/fly-dialog.actions';
import { AppState } from '..';

const initialState = 0;

export const getflyDialogIndexState = (state: AppState) => state.flyDialogIndex;

export function getflyDialogIndexReducer(state: number = initialState, action: FlyIndexActions) {
    switch (action.type) {
        case FlyDialogActionTypes.INCREMENT:
            return state + 1;
        case FlyDialogActionTypes.DECREMENT:
            if (state >= 1) {
                return state - 1;
            } else {
                return 0;
            }
        case FlyDialogActionTypes.RESET:
            return 0;
        default:
            return state;
    }
}

