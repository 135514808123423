/**
 * ------ maintenance history ------
 * 06/05/2019   Yoyo Fang   add getUserFromSsoCookieInfo for retrieving the info setted in cookie when redirected to '/sso' after success sso login
 */
import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { AppState } from '../redux';
import { StorageService } from './storage.service';
import { SSOCookiesKey } from '../constants/business.constants';
import { SystemUser } from '../tamalelibs/models/user.model';
import { CookieService } from 'ngx-cookie-service';
import { UserMessages } from '../constants/userMessages.constants';
import { SessionTimeout } from '../redux/actions/auth.actions';
import * as moment from 'moment';
import { constant } from 'lodash';
import { AppConfig } from '../tamalelibs/models/app-config.model';
import { AddInUtilities } from './utilities/addin-utilities';

@Injectable()
export class AuthHelperService {
    constructor(
        private _store: Store<AppState>,
        private _router: Router,
        private _storageService: StorageService,
        private _cookieService: CookieService,
    ) { }
    getUserId() {
        let id = '';
        const user = this._storageService.getItem('user');
        if (user && user.id) {
            id = user.id;
        }
        return id;
    }

    getToken() {
        let token = '';
        let user = new SystemUser();
        if (AddInUtilities.isOfficeEnvironment()) {
            // for web office 365, we could not retrieve token from cookie, so get it from localstorage instead.
            if (localStorage.getItem('user')) {
                user = JSON.parse(localStorage.getItem('user'));
                token = user.token;
            } else {
                // for sso on web safari, there is a case that the local storage is null but user exists in cookie
                user = this._storageService.getItem('user');
                if (user && user.token) {
                    token = user.token;
                }
            }
        } else {
            user = this._storageService.getItem('user');
            if (user && user.token) {
                token = user.token;
            }
        }
        return token;
    }

    getSsoSettings() {
        let ssoSetting;
        this._store.pipe(
            select('ssoSetting')
        ).subscribe(sso => ssoSetting = sso).unsubscribe();
        return ssoSetting;
    }

    getFromUrl(): string {
        let fromUrl = '';
        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(e => {
            fromUrl = (e as NavigationEnd).url;
        }).unsubscribe();
        return fromUrl;
    }

    redoAuthorization(ssoSetting, toUrl, fromUrl, isOfficeAddin = false): boolean {
        if (ssoSetting?.ssoEnabled) {
            const paraObj = { path: toUrl, timezoneID: moment.tz.guess() };
            if (AddInUtilities.isOfficeJSLoaded()) {
                paraObj['requestSource'] = 'ADDIN';
            }
            let url = '';
            if (ssoSetting.idpSsoUrl.indexOf('?') > 0) {
                if (ssoSetting.idpSsoUrl.indexOf('?RelayState=') > 0) { // adfs
                    url = `${ssoSetting.idpSsoUrl}?RelayState=${encodeURIComponent(JSON.stringify(paraObj))}`;
                } else { // Asure
                    url = `${ssoSetting.idpSsoUrl}&RelayState=${encodeURIComponent(JSON.stringify(paraObj))}`;
                }
            } else { // Okta
                url = `${ssoSetting.idpSsoUrl}?RelayState=${encodeURIComponent(JSON.stringify(paraObj))}`;
            }
            window.open(url, '_self');

            return false;
        } else {
            const warningMsg = fromUrl.startsWith('/login') || !fromUrl ? '' : UserMessages.onSessionTimeout;
            this._store.dispatch(new SessionTimeout(warningMsg, toUrl));
            return false;
        }
    }

    getUserFromSsoCookieInfo(): any {
        try {
            let cookieInfoBase64 = this._cookieService.get(SSOCookiesKey);
            if (cookieInfoBase64) {
                cookieInfoBase64 = cookieInfoBase64.replace(/"/g, '');
                const userInfo = JSON.parse(atob(cookieInfoBase64));
                return { user: SystemUser.parse(userInfo), relayState: userInfo.userinfo.relayState };
            } else {
                return null;
            }
        } catch (error) {
            console.error(error);
            return null;
        }
    }
}
