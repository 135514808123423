/**
 * Created by Marcus Zhao on 8/27/19.
 * Description: add calendarService option for Create/Edit Calendar Event
 *
 * ------ maintenance history ------
 *
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CalendarDialogService {
    dialogOpen$: Subject<any> = new Subject<any>();
    dialogClose$: Subject<any> = new Subject<any>();
    minimized = false;
    opened = false;
    openedAddin = false; // used to record status when user switches emails from calendar dialog in pin mode for email add-in. TAM-39218
}
