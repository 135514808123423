/**
 * Create by Bowen: 2019/12/1
 * Description:
 * Add entity/contact to relationship
 * ------ maintenance history ------
 */
import { Injectable, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { RelationshipTreeNode } from '../relationship-tree.view-model';
import { FiltersConfig } from '../../../tamalelibs/components/filter-config';
import { TSGuid } from '../../../tamalelibs/models/ts-guid.model';
import { EntityBrief } from '../../../tamalelibs/models/entity-brief.model';
import { RelationshipType } from '../../../tamalelibs/models/relationship-type.model';

@Injectable({
    providedIn: 'root'
})
export class AddRelationshipEntityDialogService {
    openDialog$: Subject<any> = new Subject();
    addEntityToRelationship$: Subject<any> = new Subject();
    FiltersConfig$: Subject<any> = new Subject();
    constructor() { }

    openAddEntityDialog(dialogTitle: string, operation: string, currentRelationshipType: RelationshipType, rootNode: RelationshipTreeNode,
        selectedItemValidRelationshipType: Array<any>,
        totalValidRelationshipType: Array<any>, dialogCheckboxConfig: object, eventKey?: string, eventValue?: string): Subject<any> {

        this.openDialog$.next({
            type: 'openDialog',
            payload:
            {
                dialogTitle: dialogTitle,
                operation: operation,
                currentRelationshipType: currentRelationshipType,
                rootNode: rootNode,
                selectedItemValidRelationshipType: selectedItemValidRelationshipType,
                totalValidRelationshipType: totalValidRelationshipType,
                dialogCheckboxConfig: dialogCheckboxConfig,
                eventKey: eventKey,
                eventValue: eventValue
            }
        });
        return this.addEntityToRelationship$;
    }

    // this config will include controlID
    getFilterConfig(textField, valueField, source, metadataType, rootNode, viewContainer): FiltersConfig {
        const filtersConfig = new FiltersConfig();
        filtersConfig.id = new TSGuid().toString();
        filtersConfig.title = textField;
        filtersConfig.fields = valueField;
        filtersConfig.disable = false; // by default
        filtersConfig.deletable = false;
        filtersConfig.source = { 'entityType': source };
        filtersConfig.value = [];
        filtersConfig.metadataType = metadataType;
        // below vars is not useful for this dialog
        filtersConfig.hideOperators = true;
        filtersConfig.operators = [{ name: '', value: '' }];
        filtersConfig.selectedOperator = '';
        filtersConfig.viewContainer = viewContainer;
        filtersConfig.currentUser = this._getCurrentUserConfig(rootNode);
        return filtersConfig;
    }

    private _getCurrentUserConfig(rootNode: RelationshipTreeNode) {
        const currentUser = new EntityBrief(rootNode.id, rootNode.name);
        currentUser.isPublic = rootNode.isPublic;
        currentUser.shortName = rootNode.shortName;
        currentUser.type = rootNode.type;
        return currentUser;
    }
}
