/**
 * Created by Abner Sui on 06/30/2020
 * -------------------------------------
 */

import { BriefConfig } from '../../widgets/widgets.model';
import { TamUploadUIFile } from '../../widgets/tam-upload/tam-upload.model';

export class FileUploadAction {
    public type: FileUploadActionTypes;
    public payload: any;
}

export class FileUploadFeedbackAction {
    public type: FileUploadFeedbackTypes;
    public payload: any;
}

export enum FileUploadActionTypes {
    GetAttachments = 0,
    DeleteAttachments = 2,
}

export enum FileUploadFeedbackTypes {
    OnStatusChange = 0,
    ReturnAttachments = 1,
    ClearAttachments = 2,
}

export class FileUploadConfig extends BriefConfig {
    files: Array<TamUploadUIFile>;
    disabled: boolean;
    height?: number;

    constructor() {
        super();
        this.files = [];
        this.disabled = false;
    }
}
