/**
 * created by Yu Zhang on 7/5/18
 * Description:
 *
 * ------ maintenance history ------
 * 06/02/2020 add CHANGE_CONTACT_TEMPLATE_VALUE_SET
 * 08/10/2020 Marcus add  ChangeContactTemplateValueSet CreateContact EditContact
 * 08/25/2020 Marcus add  CleanContactTemplateValueSet CleanTemplateMsg
 * 09/07/2020 Bowen add ADD_JOB_TITLE, REMOVE_JOB_TITLE
 */
import { Action } from '@ngrx/store';

export const CLEAR_CONTACT_MSG = 'CLEAR_CONTACT_MSG';
export const CHANGE_CONTACT_TEMPLATE_VALUE_SET = 'CHANGE_CONTACT_TEMPLATE_VALUE_SET';
export const CHANGE_CONTACT_TEMPLATE_LAYOUT_SET = 'CHANGE_CONTACT_TEMPLATE_LAYOUT_SET';
export const CLEAN_CONTACT_TEMPLATE_VALUE_SET = 'CLEAN_CONTACT_TEMPLATE_VALUE_SET';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const CLEAN_CONTACT_TEMPLATE_MSG = 'CLEAN_CONTACT_TEMPLATE_MSG';
export const ADD_JOB_TITLE = 'ADD_JOB_TITLE';
export const REMOVE_JOB_TITLE = 'REMOVE_JOB_TITLE';
export const CLEAR_JOB_TITLE = 'CLEAR_JOB_TITLE';
export const CONTACT_EMAIL_COUNT = 'CONTACT_EMAIL_COUNT';
export const ADD_DUPLICATED_CONTACT = 'ADD_DUPLICATED_CONTACT';
export const CLEAR_DUPLICATED_CONTACT = 'CLEAR_DUPLICATED_CONTACT';
export const SAVE_CONTACT_TEMPLATE = 'SAVE_CONTACT_TEMPLATE';

export enum ContactEmailActionTypes {
    EMAIL_INCREMENT = 'EMAIL_INCREMENT',
    EMAIL_DECREMENT = 'EMAIL_DECREMENT',
    EMAIL_RESET = 'EMAIL_RESET'
}
export class ContactTemplateAction {
    constructor(public payload, prefixName: string, prefixValue: string) {
        if (prefixName && prefixValue) {
            this[prefixName] = prefixValue;
        }
    }
}

export class ChangeContactTemplateValueSet extends ContactTemplateAction implements Action {
    readonly type = CHANGE_CONTACT_TEMPLATE_VALUE_SET;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class ChangeContactTemplateLayoutSet extends ContactTemplateAction implements Action {
    readonly type = CHANGE_CONTACT_TEMPLATE_LAYOUT_SET;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateContact extends ContactTemplateAction implements Action {
    readonly type = CREATE_CONTACT;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class EditContact extends ContactTemplateAction implements Action {
    readonly type = EDIT_CONTACT;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CleanTemplateMsg extends ContactTemplateAction implements Action {
    readonly type = CLEAR_CONTACT_MSG;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CleanContactTemplateValueSet extends ContactTemplateAction implements Action {
    readonly type = CLEAN_CONTACT_TEMPLATE_VALUE_SET;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class ContactEmailCount extends ContactTemplateAction implements Action {
    readonly type = CONTACT_EMAIL_COUNT;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}
export class IncremnetIndex extends ContactTemplateAction implements Action {
    readonly type = ContactEmailActionTypes.EMAIL_INCREMENT;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class DecremnetIndex extends ContactTemplateAction implements Action {
    readonly type = ContactEmailActionTypes.EMAIL_DECREMENT;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class ResetIndex extends ContactTemplateAction implements Action {
    readonly type = ContactEmailActionTypes.EMAIL_RESET;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}
export type ContactEmailIndexActions = IncremnetIndex | DecremnetIndex | ResetIndex;

