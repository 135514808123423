/**
 * ------ maintenance history ------
 * Updated by Abner Sui on 05/08/2019.
 * Change ssoEndpoint to globalConfigEndpoint to reduce requests
 * Updated by Abner Sui on 05/28/2019.
 * Add all system config in globalConfigEndpoint to reduce requests
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';

import { SsoSetting, SystemConfig } from './models/global-setting';
import { AppState } from "./redux";
import { GlobalConfigActionTypes } from './redux/actions/global-config.actions';
import { StorageService } from './services/storage.service';
import { LoginSuccess } from './redux/actions/auth.actions';
import { SystemActionTypes } from './tamalelibs/redux/actions/system.actions';
import { EntityBrief } from './tamalelibs/models/entity-brief.model';
import { Subject } from 'rxjs';
import { AddInUtilities } from './services/utilities/addin-utilities';
import { SystemUser } from './tamalelibs/models/user.model';
import { AppConfig } from './tamalelibs/models/app-config.model';

export const CONTACT_ENDPOINT = '/restapi/2.0/contact/';
export const INITIAL_CONFIG_ENDPOINT = '/restapi/2.0/globalconfig/initialconfig';
export const GLOBAL_CONFIG_ENDPOINT = '/restapi/2.0/globalconfig';
export const TOKEN_ENDPOINT = '/restapi/2.0/token/';

@Injectable({
    providedIn: 'root',
})
export class AppLoadService {
    actionSubject$ = new Subject();

    private _webTokenDesKey: string; // used to store the encryption key for the web token

    /**
     * get the encryption key for the web token
     */
    get getWebTokenDesKey() {
        return this._webTokenDesKey;
    }

    /**
     * set the encryption key for the web token
     */
    set setWebTokenDesKey(value) {
        if (value) {
            this._webTokenDesKey = value;
        }
    }

    constructor(
        private _httpClient: HttpClient,
        private _storageService: StorageService,
        private _store: Store<AppState>
    ) { }

    initSsoValue(): Promise<any> {
        const promise = this._httpClient
            .get(INITIAL_CONFIG_ENDPOINT)
            .toPromise()
            .then((response: any) => {
                if (
                    response &&
                    response["sso"] &&
                    response["sso"].enableSSO !== undefined
                ) {
                    this._store.dispatch({
                        type: GlobalConfigActionTypes.SSO_SET,
                        payload: new SsoSetting(
                            response["sso"].enableSSO,
                            response["sso"].idphome,
                            response["sso"].message
                        ),
                    });
                } else {
                    this._store.dispatch({
                        type: GlobalConfigActionTypes.SSO_SET,
                        payload: new SsoSetting(false),
                    });
                }
                // set the encryption key for the web token if it exists
                if (response && response['webTokenDesKey']) {
                    this.setWebTokenDesKey = response['webTokenDesKey'];
                }
            })
            .catch(() => {
                this._store.dispatch({
                    type: GlobalConfigActionTypes.SSO_SET,
                    payload: new SsoSetting(false),
                });
            });

        return promise;
    }

    initSsoSetting(): Promise<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;',
            'Authorization': `[token${AppConfig.token}token]`
        };
        const promise = this._httpClient
            .get(GLOBAL_CONFIG_ENDPOINT, { headers: headers })
            .toPromise()
            .then((response: any) => {
                if (
                    response &&
                    response["sso"] &&
                    response["sso"].enableSSO !== undefined
                ) {
                    this._store.dispatch({
                        type: GlobalConfigActionTypes.SSO_SET,
                        payload: new SsoSetting(
                            response["sso"].enableSSO,
                            response["sso"].idphome,
                            response["sso"].message
                        ),
                    });
                } else {
                    this._store.dispatch({
                        type: GlobalConfigActionTypes.SSO_SET,
                        payload: new SsoSetting(false),
                    });
                }

                // set the encryption key for the web token if it exists
                if (response && response['webTokenDesKey']) {
                    this.setWebTokenDesKey = response['webTokenDesKey'];
                }

                this._store.dispatch({
                    type: GlobalConfigActionTypes.SYSTEM_CONFIG_SET,
                    payload: SystemConfig.parse(response),
                });
            })
            .catch(() => {
                this._store.dispatch({
                    type: GlobalConfigActionTypes.SSO_SET,
                    payload: new SsoSetting(false),
                });
                this._store.dispatch({
                    type: GlobalConfigActionTypes.SYSTEM_CONFIG_SET,
                    payload: new SystemConfig(),
                });
            });

        return promise;
    }

    checkLocalToken(): Promise<any> {
        let user = new SystemUser();

        if (AddInUtilities.isOfficeEnvironment()) {
            if (localStorage.getItem("user")) {
                user = JSON.parse(localStorage.getItem("user"));
            }
        } else {
            user = this._storageService.getItem("user");
        }

        if (user == null) {
            return Promise.resolve();
        } else {
            let url = `${TOKEN_ENDPOINT}${user.token}`;
            const promise = this._httpClient
                .get(url)
                .toPromise()
                .then((response: any) => {
                    if (response === false) {
                        if (AddInUtilities.isOfficeEnvironment()) {
                            localStorage.removeItem("user");
                        }
                        this._storageService.removeItem("user");
                        // this._store.dispatch(new Logout());
                        return;
                    } else {
                        url = `${CONTACT_ENDPOINT}${user.id}`;
                        const params = {
                            expand: "contact-details",
                            fields: "id;short-name;long-name;entity-type;contact-details;is-public",
                            outputformat: "json",
                        };
                        const headers = {
                            Authorization: `[token${user.token}token]`,
                        };
                        return this._httpClient
                            .get(url, { headers: headers, params: params })
                            .toPromise();
                    }
                })
                .then((response: any) => {
                    if (response) {
                        this._store.dispatch({
                            type: SystemActionTypes.INIT_CURRENT_CONTACT_SUCCESS,
                            payload: EntityBrief.parse(response),
                        });
                        this._store.dispatch(new LoginSuccess(user));
                    }
                });

            return promise;
        }
    }
}
