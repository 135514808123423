/**
 * Created by Daniel on 12/13/2020.
 * Description:
 *
 * ------ maintenance history ------
 */

import { combineReducers, createSelector } from '@ngrx/store';
import { AppState } from '..';
import { EntityActions, EntityActionTypes } from '../actions/entity.actions';

export const entitySelector = (state: AppState) => state.entities;
export const allEntitiesSelector = createSelector(entitySelector, (state: any) => state.allEntities);

export function entitiesReducer(state: any = {}, action: EntityActions) {
    switch (action.type) {
        case EntityActionTypes.INITIALIZE_SUCCESS:
            return action.payload;
        case EntityActionTypes.INITIALIZE_FAILED:
            return action.payload;
        default:
            return state;
    }
}

const entityCombineReducer = combineReducers({
    allEntities: entitiesReducer
});

export function getEntityRedcuer(state, action) {
    return entityCombineReducer(state, action);
}
