/**
 * Created by Abner Sui on 04/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 * updated by Alan Yang 8/27/2019
 * 1. Added methods for SQL MDL.
 * 2. Merged Flatfile API here.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { Dataprovider } from '../models/dataprovider.model';

@Injectable()
export class DataProviderService {
    constructor(
        private _transportService: TransportService
    ) { }

    getAll(): Observable<any> {
        const url = `${AppConfig.dataproviderEndPoint}`;
        return this._transportService.get(url);
    }

    parseResponse(response): Array<Dataprovider> {
        const result: Array<Dataprovider> = [];
        response['provider-list'].forEach(oneResponse => {
            result.push(Dataprovider.parse(oneResponse));
        });
        return result;
    }

    queryById(id) {
        const url = `${AppConfig.dataproviderEndPoint}` + id;
        return this._transportService.get(url);
    }

    remove(id) {
        const url = `${AppConfig.dataproviderEndPoint}` + id;
        return this._transportService.delete(url);
    }

    createFlatfile(data, attachment) {
        const url = `${AppConfig.dataproviderEndPoint}`;
        const formData = new FormData();
        formData.append('provider', JSON.stringify(data));
        formData.append('attachment', attachment);
        return this._transportService.post(url, formData);
    }

    updateFlatfile(id, data, attachment) {
        const url = `${AppConfig.dataproviderEndPoint}` + id;
        const formData = new FormData();
        if (data) {
            formData.append('provider', JSON.stringify(data));
        }
        if (attachment) {
            formData.append('attachment', attachment);
        }
        return this._transportService.put(url, formData);
    }

    getSQLDatabases(data): Observable<any> {
        const url = `${AppConfig.dataproviderEndPoint}databases`;
        const formData = new FormData();
        formData.append('provider', JSON.stringify(data));
        return this._transportService.post(url, formData);
    }

    getSQLPreview(data): Observable<any> {
        const url = `${AppConfig.dataproviderEndPoint}preview`;
        const formData = new FormData();
        formData.append('provider', JSON.stringify(data));
        return this._transportService.post(url, formData);
    }

    createSQLItem(data): Observable<any> {
        const url = `${AppConfig.dataproviderEndPoint}`;
        const formData = new FormData();
        formData.append('provider', JSON.stringify(data));
        return this._transportService.post(url, formData);
    }

    updateSQLItem(id, data) {
        const url = `${AppConfig.dataproviderEndPoint}` + id;
        const formData = new FormData();
        formData.append('provider', JSON.stringify(data));
        return this._transportService.put(url, formData);
    }
}
