/**
 * Created by Yoyo Fang on 05/06/19.
 * Description:
 *
 * ------ maintenance history ------
 */
export const RELOAD_ENTITIES = 'RELOAD_ENTITIES';
export const RELOAD_ENTITIES_SUCCESS = 'RELOAD_ENTITIES_SUCCESS';
export const RELOAD_ENTITIES_FAIL = 'RELOAD_ENTITIES_FAIL';

export const DELETE_ENTITY = 'DELETE_ENTITY';
export const DELETE_ENTITY_SUCCESS = 'DELETE_ENTITY_SUCCESS';
export const DELETE_ENTITY_FAIL = 'DELETE_ENTITY_FAIL';
