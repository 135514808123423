import { Component, OnInit, ElementRef, Renderer2, Input } from '@angular/core';
import { UpdateHostClassService } from '../../services/update-host-class.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: '[tam-button]',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    providers: [
        UpdateHostClassService
    ]
})
export class ButtonComponent implements OnInit {
    private el: HTMLElement;
    private prefixCls = 'tam-btn';

    private _invert: boolean;
    private _loading: boolean;
    private _size: string;
    private _type: string;

    private _isDisabled: boolean;

    @Input()
    set tamType(value: string) {
        this._type = value;
        this.setClassMap();
    }
    get tamType(): string {
        return this._type;
    }

    @Input()
    set tamSize(value: string) {
        this._size = value;
        this.setClassMap();
    }

    get tamSize(): string {
        return this._size;
    }

    @Input()
    set tamInvert(value: boolean) {
        this._invert = value;
        this.setClassMap();
    }

    get tamInvert(): boolean {
        return this._invert;
    }

    @Input()
    set tamLoading(value: boolean) {
        this._loading = value;
        // should also disable the button in loading status
        // need to handle the case that the button is already disabled.
        if (this._loading === true) {
            this._isDisabled = this.el.hasAttribute('disabled');
            if (!this._isDisabled) {
                this.renderer.setAttribute(this.el, 'disabled', 'true');
            }
        } else {
            if (!this._isDisabled) {
                this.renderer.removeAttribute(this.el, 'disabled');
            }
        }
    }

    @Input()
    set disabled(value: boolean) {
        if (value) {
            this._isDisabled = true;
            this.renderer.setAttribute(this.el, 'disabled', 'true');
        } else {
            this._isDisabled = false;
            this.renderer.removeAttribute(this.el, 'disabled');
        }
    }

    get tamLoading(): boolean {
        return this._loading;
    }

    constructor(private elementRef: ElementRef, private renderer: Renderer2, private updateHostClassService: UpdateHostClassService) {
        this.el = this.elementRef.nativeElement;
        this.renderer.addClass(this.el, 'tam-btn');
        this._isDisabled = this.el.hasAttribute('disabled');
    }

    ngOnInit() {
    }

    setClassMap(): void {
        const classMap = {
            [`${this.prefixCls}-${this.tamType}`]: this.tamType,
            [`${this.prefixCls}-${this.tamSize}`]: this.tamSize,
            [`${this.prefixCls}-invert`]: this.tamInvert,
        };

        this.updateHostClassService.updateHostClass(this.el, classMap);
    }
}
