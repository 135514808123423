import { ViewContainerRef } from '@angular/core';

/**
 * created by Yu Zhang on 8/22/17.
 * Description:
 *
 * ------ maintenance history ------
 * 07/20/2019 Marcus Zhao - add params for entry.
 * 06/21/2022 Marcus Zhao - add staticDefaultObject to set value directly.
 */
export class TemplateControlConfig {
    label: string;
    isRequired: boolean;
    componentType: string;
    controlID: string;
    adhocDefID: string;
    saveActions: any;
    isHidden: boolean;
    source: any = {
        items: []
    };
    defaultValue: any;
    staticDefaultValue: any;
    staticDefaultObject?: Object;
    fileLookup: any;
    maxValue: any;
    noteAction: string;
    disabled: boolean; // mark if current control should be disabled
    isLabelHidden: boolean;
    options: any;
    controlWidth = 0;
    width = 0;
    mdlProvider: MdlProvider;
    entityId?: string; // only edit entity need this param
    prefixId?: any; // add prefixeId for child component
    entityAction?: string;
    isDraft?: boolean;
    useDefaultValue?: boolean; // for edit note
    fromEditSwitch?: boolean;
    viewContainer: ViewContainerRef | string; // For kendo popup
    isVerticalTemplate: boolean;
}
/**
 * add MdlProvider model
 */
export class MdlProvider {
    fieldname: String;
    mdlfieldname: String;
    providername: String;
    value: String;
    mdlsource?: String;

    static isWebSource(provider: MdlProvider): boolean {
        return provider && provider.mdlsource && provider.mdlsource.toLowerCase() === 'web';
    }
}
