/**
 * Created by Abner Sui on 01/28/2019.
 * Description:
 *
 * ------ maintenance history ------
 */

import { EntryClass } from './entry-class.model';
import { DocumentEmail } from './document-email.model';
import { EntityBrief } from './entity-brief.model';

export class BlastEmail {
    id: string;
    time?: Date;
    entities?: Array<EntityBrief>;
    fromPersonName?: string;
    subject?: string;
    fromEmailAddress?: string;
    contact?: EntityBrief;
    entryClass?: EntryClass;
    fromPersonNameDuplicate?: boolean;
    type?: string;

    attachments: Map<string, DocumentEmail>;
    body?: string;
    originInfo?: string;

    constructor() {
        this.attachments = new Map();
        this.body = '';
        this.entities = [];
        this.entryClass = EntryClass.Note;
        this.type = 'Undeposited Blast Email';
    }

    static parse(serverResponse): BlastEmail {
        const email = new BlastEmail();

        email.time = new Date(serverResponse['time']);
        email.entities = serverResponse.entities.data.map(item => EntityBrief.parse(item.data));
        email.id = serverResponse.id;
        email.fromPersonName = serverResponse['details'].fromPersonName;
        email.subject = serverResponse['details'].subject;
        email.fromEmailAddress = serverResponse['details'].fromEmailAddress;
        if (serverResponse['details'].contact) {
            email.contact = EntityBrief.parse(serverResponse['details'].contact.data);
        }
        email.fromPersonNameDuplicate = serverResponse['fromPersonNameDuplicate'];
        // email.type = serverResponse['type'];

        if (serverResponse['details']) {
            if (serverResponse['details'].attachments) {
                serverResponse['details'].attachments.forEach(element => {
                    const documentEmail = DocumentEmail.parseForBlastEmail(element);
                    email.attachments.set(documentEmail.id, documentEmail);
                });
            }
            if (serverResponse['details'].originInfo) {
                email.originInfo = serverResponse['details'].originInfo;
            }
        }

        return email;
    }

}
