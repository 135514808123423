<div class="container" tamDropDownOutside (clickOutside)="onClickOutside($event)">
    <input type="text" readonly [value]="inputValue" (focus)="showPopup($event)" *ngIf="!hideInput" class="textInput"
        [disabled]='config.disabledInput'>
    <div *ngIf="hideInput">
        <ng-container *ngTemplateOutlet="customContent"></ng-container>
    </div>
    <kendo-popup [anchor]="anchor" *ngIf="showEntityDropdown">
        <div class="selectContainer">
            <div class="row" style="position: relative;">
                <input type="text" placeholder="Search" [(ngModel)]="searchValue" class="searchInput"
                    (input)="searchData()">
                <div class="icon-search">
                    <smart-icon name="search"></smart-icon>
                </div>
            </div>
            <div class="items">
                <div *ngIf="items.length > 0">
                    <div *ngFor="let item of items;trackBy:trackByFn" class="row">
                        <div (click)="onItemClick(item, $event)" class="label-container">
                            <tam-entity-dropdown-item [entity]="item"></tam-entity-dropdown-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </kendo-popup>
</div>