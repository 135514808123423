/**
 * Created by Jiu Chen on 01/11/2019.
 * Description:
 *
 * ------ maintenance history ------
 * Daniel 7/12/2019 Added screen/entity dictionary reducer to avoid send duplicate request.
 */

import { ScreenActions, ScreenActionTypes } from '../actions/screen.actions';
import { AppState } from '..';
import { createSelector, combineReducers } from '@ngrx/store';
import { Thread } from '../../tamalelibs/models/thread.model';
import { Entity } from '../../tamalelibs/models/entity.model';

export function screenReducer(state: any = {}, action: ScreenActions) {
    switch (action.type) {
        case ScreenActionTypes.SET_VALUE:
            return action.payload;
        default:
            return state;
    }
}

export function savedScreenDictionaryReducer(state: Map<string, Thread> = new Map(), action: ScreenActions) {
    switch (action.type) {
        case ScreenActionTypes.SCREEN_DICTIONARY:
            state.set(action.payload.id, action.payload);
            return state;
        case ScreenActionTypes.SCREEN_DICTIONARY_DELETE:
            state.delete(action.payload.id);
            return state;
        default:
            return state;
    }
}

export function screenEntityDictionaryReducer(state: Map<string, Entity> = new Map(), action: ScreenActions) {
    switch (action.type) {
        case ScreenActionTypes.SCREEN_ENTITY_DICTIONARY:
            state.set(action.payload.id, action.payload);
            return state;
        default:
            return state;
    }
}

export function ScreenStatusDictionaryReducer(state: Array<string> = [], action: ScreenActions) {
    switch (action.type) {
        case ScreenActionTypes.SCREEN_STATUS_DICTIONARY:
            if (!state.includes(action.payload)) {
                state.push(action.payload);
            }
            return state;
        case ScreenActionTypes.SCREEN_STATUS_DICTIONARY_CLEAR:
            state = [];
            return state;
        default:
            return state;
    }
}

export const getScreenState = (state: AppState) => state.savedscreen;
export const getScreenDictionarySelector = createSelector(getScreenState, (state: any) => state.savedScreenDictionary);
export const getScreenEntityDictionarySelector = createSelector(getScreenState, (state: any) => state.screenEntityDictionary);
export const getScreenStatusDictionarySelector = createSelector(getScreenState, (state: any) => state.screenStatusDictionary);

const savedScreenReducer = combineReducers({
    savedScreenDictionary: savedScreenDictionaryReducer,
    screenEntityDictionary: screenEntityDictionaryReducer,
    screenStatusDictionary: ScreenStatusDictionaryReducer
});

export function getSavedScreenReducer(state, action) {
    return savedScreenReducer(state, action);
}
