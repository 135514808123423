/**
 * Created by Yoyo Fang on 06/06/2020.
 * Description:
 *
 * ------ maintenance history ------
 * Updated by Daniel Wang on 04/15/2022, Added get splash image endpoint.
 */

import { Injectable } from '@angular/core';
import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { IntralinksFile, IntralinksValidationObject, IntralinksMapping } from '../models/intralinks.model';

@Injectable()
export class IntralinksService {
    allSelectedFiles: Map<string, IntralinksFile[]> = new Map();
    constructor(
        private _transportService: TransportService,
    ) { }

    acceptSplash(workspaceId: string, input: IntralinksValidationObject) {
        return this._splashMFARequest(workspaceId, input);
    }

    chooseMFA(workspaceId: string, input: IntralinksValidationObject) {
        return this._splashMFARequest(workspaceId, input);
    }

    deleteAccount(screenId: string) {
        const url = AppConfig.firmSettingEndpoint + 'intralinks/' + screenId;
        return this._transportService.delete(url);
    }

    // getFolderOfFolder
    getContentOfFolder(screenId: string, workspaceId: string, folderId: string, newSince: string = null) {
        const url = AppConfig.intralinksEndPoint + 'workspaces/' + workspaceId + '/folders/' + folderId + '/contents';
        const headers = {
            'Content-Type': 'application/json',
        };
        const params = {
            'screen-id': screenId
        };
        if (newSince) {
            params['new-since'] = newSince;
        }
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    // getFolderOfWorkspace
    getFolderOfWorkspace(screenId: string, workspaceId: string) {
        const url = AppConfig.intralinksEndPoint + 'workspaces/' + workspaceId + '/folders';
        const headers = {
            'Content-Type': 'application/json',
        };
        const params = {
            'screen-id': screenId,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getSplashImage(workspaceId: string, screenId: string) {
        const url = AppConfig.intralinksEndPoint + 'workspaces/' + workspaceId + '/splashImage';
        const params = {
            'screen-id': screenId
        };
        const options = {
            params: params,
            responseType: 'blob',
        };
        return this._transportService.get(url, options);
    }

    getSplashPage(screenId: string, workspaceId: string) {
        const url = AppConfig.intralinksEndPoint + 'workspaces/' + workspaceId + '/splash';
        const headers = {
        };
        const params = {
            'screen-id': screenId
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getWorkspaces(screenId: string) {
        const url = AppConfig.intralinksEndPoint + 'workspaces';
        const headers = {
            'Content-Type': 'application/json',
        };
        const params = {
            'screen-id': screenId,
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    setAdminVisibility(instalinks: any) {
        const url = AppConfig.intralinksAdminSettingEndPoint;
        const formData: FormData = new FormData();
        formData.append('enable', instalinks.enable);
        if (instalinks.enable) {
            formData.append('uiUrl', instalinks.uiUrl);
            formData.append('serverUrl', instalinks.serverUrl);
            formData.append('consumerKey', instalinks.consumerKey);
            formData.append('consumerSecret', instalinks.consumerSecret);
        }
        return this._transportService.put(url, formData, {});
    }

    splashMFACheck(workspaceId: string, input: IntralinksValidationObject) {
        return this._splashMFARequest(workspaceId, input);
    }

    treeFileMapper(response, folderId?: string, mapping?: IntralinksMapping) {
        const result = [];
        if (response['content-list'] && response['content-list']['doc-folder-list']
            && response['content-list']['doc-folder-list'].length > 0) {
            response['content-list']['doc-folder-list'].forEach(element => {
                const item = new IntralinksFile();
                item.mapFromServer(element);
                if (mapping) {
                    const found = mapping.findByFolderId(folderId);
                    if (found) {
                        item.mapping = found.ignoreParent(true);
                    }
                }
                result.push(item);
            });
        }
        return result;
    }

    treeFolderMapper(treeListNode: any, workspaceId: string, folders: object[]) {
        if (folders && folders.length > 0) {
            folders.forEach(element => {
                const child = this.treeFolderMapper({
                    name: element['name'],
                    id: element['id'],
                    workspaceId: workspaceId,
                    type: 'folder',
                    createdDate: element['created-on'] ? new Date(parseInt(element['created-on']['milliseconds'], 0)) : null,
                    createdBy: element['created-by'] ? element['created-by']['first-name'] + ' '
                        + element['created-by']['last-name'] : null,
                    lastModifiedDate: element['last-modified-on'] ?
                        new Date(parseInt(element['last-modified-on']['milliseconds'], 0)) : null,
                    lastModifiedBy: element['last-modified-by'] ? element['last-modified-by']['first-name']
                        + ' ' + element['last-modified-by']['last-name'] : null,
                    folders: [],
                    documents: []
                }, workspaceId, element['children']);
                treeListNode.folders.push(child);
            });
        }
        return treeListNode;
    }

    // When the files selected in multiple folders change, all the currently selected files need to be saved and updated.
    updateSelectedFiles(selectedFiles: IntralinksFile[], parentId: string) {
        this.allSelectedFiles.set(parentId, selectedFiles);
        this.allSelectedFiles.forEach((value, key) => {
            if (value.length === 0) {
                this.allSelectedFiles.delete(key);
            }
        });
        return this.allSelectedFiles;
    }

    validateMFAPassword(workspaceId: string, input: IntralinksValidationObject) {
        return this._splashMFARequest(workspaceId, input);
    }

    private _splashMFARequest(workspaceId: string, input: IntralinksValidationObject) {
        const url = AppConfig.intralinksEndPoint + 'workspaces/' + workspaceId + '/splash';
        const formData = new FormData();

        formData.append('screen-id', input.screenId);
        if (typeof input.acceptSplash === 'boolean') {
            formData.append('accept-splash', input.acceptSplash.toString());
        }
        if (input.accountEmail) {
            formData.append('intralinks-account-email', input.accountEmail);
        }
        if (input.mobileNumber) {
            formData.append('intralinks-account-mobile-number', input.mobileNumber);
        }
        if (input.oneTimePassword) {
            formData.append('intralinks-account-one-time-password', input.oneTimePassword);
        }
        if (input.challengeQuestionAnswer) {
            formData.append('challenge-question-answer', input.challengeQuestionAnswer);
        }

        return this._transportService.post(url, formData);
    }
}
