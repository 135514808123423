export const StandardControlIds = {
    entity: 'ENTITIES',
    source: 'SOURCE',
    displaydate: 'DISPLAYDATE',
    sentiment: 'SENTIMENT',
    priority: 'PRIORITY',
    subject: 'SUBJECT',
    notebody: 'BODY',
    name: 'NAME',
    shortname: 'SHORTNAME'
};

export const NoteBodyHtmlMetadatas = {
    cssStyle: 'table#tabledisplay-meta {' +
        'font-family: Tahoma, Arial, sans-serif;' +
        'font-size: 11px;' +
        // 'background-color: #F7F7F7;' +
        // 'border-top: solid 5px #F7F7F7;' +
        // 'border-bottom: solid 5px #F7F7F7;' +
        'margin: 5px 0 5px 0;' +
        '}' +
        'table#tabledisplay-meta td {' +
        'padding: 0px 7px;' +
        '}' +
        'table#tabledisplay-meta td.var {' +
        'font-weight: bold;' +
        'padding-right: 25px;' +
        '}' +
        'table#tabledisplay-meta td.value {' +
        'width: 100%;' +
        '}',
    summaryStyleStartTag: '<!-- START: custom table style -->',
    summaryStyleEndTag: '<!-- END: custom table style -->',
    tableStartTag: '<!-- START: custom table metadata -->',
    tableEndTag: '<!-- END: custom table metadata -->',
    oldTableStartTag: '<!-- START: custom legal metadata -->',
    oldTableEndTag: '<!-- END: custom legal metadata -->',
    subjectBegin: '<!-- START: subject begin -->',
    subjectEnd: '<!-- END: subject end -->',
    metadataStyle: '<!-- START: custom table style --><style type="text/css">' +
        'table#tabledisplay-meta {font-family: Tahoma, Arial, sans-serif; font-size: 11px; background-color: #F7F7F7; border-top: solid 5px #F7F7F7;  border-bottom: solid 5px #F7F7F7; margin: 5px 0 5px 0;}' +
        'table#tabledisplay-meta td {padding: 0px 7px;}' +
        'table#tabledisplay-meta td.var {font-weight: bold; padding-right: 25px;}' +
        'table#tabledisplay-meta td.value {width: 100%;}</style><!-- END: custom table style -->',
    fullHtml: '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">' +
        '<html xmlns="http://www.w3.org/1999/xhtml">' +
        '<head><meta http-equiv="Content-Type" content="text/html; charset=utf-8"><title></title>' +
        '<!--METADATA-TABLE-STYLE-PLACEHOLDER--></head>' +
        '<body><!--SUBJECT-PLACEHOLDER--><!--METADATA-TABLE-PLACEHOLDER--><!--NOTE-BODY-PLACEHOLDER--></body></html>'
};

export const ComponentTypes = {
    // templates
    entity: 'entity-input',
    source: 'source-input',
    displaydate: 'displaydate-input',
    sentiment: 'sentiment-input',
    priority: 'priority-input',
    subject: 'subject-input',
    notebody: 'ckeditor-input',
    ed: 'entity-dropdown',
    met: 'met-input',
    date: 'date-input',
    shortname: 'shortname-input',
    name: 'name-input',
    group: 'group',
    virtualGroup: 'virtual-group',
    checkbox: 'cb-input',
    label: 'label',

    // filters
    dateFilter: 'date-filter',
    entityFilter: 'entity-filter',
    entryTypeFilter: 'entry-type-filter',
    noteTypeFilter: 'note-type-filter',
    priorityFilter: 'priority-filter',
    sentimentFilter: 'sentiment-filter',
    sourceFilter: 'source-filter',
    subjectFilter: 'subject-filter',
    submitterFilter: 'submitter-filter',
    teamFilter: 'team-filter'
};

export const NoteActions = {
    saveNote: 'save-note',
    saveDraft: 'save-draft',
    createNote: 'create-note',
    shareFileToNote: 'share-file-to-note',
    editNote: 'edit-note',
    deleteNote: 'delete-note',
    createSidenote: 'create-sidenote',
    editSidenote: 'edit-sidenote',
};

export const EntityActions = {
    createEntity: 'create-entity',
    editEntity: 'edit-entity',
};

export const ALL_VALUE = 'all';
export const ANY_VALUE = 'any';
export const AUTO_SAVE_INTERVAL_30 = 30000; // 30 seconds
export const AUTO_SAVE_INTERVAL_5 = 5000; // 5 seconds
export const AUTO_SAVE_INTERVAL_3 = 3000; // 3 seconds
export const LONG_TOUCH_INTERVAL = 500;
export const AUTO_SEARCH_INTERVAL = 250; // 0.25 seconds
export const MENU_TOLERANCE = 100; // 0.1 seconds

export const CURRENT_USER = {
    id: '4097fcf8f6ad4d1bbb5d3fa7bb021c66',
    shortName: 'Current User',
    name: '',
    title: '',
    company: '',
    isPublic: true,
    type: {
        id: '',
        name: 'Contact'
    }
};

export const businessConstants = {
    // general
    common: {
        type: {
            STRING: 'string',
            NUMBER: 'number',
            OBJECT: 'object'
        },
        separator: {
            DOT: '.',
            COMMA: ','
        },
        noPermissioIdentifier: '******',
        number: {
            debounceTime: 250
        }
    },

    // auto Tag
    autoTag: {
        autoTagDisabledMsg: 'Auto Tagging is disabled successfully',
        autoTagEnabledMsg: 'Auto Tagging is enabled successfully',
        autoTagDisableForLiveWiredMsg: 'Auto Tagging is disabled for livewire successfully',
        autoTagEnabledForLiveWireMsg: 'Auto Tagging is enabled for livewire successfully',
    },

    // calendar
    calendar: {
        ownProperties: {
            endDateId: '150085e20a16b420b8a683501af1ec16',
            locationId: 'f8daf99c58654c11b50de36f624f53ab',
            locationExpandId: 'b28bfccdbb424f09ae7c9353f7b42c5e',
            attendeesId: 'fdfb16ea274e45c6928a273e42451427',
            allDayId: '150085e20a16b4203cd6271b2efe6603'
        },
        linkedEntityLongName: 'linkedEntityLongName',
        SEVENT_DAYS_IN_SECONDS: 60 * 60 * 24 * 7 * 1000,
        ONE_DAY_IN_SECONDS: 60 * 60 * 24 * 1000,
        screenType: 'event',
        viewType: {
            Day: 'Day',
            WorkWeek: 'WorkWeek',
            Week: 'Week',
            Month: 'Month'
        },
        defaultScrollTime: '8:00'
    },

    // contact
    Contacts: {
        Contact_Template_Id: 'b515d57c-1d0c-440b-b6ec-4a5333b2784a'
    },

    // CRM
    exchangeMerge: {
        fields: {
            id: 'id',
            firstName: 'firstName',
            lastName: 'lastName',
            jobTitle: 'webJobTitle',
            company: 'webCompany',
            alias: 'alias',
            birthday: 'birthday',
            email: 'webEmail',
            webPhone: 'webPhone',
            webAddress: 'webAddress',
            socialMedia: 'socialMedia',
            notes: 'notes',
            modifyDate: 'modifyDate'
        },
        valueProperty: {
            firstName: 'First Name',
            lastName: 'Last Name',
            jobTitle: 'Job Title',
            company: 'Company',
            alias: 'Aliases',
            birthday: 'Birthday',
            email: 'email',
            phone: 'phone',
            address: 'address',
            addressOtherProperty: 'addressOtherProperty',
            socialMedia: 'Social media',
            notes: 'Notes',
            primary: 'primary',
            link: 'link',
            tamaleValue: 'tamaleValue',
            exchangeValue: 'exchangeValue'
        }
    },

    // Dashboard
    Dashboard: {
        ShortName_Entity_Id: 'cbb04d64c4a94dacbbcd568a83b5f133',
    },

    // entity
    entity: {
        Uncategorized_Fixed_ID: 'e2b65b79dc934474a6c7d67de46d3a4f',
        UnSpecified_Fixed_ID: '23f041bb0a16aed04e03dfffeaa6b815',
        EMAIL_SPOOLER_ID: '91a717ed56ef3a471dd5f339b85dbcce',
    },
    EntityTypes: {
        Contact_Id: '23fd62440a16aed07635d76562cc1210',
        Contact_To_Contact_Id: '359e41e95bd12563480f6956783b3c10',
        Corporate_Id: '23f033c10a16aed032a69eb91443f178',
        Event_Id: '1014983443304171be6009349b6afa34', // as an entity type for event filter
        Index_Id: '23f0342f0a16aed0d7385eff0a0a64e2',
        Industry_Id: '23f034000a16aed094f78a63e719bff5',
        Team_Id: 'c25521b41254446b80f1c7a5fb98a394',
        Topic_Id: '23f0345d0a16aed05feb8336d584ec16',
        User_Id: '23fd62440a16aed07635d76562cc1985',
        All: 'all'
    },
    EntityTypesName: {
        contact_to_entity: 'Contact → * (All entity types)',
        entity_to_contact: '*(All entity types) ← Contact',
        entity_type_team_member: 'Team Member',
        entity_type_topic_member: 'Topic Member',
        entity_type_all: '*(All entity types)',
        contact: 'Contact',
        corporate: 'Corporate'
    },
    // entry
    EntryTypes: {
        Deposited_Blast_Email_Id: '61d45c9281484c1da3ce3c2e044f74c5',
        Tamale_Event_Id: '1014983443304171be6009349b6afa33',
        Tamale_Note_Id: 'd70218d00a16ada08d6bf55b0b29ef9b',
        note: 'Note',
        event: 'Event',
        sameAsParentNoteId: '28fdc6dee10011eca98bc9396f89f91f'
    },
    note: {
        sideNoteFollowRule: 'sideNoteFollowRule',
        defaultSidenoteType: {
            firstNote: 'First note',
            latestNote: 'Latest note/sidenote'
        }
    },

    // intralinks
    intralinks: {
        onSessionWarning: {
            original: 'Your session has been logged out',
            userReadable: 'This Intralinks account does not support multiple sessions and there is another session active. Please end the other session and try again.'
        }
    },

    // relationship
    RelationshipTypes: {
        IS_IN_TEAM_ID: 'ad852ce3518b4d7babd51a9ded4d35bf',
        TOPIC_MEMBER_ID: '23f035860a16aed0afeea486d034df90',
    },

    // map tile
    maptile: {
        mapTileDisabledMsg: 'Map Tile is disabled successfully',
        mapTileEnabledMsg: 'Map Tile is enabled successfully',
    },
    // dualList
    dualList: {
        userNotAllowedAccessTitle: 'Users/Teams not allowed access',
        userAllowedAccessTitle: 'Users/Teams allowed access',
        placeHolderText: 'Search User or Teams',
        dataTextField: 'name',
        dataValueField: 'id'
    },
    // email digest
    impactedConfigType: {
        dashboard: 'Dashboard',
        emailDigest: 'Email Digest'
    },
};

export const ContactActions = {
    createContact: 'create-contact',
    editContact: 'edit-contact',
};

export const SearchAction = {
    clear: 'CLEAR',
};

// File preview info
// Preview file extension
export const PDFPreviewExtension = ['txt', 'xls', 'docx', 'doc', 'xlsx', 'csv', 'rtf', 'pdf'];
// Preview file maximum limition
export const MAX_PREVIEW_FILE_SIZE: number = 15 * 1024 * 1024;
