<div *ngIf="status" class="aacl-notification__outer-box {{styleClass}}" (mouseover)="timeStop(true)"
    (mouseout)="timeStop(false)">
    <div class="aacl-notification__inner-box">
        <span class="aacl-notification__icon-type__container">
            <smart-icon *ngIf="icon === 'check-circle-o'" class="aacl-icon" name="check-circle-o"></smart-icon>
            <smart-icon *ngIf="icon === 'exclamation-circle-o'" class="aacl-icon" name="exclamation-circle-o">
            </smart-icon>
            <smart-icon *ngIf="icon === 'error-o'" class="aacl-icon" name="error-o"></smart-icon>
            <smart-icon *ngIf="icon === 'info-o'" class="aacl-icon" name="info-o"></smart-icon>
        </span>
        <div class="aacl-notification__msg" attr.id="{{widgetID}}__messages">
            {{status?.message}}
            <div *ngIf="status?.hasDetail">
                <ul>
                    <li *ngFor="let detail of status.details;trackBy:trackByFn">{{detail}}</li>
                </ul>
            </div>
        </div>
        <span (click)="close()" class="aacl-notification__icon-close__container">
            <smart-icon class="aacl-icon" name="close"></smart-icon>
        </span>
    </div>
</div>