/**
 * Created by Daniel on 09/04/2019.
 * Description: The service for the calendar feature.
 * ------ maintenance history ------
 * Updated by Daniel on 08/31/2022: Added two functions to handle the address.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { CalendarEntry } from '../models/calendar-entry.model';
import { businessConstants } from '../constants/business.constants';
import { AdvFilterHelperService } from './adv-filter-helper.service';
import { Contact } from '../models/contact.model';
import { EntryType } from '../models/entry-type.model';
import { NoteEntry } from '../models/note-entry.model';
import { CalendarList } from '../models/entity-list.model';
import { DateHelperWebService } from './date-helper.web.service';

export const ADDRESS_SEPARATOR = ' Address: ';

@Injectable()
export class CalendarService {
    constructor(
        private _advFilterHelper: AdvFilterHelperService,
        private _transportService: TransportService) {
    }

    /**
     * generate display location by longName, typeName and detail address
     * @param longName
     * @param typeName
     * @param detailAddress
     * @returns displayLocation
     */
    generateDisplayLocation(longName: string, typeName: string, detailAddress: string): string {
        let displayLocation = '';
        if (longName && typeName) {
            displayLocation = displayLocation + longName + '\'s ' + typeName + ADDRESS_SEPARATOR;
        }
        if (detailAddress) {
            displayLocation = displayLocation + detailAddress;
        }
        return displayLocation;
    }

    /**
     * get dispaly location by location expand
     * @param locationExpand
     */
    getDisplayLocationByLocationExpand(location: string, locationExpand): string {
        let displayLocation = location;
        if (locationExpand) {
            const locationExpandObj = JSON.parse(locationExpand.value);
            const locationExpandValueObj = locationExpand.expandValue ? locationExpand.expandValue : locationExpand.expandValueObj;
            // Need to handle the case whose linked entity exist
            if (locationExpandObj && locationExpandObj.linkedEntityId && locationExpandValueObj && locationExpandValueObj.linkedEntityLongName !== businessConstants.common.noPermissioIdentifier) {
                const linkedEntityLongName = locationExpandValueObj.linkedEntityLongName;
                const linkedEntityAddressType = locationExpandObj.addressName;
                // set value for displayLocation if the location has linked entity
                displayLocation = this.generateDisplayLocation(linkedEntityLongName, linkedEntityAddressType, location);
            }
        }
        return displayLocation;
    }

    /**
     * get real address by display location
     * @param displayAddress
     * @returns realAddress
     */
    getRealAddress(displayAddress: string): string {
        let realAddress = displayAddress;
        if (displayAddress.includes(ADDRESS_SEPARATOR)) {
            const index = displayAddress.indexOf(ADDRESS_SEPARATOR);
            realAddress = displayAddress.substring(index + ADDRESS_SEPARATOR.length);
        }
        return realAddress;
    }

    getCalendarEvents(args): Observable<any> {
        const startDate = args.startDate;
        const endDate = args.endDate;
        const entityId = args.entityId;
        const isGrid = args.isGrid;
        const pageIndex = args.page;
        const pageSize = args.pageSize;
        const sortBy = args.sortBy;
        const sortOrder = args.sortOrder;
        // the constant filter for event, the difference between entry and event is entry class
        const advFilterArray = [];
        const eventFitler = `(entry-class equals "event")`;
        const spoolerFilter = `(! entities contains long-name "EMAIL_SPOOLER")`;
        const configDataFilter = `(! entities contains long-name "ConfigurationData")`;
        advFilterArray.push(eventFitler);
        advFilterArray.push(spoolerFilter);
        advFilterArray.push(configDataFilter);
        // start date filter
        if (startDate) {
            const startDateFilter = `((display-date after "${startDate}") or (display-date equals "${startDate}"))`;
            advFilterArray.push(startDateFilter);
        }
        // end date filter
        if (endDate) {
            const endDateFilter = `((display-date before "${endDate}") or (display-date equals "${endDate}"))`;
            advFilterArray.push(endDateFilter);
        }

        const filter = this._advFilterHelper.joinFiltersByAnd(advFilterArray);
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.threadEndpoint}`;
        let params = {
            timeZoneId: DateHelperWebService.getContryTimezoneId(),
            advfilter: filter,
            expand: 'thread;entry;entities;entity;source;submitter;properties;property;propdef;entry-type',
            showpermission: true,
            showblurb: true,
            sortby: 'lastediteddate',
            sortorder: 'desc',
            showattachmentsbyentry: true
        };

        if ((args.queryInfo) && (args.queryInfo.categoryFilter)) {
            params = Object.assign(params, { categoryfilter: JSON.stringify(args.queryInfo.categoryFilter) });
        }

        if (isGrid) {
            params['page'] = pageIndex;
            params['rpp'] = pageSize;
            params['sortby'] = sortBy;
            params['sortorder'] = sortOrder;
        }

        if (entityId) {
            params['entities'] = entityId;
        }

        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    mapCalendarListGrid(response): CalendarList {
        const result = new CalendarList();
        if (!response || !response['thread-list']) {
            return result;
        }

        result.calendars = this.mapToCalendarEventList(response['thread-list']);

        if (response['next'] && response['next']['href']) {
            result.next = response['next']['href'];
        }
        result.totalCount = response['count'];

        return result;
    }


    mapToCalendarEventList(eventThreadList) {
        const calendarEventList = [];
        if (eventThreadList && eventThreadList.length > 0) {
            for (const thread of eventThreadList) {
                const calendarEntry = new CalendarEntry();
                const eventEntry = thread.notes[0].data;
                calendarEntry.id = eventEntry.id;
                calendarEntry.subject = eventEntry.title;
                calendarEntry.calculatedSubject = eventEntry.calculatedTitle || eventEntry.title;
                calendarEntry.parentEntryID = eventEntry.thread.data.id;
                calendarEntry.entryType = EntryType.parse(eventEntry['entry-type']);
                calendarEntry.editable = eventEntry.editable;
                calendarEntry.deletable = eventEntry.deletable;
                // use display date to recored the start date of event
                calendarEntry.displayDate = new Date(eventEntry['display-date']);
                // handle the entities field
                calendarEntry.entitiesInfo = '';
                for (const entity of eventEntry['entities'].data) {
                    calendarEntry.entitiesInfo += entity.data['short-name'] + '; ';
                }
                calendarEntry.entitiesInfo = calendarEntry.entitiesInfo.substring(0, calendarEntry.entitiesInfo.length - 2);
                // entity field is editable if add new sidenote
                const flag = thread.notes.find(note => {
                    return note.editable === false;
                });
                if (flag) {
                    calendarEntry.isEntityFieldEditableInSideNote = false;
                } else {
                    calendarEntry.isEntityFieldEditableInSideNote = true;
                }
                calendarEntry.notesInThread = thread.notes.length;
                calendarEntry.attachmentsInThread = thread.attachments.length;
                // handle the properties of event
                if (eventEntry['properties'] && eventEntry['properties'].data.length > 0) {
                    for (const property of eventEntry['properties'].data) {
                        switch (property.data.propdef.data.id) {
                            case businessConstants.calendar.ownProperties.endDateId:
                                calendarEntry.EndDate = new Date(parseInt(property.data.value, 0));
                                break;
                            case businessConstants.calendar.ownProperties.locationId:
                                calendarEntry.Location = property.data.value;
                                break;
                            case businessConstants.calendar.ownProperties.locationExpandId:
                                calendarEntry.LocationExpand = property.data.value;
                                calendarEntry.LocationExpandValue = property.data.expandValue;
                                break;
                            case businessConstants.calendar.ownProperties.attendeesId:
                                if (property.data['expand-value']) {
                                    const attendees: Contact[] = [];
                                    for (const contact of property.data['expand-value']) {
                                        attendees.push(contact);
                                    }
                                    calendarEntry.Attendees = attendees;
                                }
                                break;
                            case businessConstants.calendar.ownProperties.allDayId:
                                calendarEntry.AllDay = property.data.value === 'true' ? true : false;
                                break;
                            default:
                                break;
                        }
                    }
                }
                // used for default sidenote rule
                const entryList = new Array<NoteEntry>();
                thread.notes.forEach(note => {
                    entryList.push(NoteEntry.parse(note.data));
                });
                calendarEntry.notes = entryList;
                calendarEventList.push(calendarEntry);
            }
        }
        return calendarEventList;
    }
}
