/**
 * Created by Todd Yu on 05/20/2020.
 * Description: A collection of enumerables, interfaces, and classes
 * related to the Tamale Admin Pages
 * ------ maintenance history ------
 * modified by Teddy Ding on 12/15/20
 */

import { Subject } from 'rxjs';
import { Type } from '@angular/core';
import { GridOptions } from 'ag-grid-community';

/** Admin Page Feedback received from grid and editor components */
export enum AdminPageFeedbackTypes {
    // received from grid components
    EDIT = 'edit',
    DELETE = 'delete',
    CREATE = 'create',
    SWITCH_DEFAULT = 'switch-default',
    TOGGLE_WEB = 'toggle-web',
    RUN = 'run',
    // received from editor component
    CREATE_SUCCESS = 'create-success',
    EDIT_SUCCESS = 'edit-success',
    DELETE_SUCCESS = 'delete-success'
}

/** Subject types for Note Types page */
export enum NoteTypesSubjectTypes {
    DEFAULT_NOTE_TYPE = 'default-note',
    DEFAULT_EVENT_TYPE = 'default-event',
    DISPLAY_TAM_WEB = 'note-display-web'
}

/** Subject types for Entity Types page */
export enum EntityTypesSubjectTypes {
    DEFAULT_ENTITY_TYPE = 'default-entity-type',
    DISPLAY_TAM_WEB = 'entity-display-web'
}

export enum GeneralPageSubjectTypes {
    FMR = 'FMR'
}

/** Admin Page Action Types */
export enum AdminPageActionTypes {
    UPDATE = 'update'
}

/** Admin Page Feedback Subject */
export class AdminPageFeedback {
    type: AdminPageFeedbackTypes | NoteTypesSubjectTypes | EntityTypesSubjectTypes;
    payload?: any;
}

/** Admin page Action Subject */
export class AdminPageAction {
    type: AdminPageActionTypes;
    payload?: any;
}

/** Interface for each Admin page located in AdminPagesConstants */
export interface AdminPageSetting {
    id: string;
    routingUrl: string;
    searchString: string;
    description?: string;
    additionalActions?: AdminPageAdditionalActions;
    feedbackSubject$?: Subject<any>;
    actionSubject$?: Subject<any>;
    editor?: {
        component: Type<any>;
        actionSubject$: Subject<AdminPageFeedback>;
        feedbackSubject$: Subject<AdminPageFeedback>;
    };
    gridSettings?: {
        columnDefs: Array<any>;
    };
}

export interface IAdminSharedPageSetting {
    id: string; // A unique identifier, which is not used to initialize the Grid
    name: string; // The name of the component
    isHiddenButton?: boolean; // show top button or not
    isShowRowGroupPanel?: boolean; // show ag-grid group panel or not
    btnTitle: string; // The name of the button in the top right corner of the Grid page
    type: string; // endpoing of the corresponding server
    columnHeader: {}; // Column information displayed on the grid
    extraColumn?: {}; // extra columns displayed on the grid
    slideSheet?: any; // the slide sheet triggered by grid page
    feedbackSubject$?: Subject<any>;
    actionSubject$?: Subject<any>;

}

/** Interface for handling any additional actions within the admin grid page */
export interface AdminPageAdditionalActions {
    search?: any;
    create?: {
        buttonName: string;
        buttonAction?: any;
    };
}

export const AGGridOptions: GridOptions = {
    defaultColDef: {
        sortable: true,
        resizable: true,
        menuTabs: []
    },
    cacheBlockSize: 50,
    suppressCellFocus: true,
    suppressColumnVirtualisation: true,
    enableBrowserTooltips: true,
    rowSelection: 'single',
    sortingOrder: ['desc', 'asc'],
    maintainColumnOrder: true,
    suppressContextMenu: true,
    suppressDragLeaveHidesColumns: true,
    suppressRowGroupHidesColumns:true,
};
