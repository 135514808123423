/**
 * Created by Jiu Chen on 12/03/2018.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum EntryTypeActionTypes {
    INITIALIZE = '[ENTRY_TYPE] INITIALIZE',
    INITIALIZE_SUCCESS = '[ENTRY_TYPE] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[ENTRY_TYPE] INITIALIZE_FAILED'
}

export class Initialize implements Action {
    readonly type = EntryTypeActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = EntryTypeActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = EntryTypeActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export type EntryTypeActions = Initialize | InitializeSuccess | InitializeFailed;
