/**
 * created by Yu Zhang on 12/14/17.
 * Description:
 *
 * ------ maintenancy history ------
 *  7/22/21 Simon Zhao add actions for obtaining the field configurations of the template of the given entity type or entry type.
 *  8/11/21 Simon Zhao add an action for update the mdl config related to a cached template.
 */

import { Action } from '@ngrx/store';
import { IMDLTemplate } from '../models/template-mdl.model';

// note template
export const GET_ENTRY_TYPE = 'GET_ENTRY_TYPE';
export const GET_ENTRY_TYPE_FAILURE = 'GET_ENTRY_TYPE_FAILURE';
export const GET_ENTRY_TYPE_SUCCESS = 'GET_ENTRY_TYPE_SUCCESS';
export const CLEAN_ENTRY_TYPE = 'CLEAN_ENTRY_TYPE';
export const GET_ENTRY_TEMPLATE_CONFIGUARTION = 'GET_ENTRY_TEMPLATE_CONFIGUARTION';
export const GET_ENTRY_TEMPLATE_CONFIGUARTION_SUCCESS = 'GET_ENTRY_TEMPLATE_CONFIGUARTION_SUCCESS';
export const GET_ENTRY_TEMPLATE_CONFIGUARTION_FAILURE = 'GET_ENTRY_TEMPLATE_CONFIGUARTION_FAILURE';
export const CLEAN_ENTRY_TEMPLATE_CONFIGURATION = 'CLEAN_ENTRY_TEMPLATE_CONFIGURATION';

export const GET_ENTITY_TEMPLATE_CONFIGUARTION = 'GET_ENTITY_TEMPLATE_CONFIGUARTION';
export const GET_ENTITY_TEMPLATE_CONFIGUARTION_SUCCESS = 'GET_ENTITY_TEMPLATE_CONFIGUARTION_SUCCESS';
export const GET_ENTITY_TEMPLATE_CONFIGUARTION_FAILURE = 'GET_ENTITY_TEMPLATE_CONFIGUARTION_FAILURE';
export const CLEAN_ENTITY_TEMPLATE_CONFIGURATION = 'CLEAN_ENTITY_TEMPLATE_CONFIGURATION';

export const CHANGE_TEMPLATE_VALUE_SET = 'CHANGE_TEMPLATE_VALUE_SET';
export const CLEAN_TEMPLATE_VALUE_SET = 'CLEAN_TEMPLATE_VALUE_SET';
export const CLEAN_TEMPLATE_MSG = 'CLEAN_TEMPLATE_MSG';
export const CREATE_RELATIONSHIP = 'CREATE_RELATIONSHIP';
export const CREATE_RELATIONSHIPS = 'CREATE_RELATIONSHIPS';
export const CREATE_RELATIONSHIP_SUCCESS = 'CREATE_RELATIONSHIP_SUCCESS';
export const CREATE_RELATIONSHIP_FAILURE = 'CREATE_RELATIONSHIP_FAILURE';
export const NOTE_BODY_ENTER_EDITING = 'NOTE_BODY_ENTER_EDITING';
export const NOTE_BODY_EXIT_EDITING = 'NOTE_BODY_EXIT_EDITING';
export const NOTE_BODY_DONE_EDITING = 'NOTE_BODY_DONE_EDITING';
export const ADJUST_NOTE_BODY_READER_HEIGHT = 'ADJUST_NOTE_BODY_READER_HEIGHT';
export const SET_NOTE_BODY_FOR_EDITING = 'SET_NOTE_BODY_FOR_EDITING';
export const SET_NOTE_BODY_FOR_EDITING_DONE = 'SET_NOTE_BODY_FOR_EDITING_DONE';
export const UPDATE_PARENTNOTE_ENTITIES = 'UPDATE_PARENTNOTE_ENTITIES';
export const UPDATE_PARENTNOTE_ENTITIES_SUCCESS = 'UPDATE_PARENTNOTE_ENTITIES_SUCCESS';
export const UPDATE_PARENTNOTE_ENTITIES_FAILURE = 'UPDATE_PARENTNOTE_ENTITIES_FAILURE';
export const ATTACHMENT_LIST_SHOW = 'ATTACHMENT_LIST_SHOW';
export const ATTACHMENT_LIST_HIDE = 'ATTACHMENT_LIST_HIDE';
export const GET_THREAD_ATTACHMENT = 'GET_THREAD_ATTACHMENT';
export const GET_THREAD_ATTACHMENT_SUCCESS = 'GET_THREAD_ATTACHMENT_SUCCESS';
export const GET_THREAD_ATTACHMENT_FAILURE = 'GET_THREAD_ATTACHMENT_FAILURE';
export const CLEAR_THREAD_ATTACHMENT = 'CLEAR_THREAD_ATTACHMENT';

// privacy
export const GET_PRIVACY_CONFIGUARTION = 'GET_PRIVACY_CONFIGUARTION';
export const GET_PRIVACY_CONFIGUARTION_SUCCESS = 'GET_PRIVACY_CONFIGUARTION_SUCCESS';
export const GET_PRIVACY_CONFIGUARTION_FAILURE = 'GET_PRIVACY_CONFIGUARTION_FAILURE';
export const CLEAN_PRIVACY_CONFIGURATION = 'CLEAN_PRIVACY_CONFIGURATION';
export const GET_PRIVACY_TEAMS = 'GET_PRIVACY_TEAMS';
export const GET_PRIVACY_TEAMS_SUCCESS = 'GET_PRIVACY_TEAMS_SUCCESS';
export const GET_PRIVACY_TEAMS_FAILURE = 'GET_PRIVACY_TEAMS_FAILURE';
export const CLEAN_PRIVACY_TEAMS = 'CLEAN_PRIVACY_TEAMS';


//#region actions for obtaining template fields for the given entity type or entry type.
export const GET_TEMPLATE_FIELDS = 'GET_TEMPLATE_FIELDS';
export const GET_TEMPLATE_FIELDS_SUCCESS = 'GET_TEMPLATE_FIELDS_SUCCESS';
export const GET_TEMPLATE_FIELDS_FAILURE = 'GET_TEMPLATE_FIELDS_FAILURE';
export const ADD_TEMPLATE_FIELDS_TO_CACHE = 'ADD_TEMPLATE_FIELDS_TO_CACHE';
export const GET_ENTITY_FIELDS = 'GET_ENTITY_FIELDS';
export const GET_ENTITY_FIELDS_SUCCESS = 'GET_ENTITY_FIELDS_SUCCESS';
export const GET_ENTITY_FIELDS_FAILURE = 'GET_ENTITY_FIELDS_FAILURE';
export const ADD_ENTITY_FIELDS_TO_CACHE = 'ADD_ENTITY_FIELDS_TO_CACHE';

//#endregion


export enum TemplateActionTypes {
    ADD_ONE_ENTRY_TEMPLATE_CONFIGURATION = '[TEMPLATE] ADD_ONE_ENTRY_TEMPLATE_CONFIGURATION',
    ADD_ONE_ENTITY_TEMPLATE_CONFIGURATION = '[TEMPLATE] ADD_ONE_ENTITY_TEMPLATE_CONFIGURATION',
    UPDATE_ENTRY_OR_ENTITY_TEMPLATE_MDL = '[TEMPLATE] UPDATE_ENTRY_OR_ENTITY_TEMPLATE_MDL'
}

export class AddOneEntryTemplateConfiguration implements Action {
    readonly type = TemplateActionTypes.ADD_ONE_ENTRY_TEMPLATE_CONFIGURATION;
    constructor(public payload: any) { }
}

export class AddOneEntityTemplateConfiguration implements Action {
    readonly type = TemplateActionTypes.ADD_ONE_ENTITY_TEMPLATE_CONFIGURATION;
    constructor(public payload: any) { }
}

export class UPdateEntryOrEntityTemplateMDL implements Action {
    readonly type = TemplateActionTypes.UPDATE_ENTRY_OR_ENTITY_TEMPLATE_MDL;
    constructor(public payload: { typeId: string, mdl: IMDLTemplate }) { }
}

export type TemplateActions = AddOneEntryTemplateConfiguration |
    AddOneEntityTemplateConfiguration | UPdateEntryOrEntityTemplateMDL;
