/**
 * Created by Alan Yang on 12/17/2018.
 * Description:
 * Filter Type: Text, Subject
 *
 * ------ maintenance history ------
 */
import { Component, OnInit, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';
import { FilterWidgetConfig, FilterWidgetFeedback, FilterWidgetFeedbackType } from '../../tamalelibs/components/filter-widget.config';

export enum FilterWidgetAciontType {
    SELECTOR_CHANGE = 'SELECTOR_CHANGE',
    TEXT_CHANGE = 'TEXT_CHANGE',
    TYPE_CHANGE = 'TYPE_CHANGE'
}

/**
 * Filter widget for metadataType is Subject, TextBox, FileName
 *
 * @export
 * @class TamTextFilterComponent
 * @implements {OnInit}
 */
@Component({
    selector: 'tam-text-filter',
    templateUrl: 'tam-text-filter.component.html',
    styleUrls: ['tam-filter-container.component.scss']
})

export class TamTextFilterComponent implements OnInit {
    @Input() config: FiltersConfig; // data
    @Input() filterWidgetConfig: FilterWidgetConfig; // event

    textValue: string;
    defaultItem: { name: string, value: string };
    isCheckBlank = true;
    isItemHoverOn = false;
    operators: Array<{ name: string, value: string }>;

    private _originalValue = '';

    constructor(private _deviceService: DeviceDetectorService) { }

    /**
     * Init component with input config.
     *
     * @memberof TamTextFilterComponent
     */
    ngOnInit() {
        this.operators = this.config.operators; // operator list
        // the selected operator
        this.defaultItem = this.config.operators.find(item => item.value === this.config.selectedOperator);
        this.isCheckBlank = (this.defaultItem.value.toLocaleLowerCase().indexOf('blank') > -1) || (this.defaultItem.value.toLocaleLowerCase().indexOf('null') > -1);
        this.textValue = this.config.value ? this.config.value : ''; // textbox value
        this._originalValue = this.textValue;

        // For use within normal web clients
        if (this._deviceService.isMobile() || this._deviceService.isTablet()) {
            this.isItemHoverOn = true;
        } else {
            this.isItemHoverOn = !this.config.deletable;
        }
    }

    /**
     * Emits update event with config as parameter to filter body on value change
     *
     * @memberof TamTextFilterComponent
     */
    onTextInputChanged() {
        this._feedback(FilterWidgetAciontType.TEXT_CHANGE, this.textValue.trim());
    }

    onMouseOver() {
        if (!this.config.deletable) {
            this.isItemHoverOn = true;
        }
    }

    onMouseOut() {
        if (!this.config.deletable) {
            this.isItemHoverOn = false;
        }
    }

    /**
     * removes current item from Filter Body via removing config object from store.
     *
     * @memberof TamTextFilterComponent
     */
    _onBtnCloseClicked() {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.remove;
        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }

    _valueChange(changeItem: { name: string, value: string }): void {
        this.isCheckBlank = (this.defaultItem.value.toLocaleLowerCase().indexOf('blank') > -1) || (this.defaultItem.value.toLocaleLowerCase().indexOf('null') > -1);

        // emits event for value change
        this._feedback(FilterWidgetAciontType.SELECTOR_CHANGE, changeItem.value);
    }

    private _feedback(type: FilterWidgetAciontType, value: string) {
        const feedback = new FilterWidgetFeedback();
        feedback.type = FilterWidgetFeedbackType.update;

        if (FilterWidgetAciontType.SELECTOR_CHANGE === type) {
            this.config.selectedOperator = value;
        } else if (FilterWidgetAciontType.TEXT_CHANGE === type) {
            this.config.value = value;
        }

        feedback.payload = this.config;
        this.filterWidgetConfig.feedbackSubject$.next(feedback);
    }
}
