/**
 * Created by Simon Zhao on 01/06/2022. A utility for aggregational grid component.
 * ------ maintenance history ------
 */
import { FieldType } from '../../tamalelibs/models/workflow.model';
import { CELL_RENDER_FRAMEWORK_ATTRIB, COLUMN_TYPE_ATTRIB } from '../../tamalelibs/services/workflow.service';
import { ColAggregationalDef } from './aggregational-grid.model';
import { StringLiteralsPipe } from './../../pipes/translate.pipe';
import { GridOptions } from 'ag-grid-community';

export const AGGFUNC_ATTRIB = 'aggFunc';
export const ACTUAL_WIDTH_ATTRIB = 'actualWidth';
export const COL_ID_ATTRIB = 'colId';
export const HEADER_LOCATION = 'header';
export const NONE_AGGREGATION = 'none';
export const TOOLTIP_VALUE_GETTER = 'tooltipValueGetter';
export const VALUE_GETTER = 'valueGetter';
export const FILTER_PARAM = 'filterParams';
export const CELL_RENDERER = 'cellRenderer';
export const COLUMN_MENU_ITEMS_TO_EXCLUDE = ['separator', 'autoSizeAll', 'resetColumns'];
export const MENU_ITEM_ICON_ATTRIB = 'icon';
export const MENU_ITEM_SELECTION_ICON = '<span class="ag-icon ag-icon-tick" />';
export const MENU_ITEM_AGGREGATION_ICON = '<span class="ag-icon ag-icon-aggregation" />';
export const VISIBLE_ATTRIB = 'visible';
export const defaultBottomGridOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
        minWidth: 50,
        maxWidth: 600,
        resizable: true
    },
    suppressCellFocus: true,
    suppressColumnVirtualisation: true,
    suppressRowGroupHidesColumns:true,
    domLayout: 'normal' // Ensure that domLayout matches the correct type
};

export class AggregationalGridComponentUtils {
    //#region customized aggregation functions
    static aggreFunctionMap = {
        sum: { func: AggregationalGridComponentUtils.sumFunction, isNone: false },
        min: { func: AggregationalGridComponentUtils.minFunction, isNone: false },
        max: { func: AggregationalGridComponentUtils.maxFunction, isNone: false },
        avg: { func: AggregationalGridComponentUtils.avgFunction, isNone: false },
        count: { func: AggregationalGridComponentUtils.countFunction, isNone: false },
        none: { func: AggregationalGridComponentUtils.noneFunction, isNone: true }
    };

    static avgFunction(values) {
        let sum = 0;
        let count = 0;
        const data = AggregationalGridComponentUtils.getArrayByObject(values);
        if (Array.isArray(data)) {
            data.forEach((value) => {
                const groupNode = value !== null && value !== undefined && typeof value === 'object';
                if (groupNode) {
                    sum += value.avg * value.count;
                    count += value.count;
                } else {
                    if (typeof value === 'number') {
                        sum += value;
                        count++;
                    }
                }
            });
        }
        let avg;
        if (count !== 0) {
            avg = sum / count;
        } else {
            avg = 0;
        }
        return avg;
    }

    //#endregion

    static countFunction(values: any[]) {
        const data = AggregationalGridComponentUtils.getArrayByObject(values);
        return !!data ? data.length : 0;
    }

    static customHeaderValueGetter(params) {
        if (params.location === HEADER_LOCATION && params.column.aggFunc && params.column.aggFunc !== NONE_AGGREGATION) {
            // add function name as part of the corresponding column header.
            return `${params.column.aggFunc}(${params.colDef.headerName})`;
        }

        return params.colDef.headerName;
    }

    static getAllowedAggregationFunctions() {
        const functionMap = {};
        Object.keys(AggregationalGridComponentUtils.aggreFunctionMap).forEach(funcCfg => functionMap[funcCfg] = AggregationalGridComponentUtils.aggreFunctionMap[funcCfg].func);
        return functionMap;
    }

    static getAllowedAggrationFunctionNameArray(): string[] {
        const nameArray = [];
        Object.keys(AggregationalGridComponentUtils.aggreFunctionMap).forEach(funcCfg => nameArray.push(funcCfg));
        return nameArray;
    }

    static generateColDefForBottomGrid(topCol: any, getters?: { tooltipGetter: (params: any) => any, valueGetter: (params: any) => any, filterValueGetter: (params: any) => any, cellRenderer: (params: any) => any }) {
        const result = {};
        Object.assign(result, topCol);
        if (getters) {
            result[TOOLTIP_VALUE_GETTER] = getters.tooltipGetter;
            result[VALUE_GETTER] = getters.valueGetter;
            result[FILTER_PARAM] = getters.filterValueGetter;
            if (!!getters.cellRenderer) {
                result[CELL_RENDERER] = getters.cellRenderer;
            }
        }

        if (AggregationalGridComponentUtils.isFileColumnDef(result) && result[CELL_RENDER_FRAMEWORK_ATTRIB]) {
            // Do not use the file cell component in the aggregation grid.
            result[CELL_RENDER_FRAMEWORK_ATTRIB] = null;
        }

        return result;
    }

    static getMainMenuItems(customColumnAggFuncs: (string, any) => void) {
        return (params) => {
            const menuItems = [];
            const itemsToExclude = COLUMN_MENU_ITEMS_TO_EXCLUDE; //  autoSizeThis
            params.defaultItems.forEach((item) => {
                if (itemsToExclude.indexOf(item) < 0) {
                    menuItems.push(item);
                }
            });

            const aggFunc = params.column.aggFunc;
            // group columns have no columnSetting
            if (params.column.colDef && AggregationalGridComponentUtils.isNumericalColumnDef(params.column.colDef)) {
                const aggMenuItems = [];
                for (const funcKey of Object.keys(AggregationalGridComponentUtils.aggreFunctionMap)) {
                    const isMenuItemSelected = (!aggFunc && AggregationalGridComponentUtils.aggreFunctionMap[funcKey].isNone) ||
                        aggFunc === funcKey;
                    const item = {
                        name: funcKey,
                        action: () => {
                            customColumnAggFuncs(funcKey, params.column);
                        }
                    };

                    if (isMenuItemSelected) {
                        // insert an icon of tick before the selected item.
                        item[MENU_ITEM_ICON_ATTRIB] = MENU_ITEM_SELECTION_ICON;
                    }

                    aggMenuItems.push(item);
                }

                menuItems.splice(1, 0, {
                    name: StringLiteralsPipe.translate('general.aggregation'),
                    icon: MENU_ITEM_AGGREGATION_ICON,
                    subMenu: aggMenuItems
                });
            }

            return menuItems;
        };
    }

    static isNumberType(col: any) {
        return true;
    }

    static isNumericalColumnDef(colDef: ColAggregationalDef) {
        return !!colDef && colDef.fieldType && colDef.fieldType === FieldType.NUMBER;
    }

    static isFileColumnDef(colDef: ColAggregationalDef) {
        return !!colDef && colDef[COLUMN_TYPE_ATTRIB] && colDef[COLUMN_TYPE_ATTRIB] === FieldType.FILE;
    }

    static minFunction(values) {
        const data = AggregationalGridComponentUtils.getArrayByObject(values);
        if (Array.isArray(data)) {
            if (data.length === 0) {
                return null;
            }
            return Math.min(...data);
        }
    }

    static maxFunction(values) {
        const data = AggregationalGridComponentUtils.getArrayByObject(values);
        if (Array.isArray(data)) {
            if (data.length === 0) {
                return null;
            }
            return Math.max(...data);
        }
    }

    static noneFunction() {
        return '';
    }

    static sumFunction(values) {
        let result = 0;
        const data = AggregationalGridComponentUtils.getArrayByObject(values);
        if (Array.isArray(data)) {
            data.forEach((value) => {
                if (typeof value === 'number') {
                    result += value;
                }
            });
        }
        return result;
    }

    static getArrayByObject(data) {
        if (Array.isArray(data)) {
            return data;
        } else if (Array.isArray(data.values)) {
            return data.values;
        }
    }
}
