<div class="container tam-form-field" [ngClass]="{'has-error':requireInvalid}">
    <div class="tam-form-label label-area">
        <field-label [label]="config.label||config.field.fieldDefinition.name"
            [tooltip]="config.description||config.field.fieldDefinition.description" [isRequired]="config.required"
            labelClassList="field-name body-3 text-ellipsis"></field-label>
    </div>

    <kendo-combobox [popupSettings]="{appendTo:'component',width:350}" #kendo class="tam-textbox field-control"
        [data]="kDataSource|async" textField="shortName" valueField="id" [loading]="isLoading" [listHeight]="210"
        [filterable]="true" [(ngModel)]="value" (valueChange)="valueChange($event, false)" (focus)="focus()"
        (filterChange)="handleFilter($event)" (blur)="blur()" [disabled]="!config.editable||config.disabled"
        [ngClass]="{'field-disabled':config.disabled||!config.editable}">
        <ng-template kendoComboBoxItemTemplate let-dataItem>
            <tam-entity-dropdown-item [entity]="dataItem"></tam-entity-dropdown-item>
        </ng-template>

        <ng-template kendoMultiSelectFooterTemplate *ngIf="searchValue">
            <div *ngIf="isShowCreateContact" (click)="createContact($event)" class="dropdown-fly-item">
                <span class="fly-entity-tag"> {{searchValue}} (New contact)</span>
            </div>
            <div *ngIf="isShowCreateEntity" (click)="createEntity()" class="dropdown-fly-item">
                <span class="fly-entity-tag">{{searchValue}} (New entity)</span>
            </div>
        </ng-template>
    </kendo-combobox>
    <div class="tam-form-help" *ngIf="config.editable&&requireInvalid&&config.required">
        {{'general.required_error_message' | stringLiterals}}
    </div>
</div>