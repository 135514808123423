/**
 * Created by Daniel Wang on 11/15/2023.
 * Description:
 * ------ maintenance history ------
 */
export enum NoteOpType {
    CreateNote = 'New Note',
    DeleteNote = 'Delete Note',
    DeleteEvent = 'Delete event',
    DeleteSideNote = 'Delete Side Note',
    EditNote = 'Edit Note',
    EditEvent = 'Edit Event',
    EditSideNote = 'Edit Sidenote',
    OpenThreadSideSheet = 'Open Thread Side Sheet'
}

export enum NumberType {
    Number = 'number',
    Ratio = 'ratio',
    Percent = 'percent',
    Currency = 'currency'
}
