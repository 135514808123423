/**
 * Created by Abner Sui on 06/30/2020
 * -------------------------------------
 */

import { OnInit, Component, OnDestroy, Input } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { EmptyObservable } from 'rxjs/observable/EmptyObservable';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { ToastService } from '../../widgets/toast/toast.service';
import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { TamUploadConfig, TamUploadUIFile } from '../../widgets/tam-upload/tam-upload.model';
import { FileHelperService } from '../../services/file-helper.service';
import { FileTransferService } from '../../tamalelibs/services/file-transfer.service';
import { FileUploadAction, FileUploadActionTypes, FileUploadFeedbackAction, FileUploadFeedbackTypes, FileUploadConfig } from './file-upload.model';
import { TamDropzoneService } from '../../widgets/tam-dropzone/tam-dropzone.service';
import { AuthHelperService } from '../../services/auth-helper.service';
import { NotificationStyles } from '../../widgets/notification/notification.model';

@Component({
    selector: 'tam-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

    @Input()
    config: FileUploadConfig;
    uploadConfig: TamUploadConfig;
    uploadEditing = { rename: 0, upload: 0, remove: 0 };
    // data models
    private _attachmentLeftovers: any = { save: [], cancel: [] }; // storage for leftovers of attachment to be deleted in diff workflows
    private _processingUploads = [];
    // rxjs ngrx/store subscriptions
    private _uploadHandler: any = null;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _alertWindow: AlertWindowService,
        private _toast: ToastService,
        private _authHelperService: AuthHelperService,
        private _fileHelperService: FileHelperService,
        private _fileTranserService: FileTransferService,
        private _dropzoneService: TamDropzoneService,
    ) {
    }

    ngOnInit() {
        this._destroySubscriptions.push(
            this.config.actionSubject$.subscribe(res => this._handleAction(res)),

            this._dropzoneService.drop$.subscribe((payload) => {
                if (payload) {
                    this._upload(payload);
                }
            }),
        );
        this._initUpload();
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    /**
     * on dialog closed, either saved or canceled, there could be leftover files on serverside to be deleted
     * @private
     * @param {*} workflow :{'save','cancel','delete,'remove'}
     */
    private _deleteLeftoverAttachments(workflow, callback): void {
        // cancel all processing uploads if user click cancel btn
        if (workflow === 'cancel') {
            this._processingUploads.forEach(xhr => {
                xhr.abort();
            });
            this._processingUploads = [];
        }

        const toBeDeleted = this._toBeDeletedOn(workflow, null);
        // dialog to be closed, clear up attachment leftovers
        this._attachmentLeftovers = { save: [], cancel: [] };
        if (toBeDeleted && toBeDeleted.length >= 1) {
            this._fileTranserService.removeFileByName(toBeDeleted).subscribe(() => callback && callback(this.uploadConfig.files));
        }
        if (callback) {
            callback(this.uploadConfig.files);
        }
    }

    private _fileRemovableOnServer(file, files): boolean {
        // Currently, of 2 files have same context , server marks them as the same file using the same "serverFileName".
        // For the case we upload 2 files which server response the same "serverFileName", the solution is only deleting the file from server when there is 1 pointer to the file.
        const remainFiles = files.filter((item) => file.serverName !== item.serverName);
        return remainFiles.length === files.length - 1;
    }

    private _getStatusChangeAction(workflow: string, isEditing: boolean, changedFile: any, files: any = null): FileUploadFeedbackAction {
        const fbAction = new FileUploadFeedbackAction();
        fbAction.type = FileUploadFeedbackTypes.OnStatusChange;
        fbAction.payload = { workflow: workflow, isEditing: isEditing };
        if (files) {
            fbAction.payload.files = files;
        }
        if (changedFile) {
            fbAction.payload.changedFile = changedFile;
        }
        return fbAction;
    }

    private _handleAction(action: FileUploadAction): void {
        switch (action.type) {
            case FileUploadActionTypes.GetAttachments:
                action.payload.next({
                    type: FileUploadFeedbackTypes.ReturnAttachments,
                    payload: this.uploadConfig.files,
                });
                break;
            case FileUploadActionTypes.DeleteAttachments:
                this._deleteLeftoverAttachments(action.payload.workflow, action.payload.callback);
                if (action.payload.refreshList) {
                    this.uploadConfig.files = [];
                }
                action.payload.subject.next({
                    type: FileUploadFeedbackTypes.ClearAttachments,
                });
                break;
            default:
                break;
        }
    }

    private _handleLazyDeletion(file: TamUploadUIFile): Observable<any> {
        if (!file.isNew) {
            // in the past, exsiting server file do lazy deletion.
            // after AB said server handle file deletion on saving,
            // no need to delete exiting file on saving
            // this._toBeDeletedOn('save', file.serverName);
            return of(null);
        } else {
            // since file will be deleted directly, remove any lazy deletion for the file
            ['save', 'cancel'].forEach(workflow => {
                if (this._toBeDeletedOn(workflow, null).includes(file.serverName)) {
                    this._attachmentLeftovers[workflow] = this._attachmentLeftovers[workflow].filter(item => item !== file.serverName);
                }
            });
            return this._fileTranserService.removeFileByName([file.serverName]);
        }
    }

    private _initUpload() {
        const token = '[token' + this._authHelperService.getToken() + 'token]';
        this.uploadEditing = { rename: 0, upload: 0, remove: 0 }; // represents number of elements in upload widget being editing
        const threadFileNameCount = {};
        this.config.files.forEach(item => {
            threadFileNameCount[item.name] = threadFileNameCount[item.name] ? (threadFileNameCount[item.name] + 1) : 1;
        });
        this.uploadConfig = {
            height: this.config.height,
            files: this.config.files,
            threadFileNameCount: threadFileNameCount,
            url: AppConfig.fileEndpoint,
            maxFiles: 50,
            maxFilesize: 250,
            paramName: 'attachment', // required by restAPI
            parallelUploads: 20,
            autoQueue: true, // Make sure the files aren't queued until manually added
            dictMaxFilesExceeded: 'You can not upload more than 50 files.', // overwrite dict..Option in case that dropzone.js changes it.
            dictFileTooBig: 'You can not upload file larger than 250MB.',
            readOnly: this.config.disabled,
            autoProcessQueue: true,
            onAddedfile: (file, xhr, config: TamUploadConfig) => { },
            onError: (errorMessage, file) => {
                if (this.uploadConfig.threadFileNameCount[file.name]) {
                    this.uploadConfig.threadFileNameCount[file.name]--;
                }
                const subscription = this._alertWindow.error('Error', [errorMessage]).subscribe((result: boolean) => {
                    subscription.unsubscribe();
                });
            },
            onSending: (file, xhr, config) => {
                xhr.setRequestHeader('Authorization', token);
                this._processingUploads.push(xhr);
                const validfilename = this._fileHelperService.addSuffixCopyToName(config.threadFileNameCount, this._fileHelperService.cleanFileName(file.name));
                const newUIFile = new TamUploadUIFile();
                newUIFile.dzid = file.upload.uuid;
                newUIFile.name = validfilename;
                newUIFile.progress = '0%';
                newUIFile.isNew = true;
                newUIFile.isCompleted = false;
                config.files.push(newUIFile);
                config.threadFileNameCount[newUIFile.name] ? config.threadFileNameCount[newUIFile.name]++ : config.threadFileNameCount[newUIFile.name] = 1;
                this._dropzoneService.reset$.next(true);
            },
            onSuccess: (file, responseObject, config) => {
                config.files = config.files.map((item, index) => {
                    if (item.dzid === file.upload.uuid) {
                        item.isCompleted = true;
                        item.id = responseObject.filenames;
                        item.serverName = responseObject.filenames;
                        this._toBeDeletedOn('cancel', item.serverName);
                    }
                    return item;
                });
                // in case user canceled during upload, remove the item from ui
                config.files = config.files.filter((item, index) => (item.dzid !== file.upload.uuid) || (!item.deleted));
            },
            onRemove: (file, dropzone, inputConfig) => {
                dropzone.files = dropzone.files.filter((item) => file.dzid !== item.upload.uuid);
                // saved file soft delete from ui, catch up api change when re-save
                // unsaved file deleted from bothe places
                if (this._fileRemovableOnServer(file, inputConfig.files)) {
                    const deletSub = this._handleLazyDeletion(file).subscribe(
                        () => {
                            if (deletSub) {
                                deletSub.unsubscribe();
                            }
                        });
                }
                if (this.uploadConfig.threadFileNameCount[file.name]) {
                    this.uploadConfig.threadFileNameCount[file.name]--;
                }
                const feedbackAction = this._getStatusChangeAction('delete', true, { ids: [file.dzid], isDirty: !file.isNew });
                this.config.feedbackSubject$.next(feedbackAction);
                return true;
            },
            onProgress: (file, progress, config) => {
                config.files = config.files.map((item, index) => {
                    if (item.dzid === file.upload.uuid) {
                        item.progress = progress + '%';
                    }
                    return item;
                });
            },
            onStatusChange: (workflow, changedEditing, config, id = null) => {
                const before = this.uploadEditing[workflow];
                this.uploadEditing[workflow] += changedEditing;
                let feedbackAction: FileUploadFeedbackAction;
                let changedFile: Object = null;
                if (id !== null) {
                    changedFile = { ids: [id], isDirty: true };
                }
                if (before === 0 && this.uploadEditing[workflow] > 0) {
                    feedbackAction = this._getStatusChangeAction(workflow, true, changedFile);
                } else if (before > 0 && this.uploadEditing[workflow] === 0) {
                    if (this.uploadEditing.upload === 0 && this.uploadEditing.rename === 0) {
                        feedbackAction = this._getStatusChangeAction(workflow, false, changedFile, this.uploadConfig.files);
                    } else {
                        feedbackAction = this._getStatusChangeAction(workflow, false, changedFile);
                    }
                } else if (workflow === 'remove') {
                    feedbackAction = this._getStatusChangeAction(workflow, false, changedFile);
                }
                this.config.feedbackSubject$.next(feedbackAction);
            },
            onNameChange: (filename, oldfilename, config, index) => {
                const pureName = filename.substring(0, /(?:\.([^.]+))?$/.exec(filename).index);
                if (!pureName || !pureName.trim()) {
                    this._toast.notify({
                        message: 'You must type a file name',
                        style: NotificationStyles.Warning
                    });
                    return false;
                } else if (/(\\|\/|\:|\*|\?|\"|\<|\>|\|)/.exec(pureName)) {
                    this._toast.notify({
                        message: 'Can\'t contain any of the following characters: \\/:*?"<>|',
                        style: NotificationStyles.Warning
                    });
                    return false;
                } else {
                    if (config.threadFileNameCount[oldfilename]) {
                        config.threadFileNameCount[oldfilename]--;
                    }
                    const newname = this._fileHelperService.addSuffixCopyToName(config.threadFileNameCount, filename);
                    if (newname !== filename) {
                        this._toast.notify({
                            message: filename + ' already exists.',
                            style: NotificationStyles.Info
                        });
                    }
                    config.files[index].name = newname;
                    config.threadFileNameCount[newname] ? config.threadFileNameCount[newname]++ : config.threadFileNameCount[newname] = 1;

                    return true;
                }
            },
            upload: null
        };
    }

    /**
     * get/set left over attachments by server file name
     * @private
     * @param {*} workflow :{'save','cancel'}
     * @param {*} [value=null] :null - get value; not null set value
     * @returns {string[]} : server file names to be deleted in given workflow
     */
    private _toBeDeletedOn(workflow, value): string[] {
        if (value) {
            // setter
            this._attachmentLeftovers[workflow].push(value);
            return null;
        } else {
            // getter
            return this._attachmentLeftovers[workflow];
        }
    }

    private _upload(event): void {
        if (this._uploadHandler) {
            this._uploadHandler(event);
        } else if (this.uploadConfig.upload) {
            this._uploadHandler = this.uploadConfig.upload;
            this._uploadHandler(event);
        }
    }

}
