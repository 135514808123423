/**
 * Created by Daniel on 2/18/2020.
 * Description:
 *
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';

export enum WorkflowTaskActionTypes {
    CHANGE_QUICK_TASK_STATUS = '[TASKMANAGEMENT] CHANGE_QUICK_TASK_STATUS',
    CHANGE_QUICK_TASK_STATUS_SUCCESS = '[TASKMANAGEMENT] CHANGE_QUICK_TASK_STATUS_SUCCESS',
    CHANGE_QUICK_TASK_STATUS_FAILED = '[TASKMANAGEMENT] CHANGE_QUICK_TASK_STATUS_FAILED',
    CREATE_QUICK_TASK = '[TASKMANAGEMENT] CREATE_QUICK_TASK',
    CREATE_QUICK_TASK_SUCCESS = '[TASKMANAGEMENT] CREATE_QUICK_TASK_SUCCESS',
    CREATE_QUICK_TASK_FAILED = '[TASKMANAGEMENT] CREATE_QUICK_TASK_FAILED',
    DELETE_TASK = '[TASKMANAGEMENT] DELETE_TASK',
    DELETE_TASK_SUCCESS = '[TASKMANAGEMENT] DELETE_TASK_SUCCESS',
    DELETE_TASK_FAILED = '[TASKMANAGEMENT] DELETE_TASK_FAILED',
    EDIT_QUICK_TASK = '[TASKMANAGEMENT] EDIT_QUICK_TASK',
    EDIT_QUICK_TASK_SUCCESS = '[TASKMANAGEMENT] EDIT_QUICK_TASK_SUCCESS',
    EDIT_QUICK_TASK_FAILED = '[TASKMANAGEMENT] EDIT_QUICK_TASK_FAILED',
    EDIT_QUICK_TASK_REMIND_DATE = '[TASKMANAGEMENT] EDIT_QUICK_TASK_REMIND_DATE',
    EDIT_QUICK_TASK_REMIND_DATE_SUCCESS = '[TASKMANAGEMENT] EDIT_QUICK_TASK_REMIND_DATE_SUCCESS',
    EDIT_QUICK_TASK_REMIND_DATE_FAILED = '[TASKMANAGEMENT] EDIT_QUICK_TASK_REMIND_DATE_FAILED',
    GET_ALL_TASK = '[TASKMANAGEMENT] GET_ALL_TASK',
    GET_ALL_TASK_SUCCESS = '[TASKMANAGEMENT] GET_ALL_TASK_SUCCESS',
    GET_ALL_TASK_FAILED = '[TASKMANAGEMENT] GET_ALL_TASK_FAILED',
    GET_TASK_BY_STATUS = '[TASKMANAGEMENT] GET_TASK_BY_STATUS',
    GET_TASK_BY_STATUS_SUCCESS = '[TASKMANAGEMENT] GET_TASK_BY_STATUS_SUCCESS',
    GET_TASK_BY_STATUS_FAILED = '[TASKMANAGEMENT] GET_TASK_BY_STATUS_FAILED',
    SET_TASK_STATUS = '[TASKMANAGEMENT] SET_TASK_STATUS',
    SET_TASK_STATUS_SUCCESS = '[TASKMANAGEMENT] SET_TASK_STATUS_SUCCESS',
    SET_TASK_STATUS_FAILED = '[TASKMANAGEMENT] SET_TASK_STATUS_FAILED',
    SET_TASK_PROCESSOR = '[TASKMANAGEMENT] SET_TASK_PROCESSOR',
    SET_TASK_PROCESSOR_SUCCESS = '[TASKMANAGEMENT] SET_TASK_PROCESSOR_SUCCESS',
    SET_TASK_PROCESSOR_FAILED = '[TASKMANAGEMENT] SET_TASK_PROCESSOR_FAILED',
}


export class ChangeQuickTaskStatus implements Action {
    readonly type = WorkflowTaskActionTypes.CHANGE_QUICK_TASK_STATUS;
    constructor(public payload: any) { }
}

export class ChangeQuickTaskStatusSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.CHANGE_QUICK_TASK_STATUS_SUCCESS;
    constructor(public payload: any) { }
}

export class ChangeQuickTaskStatusFailed implements Action {
    readonly type = WorkflowTaskActionTypes.CHANGE_QUICK_TASK_STATUS_FAILED;
    constructor(public payload: any) { }
}

export class CreateQuickTask implements Action {
    readonly type = WorkflowTaskActionTypes.CREATE_QUICK_TASK;
    constructor(public payload: any) { }
}

export class CreateQuickTaskSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.CREATE_QUICK_TASK_SUCCESS;
    constructor(public payload: any) { }
}

export class CreateQuickTaskFailed implements Action {
    readonly type = WorkflowTaskActionTypes.CREATE_QUICK_TASK_FAILED;
    constructor(public payload: any) { }
}

export class DeleteTask implements Action {
    readonly type = WorkflowTaskActionTypes.DELETE_TASK;
    constructor(public payload: any) { }
}

export class DeleteTaskSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.DELETE_TASK_SUCCESS;
    constructor(public payload: any) { }
}

export class DeleteTaskFailed implements Action {
    readonly type = WorkflowTaskActionTypes.DELETE_TASK_FAILED;
    constructor(public payload: any) { }
}

export class EditQuickTask implements Action {
    readonly type = WorkflowTaskActionTypes.EDIT_QUICK_TASK;
    constructor(public payload: any) { }
}

export class EditQuickTaskSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.EDIT_QUICK_TASK_SUCCESS;
    constructor(public payload: any) { }
}

export class EditQuickTaskFailed implements Action {
    readonly type = WorkflowTaskActionTypes.EDIT_QUICK_TASK_FAILED;
    constructor(public payload: any) { }
}

export class EditQuickTaskRemindDate implements Action {
    readonly type = WorkflowTaskActionTypes.EDIT_QUICK_TASK_REMIND_DATE;
    constructor(public payload: any) { }
}

export class EditQuickTaskRemindDateSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.EDIT_QUICK_TASK_REMIND_DATE_SUCCESS;
    constructor(public payload: any) { }
}

export class EditQuickTaskRemindDateFailed implements Action {
    readonly type = WorkflowTaskActionTypes.EDIT_QUICK_TASK_REMIND_DATE_FAILED;
    constructor(public payload: any) { }
}

export class GetAllTask implements Action {
    readonly type = WorkflowTaskActionTypes.GET_ALL_TASK;
    constructor(public payload: any) { }
}

export class GetAllTaskSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.GET_ALL_TASK_SUCCESS;
    constructor(public payload: any) { }
}

export class GetAllTaskFailed implements Action {
    readonly type = WorkflowTaskActionTypes.GET_ALL_TASK_FAILED;
    constructor(public payload: any) { }
}

export class GetTaskByStatus implements Action {
    readonly type = WorkflowTaskActionTypes.GET_TASK_BY_STATUS;
    constructor(public payload: any) { }
}

export class GetTaskByStatusSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.GET_TASK_BY_STATUS_SUCCESS;
    constructor(public payload: any) { }
}

export class GetTaskByStatusFailed implements Action {
    readonly type = WorkflowTaskActionTypes.GET_TASK_BY_STATUS_FAILED;
    constructor(public payload: any) { }
}

export class SetTaskStatus implements Action {
    readonly type = WorkflowTaskActionTypes.SET_TASK_STATUS;
    constructor(public payload: any) { }
}

export class SetTaskStatusSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.SET_TASK_STATUS_SUCCESS;
    constructor(public payload: any) { }
}

export class SetTaskStatusFailed implements Action {
    readonly type = WorkflowTaskActionTypes.SET_TASK_STATUS_FAILED;
    constructor(public payload: any) { }
}

export class SetTaskProcessor implements Action {
    readonly type = WorkflowTaskActionTypes.SET_TASK_PROCESSOR;
    constructor(public payload: any) { }
}

export class SetTaskProcessorSuccess implements Action {
    readonly type = WorkflowTaskActionTypes.SET_TASK_PROCESSOR_SUCCESS;
    constructor(public payload: any) { }
}

export class SetTaskProcessorFailed implements Action {
    readonly type = WorkflowTaskActionTypes.SET_TASK_PROCESSOR_FAILED;
    constructor(public payload: any) { }
}

export type WorkflowTaskActions =
    ChangeQuickTaskStatus |
    ChangeQuickTaskStatusSuccess |
    ChangeQuickTaskStatusFailed |
    CreateQuickTask |
    CreateQuickTaskSuccess |
    CreateQuickTaskFailed |
    DeleteTask |
    DeleteTaskSuccess |
    DeleteTaskFailed |
    EditQuickTask |
    EditQuickTaskSuccess |
    EditQuickTaskFailed |
    EditQuickTaskRemindDate |
    EditQuickTaskRemindDateSuccess |
    EditQuickTaskRemindDateFailed |
    GetAllTask |
    GetAllTaskSuccess |
    GetAllTaskFailed |
    GetTaskByStatus |
    GetTaskByStatusSuccess |
    GetTaskByStatusFailed |
    SetTaskStatus |
    SetTaskStatusSuccess |
    SetTaskStatusFailed |
    SetTaskProcessor |
    SetTaskProcessorSuccess |
    SetTaskProcessorFailed;
