export class Adhoc {
    id: string;
    name: string;
    attrDefId: Array<string>;
    controlType: string;
    dataType: string;
    source: Array<string>;
    type: string;
    title: string;
    titleDuplicate?: boolean;

    static parse(serverResponse): Adhoc {
        const result = new Adhoc();
        result.id = serverResponse['id'];
        result.type = serverResponse['type'];
        result.name = serverResponse['name'];
        if (result.type === 'title') {
            result.attrDefId = serverResponse['attrDefId'];
            result.titleDuplicate = serverResponse['titleDuplicate'];
        } else {
            result.attrDefId = [serverResponse['attrDefId']];
        }
        result.controlType = serverResponse['controlType'];
        result.dataType = serverResponse['dataType'];
        result.source = serverResponse['source'] || [];
        result.title = serverResponse['title'];
        return result;
    }
}
