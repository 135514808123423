/**
 * Created by Jiu Chen on 01/12/2018.
 * Description:
 *
 * ------ maintenance history ------
 * Daniel 7/12/2019 Added screen/entity dictionary action to avoid send duplicate request.
 */

import { Action } from '@ngrx/store';

export enum ScreenActionTypes {
    SET_VALUE = '[SCREEN] SET_VALUE',
    SCREEN_DICTIONARY = '[SCREEN] SCREEN_DICTIONARY',
    SCREEN_ENTITY_DICTIONARY = '[SCREEN] ENTITY_DICTIONARY',
    SCREEN_DICTIONARY_DELETE = '[SCREEN] DICTIONARY_DELETE',

    SCREEN_STATUS_DICTIONARY = '[SCREEN] SCREEN_STATUS_DICTIONARY',
    SCREEN_STATUS_DICTIONARY_CLEAR = '[SCREEN] SCREEN_STATUS_DICTIONARY_CLEAR',
}

export class ScreenSetValue implements Action {
    readonly type = ScreenActionTypes.SET_VALUE;

    constructor(public payload: any) { }
}

export class ScreenDictionary implements Action {
    readonly type = ScreenActionTypes.SCREEN_DICTIONARY;

    constructor(public payload: any) { }
}

export class ScreenEntityDictionary implements Action {
    readonly type = ScreenActionTypes.SCREEN_ENTITY_DICTIONARY;

    constructor(public payload: any) { }
}

export class ScreenDeleteDictionary implements Action {
    readonly type = ScreenActionTypes.SCREEN_DICTIONARY_DELETE;

    constructor(public payload: any) { }
}

export class ScreenStatusDictionary implements Action {
    readonly type = ScreenActionTypes.SCREEN_STATUS_DICTIONARY;

    constructor(public payload: any) { }
}

export class ScreenStatusDictionaryClear implements Action {
    readonly type = ScreenActionTypes.SCREEN_STATUS_DICTIONARY_CLEAR;

    constructor(public payload: any) { }
}

export type ScreenActions = ScreenSetValue | ScreenDictionary | ScreenEntityDictionary
    | ScreenDeleteDictionary | ScreenStatusDictionary | ScreenStatusDictionaryClear;
