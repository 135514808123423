import { Action } from '@ngrx/store';

export enum ThreadViewActionTypes {
    GET_THREAD = '[THREAD_VIEW] GET_THREAD',
    GET_THREAD_SUCCESS = '[THREAD_VIEW] GET_THREAD_SUCCESS',
    GET_THREAD_FAILED = '[THREAD_VIEW] GET_THREAD_FAILED',
    REMOVE_ATTACHMENT = 'DELETE_ATTACHMENT',
    GET_THREAD_SIDESHEET = '[THREAD_VIEW] GET_THREAD_SIDESHEET',
    GET_THREAD_SIDESHEET_SUCCESS = '[THREAD_VIEW] GET_THREAD_SIDESHEET_SUCCESS',
    GET_THREAD_SIDESHEET_FAILED = '[THREAD_VIEW] GET_THREAD_SIDESHEET_FAILED',
    REMOVE_THREAD_SIDESHEET_ITEM = '[THREAD_VIEW] REMOVE_THREAD_SIDESHEET_ITEM'
}

export class ThreadViewGetThread implements Action {
    readonly type = ThreadViewActionTypes.GET_THREAD;

    constructor(public payload: any) { }
}

export class ThreadViewGetThreadSuccess implements Action {
    readonly type = ThreadViewActionTypes.GET_THREAD_SUCCESS;

    constructor(public payload: any) { }
}

export class ThreadViewGetThreadFailed implements Action {
    readonly type = ThreadViewActionTypes.GET_THREAD_FAILED;

    constructor(public payload: any) { }
}

export class ThreadViewRemoveAttachment implements Action {
    readonly type = ThreadViewActionTypes.REMOVE_ATTACHMENT;

    constructor(public payload: any) { }
}

export class GetThreadSideSheet implements Action {
    readonly type = ThreadViewActionTypes.GET_THREAD_SIDESHEET;

    constructor(public payload: any) { }
}

export class GetThreadSideSheetSuccess implements Action {
    readonly type = ThreadViewActionTypes.GET_THREAD_SIDESHEET_SUCCESS;

    constructor(public payload: any) { }
}

export class GetThreadSideSheetFailed implements Action {
    readonly type = ThreadViewActionTypes.GET_THREAD_SIDESHEET_FAILED;

    constructor(public payload: any) { }
}

export class RemoveThreadSideSheetItem implements Action {
    readonly type = ThreadViewActionTypes.REMOVE_THREAD_SIDESHEET_ITEM;

    constructor(public payload: any) { }
}

export type ThreadViewActions = ThreadViewGetThread | ThreadViewGetThreadSuccess | ThreadViewGetThreadFailed | ThreadViewRemoveAttachment | GetThreadSideSheet | GetThreadSideSheetSuccess | GetThreadSideSheetFailed | RemoveThreadSideSheetItem;
