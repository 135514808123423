/**
 * Created by Abner Sui on 04/19/2019.
 * Description:
 *
 * ------ maintenance history ------
 * 01/07/2020   Yoyo Fang   extract functionalities from generateShortId to a new public function nextGUID, which accept array of guid
 * 04/02/2022 Simon Zhao changed all functions into static methods.
 */

import { TileColumn, TileColumnDataType, TileColumnType, TileColumnAdhocType, TileColumnSetting } from '../tamalelibs/models/tile-column.model';
import { IdHelperService } from './id-helper.service';
import { Adhoc } from '../tamalelibs/models/adhoc.model';
import { businessConstants } from '../constants/business.constants';

export class TileSettingService {

    private static _letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'a'];
    private static _numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

    /**
     * The function used to create the shortId for the tile grid column
     * 3: The length of the tile guid column guid
     * 29: It's the fixed guid part for file column
     * @param columns The current tle gird columns
     * @returns The new guid
     */
    static generateShortId(columns: Array<TileColumn>): string {
        const existingGuids = columns.filter(column => column.guid.length === 3 || column.field === businessConstants.dashboard.field.entry_file).map((column) => column.guid);
        const updateExistingGuids = [];
        existingGuids.forEach(item => {
            if (item.length > 3) {
                item = item.substring(29);
            }
            updateExistingGuids.push(item);
        });
        return TileSettingService.nextGUID(updateExistingGuids);
    }

    static nextGUID(existingGuids: Array<string>): string {
        let max = 'a0a';
        existingGuids.forEach(existingId => {
            if (existingId.localeCompare(max) > 0) {
                max = existingId;
            }
        });
        let result: string;
        if (max === 'z9z') {
            // Handle corner case
            max = 'a0a';
            while (existingGuids.findIndex(item => item === max) > -1 && max !== 'z9z') {
                max = TileSettingService._plusForShortId(max);
            }
            if (max === 'z9z') {
                // Handle corner corner case
                result = IdHelperService.createGUID(1);
            } else {
                result = max;
            }
        } else {
            result = TileSettingService._plusForShortId(max);
        }
        return result;
    }


    static generateColumnForAdhocDB(adhoc: Adhoc): TileColumn {
        const result = {
            columnType: TileColumnType.ENTRY_COLUMN,
            settings: new TileColumnSetting(),
            field: 'entryadhoc__' + adhoc.attrDefId[0],
            hidden: false,
            dataType: TileColumnDataType.TEXT,
            name: adhoc.name,
            guid: '',
            title: adhoc.title,
            attrDefId: adhoc.attrDefId[0],
            formattingConfig: {},
            type: TileColumnAdhocType.DB,
            id: adhoc.id,
        };
        if (adhoc.dataType === 'date') {
            result.dataType = TileColumnDataType.DATE;
            result.settings.display = '1';
        } else if (adhoc.dataType === 'entity') {
            result.dataType = TileColumnDataType.ENTITY;
            result.settings.display = '0';
        }
        return result;
    }

    /**
     * Use number as example, [1,2,3,5,6,7] + [6,8,2,3] = [1,6,2,3,5,7]
     */
    static sortColumnsByIdList(columns: Array<TileColumn>, ids: Array<string>): Array<TileColumn> {
        const result: Array<TileColumn> = [];
        const tempColumns = Object.assign([], columns);
        let columnIndex = 0;
        let idIndex = 0;
        while (tempColumns.length) {
            if (idIndex < ids.length) {
                const tempColumnIndex = tempColumns.findIndex(item => item.guid === ids[idIndex]);
                if (tempColumnIndex > -1) {
                    const tempIdIndex = ids.indexOf(tempColumns[columnIndex].guid);
                    if (tempIdIndex > -1) {
                        if (tempIdIndex !== idIndex) {
                            columnIndex = tempColumnIndex;
                        }
                        idIndex++;
                    }
                    result.push(tempColumns.splice(columnIndex, 1)[0]);
                    columnIndex = 0;
                } else {
                    idIndex++;
                }
            } else {
                result.push(...tempColumns.splice(0, tempColumns.length));
            }
        }
        return result;
    }

    private static _plusForShortId(max: string): string {
        let carry = false;
        let result = TileSettingService._letters[this._letters.indexOf(max[2]) + 1];
        if (max[2] === 'z') {
            carry = true;
        }
        if (carry) {
            result = TileSettingService._numbers[this._numbers.indexOf(max[1]) + 1] + result;
            if (max[1] === '9') {
                carry = true;
            } else {
                carry = false;
            }
        } else {
            result = max[1] + result;
            carry = false;
        }
        if (carry) {
            result = TileSettingService._letters[TileSettingService._letters.indexOf(max[0]) + 1] + result;
        } else {
            result = max[0] + result;
        }
        return result;
    }

}
