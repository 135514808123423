/**
 * Created by Abner Sui on 01/29/2019.
 * Description:
 *
 * ------ maintenance history ------
 * 08/04 marucs add parseForIntralinks for parse intralink data
 */

import { EntryClass } from './entry-class.model';
import { IntralinksFile } from './intralinks.model';

export class DocumentEmail {

    id: string;
    extension: string;
    fileDataLink: string; // the real binary data path.
    fileName: string;
    isEmbeddedImg: boolean;
    noteId: string; // Can't get from server response; Don't know where to use it;
    serverFileName: string;
    threadId: string; // The thread which owns this file, the pointer to root.
    entryClass: EntryClass;

    constructor() {
        this.entryClass = EntryClass.File;
    }

    static parseForBlastEmail(serverResponse): DocumentEmail {
        const email = new DocumentEmail();
        email.fileName = serverResponse.fileName;
        email.id = serverResponse.id;
        email.extension = email.fileName.substring(email.fileName.lastIndexOf('.') + 1);

        email.fileDataLink = serverResponse.href;
        email.isEmbeddedImg = false;

        return email;
    }

    static parseForIntralinks(intralinksData: IntralinksFile): DocumentEmail {
        const email = new DocumentEmail();
        email.fileName = intralinksData.name + intralinksData.extension;
        email.id = intralinksData.id;
        email.extension = email.fileName.substring(email.fileName.lastIndexOf('.') + 1);
        email.fileDataLink = '';
        email.isEmbeddedImg = false;
        return email;
    }
}
