/**
 * created by Yu Zhang on 2/20/18.
 * Description:
 *      implement the methods in each platform folder and use Angular provider to provide the right instance
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';

@Injectable()
export class INetworkErrorHandler {
    noNetworkExceptionHanlder(): Boolean {
        return false;
    }
}
