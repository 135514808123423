/**
 * Created by Daniel on 3/14/2019.
 * Description:
 *
 * ------ maintenance history ------
 */
import { AppState } from '..';
import { createSelector, combineReducers } from '@ngrx/store';
import { HomeScreenActions, HomeScreenActionTypes } from '../actions/home-screen.actions';
import { ArrayHelperService } from '../../tamalelibs/services/array-helper.service';

export function homeScreenDataReducer(state: any, action: HomeScreenActions) {
    switch (action.type) {
        case HomeScreenActionTypes.LOAD_SUCCESS:
            const layouts = action.payload.data;
            ArrayHelperService.sort(layouts, 'name');
            return { data: layouts, type: action.payload.type };
        case HomeScreenActionTypes.LOAD_FAILED:
        case HomeScreenActionTypes.CLEAN_UP:
            return null;
        default:
            return state;
    }
}

export function shareLayoutReducer(state: string, action: HomeScreenActions) {
    switch (action.type) {
        case HomeScreenActionTypes.SHARE_LAYOUT:
            return action.payload;
        case HomeScreenActionTypes.CLEAN_UP:
            return null;
        default:
            return state;
    }
}

export function changeLayoutReducer(state: string, action: HomeScreenActions) {
    switch (action.type) {
        case HomeScreenActionTypes.CHANGE_LAYOUT:
            return action.payload;
        case HomeScreenActionTypes.CLEAN_UP:
            return null;
        default:
            return state;
    }
}

export function deletLayoutReducer(state: any, action: HomeScreenActions) {
    switch (action.type) {
        case HomeScreenActionTypes.DELETE_SUCCESS:
        case HomeScreenActionTypes.LOAD_FAILED:
            return action.payload;
        case HomeScreenActionTypes.CLEAN_UP:
            return null;
        default:
            return state;
    }
}

export const getHomeScreenState = (state: AppState) => state.homeScreen;
export const getHomeScreenSelector = createSelector(getHomeScreenState, (state: any) => state.homeScreenItem);
export const getShareHomeScreenLayoutSelector = createSelector(getHomeScreenState, (state: any) => state.shareHomeScreenLayout);
export const getChangeHomeScreenLayoutSelector = createSelector(getHomeScreenState, (state: any) => state.changeHomeScreenLayout);
export const deleteHomeScreenLayoutSelector = createSelector(getHomeScreenState, (state: any) => state.deleteHomeScreenLayout);

const homeScreenReducer = combineReducers({
    homeScreenItem: homeScreenDataReducer,
    shareHomeScreenLayout: shareLayoutReducer,
    changeHomeScreenLayout: changeLayoutReducer,
    deleteHomeScreenLayout: deletLayoutReducer
});

export function getHomeScreenReducer(state, action) {
    return homeScreenReducer(state, action);
}
