/**
 * Created by Abner Sui on 09/24/2020.
 * Description:
 *
 * ------ maintenance history ------
 */
import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { SystemUser } from '../../tamalelibs/models/user.model';
import { StoreQuerierService } from '../../services/store-querier.service';

@Injectable({
    providedIn: 'root'
})
export class AdminPageGuard implements CanActivate {
    constructor(
        private _router: Router,
        private _storeQuerierService: StoreQuerierService,
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        const currentUser: SystemUser = this._storeQuerierService.getCurrentUser();
        if (currentUser && currentUser.credential.admin) {
            return true;
        } else {
            this._router.navigate(['error']);
            return false;
        }
    }
}
