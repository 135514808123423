/**
 * Created by Simon Zhao on 7/19/2021.
 * Description:
 * defines events happen with MDL templates.
 * ------ maintenance history ------
 */

import { Action } from '@ngrx/store';
import { IMDLTemplate } from '../../tamalelibs/models/template-mdl.model';


export enum MDLTemplateActionTypes {
    INITIALIZE = '[MDL_TEMPLATE] INITIALIZE',
    INITIALIZE_SUCCESS = '[MDL_TEMPLATE] INITIALIZE_SUCCESS',
    INITIALIZE_FAILED = '[MDL_TEMPLATE] INITIALIZE_FAILED',
    CREATE_OR_UPDATE = '[MDL_TEMPLATE] CREATE_OR_UPDATE',
    CREATE_OR_UPDATE_SUCCESS = '[MDL_TEMPLATE] CREATE_OR_UPDATE_SUCCESS',
    CREATE_OR_UPDATE_FAILED = '[MDL_TEMPLATE] CREATE_OR_UPDATE_FAILED',
}

export class Initialize implements Action {
    readonly type = MDLTemplateActionTypes.INITIALIZE;
}

export class InitializeSuccess implements Action {
    readonly type = MDLTemplateActionTypes.INITIALIZE_SUCCESS;

    constructor(public payload: any) { }
}

export class InitializeFailed implements Action {
    readonly type = MDLTemplateActionTypes.INITIALIZE_FAILED;

    constructor(public payload: any) { }
}

export class CreateOrUpdate implements Action {
    readonly type = MDLTemplateActionTypes.CREATE_OR_UPDATE;

    constructor(public payload: IMDLTemplate, public typeId: string, public typeName: string) { }
}

export class CreateOrUpdateFailed implements Action {
    readonly type = MDLTemplateActionTypes.CREATE_OR_UPDATE_FAILED;

    constructor(public payload: { typeId: string, errorMsg: string }) { }
}

export class CreateOrUpdateSuccess implements Action {
    readonly type = MDLTemplateActionTypes.CREATE_OR_UPDATE_SUCCESS;

    constructor(public payload: { typeId: string, typeName: string, mdl?: IMDLTemplate }) { }
}

export type MDLTemplateActions = Initialize | InitializeSuccess | InitializeFailed | CreateOrUpdate | CreateOrUpdateFailed | CreateOrUpdateSuccess;
