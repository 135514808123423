/**
 * Created by Abner, Sui on 09/28/2020
 * -------------------------------------
 */

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ImgPreviewService {

    actionSubject$: Subject<{ type: string, payload: string }> = new Subject();
}
