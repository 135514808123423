import { combineReducers, createSelector } from '@ngrx/store';

import { AuditLogActions, AuditLogActionTypes } from '../actions/audit-log.actions';
import { AppState } from '..';
import { FiltersConfig } from '../../tamalelibs/components/filter-config';

/**
 *
 * Sets filterList using new payload or adds filterList in payload to original filterList.
 * @export
 * @param {Array<FiltersConfig>} [state=[]]
 * @param {AuditLogActions} action
 * @returns filterList: Array<FiltersConfig>
 */
export function filterListReducer(state: Array<FiltersConfig> = [], action: AuditLogActions) {
    switch (action.type) {
        case AuditLogActionTypes.SET_FILTER_LIST:
            return action.payload;
        case AuditLogActionTypes.ADD_FILTER_LIST:
            return state.concat(action.payload);
        case AuditLogActionTypes.DELETE_FILTER_LIST:
            return action.payload;
        default:
            return state;
    }
}

export const getAuditLogState = (state: AppState) => state.auditLog;
export const getFilterListState = createSelector(getAuditLogState, (state: any) => state.filterList);

const auditLogReducer = combineReducers({
    filterList: filterListReducer
});

export function getAuditLogRedcuer(state, action) {
    return auditLogReducer(state, action);
}
