/**
 * Created by Yu Zhang on 8/11/17.
 * Description:
 *
 * ------ maintenance history ------
 * 06/26/20 Todd Yu: added support for Admin Page Entry Type Requests
 * 05/19/22 Marcus Zhao : change createNewEntryType /editEntryType to use formdata.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { TransportService } from './transport.service';
import { AppConfig } from '../models/app-config.model';
import { EntryType } from '../models/entry-type.model';

@Injectable()
export class EntryTypeService {

    constructor(private _transportService: TransportService) { }

    createNewEntryType(name: string, data: any): Observable<any> {
        const url = `${AppConfig.entryTypeEndpoint}`;
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('default-note', 'false');
        formData.append('default-event', 'false');
        formData.append('hidden-types', (!data['display-web'] || false).toString());
        formData.append('usage', JSON.stringify(data.usage));
        formData.append('config', JSON.stringify(data.config));
        return this._transportService.post(url, formData);
    }

    deleteEntryType(Id: string): Observable<any> {
        let url = `${AppConfig.entryTypeEndpoint}`;
        url = url + Id;
        return this._transportService.delete(url);
    }

    editEntryType(name: string, id: string, data: any): Observable<any> {
        console.log('data', data);
        const url = `${AppConfig.entryTypeEndpoint}${id}`;
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('default-note', data['default-note'] || false);
        formData.append('default-event', data['default-event'] || false);
        formData.append('hidden-types', (!data['display-web'] || false).toString());
        formData.append('usage', JSON.stringify(data.usage));
        formData.append('config', JSON.stringify(data.config));
        return this._transportService.put(url, formData);
    }
    getAdminPageEntryTypeList(): Observable<any> {
        let url = `${AppConfig.entryTypeEndpoint}`;
        url = url + '?showall=true';
        return this._transportService.get(url);
    }

    getAllEntryTypes(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryTypeEndpoint}`;
        const params = {
            sortby: 'name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getAllEntryTypesGeneric(): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryTypeEndpoint}`;
        const params = {
            sortby: 'name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    getAvailableList() {
        const url = `${AppConfig.entryTypeEndpoint}`;
        const params = {
            sortby: 'name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        const options = {
            params: params
        };
        return this._transportService.get(url, options);
    }

    getDefaultEntryType(entryClass: string = 'note'): Observable<any> {
        const headers = {
            'Content-Type': 'application/json'
        };
        const url = `${AppConfig.entryTypeEndpoint}`;
        const params = {
            getdefaulttype: true,
            entryclass: entryClass,
            outputformat: 'json'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    /**
     * @params entryType which is used to get the default sidenote id
     * @returns the default sidenote id
     */
    getDefaultSidenoteType(entryType: EntryType): string {
        let defaultSidenoteTypeId = '';
        if (entryType) {
            if (entryType.config &&
                entryType.config.customDefaultSideNoteType) {
                defaultSidenoteTypeId = entryType.config.customDefaultSideNoteType;
            }
        }
        return defaultSidenoteTypeId;
    }

    getFullList() {
        const url = `${AppConfig.entryTypeEndpoint}`;
        const params = {
            showall: true,
            sortby: 'name',
            sortorder: 'asc',
            outputformat: 'json'
        };
        const options = {
            params: params
        };
        return this._transportService.get(url, options);
    }
}
