
/**
 * Created by Marcus Zhao on 06/6/2019.
 * Description: constructor a prefix params to adapt SmartReducer
 *
 * ------ maintenance history ------
 * 7/22/21 SimonZhao Add Template Fields Actions.
 */
import { Action } from '@ngrx/store';
// tslint:disable-next-line: max-line-length
import { GET_ENTITY_TEMPLATE_CONFIGUARTION, GET_ENTITY_TEMPLATE_CONFIGUARTION_SUCCESS, GET_ENTRY_TYPE, GET_ENTRY_TEMPLATE_CONFIGUARTION, CHANGE_TEMPLATE_VALUE_SET, GET_THREAD_ATTACHMENT, CLEAN_TEMPLATE_VALUE_SET, CLEAN_ENTITY_TEMPLATE_CONFIGURATION, CLEAN_TEMPLATE_MSG, GET_TEMPLATE_FIELDS, GET_TEMPLATE_FIELDS_SUCCESS, GET_TEMPLATE_FIELDS_FAILURE, ADD_TEMPLATE_FIELDS_TO_CACHE, GET_ENTITY_FIELDS, GET_ENTITY_FIELDS_SUCCESS, GET_ENTITY_FIELDS_FAILURE, ADD_ENTITY_FIELDS_TO_CACHE, CREATE_RELATIONSHIP_SUCCESS, CREATE_RELATIONSHIP_FAILURE } from '../../tamalelibs/redux/template.actions';
import { CLEAR_ENTITY_MSG, CREATE_ENTITY, EDIT_ENTITY, CREATE_EXTRAOROFFICE, CREATE_MEMBER, CREATE_ENTITY_ALL_SUCCESS, EDIT_ENTITY_ALL_SUCCESS } from '../../tamalelibs/redux/entity.actions';
import { GET_ENTITY, GET_TEMPLATE_CONFIG } from '../../tamalelibs/redux/app.actions';
import { CREATE_NOTE_SUCCESS } from '../../tamalelibs/redux/note.actions';
import { TemplateField } from '../../tamalelibs/models/template-field.model';

export class EntityTemplateAction {
    constructor(public payload, prefixName: string, prefixValue: string) {
        if (prefixName && prefixValue) {
            this[prefixName] = prefixValue;
        }
    }
}
export class GetEntityTemplateConfiguration extends EntityTemplateAction implements Action {
    readonly type = GET_ENTITY_TEMPLATE_CONFIGUARTION;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}
export class GetEntityTemplateConfigurationSuccess extends EntityTemplateAction implements Action {
    readonly type = GET_ENTITY_TEMPLATE_CONFIGUARTION_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}
export class GetEntryTemplateConfiguration extends EntityTemplateAction implements Action {
    readonly type = GET_ENTRY_TEMPLATE_CONFIGUARTION;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}
export class GetEntryType extends EntityTemplateAction implements Action {
    readonly type = GET_ENTRY_TYPE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}
export class GetChangeTemplateValueSet extends EntityTemplateAction implements Action {
    readonly type = CHANGE_TEMPLATE_VALUE_SET;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class GetThreadAttachment extends EntityTemplateAction implements Action {
    readonly type = GET_THREAD_ATTACHMENT;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class GetCleanTemplateValueSet extends EntityTemplateAction implements Action {
    readonly type = CLEAN_TEMPLATE_VALUE_SET;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class ClearEntityTemplateConfig extends EntityTemplateAction implements Action {
    readonly type = CLEAN_ENTITY_TEMPLATE_CONFIGURATION;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class ClearEntityMsg extends EntityTemplateAction implements Action {
    readonly type = CLEAR_ENTITY_MSG;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateEntity extends EntityTemplateAction implements Action {
    readonly type = CREATE_ENTITY;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class EditEntity extends EntityTemplateAction implements Action {
    readonly type = EDIT_ENTITY;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateExtraOrOffice extends EntityTemplateAction implements Action {
    readonly type = CREATE_EXTRAOROFFICE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateMembers extends EntityTemplateAction implements Action {
    readonly type = CREATE_MEMBER;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class GetEntity extends EntityTemplateAction implements Action {
    readonly type = GET_ENTITY;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class GetTemplateConfig extends EntityTemplateAction implements Action {
    readonly type = GET_TEMPLATE_CONFIG;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateNoteSuccess extends EntityTemplateAction implements Action {
    readonly type = CREATE_NOTE_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateRelationShipSuccess extends EntityTemplateAction implements Action {
    readonly type = CREATE_RELATIONSHIP_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateRelationShipFailed extends EntityTemplateAction implements Action {
    readonly type = CREATE_RELATIONSHIP_FAILURE;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CleanTemplateMsg extends EntityTemplateAction implements Action {
    readonly type = CLEAN_TEMPLATE_MSG;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class CreateEntityAllSuccess extends EntityTemplateAction implements Action {
    readonly type = CREATE_ENTITY_ALL_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class EditEntityAllSuccess extends EntityTemplateAction implements Action {
    readonly type = EDIT_ENTITY_ALL_SUCCESS;
    constructor(payload, prefixName: string = null, prefixValue: string = null) {
        super(payload, prefixName, prefixValue);
    }
}

export class GetTemplateFields implements Action {
    readonly type = GET_TEMPLATE_FIELDS;
    constructor(public payload: string) {
    }
}

export class GetTemplateFieldsSuccess implements Action {
    readonly type = GET_TEMPLATE_FIELDS_SUCCESS;
    constructor(public payload: Array<TemplateField>) {
    }
}

export class GetTemplateFieldsFailure implements Action {
    readonly type = GET_TEMPLATE_FIELDS_FAILURE;
    constructor(public payload: string) {
    }
}

export type TemplateFieldsActions = GetTemplateFields | GetTemplateFieldsSuccess | GetTemplateFieldsFailure | AddTemplateFieldsToCache;

export class AddTemplateFieldsToCache implements Action {
    readonly type = ADD_TEMPLATE_FIELDS_TO_CACHE;
    constructor(public id: string, public payload: Array<TemplateField>) { }
}

export class GetEntityFields implements Action {
    readonly type = GET_ENTITY_FIELDS;
    constructor(public payload: string) {
    }
}

export class GetEntityFieldsSuccess implements Action {
    readonly type = GET_ENTITY_FIELDS_SUCCESS;
    constructor(public payload: Array<TemplateField>) {
    }
}

export class GetEntityFieldsFailure implements Action {
    readonly type = GET_ENTITY_FIELDS_FAILURE;
    constructor(public payload: string) {
    }
}

export class AddEntityFieldsToCache implements Action {
    readonly type = ADD_ENTITY_FIELDS_TO_CACHE;
    constructor(public id: string, public payload: Array<TemplateField>) {
    }
}

export type EntityFieldsActions = GetEntityFields | GetEntityFieldsSuccess | GetEntityFieldsFailure | AddEntityFieldsToCache;
