/**
 * Created by Daniel on 12/12/2020.
 */

import { Injectable } from '@angular/core';
import { tz } from 'moment-timezone';

import { DefinitionSetting, Scheduler, TimeZone, WorkflowTriggerSettings } from '../models/workflow-config.model';
import { ProcessDefinition } from '../models/workflow.model';
import { DateHelperWebService } from '../services/date-helper.web.service';

@Injectable()
export class WorkflowConfigService {
    private _timezoneList: Array<TimeZone>;
    private _definitionSettingConfig: DefinitionSetting;
    private _workflowTriggerSettingConfig: WorkflowTriggerSettings;
    private _processDefinitionConfig: ProcessDefinition;

    get timezoneList(): Array<TimeZone> {
        return this._timezoneList;
    }

    get getWorkflowTriggerSettingConfig() {
        return this._workflowTriggerSettingConfig;
    }

    set setWorkflowTriggerSettingConfig(value) {
        this._workflowTriggerSettingConfig = value;
    }

    get getDefinitionSettingConfig() {
        return this._definitionSettingConfig;
    }

    set setDefinitionSettingConfig(value) {
        this._definitionSettingConfig = value;
    }

    get getProcessDefinitionConfig() {
        return this._processDefinitionConfig;
    }

    set setProcessDefinitionConfig(value) {
        this._processDefinitionConfig = value;
    }

    constructor(
    ) {
        this._timezoneList = [];
        tz.names().forEach(item => {
            this._timezoneList.push({
                id: '(GMT' + tz(item).format('Z') + ')' + item,
                name: '(GMT' + tz(item).format('Z') + ') ' + item.replace('/', ' / '),
                offset: this.getOffsetNumFromOffsetStr(tz(item).format('Z')),
                zonename: item,
            });
        });
        this._timezoneList.sort((one, two) => {
            if (one.offset === two.offset) {
                return one.zonename.localeCompare(two.zonename);
            } else {
                return one.offset - two.offset;
            }
        });
    }

    public getDefaultScheduler(): Scheduler {
        const schdueler = new Scheduler();
        schdueler.timezone = this.getDefaultTimezone();
        schdueler.startDate = this.parseStartDateFromUIToServer(DateHelperWebService.getDateOnlyOfToday(), this.timezoneList.find(item => item.id === schdueler.timezone).offset);
        return schdueler;
    }

    public getDefaultTimezone(): string {
        const timezone = '(GMT' + tz(tz.guess(true)).format('Z') + ')' + tz.guess();
        return timezone;
    }

    public getOffsetNumFromOffsetStr(offsetStr: string): number {
        const hourStr = offsetStr.substring(1, 3);
        const minuteStr = offsetStr.substring(4);
        let result = (+hourStr) * 60 + (+minuteStr);
        if (offsetStr.startsWith('-')) {
            result = -result;
        }
        return result;
    }

    public parseSchedulerToParams(data: Scheduler): any {
        const result: any = Object.assign({}, data);
        if (result.startDate) {
            result.startDate = result.startDate.getTime();
        }
        return result;
    }

    public parseStartDateFromUIToServer(originDate: Date, minuteOffset: number): Date {
        const localMinuteOffset = this.getOffsetNumFromOffsetStr(tz(tz.guess()).format('Z'));
        const result: Date = new Date(originDate.getTime() - (minuteOffset - localMinuteOffset) * 60 * 1000);
        return result;
    }

    public parseStartDateFromServerToUI(originDate: Date, minuteOffset: number): Date {
        const localMinuteOffset = this.getOffsetNumFromOffsetStr(tz(tz.guess()).format('Z'));
        const result: Date = new Date(originDate.getTime() + (minuteOffset - localMinuteOffset) * 60 * 1000);
        return result;
    }
}
