import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { DualListConfig, DualListEventType } from './dual-list.config';

export class DualListItem {
    data: any;
    direction: string;
    selected: boolean;
    moveable: boolean;

    constructor(data: any, direction: string = 'left', selected: boolean = false, moveable: boolean = true) {
        this.data = data;
        this.direction = direction;
        this.selected = selected;
        this.moveable = moveable;
    }
}

@Component({
    selector: 'tam-dual-list',
    templateUrl: './dual-list.component.html',
    styleUrls: ['./dual-list.component.scss']
})
export class DualListComponent implements OnInit {
    @Input() config: DualListConfig;
    @Input() itemRender: TemplateRef<void>;

    leftFilterStr: string;
    rightFilterStr: string;

    get placeholderText(): string {
        return this.config.placeholderText;
    }

    get listLeft() {
        return this.getList('left', this.leftFilterStr);
    }

    get listRight() {
        return this.getList('right', this.rightFilterStr);
    }

    constructor() { }

    ngOnInit() {
    }

    getLeftList = () => this.getList('left', this.leftFilterStr);
    getRigthList = () => this.getList('right', this.rightFilterStr);

    getList(direction: string, filterStr) {
        return this.config.dataSource
            .filter(item => item.direction === direction)
            .filter(item => {
                if (!filterStr) {
                    return true;
                } else {
                    return item.data[this.config.dataTextField].toLowerCase().indexOf(filterStr.toLowerCase()) > -1;
                }
            }).sort((a, b) => {
                return a.data[this.config.dataTextField].localeCompare(b.data[this.config.dataTextField]);
            });
    }

    getSelectedItem(keyOnly?: boolean) {
        return this.config.dataSource
            .filter(item => item.direction === 'right')
            .map(item => {
                if (keyOnly) {
                    return item.data[this.config.dataValueField];
                } else {
                    return item.data;
                }
            });
    }

    isMovable(direction: string) {
        if (this.config.disabled) {
            return true;
        }

        if (this.config.required && direction === 'left') {
            return this.config.dataSource.filter(item => item.selected === false && item.direction === 'right').length === 0;
        }
    }

    moveTo(direction: string): void {
        if (this.isMovable(direction)) {
            return;
        }

        this.config.dataSource
            .filter(item => item.moveable && item.selected === true && item.direction !== direction)
            .forEach(item => {
                item.direction = direction;
                item.selected = false;
            });

        this.config.feedbackSubject$.next({ type: DualListEventType.selectChange });
    }

    onItemClick(item) {
        item.selected = !item.selected;
    }

    onItemDoubleClick(item: DualListItem, direction: string) {
        item.selected = true;
        const toDirection = direction === 'left' ? 'right' : 'left';
        this.moveTo(toDirection);
    }

    setSelectedItem(items, moveable = true) {
        this.config.dataSource
            .forEach(item => {
                if (items.indexOf(item.data.id) > -1) {
                    item.selected = true;
                    item.direction = 'right';
                    item.moveable = moveable;
                }
            });
    }
}
