/**
 * Created by Abner Sui on 1/5/2021
 * -------------------------------------
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { AppState } from '../../../redux';
import { IdHelperService } from '../../../services/id-helper.service';
import { SlideSheetService } from '../../../services/slide-sheet.service';
import { StoreQuerierService } from '../../../services/store-querier.service';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { WorkflowActionTypes } from '../../../tamalelibs/redux/actions/workflow.actions';
import { workflowFieldsSelector, workflowUsedFieldsSelector } from '../../../tamalelibs/redux/reducers/workflow.reducer';
import { SlideSheetActionTypes } from '../../slide-sheet/slide-sheet.model';
import { ControlBrief, Template, TemplateConfigurationService } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';
import { FileConfigComponent } from './file-config.component';
import { FileFieldComponent } from './file-field.component';
import { NumberConfigComponent } from './number-config.component';
import { NumberFieldComponent } from './number-field.component';
import { SingleEntityDropdownConfigComponent } from './single-entity-dropdown-config.component';
import { SingleEntityDropdownFieldComponent } from './single-entity-dropdown-field.component';
import { SingleTextDropdownConfigComponent } from './single-text-dropdown-config.component';
import { SingleTextDropdownFieldComponent } from './single-text-dropdown-field.component';
import { TextConfigComponent } from './text-config.component';
import { TextFieldComponent } from './text-field.component';
import * as lodash from 'lodash';
import { Field, FieldDefinition, FieldType } from '../../../tamalelibs/models/workflow.model';
import { map, switchMap } from 'rxjs/operators';
import { EntityBrief } from '../../../tamalelibs/models/entity-brief.model';
import { EntityService } from '../../../tamalelibs/services/entity.service';
import { DateConfigComponent } from './date-config.component';
import { DateFieldComponent } from './date-field.component';
import { TextAreaConfigComponent } from './text-area-config.component';
import { TextAreaFieldComponent } from './text-area-field.component';
import { RichEditorFieldComponent } from './rich-editor-field.component';
import { RichEditorConfigComponent } from './rich-editor-config.component';
import { EntityFieldComponent } from './entity-field.component';
import { EntityDropdownConfigComponent } from './entity-dropdown-config.component';
import { CheckListFieldComponent } from './check-list-field/check-list-field.component';
import { CheckListConfigComponent } from './check-list-config/check-list-config.component';
import { SingleUserDropdownFieldComponent } from './single-user-dropdown-field/single-user-dropdown-field.component';
import { SingleUserDropdownConfigComponent } from './single-user-dropdown-config/single-user-dropdown-config.component';

@Injectable({
    providedIn: 'root'
})
export class TaskDefinitionTemplateService implements TemplateConfigurationService {
    static controlTypeDataTypeMap: Map<ControlType, FieldType> = new Map(
        [
            [ControlType.TEXT, FieldType.TEXT],
            [ControlType.SINGLE_ENTITY_DROP_DOWN, FieldType.ENTITY],
            [ControlType.SINGLE_TEXT_DROP_DOWN, FieldType.TEXT],
            [ControlType.MULTI_ENTITY_DROP_DOWN, FieldType.ENTITY],
            [ControlType.NUMBER, FieldType.NUMBER],
            [ControlType.FILE, FieldType.FILE],
            [ControlType.DATE, FieldType.DATE],
            [ControlType.TEXT_AREA, FieldType.TEXT_AREA],
            [ControlType.RICH_EDITOR, FieldType.RICH_EDITOR],
            [ControlType.SINGLE_USER_DROP_DOWN, FieldType.ENTITY],
        ]
    );

    taskDefinitionTemplateSubject$: Subject<any> = new Subject();
    fieldExtraConfig: Map<ControlType, any> = new Map();
    /**
     * generates the validation error result for the given template
     *
     * @static
     * @param {Template} template the given template.
     * @param {string[]} [validCtrlIds=[]] An output parameter, provide an ID array of valid controls in the given template.
     * @param {Map<string, string[]>} [labelNameMap=new Map()] An output parameter, provide a map of label usages in the given template.
     * @return {*}  {Map<string, FieldValidationError>} A validation error result of given template.
     * @memberof TaskDefinitionTemplateService
     */
    static getInvalidControlDetail(template: Template, validCtrlIds: string[] = [], labelNameMap: Map<string, string[]> = new Map()): Map<string, FieldValidationError> {
        const result = new Map<string, FieldValidationError>();
        const usedNames = [];
        template.contains.forEach((row: Array<FieldConfig>) => {
            row.forEach((control: FieldConfig) => {
                const invalidDetail = new FieldValidationError();
                if (control.label) {
                    // collect the label usage across the template
                    let idArray = [];
                    if (labelNameMap.has(control.label)) {
                        idArray = labelNameMap.get(control.label);
                    } else {
                        labelNameMap.set(control.label, idArray);
                    }
                    if (idArray.indexOf(control.id) < 0) {
                        idArray.push(control.id);
                    }
                }

                if (!control.label) {
                    // required validation
                    invalidDetail.isRequiredInvalid = true;
                    result.set(control.id, invalidDetail);
                } else if (usedNames.findIndex(item => item.label === control.label && item.type === TaskDefinitionTemplateService.controlTypeDataTypeMap.get(control.type)) > -1) {
                    // label conflict validation
                    invalidDetail.isLableDuplicatedInvalid = true;
                    result.set(control.id, invalidDetail);
                } else {
                    if (control.type === ControlType.SINGLE_TEXT_DROP_DOWN) {
                        if (!control.source || control.source.length === 0) {
                            invalidDetail.isRequiredInvalid = true;
                            result.set(control.id, invalidDetail);
                        }
                    } else if (control.type === ControlType.SINGLE_ENTITY_DROP_DOWN ||
                        control.type === ControlType.MULTI_ENTITY_DROP_DOWN ||
                        control.type === ControlType.SINGLE_USER_DROP_DOWN) {
                        if (!control.source || control.source.length === 0) {
                            invalidDetail.isRequiredInvalid = true;
                            result.set(control.id, invalidDetail);
                        }
                    }
                }

                if (!result.has(control.id)) {
                    validCtrlIds.push(control.id);
                }

                usedNames.push({ label: control.label, type: TaskDefinitionTemplateService.controlTypeDataTypeMap.get(control.type) });
            });
        });
        return result;
    }

    constructor(
        private _slideSheetService: SlideSheetService,
        private _storeQuerier: StoreQuerierService,
        private _store: Store<AppState>,
        private _entityService: EntityService,
    ) {
        this.fieldExtraConfig.set(ControlType.FILE, { disabled: true, editable: true, showValidateInfo: false, component: FileFieldComponent, configComponent: FileConfigComponent, field: { fieldDefinition: { configuration: {} } }, fixWidth: 100 });
        this.fieldExtraConfig.set(ControlType.NUMBER, { disabled: true, editable: true, showValidateInfo: false, component: NumberFieldComponent, configComponent: NumberConfigComponent, field: { fieldDefinition: { configuration: {} } } });
        this.fieldExtraConfig.set(ControlType.SINGLE_ENTITY_DROP_DOWN, { disabled: true, editable: true, showValidateInfo: false, component: SingleEntityDropdownFieldComponent, configComponent: SingleEntityDropdownConfigComponent, field: { fieldDefinition: { configuration: {} } } });
        this.fieldExtraConfig.set(ControlType.SINGLE_TEXT_DROP_DOWN, { disabled: true, editable: true, showValidateInfo: false, component: SingleTextDropdownFieldComponent, configComponent: SingleTextDropdownConfigComponent, field: { fieldDefinition: { configuration: {} } } });
        this.fieldExtraConfig.set(ControlType.TEXT, { disabled: true, editable: true, showValidateInfo: false, component: TextFieldComponent, configComponent: TextConfigComponent, field: { fieldDefinition: { configuration: {} } } });
        this.fieldExtraConfig.set(ControlType.TEXT_AREA, { disabled: true, editable: true, showValidateInfo: false, component: TextAreaFieldComponent, configComponent: TextAreaConfigComponent, field: { fieldDefinition: { configuration: {} } }, fixWidth: 100 });
        this.fieldExtraConfig.set(ControlType.DATE, { disabled: true, editable: true, showValidateInfo: false, component: DateFieldComponent, configComponent: DateConfigComponent, field: { fieldDefinition: { configuration: {} } } });
        this.fieldExtraConfig.set(ControlType.RICH_EDITOR, { disabled: true, editable: true, showValidateInfo: false, component: RichEditorFieldComponent, configComponent: RichEditorConfigComponent, field: { fieldDefinition: { configuration: {} } }, fixWidth: 100 });
        this.fieldExtraConfig.set(ControlType.MULTI_ENTITY_DROP_DOWN, { disabled: true, editable: true, showValidateInfo: false, component: EntityFieldComponent, configComponent: EntityDropdownConfigComponent, field: { fieldDefinition: { configuration: {} } } });
        this.fieldExtraConfig.set(ControlType.CHECK_LIST, { disabled: true, editable: true, showValidateInfo: false, component: CheckListFieldComponent, configComponent: CheckListConfigComponent, field: { fieldDefinition: { configuration: {} } } });
        this.fieldExtraConfig.set(ControlType.SINGLE_USER_DROP_DOWN, { disabled: true, editable: true, showValidateInfo: false, component: SingleUserDropdownFieldComponent, configComponent: SingleUserDropdownConfigComponent, field: { fieldDefinition: { configuration: {} } } });
    }


    addControl(control: ControlBrief, template: FieldConfig): FieldConfig {
        const newId: string = IdHelperService.createGUID(1);
        const f = new Field();
        const fDef = new FieldDefinition();
        f.fieldDefinition = fDef;
        const newControl: FieldConfig = { id: newId, label: null, width: 100, type: control.type, required: false, fieldDefinitionId: null, showValidateInfo: false, component: null, configComponent: null, field: f, editable: true, disabled: true, maxWidth: 100 };
        if (control.type === ControlType.TEXT) {
            newControl.component = TextFieldComponent;
            newControl.configComponent = TextConfigComponent;
            newControl.minlength = 0;
            newControl.maxlength = 128;
        } else if (control.type === ControlType.NUMBER) {
            newControl.component = NumberFieldComponent;
            newControl.configComponent = NumberConfigComponent;
            newControl.separator = true;
        } else if (control.type === ControlType.TEXT_AREA) {
            newControl.component = TextAreaFieldComponent;
            newControl.configComponent = TextAreaConfigComponent;
            newControl.separator = true;
            // default rows as 2 per requirement.
            newControl.maxlines = 2;
            // the textarea is fixed 100% width.
            newControl.fixWidth = 100;
        } else if (control.type === ControlType.SINGLE_TEXT_DROP_DOWN) {
            newControl.component = SingleTextDropdownFieldComponent;
            newControl.configComponent = SingleTextDropdownConfigComponent;
            newControl.source = [];
        } else if (control.type === ControlType.SINGLE_ENTITY_DROP_DOWN) {
            newControl.component = SingleEntityDropdownFieldComponent;
            newControl.configComponent = SingleEntityDropdownConfigComponent;
            newControl.source = [];
        } else if (control.type === ControlType.FILE) {
            newControl.component = FileFieldComponent;
            newControl.configComponent = FileConfigComponent;
            newControl.fixWidth = 100;
        } else if (control.type === ControlType.DATE) {
            newControl.component = DateFieldComponent;
            newControl.configComponent = DateConfigComponent;
            newControl.staticDefaultValue = false;
        } else if (control.type === ControlType.RICH_EDITOR) {
            newControl.component = RichEditorFieldComponent;
            newControl.configComponent = RichEditorConfigComponent;
            newControl.fixWidth = 100;
        } else if (control.type === ControlType.MULTI_ENTITY_DROP_DOWN) {
            newControl.component = EntityFieldComponent;
            newControl.configComponent = EntityDropdownConfigComponent;
            newControl.source = [];
        } else if (control.type === ControlType.CHECK_LIST) {
            newControl.component = CheckListFieldComponent;
            newControl.configComponent = CheckListConfigComponent;
            newControl.fixWidth = 100;
            newControl.required = true;
            newControl.isRequiredDisabled = true;
        } else if (control.type === ControlType.SINGLE_USER_DROP_DOWN) {
            newControl.component = SingleUserDropdownFieldComponent;
            newControl.configComponent = SingleUserDropdownConfigComponent;
            newControl.source = [];
        }
        template.contains.push([
            newControl,
        ]);
        return newControl;
    }

    /**
     * executes the processes that needs to be done with the template prior to saving it.
     *
     * @memberof TaskDefinitionTemplateService
     */
    preprocessTemplate(template: Template) {
        if (template.contains && template.contains.length > 0) {
            template.contains.forEach(fieldConfig => {
                if (fieldConfig.length > 0) {
                    fieldConfig.forEach(fConfig => {
                        // For single entity fields whose switch of trigger entity as default value is on, we need to remove the default value config from them.
                        if (fConfig.isTriggerEntityAsDefaultEnabled &&
                            fConfig.staticDefaultValue &&
                            (fConfig.type === ControlType.SINGLE_ENTITY_DROP_DOWN || fConfig.type === ControlType.SINGLE_USER_DROP_DOWN)) {
                            delete fConfig.staticDefaultValue;
                        }
                    });
                }
            });
        }
    }

    cancel(configChanged: boolean): void {
        if (configChanged) {
            // Alert? Waiting for requirements
        }
        this._slideSheetService.slideSheetActionSubject$.next({ type: SlideSheetActionTypes.CLOSE });
        this._store.dispatch({
            type: WorkflowActionTypes.CLEAR_USED_FIELD,
        });
    }

    deleteControl(control: FieldConfig, template: any): any {
        this._store.dispatch({
            type: WorkflowActionTypes.UNUSE_ONE_FIELD,
            payload: {
                fieldId: control.fieldDefinitionId,
            },
        });
        this._store.dispatch({
            type: WorkflowActionTypes.DELETE_CURRENT_WORKFLOW_ONE_FIELD,
            payload: {
                fieldDefinitionId: control.fieldDefinitionId
            }
        });
    }

    formatTemplate(template: Template): any {
        template.contains.forEach(row => {
            row.forEach((control: FieldConfig) => {
                delete control.disabled;
                delete control.field;
                delete control.editable;
                delete control.showValidateInfo;
                delete control.component;
                delete control.config;
                delete control.configComponent;
                delete control.maxWidth;
                if ((control.type === ControlType.SINGLE_ENTITY_DROP_DOWN || control.type === ControlType.SINGLE_USER_DROP_DOWN) && control.staticDefaultValue) {
                    control.staticDefaultValue = control.staticDefaultValue.id;
                }
            });
        });
        return template;
    }

    formatFields(): Array<string> {
        const usedFields: Array<FieldDefinition> = Array.from(this._storeQuerier.queryBySelector(workflowUsedFieldsSelector).values());
        // add the fields to all fields store
        this._store.dispatch({
            type: WorkflowActionTypes.ADD_ONE_FIELD,
            payload: usedFields,
        });
        const fieldIds: Array<string> = [...usedFields.filter(item => item && item.id).map(item => item.id)];
        return fieldIds;
    }

    getInvalidControlId(template: FieldConfig): Array<string> {
        const result: Array<string> = [];
        const usedNames = [];
        template.contains.forEach((row: Array<FieldConfig>) => {
            row.forEach((control: FieldConfig) => {
                if (!control.label) {
                    result.push(control.id);
                } else if (usedNames.findIndex(item => item.label === control.label && item.type === TaskDefinitionTemplateService.controlTypeDataTypeMap.get(control.type)) > -1) {
                    result.push(control.id);
                } else {
                    if (control.type === ControlType.SINGLE_TEXT_DROP_DOWN) {
                        if (!control.source || control.source.length === 0) {
                            result.push(control.id);
                        }
                    } else if (control.type === ControlType.SINGLE_ENTITY_DROP_DOWN ||
                        control.type === ControlType.SINGLE_USER_DROP_DOWN) {
                        if (!control.source || control.source.length === 0) {
                            result.push(control.id);
                        }
                    }
                }
                usedNames.push({ label: control.label, type: TaskDefinitionTemplateService.controlTypeDataTypeMap.get(control.type) });
            });
        });
        return result;
    }

    initializeTemplate(template: FieldConfig): Observable<FieldConfig> {
        const allFields: Array<FieldDefinition> = this._storeQuerier.queryBySelector(workflowFieldsSelector);
        const fields: Map<string, FieldDefinition> = new Map();
        template = lodash.cloneDeep(template);
        const entityId: Set<string> = new Set();
        // singleEntity control use id for staticDefaultValue ; multiEntity control use Entity for staticDefaultValue.
        // no need send request for get entity for multi entity control
        template.contains.forEach((row, i, temp) => {
            row.forEach((item, j, r) => {
                if (item.type === ControlType.SINGLE_ENTITY_DROP_DOWN || item.type === ControlType.SINGLE_USER_DROP_DOWN) {
                    if (item.staticDefaultValue && (typeof item.staticDefaultValue) === 'string') {
                        entityId.add(item.staticDefaultValue);
                    }
                }
                // TODO will delete 'if' when all controls done.
                if (this.fieldExtraConfig.has(item.type)) {
                    let fieldDefinition: FieldDefinition = allFields.find(def => def.id === item.fieldDefinitionId);
                    if (!fieldDefinition) {
                        fieldDefinition = FieldDefinition.parseControl(item);
                    }

                    if (fieldDefinition) {
                        fields.set(fieldDefinition.id, fieldDefinition);
                    }
                    Object.assign(item, this.fieldExtraConfig.get(item.type));
                }
            });
            let emptyWidth = 100;
            row.forEach(item => emptyWidth = emptyWidth - item.width);
            row.forEach(item => item.maxWidth = item.width + emptyWidth);
        });

        this._store.dispatch({
            type: WorkflowActionTypes.SET_USED_FIELD,
            payload: fields
        });

        if (entityId.size > 0) {
            return this._entityService.getEntityListByIdsQuick(Array.from(entityId)).pipe(
                map(response => this._entityService.mapEntityBriefList(response)),
                switchMap((entityRes: Array<EntityBrief>) => {
                    template.contains.forEach(row => {
                        row.forEach(control => {
                            if (control.type === ControlType.SINGLE_ENTITY_DROP_DOWN || control.type === ControlType.SINGLE_USER_DROP_DOWN) {
                                if (control.staticDefaultValue) {
                                    control.staticDefaultValue = entityRes.find(item => item.id === control.staticDefaultValue);
                                }
                            }
                        });
                    });
                    return of(template);
                }),
            );
        } else {
            return of(template);
        }
    }
}
