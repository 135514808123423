<div class="filter-item-container" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
    <div class="title-action-container body-2" tid="title-action-container">
        <div class="filter-title">
            <div>{{this.config.title}}</div>
        </div>
        <div class="action" [style.visibility]="isItemHoverOn?'visible':'hidden'">
            <smart-icon name="trash" (click)="onBtnCloseClicked()"></smart-icon>
        </div>
    </div>
    <div class="operator-content-container" tid="operator-content-container">
        <div class="operator" [ngClass]="{'width-180': operator.value === 'range'}">
            <kendo-dropdownlist [(ngModel)]="operator" [data]="operators" [textField]="'name'" [valueField]="'value'"
                style="width: 100%;" (valueChange)="onValueChangeOperate($event)">
            </kendo-dropdownlist>
        </div>
        <div class="content">
            <multi-sel-dropdown-filter [dropdownConfig]='relationshipTypeConfig'>
                <ng-template #multiCustomContent>
                    <div class="selected-relationship-type text-ellipsis" (click)='onClickRelationshipType($event)'>
                        {{relationshipTypeCheckedNames}}
                    </div>
                </ng-template>
            </multi-sel-dropdown-filter>
        </div>
    </div>
    <div class="relationship-entity">
        <kendo-dropdownlist #entityList [(ngModel)]="entityItem" [data]="entityItems" [textField]="'name'"
            [valueField]="'id'" style="width: 100%;" (valueChange)="onEntityListValueChange()" [filterable]="true"
            (filterChange)="onEntityListFilterChange($event)" (focus)="entityList.toggle(true)"
            (mousedown)="$event.preventDefault()">
        </kendo-dropdownlist>
    </div>
    <div class="include-current-employment">
        <label class="checkbox" tam-checkbox [(ngModel)]="isIncludeCurrentEmployment"
            (click)="onCheckBoxClick()"></label>
        <label class="text">{{'general.include_employment' | stringLiterals}}</label>
    </div>
</div>