/**
 * Created by Yoyo Fang on 08/13/19.
 * Description:
 *  implement interface of dashboard tiles for tam dashboard column chart tile
 * ------ maintenance history ------
 * 09/07/2019   Yoyo Fang   remove config property from action when item click and the action sent
 */
import { DashboardTileAction, TamDashboardChartTileBaseConfiguration } from '../tam-dashboard-tile.base.config';
import { Subject, Subscription } from 'rxjs';
import { TamDashboardActions, TamDashboardEvents, TamDashboardTileType } from '../tam-dashboard.model';
import { DashboardService } from '../../../pages/dashboard/dashboard.service';
import { filter } from 'rxjs/operators';
import { TamDashboardPopUpService } from '../tam-dashboard-popup.service';
import { DashboardActionTypes } from '../../../redux/actions/dashboard.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../../redux';
import { DashboardTileService } from '../dashboard-tile.service';

export class TamDashboardTileData {
    constructor(public category: string, public value: any, public valueText: string, public groupName?, public category_value?) { }
}

export class TamDashboardColumnChartTileConfiguration implements TamDashboardChartTileBaseConfiguration {

    public data: TamDashboardTileData[];
    public id: string;
    public initialized: boolean;
    public name: string;
    public readonly: boolean;
    public seriesClickEventType: string;
    public domLayout: string;
    public subscriptions: Subscription[] = [];

    public actionSubject$: Subject<any>;
    public feedbackSubject$: Subject<any>;

    private _focusedEntityIds;
    private _labelSettings: any;
    private _tileConfig: any;

    constructor(
        private _dashboardTileService: DashboardTileService | null,
        private _dashboardService: DashboardService | null,
        private _store: Store<AppState> | null,
        private _dashboardPopUpService?: TamDashboardPopUpService,
        public vertical?: boolean,
    ) {
        this.initialized = false;
        this.vertical = !!this.vertical;
    }

    destroy() {
        this.subscriptions.forEach((subscription: Subscription) => {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe();
            }
        });
    }

    init(id: string, readonly: boolean, name: string, actionSubject$: Subject<any>,
        feedbackSubject$: Subject<any>, domLayout?: string): boolean {
        if (this.initialized === false) {
            this.id = id;
            this.readonly = readonly;
            this.name = name;
            this.actionSubject$ = actionSubject$;
            this.feedbackSubject$ = feedbackSubject$;
            this.domLayout = domLayout || 'normal';
            const actionSubscription = this.actionSubject$.pipe(
                filter((action: any) => action && action.id === this.id)
            ).subscribe((action) => this._actionHandler(action));
            this.subscriptions.push(actionSubscription);
            this.initialized = true;
            return true;
        } else {
            return false;
        }
    }

    categoryLabelContent = (e: any) => {
        if (!this.vertical) {
            return e.value;
        }
        if (e.value.length >= 32) {
            return e.value.slice(0, 32) + '...';
        }
        return e.value;
    };

    labelContent = (e: any) => {
        if (this._labelSettings) {
            const value = e.value;
            return this._dashboardService.cellRenderer(this._labelSettings, { value });
        }
        return e.value;
    };

    onSeriesClick = (event: any) => {
        if (!this.readonly) {
            return;
        }
        const categoryAxis = this._tileConfig.visualization.settings.categoryAxis;
        const category = this._dashboardTileService.formatSeriesClickCategoryValue(this._tileConfig.columns, categoryAxis, event.category);
        this.feedbackSubject$.next({
            type: this.seriesClickEventType || TamDashboardEvents.OPEN_DRILL_DOWN,
            payload: {
                id: this.id,
                value: event.value,
                category: category,
                type: this.vertical ? TamDashboardTileType.BAR_CHART.toString() : TamDashboardTileType.COLUMN_CHART.toString(),
                name: this.name,
                readonly: this.readonly,
                tileConfig: this._tileConfig,
                focusedEntityIds: this._focusedEntityIds,
                actionSubject$: this.actionSubject$,
                feedbackSubject$: this.feedbackSubject$,
                data: this.data
            }
        });

    };

    calculateArea = (areaWidth, areaHeight) => {
        let tempAreaWidth = areaWidth;
        let tempAreaHeight = areaHeight;
        if (this.data && this.data.length) {
            const len = this.data.length;
            if (this.vertical && areaHeight < len * 24) {
                tempAreaHeight = len * 24;
            }
            if (!this.vertical && areaWidth < len * 24) {
                tempAreaWidth = len * 24;
            }
        }
        return {
            areaWidth: tempAreaWidth,
            areaHeight: tempAreaHeight
        };
    };

    onMouseEnter = (e: any) => {
        if (!e.element.parent) {
            return;
        }
        this._dashboardPopUpService.updateParams({
            popupAnchor: e.originalEvent.target,
            popupContent: e.element.parent.tooltip,
            showAxisToolTip: true
        });
    };

    onMouseLeave = (e: any) => {
        if (!e.element.parent) {
            return;
        }
        this._dashboardPopUpService.updateParams({
            popupAnchor: null,
            popupContent: null,
            showAxisToolTip: false
        });
    };

    setTileData(data) {
        this.actionSubject$.next({ id: this.id, type: TamDashboardActions.SET_TILE_DATA, payload: data });
    }

    private _actionHandler(action: DashboardTileAction) {
        switch (action.type) {
            case TamDashboardActions.LOAD_DATA: {
                if (action.payload && action.payload['tileConfig']) {
                    this._loadData(action.payload);
                }
                break;
            }
            case TamDashboardActions.SET_CHART_LABEL_SETTING: {
                this._labelSettings = action.payload;
                break;
            }
            case TamDashboardActions.SET_TILE_CONFIG: {
                this._tileConfig = action.payload;
                break;
            }
            case TamDashboardActions.SET_FOCUSED_ENTITY_IDS: {
                this._focusedEntityIds = action.payload;
                break;
            }
        }
    }

    private _loadData({ tileConfig, focusedEntityIds, useConfig, saveStore }) {
        this._dashboardTileService.columnChartLoadData(tileConfig, focusedEntityIds, useConfig).subscribe((data) => {
            this.data = data;
            if (saveStore && data && this._store) {
                this._store.dispatch({
                    type: DashboardActionTypes.SAVE_TILE_DATA,
                    payload: {
                        id: this.id,
                        data: data,
                    }
                });
            }
            this.setTileData(data);
        });
    }
}

