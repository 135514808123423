/**
 * Created by Daniel Wang on 8/27/2018. This component is used to show attachments
 * ------ maintenance history ------
 * Updated by Bowen Li on 5/13/2019. PDF file preview
 * Updated by Simon Zhao on 2/2/2023 add the preview-in-other-new-tab menu item to the context menu.
 */

import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FileHelperService } from '../../services/file-helper.service';
import { ContextMenuConfig, ContextMenuShowAction } from '../../widgets/tam-context-menu/tam-context-menu.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AttachmentView } from './attachment-view.model';
import { AlertWindowService } from '../../widgets/alert-window/alert-window.service';
import { AlertButton, AlertBtnTypes } from '../../widgets/alert-window/alert-window.model';
import { Subscription } from 'rxjs';
import { MAX_PREVIEW_FILE_SIZE, PDFPreviewExtension } from './../../../../../tamalelibs/src/app/constants/business.constants';
import { StringLiteralsPipe } from '../../pipes/translate.pipe';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'attachment-list',
    templateUrl: './attachment-list.component.html',
    styleUrls: ['./attachment-list.component.scss'],
})
export class AttachmentListComponent implements OnInit, OnDestroy {
    @Input() attachments: Array<AttachmentView>;
    @Input() canShare: boolean;
    @Input() isPrintMode: boolean;
    @Output() deleteAttachment: EventEmitter<any> = new EventEmitter();

    public contextMenuConfig: ContextMenuConfig;
    public expand = false;
    public expandable = false;
    private _destroySubscriptions: Array<Subscription> = [];
    // to remove the suffix of the filename, use regex exec.(according to "tam-upload-components")
    private _fileExtensionRegex = /(?:\.([^.]+))?$/;
    private _isDownload = true;
    private _isIPAD = false;

    constructor(
        private _alertWindow: AlertWindowService,
        private _deviceService: DeviceDetectorService,
        private _fileHelperService: FileHelperService,
        private _changeDetectorRef: ChangeDetectorRef,
    ) {
        this._isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
    }

    ngOnInit() {
        this.initContextMenu();
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    copyLink(attach: AttachmentView) {
        if (attach) {
            const fileId = attach.id;
            this._fileHelperService.copyFileLink(fileId);
        }
    }

    downloadFile(attach: AttachmentView) {
        if (attach) {
            this._fileHelperService.downloadFile$.next();
            const fileUrl = attach.fileDataLink + attach.id;
            this._fileHelperService.downloadFile(fileUrl);
        }
    }

    getIconName(fileExt: string) {
        let iconName = 'unknown';
        if (fileExt) {
            iconName = this._fileHelperService.getIconName(fileExt.toLowerCase());
        }
        return iconName;
    }

    initContextMenu() {
        this.contextMenuConfig = new ContextMenuConfig();
        this.contextMenuConfig.stateful = false;
        this.contextMenuConfig.items = [
            { id: 0, text: StringLiteralsPipe.translate('general.preview'), disabled: false },
            { id: 1, text: StringLiteralsPipe.translate('general.preview_in_other_tab'), disabled: false },
            { id: 3, text: StringLiteralsPipe.translate('general.download'), disabled: false },
        ];
        if (!this._isIPAD && this.canShare) { // not show copylink menus on ipad
            this.contextMenuConfig.items.splice(2, 0, {
                id: 2,
                text: StringLiteralsPipe.translate('general.copy_link'),
                disabled: false
            });
        }
        if (this.canShare) {
            this.contextMenuConfig.items.push({ id: 4, text: StringLiteralsPipe.translate('general.delete'), disabled: false });
        }
        this._destroySubscriptions.push(
            this.contextMenuConfig.events$.subscribe(event => this.onFileMenuSelect(event)),
        );
    }

    onFileAction(attach: AttachmentView) {
        if (attach.extension && PDFPreviewExtension.includes(attach.extension.toLowerCase())) {
            this.preview(attach);
        } else {
            this.downloadFile(attach);
        }
    }

    onFileMenuSelect(res) {
        const type = +res.payload.id;
        const attachInfo = res.payload.data;
        switch (type) {
            case 0:
                this.preview(attachInfo);
                break;
            case 1:
                this.preview(attachInfo, true);
                break;
            case 2:
                this.copyLink(attachInfo);
                break;
            case 3:
                this.downloadFile(attachInfo);
                break;
            case 4:
                this._deleteFile(attachInfo);
                break;
            default:
                break;
        }
    }

    onShowContextMenu(e: MouseEvent, attach: AttachmentView) {
        let filePreviewDisable = true;
        if (attach.extension && PDFPreviewExtension.includes(attach.extension.toLowerCase())) {
            filePreviewDisable = false;
        }

        // Disable preview items based on the file type
        this.contextMenuConfig.items[0].disabled = filePreviewDisable;
        this.contextMenuConfig.items[1].disabled = filePreviewDisable;

        // Additional conditions for download and delete options
        if (this.canShare) {
            let downloadIndex = 3;
            let deleteIndex = 4;
            if (this._isIPAD) {
                downloadIndex--;
                deleteIndex--;
            }
            this.contextMenuConfig.items[downloadIndex].disabled = !this._isDownload;
            this.contextMenuConfig.items[deleteIndex].disabled = !attach.deletable;
        } else {
            this.contextMenuConfig.items[2].disabled = !this._isDownload;
        }

        // Trigger the display of the context menu
        this._changeDetectorRef.detectChanges(); // Ensure view is updated
        this.contextMenuConfig.show$.next(new ContextMenuShowAction(e, attach));
        e.preventDefault();
        e.stopPropagation();
    }


    onToggle() {
        this.expand = !this.expand;
    }

    // preview the pdf file according to service
    preview(attach: AttachmentView, isInNewTab = false) {
        if (attach) {
            const fileExtension = attach.extension;
            // Due to use new file api for preview .but delete still use old entry file api , use fileId handle file api(new)
            const regexResult = this._fileExtensionRegex.exec(attach.fileName);
            // use entryid or file id
            const fileId = attach.isUsedFile ? attach.id : attach.fileId;
            const fileLink = attach.fileDataLink;
            const fileName = attach.fileName.substring(0, regexResult.index);
            const fileSize = attach.fileSize;
            if (PDFPreviewExtension.includes(fileExtension.toLowerCase())) {
                if (fileSize > MAX_PREVIEW_FILE_SIZE) {
                    this._unablePreviewAlert(attach);
                } else {
                    if (isInNewTab) {
                        this._fileHelperService.previewFileInNewTab(fileId, fileLink, fileExtension, fileName, attach.isUsedFile, false);
                    } else {
                        this._fileHelperService.previewFile(fileId, fileLink, fileExtension, fileName, false, attach.isUsedFile);
                    }
                }
            }
        }
    }

    trackByFn(index, item) {
        return item.id;
    }

    private _deleteFile(attachInfo: AttachmentView) {
        const cancelBtn: AlertButton = {
            text: 'Cancel',
            type: AlertBtnTypes.tertiary
        };
        const confirmButton: AlertButton = {
            text: 'Delete',
            type: AlertBtnTypes.destructive
        };
        this._alertWindow.openForSideSheet$.next('');
        const subscription = this._alertWindow.
            custom('Delete confirmation required', ['Are you sure you want to delete this attachment?'], cancelBtn, confirmButton)
            .subscribe((result: boolean) => {
                if (result) {
                    const index = this.attachments.indexOf(attachInfo);
                    this.attachments.splice(index, 1);
                    this.deleteAttachment.emit(attachInfo);
                }
                subscription.unsubscribe();
            });
    }

    private _unablePreviewAlert(attach: AttachmentView) {
        const downloadBtn: AlertButton = {
            text: 'Download',
            type: AlertBtnTypes.primary
        };
        const cancelBtn: AlertButton = {
            text: 'OK',
            type: AlertBtnTypes.tertiary
        };
        const subscription = this._alertWindow.custom('Warning', ['You can\'t preview this file because the size exceeds limitation(15M).'], cancelBtn, downloadBtn)
            .subscribe((result: boolean) => {
                if (result) {
                    this.downloadFile(attach);
                }
                subscription.unsubscribe();
            });
    }
}
