import { DualListItem } from './dual-list.component';
import { Subject } from 'rxjs';

export enum DualListEventType {
    selectChange,
}

export class DualListConfig {
    hideTitle = false;
    titles: Array<string> = ['', ''];
    placeholderText = '';
    dataSource: Array<DualListItem>;
    dataTextField: string;
    dataValueField: string;
    disabled = false;
    required = false; // True for right side list require at list one item.
    feedbackSubject$: Subject<{ type: DualListEventType, payload?: any }>;

    constructor() {
        this.feedbackSubject$ = new Subject();
    }
}
