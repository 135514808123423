/**
 * Created by Abner Sui on 1/5/2021
 * -------------------------------------
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ControlType } from '../../../tamalelibs/models/template-control.model';
import { FieldType } from '../../../tamalelibs/models/workflow.model';
import { IControlConfigComponent } from '../../template-configuration/template-configuration.model';
import { FieldConfig, FieldValidationError } from './field.model';

@Component({
    selector: 'tam-file-config',
    templateUrl: './file-config.component.html',
    styleUrls: ['./field-config.component.scss']
})
export class FileConfigComponent implements OnInit, AfterViewInit, OnDestroy, IControlConfigComponent {

    @Input()
    config: FieldConfig;

    @ViewChild('base', { static: false }) base;

    dataType = FieldType.FILE;

    private _controlType = ControlType.FILE;
    private _destroySubscriptions: Array<Subscription> = [];

    constructor() { }

    getInvalidDetail(): FieldValidationError {
        return this.base.getInvalidDetail();
    }

    ngOnInit(): void {
        // this._destroySubscriptions.push();
    }

    ngAfterViewInit(): void {
        if (this.config.showValidateInfo) {
            this.validate();
        }
    }

    ngOnDestroy(): void {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    validate(): boolean {
        const baseValid: boolean = this.base.validate();
        // run local validate
        const localValid = true;
        return baseValid && localValid;
    }

}
