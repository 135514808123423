/**
 * Created by Abner Sui on 07/31/2020
 * -------------------------------------
 * 10/12/2022 Simon Zhao exposed the logic of determining the value's validity via a static function.
 */

import { Component, OnInit, Input, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FieldConfig, FieldEvents, FieldActions } from './field.model';
import { TooltipDirective } from '@progress/kendo-angular-tooltip';
import { UtilsService } from '../../../tamalelibs/services/utils.service';
import { Subscription, fromEvent } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'tam-single-text-dropdown-field',
    templateUrl: './single-text-dropdown-field.component.html',
    styleUrls: ['./field.component.scss']
})
export class SingleTextDropdownFieldComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input()
    config: FieldConfig;

    @ViewChild('kendo', { static: false }) kendo;
    @ViewChild('anchor', { static: false }) anchor;
    @ViewChild(TooltipDirective, { static: false })
    tooltipDir: TooltipDirective;

    data: Array<string>;
    /**
     * a flag indicating whether current field control is disabled.
     */
    get isDisabled() {
        return FieldConfig.isFieldDisabled(this.config);
    }
    isIPAD = false;
    requireInvalid = false;

    private _destroySubscriptions: Array<Subscription> = [];

    constructor(
        private _utils: UtilsService,
        private _deviceService: DeviceDetectorService,
    ) { }

    ngOnInit() {
        if (this.config.disabled) {
            return;
        }
        this.data = this.config.source.slice();
        this.isIPAD = this._deviceService.isMobile() || this._deviceService.isTablet();
        this._destroySubscriptions.push(
            this.config.config.actionSubject$.subscribe(action => this._onAction(action)),
        );
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
    }

    ngAfterViewInit() {
        if (this.isIPAD && this.config.field.fieldDefinition.description) {
            this._destroySubscriptions.push(
                fromEvent(document, 'click').subscribe((event) => {
                    this.showTooltip(event, this.anchor, false);
                }),
            );
        }
    }

    ngOnDestroy() {
        this._destroySubscriptions.forEach(item => item.unsubscribe());
    }

    focus(): void {
        this.kendo.toggle(true);
    }

    handleFilter(event: string): void {
        this.data = this.config.source.filter(item => item.toLowerCase().indexOf(event.toLowerCase()) > -1);
    }

    showTooltip(event, target, show: boolean): void {
        if (show === null) {
            this.tooltipDir.toggle(target);
        } else {
            this.tooltipDir.toggle(target, show);
        }
        this._utils.emptyClick(event);
    }

    validateRequire(): boolean {
        if (this.config.editable && this.config.required && !this.config.field.value) {
            return true;
        } else {
            return false;
        }
    }

    valueChange(event): void {
        this.requireInvalid = false;
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALUE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                value: event,
            },
        });
        this.config.config.feedbackSubject$.next({
            type: FieldEvents.VALIDATE_CHANGE,
            payload: {
                id: this.config.field.fieldDefinition.id,
                invalid: this.validateRequire(),
            },
        });
    }

    private _onAction(action) {
        if (action.type === FieldActions.SHOW_VALIDATE_REQUIRE) {
            this.requireInvalid = this.validateRequire();
        }
    }

}
