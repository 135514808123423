import { DuplicatedContactDialogService } from './../components/duplicated-contact-dialog/duplicated-contact-dialog.service';
import { CookieService } from 'ngx-cookie-service';

import { HomeGuard } from '../pages/home/home.guard';
import { PrintGuard } from '../pages/print/print.guard';
import { DataImportGuard } from '../pages/settings/data-import.guard';
import { EmailDigestGuard } from '../pages/email-digest/email-digest.guard';
import { AdminPageGuard } from '../components/admin-pages/admin-page.guard';
import { WorkflowGuard } from '../pages/workflow/workflow.guard';

import { StorageService } from './storage.service';
import { StoreQuerierService } from './store-querier.service';
import { FormulajsExtensionService } from './formulajs-extension.service';
import { WindowRef } from './window-ref';
import { FormulaService } from './formula-service';
import { AuthHelperService } from './auth-helper.service';
import { FileHelperService } from './file-helper.service';
import { PlatformService } from './platform.service';
import { RelationshipTreeService } from './relationship-tree.service';
import { CopyService } from './copy.service';
import { NoteService } from './note.service';
import { NoteDialogService } from '../components/note-dialog/note-dialog.service';
import { ContactDialogService } from '../components/contact-dialog/contact-dialog.service';
import { SaveScreenDialogService } from '../components/save-screen-dialog/save-screen-dialog.service';
import { AuditTrailDialogService } from '../components/audit-log/audit-trail/audit-trail.service';
import { TamDropzoneService } from '../widgets/tam-dropzone/tam-dropzone.service';
import { TransportService } from '../tamalelibs/services/transport.service';
import { TileService } from '../tamalelibs/services/tile.service';
import { AuthService } from '../tamalelibs/services/auth.service';
import { EntryService } from '../tamalelibs/services/entry.service';
import { ThreadService } from '../tamalelibs/services/thread.service';
import { FilterService } from '../tamalelibs/services/filter.service';
import { ConfigurationDataService } from '../tamalelibs/services/configuration-data.service';
import { EntryTypeService } from '../tamalelibs/services/entry-type.service';
import { RelationshipService } from '../tamalelibs/services/relationship.service';
import { AdvFilterHelperService } from '../tamalelibs/services/adv-filter-helper.service';
import { RelationshipTypeService } from '../tamalelibs/services/relationshipType.service';
import { EntityViewModel } from '../tamalelibs/components/entity.view-model';
import { EntityService } from '../tamalelibs/services/entity.service';
import { EntityTypeService } from '../tamalelibs/services/entity-type.service';
import { ScreenService } from '../tamalelibs/services/screen.service';
import { TemplateService } from '../tamalelibs/services/template.service';
import { UsersService } from '../tamalelibs/services/users.service';
import { AuditTrailService } from '../tamalelibs/services/audit-trail.service';
import { BlastEmailService } from '../tamalelibs/services/blastemail.service';
import { FileViewService } from '../tamalelibs/services/file-view.service';
import { ResearchHelperService } from './research-helper.service';
import { ResearchScreenService } from './research-screen.service';
import { AuditFilterService } from '../tamalelibs/services/audit-filter.service';
import { TileFilterService } from '../tamalelibs/services/tile-filter.service';
import { EntityDialogService } from '../components/entity-dialog/entity-dialog.service';
import { ResearchRefinebyService } from './research-refineby.service';
import { HomeScreenService } from './home-screen.service';
import { FloatMenuService } from './float-menu.service';
import { AdhocService } from '../tamalelibs/services/adhoc.service';
import { BlastemailRefinebyService } from './blastemail-refineby.service';
import { RefinebyService } from './refineby.service';
import { MarketdataService } from '../tamalelibs/services/marketdata.service';
import { DataProviderService } from '../tamalelibs/services/dataprovider.service';
import { SimpleSearchService } from '../tamalelibs/services/simple-search.service';
import { QueryService } from './query.service';
import { LayoutService } from './layout.service';
import { ScreenHelperService } from './screen-helper.service';
import { ContactScreenService } from './contact-screen.service';
import { AlertWindowService } from '../widgets/alert-window/alert-window.service';
import { FormulaBuilderService } from '../widgets/formula-builder/formula-builder.service';
import { UtilsService } from '../tamalelibs/services/utils.service';
import { OpenDynamicComponentService } from '../pages/home/home-open-dynamic-component';
import { EntityViewModelForWeb } from '../components/entity-dialog/entity-view-web.model';
import { ThreadDetailService } from './thread-detail.service';
import { PrintService } from './print.service';
import { ES6MapService } from '../tamalelibs/services/es6map.service';
import { WorkflowService } from '../tamalelibs/services/workflow.service';
import { WorkflowTaskService } from '../tamalelibs/services/workflow-task.service';
import { SlideSheetService } from './slide-sheet.service';
import { MessageService } from './message.service';
import { CalendarService } from '../tamalelibs/services/calendar.service';
import { IExceptionHandler } from '../tamalelibs/services/exception-handler.interface.service';
import { IFileDownloader } from '../tamalelibs/services/file-downloader.interface.service';
import { INetworkErrorHandler } from '../tamalelibs/services/network-error-handler.interface.service';
import { ContactViewModel } from '../tamalelibs/components/contact.view-model';
import { ILoggerInstanceService } from '../tamalelibs/services/logger-instance.interface.service';
import { ContactService } from '../tamalelibs/services/contact.service';
import { FileTransferService } from '../tamalelibs/services/file-transfer.service';
import { EmailDigestService } from './email-digest.service';
import { AddRelationshipEntityDialogService } from '../components/relationship-tree/add-relationship-entity-dialog/add-relationship-entity-dialog.service';
import { SettingScreenService } from './setting-screen.service';
import { SelectWindowService } from '../widgets/select-window/select-window.service';
import { ContactTemplateService } from '../components/contact-dialog-new/contact-template.service';
import { Base64Service } from '../tamalelibs/services/base64.service';
import { SlideSheetTempService } from './slide-sheet-temp.service';
import { NewRelationshipService } from '../components/new-relationship-dialog/new-relationship-dialog.service';
import { BottomSlideSheetService } from './bottom-slide-sheet.service';
import { GlobalService } from '../tamalelibs/services/global.service';
import { ContactRelationshipService } from '../tamalelibs/services/contact.relationship.service';
import { AutoTaggingService } from '../tamalelibs/services/auto-tagging.service';
import { IntralinksAccountDialogService } from '../components/intralinks-account-dialog/intralinks-account-dialog.service';
import { IntralinksService } from '../tamalelibs/services/intralinks.service';
import { IntralinksValidationService } from '../components/intralinks-validation/intralinks-validation.service';
import { ImgPreviewService } from '../widgets/img-preview/img-preview.service';
import { IntralinksMappingDialogService } from '../components/intralinks-mapping-dialog/intralinks-mapping-dialog.service';
import { IntralinksSessionService } from './intralinks-session.service';
import { WorkflowConfigService } from '../tamalelibs/services/workflow-config.service';
import { TaskDefinitionTemplateService } from '../components/work-flow/field/task-definition-template.service';
import { AutoTagDialogService } from '../components/admin-pages/admin-page-content/autotag-model-dialog/autotag-model-dialog.service';
import { ThreadFilterService } from '../components/thread-filter/thread-filter.service';
import { ExchagnePanelService } from '../components/exchange/exchange-panel/exchange-panel.service';
import { MapTileService } from '../tamalelibs/services/maptile.service';
import { TamDashboardMapTileService } from '../components/tam-dashboard/tam-dashboard-map-tile/tam-dashboard-map-tile.service';
import { MapFilterService } from '../tamalelibs/services/map-filter.service';
import { MdlTemplateService } from '../tamalelibs/services/mdl-template.service';
import { MdlDialogService } from '../components/mdl-dialog/mdl-dialog.service';
import { MdlViewModelForWeb } from '../components/mdl-dialog/mdl-view-web.model';
import { LazyLoadingService } from './lazy.loading.service';
import { ContextMenuService } from '@perfectmemory/ngx-contextmenu';
import { ExchangeService } from '../tamalelibs/services/exchange.service';
import { AdminPagesService } from '../tamalelibs/services/admin-page.service';
import { UserPasswordService } from '../components/user-password-dialog/user-password.service';
import { FlyService } from '../tamalelibs/services/fly.service';
import { HomeViewModel } from '../pages/home/home-view.model';
import { OfficeAddinService } from './office-addin-service';
import { HomePluginService } from '../pages/home-plugin/home-plugin.service';
import { DialogService } from './dialog.service';
import { ContactListGuard } from '../pages/contact-list/contact-list.guard';
import { DashboardTileService } from '../components/tam-dashboard/dashboard-tile.service';
import { DashboardService } from '../pages/dashboard/dashboard.service';
import { DashboardGridNoteService } from '../pages/dashboard/dashboard-grid-note.service';
import { ConfirmSendDialogService } from '../components/email-digest/confirm-send-dialog/confirm-send-dialog.component.service';
import { NotificationService } from '../tamalelibs/services/notification.service';
import { PortfolioService } from '../tamalelibs/services/portfolio.service';
import { DiagramAutomationNoteDialogService } from '../widgets/diagram/diagram-automation-dialog/diagram-note-automation-dialog/diagram-note-automation-dialog.service';

export const globalServices_plugin = [
    AdvFilterHelperService,
    AuthHelperService,
    AuthService,
    Base64Service,
    CookieService,
    CopyService,
    ContactDialogService,
    ContactTemplateService,
    ContextMenuService,
    DialogService,
    DuplicatedContactDialogService,
    EntityService,
    EntityViewModel,
    EntityViewModelForWeb,
    EntityTypeService,
    EntryService,
    EntryTypeService,
    FileHelperService,
    FormulajsExtensionService,
    FormulaService,
    FloatMenuService,
    HomePluginService,
    NewRelationshipService,
    NoteDialogService,
    NoteService,
    PlatformService,
    RelationshipService,
    ContactRelationshipService,
    LazyLoadingService,
    OfficeAddinService,
    RelationshipTreeService,
    RelationshipTypeService,
    ScreenService,
    StorageService,
    StoreQuerierService,
    TemplateService,
    ThreadService,
    TransportService,
    UsersService,
    WindowRef,
    EntityDialogService,
    TamDropzoneService,
    QueryService,
    FormulaBuilderService,
    UtilsService,
    OpenDynamicComponentService,
    ES6MapService,
    WorkflowTaskService,
    WorkflowService,
    SlideSheetService,
    MessageService,
    CalendarService,
    ILoggerInstanceService,
    IExceptionHandler,
    ContactService,
    FileTransferService,
    ContactViewModel,
    ConfigurationDataService,
    INetworkErrorHandler,
    AddRelationshipEntityDialogService,
    IntralinksService,
    IntralinksAccountDialogService,
    IntralinksValidationService,
    GlobalService,
    MarketdataService,
    DashboardTileService,
    DashboardService,
    TileService,
    DashboardGridNoteService,
    DiagramAutomationNoteDialogService
];

export const globalServices = [
    ...globalServices_plugin,
    AdminPagesService,
    HomeGuard,
    PrintGuard,
    DataImportGuard,
    EmailDigestGuard,
    AdminPageGuard,
    WorkflowGuard,
    ContactListGuard,
    AutoTaggingService,
    AutoTagDialogService,
    EmailDigestService,
    FilterService,
    LayoutService,
    ResearchHelperService,
    ResearchScreenService,
    SaveScreenDialogService,
    SimpleSearchService,
    ThreadFilterService,
    AuditTrailService,
    ScreenHelperService,
    ContactScreenService,
    ThreadDetailService,
    AuditTrailDialogService,
    BlastEmailService,
    FileViewService,
    AuditFilterService,
    TileFilterService,
    ResearchRefinebyService,
    HomeScreenService,
    AdhocService,
    BlastemailRefinebyService,
    RefinebyService,
    DataProviderService,
    PrintService,
    BottomSlideSheetService,
    IFileDownloader,
    NotificationService,
    PortfolioService,
    SettingScreenService,
    SelectWindowService,
    SlideSheetTempService,
    NewRelationshipService,
    ImgPreviewService,
    IntralinksMappingDialogService,
    IntralinksSessionService,
    ExchangeService,
    ExchagnePanelService,
    WorkflowConfigService,
    TaskDefinitionTemplateService,
    OfficeAddinService,
    MapTileService,
    TamDashboardMapTileService,
    MapFilterService,
    MdlTemplateService,
    MdlDialogService,
    MdlViewModelForWeb,
    UserPasswordService,
    FlyService,
    HomeViewModel,
    ConfirmSendDialogService,
];

