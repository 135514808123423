import { Component, OnInit, Input } from '@angular/core';
import { EntityBrief } from '../../tamalelibs/models/entity-brief.model';
import { EntityType } from '../../tamalelibs/models/entity-type.model';

@Component({
    selector: 'tam-entity-dropdown-item',
    templateUrl: './entity-dropdown-item.component.html',
    styleUrls: ['./entity-dropdown-item.component.scss']
})
export class EntityDropdownItemComponent implements OnInit {

    @Input() entity: EntityBrief;

    get sideInfo() {
        if (this.entity.type && this.entity.type.id === EntityType.CONTACT.id) {
            if (this.entity.title && this.entity.company) {
                return `${this.entity.title} at ${this.entity.company}`;
            } else if (this.entity.company) {
                return this.entity.company;
            } else if (this.entity.title) {
                return this.entity.title;
            }
        } else {
            return this.entity.name;
        }

        return '';
    }

    constructor() { }

    ngOnInit() {
    }
}
