import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AppConfig } from '../../tamalelibs/models/app-config.model';
import { AuthHelperService } from '../../services/auth-helper.service';

@Injectable({
    providedIn: 'root'
})
export class HomeGuard implements CanActivate {
    constructor(
        private _authHelper: AuthHelperService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const token = this._authHelper.getToken();
        const ssoSetting = this._authHelper.getSsoSettings();
        const fromUrl = this._authHelper.getFromUrl();
        const userId = this._authHelper.getUserId();
        if (!token) {
            this._authHelper.redoAuthorization(ssoSetting, state.url, fromUrl);
            return false;
        } else {
            AppConfig.token = token;
            AppConfig.userId = userId;
            return true;
        }
    }
}
