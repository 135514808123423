/**
 * Create by
 * Description:
 *
 * ------ maintenance history ------
 * 2020/2/26  Bowen: add the current and former employment node: prepare current and former employment data
 */
import { Injectable } from '@angular/core';
import { RelationshipTreeNode } from '../components/relationship-tree/relationship-tree.view-model';
import { Contact } from '../tamalelibs/models/contact.model';
import { EntityType } from '../tamalelibs/models/entity-type.model';
import { Relationship } from '../tamalelibs/models/relationship.model';
import { RelationshipType } from '../tamalelibs/models/relationship-type.model';
import { ArrayHelperService } from '../tamalelibs/services/array-helper.service';
import { Subject, Observable } from 'rxjs';
import { FinderSetting } from '../components/finder/finder.view-model';
import { getDynamicResearchFocusSelector2 } from '../redux/reducers/research-screen.reducer';
import { Entity } from '../tamalelibs/models/entity.model';
import { ResearchScreenSetRelationshipNodes } from '../redux/actions/research-screen.actions';
import { StoreQuerierService } from './store-querier.service';
import { take, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { QueryInfo } from '../pages/research/research.view-model';
import { EntityService } from '../tamalelibs/services/entity.service';

@Injectable()
export class RelationshipTreeService {
    relationshipTreeOperate$: Subject<any> = new Subject();
    private _queryInfo: QueryInfo = new QueryInfo();

    constructor(
        private _storeQuerier: StoreQuerierService,
        private _store: Store<any>,
        private _entityService: EntityService,
    ) { }

    static parseToTreeViewModel(entity: Contact) {
        const treeNodes: RelationshipTreeNode[] = [];
        treeNodes.push({
            company: entity.companyWeb,
            // title: entity.jobTitle,
            id: entity.id,
            level: '1',
            isLeaf: false,
            isPublic: entity.isPublic,
            name: entity.name,
            nodeType: 'ENTITY',
            shortName: entity.shortName,
            type: entity.type,
            uuid: entity.id,
        });

        if (entity.relationships == null || entity.relationships.length === 0) {
            return treeNodes;
        }

        const entityNodes: Array<Relationship> = new Array<Relationship>();
        const contactNodes: Array<Relationship> = new Array<Relationship>();
        const teamNodes: Array<Relationship> = new Array<Relationship>();
        const teamMemberNodes: Array<Relationship> = new Array<Relationship>();
        const currentEmploymentNodes: Array<Relationship> = new Array<Relationship>();
        const formerEmploymentNodes: Array<Relationship> = new Array<Relationship>();

        entity.relationships.forEach(relationship => {
            if (relationship.nodesType === 'currentEmployment') {
                currentEmploymentNodes.push(relationship);
            } else if (relationship.nodesType === 'formerEmployment') {
                formerEmploymentNodes.push(relationship);
            } else {
                if (relationship.relationshipType.id === RelationshipType.IS_IN_TEAM.id) {
                    if (relationship.childEntity.type.id === EntityType.TEAM.id) {
                        teamNodes.push(relationship);
                    } else {
                        teamMemberNodes.push(relationship);
                    }
                } else {
                    if (relationship.childEntity.type.id === EntityType.CONTACT.id) {
                        contactNodes.push(relationship);
                    } else {
                        entityNodes.push(relationship);
                    }
                }
            }
        });

        // prepare data for node "Entities"
        if (entityNodes.length > 0) {
            entityNodes.sort(ArrayHelperService.treeNodesOrderBy('relationship'));
            treeNodes.push({
                uuid: 'ENTITY_GROUP',
                name: 'Entities',
                shortName: 'Entities',
                isLeaf: false,
                level: '2',
                nodeType: 'CUSTOMIZE',
                parentId: entity.id,
            });

            const relationshipNodeIdSet: Set<string> = new Set();
            entityNodes.forEach(node => {
                if (!relationshipNodeIdSet.has(node.relationshipType.id)) {
                    treeNodes.push({
                        uuid: `ENTITY_GROUP-${node.relationshipType.id}`,
                        name: node.relationshipType.name,
                        shortName: node.relationshipType.name,
                        isLeaf: false,
                        level: '3',
                        nodeType: 'RELATIONSHIP',
                        parentId: 'ENTITY_GROUP',
                        relationshipId: node.relationshipId
                    });
                    relationshipNodeIdSet.add(node.relationshipType.id);
                }
                treeNodes.push({
                    ...node.childEntity,
                    isLeaf: true,
                    level: '4',
                    nodeType: 'ENTITY',
                    parentId: `ENTITY_GROUP-${node.relationshipType.id}`,
                    uuid: `ENTITY_GROUP-${node.relationshipType.id}-${node.childEntity.id}`,
                    relationshipId: node.relationshipId,
                    relationshipTypeName: node.relationshipType.name
                });
            });
        }

        // prepare data for node "Contacts"
        if (contactNodes.length > 0) {
            contactNodes.sort(ArrayHelperService.treeNodesOrderBy('relationship'));
            treeNodes.push({
                uuid: 'CONTACTS_GROUP',
                name: 'Contacts',
                shortName: 'Contacts',
                isLeaf: false,
                level: '2',
                nodeType: 'CUSTOMIZE',
                parentId: entity.id,
            });

            const relationshipNodeIdSet: Set<string> = new Set();
            contactNodes.forEach(node => {
                if (!relationshipNodeIdSet.has(node.relationshipType.id)) {
                    treeNodes.push({
                        uuid: `CONTACTS_GROUP-${node.relationshipType.id}`,
                        name: node.relationshipType.name,
                        shortName: node.relationshipType.name,
                        isLeaf: false,
                        level: '3',
                        nodeType: 'RELATIONSHIP',
                        parentId: 'CONTACTS_GROUP',
                        relationshipId: node.relationshipId
                    });
                    relationshipNodeIdSet.add(node.relationshipType.id);
                }
                treeNodes.push({
                    ...node.childEntity,
                    uuid: `CONTACTS_GROUP-${node.relationshipType.id}-${node.childEntity.id}`,
                    isLeaf: true,
                    level: '4',
                    nodeType: 'ENTITY',
                    parentId: `CONTACTS_GROUP-${node.relationshipType.id}`,
                    relationshipId: node.relationshipId,
                    relationshipTypeName: node.relationshipType.name
                });
            });
        }

        // prepare data for node "Current Employment"
        if (currentEmploymentNodes.length > 0) {
            currentEmploymentNodes.sort(ArrayHelperService.treeNodesOrderBy('relationship'));
            treeNodes.push({
                uuid: 'CURRENT_EMPLOYMENT_GROUP',
                name: 'Current Employment',
                shortName: 'Current Employment',
                isLeaf: false,
                level: '2',
                nodeType: 'CUSTOMIZE',
                parentId: entity.id,
            });

            const relationshipNodeIdSet: Set<string> = new Set();
            currentEmploymentNodes.forEach(node => {
                if (!relationshipNodeIdSet.has(node.relationshipType.id)) {
                    treeNodes.push({
                        uuid: `CURRENT_EMPLOYMENT_GROUP-${node.relationshipType.id}`,
                        name: node.relationshipType.name,
                        shortName: node.relationshipType.name,
                        isLeaf: false,
                        level: '3',
                        nodeType: 'RELATIONSHIP',
                        parentId: 'CURRENT_EMPLOYMENT_GROUP',
                        relationshipId: node.relationshipId
                    });
                    relationshipNodeIdSet.add(node.relationshipType.id);
                }
                treeNodes.push({
                    ...node.childEntity,
                    isLeaf: true,
                    level: '4',
                    nodeType: 'ENTITY',
                    parentId: `CURRENT_EMPLOYMENT_GROUP-${node.relationshipType.id}`,
                    uuid: `CURRENT_EMPLOYMENT_GROUP-${node.relationshipType.id}-${node.childEntity.id}`,
                    relationshipId: node.relationshipId,
                    relationshipTypeName: node.relationshipType.name
                });
            });
        }

        // prepare data for node "Former Employment"
        if (formerEmploymentNodes.length > 0) {
            formerEmploymentNodes.sort(ArrayHelperService.treeNodesOrderBy('relationship'));
            treeNodes.push({
                uuid: 'FORMER_EMPLOYMENT_GROUP',
                name: 'Former Employment',
                shortName: 'Former Employment',
                isLeaf: false,
                level: '2',
                nodeType: 'CUSTOMIZE',
                parentId: entity.id,
            });

            const relationshipNodeIdSet: Set<string> = new Set();
            formerEmploymentNodes.forEach(node => {
                if (!relationshipNodeIdSet.has(node.relationshipType.id)) {
                    treeNodes.push({
                        uuid: `FORMER_EMPLOYMENT_GROUP-${node.relationshipType.id}`,
                        name: node.relationshipType.name,
                        shortName: node.relationshipType.name,
                        isLeaf: false,
                        level: '3',
                        nodeType: 'RELATIONSHIP',
                        parentId: 'FORMER_EMPLOYMENT_GROUP',
                        relationshipId: node.relationshipId
                    });
                    relationshipNodeIdSet.add(node.relationshipType.id);
                }
                treeNodes.push({
                    ...node.childEntity,
                    isLeaf: true,
                    level: '4',
                    nodeType: 'ENTITY',
                    parentId: `FORMER_EMPLOYMENT_GROUP-${node.relationshipType.id}`,
                    uuid: `FORMER_EMPLOYMENT_GROUP-${node.relationshipType.id}-${node.childEntity.id}`,
                    relationshipId: node.relationshipId,
                    relationshipTypeName: node.relationshipType.name
                });
            });
        }

        // prepare data for node "Teams"
        if (teamNodes.length > 0) {
            teamNodes.sort(ArrayHelperService.treeNodesOrderBy('childName'));
            treeNodes.push({
                uuid: 'TEAMS_GROUP',
                name: 'Teams',
                shortName: 'Teams',
                isLeaf: false,
                level: '2',
                nodeType: 'CUSTOMIZE',
                parentId: entity.id,
            });

            teamNodes.forEach(node => {
                treeNodes.push({
                    ...node.childEntity,
                    uuid: `TEAMS_GROUP-${node.childEntity.id}`,
                    isLeaf: true,
                    level: '3',
                    nodeType: 'ENTITY',
                    parentId: 'TEAMS_GROUP',
                    relationshipId: node.relationshipId,
                    relationshipTypeName: 'Teams'
                });
            });
        }

        // prepare data for node "Team Member"
        if (teamMemberNodes.length > 0) {
            teamMemberNodes.sort(ArrayHelperService.treeNodesOrderBy('childName'));
            treeNodes.push({
                uuid: 'TEAM_MEMBER_GROUP',
                name: 'Team Members',
                shortName: 'Team Members',
                isLeaf: false,
                level: '2',
                nodeType: 'CUSTOMIZE',
                parentId: entity.id,
            });

            teamMemberNodes.forEach(node => {
                treeNodes.push({
                    ...node.childEntity,
                    uuid: `TEAM_MEMBER_GROUP-${node.childEntity.id}`,
                    isLeaf: true,
                    level: '3',
                    nodeType: 'ENTITY',
                    parentId: 'TEAM_MEMBER_GROUP',
                    relationshipId: node.relationshipId,
                    relationshipTypeName: 'Team Members'
                });
            });
        }
        return treeNodes;
    }

    updateCheckedKeysByLeafNode(leafNode: RelationshipTreeNode, relationshipTreeConfig): Array<any> {
        let siblingNodeCount = 0;
        let siblingCheckedCount = 0;
        const index = relationshipTreeConfig.checkedKeys.indexOf(leafNode.uuid);
        const relationshipTypeCheckedIndex = relationshipTreeConfig.checkedKeys.indexOf(leafNode.uuid.substring(0, leafNode.uuid.length - 33));
        relationshipTreeConfig.nodes.forEach(element => {
            if (element.level === '4' && leafNode.parentId === element.parentId) {
                siblingNodeCount++;
            }
        });
        if (index > -1) {
            // the node has been checked
            if (siblingNodeCount === 1) {
                // only the item self, need to add the relationship type
                if (relationshipTypeCheckedIndex < 0) {
                    relationshipTreeConfig.checkedKeys.push(leafNode.uuid.substring(0, leafNode.uuid.length - 33));
                }
            } else {
                // to update relationship type checkedkeys
                // because if has two node, one is checked, another one is no, if we remove the on checked node, the relationship type
                // need to check too, so we need to update the relationship type checkedkey
                relationshipTreeConfig.checkedKeys.forEach(checkedkey => {
                    if (leafNode && checkedkey.substring(0, (checkedkey.length - 33)) === leafNode.parentId) {
                        siblingCheckedCount++;
                    }
                });
                if (siblingNodeCount === siblingCheckedCount && relationshipTypeCheckedIndex < 0) {
                    relationshipTreeConfig.checkedKeys.push(leafNode.uuid.substring(0, leafNode.uuid.length - 33));
                } else {
                    relationshipTreeConfig.checkedKeys = relationshipTreeConfig.checkedKeys.filter(checkedkey =>
                        checkedkey !== leafNode.uuid.substring(0, leafNode.uuid.length - 33));
                }
            }
        } else {
            // if this node is not checked, the relationship type node cannot be checked
            relationshipTreeConfig.checkedKeys = relationshipTreeConfig.checkedKeys.filter(checkedkey =>
                checkedkey !== leafNode.uuid.substring(0, leafNode.uuid.length - 33));
        }
        return relationshipTreeConfig.checkedKeys;
    }

    updateRelationshipWithCheckedKeys(event, screenKey, relationshipTreeConfig): Observable<any> {
        const previousFinder: FinderSetting = this._storeQuerier.queryBySelector(getDynamicResearchFocusSelector2(screenKey));
        // focus entity changed
        // below is to handle the node checkedkeys
        const clickedItem = event.payload.clickedItem;
        // this.currentClickedNode = clickedItem;
        const relationshipType = event.payload.targetRelationshipType;
        let preName: string;
        if (clickedItem.parentId.substring(0, clickedItem.parentId.length - 33) === 'ENTITY_GROUP') {
            preName = 'ENTITY_GROUP';
        }
        if (clickedItem.parentId.substring(0, clickedItem.parentId.length - 33) === 'CONTACTS_GROUP') {
            preName = 'CONTACTS_GROUP';
        }
        const index = relationshipTreeConfig.checkedKeys.indexOf(clickedItem.uuid);
        if (index >= 0) {
            relationshipTreeConfig.checkedKeys.splice(index, 1);
            const relationship = `${preName}-${relationshipType.id}-${clickedItem.id}`;
            relationshipTreeConfig.checkedKeys.push(relationship);
        }
        // node checkedkeys handle over

        this._queryInfo.entityIds = [previousFinder.entity.id];
        return this._entityService.getEntityDetailWithoutAdhoc(previousFinder.entity.id).pipe(
            take(1),
            map(response => {
                const entity = Entity.parse(response);
                const treeNodes = RelationshipTreeService.parseToTreeViewModel(entity);
                this._store.dispatch(new ResearchScreenSetRelationshipNodes(screenKey, treeNodes));
                relationshipTreeConfig.nodes = treeNodes;

                // below is to update checked keys for parent node
                // below is to handle the old parent node
                let countForOldNode = 0; // the node count below the old parent
                let countForOldcheckedKeys = 0; // the checkedkeys count below the old parent
                relationshipTreeConfig.nodes.forEach(element => {
                    if (element.level === '4' && clickedItem.parentId === element.parentId) {
                        countForOldNode++;
                    }
                });
                // get clickedItem uuid, not includes self id
                const clickedItemUUID = clickedItem.uuid.substring(0, clickedItem.uuid.length - 33);
                relationshipTreeConfig.checkedKeys.forEach(element => {
                    if (clickedItemUUID === element.substring(0, element.length - 33)) {
                        // here is no need to adjust node self, because the item has sliced behind 33 words
                        // it must be different with clickedItemUUID
                        countForOldcheckedKeys++;
                    }
                });
                if (countForOldNode === countForOldcheckedKeys &&
                    relationshipTreeConfig.checkedKeys.indexOf(clickedItemUUID) < 0) {
                    relationshipTreeConfig.checkedKeys.push(clickedItemUUID);
                }
                // front is to handle old parent node

                // below is to handle the new parent node
                let countForNewNode = 0; // the node count below the new parent
                let countForNewcheckedKeys = 0; // the checkedkeys count below the new parent
                relationshipTreeConfig.nodes.forEach(element => {
                    if (element.level === '4' && relationshipType.id === element.parentId.slice(-32)) {
                        countForNewNode++;
                    }
                });
                relationshipTreeConfig.checkedKeys.forEach(element => {
                    if (element.length > 64) {
                        const relationshipTypeId = element.substring(0, element.length - 33).slice(-32);
                        if (relationshipType.id === relationshipTypeId) {
                            countForNewcheckedKeys++;
                        }
                    }
                });

                // adjust if the new parent exist in checkedkeys array
                const indexForRelationshipType = relationshipTreeConfig.checkedKeys.indexOf(`${preName}-${relationshipType.id}`);
                if (countForNewNode > countForNewcheckedKeys && indexForRelationshipType > 0) {
                    relationshipTreeConfig.checkedKeys.splice(indexForRelationshipType, 1);
                }
                if (countForNewNode === countForNewcheckedKeys && indexForRelationshipType < 0) {
                    relationshipTreeConfig.checkedKeys.push(`${preName}-${relationshipType.id}`);
                }
                // parent checkedkeys update over
            }),
        );
    }

    updateRelationshipWithoutCheckedKeys(screenKey, relationshipTreeConfig): Observable<any> {
        const previousFinder: FinderSetting = this._storeQuerier.queryBySelector(getDynamicResearchFocusSelector2(screenKey));
        this._queryInfo.entityIds = [previousFinder.entity.id];
        return this._entityService.getEntityDetailWithoutAdhoc(previousFinder.entity.id).pipe(
            take(1),
            map(response => {
                const entity = Entity.parse(response);
                const treeNodes = RelationshipTreeService.parseToTreeViewModel(entity);
                this._store.dispatch(new ResearchScreenSetRelationshipNodes(screenKey, treeNodes));
                relationshipTreeConfig.nodes = treeNodes;
            }),
        );
    }

    getEntityRelationshipTreeDetail(id, relationshipTreeConfig): any {
        this._entityService.getEntityDetailWithoutAdhoc(id).pipe(
            take(1),
        ).subscribe(response => {
            const entity = Entity.parse(response);
            const treeNodes = RelationshipTreeService.parseToTreeViewModel(entity);
            relationshipTreeConfig.nodes = treeNodes;
            relationshipTreeConfig.showAs = 'shortName';
            relationshipTreeConfig.expandedKeys = [];
            treeNodes.forEach(element => {
                if (element.uuid !== '1' && element.level !== '4') {
                    relationshipTreeConfig.expandedKeys.push(element.uuid);
                }
            });
        });
    }
}
