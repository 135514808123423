/**
 * Created by Yoyo Fang on 12/04/18.
 * Description:
 *
 * ------ maintenance history ------
 * updated by Abner Sui on 05/13/2019
 * Add email digest CRUD related functions
 * updated by Abner Sui on 06/04/2019
 * Parse start date for email digest
 * updated by Bowen Li on 07/03/2019
 * And two subject for dialog
 */
import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

import { AppConfig } from '../tamalelibs/models/app-config.model';
import { TimeZone, DashboardPrintSetting, EmailDigestType } from '../tamalelibs/models/emaildigest.model';
import { TransportService, URIComponentEncodingCodec } from '../tamalelibs/services/transport.service';
import { EmailDigest, EmailSection, SectionType, Scheduler, Frequency, SectionDisplay, SortOrder, SectionColumn } from '../tamalelibs/models/emaildigest.model';
import * as lodash from 'lodash';
import { IdHelperService } from './id-helper.service';
import { Adhoc } from '../tamalelibs/models/adhoc.model';
import { DateHelperWebService } from '../tamalelibs/services/date-helper.web.service';
import { tz } from 'moment-timezone';
import { ScheduleHelperService } from './schedule-helper.service';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class EmailDigestService {

    actionToListSubject$: Subject<any>;
    dialogActionSubject$: Subject<any>;
    dialogFeedbackSubject$: Subject<any>;
    previewActionSubject$: Subject<any>;

    private _defaultEmailDigest: EmailDigest = {
        id: '',
        name: '',
        description: '',
        type: '',
        lastModifiedDate: null,
        createDate: null,
        minuteOffset: null,
        author: null,
        editable: true,
        deletable: true,
        emailSection: [],
        latestExecutionTime: null,
        scheduler: null,
        isSubscribed: true,
        recipients: null,
        isSubscribable: true,
        hideBlankEmail: false,
        hideBlankSection: false,
        active: true,
        schedulerStr: '6:30 AM every Monday',
    };
    private _defaultScheduler: Scheduler = {
        time: '06:30',
        configuration: '',
        every: 1,
        startDate: null,
        frequency: Frequency.WEEKLY,
        on: 'MON',
    };
    private _defaultNoteSection: EmailSection = {
        id: '',
        name: 'Note Section',
        filterSetting: [
            {
                id: '',
                fields: ['entry-type id'],
                title: 'Note Type',
                value: 'all',
                operator: 'includes',
                metadataType: 'NoteType',
            },
        ],
        isIncludeEvents: false,
        advFilter: AppConfig.defaultAdvFilterNoteOrEvent,
        type: SectionType.NOTE,
        needFilterByLatestExecutionTime: false,
        displaySetting: null,
        previewResult: null,
        showAll: false,
    };
    private _defaultNoteDisplaySetting: SectionDisplay = {
        fixedColumn: ['subject', 'blurb'],
        sortOrder: SortOrder.DESC,
        sortBy: 'submittedDate',
        column: [],
        row: 10,
    };
    private _defaultFileSection: EmailSection = {
        id: '',
        name: 'File Section',
        filterSetting: [],
        isIncludeEvents: false,
        advFilter: AppConfig.defaultAdvFileFilter,
        type: SectionType.FILE,
        needFilterByLatestExecutionTime: false,
        displaySetting: null,
        previewResult: null,
        showAll: false,
    };
    private _defaultFileDisplaySetting: SectionDisplay = {
        fixedColumn: ['subject', 'blurb'],
        sortOrder: SortOrder.DESC,
        sortBy: 'submittedDate',
        column: [],
        row: 10,
    };
    private _defaultNoteColumns: Array<string> = ['entities', 'entryType', 'source', 'displayDate'];
    private _standardNoteColumns: Array<string> = ['entryType', 'entities', 'displayDate', 'submittedDate', 'lastEditedDate', 'source', 'submitter', 'priority', 'sentiment', 'location', 'eventTime', 'participants'];
    private _defaultFileColumns: Array<string> = ['title', 'source', 'submitter'];
    private _standardFileColumns: Array<string> = ['title', 'entryType', 'entities', 'displayDate', 'submittedDate', 'source', 'submitter', 'priority', 'sentiment'];
    private _timezoneList: Array<TimeZone>;

    get timezoneList(): Array<TimeZone> {
        return this._timezoneList;
    }

    get standardNoteColumns(): Array<SectionColumn> {
        const result: Array<SectionColumn> = [];
        this._standardNoteColumns.forEach(item => {
            result.push(this.generateColumn(item));
        });
        return result;
    }

    get standardFileColumns(): Array<SectionColumn> {
        const result: Array<SectionColumn> = [];
        this._standardFileColumns.forEach(item => {
            result.push(this.generateColumn(item));
        });
        return result;
    }

    constructor(
        private _transportService: TransportService,
    ) {
        this.actionToListSubject$ = new Subject();
        this.dialogActionSubject$ = new Subject();
        this.dialogFeedbackSubject$ = new Subject();
        this.previewActionSubject$ = new Subject();
        this._timezoneList = [];
        tz.names().forEach(item => {
            this._timezoneList.push({
                id: '(GMT' + tz(item).format('Z') + ')' + item,
                name: '(GMT' + tz(item).format('Z') + ') ' + item.replace('/', ' / '),
                offset: this.getOffsetNumFromOffsetStr(tz(item).format('Z')),
                zonename: item,
            });
        });
        this._timezoneList.sort((one, two) => {
            if (one.offset === two.offset) {
                return one.zonename.localeCompare(two.zonename);
            } else {
                return one.offset - two.offset;
            }
        });
    }

    public checkEmailDigestName(name: string, id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}` + 'checkname/';
        const emaildigestParam: any = {};
        if (id) {
            emaildigestParam.id = id;
        }
        emaildigestParam.name = name;
        const params = {
            outputformat: 'json',
            emaildigest: JSON.stringify(emaildigestParam),
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public deleteEmailDigest(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}${id}`;
        const options = {
            headers: headers,
        };
        return this._transportService.delete(url, options);
    }

    public getEmailDigest(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}${id}/`;
        const params = {
            showpermission: true,
            outputformat: 'json',
            expand: 'entity'
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getEmailDigestSection(id: string, sectionId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}${id}/section/${sectionId}/`;
        const options = {
            headers: headers,
        };
        return this._transportService.get(url, options);
    }

    public getEmailDigestList(): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}`;
        const params = {
            showpermission: true,
            outputformat: 'json',
            expand: 'entity',
            sortby: 'lastModifiedDate',
            sortorder: 'desc',
        };
        const options = {
            headers: headers,
            params: params
        };
        return this._transportService.get(url, options);
    }

    public getFireTime(emailDigest: EmailDigest): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}` + 'firetime/';
        const params = {
            outputformat: 'json',
        };
        const options = {
            headers: headers,
            params: params
        };
        const formData = new URLSearchParams();
        const body: any = {
            minuteOffset: emailDigest.minuteOffset,
            scheduler: this.parseSchedulerToParams(emailDigest.scheduler),
        };
        formData.set('emaildigest', JSON.stringify(body));
        return this._transportService.post(url, formData.toString(), options);
    }

    public getFireTimeById(id: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}firetime/${id}/`;
        const params = {
            outputformat: 'json',
        };
        const options = {
            headers: headers,
            params: params,
        };
        return this._transportService.get(url, options);
    }

    public previewById(id: string, showTimeZone: string, timeZoneId: string): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}preview/${id}/`;
        let params = new HttpParams({ encoder: new URIComponentEncodingCodec() });
        params = params.append('showtimezone', showTimeZone);
        params = params.append('timeZoneId', timeZoneId);
        const options = {
            headers: headers,
            responseType: 'text',
            params: params,
        };
        return this._transportService.get(url, options);
    }

    public previewSection(emailSection: EmailSection, minuteOffset: number, showTimeZone: string, timeZoneId: string): Observable<string> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}` + 'preview/section/';
        const options = {
            headers: headers,
            responseType: 'text',
        };
        const formData = new URLSearchParams();
        const body: any = {
            minuteOffset: minuteOffset,
            emailSection: [emailSection],
        };
        formData.set('emaildigest', JSON.stringify(body));
        formData.set('showtimezone', showTimeZone);
        formData.set('timeZoneId', timeZoneId);
        return this._transportService.post(url, formData.toString(), options);
    }

    public previewSectionWithOneData(emailSection: EmailSection, minuteOffset: number, showTimeZone: string, timeZoneId: string): Observable<any> {
        const section: EmailSection = lodash.cloneDeep(emailSection);
        section.displaySetting.row = 1;
        return this.previewSection(section, minuteOffset, showTimeZone, timeZoneId);
    }

    /**
     * remove the footer of preview html template
     * @param response
     */
    public removePreviewHtmlTemplateFooter(originalHtml: string): string {
        let htmlTemplate = originalHtml;
        const reg = `<table width="100%" align="center" style="background-color: white;border: solid 1px #e7eaec;padding: 10px 15px 12px 10px;border-bottom: 2px solid #2a3d53;box-shadow: 0 -2px 2px 0 rgba(179, 179, 179, 0.5);margin-top: 40px;"><tbody><tr><td width="20" style="padding-right: 2px"><img style="width: 16px;height: 16px;float: left;" src="https://image.ibb.co/dgmmRp/favicon_16px.png"></img></td><td><span align="left" style="height: 14px;line-height: 1.17;text-align: left;color: black;font-family: 'Source Sans Pro';">Sent from Tamale RMS</span></td></tr></tbody></table>`;
        htmlTemplate = htmlTemplate.replace(reg, ' ');

        return htmlTemplate;
    }

    public saveEmailDigest(item): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}`;
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('emaildigest', JSON.stringify(item));
        formData.set('showpermission', 'true');
        formData.set('expand', 'entity');
        return this._transportService.post(url, formData.toString(), options);
    }

    public sendEmail(id: string, customRecipients: string[]): Observable<any> {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}email/${id}`;
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('customRecipients', JSON.stringify(customRecipients));
        return this._transportService.post(url, formData.toString(), options);
    }

    public updateEmailDigest(item): Observable<any> {
        item = this.deleteConfigurationTimezone(item);
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            'Pragma': 'no-cache'
        };
        const url = `${AppConfig.emailDigestEndpoint}${item.id}`;
        const options = {
            headers: headers,
        };
        const formData = new URLSearchParams();
        formData.set('emaildigest', JSON.stringify(item));
        formData.set('showpermission', 'true');
        formData.set('expand', 'entity');
        return this._transportService.post(url, formData.toString(), options);
    }

    public updateEmailDigestActive(item): Observable<any> {
        const url = `${AppConfig.emailDigestEndpoint}active/${item.id}`;
        const headers = {};
        const params = { emaildigest: JSON.stringify({ active: item.active }), showpermission: true, expand: 'entity' };
        return this._transportService.put(url, null, { headers: headers, params: params });
    }

    public updateEmailDigestSubscription(item): Observable<any> {
        const url = `${AppConfig.emailDigestEndpoint}` + 'subscribe/' + item.id;
        const headers = {};
        const params = { subscribe: item.isSubscribed, showpermission: true, expand: 'entity' };
        return this._transportService.put(url, null, { headers: headers, params: params });
    }

    public parseEmailDigestFromResponse(response: Array<any>) {
        const result: Array<EmailDigest> = [];
        response.forEach(item => {
            result.push(EmailDigest.parse(item));
        });
        return result;
    }

    public parseFireTime(time: number, minuteOffset: any): string  {
        const utcDate = moment(time).tz(minuteOffset);
        return utcDate.format('dddd, MMMM D, YYYY, h:mm:ss A');
    }

    public getConfigContryTimezone(emailDigest: EmailDigest): String {
        const configContryTimezone = emailDigest.scheduler.configuration;
        if (configContryTimezone ?? false) {
            if (configContryTimezone.startsWith('(')) {
                return configContryTimezone.split(')')[1];
            }
        }
        return configContryTimezone;
    }

    public parseStartDateFromServerToUI(originDate: Date, minuteOffset: number): Date {
        const localMinuteOffset = this.getOffsetNumFromOffsetStr(tz(tz.guess()).format('Z'));
        const result: Date = new Date(originDate.getTime() + (minuteOffset - localMinuteOffset) * 60 * 1000);
        return result;
    }

    public parseStartDateFromUIToServer(originDate: Date, minuteOffset: number): Date {
        const localMinuteOffset = this.getOffsetNumFromOffsetStr(tz(tz.guess()).format('Z'));
        const result: Date = new Date(originDate.getTime() - (minuteOffset - localMinuteOffset) * 60 * 1000);
        return result;
    }

    public generateColumn(field: string, adhoc?: Adhoc): SectionColumn {
        switch (field) {
            case 'entities':
                return {
                    field: field,
                    display: 'Entity',
                    dataType: 'entity',
                    format: 'shortName',
                };
            case 'entryType':
                return {
                    field: field,
                    display: 'Note Type',
                    dataType: 'text',
                    format: '',
                };
            case 'source':
                return {
                    field: field,
                    display: 'Source',
                    dataType: 'entity',
                    format: 'shortName',
                };
            case 'displayDate':
                return {
                    field: field,
                    display: 'Display Date',
                    dataType: 'date',
                    format: '',
                };
            case 'title':
                return {
                    field: field,
                    display: 'File Name',
                    dataType: 'text',
                    format: '',
                };
            case 'submitter':
                return {
                    field: field,
                    display: 'Submitter',
                    dataType: 'entity',
                    format: 'shortName',
                };
            case 'submittedDate':
                return {
                    field: field,
                    display: 'Submitted Date',
                    dataType: 'date',
                    format: '',
                };
            case 'lastEditedDate':
                return {
                    field: field,
                    display: 'Last Modified Date',
                    dataType: 'date',
                    format: '',
                };
            case 'priority':
                return {
                    field: field,
                    display: 'Priority',
                    dataType: 'text',
                    format: '',
                };
            case 'sentiment':
                return {
                    field: field,
                    display: 'Sentiment',
                    dataType: 'text',
                    format: '',
                };
            case 'location':
                return {
                    field: field,
                    display: 'Location',
                    dataType: 'text',
                    format: '',
                };
            case 'eventTime':
                return {
                    field: field,
                    display: 'Event Time',
                    dataType: 'date',
                    format: '',
                };
            case 'participants':
                return {
                    field: field,
                    display: 'Participants',
                    dataType: 'entity',
                    format: 'shortName',
                };

            default:
                let adhocDisplay;
                if (adhoc.titleDuplicate) {
                    adhocDisplay = adhoc.title + ' (' + adhoc.dataType.toUpperCase()[0] + adhoc.dataType.substring(1) + ')';
                } else {
                    adhocDisplay = adhoc.title;
                }
                const result: SectionColumn = {
                    field: adhoc.id,
                    display: adhocDisplay,
                    dataType: adhoc.dataType,
                };
                if (adhoc.dataType === 'entity') {
                    result.format = 'shortName';
                } else {
                    result.format = '';
                }
                return result;
        }
    }

    public getDefaultNoteDisplay(): SectionDisplay {
        const result = lodash.cloneDeep(this._defaultNoteDisplaySetting);
        this._defaultNoteColumns.forEach(item => {
            result.column.push(this.generateColumn(item));
        });
        return result;
    }

    public getDefaultFileDisplay(): SectionDisplay {
        const result = lodash.cloneDeep(this._defaultFileDisplaySetting);
        this._defaultFileColumns.forEach(item => {
            result.column.push(this.generateColumn(item));
        });
        return result;
    }

    public getDefaultNoteSection(): EmailSection {
        const result = lodash.cloneDeep(this._defaultNoteSection);
        result.id = IdHelperService.createGUID();
        result.displaySetting = this.getDefaultNoteDisplay();
        return result;
    }

    public getDefaultFileSection(): EmailSection {
        const result = lodash.cloneDeep(this._defaultFileSection);
        result.id = IdHelperService.createGUID();
        result.displaySetting = this.getDefaultFileDisplay();
        return result;
    }

    public getDefaultScheduler(): Scheduler {
        const result = lodash.cloneDeep(this._defaultScheduler);
        result.configuration = tz.guess();
        result.startDate = this.parseStartDateFromUIToServer(DateHelperWebService.getDateOnlyOfToday(), this.timezoneList.find(item => item.id.includes(result.configuration)).offset);
        return result;
    }

    public getDefaultEmailDigest(type: string, userId?: string): EmailDigest {
        const result = lodash.cloneDeep(this._defaultEmailDigest);
        if (type !== EmailDigestType.DASHBOARD) {
            result.emailSection.push(this.getDefaultNoteSection());
        }
        result.scheduler = this.getDefaultScheduler();
        result.minuteOffset = this.timezoneList.find(item => item.id.includes(result.scheduler.configuration)).offset;
        result.schedulerStr = ScheduleHelperService.getScheduledAt(result.scheduler);
        result.type = type;
        if (userId) {
            result.recipients = [userId];
        } else {
            delete result.recipients;
        }
        return result;
    }

    /**
     * get local hour offset, like '-07:00'
     */
    public getLocalHourOffset(): string {
        return tz(tz.guess()).format('Z');
    }

    /**
     * Get local minute offset, may replace duplicated function in dateHelper
     */
    public getLocalMinuteOffset(): number {
        const localTimezone: string = tz(tz.guess()).format('Z');
        return this.getOffsetNumFromOffsetStr(localTimezone);
    }

    public getOffsetNumFromOffsetStr(offsetStr: string): number {
        const hourStr = offsetStr.substring(1, 3);
        const minuteStr = offsetStr.substring(4);
        let result = (+hourStr) * 60 + (+minuteStr);
        if (offsetStr.startsWith('-')) {
            result = -result;
        }
        return result;
    }

    public parseEmailDigestToParams(data: EmailDigest): any {
        const result = Object.assign({}, data);
        if (data.scheduler) {
            result.scheduler = this.parseSchedulerToParams(data.scheduler);
        }
        return result;
    }

    public parseSchedulerToParams(data: Scheduler): any {
        const result: any = Object.assign({}, data);
        result.startDate = result.startDate.getTime();
        return result;
    }

    public isSameScheduler(origin: Scheduler, target: Scheduler): boolean {
        if (origin.frequency !== target.frequency) {
            return false;
        }
        if (origin.configuration !== target.configuration) {
            return false;
        }
        if (origin.time !== target.time) {
            return false;
        }
        if (origin.every !== target.every) {
            return false;
        }
        if (origin.startDate.getTime() !== target.startDate.getTime()) {
            return false;
        }
        if (origin.on) {
            if (origin.on !== target.on) {
                return false;
            }
        } else if (target.on) {
            return false;
        }
        return true;
    }

    public deleteConfigurationTimezone(data: EmailDigest): any {
        const result = lodash.cloneDeep(data);
        if (result?.scheduler?.configuration ?? false) {
            if (result.scheduler.configuration.startsWith('(')) {
                result.scheduler.configuration = result.scheduler.configuration.split(')')[1];
                return result;
            }
        }
        return result;
    }

    public addConfigurationTimezone(data: EmailDigest): any {
        const result = lodash.cloneDeep(data);
        if (result?.scheduler?.configuration ?? false) {
            if (!result.scheduler.configuration.startsWith('(')) {
                result.scheduler.configuration = this.timezoneList.find(item => item.id.includes(result.scheduler.configuration)).id;
                return result;
            } else {
                result.scheduler.configuration = this.timezoneList.find(item => item.id.includes(result.scheduler.configuration.split(')')[1])).id;
                return result;
            }
        }
        return result;
    }
}
