<div class="notetype-editor-container">
    <div class="header-container">
        <label>{{ title }}</label>
        <smart-icon name="close" (click)="onSlideSheetClose()" class="light"></smart-icon>
    </div>
    <div class="input-container">
        <div class="tam-form-field" [ngClass]="{'has-error':nameDuplicated ||name.invalid}">
            <label class="required">{{'general.name' | stringLiterals}}</label>
            <input [(ngModel)]="currNoteData['name']" class="tam-input tam-textbox" type="text" (blur)="configChanged()"
                placeholder="Please enter a unique name" value="{{ currNoteData['name'] }}" [formControl]="name">
            <div class="tam-form-help" *ngIf="nameDuplicated">
                {{'general.name_already_exist' | stringLiterals}}
            </div>
            <div class="tam-form-help" *ngIf="name.invalid && name.errors['required']">
                {{'general.required_error_message' | stringLiterals}}
            </div>
            <div class="tam-form-help" *ngIf="name.invalid && name.errors['maxlength']">
                {{'general.name_exceed_maximum' | stringLiterals: '128'}}
            </div>
        </div>
        <div class="display-web-container">
            <label class="required">{{'general.display_in_tamaleWeb' | stringLiterals}}</label>
            <label class="checkbox" tam-checkbox [(ngModel)]="currNoteData['display-web']" (click)="configChanged()"
                style="padding-left: 8px; vertical-align: text-bottom;"></label>
        </div>
        <label> {{'general.default_extensions' | stringLiterals}}</label>
        <input [(ngModel)]="currNoteData['default-extension']" class="tam-input" type="text" (blur)="configChanged()"
            placeholder="Default Extensions" value="{{ currNoteData['default-extension'] }}" readonly>
        <label> {{'general.uses_readOnly' | stringLiterals}}</label>
        <input [(ngModel)]="currNoteData['uses']" class="tam-input" type="text" placeholder="Uses"
            (blur)="configChanged()" value="{{ currNoteData ['uses'] }}" readonly>
        <label class="required"> {{'general.used_by' | stringLiterals}}</label>
        <div class="control-content">
            <div class="dropdown-filter">
                <multi-sel-dropdown-filter [dropdownConfig]='dropdownConfig'>
                    <ng-template #multiCustomContent>
                        <div class="selected-type text-ellipsis" (click)='onClickSource($event)'
                            (click)="configChanged()">
                            {{sourceName}}
                        </div>
                    </ng-template>
                </multi-sel-dropdown-filter>
            </div>
            <div class="dropdown-error required-field" *ngIf="!sourceName">
                {{'general.this_is_required' | stringLiterals}}
            </div>
        </div>
        <label> {{'general.please_select_default_type_for_sidenote' | stringLiterals}}</label>
        <kendo-dropdownlist #dropDownEntryType [data]="noteTypes" [(ngModel)]="noteTypeDefault" [textField]="'name'"
            (blur)="configChanged()" class="control-content" [valueField]="'id'" [filterable]="true"
            (selectionChange)="selectionChange($event)" (filterChange)="handleFilter($event,dropDownEntryType)"
            (focus)="dropDownEntryType.toggle(true)" (mousedown)="$event.preventDefault()">
        </kendo-dropdownlist>
    </div>
    <div class="button-container">
        <button tam-button [tamType]="'primary'" [tamSize]="'normal'" class="button" (click)="onSubmitClick()"
            [tamLoading]="isProgressing" [disabled]="isProgressing||!sourceName">
            {{ title === 'Create New Entry Type' ? 'Create' : 'Submit'}}
        </button>
        <button tam-button [tamType]="'tertiary'" [tamSize]="'normal'" class="button" (click)="onSlideSheetClose()">
            {{'general.cancel' | stringLiterals}}
        </button>
    </div>
</div>