import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NoteEntry } from '../../tamalelibs/models/note-entry.model';
import { Thread } from '../../tamalelibs/models/thread.model';
import { ThreadDetailPartialDisplay } from '../thread-detail/thread-detail.view-model';

@Component({
    selector: 'note-detail',
    templateUrl: './note-detail.component.html',
    styleUrls: ['./note-detail.component.scss']
})
export class NoteDetailComponent implements OnInit {
    @Input() note: NoteEntry;
    @Input() highlightText: string[];
    @Input() isPrintMode: boolean;
    @Input() thread: Thread;
    @Input() partialDisplay?: ThreadDetailPartialDisplay;
    @Input() isShowMaximun: Boolean;
    @Input() isEmbedMode?: boolean;
    @Input() isSideSheet: boolean;
    @Output() editNoteOrSideNote: EventEmitter<any> = new EventEmitter();
    @Output() deleteSideNote: EventEmitter<any> = new EventEmitter();
    @Output() deleteAttachment: EventEmitter<any> = new EventEmitter();
    showNoteBody = true;

    constructor() { }

    ngOnInit() {
    }

    deleteAttachmentClick(event) {
        this.deleteAttachment.emit(event);
    }

    deleteSideNoteClick(event) {
        this.deleteSideNote.emit(event);
    }

    editNoteSideNoteClick(event) {
        this.editNoteOrSideNote.emit(event);
    }

    onBodyToggle() {
        this.showNoteBody = !this.showNoteBody;
    }
}
